import React from "react";
import {camelize} from "../../utils/stringUtils";

type LabelProps = {
    id?: string,
    label: string,
    required?: boolean
    labelId?: string,
    htmlFor?: string
}
export const Label: React.FC<LabelProps> = ({id, label, required, labelId= label, htmlFor}) => {
    const camelLabel = camelize(label);

    return (
        <label className={`h5 label ${camelLabel}Label`} htmlFor={htmlFor || camelLabel} id={id || labelId}>
            {label} {required && <a className="color-text--error">*</a>}
        </label>
    );
}