import Highcharts from "highcharts";
import {InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";

export type PlanSummaryNetWorthOverTimeGraphProps = {
    investorGroup: InvestorGroupType,
    netWealthAtCurrentAge: number | undefined,
    futureValueOfExcessAssetsAtAge: number[],
}

export const CreatePlanSummaryNetWorthOverTimeGraphOptions: (props: PlanSummaryNetWorthOverTimeGraphProps) => Highcharts.Options
    = (props: PlanSummaryNetWorthOverTimeGraphProps) => {
    let {
        investorGroup,
        netWealthAtCurrentAge,
        futureValueOfExcessAssetsAtAge,
    } = props;

    futureValueOfExcessAssetsAtAge = futureValueOfExcessAssetsAtAge ?? [];

    return {
        accessibility: {
            enabled: true
        },
        credits: {
            enabled: false
        },
        title: {
            text: undefined
        },
        chart: {
            marginRight: 40,
            style: {
                fontFamily: "RobotoCondensed"
            },
            height: 180,
        },
        tooltip: {
            enabled: false
        },
        xAxis: {
            type: 'linear',
            title: {
                text: "",
            },
            minorTicks: true,
            minorTickInterval: 5,
            minorGridLineColor: "rgb(246,246,246)",
            minorGridLineWidth: 1,
            labels: {
                y: 15,
                style: {
                    fontSize: '13px',
                    color: "rgb(118,118,118)",
                }
            },
            tickLength: 0,
            lineColor: "rgb(148, 148, 148)",
        },
        yAxis: {
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            title: {
                text: undefined
            },
            labels: {
                enabled: false
            }
        },
        series: [{
            type: 'areaspline',
            pointStart: investorGroup.planningPeriod.ageFrom,
            marker: {
                enabled: false,
                states: {
                    hover: {
                        enabled: false
                    }
                }
            },
            showInLegend: false,
            name: "Age",
            lineWidth: 4,
            data: [
                netWealthAtCurrentAge ?? 0,
                ...futureValueOfExcessAssetsAtAge
            ],
            zones: [
                {
                    color: "rgb(125, 224, 230)",
                    fillcolor: "rgb(201, 232, 225)"
                }
            ],
        }],
    };
}
