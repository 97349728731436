import React from "react";
import {DataDisplayView} from "../ReviseAssetAllocation";
import {DropdownItem, PageActionMenu, RadioGroup} from "../../../components";
import {MenuDivider} from "xps-react";

function ReviseAllocationPageMenu(props: {
    resetAll: () => void,
    resetRiskAssets: () => void,
    resetRiskControlAssets: () => void,
    handleChangeDisplayView: (e: React.ChangeEvent<HTMLInputElement>) => void,
    toggleIPCRange: () => void,
    showIPCRange: boolean,
    displayView: DataDisplayView,
    isProfileWithProposalsOrArchived?: boolean
}) {
    return <PageActionMenu
        className="marginright-12"
        aria-label="Revise Asset Allocation Menu"
        buttonKind="secondary"
        rounded={false}
        panelWidth={240}
    >
        <DropdownItem
            key="resetAll"
            itemText="Reset All"
            value="Reset All"
            onClick={props.resetAll}
            disabled={props.isProfileWithProposalsOrArchived}
        />
        <DropdownItem
            key="resetRiskAssets"
            itemText="Reset Risk Assets"
            value="Reset Risk Assets"
            onClick={props.resetRiskAssets}
            disabled={props.isProfileWithProposalsOrArchived}
        />
        <DropdownItem
            key="resetRiskControlAssets"
            itemText="Reset Risk Control Assets"
            value="Reset Risk Control Assets"
            onClick={props.resetRiskControlAssets}
            disabled={props.isProfileWithProposalsOrArchived}
        />
        <MenuDivider
            className="menu-divider"
            role="separator"
            itemText=""
            value="do_not_use"
        />
        <DropdownItem
            itemText=""
            value="do_not_use"
            onClick={() => {
                // Ignore this. Using Radio group here directly as a menuitem is overriding some attributes like role
            }}
        >
            <RadioGroup
                id="dataDisplay"
                name="dataDisplay"
                label="Data Display"
                layout="vertical"
                values={[DataDisplayView.PERCENTAGE_VIEW, DataDisplayView.DOLLAR_VIEW]}
                onChange={props.handleChangeDisplayView}
                selected={props.displayView}
            />
        </DropdownItem>
        <MenuDivider
            className="menu-divider"
            role="separator"
            itemText=""
            value="do_not_use"
        />
        <DropdownItem
            key="showHideIPCRange"
            itemText={props.showIPCRange ? 'Hide IPC Range Columns' : 'Show IPC Range Columns'}
            value={props.showIPCRange ? 'Hide IPC Range Columns' : 'Show IPC Range Columns'}
            onClick={props.toggleIPCRange}
        />
    </PageActionMenu>;
}

export default ReviseAllocationPageMenu;