import {AssetsSummary, CurrentNetWorthAsset} from "../../../Assets/models/Assets";
import {COLOR_PERSONAL_ASSETS} from "../../../constants/colors";
import {AssetAccordionHeader} from "../../../Assets/AssetSummary/common/AssetAccordionHeader";
import {AccordionItemWithActionMenu} from "../../../Assets/../components";
import React from "react";
import {assetListData} from "../../../Assets/AssetSummary/common/utils";
import {
    calculateCNWAssetsJointlyOwnedTotalPresentValue,
    calculateCNWAssetsMemberOwnedTotalPresentValue,
    calculateJointlyOwnedValue,
    calculateMemberOwnedValue,
    calculatePersonalAssetInEstateTotalValue
} from "../../../Assets/AssetSummary/common/AssetSummaryCalculator";
import {PersonalAsset} from "../../../Assets/models/PersonalAsset";
import {TableCell} from "../../../components/Table/TableCell";
import {NO_OP} from "../../../constants/common";
import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {AssetAccordionContentHeader} from "../../../Assets/AssetSummary/common/AssetAccordionContentHeader";
import {PersonalAssetRow} from "../../../Assets/AssetSummary/InEstate/PersonalAssetRow";
import {showOwnershipType} from "../../../Assets/Ownership/helpers";
import {getOwnershipDescription} from "../../../Assets/CurrentNetWorth/InEstate/ownershipUtils";

export interface InEstatePersonalAssetsProps {
    assetsDataForPage: AssetsSummary,
    investorGroup: InvestorGroupType,
    caption: string,
    allAssetsCNW: AssetsSummary
}

const PersonalAssetsCNWReport = ({
                                     assetsDataForPage,
                                     investorGroup,
                                     caption,
                                     allAssetsCNW
                                 }: InEstatePersonalAssetsProps) => {

    const {
        getFormattedTotalPresentValue,
        getFormattedPresentValueForCurrentNetWorth,
        inEstatePersonalAssets
    } = assetListData(assetsDataForPage);

    const currentNetWorthPersonalAssets: CurrentNetWorthAsset[] = allAssetsCNW.personalAssets.data.map(personalAsset => ({
        id: personalAsset.id,
        name: personalAsset.description,
        presentValue: personalAsset.presentValue,
        assetType: "personalAsset",
        ownershipCategory: personalAsset.ownershipCategory,
        memberOwnerships: personalAsset.memberOwnerships
    }));

    const primaryMember = investorGroup.primaryMember;
    const partnerMember = investorGroup.partnerMember;

    function generateAssetPopOverContent(personalAsset: PersonalAsset) {
        const ownershipDescription = getOwnershipDescription(personalAsset, primaryMember, partnerMember)
        return ownershipDescription ? <>{ownershipDescription}</> : undefined;
    }

    return (
        <>
            {inEstatePersonalAssets.length > 0 && <AccordionItemWithActionMenu
                uuid="PersonalAssetsCNWReport"
                accentColor={COLOR_PERSONAL_ASSETS}
                HeaderComponent={({expanded}) =>
                    <AssetAccordionHeader
                        caption={caption}
                        expanded={expanded}
                        title="Personal Assets"
                        formattedPrimaryMemberTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateCNWAssetsMemberOwnedTotalPresentValue(investorGroup.primaryMember.id, currentNetWorthPersonalAssets)) : undefined}
                        formattedSecondaryMemberTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateCNWAssetsMemberOwnedTotalPresentValue(investorGroup.partnerMember?.id, currentNetWorthPersonalAssets)) : undefined}
                        formattedJointTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthPersonalAssets)) : undefined}
                        formattedTotalPresentValue={getFormattedTotalPresentValue(calculatePersonalAssetInEstateTotalValue(allAssetsCNW.personalAssets.data))}
                        gridClassName={"current-net-worth-grid"}/>
                }>
                <div role="table" className="current-net-worth-grid-with-actionmenu assets-grid-table"
                     aria-label="personal-assets-table">
                    <AssetAccordionContentHeader investorGroup={investorGroup}/>

                    {inEstatePersonalAssets.map((personalAsset: PersonalAsset) => {
                        return <PersonalAssetRow personalAsset={personalAsset}
                                                 actionsDisabled={false}
                                                 onClickEdit={NO_OP}
                                                 onClickDelete={NO_OP}
                                                 key={personalAsset.id}
                                                 gridClassName={"current-net-worth-grid-with-actionmenu"}
                                                 renderPersonalAssetDetails={(asset: PersonalAsset) => {
                                                     return <>
                                                         <TableCell text={asset.description}
                                                                    subtext={showOwnershipType(asset.ownershipCategory)}
                                                                    className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}
                                                                    popoverContent={generateAssetPopOverContent(asset)}
                                                                    popoverWidth={"288px"}
                                                         />
                                                         {investorGroup.partnerMember && <>
                                                             <TableCell
                                                                 text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.primaryMember.id, asset.memberOwnerships, asset.ownershipCategory, asset.presentValue))}
                                                                 className="textalign-right"/>
                                                             <TableCell
                                                                 text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.partnerMember.id, asset.memberOwnerships, asset.ownershipCategory, asset.presentValue))}
                                                                 className="textalign-right"/>
                                                             <TableCell
                                                                 text={getFormattedPresentValueForCurrentNetWorth(calculateJointlyOwnedValue(asset.ownershipCategory, asset.presentValue))}
                                                                 className="textalign-right"/>
                                                         </>
                                                         }
                                                     </>
                                                 }}/>;
                    })}
                </div>
            </AccordionItemWithActionMenu>}
        </>
    );

}

export default PersonalAssetsCNWReport;
