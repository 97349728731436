import {Beneficiary} from "../../models/LifeInsurance";
import {MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import {getCurrentOwnerByOwnerMemberId, getMemberName} from "./ownershipUtils";

export function getBeneficiariesList(beneficiaries: Array<Beneficiary>, memberGroup: MemberGroup) {
    let lifeInsuranceBeneficiaries: string = "--";
    if (beneficiaries?.length) {
        lifeInsuranceBeneficiaries = beneficiaries.map(beneficiary => {
            return beneficiary.entityName || getMemberName(getCurrentOwnerByOwnerMemberId(beneficiary.memberId!, memberGroup!))
        }).join(", ");
    }
    return lifeInsuranceBeneficiaries;

}