import React from "react";
import {DataDisplayView, FundingDisplayOptions, GoalFundingSummary} from "./models/Funding";
import { FundingAccordionItemHeader } from "./FundingAccordionItemHeader";
import { AccordionItem } from "src/components";
import {
    formatFundingCurrency, formatFundingPercentage,
    isDollarView
} from "./fundingUtils";
import GoalFundingTable from "./GoalFundingTable";
import GoalAchievedPopover from "./GoalAchievedPopover";

type FundingTableSummaryProps = {
    goalsSummary: GoalFundingSummary;
    ageIndex: number;
    startAge: number;
    title: string;
    color: string;
    displayOptions: FundingDisplayOptions;
    displayView: DataDisplayView;
    enableAchieved?: boolean;
    showNested?: boolean;
};

const FundingTableSummary: React.FC<FundingTableSummaryProps> = ({
    goalsSummary,
    ageIndex,
    startAge,
    title,
    color,
    displayOptions,
    displayView,
    enableAchieved = true,
    showNested = true
}) => {
    const dollarView = isDollarView(displayView);
    const isAchieved = enableAchieved && !goalsSummary.presentValueAtYear?.[ageIndex];
    if(!goalsSummary.goals.length && showNested) {
        return <></>
    }
    return (
        <AccordionItem
            uuid={title}
            accentColor={color}
            HeaderComponent={({expanded, toggle}) =>
                <FundingAccordionItemHeader
                    title={title}
                    expanded={expanded}
                    fundedByRiskAssets={dollarView
                        ? formatFundingCurrency(goalsSummary.fundedByRiskAssetsAtYear?.[ageIndex])
                        : formatFundingPercentage(goalsSummary.fundedByRiskAssetsAtYearPercentage?.[ageIndex])}
                    fundedByRiskControl={dollarView
                        ? formatFundingCurrency(goalsSummary.fundedByRiskControlAtYear?.[ageIndex])
                        : formatFundingPercentage(goalsSummary.fundedByRiskControlAtYearPercentage?.[ageIndex])}
                    fundedByPortfolio={formatFundingCurrency(goalsSummary.fundedByPortfolioAtYear?.[ageIndex])}
                    fundedByNonInvestable={formatFundingCurrency(goalsSummary.fundedByNonInvestableAtYear?.[ageIndex])}
                    fundedByInflows={formatFundingCurrency(goalsSummary.fundedByInflowsAtYear?.[ageIndex])}
                    presentValue={isAchieved ? <GoalAchievedPopover isHeader={true} startAge={startAge} presentValueAtYear={goalsSummary.presentValueAtYear}/>
                        : formatFundingCurrency(goalsSummary.presentValueAtYear?.[ageIndex])}
                    gridClassName="funding-grid"
                    toggle={toggle}
                    displayOptions={displayOptions}
                    isAchieved={isAchieved}
                    showChevron={showNested}
                />
            }
        >
            {showNested && <GoalFundingTable
                goals={goalsSummary.goals}
                ageIndex={ageIndex}
                startAge={startAge}
                goalType={title}
                displayOptions={displayOptions}
                dollarView={dollarView}
                enableAchieved={enableAchieved}
            />}
        </AccordionItem>
    );
};

export default FundingTableSummary;
