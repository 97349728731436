import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import ConfirmationModal from "../../components/Modal/Confirmation/ConfirmationModal";
import {RouteWithId} from "../../routes/types";

interface SavePortfolioReserveModalProps {
    setPRClicked: boolean
    displayInsufficientWarning : boolean
}

const SavePortfolioReserveModal = ({setPRClicked, displayInsufficientWarning}: SavePortfolioReserveModalProps) => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const {id} = useParams<RouteWithId>();

    useEffect(() => {
        if (setPRClicked) {
            setShowModal(true);
        }
    }, [setPRClicked]);

    function navigateAway() {
        setShowModal(false);
        history.push(`/Profile/${id}/ClientProfile/PortfolioReserve/ReviewPortfolioReserve`);
    }
    return displayInsufficientWarning ?
    <ConfirmationModal
        confirmButtonDestructive={false}
        isOpen={showModal}
        title={"Portfolio Reserve Set"}
        content={"Your Reserve Target and Fund Lifestyle Spending using Non-Investable Assets election has been set."}
        onClickConfirm={() => {
            navigateAway()
        }}
        confirmText={'OKAY'}/>
        : <ConfirmationModal
            confirmButtonDestructive={false}
            isOpen={showModal}
            title={"Portfolio Reserve Set"}
            content={"Your Reserve Target election has been set."}
            onClickConfirm={() => {
                navigateAway()
            }}
            confirmText={'OKAY'}/>

}

export default SavePortfolioReserveModal
