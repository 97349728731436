import {GeneralInflowDetails, TrustInflowType} from "../../models/GeneralInflow";
import React from "react";
import {InvestorGroupMember} from "../../../ClientManagement/models/InvestorGroupType";
import {
    CurrencyInputWithLabel,
    DropdownItem,
    Input,
    PercentInput,
    RedAsterisk,
    RequiredFieldsSubheader,
    UnderlinedHeader
} from "../../../components";
import Dropdown from "../../../components/Dropdown/Dropdown";
import {calculateAgeFromBirthdate} from "../../../utils/dateUtils";
import {ISO8601_DATE_FORMAT} from "../../../constants/common";
import OwnerDropdown from "./OwnerDropdown";
import TrustInflowTypeDropdown from "./TrustInflowTypeDropdown";
import {ReleaseTogglesType} from "../../../ReleaseToggles/ReleaseToggles";
import useProfileEditableState from "../../../hooks/useProfileEditableState";

export type MemberOwnershipChange = {
    memberId: string,
    percentage: string,
    isRevocableTrust: boolean
};

type AssetDetailsProps = {
    onChangeType: (data: any) => void,
    onChangeTrustInflowType: (trustInflowType: TrustInflowType | undefined) => void,
    generalInflow: GeneralInflowDetails,
    onChangeDescription: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onBlurDescription: () => void,
    descriptionInlineErrorShown: boolean,
    owner: InvestorGroupMember,
    primaryMember: InvestorGroupMember,
    partnerMemberIfAlive: InvestorGroupMember | undefined,
    onChangeOwnership: (memberOwnership: MemberOwnershipChange[]) => void,
    onChangeAnnualFlow: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onBlurGrossAnnualFlow: () => void,
    onChangeTaxRate: (value: string) => void,
    onBlurTaxRate: () => void,
    onBlurNetAnnualFlow: () => void,
    releaseToggles?: ReleaseTogglesType
};

export function AssetDetails(props: AssetDetailsProps) {
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    const revTrustIdSuffix = ':revTrust';
    const jointId = 'joint';

    const handleOnChangeOwnership = (ownerId: string) => {
        const isJoint = ownerId === jointId;
        const memberOwnerships = [];

        if (isJoint) {
            memberOwnerships.push({
                memberId: props.primaryMember.id,
                percentage: "50",
                isRevocableTrust: false
            });
            memberOwnerships.push({
                memberId: props.partnerMemberIfAlive!.id,
                percentage: "50",
                isRevocableTrust: false
            });
        }
        else {
            const isRevocableTrust = ownerId.endsWith(revTrustIdSuffix);
            const id = isRevocableTrust ? ownerId.split(':')[0] : ownerId;
            memberOwnerships.push({
                memberId: id,
                percentage: "100",
                isRevocableTrust
            });
        }

        props.onChangeOwnership(memberOwnerships);
    };

    const getMemberOption = (member: InvestorGroupMember) => {
        const age = calculateAgeFromBirthdate(member.birthdate, ISO8601_DATE_FORMAT);
        return { label: `${member.name} (Age ${age})`, value: member.id };
    };

    const getRevocableTrustOption = (member: InvestorGroupMember) => {
        return { label: `${member.firstName} Revocable Trust`, value: `${member.id}${revTrustIdSuffix}` };
    };

    const ownerOptions = [
        getMemberOption(props.primaryMember),
        getRevocableTrustOption(props.primaryMember)
    ];

    if(props.partnerMemberIfAlive) {
        ownerOptions.push(getMemberOption(props.partnerMemberIfAlive));
        ownerOptions.push(getRevocableTrustOption((props.partnerMemberIfAlive)));
        ownerOptions.push({ label: 'Joint', value: jointId });
    }

    const selectedOwnerId = () => {
        const memberOwnerships = props.generalInflow.memberOwnerships;
        let ownerId = '';

        if(memberOwnerships.length > 0) {
            if(memberOwnerships.length > 1) {
                ownerId = jointId;
            }
            else {
                ownerId = `${memberOwnerships[0].memberId}${memberOwnerships[0].isRevocableTrust ? revTrustIdSuffix : ''}`;
            }
        }

        return ownerId;
    };

    let typeOptions = [];
    if (props.releaseToggles?.enableTrustInflows) {
        typeOptions = ["Salary", "Pension", "Annuities", "Trust Inflow", "Other Inflows"];
    } else {
        typeOptions = ["Salary", "Pension", "Annuities", "Other Inflows"];

        if(props.generalInflow.type === 'Trust Inflow') {
            props.generalInflow.type = 'Other Inflows';
            props.onChangeType({ value: props.generalInflow.type });
        }

        if(props.generalInflow.trustInflowType) {
            props.generalInflow.trustInflowType = undefined;
            props.onChangeTrustInflowType(props.generalInflow.trustInflowType);
        }
    }

    const typeDropdownItems = typeOptions.map(typeOption => (<DropdownItem key={typeOption}
                                                                           itemText={typeOption}
                                                                           value={typeOption}/>));

    return <section>
        <UnderlinedHeader
            className="asset-details-section-header"
            primaryText="Asset Details"
            secondaryContent={<RequiredFieldsSubheader/>}
        />
        <div className="layout-data-entry-form__field">
            <label id="general-inflow-type">
                <b>Type</b>
            </label>
            <Dropdown
                aria-labelledby="general-inflow-type"
                onChange={props.onChangeType}
                value={props.generalInflow.type}
                data-testid={"general-inflow-type"}
                disabled={isProfileWithProposalsOrArchived}
            >
                {typeDropdownItems}
            </Dropdown>
        </div>
        <div className="layout-data-entry-form__field">
            {props.generalInflow.type === "Trust Inflow" &&
                <TrustInflowTypeDropdown value={props.generalInflow.trustInflowType || "NT - Base Budget"}
                                         onChange={props.onChangeTrustInflowType}/>}
        </div>
        <div className="layout-data-entry-form__field">
            <label className="descriptionLabel" id="descriptionFieldInput-label">
                <b>Description<RedAsterisk/></b>
            </label>
            <Input
                name="description"
                readOnly={isProfileWithProposalsOrArchived}
                aria-labelledby="descriptionFieldInput-label"
                onChange={props.onChangeDescription}
                onBlur={props.onBlurDescription}
                error={props.descriptionInlineErrorShown ? "Description is required." : undefined}
                type="text"
                maxLength={100}
                value={props.generalInflow.description}
            />
        </div>
        <OwnerDropdown
            owner={selectedOwnerId()}
            owners={ownerOptions}
            disabled={isProfileWithProposalsOrArchived}
            onChange={handleOnChangeOwnership}/>
        <CurrencyInputWithLabel
            label="Gross Annual Flow"
            name="grossAnnualFlow"
            value={props.generalInflow.grossAnnualFlow}
            maxLength={15}
            minValue={0}
            onChangeValue={props.onChangeAnnualFlow}
            onBlur={props.onBlurGrossAnnualFlow}
            size="small"
            readOnly={isProfileWithProposalsOrArchived}
        />
        <div className="layout-data-entry-form__field tax-rate">
            <PercentInput defaultValue={"0"}
                          value={props.generalInflow.taxRate}
                          label="Tax Rate"
                          minValue={0}
                          maxValue={99}
                          onChange={props.onChangeTaxRate}
                          onBlur={props.onBlurTaxRate}
                          disabled={isProfileWithProposalsOrArchived}
            />
        </div>
        <CurrencyInputWithLabel
            label="Net Annual Flow"
            name="netAnnualFlow"
            value={props.generalInflow.netAnnualFlow}
            maxLength={15}
            minValue={0}
            onChangeValue={props.onChangeAnnualFlow}
            onBlur={props.onBlurNetAnnualFlow}
            size="small"
            readOnly={isProfileWithProposalsOrArchived}
        />
    </section>;
}