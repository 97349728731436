import Popover from "@nt/dds-react/core/Popover";
import {Icon} from "../../../components";
import React from "react";
import {formatCurrency} from "../../../utils/format";
import {GeneralInflow} from "../../models/GeneralInflow";
import {SocialSecurity} from "../../models/SocialSecurity";

export function FutureInflowsPopover(props: { futureInflow: GeneralInflow | SocialSecurity }) {
    return <>
        {
            props.futureInflow.isInflowWillFundLifestyleGoal &&
            <Popover
                id={`futureInflows-popover-${props.futureInflow.id}`}
                delay={0}
                content={
                    <div>
                        <div className="asset-summary-popover-content">
                            <span>Lifestyle Goal Aligned</span>
                            <span>{formatCurrency(props.futureInflow.lifestyleGoalAligned)}</span>
                        </div>
                        <div className="asset-summary-popover-content">
                            <span>Excess Inflow</span>
                            <span>{formatCurrency(props.futureInflow.excessFutureInflow)}</span>
                        </div>
                        <div className="asset-summary-popover-content">
                            <span>Inflow Reserve Years</span>
                            <span>{props.futureInflow.inflowReserveLength}</span>
                        </div>
                        <div className="asset-summary-popover-content">
                            <span>Inflow Reserve Value</span>
                            <span>{formatCurrency(props.futureInflow.inflowReservePresentValue)}</span>
                        </div>
                    </div>
                }
                direction="top"
                aria-label="futureInflows"
            >
                <Icon className="info-icon popover-trigger popoverContainer" ariaHidden={false}
                      name="status_info_outline" type="info" ariaLabel="inflow data"/>
            </Popover>
        }
    </>
}
