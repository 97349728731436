import {HeaderTickerTabsResponse} from "../../ClientManagement/ClientProfile/HeaderTickerTabType";
import {wealthManagementApiClient} from "../../ClientManagement/WealthManagementApiClient";
import {setHeaderTickerTabsProposedAllocation} from "../../ClientManagement/ClientProfile/HeaderTickerTabsSlice";
import {patchProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {patchApprovedProfile} from "../../ClientManagement/ClientProfile/approvedProfileSlice";
import {patchProposals} from "../../ClientManagement/Proposals/proposalsSlice";

function updateHeaderTickerTab(profileId: string, dispatch: any) {

    const apiRequest: [Promise<HeaderTickerTabsResponse>] = [
        wealthManagementApiClient.getHeaderTickerTabs(profileId)
    ]
    Promise.all(apiRequest).then(wealthResponse => {
        let [tickerTabResponse] = wealthResponse
        dispatch(setHeaderTickerTabsProposedAllocation({
            totalRiskControlAssetsPercent: tickerTabResponse.proposedAllocation.totalRiskControlAssetsPercent,
            totalRiskAssetsPercent: tickerTabResponse.proposedAllocation.totalRiskAssetsPercent
        }))
    })
}

export const handleTogglePortfolioReserve = (activePortfolioReserve: boolean, profileId: string, dispatch: Function) : Promise<any> => {

    return wealthManagementApiClient.toggleActivatePortfolioReserve(
        profileId,
        activePortfolioReserve
    ).then(() => {
        try {
            dispatch(patchProfile({activePortfolioReserve}));
            dispatch(patchApprovedProfile({activePortfolioReserve}));
            dispatch(patchProposals({activePortfolioReserve}));
            updateHeaderTickerTab(profileId, dispatch);
        } catch (error) {
            console.error('Could not activate portfolio reserve toggle', error);
        }

    })

};