import React, {ChangeEvent, useState} from 'react';
import {Button, CurrencyInput, Input} from "../../components";
import {YearsInput} from "./YearsInput";
import {LifestyleSpendingPeriodInputs} from "../models/LifestyleSpendingGoal";
import ModalWrapper from "../../components/Modal/ModalWrapper/ModalWrapper";

interface LifestyleSpendingPeriodProps {
    lifestyleSpendingPeriod: LifestyleSpendingPeriodInputs
    onChangeDescription: (description: string) => void,
    onChangeAnnualSpend: (annualSpend: string) => void,
    onChangeLengthOfSpend: (lengthOfSpend: string) => void
    onBlurAnnualSpend: () => void
    onBlurLengthOfSpend: () => void
    onDeleteSpendingPeriods: () => void
    disableLengthOfSpend: boolean
    hasError: boolean
    lengthOfSpend: number
    spendingTimeFrame: string
    clientAgeRange: string
    isProfileWithProposalsOrArchived?: boolean
}

const LifestyleSpendingPeriod = ({
                                     lifestyleSpendingPeriod,
                                     onChangeDescription,
                                     onChangeAnnualSpend,
                                     onChangeLengthOfSpend,
                                     onBlurAnnualSpend,
                                     onBlurLengthOfSpend,
                                     onDeleteSpendingPeriods,
                                     disableLengthOfSpend,
                                     hasError,
                                     lengthOfSpend,
                                     spendingTimeFrame,
                                     clientAgeRange,
                                     isProfileWithProposalsOrArchived = false
                                 }: LifestyleSpendingPeriodProps) => {
    const [isDescriptionInlineErrorShown, setIsDescriptionInlineErrorShown] = useState(false);

    const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsDescriptionInlineErrorShown(false)
        onChangeDescription(e.target.value)
    };

    const handleDescriptionBlur = () => {
        setIsDescriptionInlineErrorShown(false)
        if (!lifestyleSpendingPeriod.description ||
            lifestyleSpendingPeriod.description === "") {
            setIsDescriptionInlineErrorShown(true)
        }
    };

    const handleDeleteModalClick = () => {
        setShowDeletePeriodModal(false)
        onDeleteSpendingPeriods()
    }

    const [showDeletePeriodModal, setShowDeletePeriodModal] = useState<boolean>(false)

    return (
        <>
            <span className="textalign-right">
                <Input
                    className="descriptionFieldInput"
                    name="description"
                    aria-label="description"
                    aria-labelledby="descriptionFieldInput-label"
                    id="description"
                    data-testid="testingSpendingPeriod"
                    value={lifestyleSpendingPeriod.description}
                    onChange={handleDescriptionChange}
                    onBlur={handleDescriptionBlur}
                    error={isDescriptionInlineErrorShown ? "Description is required." : undefined}
                    removeMarginTop
                    size="medium"
                    type="text"
                    maxLength={200}
                    readOnly={isProfileWithProposalsOrArchived}
                />
            </span>
            <span>
                <CurrencyInput
                    className="AnnualSpendFieldInput"
                    aria-label="annualSpending"
                    name="annualSpending"
                    value={lifestyleSpendingPeriod.annualSpend}
                    onChangeValue={(e) => onChangeAnnualSpend(e.target.value)}
                    onBlur={onBlurAnnualSpend}
                    emptyValue="0"
                    maxLength={16}
                    removeMarginTop
                    size="medium"
                    readOnly={isProfileWithProposalsOrArchived}
                />
            </span>
            <span className="textalign-right">
                <div>
                    <YearsInput
                        className="LengthOfSpendFieldInput"
                        aria-label="lengthOfSpend"
                        value={lengthOfSpend}
                        readOnly={disableLengthOfSpend || isProfileWithProposalsOrArchived}
                        name="lengthOfSpend"
                        onChangeValue={(e) => onChangeLengthOfSpend(e.target.value)}
                        onBlur={onBlurLengthOfSpend}
                        hasError={hasError}
                    />
                </div>
            </span>
            <span>
                {!disableLengthOfSpend &&
                    <Button
                        className="delete_icon"
                        icon="only"
                        iconName="close"
                        kind="borderless"
                        onClick={() => setShowDeletePeriodModal(true)}
                        size="small"
                        type="button"
                        disabled={isProfileWithProposalsOrArchived}
                    />
                }
            </span>
            <div className="LifestyleSpendingPeriodRanges">
                <span className="LifestyleSpendingPeriodHeaders">Spending Time Frame:</span>
                <span
                    data-testid="spending-time-frame-value">{spendingTimeFrame}
                                </span>
                <span className="LifestyleSpendingPeriodHeaders">Client's Age Range:</span>
                <span
                    data-testid="age-range-value">{clientAgeRange}
                                </span>
            </div>
            <DeleteSpendingPeriodModal showDeleteModal={showDeletePeriodModal}
                                       handleModalDeleteButton={handleDeleteModalClick}
                                       handleModalCancelButton={() => setShowDeletePeriodModal(false)}
                                       lifestyleSpendingPeriod={lifestyleSpendingPeriod}/>
        </>);
}

type DeleteSpendingPeriodModalProps = {
    showDeleteModal: boolean
    handleModalDeleteButton: () => void
    handleModalCancelButton: () => void
    lifestyleSpendingPeriod: LifestyleSpendingPeriodInputs
}

const DeleteSpendingPeriodModal: React.FC<DeleteSpendingPeriodModalProps> = (
    {
        showDeleteModal,
        handleModalDeleteButton,
        handleModalCancelButton,
        lifestyleSpendingPeriod,
    }) => {
    return (
        <div data-testid="SpendingPeriodModal">
            <ModalWrapper
                id="discard-changes-modal"
                isOpen={showDeleteModal}
                headerText={`Delete this Goal?`}
                buttons={[
                    {
                        text: "CANCEL",
                        onClick: handleModalCancelButton,
                    },
                    {
                        text: "DELETE",
                        onClick: handleModalDeleteButton,
                        destructive: true,
                        primary: true,
                    },
                ]}
            >
                <div className="font-md">
                    Are you sure you want to delete {lifestyleSpendingPeriod.description}?
                </div>
            </ModalWrapper>
        </div>
    )
}


export default LifestyleSpendingPeriod
