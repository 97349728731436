import React from 'react';
import {formatCurrency} from "../../utils/format";
import GoalBarChartSideBar from "./GoalBarChartSidebar";
import {BarChartSummaryTable, BarChartSummaryTableItem} from "../../components/BarChartSidebar/BarChartSummaryTable";
import {NonLifestyleGoalType} from "../models/GoalType";
import {GoalBarChartKeyColors} from "./GoalBarChartKeyColors";
import {useAppSelector} from "../../store/hooks";
import {GoalModelType} from "../models/GoalModelType";
import {selectGoalModel} from "../controller/GoalsModelSlice";
import {LifestyleSpendingGoal} from "../models/LifestyleSpendingGoal";

export function buildAddNewGoalTableItems(nonLifestyleGoal: NonLifestyleGoalType): BarChartSummaryTableItem[] {
    return [{
        leftText: `${nonLifestyleGoal.userInputs.description}`,
        rightText: `${formatCurrency(nonLifestyleGoal.calculatedFields.presentValue)}`,
        isActive: false,
        keyColor: GoalBarChartKeyColors.DISCRETIONARY
    }]
}

export interface AddNewGoalBarChartSideBarProps {
    nonLifestyleGoalFromForm: NonLifestyleGoalType;
    lifestyleSpendingGoalFromForm?: LifestyleSpendingGoal;
}

function AddNewDiscretionaryGoalBarChartSideBar({nonLifestyleGoalFromForm, lifestyleSpendingGoalFromForm}: AddNewGoalBarChartSideBarProps) {
    const {discretionaryGoals} = useAppSelector<GoalModelType>(selectGoalModel)

    // If you're editing a goal, don't include the goal with a matching ID in discretionaryGoals in the calculations for nonLSPV
    const combinedDiscretionaryGoals = discretionaryGoals.filter((discretionaryGoal) => discretionaryGoal.id !== nonLifestyleGoalFromForm.id).concat(nonLifestyleGoalFromForm)


    let sumOfPresentValues = 0;
    combinedDiscretionaryGoals.forEach(goal => {
        sumOfPresentValues += goal.calculatedFields.presentValue
    })

    const leftHeader = 'Discretionary Goals'

    const goalBarChartSideBarProps: {
        discretionaryGoals: NonLifestyleGoalType[],
        lifestyleSpendingGoal?: LifestyleSpendingGoal
    } = {
        discretionaryGoals: combinedDiscretionaryGoals
    };
    if (lifestyleSpendingGoalFromForm) {
        goalBarChartSideBarProps.lifestyleSpendingGoal = lifestyleSpendingGoalFromForm;
    }

    return (
        <GoalBarChartSideBar
            {...goalBarChartSideBarProps}
        >
            <BarChartSummaryTable
                leftHeader={leftHeader}
                rightHeader={`${formatCurrency(sumOfPresentValues)}`}
                items={buildAddNewGoalTableItems(nonLifestyleGoalFromForm!)}
            />
        </GoalBarChartSideBar>
    )
}

export default AddNewDiscretionaryGoalBarChartSideBar;

