import {
    AssetRelianceResponse,
    AssetRelianceStack,
    EMPTY_ASSET_RELIANCE_RESPONSE
} from "../models/AssetRelianceResponse";
import {
    EMPTY_PROPOSED_ALLOCATION_RESPONSE,
    SimplifiedProposedAllocationResponse
} from "../AssetAllocation/ProposedAllocationResponse";

export interface HeaderTickerTabsResponse {
    assetReliance: SimplifiedAssetRelianceResponse;
    proposedAllocation: SimplifiedProposedAllocationResponse;
}

type InferTypeFromArray<T> = T extends Array<infer U> ? U : T;

export type SimplifiedAssetRelianceResponse = {
    allAssetsStack: SimplifiedAssetRelianceStack<AssetRelianceResponse['allAssetsStack']>;
    excludedAssetStacks: SimplifiedAssetRelianceStack<InferTypeFromArray<AssetRelianceResponse['excludedAssetStacks']>>[];
}

export type SimplifiedAssetRelianceStack<Stack extends AssetRelianceStack> = {
    name: Stack['name'],
    excessAssets: Stack['excessAssets'],
};

export const EMPTY_HEADER_TICKER_TABS_RESPONSE: HeaderTickerTabsResponse = {
    assetReliance: {
        allAssetsStack: {
            name: EMPTY_ASSET_RELIANCE_RESPONSE.allAssetsStack.name,
            excessAssets: EMPTY_ASSET_RELIANCE_RESPONSE.allAssetsStack.excessAssets,
        },
        excludedAssetStacks: EMPTY_ASSET_RELIANCE_RESPONSE.excludedAssetStacks,
    },
    proposedAllocation: {
        totalRiskControlAssetsPercent: EMPTY_PROPOSED_ALLOCATION_RESPONSE.totalRiskControlAssetsPercent,
        totalRiskAssetsPercent: EMPTY_PROPOSED_ALLOCATION_RESPONSE.totalRiskAssetsPercent,
    }
};