import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store/store";
import {EstateSummary} from "./models/api";

type LoadedEstateSummary = {
    estateSummary: EstateSummary,
    loaded: true
}

type UnloadedEstateSummary = {
    loaded: false
}

export type EstateSummaryState = LoadedEstateSummary | UnloadedEstateSummary;

const initialEstateSummary: EstateSummaryState = {loaded: false};

const estateSummarySlice = createSlice({
    name: "estateSummary",
    initialState: initialEstateSummary as EstateSummaryState,
    reducers: {
        setEstateSummary: (_state, action: PayloadAction<EstateSummary>) => ({
            estateSummary: action.payload,
            loaded: true
        }),
        resetEstateSummary: () =>  initialEstateSummary
    }
});

export const isLoaded = (state: EstateSummaryState): state is LoadedEstateSummary =>
    state.loaded

export const isNotLoaded = (state: EstateSummaryState): state is UnloadedEstateSummary =>
    !state.loaded

export const selectEstateSummary = (state: RootState) => state.wealthTransfer.estateSummary;

export const {setEstateSummary, resetEstateSummary} = estateSummarySlice.actions;

export const estateSummaryReducer = estateSummarySlice.reducer;