import {ReactNode} from "react";
import {DataEntrySummaryItem} from "./DataEntrySummary";

export function generateErrorableItem(label: string | ReactNode, value: string | ReactNode, hasError: boolean): DataEntrySummaryItem {
    const newVar = {
        label: label,
        value: hasError ? '––' : value,
        testId: hasError ? 'validation-testId' : undefined,
        className: hasError ? 'data-entry-summary__error' : undefined
    };
    return newVar
}