import {MeetingContainerSchema, useRelayContext} from "../ClientManagement/Meeting/Relay/types/RelayContext";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    BarChartSideBarPointerInfo,
    MeetingContentCanvas,
    MeetingContentScrollPositions,
    MultiPartMeetingContent,
    MultiPartMeetingContentDOM,
    PresenterPointerCoordinatesInfo
} from "../ClientManagement/Meeting/Meeting";
import {
    getScrollHeight,
    getScrollLeft,
    getScrollTop,
    getScrollWidth,
    setScrollLeft,
    setScrollTop
} from "../components/ScrollableContainer/ScrollableContainerUtils";
import {IValueChanged} from "fluid-framework";

import QuickPinchZoom, {make3dTransformValue} from "react-quick-pinch-zoom";
import {
    getNewPointerPositionInPixels,
    NewPointerPositionCalculationProps
} from "../ClientManagement/Meeting/utils/MeetingUtils";

export const SynchronizedMeetingContent: React.FC = () => {
    const {sharedObjects} = useRelayContext();
    const meetingContentRef = useRef<HTMLDivElement | null>(null);
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const pointerRef = useRef<HTMLDivElement | null>(null);
    const [showPointerIcon, setShowPointerIcon] = useState<boolean>(false);

    const rescaleAndResetWrapperTransform = () => {
        if (meetingContentRef?.current && wrapperRef?.current) {
            rescaleMeetingContent(meetingContentRef.current, wrapperRef.current);
            resetWrapperTransform(wrapperRef.current);
        }
    };

    const resizeObserver = useMemo(() => {
        return new ResizeObserver(() => {
            rescaleAndResetWrapperTransform();
        });
    }, [meetingContentRef, wrapperRef]);

    useEffect(() => {
        if (resizeObserver) {
            resizeObserver.observe(document.body);
        }
        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        }
    }, [resizeObserver]);

    useEffect(() => {
        if (sharedObjects) {
            sharedObjects.meetingPortalParticipantJoinDDS.set('participantJoinsFromMeetingPortal', 'join');

            let messageParts: Record<string, string[]> | null = {};
            let meetingContentCanvas: MeetingContentCanvas | null = {};
            let meetingContentScrollPositions: MeetingContentScrollPositions | null = {};

            sharedObjects.domContentDDS.on("valueChanged", (valueChanged: IValueChanged) => {
                switch (valueChanged.key) {
                    case 'meetingContentDOM':
                        if (messageParts && meetingContentCanvas && meetingContentScrollPositions) {
                            redrawMeetingContentDOM(sharedObjects, meetingContentRef, messageParts, meetingContentCanvas, meetingContentScrollPositions);
                        }
                        rescaleAndResetWrapperTransform();
                        break;
                    case 'meetingContentScrollPositions':
                        redrawMeetingContentScrollPositions(sharedObjects, meetingContentRef, (scrollPositions) => {
                            meetingContentScrollPositions = scrollPositions;
                        });
                        break;
                }
            });

            sharedObjects.canvasContentDDS.on("valueChanged", (valueChanged: IValueChanged) => {
                if (messageParts && meetingContentCanvas) {
                    redrawMeetingContentCanvas(valueChanged.key, sharedObjects, meetingContentRef, messageParts, meetingContentCanvas);
                }
            });

            sharedObjects.pointerCoordinatesInfoDDS.on("valueChanged", (valueChanged: IValueChanged) => {
                if (!showPointerIcon) {
                    setShowPointerIcon(true)
                }

                if (valueChanged.key === 'pointerCoordinatesInfo') {
                    const presenterPointerInfo: PresenterPointerCoordinatesInfo | undefined = sharedObjects.pointerCoordinatesInfoDDS.get('pointerCoordinatesInfo');

                    if (presenterPointerInfo) {
                        relayPointerCoordinatesInfo(presenterPointerInfo);
                    }
                }
            });

            return () => {
                messageParts = null;
                meetingContentCanvas = null;
                meetingContentScrollPositions = null;
            };
        }
    }, [sharedObjects, showPointerIcon]);

    const zoomComponentCallback = useCallback(({x, y, scale}) => {
        const {current: presentationPane} = wrapperRef;

        if (presentationPane) {
            presentationPane.style.setProperty("transform", make3dTransformValue({x, y, scale}));
        }
    }, []);

    const relayPointerCoordinatesInfo = (presenterPointerInfo: PresenterPointerCoordinatesInfo) => {
        if (meetingContentRef.current && wrapperRef.current && pointerRef.current) {
            const meetingContent = document.getElementById("meeting-portal-content");
            let pointerYOnBarChart = 0;
            let stackedBarChartHeight = 0;
            let presenterStackedBarChartHeight = 0;

            if (meetingContent) {
                let scrollContainer = undefined;

                if (presenterPointerInfo.isOnBarChartSidebar && presenterPointerInfo.barChartSideBarPointerInfo) {
                    if (presenterPointerInfo.barChartSideBarPointerInfo?.hoveredElementId !== 'bar-chart-sidebar-header') {
                        const stackedBarChart = document.getElementById('bar-chart-sidebar-stacked-bar-chart');

                        if (stackedBarChart) {
                            const {
                                calculatedPointerYOnBarChart,
                                clientViewStackedBarChartHeight,
                                populatedPresenterStackedBarChartHeight
                            }
                                = getCalculationParamsForBarChartAndFooter(presenterPointerInfo.barChartSideBarPointerInfo, stackedBarChart);
                            pointerYOnBarChart = calculatedPointerYOnBarChart;
                            stackedBarChartHeight = clientViewStackedBarChartHeight;
                            presenterStackedBarChartHeight = populatedPresenterStackedBarChartHeight;
                        }
                    }
                }

                if (presenterPointerInfo.scrollContainerId) {
                    scrollContainer = document.getElementById(presenterPointerInfo.scrollContainerId);
                }

                const scrollLeftDiff = scrollContainer ? presenterPointerInfo.scrollLeft - getScrollLeft(scrollContainer) : 0;
                const xPercentage = (presenterPointerInfo.x + scrollLeftDiff) / 1366;

                const scrollTopDiff = scrollContainer ? presenterPointerInfo.scrollTop - getScrollTop(scrollContainer) : 0;
                const yPercentage = (presenterPointerInfo.y + scrollTopDiff - presenterStackedBarChartHeight) / 1024

                const newPointerPositionProps: NewPointerPositionCalculationProps = {
                    boundingClientRectangle: meetingContent.getBoundingClientRect(),
                    xPercentage,
                    yPercentage,
                    pointerYOnBarChart,
                    stackedBarChartHeight
                }

                const {
                    top,
                    left
                } = getNewPointerPositionInPixels(newPointerPositionProps);

                pointerRef.current.style.top = top;
                pointerRef.current.style.left = left;
            }
        }
    }

    return (
        <QuickPinchZoom onUpdate={zoomComponentCallback} maxZoom={3} doubleTapZoomOutOnMaxScale={true}>
            <div ref={wrapperRef} style={{overflow: "hidden"}} data-testid="meetPortalContentWrapper">
                {showPointerIcon &&
                    <div className={'client-view-pointer'}
                         ref={pointerRef}>
                        <img src={'/Meeting_Pointer.svg'} aria-label={'client view pointer'} alt={''}/>
                    </div>}
                <div id={"meeting-portal-content"}
                     className="meeting-content app-viewport--in-meeting"
                     data-testid="meetingPortalContent"
                     style={{pointerEvents: "none", touchAction: "none"}}
                     ref={meetingContentRef}/>
            </div>
        </QuickPinchZoom>
    )

};

const getCalculationParamsForBarChartAndFooter = (barChartSideBarPointerInfo: BarChartSideBarPointerInfo,
                                                  stackedBarChart: HTMLElement) => {
    let calculatedPointerYOnBarChart = 0;
    let populatedPresenterStackedBarChartHeight = 0;
    let clientViewStackedBarChartHeight = 0

    if (barChartSideBarPointerInfo.hoveredElementId === 'bar-chart-sidebar-stacked-bar-chart') {
        const heightAdjustmentRatio = stackedBarChart.getBoundingClientRect().height
            / barChartSideBarPointerInfo.stackBarChartHeight;

        calculatedPointerYOnBarChart = (barChartSideBarPointerInfo.pointerYOnBarchart * heightAdjustmentRatio) +
            stackedBarChart.getBoundingClientRect().y;
    } else if (barChartSideBarPointerInfo.hoveredElementId === 'bar-chart-sidebar-footer') {
        populatedPresenterStackedBarChartHeight = barChartSideBarPointerInfo.stackBarChartHeight;
        clientViewStackedBarChartHeight = stackedBarChart.getBoundingClientRect().height;
    }

    return {calculatedPointerYOnBarChart, populatedPresenterStackedBarChartHeight, clientViewStackedBarChartHeight};
}

const redrawMeetingContentCanvas = (
    canvasQuerySelector: string,
    sharedObjects: MeetingContainerSchema,
    meetingContentRef: React.MutableRefObject<HTMLDivElement | null>,
    messageParts: Record<string, string[]>,
    meetingContentCanvas: MeetingContentCanvas
) => {
    let multiPartMeetingContent: MultiPartMeetingContent | undefined | null = sharedObjects.canvasContentDDS.get(canvasQuerySelector);
    if (multiPartMeetingContent) {
        assembleMessageParts(messageParts, multiPartMeetingContent, (assembledMessage) => {
            if (meetingContentRef.current) {
                hydrateCanvases({
                    [canvasQuerySelector]: assembledMessage
                }, meetingContentRef.current);
            }
            meetingContentCanvas[canvasQuerySelector] = assembledMessage;
        });
        multiPartMeetingContent = null; // NOSONAR
    }
}

const redrawMeetingContentDOM = (
    sharedObjects: MeetingContainerSchema,
    meetingContentRef: React.MutableRefObject<HTMLDivElement | null>,
    messageParts: Record<string, string[]>,
    meetingContentCanvas: MeetingContentCanvas,
    meetingContentScrollPositions: MeetingContentScrollPositions,
) => {
    let content: MultiPartMeetingContentDOM | undefined | null = sharedObjects.domContentDDS.get('meetingContentDOM');
    if (meetingContentRef.current && content) {
        assembleMessageParts(messageParts, content, (assembledMessage) => {
            if (meetingContentRef.current) {
                meetingContentRef.current.innerHTML = assembledMessage;
            }
        });
        content = null; // NOSONAR
    }
    if (meetingContentRef.current && meetingContentCanvas) {
        hydrateCanvases(meetingContentCanvas, meetingContentRef.current, (canvasQuerySelector) => {
            delete meetingContentCanvas[canvasQuerySelector];
        });
    }
    if (meetingContentRef.current && meetingContentScrollPositions) {
        updateScrollPositions(meetingContentScrollPositions, meetingContentRef.current);
    }
}

const redrawMeetingContentScrollPositions = (
    sharedObjects: MeetingContainerSchema,
    meetingContentRef: React.MutableRefObject<HTMLDivElement | null>,
    setMeetingContentScrollPositions: (scrollPositions: MeetingContentScrollPositions) => void,
) => {
    let scrollPositions: MeetingContentScrollPositions | undefined | null = sharedObjects.domContentDDS.get('meetingContentScrollPositions');
    if (meetingContentRef.current && scrollPositions) {
        updateScrollPositions(scrollPositions, meetingContentRef.current);
        setMeetingContentScrollPositions(scrollPositions);
        scrollPositions = null; // NOSONAR
    }
};

const assembleMessageParts = (
    messageParts: Record<string, string[]>,
    multiPartMeetingContent: MultiPartMeetingContent,
    assembledMessageCallback: (assembledMessage: string) => void
) => {
    let parts: string[] | null = messageParts[multiPartMeetingContent.messageId]
        || new Array(multiPartMeetingContent.totalParts).fill(undefined);
    parts[multiPartMeetingContent.part] = multiPartMeetingContent.content;
    // Check if the totalParts are present
    if (parts.every((part) => typeof part !== 'undefined')) {
        // Combine the parts then reset messageParts
        assembledMessageCallback(parts.join(''));
        delete messageParts[multiPartMeetingContent.messageId];
    } else {
        messageParts[multiPartMeetingContent.messageId] = parts;
    }
    parts = null; // NOSONAR
};

const hydrateCanvases = (
    meetingContentCanvas: MeetingContentCanvas,
    meetingContentElement: HTMLDivElement,
    handleCanvasNotFound?: (canvasQuerySelector: string) => void
) => {
    if (meetingContentCanvas) {
        for (let [querySelector, canvasDataURL] of Object.entries(meetingContentCanvas)) {
            let canvasElement: HTMLCanvasElement | null = meetingContentElement.querySelector(`.meeting-content ${querySelector}`);
            if (canvasElement) {
                let canvasContext = canvasElement.getContext('2d');
                if (canvasContext) {
                    let canvasImage: HTMLImageElement | null = new Image();
                    canvasImage.onload = () => {
                        if (canvasElement && canvasContext && canvasImage) {
                            canvasContext.clearRect(0, 0, canvasElement.width, canvasElement.height);
                            canvasContext.drawImage(canvasImage, 0, 0);
                        }
                        canvasElement = null;
                        canvasContext = null;
                        canvasImage = null;
                    };
                    canvasImage.onerror = () => {
                        canvasElement = null;
                        canvasContext = null;
                        canvasImage = null;
                    }
                    canvasImage.src = canvasDataURL;
                }
            } else if (handleCanvasNotFound) {
                handleCanvasNotFound(querySelector);
            }
        }
    }
};

const updateScrollPositions = (
    meetingContentScrollPositions: MeetingContentScrollPositions,
    meetingContentElement: HTMLDivElement,
) => {
    if (meetingContentScrollPositions) {
        for (let [querySelector, scrollState] of Object.entries(meetingContentScrollPositions)) {
            let scrollContainer: HTMLCanvasElement | null = meetingContentElement.querySelector(`.meeting-content ${querySelector}`);
            if (scrollContainer) {
                setScrollLeft(scrollContainer, scrollState.horizontalScrollPercentage * getScrollWidth(scrollContainer));
                setScrollTop(scrollContainer, scrollState.verticalScrollPercentage * getScrollHeight(scrollContainer));
            }
            scrollContainer = null;
        }
    }
};

const rescaleMeetingContent = (meetingContentRefCurrent: HTMLDivElement, wrapper: HTMLDivElement) => {

    const windowSize = {
        width: window.innerWidth,
        height: window.innerHeight
    }

    const presentationWidth = 1366;
    const presentationHeight = 1024;

    const finalHeight = windowSize.height;
    const finalWidth = windowSize.width;

    const windowHeightRatio = finalHeight / presentationHeight;
    const windowWidthRatio = finalWidth / presentationWidth;

    const scale = Math.min(
        windowHeightRatio,
        windowWidthRatio
    );


    const transform = {
        scale: scale,
        widthPixelShift: (presentationWidth - finalWidth) / 2,
        heightPixelShift: (presentationHeight - finalHeight) / 2,
    }

    meetingContentRefCurrent.style.transform = `translate(${-transform.widthPixelShift}px,${-transform.heightPixelShift}px) scale(${transform.scale})`;
    wrapper.style.width = finalWidth + "px";
    wrapper.style.height = finalHeight + "px";
}

const resetWrapperTransform = (ref: HTMLElement | null) => {
    if (!ref) return;
    ref.style.transform = "";
}
