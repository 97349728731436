import {SaveAction} from "../FamilyTreeSlice";
import {FamilyMode} from "./FamilyMode";


export const getAddOrEditHeaderTitle = (saveAction: SaveAction, familyMode: FamilyMode, fullName: string) => {
    switch (familyMode) {
        case FamilyMode.IMMEDIATE:
        case FamilyMode.EXTENDED:
            return saveAction === SaveAction.Create ? "Add Family Member" : `Edit ${fullName}`;
        case FamilyMode.OTHER:
            return saveAction === SaveAction.Create ? "Add Other Member" : `Edit ${fullName}` ;
    }
}