import React, {ReactNode} from "react";
import {Icon} from "../Icon";
import classNames from "classnames";

type EmptyStateContainerProps = {
    icon?: string,
    title: ReactNode,
    description?: ReactNode,
    ActionsComponent?: ReactNode,
    size?: 'large' | 'small',
    className?: string,
    hideBorder?: boolean
};

const EmptyStateContainer = ({ icon, title, description, ActionsComponent, size = 'large', className, hideBorder = false }: EmptyStateContainerProps) => {
    return (
        <div
            className={classNames(
                'empty-state-container',
                { [`empty-state-container--${size}`]: size },
                { [`empty-state-container--hide-border`]: hideBorder },
                className
            )}
        >
            <div className="empty-state-container__content">
                {icon && (
                    <div
                        className={classNames('empty-state-container__icon', {
                            'marginbottom-lg': title || description || ActionsComponent,
                        })}
                    >
                        <Icon name={icon} />
                    </div>
                )}
                {title && (
                    <div
                        className={classNames('empty-state-container__title', {
                            'marginbottom-lg': description || ActionsComponent,
                        })}
                    >
                        {title}
                    </div>
                )}
                {description && (
                    <div
                        className={classNames('empty-state-container__description', {
                            'marginbottom-lg': ActionsComponent,
                        })}
                    >
                        {description}
                    </div>
                )}
                {ActionsComponent && (
                    <div className="empty-state-container__actions">
                        {ActionsComponent}
                    </div>
                )}
            </div>
        </div>
    );
}

export default EmptyStateContainer;