import {AdvisorDocumentMenus} from "./models/AdviceLibrary";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store/store";
import {DEFAULT_MENU_STRUCTURE_RESPONSE} from "./ResourcesConstant";

export interface MenuStructureState {
    adviceLibrarySubMenus: AdvisorDocumentMenus;
    practiceDocumentsSubMenus: AdvisorDocumentMenus;
}

const initialState: MenuStructureState = {
    adviceLibrarySubMenus: DEFAULT_MENU_STRUCTURE_RESPONSE,
    practiceDocumentsSubMenus: DEFAULT_MENU_STRUCTURE_RESPONSE
};

export const menuStructureSlice = createSlice({
    name: 'menuStructure',
    initialState,
    reducers: {
        setAdviceLibraryMenu: (state, action: PayloadAction<AdvisorDocumentMenus>) => {state.adviceLibrarySubMenus = action.payload;},
        setPracticeDocumentsMenu: (state, action: PayloadAction<AdvisorDocumentMenus>) => {state.practiceDocumentsSubMenus = action.payload;}
    }
});

export const {setAdviceLibraryMenu, setPracticeDocumentsMenu} = menuStructureSlice.actions;
export const selectAdviceLibrarySubMenus = (state: RootState) => state.menuStructure.adviceLibrarySubMenus;
export const selectPracticeDocumentsSubMenus = (state: RootState) => state.menuStructure.practiceDocumentsSubMenus;
export default menuStructureSlice.reducer;