import {PortfolioReserveResponse} from "../../models/PortfolioReserve";
import {Button, PageActionMenu, Icon, RadioGroup, DropdownItem, Dropdown} from "../../../components";
import PortfolioRisk from "../PortfolioRisk";
import React, {ChangeEvent, useState} from "react";
import {AssetTotals} from "../../../Assets/models/Assets";
import {DropdownGroup} from "xps-react";
import {NO_OP} from "../../../constants/common";
import {
    selectPortfolioReserveButtonStates,
    setPortfolioReserveButtonStates,
    setPortfolioReserveOptions, selectPortfolioReserveOptions, selectMarketStress
} from "../PortfolioReserveSlice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {MenuDivider} from "xps-react";
import {selectReleaseToggles} from "../../../ReleaseToggles/releaseTogglesSlice";

type HowRiskyRowProps = {
    portfolioRiskRowActive: number | undefined | boolean,
    onClickHowRiskInfoTip: () => void,
    editPortfolioReserve: PortfolioReserveResponse,
    assets: AssetTotals
}

export function HowRiskyRow({
                                portfolioRiskRowActive,
                                onClickHowRiskInfoTip,
                                editPortfolioReserve,
                                assets
                            }: HowRiskyRowProps) {

    const dispatch = useAppDispatch();
    const portfolioReserveOptions = useAppSelector(selectPortfolioReserveOptions);
    const buttonState = useAppSelector(selectPortfolioReserveButtonStates)
    const reserveActionMenuState = useAppSelector(selectPortfolioReserveOptions)
    const marketStressEvents = useAppSelector(selectMarketStress)

    let initialMarketStressOption = "Conditional Value at Risk"
    switch (reserveActionMenuState.marketStressTest) {
        case "Historical Market Events":
            initialMarketStressOption = "Historical Market Events"
            break;
    }
    const [selectedMarketStressOption,setSelectedMarketStressOption] = useState(initialMarketStressOption)
    const handleReserveOptionDropdown = (_e: ChangeEvent<HTMLInputElement>) => {
        let selectedPortfolioReserveOptions = {...portfolioReserveOptions}
        selectedPortfolioReserveOptions.showRiskImpact = !selectedPortfolioReserveOptions.showRiskImpact;

        dispatch(setPortfolioReserveOptions(selectedPortfolioReserveOptions));
    }
    const handleMarketStressOptions = (_e: ChangeEvent<HTMLInputElement>) => {

        const {value} = _e.target;
        setSelectedMarketStressOption(value)
        let selectedPortfolioReserveOptions = {...reserveActionMenuState}
        switch (value) {
            case "Conditional Value at Risk":
                selectedPortfolioReserveOptions.marketStressTest = "Conditional Value at Risk"
                break;
            case "Historical Market Events":
                selectedPortfolioReserveOptions.marketStressTest = "Historical Market Events"
                break;
            default:
                selectedPortfolioReserveOptions.marketStressTest = "Conditional Value at Risk"
                break;
        }
        dispatch(setPortfolioReserveOptions(selectedPortfolioReserveOptions));

    }

    const handleHideButton = () => {
         dispatch(setPortfolioReserveButtonStates({
             ...buttonState,
             portfolioRisk: false
         }))
    };
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const marketStressOptions = releaseToggles?.enableMarketStress ?  ["Conditional Value at Risk", "Historical Market Events"] : ["Conditional Value at Risk"]
    const disableShowImpact = selectedMarketStressOption === "Historical Market Events";

    const investableAssetsMinusCash = assets.totalInvestableValue - editPortfolioReserve.targetCash;

    const stressEventDropdownItems = marketStressEvents.marketStressResponses
            .map((event) => {
                return (<DropdownItem key={""} className="" value={""} itemText={event.name.toString()}
                                      onClick={NO_OP}>
                </DropdownItem>)
            })

    return <>
        <div className="edit-portfolio-reserve-page__table__column">
            <div style={{display: "flex"}}>
                <div
                    style={{width: "95%"}}
                    data-testid="cvar-quick-slide-container"
                    className={`edit-portfolio-reserve-page__table__column__content ${portfolioRiskRowActive ? "" : "row-inactive"}`}>
                    How risky is this asset allocation?
                    {(portfolioReserveOptions.marketStressTest == "Conditional Value at Risk") &&
                        <div className="edit-portfolio-reserve-page__table__column__subtext">
                            <span>Average of worst 5% of expected returns</span>
                            <Button
                                icon="only"
                                iconName="info_outline"
                                kind="borderless"
                                onClick={onClickHowRiskInfoTip}
                                size="small"
                            />
                        </div>
                    }
                </div>
                <PageActionMenu data-testid="market-stress-page-action" className={"pr-page-action-menu"}
                                panelWidth={260}>
                    <DropdownGroup groupName="Market Stress Tests" key={"ls-dropdown-group"} show={true}>
                        <DropdownItem className="reserve-page-action-menu-radio-options" value="" itemText="">
                            <RadioGroup
                                name=''
                                data-testid={"monte-carlo-options"}
                                layout="vertical"
                                values={marketStressOptions}
                                selected={selectedMarketStressOption}
                                onChange={handleMarketStressOptions}
                                disabled={false}
                            />
                        </DropdownItem>
                    </DropdownGroup>
                        <DropdownItem className="reserve-page-action-menu-options" value="" itemText="" onClick={NO_OP} disabled={disableShowImpact} style={{marginTop:'7px'}}>
                            Show Impact on Investable Portfolio
                            <input
                                id="showRiskImpact"
                                name="show-risk-impact"
                                disabled={disableShowImpact}
                                type="checkbox"
                                aria-label="show-risk-impact"
                                key={"show-risk-impact"}
                                style={{marginLeft: '10px'}}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    handleReserveOptionDropdown(e)
                                }}
                                checked={portfolioReserveOptions.showRiskImpact && selectedMarketStressOption === "Conditional Value at Risk" }
                            />
                        </DropdownItem>
                        {(portfolioRiskRowActive && selectedMarketStressOption === "Conditional Value at Risk") &&
                        <MenuDivider
                            className="menu-divider"
                            role="separator"
                            itemText=""
                            value="do_not_use"
                            style={{
                                padding: "0",
                                margin: "8px -16px",
                                borderBottom: "1px solid lightgrey"
                            }}
                        />}
                        {portfolioRiskRowActive &&
                            <DropdownItem value="" itemText="" className="reserve-page-action-menu-options" onClick={handleHideButton} data-testid="hide-risk-button">
                                Hide this Section
                                <Icon ariaHidden  name="visibility_off_outline" style={{marginLeft: '115px'}} />
                            </DropdownItem>
                        }
                </PageActionMenu>
            </div>
            { (portfolioReserveOptions.marketStressTest == "Historical Market Events") &&
                <>
                    <div style={{marginTop: '10px', fontWeight: 500}}>Historical Market Stress Event</div>
                    <div style={{marginTop: '10px'}}>
                        <Dropdown
                            className={"add-goal-dropdown-menu dropdown--rounded"}
                            size="small"
                            buttonIcon="right"
                            buttonKind="secondary"
                            drop="down"
                            dropdownKind="menu"
                            defaultText="SELECT AN EVENT"
                            iconNameOpen="arrow_drop_down"
                            iconNameClose="arrow_drop_down"
                            panelWidth={"auto"}
                            rounded={true}
                            aria-label="Select-Historical-Event"
                        >
                            {stressEventDropdownItems}
                        </Dropdown>
                    </div>
                </>
            }
        </div>
        <div
            className={`edit-portfolio-reserve-page__table__column edit-portfolio-reserve-page__table__second-column edit-portfolio-reserve-page__centered`}>
            <PortfolioRisk
                cvar={editPortfolioReserve.cvar.cvarPortfolioSupports}
                totalInvestableAssetsMinusCash={investableAssetsMinusCash}
                portfolioRiskRowActive={portfolioRiskRowActive}
            />
        </div>
        <div
            className={`edit-portfolio-reserve-page__table__column edit-portfolio-reserve-page__table__third-column edit-portfolio-reserve-page__centered`}>
            <PortfolioRisk
                cvar={editPortfolioReserve.cvar.cvarMinimumRisk}
                totalInvestableAssetsMinusCash={investableAssetsMinusCash}
                portfolioRiskRowActive={portfolioRiskRowActive}
            />
        </div>
    </>;
}