import classNames from 'classnames';
import {ChangeEventHandler, FocusEventHandler, KeyboardEventHandler, ReactNode} from 'react';
import {NO_OP} from '../../constants/common';
import {Input as XpsInput} from 'xps-react';

type InputProps = {
    id?: string,
    name?: string,
    className?: string,
    label?: string,
    onChange?: ChangeEventHandler<HTMLInputElement>,
    onChangeValue?: ChangeEventHandler,
    onBlur?: FocusEventHandler,
    onFocus?: FocusEventHandler,
    error?: string,
    lblRequired?: boolean,
    maxLength?: number,
    removeMarginTop?: boolean,
    size?: 'small' | 'medium' | 'large',
    type?: string,
    value?: string | number,
    inlineLabel?: string | ReactNode,
    inlineLabelAlign?: 'left' | 'right',
    readOnly?: boolean,
    disabled?: boolean,
    borderless?: boolean,
    placeholder?: string,
    onKeyDown?: KeyboardEventHandler,
    forceShowErrors?: boolean,
    textAlign?: 'left' | 'right',
    autoFocus?: boolean
};

const Input = ({
    id,
    name,
    className,
    onChange = NO_OP,
    onChangeValue = NO_OP,
    onBlur = NO_OP,
    onFocus = NO_OP,
    label,
    lblRequired = false,
    error,
    maxLength,
    removeMarginTop = false,
    size = 'small',
    type = 'text',
    value,
    inlineLabel = '',
    inlineLabelAlign = 'left',
    readOnly = false,
    disabled = false,
    borderless = false,
    forceShowErrors = false,
    textAlign = 'left',
    ...rest
}: InputProps) => {
    return (
        <div
            className={classNames(
            'input-wrapper',
            { 'input-skin--borderless': borderless },
            { [`input-inline-align--${inlineLabelAlign}`]: inlineLabel },
            textAlign === 'right' && 'input-text-align-right',
            className
            )}
        >
            {inlineLabel && (
            <span className={classNames('input-inline-label', { [`input--${size}`]: size })}>
                <b>{inlineLabel}</b>
            </span>
            )}
            <XpsInput
                id={id}
                name={name}
                label={label}
                onChange={onChange}
                onChangeValue={onChangeValue}
                removeMarginTop={removeMarginTop}
                size={size}
                type={type}
                value={value}
                onBlur={onBlur}
                onFocus={onFocus}
                error={error}
                maxlength={maxLength}
                lblRequired={lblRequired}
                readOnly={readOnly}
                disabled={disabled}
                inlineLael={inlineLabel}
                forceShowErrors={forceShowErrors}
                {...rest}
            />
        </div>
    );
};

export default Input;
