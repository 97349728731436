import React from "react";
import RiskLegend, {riskLegendColor} from "../../components/Legend/RiskLegend";
import {roundPercentagesToHundred} from "../../utils/roundPercentagesToHundred";

interface CurrentVsProposedLegendProps {
    totalRiskAssetsPercent: number;
    totalRiskControlAssetsPercent: number;
}

const CurrentVsProposedLegend: React.FC<CurrentVsProposedLegendProps> = ({
                                                                             totalRiskAssetsPercent,
                                                                             totalRiskControlAssetsPercent
                                                                         }) => {
    const {
        percentage1: roundedRiskAssetsPercent,
        percentage2: roundedRiskControlAssetsPercent
    } = roundPercentagesToHundred(totalRiskAssetsPercent, totalRiskControlAssetsPercent);

    const totalRiskAssetLabel = `RA ${roundedRiskAssetsPercent}%`;
    const totalRiskControlLabel = `RCA ${roundedRiskControlAssetsPercent}%`;
    return (
        <div className="current-proposed-legend">
            <RiskLegend legendColor={riskLegendColor.RISK_ASSETS} label={totalRiskAssetLabel}/>
            <RiskLegend legendColor={riskLegendColor.RISK_CONTROL_ASSETS} label={totalRiskControlLabel}/>
        </div>
    );
}

export default CurrentVsProposedLegend;