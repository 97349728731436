import {AssetsSummary} from "../../../Assets/models/Assets";
import {LegalEntityTypeSummary, NonClientMemberSummary} from "../../../Assets/mappers";
import {GroupedTableData, GroupedTableItem} from "../../../components/Table/GroupedTable";

export type InAndOutEstateAssets = {
    assetsSummary: AssetsSummary | undefined,
    legalEntitySummary: LegalEntityTypeSummary[] | undefined,
    nonClientMemberSummary: NonClientMemberSummary[] | undefined
}
export const PAGE_CONSTANTS = {
    TOTAL_PAGE_HEIGHT: 1055,
    PAGE_PADDING_HEIGHT: 108,
    SECTION_HEADER_HEIGHT: 100,
    ACCORDION_HEADER_HEIGHT: 72,
    TABLE_HEADER_HEIGHT: 36,
    TABLE_ROW_HEIGHT: 60,
    TABLE_ROW_HEIGHT_FOR_CURRENT_NW: 48,
    TABLE_ROW_HEIGHT_FOR_LEGAL: 48,
    ASSET_LIABILITY_BOTTOM_MARGIN: 91,
    ASSET_LIABILITY_BOTTOM_MARGIN_FOR_CURRENT_NW: 71,
    TABLE_OFFSET:85
}

export const  hasOutOfEstateLegalEntities=(clientAssetsPage: InAndOutEstateAssets) =>{
    if (!clientAssetsPage || !clientAssetsPage.legalEntitySummary) {
        return false;
    }
    return clientAssetsPage.legalEntitySummary.length > 0;
}

export const  hasOutOfEstateNonClientSummaries=(clientAssetsPage: InAndOutEstateAssets)=> {
    if (!clientAssetsPage || !clientAssetsPage.nonClientMemberSummary) {
        return false;
    }
    return (clientAssetsPage.nonClientMemberSummary.length > 0);
}

export const hasSufficientSpace = (space:number):boolean=> space >(100+ PAGE_CONSTANTS.SECTION_HEADER_HEIGHT+PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT+PAGE_CONSTANTS.TABLE_HEADER_HEIGHT+PAGE_CONSTANTS.PAGE_PADDING_HEIGHT) ;

type DataAndSpace={legalEntityTypeSummariesArray:Array<LegalEntityTypeSummary[]>, remainingSpaceAfterLegalEntities:number}

export const splitOutOfEstateLegalEntities = (legalEntityTypeSummaries: LegalEntityTypeSummary[], remainingSpace: number) :DataAndSpace=> {
    if (legalEntityTypeSummaries.length === 0) return {legalEntityTypeSummariesArray:[], remainingSpaceAfterLegalEntities:0};

    let totalPageHeight:number = !hasSufficientSpace(remainingSpace) ? PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT  : (remainingSpace-PAGE_CONSTANTS.SECTION_HEADER_HEIGHT);
    let usedPageHeight: number = 0;
    let pages: Array<LegalEntityTypeSummary[]> = [];
    let dataForAPage: LegalEntityTypeSummary[] = [];
    legalEntityTypeSummaries.forEach((accordionLevelData) => {
        usedPageHeight += PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT + PAGE_CONSTANTS.PAGE_PADDING_HEIGHT ;
        let accordionDataForAPage: LegalEntityTypeSummary = JSON.parse(JSON.stringify(accordionLevelData));
        accordionDataForAPage.entities = [];
        let copyOfGroupedData: GroupedTableData;
        accordionLevelData.entities.forEach((groupLevelData: GroupedTableData) => {
            usedPageHeight += PAGE_CONSTANTS.TABLE_ROW_HEIGHT_FOR_LEGAL ;

            copyOfGroupedData = JSON.parse(JSON.stringify(groupLevelData));

            copyOfGroupedData.items = [];
            if(groupLevelData.items.length===0) {
                accordionDataForAPage.entities.push(JSON.parse(JSON.stringify(copyOfGroupedData)));
                usedPageHeight += PAGE_CONSTANTS.TABLE_ROW_HEIGHT_FOR_LEGAL;
            } else {
                groupLevelData.items.forEach((item: GroupedTableItem, itemIndex: number) => {
                    usedPageHeight += PAGE_CONSTANTS.TABLE_ROW_HEIGHT_FOR_LEGAL + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;

                    copyOfGroupedData.items.push(item);
                    if (usedPageHeight > totalPageHeight) {
                        accordionDataForAPage.entities.push(JSON.parse(JSON.stringify(copyOfGroupedData)));

                        dataForAPage.push(JSON.parse(JSON.stringify(accordionDataForAPage)));

                        pages.push(dataForAPage);
                        usedPageHeight = PAGE_CONSTANTS.PAGE_PADDING_HEIGHT;
                        dataForAPage = [];
                        accordionDataForAPage.entities = [];
                        totalPageHeight = PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT;
                        copyOfGroupedData.items = [];

                    } else if (itemIndex === groupLevelData.items.length - 1) {
                        accordionDataForAPage.entities.push(JSON.parse(JSON.stringify(copyOfGroupedData)));
                    }
                })
            }

        })
        if (accordionDataForAPage.entities.length > 0) dataForAPage.push(JSON.parse(JSON.stringify(accordionDataForAPage)));

    })
    if (dataForAPage.length > 0) pages.push(dataForAPage);
    return {legalEntityTypeSummariesArray:pages, remainingSpaceAfterLegalEntities:PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT-usedPageHeight};
}

export const splitNonClientMemberSummaries = (nonClientMemberSummaries: NonClientMemberSummary[], remainingSpace:number) => {
    if (nonClientMemberSummaries.length === 0) return [];
    let totalPAgeHeight = hasSufficientSpace(remainingSpace)?remainingSpace:PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT ;

    let usedPageHeight: number = 0;
    let pages: Array<NonClientMemberSummary[]> = [];
    let dataForAPage: NonClientMemberSummary[] = [];
    nonClientMemberSummaries.forEach((accordionLevelData) => {
        usedPageHeight += PAGE_CONSTANTS.ACCORDION_HEADER_HEIGHT + PAGE_CONSTANTS.TABLE_HEADER_HEIGHT;
        let accordionDataForAPage: NonClientMemberSummary = JSON.parse(JSON.stringify(accordionLevelData));
        accordionDataForAPage.assets = [];
        let copyOfGroupedData: GroupedTableData;
        accordionLevelData.assets.forEach((groupLevelData: GroupedTableData) => {
            usedPageHeight += PAGE_CONSTANTS.SECTION_HEADER_HEIGHT;
            copyOfGroupedData = JSON.parse(JSON.stringify(groupLevelData));
            copyOfGroupedData.items = [];

            groupLevelData.items.forEach((item: GroupedTableItem, itemIndex: number) => {
                usedPageHeight += PAGE_CONSTANTS.TABLE_ROW_HEIGHT_FOR_LEGAL;
                copyOfGroupedData.items.push(item);
                if (usedPageHeight > totalPAgeHeight) {
                    accordionDataForAPage.assets.push(JSON.parse(JSON.stringify(copyOfGroupedData)));
                    dataForAPage.push(JSON.parse(JSON.stringify(accordionDataForAPage)));
                    pages.push(dataForAPage);
                    usedPageHeight = 0;
                    dataForAPage = [];
                    accordionDataForAPage.assets = [];
                    totalPAgeHeight = PAGE_CONSTANTS.TOTAL_PAGE_HEIGHT;
                    copyOfGroupedData.items = [];
                } else if (itemIndex === groupLevelData.items.length - 1) {
                    accordionDataForAPage.assets.push(JSON.parse(JSON.stringify(copyOfGroupedData)));
                }
            })
        })
        if (accordionDataForAPage.assets.length > 0) dataForAPage.push(JSON.parse(JSON.stringify(accordionDataForAPage)));
    })

    if (dataForAPage.length > 0) pages.push(dataForAPage);
    return pages;
}


export const mergeAssetSummariesAndLegalEntitiesPages = (assetsPages: InAndOutEstateAssets[], legalEntitiesPages: InAndOutEstateAssets[],allAssetsPages: InAndOutEstateAssets[]) => {
    let lastPageOfAssetSummaries = assetsPages[assetsPages.length - 1];
    let firstPageOfLegalEntities = legalEntitiesPages[0];
    lastPageOfAssetSummaries.legalEntitySummary = firstPageOfLegalEntities.legalEntitySummary;
    firstPageOfLegalEntities.legalEntitySummary = undefined;
    allAssetsPages.splice(assetsPages.length,1);
}

export const  mergeLegalEntitiesAndNonClientPages = (legalEntitySummariesPages: InAndOutEstateAssets[], nonClientMemberSummariesPages: InAndOutEstateAssets[],allAssetsPages: InAndOutEstateAssets[],assetSummariesPagesLength:number) => {
    let lastPageOfLegalEntities = legalEntitySummariesPages[legalEntitySummariesPages.length - 1];
    let firstPageOfNonClientSummaries = nonClientMemberSummariesPages[0];
    lastPageOfLegalEntities.nonClientMemberSummary = firstPageOfNonClientSummaries.nonClientMemberSummary;
    firstPageOfNonClientSummaries.nonClientMemberSummary = undefined;
    allAssetsPages.splice(assetSummariesPagesLength+legalEntitySummariesPages.length,1);
}

export const getCaption = (isDetailed: boolean, allAccount: any, datum: any)=> {
    if(!isDetailed) return "";
    else return JSON.stringify(allAccount) === JSON.stringify(datum) ? "" : "continued";
}