import {formatCurrency} from "../../../utils/format";
import {ColumnCounter, TableCell, TableDisplay, TableRow} from "../../../components";
import {getAssetColor} from "../../../utils/colorPicker";
import {AssetRelianceAssetType, AssetRelianceStack} from "../../../ClientManagement/models/AssetRelianceResponse";
import {getAssetIdentity} from "../../../ClientManagement/AssetReliance/AssetRelianceUtil";

const hasStackAssetType = (stack_: AssetRelianceStack): stack_ is Omit<AssetRelianceStack, 'stackAssetType'>
    & { stackAssetType: AssetRelianceAssetType } => {
    return stack_.stackAssetType !== null && stack_.stackAssetType !== AssetRelianceAssetType.INVESTABLE_PORTFOLIO_ASSET;
}

export interface AssetTableRow<ValueType> extends TableRow<ValueType> {
    isLiability_: boolean;
}

export class AssetTableDisplayReport extends TableDisplay<number, AssetTableRow<number>> {
    private collateralToLiabilitiesMap: Map<string, number> = new Map<string, number>();

    private static isLiability_(assetType_: AssetRelianceAssetType): boolean {
        return assetType_ === AssetRelianceAssetType.PERSONAL_LIABILITY;
    }

    private static isSocialSecurity_(assetType_: AssetRelianceAssetType): boolean {
        return assetType_ === AssetRelianceAssetType.SOCIAL_SECURITY;
    }

    private static isInvestablePortfolioStack_(stackAssetType_: AssetRelianceAssetType | null): boolean {
        return stackAssetType_ === AssetRelianceAssetType.INVESTABLE_PORTFOLIO_ASSET;
    }

    private static isInvestablePortfolioAsset(assetType_: AssetRelianceAssetType): boolean {
        return assetType_ === AssetRelianceAssetType.RISK_CONTROL ||
            assetType_ === AssetRelianceAssetType.LIQUID_RISK_ASSET ||
            assetType_ === AssetRelianceAssetType.SEMI_LIQUID_RISK_ASSET ||
            assetType_ === AssetRelianceAssetType.IL_LIQUID_RISK_ASSET ||
            assetType_ === AssetRelianceAssetType.CONCENTRATED_INVESTMENTS ||
            assetType_ === AssetRelianceAssetType.OTHER_ASSET;
    }

    private constructLiabilityMap(rowStacks_: AssetRelianceStack[]) {
        rowStacks_.filter(row_ => row_.stackAssetType === AssetRelianceAssetType.PERSONAL_LIABILITY)
            .forEach(row_ => {
                const collateralIdOfRow_ = row_.collateral?.id!;
                if (collateralIdOfRow_ === null) {
                    return;
                }

                const liabilityAmount_ = this.collateralToLiabilitiesMap.get(collateralIdOfRow_) ?? 0;
                this.collateralToLiabilitiesMap.set(collateralIdOfRow_, liabilityAmount_ + Number(row_.presentValue));
            })
    }

    constructor(
        columnCounter: ColumnCounter,
        protected readonly rowStacks_: AssetRelianceStack[],
        protected readonly columnStacks_: AssetRelianceStack[],
        protected readonly originalRowStacks_: AssetRelianceStack[],
        protected readonly isAssetContinued_ : boolean
    ) {
        super(columnCounter, (value_) => formatCurrency(value_));
        this.constructLiabilityMap(rowStacks_);
    }

    get rows(): AssetTableRow<number>[] {
        return this.rowStacks_.filter(hasStackAssetType).map((stack_): AssetTableRow<number> => {
            const isLiability_ = AssetTableDisplayReport.isLiability_(stack_.stackAssetType);

            const getValues_ = () => {
                if (isLiability_){
                    return this.generateLiabilityRow(stack_);
                }
                return this.generateColumnValues(Number(stack_.presentValue), stack_.investableValue, (stack_.stackSequenceNumber - 1), stack_.stackAssetType);
            }

            return {
                accentColor: getAssetColor(stack_.stackAssetType),
                uniqueIdentifier: AssetTableDisplayReport.getStackIdentity(stack_),
                label: stack_.description,
                labelSuffix: AssetTableDisplayReport.isSocialSecurity_(stack_.stackAssetType) ? "(Social Security)" : undefined,
                values: getValues_(),
                isLiability_,
                children: stack_.assets.map((assetSubclass_): AssetTableRow<number> => ({
                    accentColor: getAssetColor(stack_.stackAssetType),
                    uniqueIdentifier: getAssetIdentity(stack_.stackAssetType, assetSubclass_.assetId),
                    label: assetSubclass_.description,
                    values: this.generateColumnValues(assetSubclass_.presentValue, assetSubclass_.investableValue, (stack_.stackSequenceNumber - 1), stack_.stackAssetType),
                    isLiability_,
                    children: [],
                }))
            };
        });
    }

    private static getStackIdentity (stack_: AssetRelianceStack): string {
        return stack_ && (stack_.stackAssetType ? String(stack_.stackAssetType) : '')
            + (stack_.stackAssetId ? '_' + String(stack_.stackAssetId) : '');
    }

    protected get headerLabel(): string {
        let headerLabel = "Assets" ;
        if(this.isAssetContinued_)
        {
            headerLabel = headerLabel + " (continued)";
        }
        return headerLabel;
    }

    protected get headerValues(): TableCell<number>[] {
        const headerValues_: number[] = Array(this.columnCount).fill(0);
        if (this.columnCount > 0) {
            let previousNetAssets_ = Number(this.columnStacks_[0].presentValue);
            headerValues_[0] = previousNetAssets_;
            for (let index_ = 1; index_ < this.columnCount; index_++) {
                const stack_ = this.columnStacks_[index_];
                if (stack_) {
                    if (stack_.stackAssetType === AssetRelianceAssetType.INVESTABLE_PORTFOLIO_ASSET) {
                        headerValues_[index_] = stack_.investableValue;
                    } else {
                        previousNetAssets_ -= Number(stack_.presentValue) + (this.collateralToLiabilitiesMap.get(stack_.stackAssetId!) ?? 0);
                        headerValues_[index_] = previousNetAssets_;
                    }
                }
            }
        }
        return headerValues_.map((value) => this.createTableCell({value}));
    }

    private generateColumnValues(
        columnValue_: number,
        investableValue_: number,
        rowIndex_: number,
        rowAssetType_: AssetRelianceAssetType,
    ): TableCell<number>[] {
        const columnValues_ = Array(this.columnCount).fill(columnValue_);

        for (let columnIndex_ = 0; columnIndex_ < this.columnCount; columnIndex_++) {
            const columnStack_ = this.columnStacks_[columnIndex_];
            const columnStackSequenceNumber_ = columnStack_?.stackSequenceNumber ?? 0;
            const isColumnInvestablePortfolioStack_ = AssetTableDisplayReport.isInvestablePortfolioStack_(columnStack_?.stackAssetType);
            const isRowInvestablePortfolioAsset_ = AssetTableDisplayReport.isInvestablePortfolioAsset(rowAssetType_);
            const isRowAssetExcluded_ = rowIndex_ < columnStackSequenceNumber_;

            if (isColumnInvestablePortfolioStack_ && isRowInvestablePortfolioAsset_ && investableValue_) {
                columnValues_[columnIndex_] = investableValue_;
            } else if (isRowAssetExcluded_) {
                columnValues_[columnIndex_] = null;
            }
        }
        return columnValues_.map(value => this.createTableCell({value}));
    }

    private generateLiabilityRow(
        stack_: Omit<AssetRelianceStack, "stackAssetType"> & { stackAssetType: AssetRelianceAssetType }): TableCell<number>[] {

        const columnValues_ = Array(this.columnCount).fill(stack_.presentValue);

        const summaryColumnCount_ = 1;
        const rowIndexOfCollateralAsset_ = this.originalRowStacks_.findIndex(rowStack => stack_.collateral?.id === rowStack.stackAssetId);
        const columnIndexOfCollateralAsset_ = this.columnStacks_.findIndex(columnStack => stack_.collateral?.id === columnStack.stackAssetId);
        const columnsBeforeDebtCollateral_ = this.originalRowStacks_
            .filter((val_, idx_) =>  idx_ < rowIndexOfCollateralAsset_ && val_.excluded)
            .length + summaryColumnCount_;


        const liabilityHasNoCollateral_ = () =>  stack_.collateral?.id === null || rowIndexOfCollateralAsset_ < 0;


        for (let columnIndex_ = 0; columnIndex_ < this.columnCount; columnIndex_++) {
            const columnStack_ = this.columnStacks_[columnIndex_];

            if (AssetTableDisplayReport.isInvestablePortfolioStack_(columnStack_?.stackAssetType)) {
                columnValues_[columnIndex_] = null;
                continue;
            }

            if (liabilityHasNoCollateral_()) {
                continue;
            }

            if (columnIndexOfCollateralAsset_ >= 0) {
                if (columnIndex_ >= columnIndexOfCollateralAsset_) columnValues_[columnIndex_] = null;
            } else if (columnIndex_ >= columnsBeforeDebtCollateral_) columnValues_[columnIndex_] = null;
        }

        return columnValues_.map(value => this.createTableCell({value}));
    }
}