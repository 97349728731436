import {Page, ReportSection} from "../models/Reporting";
import React, {ChangeEvent} from "react";
import {Checkbox} from "xps-react";
import {Button} from "../../components";

function ReportSetupPagesSection(props: {
    pageSection: ReportSection;
    isHeaderChecked: boolean;
    toggleSectionHeader: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isPageChecked: (page: Page) => boolean;
    toggleCheckbox: (e: ChangeEvent<HTMLInputElement>) => void;
    navigateToEditReportPage: (param:string) => void;
}) {
    return <div className="report-section page-group marginbottom-xxxl">
        <div className="page-section-title">
            <Checkbox
                disabled={props.pageSection.isDisabled}
                className="page-item"
                aria-label={props.pageSection.sectionTitle}
                value={props.pageSection.sectionTitle}
                checked={props.isHeaderChecked}
                name={props.pageSection.sectionTitle}
                onChange={props.toggleSectionHeader}
            />
        </div>
        <hr className="section"/>
        <div className="marginleft-xl">
            {props.pageSection.pages.filter(page => !page.isDisabled).map(page => (
                <ReportPageCheckbox
                    key={page.value}
                    page={page}
                    checked={props.isPageChecked(page)}
                    onChange={props.toggleCheckbox}
                    onClick={()=>props.navigateToEditReportPage(page.value)}
                />
            ))}
        </div>
    </div>;
}

function ReportPageCheckbox(props: {
    page: Page;
    checked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick: () => void;
}) {
    return (
        <div className="page-item">
            <Checkbox
                disabled={props.page.isDisabled || props.page.isGreyedOut}
                className="page-item-checkbox"
                aria-label={props.page.label}
                value={props.page.label}
                checked={props.checked}
                name={props.page.value}
                onChange={props.onChange}
            />

            {props.page.isEditable && (
                <Button
                    disabled={props.page.isDisabled}
                    icon="left"
                    iconName="edit"
                    includeRef={false}
                    kind="borderless"
                    size="small"
                    tabIndex={0}
                    type="button"
                    aria-label={`Edit ${props.page.label}`}
                    onClick={props.onClick}
                >
                    edit
                </Button>
            )}
        </div>
    );
}

export default ReportSetupPagesSection;