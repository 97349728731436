import React from "react";
import {DatePicker, Label} from "../../../components";
import {DISPLAY_DATE_FORMAT} from "../../../constants/common";
import moment, {Moment} from "moment";

type DeceasedDateInputProps = {
    deceasedDate: string | null,
    birthdate: string | null,
    onChange: (deceasedDate: Moment) => void
    disabled?: boolean,
};

export const DeceasedDateInput: React.FC<DeceasedDateInputProps> = ({
                                                                        deceasedDate,
                                                                        birthdate,
                                                                        onChange,
                                                                        disabled = false,
                                                                    }: DeceasedDateInputProps) => {
    return <div
        className="h4 parent layout-data-entry-form__field familyMemberFormField">
        <Label label={"Deceased as of"} required={true}/>
        <DatePicker
            className="deceasedDateField"
            id="deceasedDateInput"
            aria-label="Deceased Date"
            aria-labelledby="deceasedDateInput-label"
            displayFormat={DISPLAY_DATE_FORMAT}
            hideKeyboardShortcutsPanel
            isOutsideRange={(date: Moment) => date.isBefore(birthdate) || date.isAfter(moment())}
            date={deceasedDate ? moment(deceasedDate) : undefined}
            disabled={disabled}
            onDateChange={(date) => {
                const currentDate = moment();
                const daysFromBirthdate = currentDate.diff(birthdate, 'days');
                const randomNumberOfDays = Math.floor(daysFromBirthdate * Math.random()) + 1
                const clampToBirthDate = birthdate ? moment.max(date, moment(birthdate)) : date;
                const clampedDeceasedDate = moment.min(clampToBirthDate, currentDate);

                const diffToClampedDate = date.diff(clampedDeceasedDate);
                if (diffToClampedDate > 0) {
                    onChange(clampedDeceasedDate.subtract(randomNumberOfDays, 'days'));
                } else if (diffToClampedDate < 0) {
                    onChange(clampedDeceasedDate.add(randomNumberOfDays, 'days'));
                } else {
                    onChange(clampedDeceasedDate);
                }
            }}
        />
    </div>;
}