import {Button, CurrencyInput, UnderlinedHeader} from "../../components";
import {formatCurrency} from "../../utils/format";
import React, {ChangeEvent, useEffect, useState} from "react";
import {MemberType} from "../../ClientManagement/models/MemberType";
import {FamilyAssets} from "../../Assets/models/FamilyAssets";
import Icon from "../../components/Icon/Icon";

type FamilyAssetsDetailsProps = {
    memberSelected: MemberType,
    familyAssets: FamilyAssets,
    appliedAssets: number,
    onChangeTotalAssets: (value: number) => void,
    onSaveTotalAssets: (familyAssets: FamilyAssets) => void
}
const FamilyAssetsDetails = ({
                                 memberSelected,
                                 familyAssets,
                                 appliedAssets, // total of family assets from all family goals
                                 onChangeTotalAssets,
                                 onSaveTotalAssets
                             }: FamilyAssetsDetailsProps) => {
    const [totalAssets, setTotalAssets] = useState(familyAssets.totalAssets);
    const [availableAssets, setAvailableAssets] = useState(familyAssets.totalAssets - appliedAssets);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [editTotalAssets, setEditTotalAssets] = useState(false);

    useEffect(() => {
        if (showErrorMessage) {
            const timeout = setTimeout(() => setShowErrorMessage(false), 6000);
            return () => clearTimeout(timeout);
        }
    }, [showErrorMessage])

    useEffect (() => {
        if (editTotalAssets) {
            document.getElementById("total-asset-curr-input")?.focus();
        }
    }, [editTotalAssets])

    useEffect(() => {
        setAvailableAssets(familyAssets.totalAssets - appliedAssets);
    }, [familyAssets.totalAssets, appliedAssets]);

    const handleChangeValue = (_event: ChangeEvent<HTMLInputElement>, value: string | number) => {
        const totalAssetsValue = value ? value as number : 0;
        setTotalAssets(totalAssetsValue);
        if(totalAssetsValue < appliedAssets) {
            setAvailableAssets(0);
        }
        else {
            setAvailableAssets(totalAssetsValue - appliedAssets);
        }
    }

    const onSave = () => {
        if (totalAssets < appliedAssets) {
            setShowErrorMessage(true);
            setTotalAssets(appliedAssets);
        } else {
            setEditTotalAssets(false);
            onChangeTotalAssets(totalAssets);
            onSaveTotalAssets({
                ...familyAssets!,
                totalAssets:totalAssets
            })
        }
    }

    const onCancel = () => {
        setTotalAssets(familyAssets.totalAssets);
        setAvailableAssets(familyAssets.totalAssets-appliedAssets);
        setEditTotalAssets(false);
    }

    return (
        <>
            <section className="family-asset_details_header">
                <UnderlinedHeader primaryText="Family Asset Details"/>
            </section>
            <div className="family-asset-details-table">
                <div>
                    <label className='h5'>{memberSelected.firstName + "'s Total Assets"}</label>
                    { !editTotalAssets &&
                        <div className="paddingtop-md">
                            <div data-testid={'family-details-total-assets'}>{formatCurrency(totalAssets)}
                            <Button
                                icon="right"
                                iconName="edit"
                                kind="borderless"
                                size="small"
                                onClick={() => setEditTotalAssets(true)}
                            >
                                Edit
                            </Button>
                            </div>
                        </div>
                    }
                    { editTotalAssets &&
                        <div>
                            <CurrencyInput
                                id="total-asset-curr-input"
                                error={showErrorMessage ? " " : undefined}
                                className="total-asset-input"
                                value={totalAssets}
                                onChangeValue={handleChangeValue}
                            />
                            <div>
                                {showErrorMessage &&
                                    <label className="color-text--error total-asset-error-message paddingtop-sm">
                                        <Icon
                                            ariaHidden
                                            name="warning"
                                            className={"paddingright-none"}
                                        />
                                        <span>Total Assets were adjusted to match Applied Family Assets.</span>
                                    </label>
                                }
                                <div className="paddingtop-md">
                                    <Button
                                        kind="secondary"
                                        size="small"
                                        onClick={onCancel}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        kind="primary"
                                        size="small"
                                        onClick={onSave}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div>
                    <label className='h5'>{"Applied to " + memberSelected.firstName + "'s Goals"}</label>
                    <div className="paddingtop-lg">{formatCurrency(appliedAssets)}</div>
                </div>
                <div>
                    <label className='h5'>{"Available Assets"}</label>
                    <div data-testid= "availableAssets" className="paddingtop-lg">{totalAssets === appliedAssets ? formatCurrency(0) : formatCurrency(availableAssets)}</div>
                </div>
            </div>
        </>
    )
}

export default FamilyAssetsDetails;