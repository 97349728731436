export type PortfolioReserveButtonState = {
    excessAssets: boolean,
    portfolioRisk: boolean,
    futureInflow: boolean,
    assetAllocation: boolean
};

export const emptyPortfolioReserveButtonState: PortfolioReserveButtonState = {
    excessAssets: false,
    portfolioRisk: false,
    futureInflow: false,
    assetAllocation: false
}
