import React, {ReactNode, useEffect, useState} from "react";
import CommunicationsContext, {CommunicationsConnectionState, ConnectCallProps} from "./CommunicationsContext";
import {
    CallAgentProvider,
    CallClientProvider,
    CallClientState,
    CallProvider,
    createStatefulCallClient,
    CreateViewResult,
    LocalVideoStreamState,
    StatefulCallClient
} from "@azure/communication-react";
import {Call, CallAgent, DeviceAccess, LocalVideoStream} from "@azure/communication-calling";
import {AzureCommunicationTokenCredential} from "@azure/communication-common";

type CommunicationProviderProps = {
    children: ReactNode,
}

type CallManagers = {
    statefulCallClient: StatefulCallClient,
    callAgent?: CallAgent,
    call?: Call,
    tokenCredential?: AzureCommunicationTokenCredential
}

const createInitialCallManager = () => {
    return {
        statefulCallClient: createStatefulCallClient({
            userId: {communicationUserId: ''}
        }),
    };
}

const CommunicationsProvider = ({children}: CommunicationProviderProps) => {
    const [callManagers, setCallManagers] = useState<CallManagers>(createInitialCallManager());
    const [callClientState, setCallClientState] = useState<CallClientState>(callManagers.statefulCallClient.getState())
    const [localVideoPreview, setLocalVideoPreview] = useState<LocalVideoStreamState | undefined>(undefined);
    const [audioEnabled, setAudioEnabled] = useState<boolean>(false);
    const [videoEnabled, setVideoEnabled] = useState<boolean>(false);

    useEffect(() => {
        if (callManagers.statefulCallClient) {
            const updateState = (newState: CallClientState) => {
                setCallClientState(newState);
            }
            callManagers.statefulCallClient.onStateChange(updateState);
            return () => {
                callManagers.statefulCallClient.offStateChange(updateState);
            };
        }
    }, []);

    const connect = async ({
                               acsTokenDetails,
                               userId,
                               joinUrl,
                               isAudioOn,
                               isVideoOn
                           }: ConnectCallProps) => {
        setAudioEnabled(isAudioOn);
        setVideoEnabled(isVideoOn);

        const tokenCredential = new AzureCommunicationTokenCredential({
            token: acsTokenDetails.accessToken,
            refreshProactively: true,
            tokenRefresher: acsTokenDetails.tokenRefresher
        });

        const statefulCallClient = createStatefulCallClient({
            userId: {communicationUserId: userId}
        });

        const callAgent = await statefulCallClient.createCallAgent(tokenCredential);

        const call = await statefulCallClient.getDeviceManager().then((deviceManager) => {
            return deviceManager.askDevicePermission({
                audio: true,
                video: true
            }).then((deviceAccess) => {
                const selectedCamera = callManagers.statefulCallClient.getState().deviceManager.selectedCamera;
                if (deviceAccess.video && isVideoOn && selectedCamera) {
                    const localVideoStream = new LocalVideoStream(selectedCamera);
                    return callAgent.join({meetingLink: joinUrl}, {
                        audioOptions: {muted: !(deviceAccess.audio && isAudioOn)},
                        videoOptions: {localVideoStreams: [localVideoStream]}
                    });
                }
                return callAgent.join({meetingLink: joinUrl}, {
                    audioOptions: {muted: !(deviceAccess.audio && isAudioOn)},
                });
            })
        })

        setCallManagers({
            statefulCallClient,
            callAgent,
            call,
            tokenCredential
        })
    };

    useEffect(() => {
        const call = callManagers.call;
        if (call) {
            const handleIsMutedChanged = () => {
                setAudioEnabled(!call.isMuted);
            };
            call.on('isMutedChanged', handleIsMutedChanged);
            const handleIsLocalVideoStartedChanged = () => {
                setVideoEnabled(call.isLocalVideoStarted);
            };
            call.on('isLocalVideoStartedChanged', handleIsLocalVideoStartedChanged);
            return () => {
                call.off('isMutedChanged', handleIsMutedChanged);
                call.off('isLocalVideoStartedChanged', handleIsLocalVideoStartedChanged);
            }
        }
    }, [callManagers.call])

    const disconnect = async () => {
        const promisedDisposals = [];
        if (callManagers.call) {
            promisedDisposals.push(callManagers.call.hangUp());
        }
        if (callManagers.callAgent) {
            promisedDisposals.push(callManagers.callAgent.dispose());
        }
        if (promisedDisposals.length) {
            await Promise.all(promisedDisposals).catch((error) => {
                console.error("Error hanging up call : ", error.message)
            });
        }
        if (callManagers.tokenCredential) {
            callManagers.tokenCredential.dispose();
        }
        setCallManagers(createInitialCallManager());
    }

    const getDevicePermissions = async (): Promise<DeviceAccess> => {
        return callManagers.statefulCallClient.getDeviceManager()
            .then((deviceManager) => {
                return deviceManager.askDevicePermission({
                    video: true,
                    audio: true
                }).then(async (deviceAccess) => {
                    if (deviceAccess.video) {
                        await deviceManager.getCameras(); // to populate deviceManager.selectedCameras
                    }
                    return deviceAccess;
                });
            })
    }

    const createVideoPreview = async (): Promise<CreateViewResult | undefined> => {
        const selectedCamera = callClientState?.deviceManager.selectedCamera;

        if (selectedCamera) {
            const streamState = {
                source: selectedCamera,
                mediaStreamType: 'Video'
            } as LocalVideoStreamState;
            setLocalVideoPreview(streamState);
            return callManagers.statefulCallClient.createView(
                undefined,
                undefined,
                streamState,
                {
                    scalingMode: 'Fit'
                }
            )
        }
    };

    const disposeVideoPreview = () => {
        if (!localVideoPreview) {
            console.warn('No local preview to dispose');
            return;
        }
        callManagers.statefulCallClient.disposeView(undefined, undefined, localVideoPreview)
        setLocalVideoPreview(undefined);
    }

    return (<CommunicationsContext.Provider value={{
        callManagers: {
            statefulCallClient: callManagers.statefulCallClient,
            callAgent: callManagers.callAgent,
            call: callManagers.call
        },
        callClientState,
        connect,
        disconnect,
        getDevicePermissions,
        createVideoPreview,
        disposeVideoPreview,
        connectionState: callManagers.call
            ? CommunicationsConnectionState.CONNECTED
            : CommunicationsConnectionState.DISCONNECTED,
        audioVideoSettings: {
            audioEnabled,
            videoEnabled,
        },
    }}>
        <CallClientProvider callClient={callManagers.statefulCallClient}>
            <CallAgentProvider callAgent={callManagers?.callAgent}>
                <CallProvider call={callManagers?.call}>
                    {children}
                </CallProvider>
            </CallAgentProvider>
        </CallClientProvider>
    </CommunicationsContext.Provider>);
}

export default CommunicationsProvider;