import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type OutOfEstateAssetsState = {
    showOutOfEstateAssetSummary: boolean,
    showOutOfEstateCurrentNetWorth: boolean
};

const initialOutOfEstate: OutOfEstateAssetsState = {
    showOutOfEstateAssetSummary: false,
    showOutOfEstateCurrentNetWorth: false
}

const outOfEstateAssetsSlice = createSlice({
    name: "outOfEstate",
    initialState: initialOutOfEstate,
    reducers: {
        setShowOutOfEstateAssets: (state, action: PayloadAction<boolean>) => {
            state.showOutOfEstateAssetSummary = action.payload;
        },
        setShowOutOfEstateCNW: (state, action: PayloadAction<boolean>) => {
            state.showOutOfEstateCurrentNetWorth = action.payload;
        },
        resetShowOutOfEstateAssets: (state) => {
            state.showOutOfEstateAssetSummary = false;
            state.showOutOfEstateCurrentNetWorth = false;
        }
    }
});

export const {
    setShowOutOfEstateAssets,
    setShowOutOfEstateCNW,
    resetShowOutOfEstateAssets
} = outOfEstateAssetsSlice.actions;

export default outOfEstateAssetsSlice.reducer;
