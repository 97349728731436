import React from 'react';
import {NO_OP} from "../../constants/common";
import {LinkProps} from "../../models/routeData/RouteParamTypes";

type NavContextValue = {
    toggleMenu: (link: LinkProps, path?: string) => void,
};

const defaultValue: NavContextValue = {
    toggleMenu: NO_OP,
};

export default React.createContext(defaultValue);
