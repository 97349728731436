import React from "react";
import {Donut} from "xps-react";
import {COLOR_RISK_ASSETS, COLOR_RISK_CONTROL_ASSETS} from "../../constants/colors";
import {ReviseAssetAllocationResponse} from "./ReviseAssetAllocation";

export interface RiskDonutProps {
    data: ReviseAssetAllocationResponse,
    allocation: string
    donutSize: 'xsm' | 'sm' | 'md' | 'lg' | 'xl',
    testId?: string;
}

const riskColors: [string, string] = [COLOR_RISK_ASSETS, COLOR_RISK_CONTROL_ASSETS]

const RiskDonut: React.FC<RiskDonutProps> = ({data, allocation, donutSize, testId}) => {
    const totalRAAmount = (allocation === 'current') ? data.totalCurrentRiskAssetsAmount : data.totalProposedRiskAssetsAmount | data.totalProposedRiskAssetsPercent;
    const totalRCAmount = (allocation === 'current') ? data.totalCurrentRiskControlAssetsAmount : data.totalProposedRiskControlAssetsAmount | data.totalProposedRiskControlAssetsPercent;
    return (
        <div className={`donut-container donut-container__${donutSize}`}>
            <Donut
                className="donut"
                data={[{value: totalRAAmount}, {value: totalRCAmount}]}
                colors={riskColors}
                outerRadius={48}
                data-testid={testId}
            />
        </div>
    )
}

export default RiskDonut;
