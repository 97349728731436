import {AssetsSummary} from "../../models/Assets";
import React, {useContext} from "react";
import {AccordionItemWithActionMenu} from "../../../components";
import {AssetAccordionHeader} from "../common/AssetAccordionHeader";
import {formatCurrency} from "../../../utils/format";
import {COLOR_LIABILITIES} from "../../../constants/colors";
import {DeleteModalProps} from "../../common/AssetsView";
import {useHistory} from "react-router-dom";
import {useAppSelector} from "../../../store/hooks";
import {selectProfile} from "../../../ClientManagement/ClientProfile/activeProfileSlice";
import {PersonalLiability} from "../../models/PersonalLiability";
import AccordionWithHeader from "../../../components/Accordion/AccordionWithHeader";
import AssetsViewContext from "../../common/AssetsViewContext";
import {assetListData} from "../common/utils";
import PersonalLiabilitiesTable from "../InEstate/PersonalLiabilitiesTable";

export type LiabilityListProps = {
    liabilitiesData: AssetsSummary,
    onDeleteAssetClick: (deleteModalProps: DeleteModalProps) => void
};

export default function LiabilityList({liabilitiesData, onDeleteAssetClick}: LiabilityListProps) {

    function getFormattedTotalPresentValue(totalPresentValue: number) {
        return totalPresentValue !== undefined ? `-${formatCurrency(totalPresentValue)}` : '';
    }

    const history = useHistory();
    const profile = useAppSelector(selectProfile);
    const viewType = useContext(AssetsViewContext);
    const {
        hasInEstatePersonalLiability,
        inEstatePersonalLiabilities
    } = assetListData(liabilitiesData);

    const allItems = [
        inEstatePersonalLiabilities.length > 0 ? "PersonalLiabilities" : ''
    ].filter(header => header.length > 0);

    const handleOnClickEdit = (personalLiabilityId: string) => {
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}/EditPersonalLiability/${personalLiabilityId}`);
    };

    const handleOnClickDelete = (personalLiability: PersonalLiability) => {
        onDeleteAssetClick({
            showDeleteModal: true,
            modalTitle: "Liability",
            assetDescription: personalLiability.description,
            assetType: "liability",
            assetId: personalLiability.id,
        });
    };

    return <section>
        <div className="liabilities-list">
            <div className="accordion-tables">
                <AccordionWithHeader
                    accordionId={"AssetSummaryLiabilityListAccordion"}
                    allItems={allItems}
                    columnTitles={["Liability Type", "","Present Value"]}
                    renderHeader
                >
                    <div className="liabilities">
                        {hasInEstatePersonalLiability && (
                            <AccordionItemWithActionMenu
                                uuid="PersonalLiabilities"
                                accentColor={COLOR_LIABILITIES}
                                HeaderComponent={({expanded}) =>
                                    <AssetAccordionHeader
                                        expanded={expanded}
                                        formattedTotalPresentValue={getFormattedTotalPresentValue(liabilitiesData.liabilitiesValue.inEstateValue)}
                                        title="Personal Liabilities"
                                        gridClassName={"assets-grid"}/>
                                }
                            >
                                <PersonalLiabilitiesTable
                                    personalLiabilities={inEstatePersonalLiabilities}
                                    onEdit={handleOnClickEdit}
                                    onDelete={handleOnClickDelete}
                                />
                            </AccordionItemWithActionMenu>
                        )}
                    </div>
                </AccordionWithHeader>
            </div>
        </div>
    </section>;
}
