import {EstateType} from "./models/api";
import {DropdownItem, PageActionMenu} from "../components";
import {DropdownGroup} from "xps-react";
import React from "react";
import {NO_OP} from "../constants/common";

export function WealthTransferOptions({estateType, onToggleEstateType}: {
    estateType: EstateType,
    onToggleEstateType: (estateType: EstateType) => void
}) {
    return <div className="wealth-transfer-options-dropdown">
        <PageActionMenu
            className="marginright-xs"
            panelHeight="auto"
            panelWidth={240}
            aria-label="more-options-dropdown"
        >
            <DropdownGroup groupName="Value">
                <DropdownItem itemText="" value="" onClick={NO_OP}>
                    <div
                        role={"option"}
                        aria-label={"Present Value"}
                        className="page-action-menu-options"
                        onClick={(e) => {
                            onToggleEstateType(EstateType.CURRENT);
                            e.stopPropagation();
                        }}>
                        Present Value
                        <input name="value-group"
                               type="radio"
                               value="current"
                               checked={estateType === EstateType.CURRENT}
                               onChange={(e) => {
                                   onToggleEstateType(EstateType.CURRENT);
                               }}
                        />
                    </div>
                </DropdownItem>
                <DropdownItem aria-label={"future-value-option"} itemText="" value="" onClick={NO_OP}>
                    <div
                        role={"option"}
                        aria-label="Future Value"
                        className="page-action-menu-options"
                        onClick={(e) => {
                             estateType !== EstateType.SUNSET && onToggleEstateType(EstateType.FUTURE);
                             e.stopPropagation();
                         }}>
                        Future Value
                        <input name="value-group" type="radio"
                               value="future"
                               checked={estateType !== EstateType.CURRENT}
                               onChange={(e) => {
                                   estateType !== EstateType.SUNSET && onToggleEstateType(EstateType.FUTURE);
                               }}/>
                    </div>
                </DropdownItem>
            </DropdownGroup>


            <DropdownGroup groupName="Estate Tax Exemption"
                           label="Estate Tax Exemption Dropdown">
                <DropdownItem itemText="" value="" onClick={NO_OP}>
                    <div
                        role={"option"}
                        aria-label="Current Exemption"
                         className="page-action-menu-options"
                         onClick={(e) => {
                             estateType !== EstateType.CURRENT && onToggleEstateType(EstateType.FUTURE);
                             e.stopPropagation();
                         }}>
                        Current Exemption
                        <input name="estate-tax-exemption-group"
                               type="radio"
                               value="current-exemption"
                               checked={estateType !== EstateType.SUNSET}
                               onChange={(e) => {
                                   estateType !== EstateType.CURRENT && onToggleEstateType(EstateType.FUTURE);
                               }}
                        />
                    </div>
                </DropdownItem>

                <DropdownItem itemText="" value="" onClick={NO_OP}
                              disabled={estateType === EstateType.CURRENT}>
                    <div
                        aria-disabled={estateType === EstateType.CURRENT}
                        role={"option"}
                        aria-label="2026 Sunset"
                         className="page-action-menu-options"
                         onClick={(e) => {
                             if(estateType === EstateType.CURRENT) {
                                 return;
                             }
                             onToggleEstateType(EstateType.SUNSET);
                             e.stopPropagation();
                         }}>
                        2026 Sunset
                        <input name="estate-tax-exemption-group"
                               type="radio" value="sunset"
                               checked={estateType === EstateType.SUNSET}
                               onChange={(e) => {
                                   if(estateType === EstateType.CURRENT) {
                                       return;
                                   }
                                   onToggleEstateType(EstateType.SUNSET);
                               }}
                        />
                    </div>
                </DropdownItem>
            </DropdownGroup>
        </PageActionMenu>
    </div>;
}
