import {ReactPlugin} from "@microsoft/applicationinsights-react-js";
import {ApplicationInsights, ITelemetryItem} from "@microsoft/applicationinsights-web";
import storageHelper from "./core/storageHelper";

const reactPlugin = new ReactPlugin();

let appInsights: ApplicationInsights;

function createAppInsightsInstance() {
    if(appInsights) return;
    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: storageHelper.getSpaConfig()?.insight,
            disableFetchTracking: false,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            enableAutoRouteTracking: true,
            autoTrackPageVisitTime: true,
            extensions: [reactPlugin]
        }
    });
}

export const initializeAppInsights: () => void = () => {
    createAppInsightsInstance();
    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer((telemetryItem) => {
        if (telemetryItem.baseType == 'Pageview' || telemetryItem.baseType == 'PageviewData') {
            const telemetryItemBaseData = telemetryItem.baseData as ITelemetryItem;
            if (telemetryItemBaseData && telemetryItemBaseData.name.indexOf("Goals Driven Wealth Management") !== -1) {
                // Disable sending Page View information to AppInsights
                return false;
            }
        }
        telemetryItem.tags!['ai.cloud.role'] = 'gpi-ui'
        telemetryItem.tags!['ai.cloud.roleInstance'] = 'gpi-ui'
    });
    appInsights.trackPageViewPerformance({name: 'GPI-UI', uri: window.location.href});
}

export const useAppInsights: () => ApplicationInsights = () => {
    createAppInsightsInstance();
    return appInsights;
};
