import React from "react";
import {LifeInsurance} from "../../models/LifeInsurance";
import {MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import {DeleteModalProps} from "../../common/AssetsView";
import {LifeInsuranceRow} from "./LifeInsuranceRow";
import {TableCell} from "../../../components/Table/TableCell";
import moment from "moment";
import {mapToOwnershipDetailsFormData} from "../../Ownership/mappers";
import {formatCurrency} from "../../../utils/format";
import {getCurrentOwnerByOwnerMemberId, getOwnerName} from "../common/ownershipUtils";
import {getBeneficiariesList} from "../common/lifeInsuranceUtils";
import DragDrop from '@nt/dds-react/core/DragDrop';
import {NO_OP} from "../../../constants/common";
import {Icon} from "../../../components";
import {shortenInstitution, shortenName} from "../../common/textUtils";
import {DropResult} from "react-beautiful-dnd";
import {sortByOrdinalAndCashValue} from "../common/utils";

type LifeInsurancesTableProps = {
    lifeInsurances: LifeInsurance[],
    memberGroup: MemberGroup | undefined,
    onEdit: (lifeInsuranceId: string) => void,
    onDelete: (deleteModalProps: DeleteModalProps) => void,
    hideActionMenu?: boolean,
    isDraggable?: boolean,
    captureLifeInsurancesOrder?: ({source, destination}: DropResult) => void
}

export function LifeInsurancesTable({
                                        lifeInsurances,
                                        memberGroup,
                                        onEdit,
                                        onDelete,
                                        hideActionMenu = false,
                                        isDraggable = false,
                                        captureLifeInsurancesOrder = NO_OP
                                    }: LifeInsurancesTableProps) {

    const sortedLifeInsurances = sortByOrdinalAndCashValue(lifeInsurances) as LifeInsurance[]

    const renderLifeInsuranceRow = (lifeInsurance: LifeInsurance) =>
        <LifeInsuranceRow lifeInsurance={lifeInsurance}
                          key={lifeInsurance.id}
                          gridClassName={"assets-grid-with-actionmenu"}
                          renderLifeInsuranceDetails={(lifeInsuranceDetails) =>
                              <>
                                  <TableCell
                                      className="marginleft-lg"
                                      text={shortenName(lifeInsuranceDetails.description)}
                                      subtext={`Issued ${(lifeInsuranceDetails.issueDate) ? moment(lifeInsuranceDetails.issueDate).format('MM/DD/YYYY') : '—'}`}/>
                                  <TableCell
                                      text={shortenInstitution(lifeInsuranceDetails.insuranceCompanyName)}
                                      subtext={getOwnerName(mapToOwnershipDetailsFormData(lifeInsuranceDetails).memberOwnerships, memberGroup!)}
                                      className="textalign-left"/>
                                  <TableCell
                                      text={getCurrentOwnerByOwnerMemberId(lifeInsuranceDetails.insuredMemberId, memberGroup!).firstName}
                                      subtext={getBeneficiariesList(lifeInsuranceDetails.beneficiaries, memberGroup!)}
                                      className="textalign-left"/>
                                  <TableCell
                                      text={formatCurrency(lifeInsuranceDetails.deathBenefitValue)}
                                      className="textalign-right"/>
                              </>}
                          actionsDisabled={false}
                          hideActionMenu={hideActionMenu}
                          onClickEdit={onEdit}
                          onClickDelete={(lifeInsuranceToDelete) => {
                              onDelete({
                                  showDeleteModal: true,
                                  modalTitle: "Asset",
                                  assetDescription: lifeInsuranceToDelete.description ?? "",
                                  assetType: "life insurance",
                                  assetId: lifeInsuranceToDelete.id,
                              });
                          }}/>

    const renderSubGroup = (subHeader: string, lifeInsurancesToRender: LifeInsurance[]) => {
        if (isDraggable) {
            return (
                <>
                    {lifeInsurancesToRender.length > 0 &&
                        <div role="row" className="grid-display-contents asset-row-subheader">
                                            <span role="cell" aria-label={'include-in-asset'}
                                                  className="textalign-left grid-span-5 fontweight-medium">{subHeader}</span>
                        </div>}
                    <DragDrop
                        className="draggable"
                        direction="vertical"
                        draggableId="lifeInsuranceDraggableId1"
                        droppableId="lifeInsuranceDroppableId1"
                        onDragEnd={captureLifeInsurancesOrder}
                        items={
                            lifeInsurancesToRender.map((lifeInsuranceToRender) =>
                                <div className="display-flex align-items-right" tabIndex={-1}
                                     key={lifeInsuranceToRender.id}>
                                    <Icon ariaHidden className="drag__icon" name="drag_vertical"/>
                                    {renderLifeInsuranceRow(lifeInsuranceToRender)}
                                </div>
                            )
                        }
                    >
                    </DragDrop>
                </>
            )
        }

        return (
            <>
                {lifeInsurancesToRender.length > 0 &&
                    <div role="row" className="grid-display-contents asset-row-subheader">
                                            <span role="cell" aria-label={'include-in-asset'}
                                                  className="textalign-left grid-span-5 fontweight-medium">{subHeader}</span>
                    </div>}
                {lifeInsurancesToRender.map(renderLifeInsuranceRow)}
            </>

        )
    }

    const willFundGoals = sortedLifeInsurances.filter(insurance => (insurance.isCashValueWillFundGoals));
    const willNotFundGoals = sortedLifeInsurances.filter(insurance => (!insurance.isCashValueWillFundGoals));

    return (
        <div role="table" className="assets-grid-table"
             aria-label="life-insurance-table">
            <div role="row"
                 className={`${hideActionMenu ? 'assets-grid-table-header_arrange' : 'assets-grid-table-header'} assets-grid-with-actionmenu`}
                 tabIndex={-1}>
                <span role="columnheader" className='textalign-left'>Name</span>
                <span role="columnheader" className='textalign-left'>Company & Owner</span>
                <span role="columnheader"
                      className='textalign-left'>Insured & Beneficiary</span>
                <span role="columnheader" className='textalign-right'>Death Benefit</span>
                <span role="columnheader" className='textalign-right'>Cash Value</span>
            </div>
            {renderSubGroup("Included in Net Assets", willFundGoals)}
            {renderSubGroup("Exclude from Net Assets", willNotFundGoals)}
        </div>
    )
}