import DataEntryHeader from "../../../components/DataEntry/DataEntryHeader";
import {EmptyStateContainer} from "../../../components";
import React from "react";
import {NO_OP} from "../../../constants/common";

type NoInvestmentProgramProps = {
    onCancel: () => void
};

const NoInvestmentProgram = ({ onCancel }: NoInvestmentProgramProps) => {

    return (
        <div className="investment-programs-container">
            <DataEntryHeader
                className="dataEntryHeader"
                title="Add Investment Program"
                primaryButtonText="Search"
                onPrimaryButtonClick={NO_OP}
                secondaryButtonText="Cancel"
                onSecondaryButtonClick={onCancel}
            />
            <div className="investment-programs-container">
                <EmptyStateContainer
                    className='no-investment-programs-placeholder'
                    title="No Investment Programs are available."
                    size="large"
                    description={<span>Please create a new Investment Program using Groupings</span>}/>
            </div>
        </div>
    );
}

export default NoInvestmentProgram;
