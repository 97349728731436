import React, {ChangeEvent, FC, useState} from "react";
import ModalWrapper from "../../../components/Modal/ModalWrapper/ModalWrapper";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {
    initialMeeting,
    selectMeetingModalVisibility,
    selectScheduledMeetingForm,
    setMeetingModalVisibility,
    setScheduledMeetingForm
} from "../meetingSlice";
import {DatePicker, Label, Name, RadioGroup} from "../../../components";
import {useMsal} from "@azure/msal-react";
import useProfileAndProposals from "src/hooks/useProfileAndProposals";
import {useParams} from "react-router-dom";
import {RouteWithId} from "src/routes/types";
import {selectReleaseToggles} from "src/ReleaseToggles/releaseTogglesSlice";
import moment, {Moment} from "moment";
import {
    HumanReadableMeetingType,
    mapHumanReadableMeetingTypeToEnum,
    mapMeetingTypeEnumToHumanReadable,
    Meeting,
    MeetingType
} from "../Meeting";
import {createOnlineMeeting, initializeGraphClient, OnlineMeetingDetails} from "../GraphClient";
import {msalUtils} from "src/MsalUtils";
import {meetingApiClient} from "../MeetingApiClient";
import {DISPLAY_DATE_FORMAT} from "../../../constants/common";
import {Dropdown, DropdownItem} from "xps-react";

const MeetingScheduleModal: FC = () => {
    const {id} = useParams<RouteWithId>()
    const {approvedProfile, proposals} = useProfileAndProposals(id);
    const msal = useMsal();
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const modalVisibility = useAppSelector(selectMeetingModalVisibility);
    const scheduledMeetingForm = useAppSelector(selectScheduledMeetingForm);
    const dispatch = useAppDispatch();

    const [meetingTitle, setMeetingTitle] = useState(scheduledMeetingForm?.title)
    const [idToPresent, setIdToPresent] = useState(scheduledMeetingForm?.profileIdToPresent);
    const defaultDate = scheduledMeetingForm?.startDate ? moment(scheduledMeetingForm?.startDate) : undefined;
    const [date, setDate] = useState<Moment | undefined>(defaultDate);
    const defaultMeetingType = scheduledMeetingForm!.id.length > 0 ? scheduledMeetingForm?.type! : undefined;
    const [meetingType, setMeetingType] = useState<MeetingType | undefined>(defaultMeetingType);
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);

    const validateForm = (): boolean => {
        if (!meetingTitle) return false;
        if (!idToPresent) return false;
        if (date === undefined) return false;
        return meetingType !== undefined;
    }

    const modalHeader = scheduledMeetingForm!.id.length > 0 ? "Update a scheduled meeting": "Schedule a new meeting"
    const meetingModalWrapperHeader = (
        <React.Fragment>
            <span className={"meeting-modal-header"}>{modalHeader}</span>
            <p className="font-default color-text--error noMargin">{"* Required Fields"}</p>
        </React.Fragment>
    );

    const dataToPresentSection = (
        <div className="layout-data-entry-form__field" style={{marginBottom: "16px"}}>
            <Label label="Data to Present"
                   htmlFor="data-to-present-DropdownField"
                   labelId="data-to-present-DropdownField-Label"
                   required={true}
            />
            <Dropdown
                id="data-to-present"
                size="medium"
                value={idToPresent}
                dropdownKind="select"
                onChange={({value: newIdToPresent}: { value: string }) => {
                    setIdToPresent(newIdToPresent);
                }}
            >
                <DropdownItem className={'fontweight-500'} key={approvedProfile.id} value={approvedProfile.id}
                              text={approvedProfile.displayName}
                              itemText={approvedProfile.displayName}
                />
                {proposals.map(proposal => <DropdownItem key={proposal.id} value={proposal.id}
                                                         text={proposal.displayName}
                                                         itemText={proposal.displayName}
                                                         style={{paddingLeft: '35px'}}
                />)}
            </Dropdown>
        </div>
    );

    const meetingTypeSection = (
        <div className="layout-data-entry-form__field" style={{
            marginBottom: -16,
        }}>
            <RadioGroup
                id="meetingType"
                name="meetingType"
                label="Meeting Type "
                layout="vertical"
                selected={mapMeetingTypeEnumToHumanReadable(meetingType)}
                values={
                    [MeetingType.TRAINING_MEETING, MeetingType.CLIENT_MEETING]
                        .map(type => mapMeetingTypeEnumToHumanReadable(type))
                }
                required={true}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setMeetingType(mapHumanReadableMeetingTypeToEnum(e.target.value as HumanReadableMeetingType))
                }}
            />
        </div>
    );

    return <ModalWrapper
        id="schedule-meeting"
        headerText={meetingModalWrapperHeader}
        isOpen={modalVisibility?.meetingSchedule}
        buttons={[
            {
                text: "Cancel",
                onClick: () => {
                    if (scheduledMeetingForm!.id.length > 0) {
                        dispatch(setMeetingModalVisibility({
                            ...modalVisibility,
                            meetingInfo: true,
                            meetingSchedule: false
                        }))
                    }
                    else {
                        dispatch(setMeetingModalVisibility({
                            ...modalVisibility,
                            meetingInfo: false,
                            meetingSchedule: false
                        }))
                        dispatch(setScheduledMeetingForm(initialMeeting))
                    }
                }
            },
            {
                text: scheduledMeetingForm!.id.length > 0 ? "Update Meeting" : "Generate Meeting",
                disabled: disableSubmitButton,
                onClick: async () => {
                    if (scheduledMeetingForm!.id.length > 0) {
                        const isFormValid = validateForm();
                        if (isFormValid) {
                            setDisableSubmitButton(true);
                            const updatedMeeting: Meeting = {
                                ...scheduledMeetingForm!,
                                title: meetingTitle!,
                                startDate: date?.toISOString(true)!,
                                type: meetingType!,
                                profileIdToPresent: idToPresent!,
                            }
                            meetingApiClient.updateMeeting(updatedMeeting)
                                .then((meeting) => {
                                    dispatch(setScheduledMeetingForm(meeting))
                                    dispatch(setMeetingModalVisibility({
                                        ...modalVisibility,
                                        meetingInfo: true,
                                        meetingSchedule: false
                                    }))
                                    setDisableSubmitButton(false);
                                })
                        }
                    } else {
                        const isFormValid = validateForm();
                        if (isFormValid) {
                            setDisableSubmitButton(true);
                            const onlineMeetingDetails = await createOnlineMeeting(initializeGraphClient(msal))
                                .catch(() => ({} as OnlineMeetingDetails))
                            const meetingToSchedule: Meeting = {
                                id: "",
                                createdBy: msalUtils.getLanId(msal),
                                createdByName: msalUtils.getAccountName(msal),
                                remoteEnabled: releaseToggles?.enableMeetingManagementRemote ?? false,
                                title: meetingTitle!,
                                startDate: date?.toISOString(true)!,
                                type: meetingType!,
                                profileId: id,
                                profileIdToPresent: idToPresent!,
                                ...onlineMeetingDetails
                            }
                            meetingApiClient.scheduleMeeting(meetingToSchedule)
                                .then(meeting => {
                                    dispatch(setScheduledMeetingForm(meeting));
                                    setDisableSubmitButton(false);
                                    dispatch(setMeetingModalVisibility({
                                        ...modalVisibility,
                                        meetingInfo: true,
                                        meetingSchedule: false
                                    }))
                                })
                                .catch((error) => console.error("Failed to schedule meeting: ", error.message))
                        }
                    }
                },
                primary: true
            }
        ]}
    >
        <div className="schedule-meeting-subhead">Fill out the form below to schedule a meeting for remote
            participants.
        </div>
        <div className="schedule-meeting-input-container">
            <div className="layout-data-entry-form__field" style={{marginBottom: "16px"}}>
                <Label label="Date" required={true}/>
                <DatePicker aria-label="Date"
                            aria-labelledby="Date"
                            className="schedule-meeting-date"
                            width={"191px"}
                            displayFormat={DISPLAY_DATE_FORMAT}
                            hideKeyboardShortcutsPanel
                            isOutsideRange={(newDate: Moment) => newDate.isBefore(moment(), "D")}
                            id=""
                            date={date}
                            onDateChange={(selectedDate: Moment) => {
                                if (selectedDate.isBefore(moment(), "D")) {
                                    setDate(moment())
                                    return;
                                }
                                setDate(selectedDate);
                            }}
                />
            </div>
            <div className="layout-data-entry-form__field">
                <Name
                    name={meetingTitle ?? ''}
                    className="schedule-meeting-title"
                    label="Meeting Title"
                    required={true}
                    maxLength={255}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setMeetingTitle(e.target.value)
                    }}
                />
            </div>
            {dataToPresentSection}
            {meetingTypeSection}
        </div>
    </ModalWrapper>
}

export default MeetingScheduleModal;