import React, {ReactElement} from "react";
import ClientMemberOwnershipTable from "../components/ClientMemberOwnershipTable";
import {
    LegalEntityFormData,
    LegalEntityOwnershipFormData,
    OwnershipCategory,
    OwnershipDetailsFormData
} from "../../models/Ownership";
import {LegalEntityOwnershipTable} from "./LegalEntityOwnershipTable";
import {formatNumber} from "../../../utils/format";
import {AlertBanner, UnderlinedHeader} from "../../../components";
import OwnershipCategoryDropdown from "../components/OwnershipCategoryDropdown";
import {calculateOwnershipPercentages} from "../validation";
import classNames from "classnames";
import {MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import {doesCategoryAllowNonClientOwnerships} from "../mappers";
import {NonClientIndividualOwnershipTable} from "./NonClientIndividualOwnershipTable";
import {isLivingMember} from "../../../ClientManagement/mappers";
import {TaxDetailsType} from "../../models/TaxDetails";

export type NonSoleOwnershipProps = {
    totalAssetValue?: number | null,
    formData: OwnershipDetailsFormData,
    onFormDataChange: (updatedFormData: OwnershipDetailsFormData) => void,
    memberGroup: MemberGroup,
    handleUpdateLegalEntity: (ownerEntity: LegalEntityFormData) => void,
    legalEntities: LegalEntityFormData[],
    isOwnershipPercentageErrorBannerShown: boolean,
    disableOutOfEstate?: boolean,
    subheader?: ReactElement,
    isProfileWithProposalsOrArchived?: boolean
    isPartiallyOwned?: boolean,
    taxDetails?: TaxDetailsType
}

export default function NonSoleOwnership({
                                             totalAssetValue,
                                             formData,
                                             onFormDataChange,
                                             memberGroup,
                                             handleUpdateLegalEntity,
                                             legalEntities,
                                             isOwnershipPercentageErrorBannerShown,
                                             disableOutOfEstate = false,
                                             subheader,
                                             isProfileWithProposalsOrArchived = false,
                                             isPartiallyOwned,
                                             taxDetails
                                         }: NonSoleOwnershipProps) {
    const estateStatus = () => {
        const {inEstateTotal, outOfEstateTotal} = calculateOwnershipPercentages(formData);

        let estateDescription = "";
        if (inEstateTotal > 0)
            estateDescription += `${formatNumber(inEstateTotal)}% IN ESTATE`;

        if (inEstateTotal > 0 && outOfEstateTotal > 0)
            estateDescription += `, `;

        if (outOfEstateTotal > 0)
            estateDescription += `${formatNumber(outOfEstateTotal)}% OUT OF ESTATE`;

        return estateDescription;
    }

    const handleOnChangeOwnershipCategory = (newOwnershipCategory: OwnershipCategory) => {
        onFormDataChange({
            ...formData,
            ownershipCategory: newOwnershipCategory
        });
    };

    const estateStatusErrorClass = classNames({
        'color-text--error': isOwnershipPercentageErrorBannerShown
    })

    const estatePercentageClass = calculateOwnershipPercentages(formData).inEstateTotal === 100 && isPartiallyOwned ? 'color-text--error' : estateStatusErrorClass

    const allowsNonClientOwnership = doesCategoryAllowNonClientOwnerships(formData.ownershipCategory);
    const partnerMember = memberGroup.partnerMember && isLivingMember(memberGroup.partnerMember) ? memberGroup.partnerMember : undefined;
    const livingNonClientMembers = memberGroup.additionalMembers.filter(isLivingMember);

    return (
        <section className="ownership-details-section">
            <UnderlinedHeader
                className="ownership-section-header"
                primaryText="Ownership"
                rightAlignedContent={<span data-testid="ownership-header-text" className={estatePercentageClass}> {estateStatus()} </span>}
            />

                <div className={"paddingbottom-lg"}>
                    <AlertBanner
                        icon={'warning'}
                        className={""}
                        showAlert={isOwnershipPercentageErrorBannerShown && calculateOwnershipPercentages(formData).grandTotal !== 100 }
                        message={"Total ownership is " + calculateOwnershipPercentages(formData).grandTotal + "%"}
                    />
                </div>

            {subheader}
            {taxDetails && taxDetails.isEntityCapitalGains && <div className={"paddingbottom-lg"}>
                <AlertBanner
                    icon={'info'}
                    showAlert={true}
                    type={'info'}
                >
                    <div style={{color: "black", paddingLeft: "2px", fontWeight: "normal"}}>If the entity is 100% out of estate, the estate will retain the full tax liability (e.g. Grantor Trust).</div>
                </AlertBanner>
            </div>}
            <OwnershipCategoryDropdown
                value={formData.ownershipCategory}
                onChange={(data) => handleOnChangeOwnershipCategory(data.value)}
                partnerMember={partnerMember}
                isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
            />

            <ClientMemberOwnershipTable onUpdate={onFormDataChange}
                                        primaryMember={memberGroup.primaryMember}
                                        partnerMember={partnerMember}
                                        ownershipDetails={formData}
                                        totalAssetValue={totalAssetValue}
                                        isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
            />
            {
                (allowsNonClientOwnership && !disableOutOfEstate) &&
                <React.Fragment>
                    <NonClientIndividualOwnershipTable
                        formData={formData}
                        nonClientMembers={livingNonClientMembers}
                        onNonClientMemberOwnershipChange={onFormDataChange}
                        totalAssetValue={totalAssetValue}
                        isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                    />
                    <LegalEntityOwnershipTable legalEntityOwnerships={formData.legalEntityOwnerships}
                                               totalAssetValue={totalAssetValue}
                                               legalEntities={legalEntities}
                                               updateLegalEntity={handleUpdateLegalEntity}
                                               onLegalEntityOwnershipChange={(legalEntityOwnerships: LegalEntityOwnershipFormData[]) => {
                                                   onFormDataChange({
                                                       ...formData,
                                                       legalEntityOwnerships: legalEntityOwnerships,
                                                   });
                                               }}
                                               isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                    />
                </React.Fragment>
            }
        </section>
    )
}
