import React from 'react';
import {GeneralInflows, SocialSecurities} from "../../models/Assets";
import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {DeleteModalProps} from "../../common/AssetsView";
import {GeneralInflowTable} from "./GeneralInflowTable";
import {SocialSecuritiesTable} from "./SocialSecuritiesTable";
import {DropResult} from "react-beautiful-dnd";
import {NO_OP} from "../../../constants/common";
import {GeneralInflow} from "../../models/GeneralInflow";

type FutureInflowsTableProps = {
    generalInflows: GeneralInflows;
    socialSecurities: SocialSecurities;
    investorGroup: InvestorGroupType;
    onEditGeneralInflow: (assetId: string) => void;
    onEditSocialSecurity: (assetId: string) => void;
    onDeleteAssetClick: (deleteModalProps: DeleteModalProps) => void;
    hideActionMenu?: boolean;
    isDraggable?: boolean;
    onSaveReviewTrustDisclosure: (generalInflow: GeneralInflow) => void
    captureGeneralInflowsOrder?: ({source, destination}: DropResult) => void
    captureSocialSecuritiesOrder?: ({source, destination}: DropResult) => void
}

export default function FutureInflowsTable({
                                               generalInflows,
                                               socialSecurities,
                                               investorGroup,
                                               onEditGeneralInflow,
                                               onEditSocialSecurity,
                                               onDeleteAssetClick,
                                               hideActionMenu = false,
                                               isDraggable = false,
                                               onSaveReviewTrustDisclosure = NO_OP,
                                               captureGeneralInflowsOrder = NO_OP,
                                               captureSocialSecuritiesOrder = NO_OP
                                           }: FutureInflowsTableProps) {

    return <div role="table" className="assets-grid-table" aria-label="future-inflows-table">
        <div role="row" className="assets-grid-with-actionmenu assets-grid-table-header" tabIndex={-1}>
            <span role="columnheader" className="textalign-left">Name</span>
            <span role="columnheader" className="textalign-right">Net & Gross Flow</span>
            <span role="columnheader" className="textalign-right">Years Until Flow</span>
            <span role="columnheader" className="textalign-right">Years Of Flow</span>
            <span role="columnheader" className="textalign-right">Present Value</span>
        </div>
        {generalInflows.data.length > 0 &&
            <GeneralInflowTable
                generalInflows={generalInflows.data}
                onEdit={onEditGeneralInflow}
                onSaveReviewTrustDisclosure={onSaveReviewTrustDisclosure}
                onDelete={onDeleteAssetClick}
                hideActionMenu={hideActionMenu}
                isDraggable={isDraggable}
                captureGeneralInflowsOrder={captureGeneralInflowsOrder}
            />}
        {socialSecurities.data.length > 0 &&
            <SocialSecuritiesTable
                investorGroup={investorGroup}
                socialSecurities={socialSecurities.data}
                onEdit={onEditSocialSecurity}
                onDelete={onDeleteAssetClick}
                hideActionMenu={hideActionMenu}
                isDraggable={isDraggable}
                captureSocialSecuritiesOrder={captureSocialSecuritiesOrder}
            />}
    </div>;
}


