import {TabPanel as XpsTabPanel} from 'xps-react'
import React, {ReactNode} from "react";

type TabPanelProps = {
    className?: string,
    tabIndex?: number,
    children: ReactNode
}

const TabPanel =({className,tabIndex, children,}:TabPanelProps) => {
    return <XpsTabPanel className={className}
                        tabIndex={tabIndex}>{children}</XpsTabPanel>
}

export default TabPanel;
