import {BaseMemberType, MemberType, RelatesToType} from "../../../models/MemberType";
import {FamilyRelationshipState} from "../../FamilyTreeSlice";
import {FamilyRelationshipType} from "../../../models/FamilyRelationshipType";


const formatFullName: (member: MemberType) => string = member => `${member.firstName} ${member.lastName}`;


function displayRelationshipTitle(familyMemberForm: BaseMemberType & { id?: string | null; relatesTo?: RelatesToType[]; children?: string[] }, displayText: string) {
    return !!familyMemberForm.firstName ? `${familyMemberForm.firstName}'s ${displayText}` : `This Person's ${displayText}`;
}

const memberAsFamilyRelationshipState = (member: MemberType): FamilyRelationshipState => ({
    memberId: member?.id,
    fullName: `${member?.firstName} ${member?.lastName}`,
    type: FamilyRelationshipType.PARENT
})
const removeDuplicates = (val: FamilyRelationshipState, idx: number, self: FamilyRelationshipState[]) => self.findIndex((v) => v.memberId === val.memberId) === idx;


const util = {
    formatFullName: formatFullName,
    displayRelationshipTitle: displayRelationshipTitle,
    memberAsFamilyRelationshipState: memberAsFamilyRelationshipState,
    removeDuplicates: removeDuplicates,
}

export default util;