import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {EditBeneficiariesForm} from "./EditBeneficiariesForm";
import {wealthTransferApiClient} from "../WealthTransferApiClient";
import {BeneficiariesFormData, ResiduaryMemberBequestFormData} from "../models/ui";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {MemberGroupMember} from "../../ClientManagement/models/InvestorGroupType";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {mapToFormData} from "../models/mappers";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {RouteWithId} from "../../routes/types";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {isLoaded, isNotLoaded, selectEstateSummary, setEstateSummary} from "../EstateSummarySlice";
import {AllBequests} from "../models/api";
import {LifeStatus} from "../../ClientManagement/models/MemberType";

type ResiduaryMemberBequestFormDataWithNonnullMemberId = ResiduaryMemberBequestFormData & {
    memberId: string
};

function memberIdIsNotNull(bequest: ResiduaryMemberBequestFormData): bequest is ResiduaryMemberBequestFormDataWithNonnullMemberId {
    return bequest.memberId !== null;
}

export const EditBeneficiariesContainer = () => {
    const history = useHistory();
    const {id} = useParams<RouteWithId>();
    const {profile} = useProfileAndProposals(id);
    const dispatch = useAppDispatch();
    const estateSummaryState = useAppSelector(selectEstateSummary);
    const estateSummary = isLoaded(estateSummaryState) ? estateSummaryState.estateSummary : undefined;
    const [members, setMembers] = useState<MemberGroupMember[]>();
    const [initialFormData, setInitialFormData] = useState<BeneficiariesFormData>();


    useEffect(() => {
        let isSubscribed = true;
        Promise.all([
            clientManagementApiClient.getMemberGroup(profile.id),
            wealthTransferApiClient.getAllBequests(profile.id),
        ]).then(([memberGroup, allBequests]) => {
            if (!isSubscribed) {
                return;
            }

            setMembers(memberGroup.additionalMembers
                .filter(member => member.lifeStatus === LifeStatus.Living));
            setInitialFormData(mapToFormData(allBequests));
        }).catch(error => console.error('Could not fetch beneficiaries data', error.message));

        return () => {
            isSubscribed = false;
        }
    }, [profile.id]);

    const dismissDrawer = () => history.push(`/Profile/${profile.id}/ClientProfile/WealthTransferPlanning`);

    const onEvaluate = (formData: BeneficiariesFormData) => {
        wealthTransferApiClient
            .estateSummaryEvaluateBequests(profile.id, buildAllBequests(formData))
            .then(evaluatedEstateSummary => dispatch(setEstateSummary(evaluatedEstateSummary)))
    }

    function buildAllBequests(formData: BeneficiariesFormData): AllBequests {
        const memberBequests = formData.memberBequests.map(bequest => ({
            memberId: bequest.memberId!,
            presentValue: parseFloat(bequest.presentValue)
        }));

        const philanthropicBequests = formData.philanthropicBequests.map(bequest => ({
            philanthropyName: bequest.philanthropyName,
            value: parseFloat(bequest.value),
            type: bequest.type
        }));

        const residuaryMemberBequests = formData.residuaryMemberBequests
            .filter(memberIdIsNotNull)
            .map(bequest => ({
                memberId: bequest.memberId,
                percentage: parseFloat(bequest.percentage)
            }));

        return {memberBequests, philanthropicBequests, residuaryMemberBequests};
    }

    const onSave = async (formData: BeneficiariesFormData) => {
        const request = buildAllBequests(formData);
        await wealthTransferApiClient.saveWealthTransferPlan(profile.id, request);
        dismissDrawer();
    };

    if (isNotLoaded(estateSummaryState) || !members || !initialFormData || estateSummary === undefined) {
        return <LoadingIndicator/>;
    }

    return <EditBeneficiariesForm
        initialFormData={initialFormData}
        members={members}
        estateSummary={estateSummary}
        onSave={onSave}
        onCancel={dismissDrawer}
        onEvaluate={onEvaluate}
    />
};