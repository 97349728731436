import React from "react";
import {Table} from "xps-react";
import {
    MEETINGS_HISTORY_DEFAULT_ROWS,
    MEETINGS_HISTORY_MIN_ROWS,
    MEETINGS_HISTORY_PAGE_SIZES
} from "./MeetingConstants";
import {HistoricalMeeting, mapMeetingTypeEnumToHumanReadable} from "./Meeting";
import moment from "moment";
import {toDisplayDateFormat} from "../../utils/dateUtils";
import {FilterMeetingOptions} from "./MeetingsHistory";

interface MeetingHistoryDetailsProps {
    meetings: HistoricalMeeting[]
    meetingSubset: FilterMeetingOptions
}

const MeetingHistoryDetails: React.FC<MeetingHistoryDetailsProps> = ({meetings, meetingSubset}: MeetingHistoryDetailsProps) => {

    const emptyMeetingText = meetingSubset === "expiredMeetings" ? "No meetings have expired." : "This profile has no meeting history";

    return (
        <div className="table-spacing">
            <Table
                id="MeetingsHistoryTable"
                caption="Meeting History"
                data={meetings}
                minRows={MEETINGS_HISTORY_MIN_ROWS}
                filterable={false}
                noDataText={emptyMeetingText}
                pageSizeDropUp={true}
                pageSizeOptions={MEETINGS_HISTORY_PAGE_SIZES}
                // rowHeight="medium"
                sortable={false}
                defaultPageSize={MEETINGS_HISTORY_DEFAULT_ROWS}
                columns={[
                    {
                        Header: () => (
                            <span>Meeting Title</span>
                        ),
                        accessor: "title",
                        Cell: ({original}: { original: HistoricalMeeting }) => (
                            <span>
                                   {original.title}
                           </span>
                        ),
                        width: 340
                    },
                    {
                        Header: () => (
                            <span>Meeting Started By</span>
                        ),
                        accessor: "createdByName",
                        Cell: ({original}: { original: HistoricalMeeting }) => (
                            <span>
                                   {`${original.createdByName} (${original.createdBy})`}
                           </span>
                        ),
                        width: 230
                    },
                    {
                        Header: () => (
                            <span>Meeting Date</span>
                        ),
                        accessor: "startDate",
                        Cell: ({original}: { original: HistoricalMeeting }) => (
                            <span>
                                   {toDisplayDateFormat(original.startDate)}
                           </span>
                        ),
                        width: 150
                    },
                    {
                        Header: () => (
                            <span>Start Time (CT)</span>
                        ),
                        accessor: "startDate",
                        Cell: ({original}: { original: HistoricalMeeting }) => (
                            <span>
                                   {original.startDate ? moment(original.startDate).format('hh:mm A') : ''}
                           </span>
                        ),
                        width: 150
                    },
                    {
                        Header: () => (
                            <span>End Time (CT)</span>
                        ),
                        accessor: "endDate",
                        Cell: ({original}: { original: HistoricalMeeting }) => (
                            <span>
                                   {original.endDate ? moment(original.endDate).format('hh:mm A ') : ''}
                           </span>
                        ),
                        width: 150
                    },
                    {
                        Header: () => (<span>Meeting Type</span>),
                        accessor: "endDate",
                        Cell: ({original}: { original: HistoricalMeeting }) => (<span>{
                            mapMeetingTypeEnumToHumanReadable(original.type)
                        }</span>)
                    }
                ]}
            />
        </div>
    )
}
export default MeetingHistoryDetails;
