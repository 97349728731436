import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {FamilyTreeType, FamilyTreeViewportBounds} from 'src/ClientManagement/models/FamilyTreeType';
import type {RootState} from '../../store/store';
import {
    FamilyMemberForm,
    FamilyMemberFormValidationContainer,
    Gender,
    LifeStatus,
    MemberType,
    RelatesToType
} from "../models/MemberType";
import {FamilyMode} from "./AddFamilyMember/FamilyMode";


export enum SaveAction {
    Create = "Create",
    Edit = "Edit"
}

export const emptyMemberForm: FamilyMemberForm = {
    firstName: '',
    middleInitial: null,
    lastName: '',
    birthdate: null,
    age: 0,
    gender: Gender.Undisclosed,
    stateOfResidency: null,
    personalPlanningHorizon: 0,
    lifeStatus: LifeStatus.Living,
    deceasedDate: null,
}
export const getEmptyMemberForm: () => FamilyMemberForm = () => ({...emptyMemberForm});

const initialFormValidationState: FamilyMemberFormValidationContainer = {familyMemberForm: null};
export const getInitialFormValidationState: () => FamilyMemberFormValidationContainer = () => ({...initialFormValidationState});

export type FamilyFormInteractions = {
    firstName: boolean,
    lastName: boolean,
    age: boolean,
    personalPlanningHorizon: boolean
}

export const emptyFormInteractions: FamilyFormInteractions = {
    firstName: false,
    lastName: false,
    age: false,
    personalPlanningHorizon: false
}
export const getEmptyFamilyFormInteractions: () => FamilyFormInteractions = () => ({...emptyFormInteractions});

export type FamilyRelationshipState = RelatesToType & {
    fullName: string,
}

export interface FamilyTreeState {
    familyTree: FamilyTreeType;
    familyMemberForm: FamilyMemberForm;
    familyMemberFormSnapshot: FamilyMemberForm;
    isOpen: boolean;
    createEditAction: SaveAction;
    familyMode: FamilyMode;
    isValidForm: boolean;
    familyFormValidation: FamilyMemberFormValidationContainer;
    familyFormInteractions: FamilyFormInteractions;
    listOfStates: Array<string>,
    isSaveButtonClicked: boolean,
    relationshipStates: FamilyRelationshipState[],
    relatedTo: MemberType,
    editMemberType?: MemberType,
    addMemberChildren?: Array<string>,
    familyTreeViewportBounds: FamilyTreeViewportBounds | null,
    isOtherMembersSelected: boolean,
    isExtendedFamilySelected: boolean,
    changedAParent: boolean
}

// initialState can be a value or a function. In this case, it's a function because `null` doesn't have enough type
// information by itself to say that it can also be of type FamilyTreeType.
export const DEFAULT_FAMILY_TREE_STATE: FamilyTreeState = {
    familyTree: {
        profileId: "",
        displayName: "",
        primaryContact: {...emptyMemberForm, id: "", family: [], otherMembers: []}
    },
    familyMemberForm: getEmptyMemberForm(),
    familyMemberFormSnapshot: getEmptyMemberForm(),
    isOpen: false,
    createEditAction: SaveAction.Create,
    familyMode: FamilyMode.IMMEDIATE,
    isValidForm: false,
    familyFormValidation: getInitialFormValidationState(),
    familyFormInteractions: getEmptyFamilyFormInteractions(),
    listOfStates: [],
    isSaveButtonClicked: false,
    relationshipStates: [],
    relatedTo: {...emptyMemberForm, id: "", family: [], otherMembers: []},
    familyTreeViewportBounds: null,
    isOtherMembersSelected: false,
    isExtendedFamilySelected: false,
    changedAParent: false
}
export const familyTreeSlice = createSlice({
    name: 'familyTree',
    initialState: {...DEFAULT_FAMILY_TREE_STATE},
    reducers: {
        // reducers need to return the new state, given the existing state and the action. In this case, the new state is
        // just the payload of the action; in other cases, you can mutate the state object, and changes to that state will
        // be recorded to create the new state.
        setFamilyTree: (state, action: PayloadAction<FamilyTreeType>) => {
            state.familyTree = action.payload;
        },
        setFamilyMemberForm: (state, action: PayloadAction<FamilyMemberForm>) => {
            state.familyMemberForm = action.payload;
        },
        setFamilyMemberFormSnapshot: (state, action: PayloadAction<FamilyMemberForm>) => {
            state.familyMemberFormSnapshot = action.payload;
        },
        setIsOpen: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
        setSaveAction: (state, action: PayloadAction<SaveAction>) => {
            state.createEditAction = action.payload;
        },
        setFamilyMode: (state, action: PayloadAction<FamilyMode>) => {
            state.familyMode = action.payload;
        },
        setIsValidForm: (state, action: PayloadAction<boolean>) => {
            state.isValidForm = action.payload;
        },
        setFamilyFormValidation: (state, action: PayloadAction<FamilyMemberFormValidationContainer>) => {
            state.familyFormValidation = action.payload;
        },
        setFamilyFormInteractions: (state, action: PayloadAction<FamilyFormInteractions>) => {
            state.familyFormInteractions = action.payload;
        },
        setListOfStates: (state, action: PayloadAction<Array<string>>) => {
            state.listOfStates = action.payload;
        },
        setIsSaveButtonClicked: (state, action: PayloadAction<boolean>) => {
            state.isSaveButtonClicked = action.payload;
        },
        setRelationshipStates: (state, action: PayloadAction<FamilyRelationshipState[]>) => {
            state.relationshipStates = action.payload;
        },
        setRelatedTo: (state, action: PayloadAction<MemberType>) => {
            state.relatedTo = action.payload;
        },
        setEditMemberType: (state, action: PayloadAction<MemberType>) => {
            state.editMemberType = action.payload;
        },
        setAddMemberChildren: (state, action: PayloadAction<Array<string>>) => {
            state.addMemberChildren = action.payload;
        },
        setFamilyTreeViewportBounds: (state, action: PayloadAction<FamilyTreeViewportBounds>) => {
            state.familyTreeViewportBounds = action.payload;
        },
        setIsOtherMembersSelected: (state, action: PayloadAction<boolean>) => {
            state.isOtherMembersSelected = action.payload;
        },
        setIsExtendedFamilySelected: (state, action: PayloadAction<boolean>) => {
            state.isExtendedFamilySelected = action.payload;
        },
        setChangedAParent: (state, action: PayloadAction<boolean>) => {
            state.changedAParent = action.payload;
        },
        resetFamilyTreeToDefaults: (state) => {
            state.familyTreeViewportBounds = null;
            state.isOtherMembersSelected = false;
            state.isExtendedFamilySelected = false;
        },
        resetFamilyTreeZoomViewportBounds: (state) => {
            state.familyTreeViewportBounds = null;
        },
    },
});

export const {
    setFamilyTree,
    setFamilyMemberForm,
    setFamilyMemberFormSnapshot,
    setIsOpen,
    setSaveAction,
    setFamilyMode,
    setIsValidForm,
    setFamilyFormValidation,
    setFamilyFormInteractions,
    setListOfStates,
    setIsSaveButtonClicked,
    setRelationshipStates,
    setRelatedTo,
    setEditMemberType,
    setAddMemberChildren,
    setFamilyTreeViewportBounds,
    setIsOtherMembersSelected,
    setIsExtendedFamilySelected,
    setChangedAParent,
    resetFamilyTreeToDefaults,
    resetFamilyTreeZoomViewportBounds
} = familyTreeSlice.actions;

export const selectFamilyTree = (state: RootState) => state.client.familyTree.familyTree;
export const selectFamilyMemberForm = (state: RootState) => state.client.familyTree.familyMemberForm;
export const selectFamilyMemberFormSnapshot = (state: RootState) => state.client.familyTree.familyMemberFormSnapshot;
export const selectIsOpen = (state: RootState) => state.client.familyTree.isOpen;
export const selectSaveAction = (state: RootState) => state.client.familyTree.createEditAction;
export const selectFamilyMode = (state: RootState) => state.client.familyTree.familyMode;
export const selectIsValidForm = (state: RootState) => state.client.familyTree.isValidForm;
export const selectFamilyFormValidation = (state: RootState) => state.client.familyTree.familyFormValidation;
export const selectFamilyFormInteractions = (state: RootState) => state.client.familyTree.familyFormInteractions;
export const selectListOfStates = (state: RootState) => state.client.familyTree.listOfStates;
export const selectIsSaveButtonClicked = (state: RootState) => state.client.familyTree.isSaveButtonClicked;
export const selectRelationshipStates = (state: RootState) => state.client.familyTree.relationshipStates;
export const selectRelatedTo = (state: RootState) => state.client.familyTree.relatedTo;
export const selectEditMemberType = (state: RootState) => state.client.familyTree.editMemberType;
export const selectAddMemberChildren = (state: RootState) => state.client.familyTree.addMemberChildren;
export const selectFamilyTreeViewportBounds = (state: RootState) => state.client.familyTree.familyTreeViewportBounds;
export const selectIsOtherMembersSelected = (state: RootState) => state.client.familyTree.isOtherMembersSelected;
export const selectIsExtendedFamilySelected = (state: RootState) => state.client.familyTree.isExtendedFamilySelected;
export const selectChangedAParent = (state: RootState) => state.client.familyTree.changedAParent;

export default familyTreeSlice.reducer;
