import {TabBar as XpsTabBar} from "xps-react";
import React, {ReactNode} from "react";

type TabBarProps = {
    size?: 'small' | 'medium' | 'large',
    className?: string,
    theme?: 'dark' | 'light',
    children: ReactNode
}

const TabBar = ({size, className, theme, children,}: TabBarProps) => {
    return <XpsTabBar size={size}
                      className={className}
                      theme={theme}>
        {children}</XpsTabBar>
}

export default TabBar;
