import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectAssetRelianceResponse, setAssetRelianceResponse} from "../AssetRelianceSlice";
import {useHistory, useParams} from "react-router-dom";
import {wealthManagementApiClient} from "../../WealthManagementApiClient";
import {RouteWithId} from "../../../routes/types";
import EditAssetRelianceContent from "./EditAssetRelianceContent";
import {SaveAssetRelianceRequest} from "../../models/AssetRelianceRequest";
import {EditAssetRelianceResponse} from "./EditAssetRelianceResponse";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import usePageViewTimer from "../../../hooks/usePageViewTimer";
import usePrevious from "../../../utils/usePrevious";
import {resetAccordionTableState} from "../../../components/AccordionTable/AccordionTableSlice";
import AssetRelianceFooter from "../AssetRelianceFooter";
import {selectReleaseToggles} from "../../../ReleaseToggles/releaseTogglesSlice";

const EditAssetReliance: React.FC = () => {
    const {id} = useParams<RouteWithId>();
    const storedAssetRelianceResponse = useAppSelector(selectAssetRelianceResponse);
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const [pageLoaded, setPageLoaded] = useState<boolean>(false);
    usePageViewTimer('Edit Asset Reliance Page Load Timer (milliseconds)', !pageLoaded);

    const dispatch = useAppDispatch();
    const history = useHistory();
    const previousProfileId = usePrevious(id);

    const shouldFetchDataForProfileId = (previousId: string) => id && id !== previousId;

    useEffect(() => {
        let controller: AbortController;
        if (shouldFetchDataForProfileId(previousProfileId || storedAssetRelianceResponse.profileId)) {
            setPageLoaded(false);
            controller = new AbortController();
            const {signal} = controller;
            wealthManagementApiClient.getAssetReliance(id, signal)
                .then((response) => {
                    dispatch(setAssetRelianceResponse(response));
                    setPageLoaded(true);
                }).catch(error => {
                console.error('Could not fetch asset reliance', error.message)
            });
        }
        return () => {
            if (controller) {
                controller.abort();
            }
            dispatch(resetAccordionTableState());
        };
    }, [id]);

    useEffect(() => {
        if(releaseToggles?.enableUpdatesToEditAssetReliance) {
            const timeout = setTimeout(() => {
                setPageLoaded(true);
            }, 0)

            return () => clearTimeout(timeout);
        }
    }, [])

    const assetRelianceResponse = new EditAssetRelianceResponse(storedAssetRelianceResponse);

    if (!pageLoaded  && releaseToggles?.enableUpdatesToEditAssetReliance) {
        return <LoadingIndicator/>
    }

    return (
        <div className="edit-asset-reliance-page">
            <>
                <EditAssetRelianceContent
                    assetRelianceResponse={assetRelianceResponse}
                    handleSave={async (payload: SaveAssetRelianceRequest) => {
                        await wealthManagementApiClient.postAssetReliance(assetRelianceResponse.profileId, payload).catch(error => {
                            console.error('Could not save asset reliance', error.message)
                        });
                        history.push(`/Profile/${assetRelianceResponse.profileId}/ClientProfile/AssetReliance`)
                    }}
                    handleCancel={() => {
                        history.push(`/Profile/${assetRelianceResponse.profileId}/ClientProfile/AssetReliance`);
                    }}
                />
                <AssetRelianceFooter/>
            </>
        </div>
    )
}

export default EditAssetReliance;