import {Icon} from "../../components";
import DdsPopover from "@nt/dds-react/core/Popover";
import React from "react";
import {formatCurrency} from "../../utils/format";

type FamilyGoalPopoverProps = {
    beneficiaryId?: string,
    totalPresentValue?: number,
    appliedFamilyAssets?: number,
    myResponsibility?: number,
    isFundedByNonInvestableAssets: boolean,
    hasOneOrMoreGoals?: boolean
}

const getMessage = (isFundedByNonInvestableAssets: boolean,
                    hasFamilyAssetsApplied: boolean,
                    hasOneOrMoreGoals: boolean): string => {
    const subject = hasOneOrMoreGoals ? 'One or more goals' : 'This goal is';
    if (isFundedByNonInvestableAssets && !hasFamilyAssetsApplied) {
        return `${subject} funded by non-investable assets.`;
    } else if (isFundedByNonInvestableAssets && hasFamilyAssetsApplied) {
        if (hasOneOrMoreGoals) {
            return `${subject} is funded by non-investable assets, and one or more goals is funded by family assets:`;
        } else {
            return `${subject} funded by non-investable assets and family assets:`;
        }
    } else if (!isFundedByNonInvestableAssets && hasFamilyAssetsApplied) {
        return `${subject} funded by family assets:`;
    } else
        return "";
};

export const PopoverContent = ({
                                   totalPresentValue,
                                   appliedFamilyAssets,
                                   myResponsibility,
                                   isFundedByNonInvestableAssets,
                                   hasOneOrMoreGoals = false
                               }: FamilyGoalPopoverProps) => {

    const hasFamilyAssetsApplied = appliedFamilyAssets ? appliedFamilyAssets > 0 : false;
    const message = getMessage(isFundedByNonInvestableAssets, hasFamilyAssetsApplied, hasOneOrMoreGoals);

    return (
        <>
            <div className="padding-md">
                <i>{message}</i>
            </div>
            {hasFamilyAssetsApplied &&
                <>
                    <hr/>
                    <div className="padding-md">
                        <div className="display-flex justify-content-between">
                            <label className="fontweight-medium">Total Present Value</label>
                            <label>{formatCurrency(totalPresentValue)}</label>
                        </div>
                        <div className="display-flex justify-content-between paddingtop-md">
                            <label className="fontweight-medium">Applied Family Assets</label>
                            <label>{formatCurrency(appliedFamilyAssets)}</label>
                        </div>
                    </div>
                    <hr/>
                    <div className="display-flex justify-content-between padding-md">
                        <label className="fontweight-medium">My Responsibility</label>
                        <label>{formatCurrency(myResponsibility)}</label>
                    </div>
                </>
            }
        </>
    )
}

const GoalsTypePopover = ({
                               beneficiaryId,
                               totalPresentValue,
                               appliedFamilyAssets,
                               myResponsibility,
                               isFundedByNonInvestableAssets,
                               hasOneOrMoreGoals = false,
                           }: FamilyGoalPopoverProps) => {

    return (
        <DdsPopover
            width={300}
            id={`family-goals-popover-${beneficiaryId}`}
            delay={0}
            content={
                <PopoverContent
                    beneficiaryId={beneficiaryId}
                    appliedFamilyAssets={appliedFamilyAssets}
                    totalPresentValue={totalPresentValue}
                    myResponsibility={myResponsibility}
                    isFundedByNonInvestableAssets={isFundedByNonInvestableAssets}
                    hasOneOrMoreGoals={hasOneOrMoreGoals}
                />
            }
            direction="top"
            aria-label="family-goal-popover"
        >
            <Icon className="popover-trigger popoverContainer"
                  name="link"
                  ariaHidden={false}
                  ariaLabel="associated-family-goal"
            />
        </DdsPopover>
    )
};

export default GoalsTypePopover;
