import {DriverError, DriverErrorType} from "@fluidframework/driver-definitions";

export const isRelayContainerNotFoundError = (error: unknown) => {
    return isRelayDriverError(error) && error.errorType === DriverErrorType.fileNotFoundOrAccessDeniedError;
};

const isRelayDriverError = (error: unknown): error is DriverError => {
    return !!error && typeof error === 'object'
        && error.hasOwnProperty('errorType')
        && error.hasOwnProperty('message');
};