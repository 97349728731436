import React from "react";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../../routes/types";
import {AgendaItem,} from "../../../Agenda/models/AgendaDetails";
import {AgendaEntry} from "../../../Agenda/AgendaEntryList";
import {NO_OP} from "../../../constants/common";
import {Logo} from "xps-react";
import moment from "moment";
import {DEFAULT_DISCUSSION_TOPIC} from "../../../Agenda/agendaConstants";
import PrintViewWrapper from "../PrintViewWrapper";

import useAgendaDetails from "../../../Agenda/useAgendaDetails";

const AgendaContainer = () => {
    const {id} = useParams<RouteWithId>();

    const {agendaSummary, splittedData, displayName, isPresentersContinued} = useAgendaDetails(id);
    const areMeetingDetailsSaved = agendaSummary && agendaSummary.id && agendaSummary.meetingTitle && agendaSummary.meetingDate && agendaSummary.presenters.length;

    return <>{splittedData.map((meetingAgenda, index) => {
            const isNotFirstPage = (index && isPresentersContinued) ? <i>(continued)</i> : '';
            return <PrintViewWrapper displayName={displayName} key={index}>
                <div className="agenda" data-testid={"agenda-summary"}>
                    <article className="agenda-page layout-sidebar-left">
                        {areMeetingDetailsSaved ?
                            (<section data-theme="aqua">
                                <div className="agenda-page__cover">
                                    <div className="agenda-page__edit-meeting-details">
                                        <Logo color="black" logoType="single" width="250" ariaLabel="Northern Trust Logo"/>
                                    </div>
                                    <div className="agenda-page__cover__title">
                                        <h1 className="margintop-none marginright-xxl">{agendaSummary.meetingTitle}</h1>
                                        <div className={"agenda-meeting-detail-header"}>
                                            <h5>
                                                <span>{`Prepared for ${agendaSummary.clientProfileName}`}</span>
                                                <br/>
                                                <span>{moment(agendaSummary.meetingDate).format('dddd, MMMM Do YYYY')}</span>
                                                <br/>
                                                <span>{agendaSummary.meetingLocation}</span>
                                                <br/>
                                            </h5>
                                        </div>
                                        <hr/>
                                    </div>
                                    {meetingAgenda.presenters && <div className="agenda-page__cover__team" data-testid={"presenters-list"}>
                                        <span
                                            className="agenda-page__cover__team__presented-by margintop-none marginbottom-lg">
                                          Presented by your Northern Trust Team {isNotFirstPage}
                                        </span>
                                        <br/>
                                        <br/>
                                        {meetingAgenda.presenters &&
                                            <div className="agenda-page__cover__team__members">
                                                {meetingAgenda.presenters.filter(p => p.presenterType === 'NT_PRESENTER').map(({
                                                                                                                                   presenterName,
                                                                                                                                   presenterTitle
                                                                                                                               }, presenterIndex) => (
                                                    <div className="agenda-page__cover__team__member" key={presenterIndex}>
                                                        <b className="margintop-none marginbottom-xs">{presenterName}</b>
                                                        <br/>
                                                        {presenterTitle ? <><span>{presenterTitle}</span><br/></> : ""}
                                                        <br/>
                                                    </div>
                                                ))}
                                            </div>}
                                        {(meetingAgenda.presenters && meetingAgenda.presenters.findIndex(p => p.presenterType === "EXTERNAL_PRESENTER") >= 0) && <>
                                            <span
                                                className="agenda-page__cover__team__presented-by margintop-none marginbottom-lg">
                                              <b>Other Presenters</b>
                                            </span>
                                            <br/>
                                            <br/>
                                            <div className="agenda-page__cover__team__members">
                                                {meetingAgenda?.presenters.filter(p => p.presenterType === "EXTERNAL_PRESENTER").map(({
                                                                                                                                          presenterType,
                                                                                                                                          presenterName,
                                                                                                                                          presenterTitle
                                                                                                                                      }, extIndex) => (

                                                    <div className="agenda-page__cover__team__member" key={extIndex}>
                                                        <b className="margintop-none marginbottom-xs">{presenterName}</b>
                                                        <br/>
                                                        {presenterTitle && <><span>{presenterTitle}</span><br/></>}
                                                        <br/>
                                                    </div>
                                                ))}
                                            </div>
                                        </>}
                                    </div>}
                                </div>
                            </section>)
                            : (<section data-theme="aqua">
                                <div className="agenda-page__cover">
                                    <div className="agenda-page__cover__title">
                                        <Logo color="black" logoType="single" width="250" ariaLabel="Northern Trust Logo"/>
                                    </div>
                                    <div className="agenda-page__cover__empty">
                                    </div>
                                </div>
                            </section>)}
                        <section className="margin-xxl">
                            <div className="agenda-page__schedule">
                                <h2 className="marginbottom-xxxl">Today's Agenda {index > 0 ? <i>(continued)</i> : ''}</h2>
                                {meetingAgenda?.agendaItems &&
                                    <div
                                        className="agenda-page__schedule__list"
                                        aria-label="Agenda Entries"
                                    >
                                        {meetingAgenda.agendaItems.map((agendaItem: AgendaItem, agendaItemIndex) => {
                                            return (<div
                                                aria-label="Agenda Entry"
                                                className={"agenda-page__schedule__list__item"}
                                                key={agendaItemIndex}
                                            >
                                                <AgendaEntry
                                                    agendaItem={agendaItem} dataIndex={agendaItemIndex}
                                                    onClickEditEntryItem={NO_OP}
                                                    onDeleteEntryItem={NO_OP}
                                                    agendaEntryFormData={DEFAULT_DISCUSSION_TOPIC}
                                                    setAgendaEntryFormData={NO_OP}/>
                                            </div>)
                                        })
                                        }
                                    </div>}
                            </div>
                        </section>
                    </article>
                </div>
            </PrintViewWrapper>
        }
    )
    }</>
}

export default AgendaContainer;