import {WarningPopup} from "./WarningPopup";
import React from "react";

export function SetReserveTargetModal(props: { open: boolean, onOK: () => void }) {
    return <WarningPopup
        id="set-pr-warning-modal"
        data-testid={"set-pr-warning-modal"}
        isOpen={props.open}
        headerText={"The Portfolio Reserve cannot be turned on until a Reserve Target is set in the profile."}
        buttons={[{text: "OKAY", onClick: props.onOK}]}
    />;
}