import React, {useEffect, useState} from 'react';
import LoadingIndicator from "../../pages/LoadingIndicator";
import {reportingApiClient} from "../ReportingApiClient";
import {App_Conf} from "../../core/app_conf";
import {RouteWithId} from "../../routes/types";
import {useParams} from "react-router-dom";
import {Document, Page} from "react-pdf";
import {AdvisorDocumentMap, GenerateReportRequest, ReportConfig} from "../models/Reporting";
import {useAppSelector} from "../../store/hooks";
import {
    selectFamilyTreeViewportBounds,
    selectIsExtendedFamilySelected,
    selectIsOtherMembersSelected
} from "../../ClientManagement/FamilyTree/FamilyTreeSlice";
import {
    selectAdviceLibraryDocuments, selectOptionsForGoalFundingReport,
    selectOptionsForNWOTReport,
    selectPracticeDocuments,
    selectSelectedClientDocuments,
    selectSelectedMyDocuments,
    selectSelectedPagesForReport
} from "./ReportingSlice";
import usePageViewTimer from "../../hooks/usePageViewTimer";
import {selectAdviceLibrarySubMenus, selectPracticeDocumentsSubMenus} from "../../Resources/menuStructureSlice";
import ReportingConnectionFailedPage from './ReportingConnectionFailedPage';
import {DCHToasts, MessageFromDCH} from "./DCHToasts";
import {
    selectAssetReliancePageActionMenu,
    selectAssetReliancePageActionMenuForDetailedReport, selectAssetReliancePageActionMenuForSummaryReport
} from "../../ClientManagement/AssetReliance/AssetRelianceSlice";
import {FveDiscountRateType} from "../../ClientManagement/AssetReliance/AssetRelianceButtonState";

type PreviewReportProps = {
    displayName: string,
    isGenerating: boolean,
    isUploading: boolean,
    setIsGenerating: (value: boolean) => void,
    generatedReportUrl: string,
    setGeneratedReportUrl: (url: string) => void,
    showMessageFromDCH?: boolean,
    messageFromDCH?: MessageFromDCH
    onCloseSuccessMessage?: () => void,
    onCloseFailureMessage?: () => void,
    showFailureMessageFromWDS?: boolean,
    failureMessageFromWDS?: string,
    hasError: boolean,
    setHasError: (value: boolean) => void
}

const PreviewReport = ({
                           displayName,
                           isGenerating,
                           isUploading,
                           setIsGenerating,
                           generatedReportUrl,
                           setGeneratedReportUrl,
                           showMessageFromDCH = false,
                           messageFromDCH = {
                               title: "",
                               message: "",
                               type: ""
                           },
                           onCloseSuccessMessage,
                           onCloseFailureMessage,
                           showFailureMessageFromWDS = false,
                           failureMessageFromWDS = "",
                           hasError,
                           setHasError
                       }: PreviewReportProps) => {
    const {id} = useParams<RouteWithId>();
    const [noOfPages, setNoOfPages] = useState(0);
    const [reportStatus, setReportStatus] = useState<string>("");

    const familyTreeViewportBounds = useAppSelector(selectFamilyTreeViewportBounds);
    const nwotOptions = useAppSelector(selectOptionsForNWOTReport);
    const goalOptions = useAppSelector(selectOptionsForGoalFundingReport);
    const isOtherMembersSelected = useAppSelector(selectIsOtherMembersSelected);
    const isExtendedFamilySelected = useAppSelector(selectIsExtendedFamilySelected);
    const selectedPagesForAnnualReportFromRedux = useAppSelector(selectSelectedPagesForReport);
    const selectedClientDocuments = useAppSelector(selectSelectedClientDocuments);
    const selectedMyDocuments = useAppSelector(selectSelectedMyDocuments);
    const selectedAdviceLibraryDocuments = useAppSelector(selectAdviceLibraryDocuments);
    const selectedPracticeDocuments = useAppSelector(selectPracticeDocuments);
    const reportConfig: ReportConfig = JSON.parse(JSON.stringify(selectedPagesForAnnualReportFromRedux));
    const pagesList = Array.apply(null, Array(noOfPages));
    const adviceLibrarySubMenu = useAppSelector(selectAdviceLibrarySubMenus);
    const practiceDocumentSubMenu = useAppSelector(selectPracticeDocumentsSubMenus);
    const assetRelianceButtonStateForSummaryReport = useAppSelector(selectAssetReliancePageActionMenuForSummaryReport);
    const assetRelianceButtonStateForDetailedReport = useAppSelector(selectAssetReliancePageActionMenuForDetailedReport);


    let statusInterval: NodeJS.Timer;
    let statusCount: number = 0;

    usePageViewTimer('Generate Report Page Load Timer (milliseconds)', isGenerating);

    useEffect(() => {
        setIsGenerating(true);

        return () => {
            URL.revokeObjectURL(generatedReportUrl);
        }
    }, []);

    useEffect(() => {
        if (reportStatus && reportStatus === "COMPLETED") {
            reportingApiClient.getGeneratedReport(id).then((blobPdf) => {
                setHasError(false);
                setIsGenerating(false);
                if (blobPdf.size) {
                    const blobUrl = URL.createObjectURL(blobPdf);
                    setGeneratedReportUrl(blobUrl);
                }
            }).catch(resetReportStates);
        }

        if (reportStatus && reportStatus === "IN PROGRESS") {
            statusInterval = setInterval(() => {
                reportingApiClient.getReportingStatus(id).then((reportStatusResponse) => {
                    ++statusCount;
                    if (statusCount === 300) {
                        clearInterval(statusInterval);
                        resetReportStates();
                    }
                    if (reportStatusResponse.status === "COMPLETED") {
                        clearInterval(statusInterval);
                        setReportStatus(reportStatusResponse.status);
                    }
                }).catch(() => {
                    resetReportStates();
                    clearInterval(statusInterval);
                });
            }, 3000);
        }

        return () => statusInterval && clearInterval(statusInterval);
    }, [reportStatus]);

    useEffect(() => {
        if (isGenerating && !reportStatus) {
            generateClientReport();
        }

    }, [isGenerating]);

    function resetReportStates() {
        statusCount = 0;
        setReportStatus("");
        setIsGenerating(false);
        setHasError(true);
    }

    const generateClientReport = () => {
        URL.revokeObjectURL(generatedReportUrl);

        if (reportConfig.familyTree !== null && reportConfig.familyTree.isEnabled) {
            reportConfig.familyTree.familyTreeScreenShotDetails = {
                familyMembersDetails: familyTreeViewportBounds,
                isOtherMembersSelected: isOtherMembersSelected,
                isExtendedFamilySelected: isExtendedFamilySelected
            };
        }
        if (reportConfig.netWorthOverTime !== null && reportConfig.netWorthOverTime.isEnabled) {
            reportConfig.netWorthOverTime.netWorthOverTimeOptions = {
                discountRate: nwotOptions.discountRate,
                insuranceIncluded: nwotOptions.insuranceIncluded
            }
        }

        if (reportConfig.goalFunding !== null && reportConfig.goalFunding.isEnabled) {
            reportConfig.goalFunding.goalFundingOptions = {
                displayView: goalOptions.displayView,
                displayOptions: goalOptions.displayOptions,
                fundingAge: goalOptions.fundingAge
            }
        }

        if (reportConfig.assetRelianceWithFutureValue !== null && reportConfig.assetRelianceWithFutureValue.isEnabled) {
            reportConfig.assetRelianceWithFutureValue.assetRelianceOptions = {
                showExpectedExcessAsset: assetRelianceButtonStateForSummaryReport.showExpectedExcessAsset,
                includeLifeInsuranceAtDeath: assetRelianceButtonStateForSummaryReport.includeLifeInsuranceAtDeath,
                selectedFveDiscountRate: assetRelianceButtonStateForSummaryReport.selectedFveDiscountRate
            }
        }

        if (reportConfig.assetRelianceDetailedWithFutureValue !== null && reportConfig.assetRelianceDetailedWithFutureValue.isEnabled) {
            reportConfig.assetRelianceDetailedWithFutureValue.assetRelianceOptions = {
                showExpectedExcessAsset: assetRelianceButtonStateForDetailedReport.showExpectedExcessAsset,
                includeLifeInsuranceAtDeath: assetRelianceButtonStateForDetailedReport.includeLifeInsuranceAtDeath,
                selectedFveDiscountRate: assetRelianceButtonStateForDetailedReport.selectedFveDiscountRate
            }
        }

        const advisorDocumentDetailsLocal: AdvisorDocumentMap = {};
        advisorDocumentDetailsLocal["AdviceLibrary"] = {
            advisorDocumentMap: {},
            menuTitle: "AdviceLibrary"
        }
        adviceLibrarySubMenu.advisorDocumentSubMenus.forEach(submenu => {
            let documentsList: string[] = [];
            submenu.subMenuItems.forEach(item => {
                if (selectedAdviceLibraryDocuments.includes(item.documentLabel)) {
                    documentsList.push(item.documentLabel)
                }
            });

            if (documentsList.length !== 0) {
                advisorDocumentDetailsLocal["AdviceLibrary"].advisorDocumentMap[submenu.subMenuLabel] = documentsList;
            }
        });

        advisorDocumentDetailsLocal["PracticeDocuments"] = {
            advisorDocumentMap: {},
            menuTitle: "PracticeDocuments"
        }
        practiceDocumentSubMenu.advisorDocumentSubMenus.forEach(submenu => {
            let doclist: string[] = [];
            submenu.subMenuItems.forEach(item => {
                if (selectedPracticeDocuments.includes(item.documentLabel)) {
                    doclist.push(item.documentLabel)
                }
            });

            if (doclist.length !== 0) {
                advisorDocumentDetailsLocal["PracticeDocuments"].advisorDocumentMap[submenu.subMenuLabel] = doclist;
            }
        });

        const generateReportRequest: GenerateReportRequest = {
            pageUrl: `${App_Conf.APP_URL}/ReportPreview/${id}`,
            config: {
                ...reportConfig,
                coverPage: {
                    ...reportConfig.coverPage,
                    isEnabled: true
                },
                disclaimerPage: {
                    ...reportConfig.disclaimerPage,
                    isEnabled: true
                }
            },
            documentMap: advisorDocumentDetailsLocal,
            clientDocuments: selectedClientDocuments.map(clientDocument => {
                const extension = clientDocument.name.split('.').pop();
                return clientDocument.id + "." + extension;
            }),
            myDocuments: selectedMyDocuments.map(myDocument => {
                const extension = myDocument.name.split('.').pop();
                return myDocument.id + "." + extension;
            }),
            displayName: displayName
        };

        reportingApiClient.generateReport(generateReportRequest, id).then(responseStatus => {
            setReportStatus(responseStatus.status);
        }).catch(resetReportStates);
    };

    const refreshReport = () => {
        setIsGenerating(true);
    }

    if (!displayName) {
        return <LoadingIndicator/>
    }

    if (isGenerating) {
        return <LoadingIndicator className="loading-generate-report"
                                 displayMessage="Generating Report"
                                 displaySubText="Please wait as this might take a few minutes..."
        />
    }

    if (isUploading) {
        return <LoadingIndicator className="loading-generate-report"
                                 displayMessage="Uploading Report To Private Passport."
                                 displaySubText="Please wait as this might take a few seconds..."
        />
    }


    function onCloseSuccess() {
        if (onCloseSuccessMessage) {
            onCloseSuccessMessage()
        }
    }

    function onCloseFailure() {
        if (onCloseFailureMessage) {
            onCloseFailureMessage()
        }
    }

    return (
        <>
            {hasError ? <ReportingConnectionFailedPage onRefresh={refreshReport}/>
                :
                <div>
                    {(showMessageFromDCH || showFailureMessageFromWDS) && <div>
                        <DCHToasts  showMessageFromDCH={showMessageFromDCH} messageFromDCH={messageFromDCH} onCloseSuccessMessage={onCloseSuccess} onCloseFailureMessage={onCloseFailure} showFailureMessageFromWDS={showFailureMessageFromWDS} failureMessageFromWDS={failureMessageFromWDS}/>
                    </div>}
                    <div className='preview-and-send' data-theme="print">
                        <Document
                            className="pdf-preview-pane__container"
                            file={generatedReportUrl}
                            error="PDF is missing"
                            onLoadSuccess={({numPages}) => {
                                setNoOfPages(numPages)
                            }}
                        >
                            {pagesList
                                .map((_page, index) =>
                                    <Page
                                        key={index}
                                        pageNumber={index + 1}
                                        scale={120 / 60}
                                    />
                                )
                            }
                        </Document></div>
                </div>
            }
        </>
    );
};

export default PreviewReport;
