import {Icon} from "../../components";
import {COLOR_NT_AQUA_800} from "../../constants/colors";
import React from "react";

type disclaimerProp = {
    origin: string;
};

export const DisclaimerMessage = ({origin}:disclaimerProp) => {
    return (
        <>
            <footer className="search-bar-info-message">
                <Icon name="info_outline" color={COLOR_NT_AQUA_800}/>
                <span className="paddingleft-md">
                    {
                        (origin === "POIA" || "IP") ?
                        "Market values displayed exclude sundry assets, personal real estate, promissory notes, and " +
                            "doubtful and desperate securities. If these are relevant to the capital allocation process, " +
                            "they should be added manually." : null
                    }
                    </span>
            </footer>
        </>
    )
}
