import React, {useMemo, useState} from "react";
import {NavLink, useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {clearPartnerWorkstationState} from "../../ClientManagement/PartnerWorkstation/partnerWorkstationSlice";
import AppHeader from "./AppHeader";
import {useMsal} from "@azure/msal-react";
import {handleLogout} from "../../MsalUtils";
import LogoutModal from "../Modal/Logout/LogoutModal";
import {
    resetAudience,
    resetMeeting,
    selectActiveMeeting,
    selectParticipatingInMeeting,
    setShowMeetingControls
} from "../../ClientManagement/Meeting/meetingSlice";
import {ConfirmExitMeetingModal} from "../../ClientManagement/Meeting/MeetingActions";
import {useCommunicationsContext} from "../../ClientManagement/Meeting/CommunicationsContext";
import {useRelayContext} from "../../ClientManagement/Meeting/Relay/types/RelayContext";
import useMeetingUtils from "../../ClientManagement/Meeting/useMeetingUtils";
import {Meeting, MeetingStatus} from "../../ClientManagement/Meeting/Meeting";
import {meetingApiClient} from "../../ClientManagement/Meeting/MeetingApiClient";

const Header: React.FC = () => {
    const history = useHistory();
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    const [isConfirmExitMeetingModalOpen, setConfirmExitMeetingModalOpen] = useState(false);

    const dispatch = useAppDispatch();
    const msal = useMsal();
    const communicationsContext = useCommunicationsContext();

    const isParticipatingInMeeting = useAppSelector(selectParticipatingInMeeting);
    const activeMeeting = useAppSelector(selectActiveMeeting) as Meeting;

    const {unloadMeetingContainer} = useRelayContext();
    const {isCurrentUserPresenting} = useMeetingUtils();

    const navigateHome = () => {
        dispatch(clearPartnerWorkstationState());
        if(isParticipatingInMeeting) {
            history.push({pathname: `/Profile/${activeMeeting.profileId}`})
        }
        else {
            history.push({pathname: `/`})
        }
    }

    const showExitMeetingModal = () => {
        setConfirmExitMeetingModalOpen(true);
    }

    const logoutLink = useMemo(() => {
        return [
            {
                id: 'Logout',
                name: "log out",
                enabled: true,
                customHandler: () => setIsLogoutModalOpen(true)
            }
        ]
    }, [])

    const onClickLogout = () => {
        handleLogout(msal);
    }
    const onClickCancel = () => {
        setIsLogoutModalOpen(false);
    }

    const handleExitMeeting = () => {
        communicationsContext.disposeVideoPreview();

        const updatedMeeting = {...activeMeeting, status: MeetingStatus.ENDED};
        (isCurrentUserPresenting
                ? meetingApiClient.updateMeeting(updatedMeeting)
                : Promise.resolve()
        ).then(() => {
            dispatch(resetMeeting());
        }).catch(error => {
            console.error('Could not update meeting', error.message)
        }).finally(() => {
            unloadMeetingContainer();
            communicationsContext.disconnect().then();
            dispatch(setShowMeetingControls(false));
            dispatch(resetMeeting());
            dispatch(resetAudience());

            navigateHome();
        });
    }

    return (
        <>
            <AppHeader
                appTitle="Goals Driven Wealth Management"
                onLogoClick={activeMeeting && isParticipatingInMeeting ? showExitMeetingModal : navigateHome}
                links={logoutLink}
                LinkRenderer={NavLink}
                headerToolbarAlignment="center"
                navigationStyle="header"
            />
            <LogoutModal isOpen={isLogoutModalOpen} onClickCancel={onClickCancel} onClickLogout={onClickLogout}/>
            {isConfirmExitMeetingModalOpen &&
                <ConfirmExitMeetingModal
                    isOpen={isConfirmExitMeetingModalOpen}
                    handleCancel={() => setConfirmExitMeetingModalOpen(false)}
                    handleExitMeeting={() => handleExitMeeting()}
                />
            }
        </>
    )
};

Header.displayName = "Header";
export default Header;

