import React from "react";
import {Container} from "react-grid-system";
import FutureEstateValueBarChartLegend from "./FutureEstateValueBarChartLegend";
import {COLOR_ASSETS_ACCOUNTS, COLOR_TAXES} from "../../constants/colors";
import FutureEstateValueBarCharts from "./FutureEstateValueBarCharts";
import {useAppSelector} from "../../store/hooks";
import {
    selectFutureEstateValueWithoutStrategies,
    selectFutureEstateValueWithStrategies
} from "../WealthPlanOptimizerSlice";

const FutureEstateValueCard: React.FC = () => {
    const futureEstateValueWithoutStrategies = useAppSelector(selectFutureEstateValueWithoutStrategies)
    const futureEstateValueWithStrategies = useAppSelector(selectFutureEstateValueWithStrategies);

    return (
        <Container className="future-estate-value-container">
            <div className="future-estate-value-header">
                <h4>Your Future Estate Value</h4>
                <div>
                    <FutureEstateValueBarChartLegend legendColor={COLOR_ASSETS_ACCOUNTS}
                                                     label={"Amount to Beneficiaries"}/>
                    <FutureEstateValueBarChartLegend legendColor={COLOR_TAXES}
                                                     label={"Est. Estate Tax"}/>
                </div>
            </div>
            <div className={"future-estate-value-bar-chart-container"}>
                <FutureEstateValueBarCharts
                    amountToBeneficiariesWithoutStrategies={futureEstateValueWithoutStrategies.totalAmountToBeneficiaries}
                    estEstateTaxWithoutStrategies={futureEstateValueWithoutStrategies.totalEstEstateTax}
                    amountToBeneficiariesWithStrategies={futureEstateValueWithStrategies.totalAmountToBeneficiaries}
                    estEstateTaxWithStrategies={futureEstateValueWithStrategies.totalEstEstateTax}
                />
            </div>
            <div className={"future-estate-value-footer"}>
                <span>Without Strategies</span>
                <span>With Strategies</span>
            </div>
        </Container>
    );
}

export default FutureEstateValueCard;