import {WarningPopup} from "./WarningPopup";
import React from "react";

export function ProposalMissingReserveTargetModal(props: { open: boolean, onConfirm: () => void, onCancel: () => void, children: JSX.Element[] }) {
    return <WarningPopup
        id="proposal-pr-warning-modal"
        isOpen={props.open}
        headerText={`You have not set a Reserve Target in the following proposals:`}
        buttons={[
            {
                text: "CONFIRM",
                onClick: props.onConfirm,
                primary: true,
            },
            {
                text: "CANCEL",
                onClick: props.onCancel,
            },
        ]}
        children={
            <>
                <ul className={"off-toggle-modal__list-item font-md"} data-testid={"proposals-without-pr-list"}>
                    {props.children}
                </ul>
                <div style={{fontSize: "13px"}}>
                    By turning on the Portfolio Reserve, the Reserve Target set at the profile will be applied to
                    these proposals.
                </div>
            </>
        }
    />;
}