import classNames from 'classnames';
import React, {ReactNode} from 'react';

type DataEntrySideDrawerProps = {
    children?: ReactNode,
    className?: string,
    direction?: 'left' | 'right',
    formLayout?: 'full' | 'split' | 'none',
    hideOverlay?: boolean,
    id?: string,
    isOpen?: boolean,
    onOverlayClick?: () => void,
    overlayClassName?: string,
    size?: 'small' | 'large' | 'full',
    scrollableRegionId?: string
}

export default function DataEntrySideDrawer({
                                                children,
                                                className,
                                                direction = 'right',
                                                formLayout = 'full',
                                                hideOverlay = false,
                                                id,
                                                isOpen = false,
                                                onOverlayClick,
                                                overlayClassName,
                                                size = 'small',
                                                scrollableRegionId
                                            }: DataEntrySideDrawerProps
) {
    return (
        <div
            className={classNames('data-entry-side-drawer', {
                'data-entry-side-drawer--open': isOpen,
                'data-entry-side-drawer--closed': !isOpen,
                [`data-entry-side-drawer--${size}`]: size,
                [`data-entry-side-drawer--${direction}`]: direction,
                className,
            })}
            id={id}
        >
            {
                !hideOverlay && (
                    <div
                        className={classNames('data-entry-side-drawer__overlay', overlayClassName)}
                        role={onOverlayClick && 'button'}
                        tabIndex={onOverlayClick && -1}
                        onClick={onOverlayClick}
                        onKeyDown={
                            onOverlayClick &&
                            (event => {
                                if (event.key === 'Enter') {
                                    onOverlayClick();
                                }
                            })
                        }
                    />
                )}
            {formLayout !== 'none' ? (
                <form
                    className={classNames('data-entry-side-drawer__form', 'layout-data-entry-form', {
                        [`layout-data-entry-form--${formLayout}`]: formLayout,
                    })}
                    id={scrollableRegionId}
                >
                    {children}
                </form>
            ) : (
                <div className="data-entry-side-drawer__content">{children}</div>
            )}
        </div>
    )
}
