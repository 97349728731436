import {emptyLifestylePresentValueResponse, LifestylePresentValueResponse} from "./PresentValue";
import {PlanningPeriodType} from "../../ClientManagement/models/InvestorGroupType";

export type LifestyleSpendingPeriodInputs = {
    id?: string;
    description: string;
    annualSpend: number;
    startYear: number;
    endYear: number;
}

export type LifestyleSpendingGoalResponse = {
    id?: string;
    clientId: string;
    userInputs: LifestyleSpendingGoalInputs
    calculatedFields: LifestylePresentValueResponse
}

export type LifestyleSpendingGoalInputs = {
    lifestyleSpendingPeriods: LifestyleSpendingPeriodInputs[];
    isFundedByNonInvestableAssets: boolean;
    totalFundedByNonInvestableAssetsAmount: number | null;
}

export type LifestyleSpendingGoal = {
    id?: string;
    calculatedFields: LifestylePresentValueResponse;
    userInputs: LifestyleSpendingGoalInputs
};


export type InflowPresentValue = {
    description: string;
    lifestyleGoalAligned: number;
    portfolioReserveAligned: number;
}

export type LifestyleSpendingRequest = {
    id?: string;
    userInputs: {
        lifestyleSpendingPeriods: LifestyleSpendingPeriodInputs[];
        isFundedByNonInvestableAssets: boolean;
        totalFundedByNonInvestableAssetsAmount: number | null;
    },
    lengthOfPlanningPeriod: number
}

export const emptyLifestyleGoalForm = (planningPeriod: PlanningPeriodType, selectedFBNI?: boolean): LifestyleSpendingGoal => ({
    calculatedFields: {...emptyLifestylePresentValueResponse},
    userInputs: {
        isFundedByNonInvestableAssets: selectedFBNI || false,
        lifestyleSpendingPeriods: [{...emptyLifestyleSpendingPeriod(planningPeriod.startYear, planningPeriod.numberOfYears + planningPeriod.startYear)}],
        totalFundedByNonInvestableAssetsAmount: null
    }
});

export const emptyLifestyleSpendingPeriod = (startYear: number, endYear: number): LifestyleSpendingPeriodInputs => ({
    description: "Lifestyle Spending Period 1",
    annualSpend: 0,
    startYear,
    endYear
})

