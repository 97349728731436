import React, {MouseEvent} from "react";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectDisplayName} from "../ClientProfile/approvedProfileSlice";
import {useHistory} from "react-router-dom";
import {
    AlertBanner,
    DropdownItem,
    PageActionMenu,
    PresentationPaneButton,
    PresentationPaneHeader
} from "../../components";
import {DropdownGroup} from "xps-react";
import {setAssetReliancePageActionMenu} from "./AssetRelianceSlice";
import {AssetRelianceButtonState, FveDiscountRateType} from "./AssetRelianceButtonState";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import {LifeInsuranceSummary} from "../../Assets/models/Assets";
import {setAccordionTableState} from "../../components/AccordionTable/AccordionTableSlice";
import {InvestorGroupType} from "../models/InvestorGroupType";
import {LifeInsurance} from "../../Assets/models/LifeInsurance";

interface AssetRelianceHeaderProps {
    id: string;
    title: string;
    investorGroup: InvestorGroupType;
    headerOptions: AssetRelianceButtonState;
    showPageActionMenu: boolean;
    lifeInsurances?: LifeInsuranceSummary;
}

const AssetRelianceHeader: React.FC<AssetRelianceHeaderProps> = ({
                                                                     id,
                                                                     title,
                                                                     investorGroup,
                                                                     headerOptions,
                                                                     showPageActionMenu,
                                                                     lifeInsurances,
                                                                 }) => {
    const displayName = useAppSelector(selectDisplayName) ?? "";
    const {enableFutureValueOfExcessAssets} = useAppSelector(selectReleaseToggles)!;
    const dispatch = useAppDispatch();
    const history = useHistory();

    const handleFveDiscountRate = (selectedFveDiscountRate: FveDiscountRateType) => {
        return (e: MouseEvent<HTMLElement>) => {
            dispatch(setAssetReliancePageActionMenu({
                ...headerOptions,
                selectedFveDiscountRate: selectedFveDiscountRate,
            }));
            e.stopPropagation();
        };
    }

    function handleShowExcessAssets() {
        return (e: React.MouseEvent<HTMLInputElement>) => {
            dispatch(setAssetReliancePageActionMenu({
                ...headerOptions,
                showExpectedExcessAsset: !headerOptions.showExpectedExcessAsset
            }));
            dispatch(setAccordionTableState({
                expanded: {[`${id}-review-asset-reliance-excess-assets`]: !headerOptions.showExpectedExcessAsset}
            }));
            e.stopPropagation();
        };
    }

    function handleIncludeLifeInsuranceAtDeath() {
        return (e: React.MouseEvent<HTMLInputElement>) => {
            dispatch(setAssetReliancePageActionMenu({
                ...headerOptions,
                includeLifeInsuranceAtDeath: !headerOptions.includeLifeInsuranceAtDeath
            }));
            e.stopPropagation();
        };
    }

    function showLifeInsuranceCheckbox(lifeinsurance: LifeInsurance[]) : boolean {
        const filteredInsurance = lifeinsurance.filter(lifeInsurance => lifeInsurance.isCashValueWillFundGoals)
        if(lifeinsurance.length>0){
            if (lifeinsurance.length == filteredInsurance.length) {
                return false
            }
            else if (lifeinsurance.length > filteredInsurance.length) {
                return true
            }
            else {return true}
        }
        return false
    }

    const {ageFrom, numberOfYears} = investorGroup.planningPeriod;


    const titleRowChildren = () => {
        return (
            <div>
                {enableFutureValueOfExcessAssets && showPageActionMenu && <PageActionMenu
                    className="asset-reliance-header-container three-dots-menu"
                    panelHeight="auto"
                    panelWidth={280}
                    aria-label="show-asset-reliance-options"
                    key={"page-action-menu"}
                >
                    <DropdownGroup groupName=" " key={"ls-dropdown-group"} show={true}>
                        <DropdownItem className="asset-reliance-page-action-menu-options"
                                      value="show-expected-excess-at-age" itemText="">
                            <div className="show-fve-options"
                                 onClick={handleShowExcessAssets()}>
                                Show Estimated Excess at Age {ageFrom + numberOfYears}
                                <input
                                    id="showExpectedExcessAtAge"
                                    name="show-expected-excess-at-age"
                                    type="checkbox"
                                    aria-label="show-expected-excess-at-age"
                                    key={"show-expected-excess-at-age"}
                                    onClick={handleShowExcessAssets()}
                                    checked={headerOptions.showExpectedExcessAsset}
                                />
                            </div>
                        </DropdownItem>
                        {showLifeInsuranceCheckbox(lifeInsurances!.data) &&
                            <DropdownItem className="asset-reliance-page-action-menu-options"
                                      value="include-life-insurance-at-death" itemText="">
                            <div className="show-fve-options"
                                 onClick={handleIncludeLifeInsuranceAtDeath()}>
                                Include Life Insurance at Death
                                <input
                                    data-testid={"include-life-insurance-at-death"}
                                    id="includeLifeInsuranceAtDeath"
                                    name="include-life-insurance-at-death"
                                    type="checkbox"
                                    aria-label="include-life-insurance-at-death"
                                    key={"include-life-insurance-at-death"}
                                    onClick={handleIncludeLifeInsuranceAtDeath()}
                                    checked={headerOptions.includeLifeInsuranceAtDeath}
                                />
                            </div>
                        </DropdownItem>}
                    </DropdownGroup>
                    <DropdownGroup groupName="Growth Rate" key={"ls-dropdown-group"}>
                        <DropdownItem key="tndr">
                            <div style={{width: "100%"}} className="asset-reliance-page-action-menu-options"
                                 onClick={handleFveDiscountRate(FveDiscountRateType.TRIPLE_NET)}>
                                Triple Net Growth Rate
                                <input name="triple-net-discount"
                                       data-testid={"triple-net-discount-option"}
                                       type="radio" value='tndr'
                                       aria-label="triple-net-discount-option"
                                       checked={headerOptions.selectedFveDiscountRate === FveDiscountRateType.TRIPLE_NET}
                                       onClick={handleFveDiscountRate(FveDiscountRateType.TRIPLE_NET)}
                                       disabled={!headerOptions.showExpectedExcessAsset}
                                />
                            </div>
                        </DropdownItem>
                        <DropdownItem className="page-action-menu-options" key={"dr-dropdown-group"}>
                            <div style={{width: "100%"}} className="asset-reliance-page-action-menu-options"
                                 onClick={handleFveDiscountRate(FveDiscountRateType.NOMINAL_NET)}>
                                Nominal Net Growth Rate
                                <input name="nominal-net-discount"
                                       data-testid={"nominal-net-discount-option"}
                                       type="radio" value='nndr'
                                       aria-label="nominal-net-discount-option"
                                       checked={headerOptions.selectedFveDiscountRate === FveDiscountRateType.NOMINAL_NET}
                                       onClick={handleFveDiscountRate(FveDiscountRateType.NOMINAL_NET)}
                                       disabled={!headerOptions.showExpectedExcessAsset}
                                />
                            </div>
                        </DropdownItem>
                    </DropdownGroup>
                </PageActionMenu>}
                <span className="paddingleft-12"></span>
                <PresentationPaneButton
                    iconName="edit"
                    kind="borderless"
                    onClick={() => history.push(`/Profile/${id}/ClientProfile/EditAssetReliance`)}>
                    Edit
                </PresentationPaneButton>
            </div>
        );
    };

    return (
        <>
        <PresentationPaneHeader
            className="asset-reliance-header-container"
            displayName={displayName}
            title={title}
            titleRow={titleRowChildren()}
        />
    <AlertBanner type={"info"} showAlert={headerOptions.includeLifeInsuranceAtDeath!}>This includes the value of the death benefit of your
        Life Insurance policy, which may be included in your taxable estate.</AlertBanner>
        </>

)
}

export default AssetRelianceHeader;
