import {AssetSubclassesSummary} from "./AssetSubclassesSummary";

export type CurrentAllocationResponse = {
    totalRiskAssetsAmount: number,
    totalRiskControlAssetsAmount: number,
    totalRiskAssetsPercent: number,
    totalRiskControlAssetsPercent: number,
    riskAssets: AssetSubclassesSummary[],
    riskControl: AssetSubclassesSummary[]
}

export type SimplifiedCurrentAllocationResponse = Pick<CurrentAllocationResponse,
    'totalRiskControlAssetsPercent' | 'totalRiskAssetsPercent'>;

export const EMPTY_CURRENT_ALLOCATION_RESPONSE: CurrentAllocationResponse = {
    totalRiskAssetsAmount: 0,
    totalRiskControlAssetsAmount: 0,
    totalRiskAssetsPercent: 0,
    totalRiskControlAssetsPercent: 0,
    riskAssets: [],
    riskControl: []
};