import {formatCurrency} from "../../../utils/format";
import React, {CSSProperties} from "react";
import {LifestylePresentValueResponse} from "../../models/PresentValue";
import {PlanningPeriodType} from "../../../ClientManagement/models/InvestorGroupType";
import {Icon, Popover} from "../../../components";



export type ReserveTargetDetailsProps = {
    presentValue: LifestylePresentValueResponse,
    planningPeriod: PlanningPeriodType
}

const flexStyles: CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "8px"
}

const ReserveTargetDetails: React.FC<ReserveTargetDetailsProps> = ({
                                                                       presentValue,
                                                                       planningPeriod
                                                                   }) => {
    const displayAlignedInflows = presentValue.totalAlignedInflowPresentValue === 0 ? 0 : -presentValue.totalAlignedInflowPresentValue
    const nonInvestableAssetsYears = planningPeriod.numberOfYears - presentValue.sufficientYears;

    return <div>
        <div style={{...flexStyles, fontWeight: "500"}}>
            <span>Total Lifestyle Spending</span>
            <span>{formatCurrency(presentValue.presentValue)}</span>
        </div>
        <div style={flexStyles}>
            <span>Funded by Inflows</span>
            <span>{formatCurrency(displayAlignedInflows)}</span>
        </div>
        <div style={flexStyles}>
            {
                presentValue.presentValueForInsufficientYears >0 && <Popover
                content={
                    <div>
                        <span>The Reserve Target entered considers</span>
                        <span> reliance on non-investable assets in</span>
                        <span> funding latter years of the lifestyle goal in</span>
                        <span> order to maintain investable excess to</span>
                        <span> generate a proposed allocation today.</span>
                    </div>
                }
                arrow={true}
                direction="top"
                aria-label="funded-by-non-investable-assets-popover"
                width={"288px"}
            >
                <Icon className="popover-trigger popoverContainer"
                      style={{marginLeft: "-8px",marginRight: "2px", color: "#3D4042"}}
                      name="status_info_outline" type="info" ariaHidden={false}
                      ariaLabel="funded by non investable-assets"/>
            </Popover>
            }
            <span>Funded by Non-Investable Assets</span>
            <span>{formatCurrency(presentValue.presentValueForInsufficientYears)}</span>
        </div>
        {nonInvestableAssetsYears != 0 && <div style={flexStyles}>
            <span>({nonInvestableAssetsYears} {nonInvestableAssetsYears <= 1 ? " year" : " years"})</span>
        </div>}
        <div style={flexStyles}>
            <span>Required Portfolio Spending</span>
            <span>{formatCurrency(presentValue.requiredPortfolioSpendingPresentValue)}</span>
        </div>
        <div style={flexStyles}>
            <span>Lifestyle Asset Allocation %</span>
            <span>{`RA ${Math.round(presentValue.riskAsset)} / RC ${Math.round(presentValue.riskControl)}`}</span>
        </div>
    </div>;
}

export default ReserveTargetDetails;
