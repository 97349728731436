import storageHelper from "src/core/storageHelper";
import {App_Conf} from "../../core/app_conf";
import {acquireAADTokenForTeamsUser, acquireToken, getAccountUserObjectId} from "../../MsalUtils";
import {stringToBase64} from "../../utils/base64Utils";

const {ACS_TEAMS_USER_TOKEN_PROVIDER_FN_URL} = App_Conf;

const getACSTeamsUserToken = async (msal: any): Promise<string> => {
    const url = new URL(ACS_TEAMS_USER_TOKEN_PROVIDER_FN_URL);
    const objectId = getAccountUserObjectId(msal);
    const teamsUserAADToken = await acquireAADTokenForTeamsUser(msal);
    const accessToken = await acquireToken(msal);

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            clientId: storageHelper.getSpaConfig().clientId,
            userObjectId: objectId,
            teamsUserAadToken: stringToBase64(teamsUserAADToken),
        }),
        mode: 'cors'
    })
    if (response.ok) {
        return response.json().then((responseBody) => responseBody.token);
    }
    throw response;
}

export const ACSTokenProviderClient = {
    getACSTeamsUserToken: getACSTeamsUserToken
}