import React from 'react';
import {formatCurrency, formatNumberRoundedToTwoDecimals} from "../../utils/format";
import {TickerValueFormat} from "./TickerValueFormat";
import classNames from "classnames";

interface TickerTabProps {
    title: string,
    value: number,
    format: TickerValueFormat,
    extraMargin?: boolean,
}

const TickerTab: React.FC<TickerTabProps> = ({title, value, format, extraMargin}) => {
    let formattedValue = "";
    if (format === TickerValueFormat.CURRENCY) {
        formattedValue = formatCurrency(value);
    } else if (TickerValueFormat.PERCENTAGE) {
        formattedValue = formatNumberRoundedToTwoDecimals(value) + "%";
    }
    return (
        <div className={classNames(
            "ticker-tab",
            extraMargin && "ticker-tab__extra-margin"
        )}>
            <div className={"title"}>{title}</div>
            <div className={classNames(
                "value",
                {"negative-difference": value < 0}
            )}>{formattedValue}</div>
        </div>
    )
}

export default TickerTab;