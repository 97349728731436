import React from 'react';
import {AssetsSummary} from "../../models/Assets";
import Accounts from "./Accounts";
import PersonalAssets from "./PersonalAssets";
import LifeInsurances from "./LifeInsurances";
import PersonalLiabilities from "./PersonalLiabilities";
import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {hasInEstateAssets} from "../../AssetSummary/common/utils";
import {DeleteModalProps} from "../../common/AssetsView";
import EquityCompensations from './EquityCompensations';
import AccordionWithHeader from "../../../components/Accordion/AccordionWithHeader";

export interface AssetListProps {
    profileId: string,
    assetsData: AssetsSummary;
    investorGroup: InvestorGroupType;
    showDeathBenefit?: boolean;
    onRemoveAssetClick: (deleteModalProps: DeleteModalProps) => void;
    onRemoveInvestmentProgram: () => void;
    onRefreshInvestmentProgramHoldings: () => void;
}

const AssetList = ({
                       profileId,
                       assetsData,
                       investorGroup,
                       showDeathBenefit = false,
                       onRemoveAssetClick,
                       onRemoveInvestmentProgram,
                       onRefreshInvestmentProgramHoldings
                   }: AssetListProps) => {

    const hasAccounts = assetsData.accounts.data.length > 0;
    const hasEquityCompensations = assetsData.equityCompensations.data.length > 0;
    const hasPersonalAssets = assetsData.personalAssets.data.length > 0;
    const hasLifeInsurances = assetsData.lifeInsurances.data.length > 0;
    const hasPersonalLiabilities = assetsData.personalLiabilities.length > 0;

    const allItems = [
        hasAccounts ? "Accounts" : '',
        hasPersonalAssets ? "PersonalAssets" : '',
        hasLifeInsurances ? "LifeInsurances" : '',
        hasPersonalLiabilities ? "PersonalLiabilities" : '',
        hasEquityCompensations ? "EquityCompensations" : ''
    ].filter(item => item.length > 0);

    const columnTitles = investorGroup.partnerMember
        ? ['Asset Type', investorGroup.primaryMember.firstName, investorGroup.partnerMember.firstName, 'Joint', 'Present Value']
        : ['Asset Type', 'Present Value'];

    return (
        <section data-testid="in-estate-current-net-worth">
            <div className="asset-list">
                <div className="accordion-tables">
                    <AccordionWithHeader
                        accordionId={"CurrentNetWorthInEstateAssetList"}
                        allItems={allItems}
                        columnTitles={columnTitles}
                        renderHeader={hasInEstateAssets(assetsData)}
                        headerProps={{
                            testId: "current-net-worth-headers",
                            className: "current-net-worth-grid-with-actionmenu",
                            gridSpanClass: investorGroup.partnerMember ? '' : 'grid-span-4'
                        }}
                    >
                        <div className="assets">
                            <Accounts profileId={profileId} assetsData={assetsData} investorGroup={investorGroup}
                                      onRemoveAssetClick={onRemoveAssetClick} onRemoveInvestmentProgram={onRemoveInvestmentProgram}
                                      onRefreshInvestmentProgramHoldings={onRefreshInvestmentProgramHoldings}
                            />
                            <EquityCompensations profileId={profileId} assetsData={assetsData} investorGroup={investorGroup}
                                            onRemoveAssetClick={onRemoveAssetClick}/>
                            <PersonalAssets profileId={profileId} assetsData={assetsData} investorGroup={investorGroup}
                                            onRemoveAssetClick={onRemoveAssetClick}/>
                            <LifeInsurances profileId={profileId} assetsData={assetsData} investorGroup={investorGroup}
                                            showDeathBenefit={showDeathBenefit} onRemoveAssetClick={onRemoveAssetClick}/>
                            <PersonalLiabilities profileId={profileId} assetsData={assetsData}
                                                 investorGroup={investorGroup} onRemoveAssetClick={onRemoveAssetClick}/>
                        </div>

                    </AccordionWithHeader>
                </div>
            </div>
        </section>
    );
}

export default AssetList;
