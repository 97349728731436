import {findAllRelationshipTypesFor} from "../../ClientManagement/FamilyTree/RelationshipTypeUtil";
import {LifeStatus, MemberType} from "../../ClientManagement/models/MemberType";
import {Link} from "react-router-dom";
import React from "react";
import {ProfileResponse} from "../../ClientManagement/models/ProfileResponse";
import {
    FamilyGoalRequest,
    FamilyGoalsType,
    FamilyGoalType,
    FamilyGoalUserInputs,
    newFamilyGoal,
} from "../models/FamilyGoalType";
import {InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import {NO_OP} from "../../constants/common";
import {
    BeneficiaryGoals as BeneficiaryGoalsType,
    BeneficiaryGoals,
    BeneficiaryGoalsResponse,
    GoalModelType,
    TaxLiabilities
} from "../models/GoalModelType";
import {LifestyleSpendingGoal} from "../models/LifestyleSpendingGoal";
import {GoalType, NonLifestyleGoalType} from "../models/GoalType";
import {DropdownItem} from "../../components";
import GoalUtils from "./GoalUtils";

function getCumulativeTotalMyResponsibility(familyGoals: GoalModelType['familyGoals']): number {
    return Object.values(familyGoals)
        .map((beneficiaryGoals) => getTotalMyResponsibility(beneficiaryGoals))
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
}

function getTotalNonLifestyleRiskControl(beneficiaryGoals: BeneficiaryGoalsType[]): number {
    let totalRiskControlValue: number = 0;
    for (let beneficiaryGoal of beneficiaryGoals) {
        totalRiskControlValue += Object.values(beneficiaryGoal.goals)
            .map((goal) =>
                (goal.userInputs.riskControl === null ?
                    goal.calculatedFields.presentValue * (goal.calculatedFields.riskControl / 100)
                    : goal.calculatedFields.presentValue * (goal.userInputs.riskControl / 100))
            )
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    }
    return totalRiskControlValue
}

function getTotalPresentValueForBeneficiary(goalsMap: { [key: string]: FamilyGoalType }): number {
    return Object.values(goalsMap)
        .map((familyGoal) => familyGoal.calculatedFields.presentValue)
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
}

function makeBeneficiaryGoalsForSingleGoal(familyGoal: FamilyGoalType, member: MemberType, goalIndex?: string): BeneficiaryGoals {
    return {
        beneficiaryId: familyGoal.beneficiaryId,
        birthdate: member.birthdate,
        age: member.age,
        name: `${member.firstName} ${member.lastName}`,
        goals: {[goalIndex ? goalIndex : familyGoal.id!]: familyGoal},
        totalPresentValue: familyGoal.calculatedFields ? familyGoal.calculatedFields.presentValue : 0,
    }
}

function combineBeneficiaryGoalsWithFamilyGoal(familyGoal: FamilyGoalType, member: MemberType, beneficiaryGoals?: BeneficiaryGoals): BeneficiaryGoals {
    const goalId = familyGoal.id
        ? familyGoal.id
        : "UNSAVED_FORM_STATE"

    if (!beneficiaryGoals) {
        return makeBeneficiaryGoalsForSingleGoal(familyGoal, member, goalId)
    }
    const combinedGoals = {
        ...beneficiaryGoals.goals,
        [goalId]: familyGoal
    };
    return {
        ...beneficiaryGoals,
        totalPresentValue: getTotalPresentValueForBeneficiary(combinedGoals),
        goals: combinedGoals
    }
}

function deleteFamilyGoalFromBeneficiaryGoals(familyGoal: FamilyGoalType, beneficiaryGoals: BeneficiaryGoals): BeneficiaryGoals {
    const goalId = familyGoal.id!;
    delete beneficiaryGoals.goals[goalId];
    return {
        ...beneficiaryGoals,
        totalPresentValue: beneficiaryGoals.goals ? getTotalPresentValueForBeneficiary(beneficiaryGoals.goals) : 0
    };
}

export function getSortedFamilyMembersForProfile(profile: ProfileResponse): MemberType[] {
    const primaryContactLegalPartnerId: string = GoalUtils.getPartnerIdForPrimary(profile);
    return findAllRelationshipTypesFor(profile.primaryContact as MemberType)
        .filter((familyRelationship) => {
            return (
                familyRelationship.fromMember.lifeStatus === LifeStatus.Living &&
                familyRelationship.id !== primaryContactLegalPartnerId // This filters Spouse and Domestic Partner for Primary ONLY
            )
        })
        .map((familyRelationship) => familyRelationship.fromMember)
        .sort((member1, member2) => {
            let birthdate1 = member1.birthdate;
            let birthdate2 = member2.birthdate;
            if (birthdate1 != null && birthdate2 != null) {
                if (new Date(birthdate1).getTime() < new Date(birthdate2).getTime()) {
                    return -1;
                } else if (new Date(birthdate1).getTime() === new Date(birthdate2).getTime()) {
                    return 0;
                } else {
                    return 1;
                }
            } else {
                return member2.age - member1.age
            }

        });
}

function getFamilyGoalDropdownItemsFor(profile: ProfileResponse, prefix: string = ""): JSX.Element[] {
    const sortedFamilyMembers = getSortedFamilyMembersForProfile(profile);
    return sortedFamilyMembers
        .map((member) => {
            return (<DropdownItem key={member.id} className="add-goal-dropdown-item" value="Family Goal" itemText=""
                                  onClick={NO_OP}>
                <Link to={{
                    pathname: `/Profile/${profile.id}/ClientProfile/Goals/FamilyGoal`,
                    state: {memberSelected: member}
                }} className="add-goal-dropdown-item">
                    <div id={member.id} style={{width: "100%"}}>
                        {prefix && prefix + " "}
                        {member.firstName + " " + member.lastName + " (age " + member.age + ")"}
                    </div>
                </Link>
            </DropdownItem>)
        })
}

type GoalTypeLinkGenerator =
    (goalType: string) => {
        pathname: string,
        state: any
    }

function getFamilyGoalsTypeDropdownItems(createLink?: GoalTypeLinkGenerator): JSX.Element[] {
    return Object.values(FamilyGoalsType)
        .filter((goalType: FamilyGoalsType) => goalType != FamilyGoalsType.UNKNOWN)
        .map((goalType: FamilyGoalsType) => {
            if (createLink !== undefined) {
                let linkGen = createLink(goalType)
                return (<DropdownItem key={goalType} value={goalType} itemText="" onClick={NO_OP}>
                    <Link to={
                        linkGen
                    } className="add-goal-dropdown-item">
                        <div id={goalType.toString()} style={{width: "100%"}}>
                            {goalType}
                        </div>
                    </Link>
                </DropdownItem>)
            } else {
                return (<DropdownItem style={{width: "100%"}} key={goalType}
                                      itemText={goalType}
                                      value={goalType}/>);
            }
        })
}

export type AgeRange = {
    startAge: number;
    endAge: number;
}

const toRequest = (familyGoal: FamilyGoalType): FamilyGoalRequest => {
    if (familyGoal.userInputs.selectedGoalSubType === FamilyGoalsType.UNKNOWN) {
        throw Error("Cannot build request without a Goal Type selected")
    }
    const {selectedGoalSubType, ...tempUserInputs} = familyGoal.userInputs
    return {
        ...tempUserInputs,
        selectedGoalType: selectedGoalSubType,
        beneficiaryId: familyGoal.beneficiaryId,
        ownerId: familyGoal.ownerId,
        id: familyGoal.id,
        familyAssetsAmount: familyGoal.familyAssetsAmount
    }
}

function calculateTotalGoalsAndTaxLiabilities(lifestyleSpendingGoal: LifestyleSpendingGoal, familyGoals: GoalModelType['familyGoals'], taxLiabilities: TaxLiabilities, nonLifestyleGoals?: NonLifestyleGoalType[]) {
    let totalGoalsAndTaxLiabilities: number = 0;

    totalGoalsAndTaxLiabilities += getCumulativeTotalMyResponsibility(familyGoals)

    if (lifestyleSpendingGoal && lifestyleSpendingGoal.calculatedFields.presentValue) {
        totalGoalsAndTaxLiabilities += lifestyleSpendingGoal.calculatedFields.presentValue;
    }

    totalGoalsAndTaxLiabilities += taxLiabilities.totalTaxLiabilities;
    if (nonLifestyleGoals) {
        const totalNonLifestylePV = nonLifestyleGoals
            .map(nonLifestyleGoal => nonLifestyleGoal.calculatedFields.presentValue)
            .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
        totalGoalsAndTaxLiabilities += totalNonLifestylePV
    }
    return totalGoalsAndTaxLiabilities;
}

function getBeneficiaryFromProfile(profile: ProfileResponse, beneficiaryId: string): MemberType {
    const memberSelected = getSortedFamilyMembersForProfile(profile).find(member => {
        return member.id === beneficiaryId
    });
    if (!memberSelected) {
        throw new Error('MemberSelected is undefined')
    }
    return memberSelected;
}


function initializeFamilyGoal(profileId: string, beneficiaryId: string, selectedGoalSubType: FamilyGoalsType, investorGroup: InvestorGroupType): FamilyGoalType {
    const {startDate, endDate} = GoalUtils.getDefaultTimeframe(investorGroup);
    return newFamilyGoal(profileId, beneficiaryId, selectedGoalSubType, startDate, endDate);
}

type ModifiedUserInputs = FamilyGoalUserInputs & { selectedGoalType?: FamilyGoalsType };

function transformFamilyGoalResponse(beneficiaryGoalResponse: BeneficiaryGoalsResponse) {
    Object.values(beneficiaryGoalResponse.goals).forEach(updateGoalToRemoveTypeAndAddSubType);
}

function updateGoalToRemoveTypeAndAddSubType(goal: FamilyGoalType & { userInputs: ModifiedUserInputs }) {
    goal.userInputs.selectedGoalSubType = goal.userInputs.selectedGoalType || FamilyGoalsType.UNKNOWN;
    delete goal.userInputs.selectedGoalType;
    return goal;
}

function convertFamilyGoalTypeToNonLifestyleGoalType(familyGoal: FamilyGoalType): NonLifestyleGoalType {
    const convertedGoal: NonLifestyleGoalType = {
        createdTimestamp: familyGoal.createdTimestamp,
        calculatedFields: {
            ...familyGoal.calculatedFields
        },
        id: familyGoal.id,
        ownerId: familyGoal.ownerId,
        goalType: GoalType.FAMILY,
        userInputs: {
            ...familyGoal.userInputs
        },
        beneficiaryId: familyGoal.beneficiaryId,
    }
    return convertedGoal
}

function convertFamilyNonLifestyleGoalTypeToFamilyGoalType(nonLifestyleGoal: NonLifestyleGoalType, familyAssetsAmount?: number): FamilyGoalType {
    const {goalType, ...cleanedGoal} = {...nonLifestyleGoal}
    const beneficiaryId = nonLifestyleGoal.beneficiaryId ? nonLifestyleGoal.beneficiaryId : ""
    const ownerId = nonLifestyleGoal.ownerId ? nonLifestyleGoal.ownerId : ""

    if (!nonLifestyleGoal.beneficiaryId) {
        throw new Error("Cannot build family Goal without a beneficiary ID")
    }
    if (!nonLifestyleGoal.ownerId) {
        throw new Error("Cannot build family Goal without an owner ID")
    }
    if (goalType !== GoalType.FAMILY) {
        throw new Error("Cannot convert Non-Family goal to FamilyGoalType")
    }
    const convertedGoal: FamilyGoalType = {
        ...cleanedGoal,
        beneficiaryId,
        ownerId,
        familyAssetsAmount
    }
    return convertedGoal
}

function getTotalFamilyAssets(beneficiaryGoals: BeneficiaryGoals) {
    return Object.values(beneficiaryGoals.goals).reduce((prev, curr) => prev + (curr.familyAssetsAmount || 0), 0)
}

function getTotalGoalsPresentValue(beneficiaryGoals: BeneficiaryGoals) {
    return Object.values(beneficiaryGoals.goals).reduce((prev, curr) => prev + (curr.calculatedFields.goalsPresentValue || 0), 0)
}

function getTotalMyResponsibility(beneficiaryGoals: BeneficiaryGoals) {
    return Object.values(beneficiaryGoals.goals).reduce((prev, curr) => {
        const myResponsibility = curr.calculatedFields.presentValue
        return prev + (myResponsibility > 0 ? myResponsibility : 0);
    }, 0)
}

export default {
    makeBeneficiaryGoalsForSingleGoal,
    getFamilyGoalDropdownItemsFor,
    getBeneficiaryFromProfile,
    combineBeneficiaryGoalsWithFamilyGoal,
    getCumulativeTotalMyResponsibility,
    calculateTotalGoalsAndTaxLiabilities,
    toRequest,
    updateGoalToRemoveTypeAndAddSubType,
    initializeFamilyGoal,
    convertFamilyNonLifestyleGoalTypeToFamilyGoalType,
    getTotalNonLifestyleRiskControl,
    getTotalPresentValueForBeneficiary,
    deleteFamilyGoalFromBeneficiaryGoals,
    transformFamilyGoalResponse,
    getFamilyGoalsTypeDropdownItems,
    convertFamilyGoalTypeToNonLifestyleGoalType,
    getTotalFamilyAssets,
    getTotalMyResponsibility,
    getTotalGoalsPresentValue
}