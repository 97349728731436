import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store/store";

export interface PartnerWorkstationState {
    linkedUlek: string;
    linkedClientName: string;
}
// initialState can be a value or a function. In this case, it's a function because `null` doesn't have enough type
// information by itself to say that it can also be of type ProfileResponse.
const initialState: PartnerWorkstationState = {
    linkedUlek: '',
    linkedClientName: ''
}

export const partnerWorkstationSlice = createSlice({
    name: 'partnerWorkstation',
    initialState,
    reducers: {
        // reducers need to return the new state, given the existing state and the action. In this case, the new state is
        // just the payload of the action; in other cases, you can mutate the state object, and changes to that state will
        // be recorded to create the new state.
        setLinkedUlek: (state, action: PayloadAction<string>) => {
            state.linkedUlek = action.payload;
        },
        setLinkedClientName: (state, action: PayloadAction<string>) => {
            state.linkedClientName = action.payload;
        },
        clearPartnerWorkstationState: (state)  => {
            Object.assign(state, initialState)
        }
    }
});

export const {setLinkedUlek, setLinkedClientName, clearPartnerWorkstationState} = partnerWorkstationSlice.actions;

export const selectLinkedUlek = (state: RootState) => state.client.partnerWorkstation?.linkedUlek;
export const selectLinkedClientName = (state: RootState) => state.client.partnerWorkstation?.linkedClientName;

export default partnerWorkstationSlice.reducer;