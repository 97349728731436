import React, {useContext, useEffect, useState} from "react";
import PersonalLiabilityForm from "./PersonalLiabilityForm";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithAssetId} from "../../routes/types";
import {assetsApiClient} from "../AssetsApiClient";
import {PersonalLiabilityFormData} from "../models/PersonalLiability";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import EditFormContainer from "../EditFormContainer";
import AssetsViewContext from "../common/AssetsViewContext";
import {MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import {LegalEntityFormData} from "../models/Ownership";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {mapFormDataToCreatePersonalLiabilityRequest, mapPersonalLiabilityToFormData} from "./mappers";

export default function EditPersonalLiability() {
    const history = useHistory();
    const viewType = useContext(AssetsViewContext);
    const profile = useAppSelector(selectProfile);
    const [initialPersonalLiability, updateInitialPersonalLiability] = useState<PersonalLiabilityFormData>();
    const [memberGroup, setMemberGroup] = useState<MemberGroup>();
    const [legalEntities, updateLegalEntities] = useState<LegalEntityFormData[]>([]);
    const {assetId} = useParams<RouteWithAssetId>();

    useEffect(() => {
        Promise.all([
            clientManagementApiClient.getMemberGroup(profile.id),
            assetsApiClient.getLegalEntities(profile.id)
        ]).then(([memberGroupResponse, legalEntitiesResponse]) => {
            setMemberGroup(memberGroupResponse);
            updateLegalEntities(legalEntitiesResponse)
        }).catch(error => console.error('Could not fetch personal liability data', error.message));
    }, [])

    useEffect(() => {
        assetsApiClient.getPersonalLiability(profile.id, assetId)
            .then((personalLiability) => updateInitialPersonalLiability(
                mapPersonalLiabilityToFormData(personalLiability)
            ));
    }, [assetId]);

    const handleSave = async (formData: PersonalLiabilityFormData) => {
        await assetsApiClient.putPersonalLiability({
                ...mapFormDataToCreatePersonalLiabilityRequest(formData, profile.id),
                id: assetId
            }
        );

        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
    }

    const handleDelete = async () => {
        const response = await assetsApiClient.deletePersonalLiability(profile.id, assetId);
        if (response.status === 200) {
            history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
        }
    };

    if (!initialPersonalLiability) {
        return <LoadingIndicator/>
    }

    return <div className="personal-liability">
        <EditFormContainer
            modalTitle="Liability"
            assetType="Liability"
            assetDescription={initialPersonalLiability.description}
            handleDelete={handleDelete}
            form={(handleCancel) => (
                <PersonalLiabilityForm
                    defaultPersonalLiability={initialPersonalLiability}
                    formatTitle={(description) => `Edit ${description}`}
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                    memberGroup={memberGroup!}
                    legalEntities={legalEntities}
                    updateLegalEntities={updateLegalEntities}
                />
            )}
        />
    </div>;
}
