import {
    Dropdown,
    DropdownItem,
    Input,
    RadioGroup,
    RedAsterisk,
    RequiredFieldsSubheader,
    UnderlinedHeader
} from "../../components";
import React, {ChangeEvent, useEffect, useState} from "react";
import TaxDetails from "../TaxDetails/TaxDetails";
import {
    HeldAwayAccount
} from "../models/InvestmentProgram";
import {TaxDetailsType, TaxStatusOptions} from "../models/TaxDetails";
import Ownership from "../Ownership";
import {LegalEntityFormData, OwnershipDetailsFormData} from "../models/Ownership";
import {MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import moment from "moment";
import {HoldingSummary} from "../models/Holding";
import {setActiveFormAsset} from "../clientAssetsSlice";
import {calculateInEstateFormValue} from "../AssetSummary/common/AssetSummaryCalculator";
import {hasSomeInEstateOwnershipInForm} from "../Ownership/helpers";
import {mapToOwnershipWriteModel} from "../Ownership/mappers";
import {useAppDispatch} from "../../store/hooks";
import {booleanToRadioButton, radioButtonToBoolean} from "../formHelpers";
import {RadioYesNoOptional} from "../models/Common";

type HeldAwayAccountFormProps = {
    heldAwayAccount: HeldAwayAccount,
    ownershipDetailsFormData: OwnershipDetailsFormData,
    isRequiredFieldsBannerShown: boolean,
    isOwnershipPercentageErrorBannerShown: boolean,
    handleHeldAwayAccountChange: Function,
    investmentProgramName: string | null,
    taxDetails: TaxDetailsType,
    handleTaxDetailsChange: (taxDetails: TaxDetailsType) => void,
    unrealizedCapitalGainsTax: number | null,
    deferredTaxLiability: number | null,
    totalInvestablePresentValue?: number | null,
    getInvestablePresentValue: (liabilityPaidByPortfolio: boolean) => void
    handleUnrealizedCapitalGainsTaxChange: (isEntityCapitalGains: boolean) => void,
    memberGroup: MemberGroup,
    legalEntities: LegalEntityFormData[],
    handleLegalEntitiesChange: (legalEntities: LegalEntityFormData[]) => void,
    onOwnershipFormChange: (ownershipFormData: OwnershipDetailsFormData) => void
    asOfDate: string | undefined,
    heldAwayAccountHoldings?: HoldingSummary
}
const defaultIsEntityCapitalGains = true;
const taxStatusOptions = Object.freeze(["Taxable", "Deferred", "Exempt"]);
const taxStatusDropdownItems = taxStatusOptions.map(taxOption => (<DropdownItem key={taxOption}
                                                                                itemText={taxOption}
                                                                                value={taxOption}/>));
export const HeldAwayAccountForm = ({
                                        heldAwayAccount,
                                        ownershipDetailsFormData,
                                        isRequiredFieldsBannerShown,
                                        isOwnershipPercentageErrorBannerShown,
                                        handleHeldAwayAccountChange,
                                        taxDetails,
                                        handleTaxDetailsChange,
                                        unrealizedCapitalGainsTax,
                                        deferredTaxLiability,
                                        totalInvestablePresentValue,
                                        getInvestablePresentValue,
                                        handleUnrealizedCapitalGainsTaxChange,
                                        memberGroup,
                                        legalEntities,
                                        handleLegalEntitiesChange,
                                        onOwnershipFormChange,
                                        asOfDate,
                                        heldAwayAccountHoldings
                                    }: HeldAwayAccountFormProps) => {
    const [isNameEmptyOnBlur, setIsNameEmptyOnBlur] = useState(false);
    const dispatch = useAppDispatch();
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    useEffect(() => {
        const totalValue = heldAwayAccountHoldings?.totalMarketValue ?? 0;
        dispatch(setActiveFormAsset({
            assetType: 'investmentProgramHAA',
            id: heldAwayAccount.id,
            inEstateValue: calculateInEstateFormValue(totalValue, ownershipDetailsFormData.memberOwnerships),
            description: heldAwayAccount.financialAccountName,
            hasInEstateOwnership: hasSomeInEstateOwnershipInForm(mapToOwnershipWriteModel(ownershipDetailsFormData).memberOwnerships),
        }));
        return () => {
            dispatch(setActiveFormAsset(null));
        };
    }, [heldAwayAccount.financialAccountName, ownershipDetailsFormData]);


    const isNameInlineErrorShown = () => {
        const isNameEmptyOnSave = isRequiredFieldsBannerShown && !heldAwayAccount?.financialAccountName.trim();
        return isNameEmptyOnBlur || isNameEmptyOnSave;
    };
    const handleOwnershipFormChange = (ownershipFormData: OwnershipDetailsFormData) => {
        onOwnershipFormChange(ownershipFormData);
    }
    const resetAccountAndTaxDetailsToDefaults = (selectedTaxStatus: TaxStatusOptions) => {
        const updatedAccountDetails: HeldAwayAccount = {
            ...heldAwayAccount,
            taxStatus: selectedTaxStatus,
        };
        const updatedTaxDetails: TaxDetailsType = {
            isEntityCapitalGains: null,
            isLiabilityPaidByPortfolio: null,
        };
        switch (selectedTaxStatus) {
            case "Taxable":
                updatedTaxDetails.isEntityCapitalGains = defaultIsEntityCapitalGains;
                updatedTaxDetails.isLiabilityPaidByPortfolio = heldAwayAccount.isLiabilityPaidByPortfolio;
                break;
            case "Deferred":
                updatedTaxDetails.isLiabilityPaidByPortfolio = heldAwayAccount.isLiabilityPaidByPortfolio;
                break;
            case "Exempt":
                break;
            default:
                break;
        }
        return {updatedAccountDetails, updatedTaxDetails};
    };
    const handleTaxStatusChange = (selectedTaxStatus: TaxStatusOptions) => {
        const {updatedAccountDetails, updatedTaxDetails} = resetAccountAndTaxDetailsToDefaults(selectedTaxStatus);
        handleTaxDetailsChange(updatedTaxDetails);
        handleHeldAwayAccountChange(updatedAccountDetails);
    }

    const totalAssetValue = heldAwayAccountHoldings ? heldAwayAccountHoldings.totalMarketValue : null;

    return (
        <article>
            <section className="held-away-section">
                <UnderlinedHeader primaryText="Account Details" secondaryContent={<RequiredFieldsSubheader/>}/>
                <div className="layout-data-entry-form__field">
                    <label id="financialAccountName-label"> <b>Name<RedAsterisk/></b>
                    </label>
                    <Input
                        aria-label="financialAccountName"
                        aria-labelledby="financialAccountName-label"
                        value={heldAwayAccount.financialAccountName}
                        error={isNameInlineErrorShown() ? "Name is required." : undefined}
                        onBlur={() => setIsNameEmptyOnBlur(!heldAwayAccount.financialAccountName.trim())}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            handleHeldAwayAccountChange({
                                ...heldAwayAccount,
                                financialAccountName: e.target.value
                            })
                        }}
                        readOnly={isProfileWithProposalsOrArchived}
                    />
                </div>
                <div className="layout-data-entry-form__field">
                    <label id="asOfDate-label">
                        <b>As of Date</b>
                    </label>
                    <span role="note" aria-labelledby="asOfDate-label" className="paddingleft-md">
                        {asOfDate ? `${moment(asOfDate).format("MM/DD/YYYY")}` : "--"}
                    </span>
                </div>
                <div className="layout-data-entry-form__field">
                    <label id="financialAccountNumber-label">
                        <b>Account Number</b>
                    </label>
                    <span role="note" aria-labelledby="financialAccountNumber-label" className="paddingleft-md">
                        {heldAwayAccount.financialAccountNumber}
                    </span>
                </div>
                <div className="layout-data-entry-form__field">
                    <label id="entityType-label">
                        <b>Entity Type</b>
                    </label>
                    <span role="note" aria-labelledby="entityType-label" className="paddingleft-md">
                        {heldAwayAccount.entityType ? heldAwayAccount.entityType : '––'}
                    </span>
                </div>
                <div className="layout-data-entry-form__field">
                    <label id="institution-label">
                        <b>Institution</b>
                    </label>
                    <span role="note" aria-labelledby="institution-label" className="paddingleft-md">
                        {heldAwayAccount.institution}
                    </span>
                </div>
                <div className="layout-data-entry-form__field">
                    <label id="taxStatus">
                        <b>Tax Status</b>
                    </label>
                    <Dropdown
                        className="taxStatusField"
                        name="taxStatus"
                        id="taxStatusInput"
                        aria-labelledby="taxStatus"
                        size="medium"
                        onChange={(data: any) => handleTaxStatusChange(data?.value as TaxStatusOptions)}
                        value={heldAwayAccount.taxStatus}
                    >
                        {taxStatusDropdownItems}
                    </Dropdown>
                </div>
                {(heldAwayAccount.taxStatus === "Deferred" || heldAwayAccount.taxStatus === "Exempt") &&

                        <RadioGroup
                            id="doesPermitBeneficiary"
                            name="doesPermitBeneficiary"
                            label="Does this account permit a beneficiary?"
                            layout="horizontal"
                            values={["Yes", "No"]}
                            disabled={isProfileWithProposalsOrArchived}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                const {value} = e.target;
                                handleHeldAwayAccountChange({
                                    ...heldAwayAccount,
                                    doesPermitBeneficiary: radioButtonToBoolean(value as RadioYesNoOptional),
                                })
                            }}
                            selected={booleanToRadioButton(heldAwayAccount.doesPermitBeneficiary) ?? 'No'}
                        />
                }
            </section>
            {(heldAwayAccount.taxStatus === "Taxable" || heldAwayAccount.taxStatus === "Deferred") &&
                <TaxDetails taxDetails={taxDetails}
                            updateTaxDetails={handleTaxDetailsChange}
                            taxStatus={heldAwayAccount.taxStatus}
                            isRequiredFieldsBannerShown={isRequiredFieldsBannerShown}
                            unrealizedCapitalGainsTax={unrealizedCapitalGainsTax}
                            deferredTaxLiability={deferredTaxLiability}
                            totalInvestablePresentValue={totalInvestablePresentValue}
                            handleUnrealizedCapitalGainsTaxChange={handleUnrealizedCapitalGainsTaxChange}
                            handleLiabilityPaidByPortfolioChange={getInvestablePresentValue}
                            isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                />
            }
            <Ownership
                onFormDataChange={handleOwnershipFormChange}
                formData={ownershipDetailsFormData}
                totalAssetValue={totalAssetValue}
                isOwnershipPercentageErrorBannerShown={isOwnershipPercentageErrorBannerShown}
                memberGroup={memberGroup}
                legalEntities={legalEntities}
                updateLegalEntities={handleLegalEntitiesChange}
                isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
            />
        </article>
    )
}