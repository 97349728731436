import React, {useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import classNames from "classnames";
import {useHistory} from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type PdfPreviewPaneProps = {
    pdf: string,
    currentPage: number,
    redirectUrl: string | null
}

export function CustomPagePreview({
                        currentPage,
                        pageNumber,
                        handleNavigate
                    }: { currentPage: number, pageNumber: number, handleNavigate: (pageNumber: number) => void }) {
    return <div
        key={pageNumber}
        className={classNames("pdf-page", {
            "pdf-page__active": currentPage === pageNumber
        })}
        aria-label={`Preview Page ${pageNumber}`}
        role="option"
        aria-selected={currentPage === pageNumber}
        tabIndex={0}
        onClick={() => {
            handleNavigate(pageNumber);
        }}
        onKeyPress={(e) => {
            if (e.key === 'Enter') {
                handleNavigate(pageNumber)
            }
        }}
    >
        <Page
            pageNumber={pageNumber}
        />
    </div>;
}

const PdfPreviewPane: React.FC<PdfPreviewPaneProps> = ({
                                                           pdf,
                                                           currentPage = 1,
                                                           redirectUrl = null
                                                       }) => {
    const history = useHistory();
    const [noOfPages, setNoOfPages] = useState(0);

    const pagesList = Array.apply(null, Array(noOfPages));

    function handleNavigate(pageNumber: number) {
        if (redirectUrl) {
            history.push(`${redirectUrl}${pageNumber}`);
        }
    }

    return (
        <div className="pdf-preview-pane nav-drawer">
            <Document
                className="pdf-preview-pane__container"
                file={pdf}
                error="PDF is missing"
                onLoadSuccess={({numPages}) => {
                    setNoOfPages(numPages)
                }}
            >
                {pagesList
                    .map((_page, index) =>
                        <CustomPagePreview key={index} currentPage={currentPage} pageNumber={index + 1} handleNavigate={handleNavigate} />
                    )
                }
            </Document>
        </div>

    );
};

export default PdfPreviewPane;
