import React, {useState} from "react";
import {AssetsYearsInput, Button, UnderlinedHeader} from "../../components";
import {formatCurrency} from "../../utils/format";
import {QuickSlide} from '../../Goals/PortfolioReserve/QuickSlide/QuickSlide';
import {ReleaseTogglesType} from "../../ReleaseToggles/ReleaseToggles";

type InflowReserveProps = {
    isInflowWillFundLifestyleGoal: boolean,
    isProfileWithProposalsOrArchived?: boolean,
    setInflowReserveLengthInYears: (value: number) => void,
    inflowReserveLengthInYears: null | number,
    yearsOfFlow: number,
    inflowReservePresentValue: number | undefined,
    onBlur: (value: number) => void,
    releaseToggles: ReleaseTogglesType
}

export function InflowReserve(props: InflowReserveProps) {
    const {enableInflowReserve} = props.releaseToggles;
    const [showQuickSlide, setShowQuickSlide] = useState(false);

    return <>
        {
            enableInflowReserve &&
            props.isInflowWillFundLifestyleGoal &&
            <section className="inflow-section">
                <UnderlinedHeader
                    className="inflow-reserve-section-header"
                    primaryText="Inflow Reserve"
                    secondaryContent={
                        <Button
                            icon="only"
                            iconName="info_outline"
                            kind="borderless"
                            onClick={() => {
                                setShowQuickSlide(true);
                            }}
                            size="small"
                        />
                    }
                />
                {showQuickSlide &&
                    <QuickSlide
                        title={"Inflow Reserve"}
                        imageSrc={"/InflowReserve.svg"}
                        children={<>
                            <div>
                                <p>
                                Inflow Reserve is an allocation of Risk Control Assets within your Investable Portfolio.
                                The purpose of this reserve is to fund your Lifestyle Spending in the event that you do not
                                receive a particular inflow (e.g. salary). This reserve is determined by the number of
                                Lifestyle Spending years that you want to protect.
                                </p>
                                <p>
                                    If the Inflow Reserve is not drawn upon, the Risk Control can help you fund your
                                    Portfolio Reserve.
                                </p>
                            </div>
                        </>}
                        closeHandler={() => setShowQuickSlide(false)}
                    ></QuickSlide>
                }
                <div className="inflow-section-grid-container layout-data-entry-form__field">
                    <AssetsYearsInput
                        label="Length in Years"
                        fieldName="inflowReserveLengthInYears"
                        value={props.inflowReserveLengthInYears ?? 0}
                        maxValue={props.yearsOfFlow}
                        minValue={0}
                        maxLength={3}
                        onChangeValue={(_event, value) => {
                            props.setInflowReserveLengthInYears(value)
                        }}
                        onBlur={(_event, value) => {
                            const editedLengthInYears = value || 0;
                            props.setInflowReserveLengthInYears(editedLengthInYears)
                            props.onBlur(editedLengthInYears)
                        }}
                        readOnly={props.isProfileWithProposalsOrArchived}
                    />
                    <span className="empty-grid-2-columns"/>
                </div>
                <div className="layout-data-entry-form__field">
                    <label id="inflow-reserve-value"><b>Inflow Reserve Value</b></label>
                    <span
                        aria-labelledby="inflow-reserve-value">{formatCurrency(props.inflowReservePresentValue)}</span>
                </div>
            </section>
        }
    </>
}