import React, {FC, useCallback, useEffect, useState} from "react";
import ModalWrapper from "../../../components/Modal/ModalWrapper/ModalWrapper";
import {App_Conf} from "../../../core/app_conf";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {
    initialMeeting,
    selectActiveMeeting,
    selectMeetingModalVisibility,
    selectScheduledMeetingForm,
    setMeetingModalVisibility,
    setScheduledMeetingForm
} from "../meetingSlice";
import QRCode from "qrcode";
import {Button} from "../../../components";
import {MeetingStatus} from "../Meeting";
import moment from "moment";

const MeetingInfoModal: FC = () => {
    let meeting = useAppSelector(selectActiveMeeting);
    const scheduledMeeting = useAppSelector(selectScheduledMeetingForm);
    if (scheduledMeeting!.id.length > 0) meeting = scheduledMeeting;

    const dispatch = useAppDispatch();
    const modalVisibility = useAppSelector(selectMeetingModalVisibility);

    const [qrCodeImageSize, setQrCodeImageSize] = useState<{ width: number, height: number } | null>(null);

    const {APP_URL} = App_Conf;

    const renderQRCode = useCallback((url: string) => {
        let canvasElement: HTMLCanvasElement | null = document.querySelector(`.qr-code`);
        if (canvasElement) {
            if (qrCodeImageSize && qrCodeImageSize.width > 0 && qrCodeImageSize.height > 0) {
                canvasElement.width = qrCodeImageSize.width;
                canvasElement.height = qrCodeImageSize.height;
            }
            let canvasContext = canvasElement.getContext('2d');
            if (canvasContext) {
                let canvasImage: HTMLImageElement | null = new Image();
                canvasImage.onload = () => {
                    if (canvasElement && canvasContext && canvasImage) {
                        canvasContext.clearRect(0, 0, canvasElement.width, canvasElement.height);
                        canvasContext.drawImage(canvasImage, 0, 0);
                        if (!qrCodeImageSize) {
                            setQrCodeImageSize({width: canvasImage.naturalWidth, height: canvasImage.naturalHeight});
                        }
                    }
                    canvasElement = null;
                    canvasContext = null;
                    canvasImage = null;
                };
                canvasImage.onerror = () => {
                    canvasElement = null;
                    canvasContext = null;
                    canvasImage = null;
                }
                canvasImage.src = url;
            }
        }
    }, [qrCodeImageSize]);

    const generateQRCode = useCallback(() => {
        if (meeting?.onlineMeetingId && meeting.onlineMeetingCode) {
            QRCode.toDataURL(
                `${APP_URL}/meetings?id=${meeting.onlineMeetingId}&code=${meeting.onlineMeetingCode}&firstName=Guest`,
                {errorCorrectionLevel: 'L', margin: 0, scale: 5}
            ).then(url => renderQRCode(url));
        }
    }, [meeting?.onlineMeetingId, meeting?.onlineMeetingCode, renderQRCode]);

    useEffect(() => {
        generateQRCode()
    }, [generateQRCode]);

    const copyMeetingDetails = () => {
        const meetingInfo = "Please join the meeting using the URL below:" + "\n" +
            APP_URL + "/meetings\n" +
            "Meeting Id: " + meeting?.onlineMeetingId + "\n" +
            "Passcode: " + meeting?.onlineMeetingCode
        navigator.clipboard.writeText(meetingInfo).then();
    }

    const editMeetingDetails = () => {
        dispatch(setScheduledMeetingForm(scheduledMeeting!));
        dispatch(setMeetingModalVisibility({
            ...modalVisibility,
            meetingInfo: false,
            meetingSchedule: true
        }));
    }

    return <ModalWrapper
        id={'meeting-info'}
        isOpen={modalVisibility?.meetingInfo}
        headerText={
            <>
                <h1 className={"meeting-info-modal-text-bold"}>{meeting?.title}</h1>
                {scheduledMeeting && meeting?.status === MeetingStatus.SCHEDULED && <>
                    <div>
                        <span className="meeting-info-modal-text-bold">Date: </span>
                        <span data-testid="onlineMeetingId">{
                            meeting?.startDate
                                ? moment(meeting.startDate).format('MM/DD/yyyy')
                                : ''
                        }</span>
                    </div>
                    <div>
                        <Button
                            style={{padding: 0}}
                            kind={'borderless'}
                            onClick={() => editMeetingDetails()}
                            size="small"
                            icon="left"
                            iconName="chevron_left">Edit the meeting</Button>
                    </div>
                </>}
            </>
        }
        buttons={[
            {
                text: "Close",
                onClick: () => {
                    dispatch(setScheduledMeetingForm(initialMeeting))
                    dispatch(setMeetingModalVisibility({
                        ...modalVisibility,
                        meetingInfo: false,
                        meetingSchedule: false
                    }));
                },
            }
        ]}>
        {
            <>
                <div>
                    <div className="meeting-info-modal-scan-qr-instructions">Scan the QR code with your tablet to join
                        the meeting, or join manually using the information below.
                    </div>
                    <div className="meeting-info-modal-qr-code-box">
                        <canvas className="qr-code"/>
                    </div>
                    <div className="meeting-info-modal-text">
                        <div>{APP_URL + "/meetings"}</div>
                    </div>
                    <div className="meeting-info-modal-text">
                        <div><span className="meeting-info-modal-text-bold marginright-sm">Meeting ID:</span><span
                            data-testid="onlineMeetingId">{meeting?.onlineMeetingId}</span></div>
                        <div><span className="meeting-info-modal-text-bold marginright-sm">Passcode:</span><span
                            data-testid="onlineMeetingCode">{meeting?.onlineMeetingCode}</span></div>
                        <Button
                            className='copy-meeting-details'
                            kind={'secondary'}
                            onClick={() => copyMeetingDetails()}
                            size="medium"
                            icon="left"
                            iconName="link">COPY MEETING DETAILS</Button>
                    </div>
                </div>
            </>
        }
    </ModalWrapper>
}

export default MeetingInfoModal;