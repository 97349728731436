import React, {useState} from "react";
import {GetInvestmentProgramsParams} from "../../AssetsApiClient";
import {InvestmentProgram, InvestmentProgramsResponse} from "../../models/InvestmentProgram";
import ConfirmationModal from "./ConfirmationModal";
import HeaderAndSearchBar from "./HeaderAndSearchBar";
import {SearchParams} from "./index";
import InvestmentProgramTabs, {TabType} from "./InvestmentProgramTabs";
import {DisclaimerMessage} from "../../common/DisclaimerMessage";

const searchDropdownOptions = [
    {
        label: 'IP Name',
        value: 'groupName'
    }, {
        label: 'Portfolio Manager',
        value: 'portfolioManager'
    }
];

export type AddInvestmentProgramProps = {
    onSearch: (searchParams: GetInvestmentProgramsParams) => void,
    onSave: (investmentProgram: InvestmentProgram, isTeam: boolean) => void,
    onCancel: () => void,
    errorMessage?: string,
    detailErrorMessage?: string,
    myInvestmentPrograms: InvestmentProgramsResponse,
    teamInvestmentPrograms: InvestmentProgramsResponse,
    isLoading: boolean,
    displayNoMatchFound?: boolean,
}

const AddInvestmentProgram = ({
                                  onSearch,
                                  onCancel,
                                  onSave,
                                  errorMessage,
                                  detailErrorMessage,
                                  myInvestmentPrograms,
                                  teamInvestmentPrograms,
                                  isLoading,
                                  displayNoMatchFound,
                              }: AddInvestmentProgramProps) => {

    const DEFAULT_PAGE = 0;
    const DEFAULT_PAGE_SIZE = 25;
    const DEFAULT_TAB = TabType.MyInvestmentProgramsTab;
    const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [currentTab, setCurrentTab] = useState<TabType>(DEFAULT_TAB);
    const [investmentProgram, setInvestmentProgram] = useState<InvestmentProgram>();
    const [searchText, setSearchText] = useState('');
    const [isTabChange, setTabChange] = useState(false);

    const myInvestmentProgramSearchOptions = [searchDropdownOptions[0]];
    const isTeamInvestmentProgram = currentTab === TabType.TeamInvestmentProgramsTab;
    const currentTabSearchOptions =
        isTeamInvestmentProgram ? searchDropdownOptions : myInvestmentProgramSearchOptions;

    const [searchType, setSearchType] = useState(currentTabSearchOptions[0].value);
    const isSearching = () => {
        return searchText.length > 0;
    };

    const handleSelectInvestmentProgram = (selectedInvestmentProgram: InvestmentProgram) => {
        setInvestmentProgram(selectedInvestmentProgram);
    };

    const handleConfirm = (confirmedInvestmentProgram: InvestmentProgram) => {
        setInvestmentProgram(undefined);
        onSave(confirmedInvestmentProgram, isTeamInvestmentProgram);
    };

    const handleCancel = () => {
        setInvestmentProgram(undefined);
    };

    const onChangeCurrentPage = (newPage: number) => {
        setCurrentPage(newPage);
        handleSearch({page: newPage + 1})
    };

    const onChangePageSize = (newPageSize: number) => {
        setPageSize(newPageSize);
        handleSearch({pageSize: newPageSize});
    }

    const onChangeTab = (newTab: TabType) => {
        if (newTab === currentTab) return;

        setCurrentTab(newTab);
        setSearchType(currentTabSearchOptions[0].value);
        setTabChange(true);
        resetSearch(newTab === TabType.TeamInvestmentProgramsTab)
    }

    const resetSearch = (isTeamTab: boolean) => {
        setSearchText('');
        setCurrentPage(DEFAULT_PAGE);
        setPageSize(DEFAULT_PAGE_SIZE);

        onSearch({
            team: isTeamTab,
            page: DEFAULT_PAGE + 1,
            pageSize: DEFAULT_PAGE_SIZE
        });
    }

    const handleSearch = (overrides: Partial<SearchParams> = {}) => {
        onSearch({
            team: isTeamInvestmentProgram,
            page: currentPage + 1,
            pageSize: pageSize,
            [`${searchType}`]: searchText,
            ...overrides
        })
        setTabChange(false);
    }

    const handleClearSearchText = () => {
        resetSearch(isTeamInvestmentProgram);
    };

    const handleChangeSearchText = (textToSearch: string) => {
        setCurrentPage(DEFAULT_PAGE);
        setSearchText(textToSearch);
    }

    const handleChangeSearchType = (typeToSearch: string) => {
        setCurrentPage(DEFAULT_PAGE);
        setSearchType(typeToSearch);
    }

    return (
        <>
            <HeaderAndSearchBar
                onSearch={() => handleSearch()}
                onClear={handleClearSearchText}
                onCancel={onCancel}
                searchType={searchType}
                searchOptions={currentTabSearchOptions}
                errorMessage={errorMessage}
                detailErrorMessage={detailErrorMessage}
                searchText={searchText}
                onChangeSearchText={handleChangeSearchText}
                onChangeSearchType={handleChangeSearchType}
            />
            <section className="investment-programs-container">
                <InvestmentProgramTabs
                    onChangeTab={onChangeTab}
                    myInvestmentPrograms={myInvestmentPrograms}
                    teamInvestmentPrograms={teamInvestmentPrograms}
                    onSelect={handleSelectInvestmentProgram}
                    currentPage={currentPage}
                    onChangeCurrentPage={onChangeCurrentPage}
                    pageSize={pageSize}
                    onChangePageSize={onChangePageSize}
                    isLoading={isLoading}
                    isSearching={isSearching()}
                    displayNoMatchFound={displayNoMatchFound}
                    isTabChange={isTabChange}
                />
            </section>
            <ConfirmationModal
                investmentProgram={investmentProgram}
                onConfirm={handleConfirm}
                onCancel={handleCancel}
            />
            <DisclaimerMessage origin={"IP"}/>
        </>
    );
}

export default AddInvestmentProgram;