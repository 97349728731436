import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store/store";
import {
    AssetRelianceResponse,
    AssetRelianceStack,
    EMPTY_ASSET_RELIANCE_RESPONSE
} from "../models/AssetRelianceResponse";
import {
    AssetRelianceButtonState,
    EMPTY_ASSET_RELIANCE_BUTTON_STATE,
    EMPTY_ASSET_RELIANCE_BUTTON_STATE_FOR_REPORT
} from "./AssetRelianceButtonState";

export interface AssetRelianceState {
    assetRelianceResponse: AssetRelianceResponse;
    assetReliancePageActionMenu: AssetRelianceButtonState;
    assetReliancePageActionMenuForDetailedReport: AssetRelianceButtonState;
    assetReliancePageActionMenuForSummaryReport: AssetRelianceButtonState;
}

export const initialAssetRelianceState: AssetRelianceState = {
    assetRelianceResponse: EMPTY_ASSET_RELIANCE_RESPONSE,
    assetReliancePageActionMenu: EMPTY_ASSET_RELIANCE_BUTTON_STATE,
    assetReliancePageActionMenuForDetailedReport: EMPTY_ASSET_RELIANCE_BUTTON_STATE_FOR_REPORT,
    assetReliancePageActionMenuForSummaryReport: EMPTY_ASSET_RELIANCE_BUTTON_STATE_FOR_REPORT
}

export const assetRelianceSlice = createSlice({
    name: "assetReliance",
    initialState: initialAssetRelianceState,
    reducers: {
        setAssetRelianceResponse: (state, action: PayloadAction<AssetRelianceResponse>) => {
            state.assetRelianceResponse = action.payload;
        },
        setAssetRelianceStacks: (state, action: PayloadAction<AssetRelianceStack[]>) => {
            state.assetRelianceResponse.assetStacks = [...action.payload]
                .sort((a: AssetRelianceStack, b: AssetRelianceStack) => {
                    return a.stackSequenceNumber - b.stackSequenceNumber;
                });
        },
        setAssetReliancePageActionMenu: (state, action: PayloadAction<AssetRelianceButtonState>) => {
            state.assetReliancePageActionMenu = action.payload;
        },
        setAssetReliancePageActionMenuForDetailedReport: (state, action: PayloadAction<AssetRelianceButtonState>) => {
            state.assetReliancePageActionMenuForDetailedReport = action.payload;
        },
        setAssetReliancePageActionMenuForSummaryReport: (state, action: PayloadAction<AssetRelianceButtonState>) => {
            state.assetReliancePageActionMenuForSummaryReport = action.payload;
        },
        resetAssetReliancePageActionMenu: (state) => {
            state.assetReliancePageActionMenu = EMPTY_ASSET_RELIANCE_BUTTON_STATE;
        }
    }
});

export const {
    setAssetRelianceResponse,
    setAssetRelianceStacks,
    setAssetReliancePageActionMenu,
    resetAssetReliancePageActionMenu,
    setAssetReliancePageActionMenuForDetailedReport,
    setAssetReliancePageActionMenuForSummaryReport
} = assetRelianceSlice.actions;

export const selectAssetRelianceResponse = (state: RootState) => state.client.assetReliance.assetRelianceResponse;
export const selectFutureValueExcess = (state: RootState) => selectAssetRelianceResponse(state).allAssetsStack.futureValueOfExcessAssets;
export const selectAssetReliancePageActionMenu = (state: RootState) => state.client.assetReliance.assetReliancePageActionMenu;
export const selectAssetReliancePageActionMenuForDetailedReport = (state: RootState) => state.client.assetReliance.assetReliancePageActionMenuForDetailedReport;
export const selectAssetReliancePageActionMenuForSummaryReport = (state: RootState) => state.client.assetReliance.assetReliancePageActionMenuForSummaryReport;

export default assetRelianceSlice.reducer;