import React, {useState} from "react";
import welcomeBg from './images/WelcomeBg.png';
import whatIsGdwmBg from './images/WhatIsGdwmBg.png';
import scenarioPlanSummary from './images/ScenarioPlanSummary.svg';
import {Logo} from "xps-react";
import {Button} from "../../components";

const IntroDeck: React.FC = () => {
    const [isStarted, setIsStarted] = useState(false);

    const startSlides = () => {
        setIsStarted(true);
    };

    if (isStarted) {
        return <Slides/>
    }

    return <WelcomeSlide onClick={startSlides}/>
}


function WelcomeSlide(props: { onClick: () => void }) {
    return <div className="intro-deck" data-theme="aqua"
                style={{backgroundImage: `url(${welcomeBg})`, backgroundRepeat: "no-repeat"}}>
        <header className="intro-deck__header">
            <Logo
                alt="Stacked Left-Aligned Double Line Anchor Logo"
                ariaLabel="Northern Trust Logo"
                color="white"
                logoType="double"
                width='132'
            />
        </header>
        <section className="intro-deck__body flex-column">
            <span className="welcome-title">Welcome to <br/> Goals-Driven Wealth <br/> Management</span>
            <span className="welcome-message">
                    A disciplined investment approach that empowers you <br/> with confidence, no matter how life unfolds.
                </span>
            <Button className="margintop-xxl start-button" onClick={props.onClick} kind="secondary"
                    size="medium">Start</Button>
        </section>
    </div>;
}

function Slides() {
    return <div className="intro-deck" data-theme="aqua"
                style={{backgroundImage: `url(${whatIsGdwmBg})`, backgroundRepeat: "no-repeat"}}>
        <header className="intro-deck__header">
            <Logo
                className="nt-logo"
                alt="Stacked Left-Aligned Double Line Anchor Logo"
                ariaLabel="Northern Trust Logo"
                color="white"
                logoType="double"
                width="132"
            />
            <span className="intro-deck__title marginbottom-lg">
                What is Goals Driven <br/> Wealth Management?
            </span>
            <span className="intro-deck__subtitle">
                An institutionally supported framework for making decisions, helping you identify
                <br/>opportunities, evaluate trade-offs and providing the confidence to take action.
            </span>
        </header>
        <section className="intro-deck__body justify-content-between margintop-xxxl">
            <div className="what-is-gdwm display-flex flex-column margintop-xxxl flex-grow-1">
                <hr/>
                <span>Northern’s Approach to Wealth <br/> Management Puts You at the Center.</span>
                <ul>
                    <li>Your goals and priorities come first</li>
                    <li>Your assets are directly aligned to your goals</li>
                    <li>You will have a better understanding of risk</li>
                    <li>You will have Northern Trust as your guide</li>
                </ul>
            </div>
            <img
                src={scenarioPlanSummary}
                className="scenario-image width-50p"
                alt="Scenario Plan Summary"
                aria-label="Scenario Plan Summary"
            />
        </section>
        <footer className="intro-deck__footer justify-end">
            <Button
                className="float-right"
                icon="only"
                iconName="chevron_right"
                kind="primary"
                size="large"
                type="button"
            />
        </footer>
    </div>;
}

export default IntroDeck;
