import {HoldingAssetSubclassDetails} from "../../models/Holding";
import React from "react";
import {formatNumber} from "../../../utils/format";
import {sumByField} from "./holdingUtils";
import {AssetSubclass} from "../../models/AssetClassifications";

export const EditProductHolding = ({
                                                assetSubclass,
                                                holdingsWithIndex,
                                                subclassIndex,
                                                assetSubclassDetailsRowRenderer,
                                            }: {
    assetSubclass: AssetSubclass,
    holdingsWithIndex: { holding: HoldingAssetSubclassDetails; index: number }[],
    subclassIndex: number,
    assetSubclassDetailsRowRenderer: (assetSubclassDetail: HoldingAssetSubclassDetails, index: number) => React.ReactNode,
}) => {
    const assetSubclassDetailsIds = assetSubclass.assetSubclassDetails
        .map(assetSubclassDetails => assetSubclassDetails.assetSubclassDetailsId);
    const holdingsInSubclass = holdingsWithIndex
        .filter(({holding}) => assetSubclassDetailsIds.includes(holding.assetSubclassDetailsId))
        .map(({holding}) => holding);
    const taxCost = formatNumber(sumByField(holdingsInSubclass, holding => holding.taxCost))
    const marketValue = formatNumber(sumByField(holdingsInSubclass, holding => holding.marketValue))
    const investableValue = formatNumber(sumByField(holdingsInSubclass, holding => holding.investableValue))

    return (
        <div key={`asset-subclass-${subclassIndex}`} className="holding-grid">
            <div className="grid-display-contents holding-subclass-row" role="row"
                 aria-label={`${assetSubclass.assetSubclassName} - Tax Cost ${taxCost} - Market Value ${marketValue} - Investable Value ${investableValue}`}>
                <span className="line"/>
                <span role="cell" aria-label={`${assetSubclass.assetSubclassName}`}
                      className="holding-subclass-name fontweight-medium holding-grid-column-custom-span">{assetSubclass.assetSubclassName}</span>
                <span role="cell" aria-label={`Tax Cost - ${taxCost}`}
                      className="textalign-right">{taxCost}</span>
                <span role="cell" aria-label={`Market Value - ${marketValue}`}
                      className="textalign-right">{marketValue}</span>
                <span role="cell"
                      aria-label={`Investable Value - ${investableValue}`}
                      className="textalign-right">{investableValue}</span>
                <span role="cell" className="empty-grid-column"/>
            </div>
            <div className="grid-display-contents">
                {
                    assetSubclass.assetSubclassDetails
                        .flatMap((assetSubclassDetail) =>
                            holdingsWithIndex.filter(({holding}) =>
                                holding.assetSubclassDetailsId === assetSubclassDetail.assetSubclassDetailsId))
                        .map(({holding, index}) =>
                            assetSubclassDetailsRowRenderer(holding, index))
                }
            </div>
        </div>
    )
};
