import {LifestyleSpendingGoal} from "../models/LifestyleSpendingGoal";
import {InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import {ProfileResponse} from "../../ClientManagement/models/ProfileResponse";
import {PortfolioReserveResponse} from "../models/PortfolioReserve";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {AssetTotals} from "../../Assets/models/Assets";
import {goalsApiClient} from "../GoalsApiClient";
import {assetsApiClient} from "../../Assets/AssetsApiClient";
import {emptyPortfolioReserveModel, PortfolioReserveModelType} from "./models/PortfolioReserveModelType";
import {AllGoalsType, BeneficiaryGoals, BeneficiaryGoalsResponse} from "../models/GoalModelType";
import FamilyGoalUtils from "../Summary/FamilyGoalUtils";
import GoalsService from "../controller/GoalsService";
import {FamilyGoalType} from "../models/FamilyGoalType";
import {formatTaxLiabilities} from "../GoalsUtils";
import {NonLifestyleGoalType} from "../models/GoalType";
import GoalUtils from "../Summary/GoalUtils";
import {parseLifestyleGoalResponse} from "../parseLifestyleGoalResponse";

const getPortfolioReserveModel = async (profileId: string, handleError: Function): Promise<PortfolioReserveModelType> => {
    const apiRequests: [
        Promise<AllGoalsType>,
        Promise<ProfileResponse>,
        Promise<InvestorGroupType>,
        Promise<AssetTotals>,
    ] = [
        goalsApiClient.getAllGoals(profileId),
        clientManagementApiClient.getProfile(profileId),
        clientManagementApiClient.getInvestorGroup(profileId),
        assetsApiClient.getAssetTotals(profileId),
    ]
    try {
        let [
            allGoals,
            profile,
            investorGroup,
            assets,
        ] = await Promise.all(apiRequests)

        let portfolioReserve: PortfolioReserveResponse;
        const familyGoals: { [key: string]: BeneficiaryGoals } = {}
        const goalMap: { [key: string]: FamilyGoalType } = {}
        const goals: { annualFlow: number, yearsOfFlow: number, yearsUntilFlow: number }[] = []
        const lifestyleSpendingGoal: LifestyleSpendingGoal = parseLifestyleGoalResponse(allGoals.lifeStyle, investorGroup.planningPeriod)

        Object.entries(allGoals.family)
            .forEach(([key, value]) => {
                familyGoals[key] = GoalsService.transformBeneficiaryGoals(value, profile)
            })

        Object.values(allGoals.family)
            .forEach((beneficiaryGoal: BeneficiaryGoalsResponse) => {
                    Object.entries(beneficiaryGoal.goals)
                        .forEach(([key, value]) =>
                            goalMap[key] = value
                        )
                }
            )

        let nonLifestyleRiskControl: number = FamilyGoalUtils.getTotalNonLifestyleRiskControl(Object.values(familyGoals)) + GoalUtils.getTotalNonLifestyleRiskControlForNonLSGoals(allGoals.nonLifestyle);
        let nonLifestyleTotalPresentValue: number = FamilyGoalUtils.getTotalPresentValueForBeneficiary(goalMap) + GoalUtils.getTotalPresentValueForNonLSGoals(allGoals.nonLifestyle);


        Object.values(goalMap)
            .forEach((familyGoal: FamilyGoalType) => {
                    goals.push(GoalUtils.getFormattedCalculationGoals(familyGoal, investorGroup))
                }
            )

        Object.values(allGoals.nonLifestyle)
            .forEach((nonLifestyleGoal: NonLifestyleGoalType) => {
                goals.push(GoalUtils.getFormattedCalculationGoals(nonLifestyleGoal, investorGroup))
            })

            if (profile.portfolioReserveTargetLength != undefined) {
                portfolioReserve = await goalsApiClient.getPortfolioReserve(profileId,
                    {
                        selectedReserveTarget: String(profile.portfolioReserveTargetLength)
                    }
                );
            } else {
                portfolioReserve = await goalsApiClient.getPortfolioReserve(profileId);
            }

        return {
            ...emptyPortfolioReserveModel,
            lifestyleSpendingGoal,
            profile,
            portfolioReserve,
            assets,
            investorGroup,
            normalizedNonLifestyleGoals: {
                nonLifestyleRiskControl: nonLifestyleRiskControl,
                nonLifestyleTotalPresentValue: nonLifestyleTotalPresentValue,
                goals: goals
            },
            familyGoals: allGoals.family,
            nonLifestyleGoals: allGoals.nonLifestyle,
            totalAssetPurchasesPresentValue: allGoals.totalAssetPurchasesPresentValue,
            taxLiabilities: formatTaxLiabilities(assets.totalTaxLiabilities),
        }
    } catch (e: Error | any) {
        if(e.headers) {
            handleError(e.headers.get('trace-id'));
        } else {
            handleError(e.messageClientId);
        }
        console.error("This error occurred while trying to construct PortfolioReserveModel", e.message)
        return emptyPortfolioReserveModel
    }
}

export default {getPortfolioReserveModel};




