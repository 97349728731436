import {AgendaDetails, AgendaItem, Presenter} from "../models/AgendaDetails";
import {DEFAULT_AGENDA_ENTRIES} from "../agendaConstants";

export function generateAgenda(overrides?: Partial<AgendaDetails>): AgendaDetails {
    return {
        id: 'test-agenda-1',
        profileId: 'test-profile',
        meetingTitle: "Shauna's Annual Review",
        clientProfileName: "Shauna display name",
        meetingDate: "2022-11-12T12:00:00.000Z",
        meetingLocation: "Northern Trust",
        createdDate: "2022-10-05T12:00:00.000Z",
        presenters: [
            generateNTPresenterDetails(),
            generateNTPresenterDetails({
                presenterId: 'test-presenter-id-2',
                presenterSequenceNo:1,
            }),
            generateExternalPresenterDetails()
        ],
        agendaItems: [generateAgendaEntryItem()],
        ...overrides
    }
}

export function generateNTPresenterDetails(overrides?: Partial<Presenter>): Presenter {
    return {
        presenterId: 'test-presenter-id-1',
        presenterSequenceNo:0,
        presenterType: 'NT_PRESENTER',
        ...overrides
    }
}

export function generateExternalPresenterDetails(overrides?: Partial<Presenter>): Presenter {
    return {
        id: 'test-id-1',
        presenterName: 'External Presenter 1',
        presenterSequenceNo:2,
        presenterType: 'EXTERNAL_PRESENTER',
        ...overrides
    }
}

export function generateAgendaEntryItem(overrides?: Partial<AgendaItem>): AgendaItem {
    return {
        id: 'agenda-entry-1',
        startTime: '12:00 PM',
        presenterName: 'Mayur Suramwar',
        title: 'Adding Personal Assets',
        notes: '- Discuss about new personal assets acquired in the last year',
        itemSequenceNo: 1,
        ...overrides
    }
}

export function generateDefaultAgenda(overrides?: Partial<AgendaDetails>): AgendaDetails {
    return {
        profileId: "",
        meetingTitle: "",
        clientProfileName: "",
        meetingDate: "",
        meetingLocation: "",
        presenters: [{
            presenterType: "NT_PRESENTER"
        }],
        agendaItems: DEFAULT_AGENDA_ENTRIES,
        ...overrides
    }
}