import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from '../store/store';

export type ResourcesState = {
    currentPage: number,
    pdfUrl: string | null,
    redirectUrl: string | null
}

const defaultResourcesState: ResourcesState = {
    currentPage: 0,
    pdfUrl: null,
    redirectUrl: null
}

function initialState(): ResourcesState {
    return defaultResourcesState;
}

const resourcesSlice = createSlice({
    name: 'assets',
    initialState,
    reducers: {
        setDocumentInfo: (state, action: PayloadAction<ResourcesState>) => ({
            ...state,
            ...action.payload,
        }),
        clearDocumentInfo: () => defaultResourcesState,
    }
});

export const {
    setDocumentInfo,
    clearDocumentInfo,
} = resourcesSlice.actions;

export const selectDocumentInfo = (state: RootState) => state.client.resources;

export default resourcesSlice.reducer;
