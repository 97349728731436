import {InflowPresentValue, LifestyleSpendingGoal} from "./LifestyleSpendingGoal";

export type PresentValueResponse = {
    presentValue: number;
    goalsPresentValue?: number;
    presentValueByYear?: number[]
    riskAsset: number;
    riskControl: number;
    tripleNetDiscountRate: number;
    grossDiscountRate: number
}

export type LifestylePresentValueResponse = PresentValueResponse & {
    totalAlignedInflowPresentValue: number;
    requiredPortfolioSpendingPresentValue: number;
    portfolioReserveAlignedInflowPresentValue: number;
    portfolioReservePresentValue: number;
    reserveTargetAmount: number;
    presentValueForInsufficientYears: number;
    inflowPresentValues: InflowPresentValue[];
    sufficientYears: number;
}

export type LifestyleAndNonLifestylePresentValueResponse = {
    lifestylePresentValue: LifestylePresentValueResponse | null,
    nonLifestylePresentValue: PresentValueResponse
};

export type NonLSPresentValueRequest = {
    annualFlow: string,
    startDate: string,
    endDate: string,
    riskControlAsset?: string,
    nonPortfolioFunded: number,
}

export type LifestyleAndNonLifestylePresentValueRequest = {
    lifestylePresentValueRequest: LifestyleSpendingGoal["userInputs"] |null;
    nonLifestyleGoal: NonLSPresentValueRequest & {goalId: string} & {isFundedByNonInvestableAssets: boolean};
};

export const emptyPresentValueResponse: PresentValueResponse = {
    presentValue: 0,
    riskAsset: 0,
    riskControl: 100,
    tripleNetDiscountRate: 0,
    grossDiscountRate: 0
}

export const emptyLifestylePresentValueResponse: LifestylePresentValueResponse = {
    ...emptyPresentValueResponse,
    totalAlignedInflowPresentValue: 0,
    requiredPortfolioSpendingPresentValue: 0,
    portfolioReserveAlignedInflowPresentValue: 0,
    portfolioReservePresentValue: 0,
    reserveTargetAmount: 0,
    presentValueForInsufficientYears: 0,
    inflowPresentValues: [],
    sufficientYears: 0,
};

export const emptyNonLifestylePresentValueResponse: LifestyleAndNonLifestylePresentValueResponse = {
    lifestylePresentValue: null,
    nonLifestylePresentValue: emptyPresentValueResponse
};