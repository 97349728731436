import React from 'react';
import {LifestyleSpendingPeriodInputs} from "../models/LifestyleSpendingGoal";
import {PlanningPeriodType} from "../../ClientManagement/models/InvestorGroupType";
import LifestyleSpendingPeriod from "./LifestyleSpendingPeriod";


interface LifestyleSpendingPeriodsListProps {
    lifestyleSpendingPeriodsList: LifestyleSpendingPeriodInputs[]
    onUpdate: (updatedLifestyleSpendingPeriods: LifestyleSpendingPeriodInputs[]) => Promise<void>
    onBlurAnnualSpend: () => void
    onBlurLengthOfSpend: () => void
    planningPeriod: PlanningPeriodType
    hasError: boolean
    isProfileWithProposalsOrArchived?: boolean
}

const LifestyleSpendingPeriodsList = ({
                                          lifestyleSpendingPeriodsList,
                                          onUpdate,
                                          planningPeriod,
                                          hasError,
                                          onBlurAnnualSpend,
                                          onBlurLengthOfSpend,
                                          isProfileWithProposalsOrArchived = false
                                      }: LifestyleSpendingPeriodsListProps) => {

    const handleUpdateSpendingPeriodList = async (replaceSpendingPeriod: LifestyleSpendingPeriodInputs, index: number) => {
        let tempList = [...lifestyleSpendingPeriodsList]
        tempList.splice(index, 1, replaceSpendingPeriod)
        await onUpdate(tempList)
    }

    const handleDescriptionChange = async (description: string, index: number) => {
        const replaceSpendingPeriod: LifestyleSpendingPeriodInputs = {
            ...lifestyleSpendingPeriodsList[index],
            description: description,
        }
        await handleUpdateSpendingPeriodList(replaceSpendingPeriod, index)
    }

    const handleAnnualSpendChange = async (annualSpend: string, index: number) => {
        const replaceSpendingPeriod: LifestyleSpendingPeriodInputs = {
            ...lifestyleSpendingPeriodsList[index],
            annualSpend: parseInt(annualSpend) ? parseInt(annualSpend) : 0,
        }
        await handleUpdateSpendingPeriodList(replaceSpendingPeriod, index)
    }

    const handleLengthOfSpendChange = async (newInput: string, changeIndex: number) => {
        const changedLengthOfSpend = parseInt(newInput) ? parseInt(newInput) : 0;
        const initialLengthOfSpend = lifestyleSpendingPeriodsList[changeIndex].endYear - lifestyleSpendingPeriodsList[changeIndex].startYear
        const delta = changedLengthOfSpend - initialLengthOfSpend

        const updatedSpendingPeriods = lifestyleSpendingPeriodsList.map((period, index) => {
            if (index > changeIndex) {
                return {
                    ...period,
                    startYear: period.startYear + delta,
                    endYear: period.endYear + delta
                }
            } else if (index === changeIndex) {
                return {
                    ...period,
                    endYear: period.startYear + changedLengthOfSpend
                }
            } else {
                return period
            }
        })

        await onUpdate(updatedSpendingPeriods)
    }

    const handleDeleteSpendingPeriod = async (index: number) => {
        const listWithDeletions = [...lifestyleSpendingPeriodsList]
        listWithDeletions.splice(index, 1)

        if (listWithDeletions.length > 1) {
            const delta = lifestyleSpendingPeriodsList[index].endYear - lifestyleSpendingPeriodsList[index].startYear
            return onUpdate(updatePeriodYears(listWithDeletions, index, delta));
        }
        return onUpdate([{
            ...listWithDeletions[0],
            startYear: planningPeriod.startYear,
            endYear: planningPeriod.startYear + planningPeriod.numberOfYears
        }])
    }

    const disableLengthOfSpend = lifestyleSpendingPeriodsList.length === 1

    return (
        <>
            {lifestyleSpendingPeriodsList.map((item, index) => {
                const lengthOfSpend = item.endYear - item.startYear
                const spendingTimeFrame = `${item.startYear}—${item.endYear}`
                const clientAgeRange =`${planningPeriod.ageFrom + item.startYear - planningPeriod.startYear}—${planningPeriod.ageFrom + item.endYear - planningPeriod.startYear}`
                return (
                    <div key={index}>
                        {index !== 0 && <hr/>}
                        <div className='lifestyle-grid spending-period' data-testid="lifestyle-spending-period">
                            <LifestyleSpendingPeriod
                                lifestyleSpendingPeriod={item}
                                onChangeDescription={(description) => {
                                    handleDescriptionChange(description, index)
                                }}
                                onChangeAnnualSpend={(annualSpend) => {
                                    handleAnnualSpendChange(annualSpend, index)
                                }}
                                onChangeLengthOfSpend={(updatedLengthOfSpend) => {
                                    handleLengthOfSpendChange(updatedLengthOfSpend, index)
                                }}
                                onBlurAnnualSpend={onBlurAnnualSpend}
                                onDeleteSpendingPeriods={() => {
                                    handleDeleteSpendingPeriod(index)
                                }}
                                onBlurLengthOfSpend={onBlurLengthOfSpend}
                                disableLengthOfSpend={disableLengthOfSpend}
                                hasError={hasError}
                                lengthOfSpend={lengthOfSpend}
                                spendingTimeFrame={spendingTimeFrame}
                                clientAgeRange={clientAgeRange}
                                isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                            />
                        </div>
                    </div>
                )
            })}
        </>
    )
}

const updatePeriodYears = (periods: LifestyleSpendingPeriodInputs[], index: number, delta: number) => {
    return periods.map((period, newIndex) => {
        if (newIndex < index) {
            return period
        } else {
            return {
                ...period,
                startYear: period.startYear - delta,
                endYear: period.endYear - delta
            }
        }
    })
}

export default LifestyleSpendingPeriodsList
