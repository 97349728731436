import {emptyLifestylePresentValueResponse} from "../models/PresentValue";
import {
    emptyLifestyleSpendingPeriod,
    LifestyleSpendingGoalResponse,
    LifestyleSpendingRequest
} from "../models/LifestyleSpendingGoal";

import {v4 as randomUuid} from "uuid";

export const emptyLifestyleSpendingRequest = (startYear: number, endYear: number): LifestyleSpendingRequest => ({
    userInputs: {
        lifestyleSpendingPeriods: [emptyLifestyleSpendingPeriod(startYear, endYear)],
        isFundedByNonInvestableAssets: false,
        totalFundedByNonInvestableAssetsAmount: null,
    },
    lengthOfPlanningPeriod: 0
})

export const emptyLifestyleSpendingGoalResponse: LifestyleSpendingGoalResponse = {
    calculatedFields: {...emptyLifestylePresentValueResponse},
    clientId: randomUuid(),
    ...emptyLifestyleSpendingRequest(2000, 2100)
}

