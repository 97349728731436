import {TableActionDropdownMenu} from "../../../components/Table/TableActionDropdownMenu";
import {DropdownItem, Icon} from "../../../components";
import React from "react";
import classNames from "classnames";
import {TableCell} from "src/components/Table/TableCell";
import {NO_OP} from "src/constants/common";
import {formatCurrency} from "src/utils/format";
import {toDisplayDateFormat} from "src/utils/dateUtils";


import {MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import useProfileEditableState from "../../../hooks/useProfileEditableState";
import AccordionItemReport from "./AccordionItemReport";
import {EquityCompensationFormData} from "../../../Assets/models/EquityCompensation";
import {shortenName} from "../../../Assets/common/textUtils";
import {getCurrentOwnerByOwnerMemberId, getMemberName} from "../../../Assets/AssetSummary/common/ownershipUtils";

type AssetSummaryEquityCompProps = {
    equityComp_: EquityCompensationFormData;
    onClickEdit_: (id: string | undefined) => void;
    onClickDelete_: (equityComp: EquityCompensationFormData) => void;
    actionsDisabled_: boolean;
    gridClassName_: string;
    uuid_: string;
    memberGroup_: MemberGroup | undefined;
};
export const EquityCompensationTableRowReport = ({
                                               equityComp_,
                                               onClickEdit_,
                                               onClickDelete_,
                                               actionsDisabled_,
                                               gridClassName_,
                                               uuid_,
                                               memberGroup_,
                                           }: AssetSummaryEquityCompProps) => {
    const hasStockOptions_ = equityComp_.stockOptionAfterTaxUnvestedValue !== null || equityComp_.stockOptionAfterTaxVestedValue !== null;
    const hasStockGrants_ = equityComp_.stockGrantAfterTaxUnvestedValue !== null
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    return (
        <AccordionItemReport
            className_="assets-grid-with-actionmenu equity-compensation-summary-accordion-item"
            HeaderComponent={({ expanded }) => (
                <div
                    key={`equity-compensation-row-${equityComp_.id}`}
                    aria-label={`equity-compensation-row-${equityComp_.id}`}
                    role="row"
                    className={classNames("assets-grid-table-row", gridClassName_)}
                    tabIndex={0}
                >
          <span
              role="cell"
              aria-label="Name & Owner"
              className="display-flex align-items-center"
          >
            <Icon
                name={expanded ? "chevron_down" : "chevron_right"}
                size="large"
            />
            <span className="paddingleft-md">
              <div className="cell-text-content">
                {shortenName(equityComp_.entityOrAccountName)}
              </div>
              <div
                  className="sublabel-12px cell-text-content"
                  data-testid="tableCellSubText"
              >
                {getMemberName(getCurrentOwnerByOwnerMemberId(equityComp_.owner, memberGroup_!))}
              </div>
            </span>
          </span>
                    <TableCell
                        ariaLabel="Pre-Tax Unvested"
                        text={
                            equityComp_.preTaxUnvestedValue
                                ? formatCurrency(equityComp_.preTaxUnvestedValue)
                                : "--"
                        }
                        className="textalign-right"
                    />
                    <TableCell
                        ariaLabel="Pre-Tax Vested"
                        text={
                            equityComp_.preTaxVestedValue
                                ? formatCurrency(equityComp_.preTaxVestedValue)
                                : "--"
                        }
                        className="textalign-right"
                    />
                    <TableCell
                        ariaLabel="After-Tax Unvested"
                        text={
                            equityComp_.afterTaxUnvestedValue
                                ? formatCurrency(equityComp_.afterTaxUnvestedValue)
                                : "--"
                        }
                        className="textalign-right"
                    />
                    <TableCell
                        ariaLabel="After-Tax Vested"
                        text={
                            equityComp_.afterTaxVestedValue
                                ? formatCurrency(equityComp_.afterTaxVestedValue)
                                : "--"
                        }
                        className="textalign-right"
                    />
                    <TableActionDropdownMenu
                        disabled={actionsDisabled_}
                        ariaLabel={`${equityComp_.entityOrAccountName} Menu`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <DropdownItem
                            itemText="Edit Asset"
                            value="Edit Asset"
                            onClick={() => onClickEdit_(equityComp_.id)}
                        />
                        <DropdownItem
                            itemText="Delete Asset"
                            value="Delete Asset"
                            disabled={isProfileWithProposalsOrArchived}
                            onClick={() => onClickDelete_(equityComp_)}
                        >
                            <Icon name="delete" className="asset-table-delete-icon" />
                        </DropdownItem>
                    </TableActionDropdownMenu>
                </div>
            )}
            uuid_={uuid_}
            key={uuid_}
        >
            {hasStockOptions_ && <div
                key={`stock-options-row-${equityComp_.id}`}
                aria-label={`stock-options-row-${equityComp_.id}`}
                role="row"
                className={classNames(
                    "assets-grid-table-row nested-row-within-accordion",
                    gridClassName_
                )}
                tabIndex={0}
            >
                <TableCell
                    ariaLabel="Name & As Of Date"
                    text={"Options"}
                    subtext={
                        equityComp_.stockOptionsAsOfDate
                            ? `As of ${toDisplayDateFormat(equityComp_.asOfDate)}`
                            : "--"
                    }
                />
                <TableCell
                    text={
                        equityComp_.stockOptionPreTaxUnvestedValue
                            ? formatCurrency(equityComp_.stockOptionPreTaxUnvestedValue)
                            : "--"
                    }
                    className="textalign-right"
                    ariaLabel="Pre-Tax Unvested"
                />
                <TableCell
                    text={
                        equityComp_.stockOptionPreTaxVestedValue
                            ? formatCurrency(equityComp_.stockOptionPreTaxVestedValue)
                            : "--"
                    }
                    className="textalign-right"
                    ariaLabel="Pre-Tax Vested"
                />
                <TableCell
                    text={
                        equityComp_.stockOptionAfterTaxUnvestedValue
                            ? formatCurrency(equityComp_.stockOptionAfterTaxUnvestedValue)
                            : "--"
                    }
                    className="textalign-right"
                    ariaLabel="After-Tax Unvested"
                />
                <TableCell
                    text={
                        equityComp_.stockOptionAfterTaxVestedValue
                            ? formatCurrency(equityComp_.stockOptionAfterTaxVestedValue)
                            : "--"
                    }
                    className="textalign-right"
                    ariaLabel="After-Tax Vested"
                />
                <TableActionDropdownMenu
                    disabled={true}
                    ariaLabel={`${equityComp_.entityOrAccountName} Stock Options Menu`}
                >
                    <DropdownItem
                        itemText="Edit Options"
                        value="Edit Options"
                        onClick={NO_OP}
                    />
                </TableActionDropdownMenu>
            </div>}
            {hasStockGrants_ && <div
                key={`stock-grants-row-${equityComp_.id}`}
                aria-label={`stock-grants-row-${equityComp_.id}`}
                role="row"
                className={classNames(
                    "assets-grid-table-row nested-row-within-accordion",
                    gridClassName_
                )}
                tabIndex={0}
            >
                <TableCell
                    ariaLabel="Name & As Of Date"
                    text={"Grants"}
                    subtext={
                        equityComp_.stockGrantsAsOfDate
                            ? `As of ${toDisplayDateFormat(equityComp_.asOfDate)}`
                            : "--"
                    }
                />
                <TableCell
                    ariaLabel="Pre-Tax Unvested"
                    text={
                        equityComp_.stockGrantPreTaxUnvestedValue
                            ? formatCurrency(equityComp_.stockGrantPreTaxUnvestedValue)
                            : "--"
                    }
                    className="textalign-right"
                />
                <TableCell
                    ariaLabel="Pre-Tax Vested"
                    text={"--"}
                    className="textalign-right"
                />
                <TableCell
                    ariaLabel="After-Tax Unvested"
                    text={
                        equityComp_.stockGrantAfterTaxUnvestedValue
                            ? formatCurrency(equityComp_.stockGrantAfterTaxUnvestedValue)
                            : "--"
                    }
                    className="textalign-right"
                />
                <TableCell
                    ariaLabel="After-Tax Vested"
                    text={"--"}
                    className="textalign-right"
                />
                <TableActionDropdownMenu
                    disabled={true}
                    ariaLabel={`${equityComp_.entityOrAccountName} Stock Options Menu`}
                >
                    <DropdownItem
                        itemText="Edit Grants"
                        value="Edit Grants"
                        onClick={NO_OP}
                    />
                </TableActionDropdownMenu>
            </div>}
        </AccordionItemReport>
    );
};
