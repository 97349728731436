import React from "react";
import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";

type AssetAccordionContentHeaderProps = {
    investorGroup: InvestorGroupType,
    hideJoint?: boolean
}

export function AssetAccordionContentHeader({investorGroup, hideJoint = false}: AssetAccordionContentHeaderProps) {
    return <div role="row" className="current-net-worth-grid-with-actionmenu assets-grid-table-header" tabIndex={-1}>
                        <span role="columnheader"
                              className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} paddingleft-xxl textalign-left`}>Name</span>
        {investorGroup.partnerMember &&
            <>
                                <span role="columnheader"
                                      className="textalign-right">{investorGroup.primaryMember.firstName}</span>
                <span role="columnheader"
                      className="textalign-right">{investorGroup.partnerMember.firstName}</span>
                <span role="columnheader" className="textalign-right">{hideJoint ? '' : 'Joint'}</span>
            </>
        }
        <span role="columnheader" className='textalign-right'>Present Value</span>
    </div>;
}


