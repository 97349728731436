export type MarketStressEvent = {
    name: String
}

export type MarketStressResponse = {
    marketStressResponses: MarketStressEvent[]
}

export const emptyMarketStressResponse: MarketStressResponse = {
    marketStressResponses: [
        {
            name: ""
        }
    ]
}
