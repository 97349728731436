import {AllOrApprovedProduct, Product, ProductSearchResults} from "src/Assets/models/Product";
import {HoldingAssetSubclassDetails} from "../../../models/Holding";
import {TypeaheadSearchBar} from "src/components/TypeaheadSearchBar";
import AddCustomProductsDropdownMenu from "../AddCustomProductsDropdownMenu";
import {AssetClassifications} from "../../../models/AssetClassifications";
import React from "react";
import {Dropdown, DropdownItem} from "../../../../components";
import {DropdownOnChangeData} from "../../../../components/Dropdown/Dropdown";
import {formatCurrency} from "../../../../utils/format";

type AddProductProps = {
    onPerformSearch: (searchTerm: string, page?: number) => Promise<ProductSearchResults>,
    onProductSelect: (product: Product) => void,
    customProductClassifications: AssetClassifications,
    onAddCustomProduct: (assetSubclassDetail: HoldingAssetSubclassDetails) => void,
    defaultAllOrApprovedProduct: AllOrApprovedProduct,
    onChangeAllOrApprovedProductDropdown: (allOrApprovedProduct: DropdownOnChangeData<AllOrApprovedProduct>) => void,
    enableApprovedProducts: boolean | undefined
}

const AddProduct = ({
                        onPerformSearch,
                        onProductSelect,
                        onAddCustomProduct,
                        customProductClassifications,
                        onChangeAllOrApprovedProductDropdown,
                        defaultAllOrApprovedProduct,
                        enableApprovedProducts
                    }: AddProductProps) => {

    const searchPlaceHoldingText = defaultAllOrApprovedProduct === "allProducts"
        ? "Search all products by ticker, name or CUSIP"
        : "Search Northern Trust approved products by ticker or name";

    const isSearchingApprovedProducts = enableApprovedProducts && defaultAllOrApprovedProduct === "approvedProducts";

    function displayResultHeader() {
        return (
            isSearchingApprovedProducts
                ? <div className="search-approved-products-results-grid padding-md">
                    <span className="textalign-left"><b>Ticker</b></span>
                    <span className="textalign-left"><b>Name</b></span>
                    <span className="textalign-left"><b>Asset Subclass</b></span>
                    <span className="textalign-right"><b>Minimum</b></span>
                </div>
                : <div className="search-products-results-grid padding-md">
                    <span className="textalign-left"><b>Ticker</b></span>
                    <span className="textalign-left"><b>Name</b></span>
                    <span className="textalign-right"><b>CUSIP</b></span>
                    <span className="textalign-left"><b>Asset Class</b></span>
                    <span className="textalign-left"><b>Asset Subclass</b></span>
                    <span className="textalign-left"><b>Subclass Detail</b></span>
                </div>
        )
    }

    function formatInvestmentMinimumAmount(amount?: number) {
        return (amount ?? 0) > 0 ? formatCurrency(amount) : '-';
    }

    function displaySearchResult(result: Product){
        return (
            isSearchingApprovedProducts
            ? <div className="search-approved-products-results-grid padding-md"
                   data-testid="search-product-result">
                    <span className="textalign-left">{result.ticker}</span>
                    <span className="textalign-left">{result.name}</span>
                    <span className="textalign-left">{result.assetSubclass}</span>
                    <span className="textalign-right">{formatInvestmentMinimumAmount(result.investmentMinimumAmount)}</span>
                </div>
            : <div className="search-products-results-grid padding-md"
                   data-testid="search-product-result">
                    <span className="textalign-left">{result.ticker}</span>
                    <span className="textalign-left">{result.name}</span>
                    <span className="textalign-right">{result.cusip}</span>
                    <span className="textalign-left">{result.assetClass}</span>
                    <span className="textalign-left">{result.assetSubclass}</span>
                    <span className="textalign-left">{result.assetSubclassDetail}</span>
                </div>
        )
    }

    return (
        <>
            <div className="product-search">
                <TypeaheadSearchBar<Product>
                    placeholder={searchPlaceHoldingText}
                    performSearch={onPerformSearch}
                    resultHeader={
                        <div>
                            {displayResultHeader()}
                            <div className="line marginleft-md marginright-md"/>
                        </div>
                    }
                    displayResult={(product: Product) => displaySearchResult(product)}
                    onSelect={onProductSelect}
                    searchType={defaultAllOrApprovedProduct}
                />
                {enableApprovedProducts &&
                    <Dropdown
                        label={"SHOW"}
                        inlineLabel
                        data-testid="allOrApprovedProductsDropdown"
                        className="product-types"
                        size="large"
                        alignRight
                        buttonIcon="right"
                        drop="down"
                        panelWidth="inherit"
                        buttonAriaLabel="All or Approved Products"
                        panelHeight="auto"
                        onChange={onChangeAllOrApprovedProductDropdown}
                        value={defaultAllOrApprovedProduct}
                    >
                        <DropdownItem
                            itemText="All Products"
                            value="allProducts"
                            key="allProducts"
                            style={{width: 226}}
                        />
                        <DropdownItem
                            itemText="Approved Products"
                            value="approvedProducts"
                            key="approvedProducts"
                            style={{width: 226}}
                        />
                    </Dropdown>
                }
                <AddCustomProductsDropdownMenu
                    customProductClassifications={customProductClassifications}
                    scrollableRegionId="holdings-side-drawer"
                    handleAddCustomProduct={onAddCustomProduct}
                />
            </div>
        </>
    );
};

export default AddProduct;
