import {NonLifestyleGoalRow} from "../../../Goals/Prioritization/Prioritization";
import {GoalModelType} from "../../../Goals/models/GoalModelType";

export interface GoalsPrioritizationProps {
    displayName?: string,
    id?: string,
    needs?: NonLifestyleGoalRow[],
    wants?: NonLifestyleGoalRow[],
    fundedByNonInvestables?: NonLifestyleGoalRow[],
    goalModel?: GoalModelType,
}

export interface GoalsPrioritizationData {
    needs?: NonLifestyleGoalRow[],
    wants?: NonLifestyleGoalRow[],
    fundedByNonInvestables?: NonLifestyleGoalRow[],
    isNeedsFirstPage?: boolean,
    isWantsFirstPage?: boolean,
    isFundedByNonInvestablesFirstPage?: boolean,
}

const TOTAL_PAGE_HEIGHT = 1055;
const PAGE_PADDING_HEIGHT = 85;
const SECTION_HEADER_HEIGHT = 100;
const ACCORDION_HEADER_HEIGHT = 72;
const TABLE_HEADER_HEIGHT = 0;
const TABLE_ROW_HEIGHT = 55;
const PAGE_SPACING_BETWEEN_TWO_PAGES = 71;

const UNDERLINE_HEADER = 0;
const PAGE_DISCLAIMER_SECTION_TITLE = 114;
const CARD_HIGHT = 130
const CORE_LIFE_STYLE_DATA = 217
const EMPTY_DATA_SEGMENT = 146;


function updatePageData(dataType: string, pageData: any) {
    if (dataType === "needs") {
        pageData = {...pageData, wants: undefined, fundedByNonInvestables: undefined}
    }
    if (dataType === "wants" && pageData.needs) {
        pageData = {...pageData, fundedByNonInvestables: undefined}
    } else if (dataType === "wants" && (pageData.needs === undefined)) {
        pageData = {...pageData, needs: undefined, fundedByNonInvestables: undefined}
    }
    if (dataType === "fundedByNonInvestables" && (pageData.needs === undefined) && (pageData.wants === undefined)) {
        pageData = {...pageData, needs: undefined, wants: undefined}
    }
    else if (dataType === "fundedByNonInvestables" && pageData.needs && (pageData.wants === undefined)) {
        pageData = {...pageData, wants: undefined}
    }
    else if (dataType === "fundedByNonInvestables" && (pageData.needs === undefined) && pageData.wants) {
        pageData = {...pageData, needs: undefined}
    }
    return pageData;
}


const setPageData = async (dataType: string,
                           pageData: any,
                           pageScore: number,
                           data: any,
                           pages: any,
                           defaultData: any) => {

    let updatedPageScore = data.length === 0 ? pageScore + EMPTY_DATA_SEGMENT : pageScore + data.length * TABLE_ROW_HEIGHT;

    if (updatedPageScore < TOTAL_PAGE_HEIGHT) {
        pageData = updatePageData(dataType, pageData);
        pageData = {
            ...pageData,
            [dataType]: data
        }
    } else {
        const remainingPageSpace = TOTAL_PAGE_HEIGHT - pageScore;
        let noOfDataPageCanFit = 0;
        if (remainingPageSpace > 0 && data.length > 0) {
            noOfDataPageCanFit = Math.floor(remainingPageSpace / TABLE_ROW_HEIGHT);
            if (noOfDataPageCanFit > 0) {
                const dataThatCanFitInCurrentPage = data.slice(0, noOfDataPageCanFit);
                pageData = updatePageData(dataType, pageData);
                pageData = {
                    ...pageData,
                    [dataType]: dataThatCanFitInCurrentPage
                };
            }
        }

        // Push Current Page
        pages.push(pageData);

        // Creating New Page
        updatedPageScore = PAGE_PADDING_HEIGHT + SECTION_HEADER_HEIGHT + PAGE_SPACING_BETWEEN_TWO_PAGES + PAGE_DISCLAIMER_SECTION_TITLE;
        if (dataType === "needs") {
            pageData = noOfDataPageCanFit > 0 ? {...defaultData, isNeedsFirstPage: false} : {
                ...defaultData,
                isNeedsFirstPage: true
            };
        } else if (dataType === "wants") {
            pageData = noOfDataPageCanFit > 0 ? {...defaultData, isWantsFirstPage: false} : {
                ...defaultData,
                isWantsFirstPage: true
            };
        } else if (dataType === "fundedByNonInvestables") {
            pageData = noOfDataPageCanFit > 0 ? {
                ...defaultData,
                fundedByNonInvestables: [],
                isFundedByNonInvestablesFirstPage: false
            } : {...defaultData, fundedByNonInvestables: [], isFundedByNonInvestablesFirstPage: true};
        }


        // For rest of the data, split the remaining data recursively until all are fit into 1 or more pages
        const remainingGoals = data.slice(noOfDataPageCanFit);

        if (remainingGoals.length > 0) {
            const result = await setPageData(dataType, pageData, updatedPageScore, remainingGoals, pages, defaultData);
            updatedPageScore = result.pageScore;
            pageData = result.pageData;
        }


    }

    return {pageScore: updatedPageScore, pageData};
}

export const splitGoalsPrioritizationData = async (mainData: any, defaultData: any): Promise<Array<GoalsPrioritizationData>> => {

    const pages: Array<any> = [];
    let pageScore = PAGE_PADDING_HEIGHT + SECTION_HEADER_HEIGHT + PAGE_DISCLAIMER_SECTION_TITLE + CARD_HIGHT;
    let pageData: any = {...defaultData};

    if(mainData['needs'] && mainData['needs'].length > 0) {
        pageScore = pageScore + CORE_LIFE_STYLE_DATA;
        const result = await setPageData('needs', {...pageData,isNeedsFirstPage:true}, pageScore, mainData['needs'], pages, defaultData);
        pageScore = result.pageScore;
        pageData = result.pageData;
    }
    if (mainData['wants']) {
        pageScore = pageScore + ACCORDION_HEADER_HEIGHT + TABLE_HEADER_HEIGHT;
        const result = await setPageData('wants', {...pageData,isWantsFirstPage:true}, pageScore, mainData['wants'], pages, defaultData);
        pageScore = result.pageScore;
        pageData = result.pageData;
    }

    if (mainData['fundedByNonInvestables']) {
        pageScore = pageScore + ACCORDION_HEADER_HEIGHT + TABLE_HEADER_HEIGHT;
        const result = await setPageData('fundedByNonInvestables', {...pageData,isFundedByNonInvestablesFirstPage:true}, pageScore, mainData['fundedByNonInvestables'], pages, defaultData);
        pageData = result.pageData;
    }
    if (pageData !== defaultData) {
        pages.push(pageData);
    }
    return pages;

}