import {Icon} from "../../../components";
import React from "react";
import {initializeIcons, registerIcons} from "@fluentui/react";
import {DEFAULT_COMPONENT_ICONS} from "@azure/communication-react";

initializeIcons();
registerIcons({icons: DEFAULT_COMPONENT_ICONS});

export const customExpandIcon = (): JSX.Element => {
    return (
        <Icon
            key={'launch'}
            name={'launch'}
            color={'white'}
            style={{fontSize: '20px'}}
        />
    );
};

export const customMicOnIcon = (fontSize = '20px'): JSX.Element => {
    return (
        <Icon
            key={'micCustomIconOn'}
            name={'mic'}
            color={'white'}
            style={{fontSize}}
        />
    );
};

export const customMicOffIcon = (fontSize = '20px'): JSX.Element => {
    return (
        <Icon
            key={'micCustomIconOff'}
            name={'mic_off'}
            color={'white'}
            style={{fontSize}}
        />
    );
};

export const customCameraOnIcon = (fontSize = '20px'): JSX.Element => {
    return (
        <Icon
            key={'cameraCustomIconOn'}
            name={'videocam'}
            color={'white'}
            style={{fontSize}}
        />
    );
};

export const customCameraOffIcon = (fontSize = '20px'): JSX.Element => {
    return (
        <Icon
            key={'cameraCustomIconOff'}
            name={'videocam_off'}
            color={'white'}
            style={{fontSize}}
        />
    );
};

export const controlBarStyles = {
    root: {
        justifyContent: 'flex-end',
        backgroundColor: 'black',
        '& button': {
            color: 'white',
        }
    }
};

export const customParticipantIcon = (fontSize = '20px'): JSX.Element => {
    return (
        <Icon
            key={'people'}
            name={'people'}
            color={'white'}
            style={{fontSize}}
        />
    );
};