export interface AssetClassifications {
    riskAssetClasses: AssetClass[],
    riskControlAssetClasses: AssetClass[],
}

export interface AssetClass {
    assetClassName: string,
    assetSubclasses: AssetSubclass[],
}

export interface AssetSubclass {
    assetSubclassName: string
    defaultAssetSubclassDetailId: string | null
    assetSubclassDetails: AssetSubclassDetails[]
}

export interface AssetSubclassDetails {
    assetSubclassDetailsId: string,
    assetSubclassDetailsName: string,
    liquidityCategory: string | null
}

export const EQUITY_DERIVATIVE_ASSET_CLASS4_ID = '1d39b6be-b52b-4631-9949-14886666b555';
