import {ColumnCounter, TableCell, TableDisplay, TableRow} from "../../../components";
import {
    formatCurrencyFve,
    formatPercentWithWholeNumberAndTwoDecimalsFve
} from "../../../utils/format";
import {AssetTableDisplay} from "./AssetTableDisplay";
import {GoalTableDisplay} from "./GoalTableDisplay";
import {ReviewAssetTableDisplay} from "./ReviewAssetTableDisplay";
import {AssetRelianceStack} from "../../models/AssetRelianceResponse";
import {hasPositiveExcessAssets} from "../AssetRelianceUtil";
import {COLOR_ASSET_SHORTFALL_ACCENT, COLOR_EXCESS_ASSETS_ACCENT} from "../../../constants/colors";
import {InvestorGroupType} from "../../models/InvestorGroupType";
import {FveDiscountRateType} from "../AssetRelianceButtonState";

export class ExcessAssetsTableDisplay extends TableDisplay<number | string> {
    private readonly assetTableDisplay: AssetTableDisplay | ReviewAssetTableDisplay;
    private readonly goalTableDisplay: GoalTableDisplay;
    private readonly allAssetsStack: AssetRelianceStack;
    private readonly showExpectedExcessAssets: boolean;
    private readonly includeLifeInsuranceAtDeath: boolean;
    private readonly fveDiscountRateType: FveDiscountRateType;
    private readonly investorGroup?: InvestorGroupType;
    private readonly excludedExcessAssetStacks: AssetRelianceStack[];

    constructor({
                    columnCounter,
                    assetTableDisplay,
                    goalTableDisplay,
                    allAssetsStack,
                    showExpectedExcessAssets,
                    includeLifeInsuranceAtDeath,
                    fveDiscountRateType,
                    investorGroup,
                    excludedExcessAssetStacks,
                }: {
        columnCounter: ColumnCounter,
        assetTableDisplay: AssetTableDisplay | ReviewAssetTableDisplay,
        goalTableDisplay: GoalTableDisplay,
        allAssetsStack: AssetRelianceStack,
        showExpectedExcessAssets: boolean,
        includeLifeInsuranceAtDeath: boolean,
        fveDiscountRateType: FveDiscountRateType,
        investorGroup?: InvestorGroupType,
        excludedExcessAssetStacks: AssetRelianceStack[],
    }) {
        super(columnCounter,
            (value) => formatCurrencyFve(value),
            (secondaryValue) => formatPercentWithWholeNumberAndTwoDecimalsFve(secondaryValue)
        );

        this.assetTableDisplay = assetTableDisplay;
        this.goalTableDisplay = goalTableDisplay;
        this.allAssetsStack = allAssetsStack;
        this.excludedExcessAssetStacks = excludedExcessAssetStacks;
        this.showExpectedExcessAssets = showExpectedExcessAssets;
        this.includeLifeInsuranceAtDeath = includeLifeInsuranceAtDeath;
        this.fveDiscountRateType = fveDiscountRateType;
        this.investorGroup = investorGroup;
    }

    get rows(): TableRow<number | string>[] {
        const excessAssetsHeaderValues = Array(1).fill(0);
        return this.showExpectedExcessAssets ? excessAssetsHeaderValues.map((): TableRow<number | string> => ({
            accentColor: hasPositiveExcessAssets(this.allAssetsStack.excessAssets) ? COLOR_EXCESS_ASSETS_ACCENT : COLOR_ASSET_SHORTFALL_ACCENT,
            uniqueIdentifier: "",
            label: ExcessAssetsTableDisplay.createLabel(this.investorGroup!),
            labelClassName: 'paddingtop-md',
            secondaryLabel: ExcessAssetsTableDisplay.createSecondaryLabel(this.fveDiscountRateType),
            subtitle: ExcessAssetsTableDisplay.createSubtitle(this.investorGroup!),
            values: this.createFutureValueOfExcessAssetsTableCells(this.allAssetsStack, this.excludedExcessAssetStacks, this.fveDiscountRateType, this.includeLifeInsuranceAtDeath),
            children: []
        })) : [];
    }

    protected get headerLabel(): string {
        return hasPositiveExcessAssets(this.allAssetsStack.excessAssets) ? "Excess Assets" : "Asset Shortfall";
    }

    protected get headerValues(): TableCell<number| string>[] {
        const assetHeaderValues = this.assetTableDisplay.header.values;
        const goalHeaderValues = this.goalTableDisplay.header.values;
        const excessAssetsHeaderValues = Array(this.columnCount).fill(0);

        if (assetHeaderValues.length !== this.columnCount || goalHeaderValues.length !== this.columnCount) {
            console.error(`Mismatched column counts: Excess Assets = ${this.columnCount}, Assets = ${assetHeaderValues.length}, Goals = ${goalHeaderValues.length}`);
            return [];
        }

        for (let i = 0, l = this.columnCount; i < l; i++) {
            excessAssetsHeaderValues[i] = assetHeaderValues[i].originalValue - goalHeaderValues[i].originalValue;
        }
        return excessAssetsHeaderValues.map(value => {
            const tableCellClassName = value < 0 ? 'negative-difference' : undefined;
            return this.createTableCell({value, className: tableCellClassName});
        });
    }

    private static createLabel(investorGroup: InvestorGroupType): string {
        const {ageFrom, numberOfYears} = investorGroup.planningPeriod;
        return `Excess Assets at Age ${ageFrom + numberOfYears}`;
    }

    private static createSecondaryLabel(fveDiscountRateType: FveDiscountRateType): string {
        switch (fveDiscountRateType) {
            case FveDiscountRateType.TRIPLE_NET:
                return 'Triple Net Growth Rate'
            case FveDiscountRateType.NOMINAL_NET:
                return 'Nominal Net Growth Rate'
            default:
                return ''
        }
    }

    private static createSubtitle(investorGroup: InvestorGroupType): string {
        const {numberOfYears} = investorGroup.planningPeriod;
        return `${numberOfYears} year planning period`;
    }

    private static getFutureValueOfExcessAssets(fveDiscountRateType: FveDiscountRateType, allAssetStacks: AssetRelianceStack, includeLifeInsuranceAtDeath: boolean) {
        const lifeInsuranceStr = includeLifeInsuranceAtDeath ? "includeLifeInsurance" : "noLifeInsurance";
        const val = {
            'includeLifeInsurance': {
                [FveDiscountRateType.TRIPLE_NET]: allAssetStacks.fveWithLifeInsuranceDeathValueTNDR,
                [FveDiscountRateType.NOMINAL_NET]: allAssetStacks.fveWithLifeInsuranceDeathValueNNDR,
            },
            'noLifeInsurance': {
                [FveDiscountRateType.TRIPLE_NET]: allAssetStacks.futureValueOfExcessAssets,
                [FveDiscountRateType.NOMINAL_NET]: allAssetStacks.futureValueOfExcessAssetsWithNominalNetDiscountRate,
            }
        }

        return val[lifeInsuranceStr][fveDiscountRateType];
    }

    private static getDiscountRate(fveDiscountRateType: FveDiscountRateType, assetStacks: AssetRelianceStack) {
        return fveDiscountRateType === FveDiscountRateType.TRIPLE_NET ? assetStacks.displayedTripleNetDiscountRate : assetStacks.displayedNominalNetDiscountRate;
    }

    private createFutureValueOfExcessAssetsTableCells(allAssetStacks: AssetRelianceStack, otherAssetStacks: AssetRelianceStack[], fveDiscountRateType: FveDiscountRateType, includeLifeInsuranceAtDeath: boolean): TableCell<number | string>[] {
        const tableCellClassName = undefined;

        const allAssetsTableCell = this.createTableCell({
            value: ExcessAssetsTableDisplay.getFutureValueOfExcessAssets(fveDiscountRateType, allAssetStacks, includeLifeInsuranceAtDeath),
            secondaryValue: ExcessAssetsTableDisplay.getDiscountRate(fveDiscountRateType, allAssetStacks),
            className: tableCellClassName
        });

        const excessAssetTableCells: TableCell<number | string>[] = otherAssetStacks.map((stack) => {
            const fveValue = ExcessAssetsTableDisplay.getFutureValueOfExcessAssets(fveDiscountRateType, stack, includeLifeInsuranceAtDeath);
            const growthRateValue = ExcessAssetsTableDisplay.getDiscountRate(fveDiscountRateType, stack);
            return this.createTableCell({
                value: fveValue < 0  || stack.stackAssetType === "INVESTABLE_PORTFOLIO_ASSET" ? "N/A" : fveValue,
                secondaryValue: fveValue < 0 || stack.stackAssetType === "INVESTABLE_PORTFOLIO_ASSET" ? "" : growthRateValue,
                className: tableCellClassName
            });
        });
        excessAssetTableCells.unshift(allAssetsTableCell);
        return excessAssetTableCells;
    }

}
