import StorageService from "./StorageService";
import configApiClient from "./configApiClient";
import {SpaConfigResponse} from "./models/SpaConfig";

export enum SessionStorageKey {
  SpaConfig = 'spaConfig'
}

export interface SessionStorageModel {
  [SessionStorageKey.SpaConfig]: SpaConfigResponse 
}

export const sessionStorageService = new StorageService<SessionStorageModel>(
  window.sessionStorage
);

export const getSpaConfig = () => {
  const spaConfig = sessionStorageService.get(SessionStorageKey.SpaConfig)
  return spaConfig!;
}

export const fetchSpaConfigAndUpdateSessionStorage = async () => {
  const response = await configApiClient.getSpaConfig()
  sessionStorageService.set(SessionStorageKey.SpaConfig, response);
  return response;
}

export default {
  getSpaConfig,
  fetchSpaConfigAndUpdateSessionStorage
}