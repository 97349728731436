import {FamilyRelationship} from "./FamilyRelationship";
import {Gender, LifeStatus} from "./MemberType";

export type BasePrimaryContact = {
    firstName: string,
    middleInitial: string | null,
    lastName: string,
    birthdate: string | null,
    age: number,
    gender: Gender,
    stateOfResidency: string | null,
    personalPlanningHorizon: number,
}

export type PrimaryContact = BasePrimaryContact & {
    id?: string,
    stateAbbr?: string | null,
    lifeStatus: LifeStatus,
    family: FamilyRelationship[],
    otherMembers?: FamilyRelationship[]
}

export type PrimaryContactForm = BasePrimaryContact;

export type PrimaryContactValidation = null | {
    firstName: string | null,
    lastName: string | null,
    age: string | null,
    personalPlanningHorizon: string | null,
}

export const emptyPrimaryContactForm: PrimaryContactForm = {
    firstName: '',
    middleInitial: '',
    lastName: '',
    birthdate: '',
    age: 0,
    gender: Gender.Undisclosed,
    stateOfResidency: '',
    personalPlanningHorizon: 100
}

export const emptyPrimaryContact: PrimaryContact = {
    id: '',
    firstName: '',
    middleInitial: '',
    lastName: '',
    birthdate: '',
    age: 50,
    gender: Gender.Male,
    stateOfResidency: '',
    stateAbbr: '',
    personalPlanningHorizon: 100,
    lifeStatus: LifeStatus.Living,
    family: [],
}
