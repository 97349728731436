import React from "react";
import {EstateSummary, EstateType} from "../../../WealthTransfer/models/api";
import {formatCurrency} from "../../../utils/format";

interface WealthTransferReportHeaderProps {
    estateValue: EstateSummary['estateValue'],
    beneficiaryTotalValue: EstateSummary['beneficiaryTotalValue'],
    estimatedEstateTax: EstateSummary['estimatedEstateTax'],
    enableWealthTransferMenu: boolean,
    estateType: EstateType,
    atAge: number
}

const WealthTransferReportHeader = ({
                                        estateValue,
                                        beneficiaryTotalValue,
                                        estimatedEstateTax,
                                        estateType,
                                        atAge
                                    }: WealthTransferReportHeaderProps) => {

    const text = estateType === EstateType.CURRENT ? 'Your current estate value is ' : `Your estimated estate value at age ${atAge} is `;
    return (<>
        <h3>{text} <b>{formatCurrency(estateValue)}</b>.
            Under your current
            plan, <b>{formatCurrency(beneficiaryTotalValue)}</b> would be
            distributed to beneficiaries
            and <b>{formatCurrency(estimatedEstateTax)}</b> to estimated estate taxes.
        </h3>

    </>);
}

export default  WealthTransferReportHeader;