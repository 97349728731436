import React, {FC} from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";

export type LogoutModalProps = {
    isOpen: boolean,
    onClickCancel: () => void,
    onClickLogout: () => void
}

const LogoutModal: FC<LogoutModalProps> = (
    {
        isOpen = false,
        onClickCancel,
        onClickLogout
    }) => (
    <ModalWrapper
        id="discard-changes-modal"
        isOpen={isOpen}
        headerText="Log Out ?"
        buttons={[
            {
                text: `cancel`,
                onClick: onClickCancel,
            },
            {
                text: `log out`,
                onClick: onClickLogout,
                destructive: true,
                primary: true,
            }
        ]}
    >
        <div className="font-md">Do you want to log out of Goals Driven Wealth Management Application at this time?
        </div>
    </ModalWrapper>
);

export default LogoutModal;
