import {LocationURIs} from "./core/app_conf";

const TOONIMO_DEV_SNIPPET = "%2F*%20Powered%20by%20Toonimo%20*%2F%0A%20%20(function(i%2Cs%2Co%2Cmp%2Crv%2Citgr%2Cc1%2Cc2%2Cg%2Ca%2Cm%2Cf%2Ct1%2Ct2%2Crvcdn%2Cprt)%7Bprt%3Di.TMO_SelectedAjaxProtocol%20%3D%20(i.location.protocol.indexOf('http')%20%3D%3D%3D%200%20%3F%20'%2F%2F'%20%3A%20'https%3A%2F%2F')%3B%0A%20%20i.TMO_jsFetchTimeStart%3D%2Bnew%20Date()%3Bi.TMO_mp%3Dmp%2Brv%3Bm%3Ds.getElementsByTagName(o)%5B0%5D%3Brv%3Frvcdn%3D'%3F'%2Brv%3Arvcdn%3D''%3B%0A%20%20c1%3Dprt%2B'cdn.toonimo.com%2Fdcode%2Fprod%2Flatest_n.js'%2Brvcdn%3Bc2%3Dprt%2B'server.toonimo.com%2Ftoonimo_loader.php%3Ftype%3Dcode'%2Bmp%2Brv%3Bd1%3Dprt%2B'cdn.toonimo.com%2Fdsettings%2Fprod%2FTTK_5dbaec1eb726b%2Fdomain_10885_4412.js'%2Brvcdn%3Bd2%3Dprt%2B'server.toonimo.com%2Ftoonimo_loader.php%3Ftype%3Ddomain_settings%26toonimo_token%3DTTK_5dbaec1eb726b%26domain_id%3D10885%26env_id%3D4412'%2Bmp%2Brv%3Bt1%3D'toonimo_obf'%3Bt2%3D'toonimo_server'%3B%0A%20%20f%3Dfunction(ar%2C%20itgr)%7B%20a%3Ds.createElement(o)%3B%20itgr%20%26%26%20(a.setAttribute('integrity'%2C%20itgr)%20%7C%7C%20a.setAttribute('crossorigin'%2C%20'anonymous'))%3Bwindow.ToonimoSettings%20%26%26%20window.ToonimoSettings.nonce%20%3F%20a.setAttribute('nonce'%2Cwindow.ToonimoSettings.nonce)%20%3A'%20'%20%3Ba.async%3D1%3Ba.src%3Dar%3Bm.parentNode.insertBefore(a%2Cm)%7D%3B%0A%20%20((location.search.indexOf(t1)%3E-1%7C%7Cc2.indexOf(t1)%3E-1%7C%7Clocation.search.indexOf(t2)%3E-1%7C%7Cc2.indexOf(t2)%3E-1)%3Ff(c2)%3Af(c1%2C%20itgr))%3B%0A%20%20((location.search.indexOf(t2)%3E-1%7C%7Cd2.indexOf(t2)%3E-1)%3Ff(d2)%3Af(d1))%3B%0A%20%20%7D)(window%2Cdocument%2C'script'%2C%20'')%3B";
const TOONIMO_UAT_SNIPPET = "%2f*%20Powered%20by%20Toonimo%20*%2f%0A%20%20(function(i%2Cs%2Co%2Cmp%2Crv%2Citgr%2Cc1%2Cc2%2Cg%2Ca%2Cm%2Cf%2Ct1%2Ct2%2Crvcdn%2Cprt)%7Bprt%3Di.TMO_SelectedAjaxProtocol%20%3D%20(i.location.protocol.indexOf('http')%20%3D%3D%3D%200%20%3F%20'%2f%2f'%20%3A%20'https%3A%2f%2f')%3b%0A%20%20i.TMO_jsFetchTimeStart%3D%2Bnew%20Date()%3bi.TMO_mp%3Dmp%2Brv%3bm%3Ds.getElementsByTagName(o)%5B0%5D%3brv%3Frvcdn%3D'%3F'%2Brv%3Arvcdn%3D''%3b%0A%20%20c1%3Dprt%2B'cdn.toonimo.com%2fdcode%2fprod%2flatest_n.js'%2Brvcdn%3bc2%3Dprt%2B'server.toonimo.com%2ftoonimo_loader.php%3Ftype%3Dcode'%2Bmp%2Brv%3bd1%3Dprt%2B'cdn.toonimo.com%2fdsettings%2fprod%2fTTK_5dbaec1eb726b%2fdomain_10885_4428.js'%2Brvcdn%3bd2%3Dprt%2B'server.toonimo.com%2ftoonimo_loader.php%3Ftype%3Ddomain_settings%26toonimo_token%3DTTK_5dbaec1eb726b%26domain_id%3D10885%26env_id%3D4428'%2Bmp%2Brv%3bt1%3D'toonimo_obf'%3bt2%3D'toonimo_server'%3b%0A%20%20f%3Dfunction(ar%2C%20itgr)%7B%20a%3Ds.createElement(o)%3b%20itgr%20%26%26%20(a.setAttribute('integrity'%2C%20itgr)%20%7C%7C%20a.setAttribute('crossorigin'%2C%20'anonymous'))%3bwindow.ToonimoSettings%20%26%26%20window.ToonimoSettings.nonce%20%3F%20a.setAttribute('nonce'%2Cwindow.ToonimoSettings.nonce)%20%3A'%20'%20%3ba.async%3D1%3ba.src%3Dar%3bm.parentNode.insertBefore(a%2Cm)%7D%3b%0A%20%20((location.search.indexOf(t1)%3E-1%7C%7Cc2.indexOf(t1)%3E-1%7C%7Clocation.search.indexOf(t2)%3E-1%7C%7Cc2.indexOf(t2)%3E-1)%3Ff(c2)%3Af(c1%2C%20itgr))%3b%0A%20%20((location.search.indexOf(t2)%3E-1%7C%7Cd2.indexOf(t2)%3E-1)%3Ff(d2)%3Af(d1))%3b%0A%20%20%7D)(window%2Cdocument%2C'script'%2C%20'')%3b";
const TOONIMO_QA_SNIPPET = "%2f*%20Powered%20by%20Toonimo%20*%2f%0A%20%20(function(i%2Cs%2Co%2Cmp%2Crv%2Citgr%2Cc1%2Cc2%2Cg%2Ca%2Cm%2Cf%2Ct1%2Ct2%2Crvcdn%2Cprt)%7Bprt%3Di.TMO_SelectedAjaxProtocol%20%3D%20(i.location.protocol.indexOf('http')%20%3D%3D%3D%200%20%3F%20'%2f%2f'%20%3A%20'https%3A%2f%2f')%3b%0A%20%20i.TMO_jsFetchTimeStart%3D%2Bnew%20Date()%3bi.TMO_mp%3Dmp%2Brv%3bm%3Ds.getElementsByTagName(o)%5B0%5D%3brv%3Frvcdn%3D'%3F'%2Brv%3Arvcdn%3D''%3b%0A%20%20c1%3Dprt%2B'cdn.toonimo.com%2fdcode%2fprod%2flatest_n.js'%2Brvcdn%3bc2%3Dprt%2B'server.toonimo.com%2ftoonimo_loader.php%3Ftype%3Dcode'%2Bmp%2Brv%3bd1%3Dprt%2B'cdn.toonimo.com%2fdsettings%2fprod%2fTTK_5dbaec1eb726b%2fdomain_10885_4287.js'%2Brvcdn%3bd2%3Dprt%2B'server.toonimo.com%2ftoonimo_loader.php%3Ftype%3Ddomain_settings%26toonimo_token%3DTTK_5dbaec1eb726b%26domain_id%3D10885%26env_id%3D4287'%2Bmp%2Brv%3bt1%3D'toonimo_obf'%3bt2%3D'toonimo_server'%3b%0A%20%20f%3Dfunction(ar%2C%20itgr)%7B%20a%3Ds.createElement(o)%3b%20itgr%20%26%26%20(a.setAttribute('integrity'%2C%20itgr)%20%7C%7C%20a.setAttribute('crossorigin'%2C%20'anonymous'))%3bwindow.ToonimoSettings%20%26%26%20window.ToonimoSettings.nonce%20%3F%20a.setAttribute('nonce'%2Cwindow.ToonimoSettings.nonce)%20%3A'%20'%20%3ba.async%3D1%3ba.src%3Dar%3bm.parentNode.insertBefore(a%2Cm)%7D%3b%0A%20%20((location.search.indexOf(t1)%3E-1%7C%7Cc2.indexOf(t1)%3E-1%7C%7Clocation.search.indexOf(t2)%3E-1%7C%7Cc2.indexOf(t2)%3E-1)%3Ff(c2)%3Af(c1%2C%20itgr))%3b%0A%20%20((location.search.indexOf(t2)%3E-1%7C%7Cd2.indexOf(t2)%3E-1)%3Ff(d2)%3Af(d1))%3b%0A%20%20%7D)(window%2Cdocument%2C'script'%2C%20'')%3b";
const TOONIMO_PROD_SNIPPET = "%2F*%20Powered%20by%20Toonimo%20*%2F%0A%20%20(function(i%2Cs%2Co%2Cmp%2Crv%2Citgr%2Cc1%2Cc2%2Cg%2Ca%2Cm%2Cf%2Ct1%2Ct2%2Crvcdn%2Cprt)%7Bprt%3Di.TMO_SelectedAjaxProtocol%20%3D%20(i.location.protocol.indexOf('http')%20%3D%3D%3D%200%20%3F%20'%2F%2F'%20%3A%20'https%3A%2F%2F')%3B%0A%20%20i.TMO_jsFetchTimeStart%3D%2Bnew%20Date()%3Bi.TMO_mp%3Dmp%2Brv%3Bm%3Ds.getElementsByTagName(o)%5B0%5D%3Brv%3Frvcdn%3D'%3F'%2Brv%3Arvcdn%3D''%3B%0A%20%20c1%3Dprt%2B'cdn.toonimo.com%2Fdcode%2Fprod%2Flatest_n.js'%2Brvcdn%3Bc2%3Dprt%2B'server.toonimo.com%2Ftoonimo_loader.php%3Ftype%3Dcode'%2Bmp%2Brv%3Bd1%3Dprt%2B'cdn.toonimo.com%2Fdsettings%2Fprod%2FTTK_5dbaec1eb726b%2Fdomain_10885_4287.js'%2Brvcdn%3Bd2%3Dprt%2B'server.toonimo.com%2Ftoonimo_loader.php%3Ftype%3Ddomain_settings%26toonimo_token%3DTTK_5dbaec1eb726b%26domain_id%3D10885%26env_id%3D4287'%2Bmp%2Brv%3Bt1%3D'toonimo_obf'%3Bt2%3D'toonimo_server'%3B%0A%20%20f%3Dfunction(ar%2C%20itgr)%7B%20a%3Ds.createElement(o)%3B%20itgr%20%26%26%20(a.setAttribute('integrity'%2C%20itgr)%20%7C%7C%20a.setAttribute('crossorigin'%2C%20'anonymous'))%3Bwindow.ToonimoSettings%20%26%26%20window.ToonimoSettings.nonce%20%3F%20a.setAttribute('nonce'%2Cwindow.ToonimoSettings.nonce)%20%3A'%20'%20%3Ba.async%3D1%3Ba.src%3Dar%3Bm.parentNode.insertBefore(a%2Cm)%7D%3B%0A%20%20((location.search.indexOf(t1)%3E-1%7C%7Cc2.indexOf(t1)%3E-1%7C%7Clocation.search.indexOf(t2)%3E-1%7C%7Cc2.indexOf(t2)%3E-1)%3Ff(c2)%3Af(c1%2C%20itgr))%3B%0A%20%20((location.search.indexOf(t2)%3E-1%7C%7Cd2.indexOf(t2)%3E-1)%3Ff(d2)%3Af(d1))%3B%0A%20%20%7D)(window%2Cdocument%2C'script'%2C%20'')%3B";

export function loadToonimoTrainingContent() {
    let activeToonimoSnipppet;
    switch (window.location.host) {
        case LocationURIs.AZURE_PROD, LocationURIs.AZURE_PRODAZ, LocationURIs.AZURE_PROD_EU2, LocationURIs.AZURE_PRODAZ_EU2:
            activeToonimoSnipppet = TOONIMO_PROD_SNIPPET;
            break;
        case LocationURIs.AZURE_QA1:
            activeToonimoSnipppet = TOONIMO_DEV_SNIPPET;
            break;
        case LocationURIs.AZURE_DEV, LocationURIs.AZURE_DEVAZ:
            activeToonimoSnipppet = TOONIMO_DEV_SNIPPET;
            break;
        case LocationURIs.AZURE_QA, LocationURIs.AZURE_QAAZ, LocationURIs.AZURE_QAAZ_EU2:
            activeToonimoSnipppet = TOONIMO_QA_SNIPPET;
            break;
        case LocationURIs.AZURE_UAT, LocationURIs.AZURE_UATAZ:
            activeToonimoSnipppet = TOONIMO_UAT_SNIPPET;
            break;
        default:
            activeToonimoSnipppet = TOONIMO_DEV_SNIPPET;
            break;
    }

    (function (d, w, st, dec, lst) {
        w.ToonimoScriptTagMarker = 'DO_NOT_DELETE';
        let s = d.createElement(st);
        s.async = true;
        s.innerHTML = dec(activeToonimoSnipppet);
        lst.parentNode.insertBefore(s, lst);
    })(document, window, 'script', decodeURIComponent, document.scripts[document.scripts.length - 1]);
}