import {FamilyRelationship} from "../models/FamilyRelationship";
import {FamilyTreeType, FamilyTreeViewportBounds, TemplateCategory} from "../models/FamilyTreeType";
import {Gender, LifeStatus, MemberType} from "../models/MemberType";
import {FamilyRelationshipType} from "../models/FamilyRelationshipType";
import {PrimaryContact} from "../models/PrimaryContact";

export const Blane: MemberType = {
    "id": "20a492e6-06bb-4cb8-9d87-ac49388afe26",
    "firstName": "Blane",
    "lastName": "Nelson",
    "middleInitial": "X",
    "gender": Gender.Male,
    "stateOfResidency": "Alabama",
    "stateAbbr": "AL",
    "personalPlanningHorizon": 99,
    "birthdate": "2019-11-23T10:00:00.000+00:00",
    "family": [],
    "otherMembers": [],
    "lifeStatus": LifeStatus.Living,
    "age": 2,
    "deceasedDate": null,
}

export const Chris: MemberType = {
    "id": "20a492e6-06bb-4cb8-9d87-ac49388trt23",
    "firstName": "Chris",
    "lastName": "Nelson",
    "middleInitial": "X",
    "gender": Gender.Male,
    "stateOfResidency": "Alabama",
    "stateAbbr": "AL",
    "personalPlanningHorizon": 99,
    "birthdate": "2019-11-23T10:00:00.000+00:00",
    "family": [],
    "otherMembers": [],
    "lifeStatus": LifeStatus.Living,
    "age": 32,
    "deceasedDate": null,
}

export const Allister: MemberType = {
    "id": "20a492e6-06bb-4cb8-9d87-ac49389fsd34",
    "firstName": "Allister",
    "lastName": "Nelson",
    "middleInitial": "X",
    "gender": Gender.Female,
    "stateOfResidency": "Alabama",
    "stateAbbr": "AL",
    "personalPlanningHorizon": 99,
    "birthdate": "2019-11-23T10:00:00.000+00:00",
    "family": [],
    "otherMembers": [],
    "lifeStatus": LifeStatus.Living,
    "age": 30,
    "deceasedDate": null,
}

export const Dale: MemberType = {
    "id": "4c388b8e-d17f-4896-94f1-e59688313dd0",
    "firstName": "Dale",
    "lastName": "Nelson",
    "middleInitial": "X",
    "gender": Gender.Male,
    "stateOfResidency": "Alabama",
    "stateAbbr": "AL",
    "personalPlanningHorizon": 99,
    "birthdate": "2017-11-23T10:00:00.000+00:00",
    "family": [],
    "otherMembers": [],
    "lifeStatus": LifeStatus.Living,
    "age": 4,
    "deceasedDate": null,
}


export const Birgit: MemberType = {
    "id": "66668b8e-d17f-4896-94f1-e59688313dd0",
    "firstName": "Birgit",
    "lastName": "Nelson",
    "middleInitial": "X",
    "gender": Gender.Female,
    "stateOfResidency": "Alabama",
    "stateAbbr": "AL",
    "personalPlanningHorizon": 99,
    "birthdate": "1989-11-23T10:00:00.000+00:00",
    "family": [],
    "otherMembers": [],
    "lifeStatus": LifeStatus.Living,
    "age": 32,
    "deceasedDate": null,
}

export const Sloan: MemberType = {
    "id": "bf7e748d-32c5-41d2-8295-a35432071828",
    "firstName": "Sloan",
    "lastName": "Nelson",
    "middleInitial": "X",
    "gender": Gender.Female,
    "stateOfResidency": "Alabama",
    "stateAbbr": "AL",
    "personalPlanningHorizon": 99,
    "birthdate": "1990-11-23T10:00:00.000+00:00",
    "family": [],
    "otherMembers": [],
    "lifeStatus": LifeStatus.Living,
    "age": 32,
    "deceasedDate": null,
}

export const ExtendedFamilyParent: MemberType = {
    id: "extended-family-parent",
    firstName: "Primary",
    lastName: "Parent",
    middleInitial: "C",
    gender: Gender.Female,
    stateOfResidency: "Alabama",
    stateAbbr: "AL",
    personalPlanningHorizon: 99,
    birthdate: "1970-11-23T10:00:00.000+00:00",
    family: [],
    otherMembers: [],
    lifeStatus: LifeStatus.Living,
    age: 52,
    deceasedDate: null,
}

export const Shemp: MemberType = {
    "id": "20a492e6-06bb-4cb8-9d87-ac49388afe29",
    "firstName": "Shemp",
    "lastName": "Nelson",
    "middleInitial": "X",
    "gender": Gender.Male,
    "stateOfResidency": "Alabama",
    "stateAbbr": "AL",
    "personalPlanningHorizon": 99,
    "birthdate": "2010-09-20T10:00:00.000+00:00",
    "family": [],
    "otherMembers": [],
    "lifeStatus": LifeStatus.Living,
    "age": 13,
    "deceasedDate": null,
}

export const Larry: MemberType = {
    "id": "20a492e6-06bb-4cb8-9d87-ac49388afe27",
    "firstName": "Larry",
    "lastName": "Nelson",
    "middleInitial": "X",
    "gender": Gender.Male,
    "stateOfResidency": "Alabama",
    "stateAbbr": "AL",
    "personalPlanningHorizon": 99,
    "birthdate": "2010-09-21T10:00:00.000+00:00",
    "family": [],
    "otherMembers": [],
    "lifeStatus": LifeStatus.Living,
    "age": 13,
    "deceasedDate": null,
}

export const Moe: MemberType = {
    "id": "20a492e6-06bb-4cb8-9d87-ac49388afe28",
    "firstName": "Moe",
    "lastName": "Nelson",
    "middleInitial": "X",
    "gender": Gender.Male,
    "stateOfResidency": "Alabama",
    "stateAbbr": "AL",
    "personalPlanningHorizon": 99,
    "birthdate": "2010-09-22T10:00:00.000+00:00",
    "family": [],
    "otherMembers": [],
    "lifeStatus": LifeStatus.Living,
    "age": 13,
    "deceasedDate": null,
}

export const Joe: MemberType = {
    "id": "20a492e6-06bb-4cb8-9d87-ac49388afe38",
    "firstName": "Joe",
    "lastName": "Nelson",
    "middleInitial": "X",
    "gender": Gender.Male,
    "stateOfResidency": "Alabama",
    "stateAbbr": "AL",
    "personalPlanningHorizon": 99,
    "birthdate": null,
    "family": [],
    "otherMembers": [],
    "lifeStatus": LifeStatus.Living,
    "age": 13,
    "deceasedDate": null,
}

export const ExtendedFamilyParentWithSpouse: MemberType = {
    ...ExtendedFamilyParent,
    family: [{
        "id": "7178f861-3448-4a74-b639-6a653274f302",
        "fromMember": Allister,
        "type": FamilyRelationshipType.SPOUSE
    }],
}

export const ChrisWithSpouse: MemberType = {
    ...Chris,
    family: [{
        "id": "7178f861-3448-4a74-b639-6a653274f302",
        "fromMember": Allister,
        "type": FamilyRelationshipType.SPOUSE
    }]
}

export const SloanWithChild: MemberType = {
    ...Sloan,
    family: [{
        "id": "7178f861-3448-4a74-b639-6a653274f302",
        "fromMember": Blane,
        "type": FamilyRelationshipType.CHILD
    }]
}

export const SloanWithPartner: MemberType = {
    ...Sloan,
    family: [{
        "id": "8878f861-3448-4a74-b639-6a653274f302",
        "fromMember": Birgit,
        "type": FamilyRelationshipType.SPOUSE
    }]
}

export const SloanWithMultipleChildren: MemberType = {
    ...Sloan,
    family: [
        {
            "id": "7178f861-3448-4a74-b639-6a653274f302",
            "fromMember": Blane,
            "type": FamilyRelationshipType.CHILD
        },
        {
            "id": "d82d9370-a7a9-4b58-81ae-2a95fdd39c29",
            "fromMember": Dale,
            "type": FamilyRelationshipType.CHILD
        }
    ]
}

export const SloanWithMultipleChildrenWithSameBirthdate: MemberType = {
    ...Sloan,
    family: [
        {
            "id": "20a492e6-06bb-4cb8-9d87-ac49388afe26",
            "fromMember": Shemp,
            "type": FamilyRelationshipType.CHILD
        },
        {
            "id": "20a492e6-06bb-4cb8-9d87-ac49388afe27",
            "fromMember": Larry,
            "type": FamilyRelationshipType.CHILD
        },
        {
            "id": "20a492e6-06bb-4cb8-9d87-ac49388afe28",
            "fromMember": Moe,
            "type": FamilyRelationshipType.CHILD
        },
        {
            "id": "20a492e6-06bb-4cb8-9d87-ac49388afe38",
            "fromMember": Joe,
            "type": FamilyRelationshipType.CHILD
        }
    ]
}

export const SloanWithMultipleChildrenAndSpouse: MemberType = {
    ...Sloan,
    family: [
        {
            "id": "7178f861-3448-4a74-b639-6a653274f302",
            "fromMember": Birgit,
            "type": FamilyRelationshipType.SPOUSE
        },
        {
            "id": "7178f861-3448-4a74-b639-6a653274f302",
            "fromMember": ChrisWithSpouse,
            "type": FamilyRelationshipType.CHILD
        },
        {
            "id": "d82d9370-a7a9-4b58-81ae-2a95fdd39c29",
            "fromMember": Dale,
            "type": FamilyRelationshipType.CHILD
        }
    ]
}

export const Jack: MemberType = {
    "id": "ba2d6b25-172a-4915-abe4-647e5004ca1a",
    "firstName": "Jack",
    "lastName": "Nelson",
    "middleInitial": "X",
    "gender": Gender.Male,
    "stateOfResidency": "Alabama",
    "stateAbbr": "AL",
    "personalPlanningHorizon": 99,
    "birthdate": "1993-11-23T10:00:00.000+00:00",
    "family": [],
    "otherMembers": [],
    "lifeStatus": LifeStatus.Living,
    "age": 29,
    "deceasedDate": null,
}

export const Dave: MemberType = {
    "id": "bf7e748d-32c5-41d2-8295-a35432071826",
    "firstName": "Dave",
    "lastName": "Nelson",
    "middleInitial": "B",
    "gender": Gender.Male,
    "stateOfResidency": "Alabama",
    "stateAbbr": "AL",
    "personalPlanningHorizon": 90,
    "birthdate": "1990-11-23T10:00:00.000+00:00",
    "family": [],
    otherMembers: [],
    "lifeStatus": LifeStatus.Living,
    "age": 30,
    "deceasedDate": null,
}

export const Shauna: MemberType = {
    lifeStatus: LifeStatus.Living,
    id: "71c93bd7-6c43-4dc2-bf96-07c37c4fef06",
    firstName: "Shauna",
    lastName: "Nelson",
    middleInitial: "A",
    stateOfResidency: "Alaska",
    stateAbbr: "AK",
    personalPlanningHorizon: 100,
    birthdate: "1981-07-14T00:00:00.000+00:00",
    family: [
        {
            id: "954b3fd2-3f97-4505-b967-dbd3763d0247",
            fromMember: Sloan,
            type: FamilyRelationshipType.CHILD
        },
        {
            id: "954b3fd2-3f97-4505-b967-dbd3763d0247",
            fromMember: {...Dave, primaryParent: true},
            type: FamilyRelationshipType.PARENT
        }
    ],
    otherMembers: [
        {
            id: "af7e748d-32c5-41d2-8295-a35432071828",
            fromMember: {...Sloan, firstName: "Samantha", lastName: "Smith", id: "samantha-id"},
            type: FamilyRelationshipType.CHILD
        },
        {
            id: "bf7e748d-32c5-41d2-8295-a35432071828",
            fromMember: {...Sloan, firstName: "Park", lastName: "Young", id: "park-id"},
            type: FamilyRelationshipType.CHILD
        },
        {
            id: "cf7e748d-32c5-41d2-8295-a35432071828",
            fromMember: {...Sloan, firstName: "Seok", lastName: "Young", id: "seok-id"},
            type: FamilyRelationshipType.CHILD
        }
    ],
    age: 55,
    gender: Gender.Female,
    deceasedDate: null,
}

export const ShaunaWithGrandchild: MemberType = {
    ...Shauna,
    family: [{
        "id": "954b3fd2-3f97-4505-b967-dbd3763d0247",
        "fromMember": SloanWithChild,
        "type": FamilyRelationshipType.CHILD
    }],
}

export const ShaunaWithMultipleGrandchildren: MemberType = {
    ...Shauna,
    family: [{
        "id": "954b3fd2-3f97-4505-b967-dbd3763d0247",
        "fromMember": SloanWithMultipleChildren,
        "type": FamilyRelationshipType.CHILD
    }],
}

export const ShaunaWithPartnerAndMultipleGrandchildren: MemberType = {
    ...Shauna,
    family: [
        {
            "id": "954b3fd2-3f97-4505-b967-dbd3763d0247",
            "fromMember": SloanWithMultipleChildren,
            "type": FamilyRelationshipType.CHILD
        },
        {
            "id": "954b3fd2-3f97-4505-b967-dbd3763d0250",
            "fromMember": Dave,
            "type": FamilyRelationshipType.SPOUSE
        }
    ],
}

export const ShaunaWithPartnerAndMultipleGrandchildrenWithSpouse: MemberType = {
    ...Shauna,
    family: [
        {
            "id": "954b3fd2-3f97-4505-b967-dbd3763d0247",
            "fromMember": SloanWithMultipleChildrenAndSpouse,
            "type": FamilyRelationshipType.CHILD
        },
        {
            "id": "954b3fd2-3f97-4505-b967-dbd3763d0250",
            "fromMember": Dave,
            "type": FamilyRelationshipType.SPOUSE
        }
    ],
}

export const ShaunaWithPartnerSignificantOtherAndSpouse: MemberType = {
    ...Shauna,
    family: [
        {
            "id": "954b3fd2-3f97-4505-b967-dbd3763d0247",
            "fromMember": Jack,
            "type": FamilyRelationshipType.DOMESTIC_PARTNER
        },
        {
            "id": "954b3fd2-3f97-4505-b967-dbd3763d0248",
            "fromMember": Sloan,
            "type": FamilyRelationshipType.SIGNIFICANT_OTHER
        },
        {
            "id": "954b3fd2-3f97-4505-b967-dbd3763d0250",
            "fromMember": Dave,
            "type": FamilyRelationshipType.SPOUSE
        }
    ],
}
export const ShaunaWithPartnerSignificantOther: MemberType = {
    ...Shauna,
    family: [
        {
            "id": "954b3fd2-3f97-4505-b967-dbd3763d0250",
            "fromMember": Dave,
            "type": FamilyRelationshipType.SIGNIFICANT_OTHER
        },
    ],
}

export const ShaunaWithSpouse: MemberType = {
    ...Shauna,
    family: [
        {
            "id": "954b3fd2-3f97-4505-b967-dbd3763d0250",
            "fromMember": Dave,
            "type": FamilyRelationshipType.SPOUSE
        },
    ],
}

export const ShaunaWithExSpouse: MemberType = {
    ...Shauna,
    family: [
        {
            "id": "954b3fd2-3f97-4505-b967-dbd3763d0247",
            "fromMember": Jack,
            "type": FamilyRelationshipType.EX_SPOUSE
        },
    ],
}

export const ShaunaWithDeceasedChild: MemberType = {
    ...Shauna,
    family: [
        {
            "id": "954b3fd2-3f97-4505-b967-dbd3763d0247",
            "fromMember": {...Blane, lifeStatus: LifeStatus.Deceased},
            "type": FamilyRelationshipType.CHILD
        },


    ],
}

export const SloanWithPartnerAndChild: MemberType = {
    ...Sloan,
    family: [
        {
            "id": "7178f861-3448-4a74-b639-6a653274f302",
            "fromMember": Blane,
            "type": FamilyRelationshipType.CHILD
        },
        {
            "id": "d82d9370-a7a9-4b58-81ae-2a95fdd39c29",
            "fromMember": Dale,
            "type": FamilyRelationshipType.CHILD
        },
        {
            "id": "8878f861-3448-4a74-b639-6a653274f302",
            "fromMember": {
                ...Birgit, family: [
                    {
                        "id": "7178f861-3448-4a74-b639-6a653274f302",
                        "fromMember": Blane,
                        "type": FamilyRelationshipType.CHILD
                    },
                    {
                        "id": "d82d9370-a7a9-4b58-81ae-2a95fdd39c29",
                        "fromMember": Dale,
                        "type": FamilyRelationshipType.CHILD
                    }
                ]
            },
            "type": FamilyRelationshipType.SPOUSE
        }
    ]
}

export const Norman: MemberType = {
    lifeStatus: LifeStatus.Living,
    id: "d9563769-8c87-4c05-bdaf-c7e8b6dc0347",
    firstName: "Norman",
    lastName: "Waite",
    middleInitial: "X",
    stateOfResidency: "Illinois",
    stateAbbr: "IL",
    personalPlanningHorizon: 100,
    birthdate: "1981-07-14T00:00:00.000+00:00",
    family: [],
    otherMembers: [],
    age: 55,
    gender: Gender.Male,
    deceasedDate: null
}

export const testReferenceData = {
    managementFees: {
        managementFeePercentMaximum: "100",
        managementFeePercentMinimum: "100",
        managementFeePercentDefault: "100"
    },
    statesOfResidency: ['Pennsylvania', 'Illinois', 'Kansas', 'Alabama'],
    defaultPersonalPlanningHorizon: 100,
    taxRates: {
        federalTaxRateDefault: '',
        stateTaxRateDefault: '',
        capitalGainsPercentDefault: '',
        federalTaxRateMinimum: '',
        federalTaxRateMaximum: '',
        stateTaxRateMinimum: '',
        stateTaxRateMaximum: '',
        capitalGainsPercentMinimum: '',
        capitalGainsPercentMaximum: ''
    },
    accreditedStatus: {
        defaultValue: "Accredited",
        items: ["Accredited", "Standard"]
    },
    allocationType: {
        defaultValue: "Strategic",
        items: ["Strategic", "Tactical"]
    }
}

export const testFamily: FamilyTreeType = {
    profileId: "e261446a-cad0-4702-9cd6-a62a348b9493",
    displayName: "Shauna and Dave's Profile",
    primaryContact: Shauna
}

export const testFamilyNoOtherMembers: FamilyTreeType = {
    profileId: "e261446a-cad0-4702-9cd6-a62a348b9493",
    displayName: "Dave's Profile",
    primaryContact: Dave
}

export const testFamilyWithSignificantOther: FamilyTreeType = {
    profileId: "e261446a-cad0-4702-9cd6-a62a348b9493",
    displayName: "Shauna and Dave's Profile",
    primaryContact: {
        ...ShaunaWithPartnerSignificantOther
    }
}

export const testFamilyWithSpouse: FamilyTreeType = {
    profileId: "e261446a-cad0-4702-9cd6-a62a348b9493",
    displayName: "Shauna and Dave's Profile",
    primaryContact: {
        ...ShaunaWithSpouse
    }
}

export const familyWithSingleParentNotPrimaryContact: FamilyTreeType = {
    profileId: "e261446a-cad0-4702-9cd6-a62a348b9493",
    displayName: "Shauna and Dave's profile",
    primaryContact: {
        ...Shauna,
        family: SloanWithMultipleChildren.family
    }
}

export const familyWithDualParents: FamilyTreeType = {
    profileId: "e261446a-cad0-4702-9cd6-a62a348b9493",
    displayName: "Shauna and Dave's Profile",
    primaryContact: {
        ...Shauna,
        family: [
            {
                id: 'bf7e748d-32c5-41d2-8295-a35432071845',
                fromMember: {
                    ...Dave,
                    family: [
                        {
                            id: "bf7e748d-32c5-41d2-8295-a35432071828",
                            fromMember: Sloan,
                            type: FamilyRelationshipType.CHILD
                        },
                        {
                            id: "ba2d6b25-172a-4915-abe4-647e5004ca1a",
                            fromMember: Jack,
                            type: FamilyRelationshipType.CHILD
                        }]
                },
                type: FamilyRelationshipType.SPOUSE
            },
            {
                id: "bf7e748d-32c5-41d2-8295-a35432071828",
                fromMember: Sloan,
                type: FamilyRelationshipType.CHILD
            },
            {
                id: "ba2d6b25-172a-4915-abe4-647e5004ca1a",
                fromMember: Jack,
                type: FamilyRelationshipType.CHILD
            }]
    }
}

export const familyWithSpouseAsSingleParent: FamilyTreeType = {
    ...familyWithDualParents,
    primaryContact: {
        ...familyWithDualParents.primaryContact,
        family: [
            {
                id: 'bf7e748d-32c5-41d2-8295-a35432071845',
                fromMember: {
                    ...Dave,
                    family: [
                        {
                            id: "bf7e748d-32c5-41d2-8295-a35432071828",
                            fromMember: Sloan,
                            type: FamilyRelationshipType.CHILD
                        },
                        {
                            id: "ba2d6b25-172a-4915-abe4-647e5004ca1a",
                            fromMember: Jack,
                            type: FamilyRelationshipType.CHILD
                        },
                        {
                            id: "ba2d6b25-172a-4915-abe4-647e5004ca1b",
                            fromMember: Birgit,
                            type: FamilyRelationshipType.CHILD
                        }]
                },
                type: FamilyRelationshipType.SPOUSE
            },
            {
                id: "bf7e748d-32c5-41d2-8295-a35432071828",
                fromMember: Sloan,
                type: FamilyRelationshipType.CHILD
            }
        ]
    }
}

export function generateFamilyTreeViewportBounds(overrides?: Partial<FamilyTreeViewportBounds>) {
    return {
        scale: 2.6139132535407591,
        position: {
            x: -776.9869051689577,
            y: -548.8977990699799
        },
        ...overrides
    };
}

export const primaryWithFamily: (family: FamilyRelationship[], familyTree?: FamilyTreeType) => FamilyTreeType =
    (family, familyTree: FamilyTreeType = testFamily) => {
        return {
            ...familyTree,
            primaryContact: {
                ...familyTree.primaryContact,
                family: family
            }
        };
    };

export const primaryWithSpouseAndChild = (overrides?: Partial<PrimaryContact>): FamilyTreeType => {
    return {
        ...testFamily,
        primaryContact: {
            ...ShaunaWithPartnerAndMultipleGrandchildren,
            ...overrides
        }
    };
};

export const clickAddOtherMembers = (diagram: go.Diagram) => {
    const addOtherMembersButton = diagram.findNodeForKey(TemplateCategory.ADD_OTHER_MEMBERS_BUTTON);
    addOtherMembersButton?.data.onClick();
}

export const isAddOtherMembersVisible = (diagram: go.Diagram) => {
    const addOtherMembersButton = diagram.findNodeForKey(TemplateCategory.ADD_OTHER_MEMBERS_BUTTON);
    return !!addOtherMembersButton;
}