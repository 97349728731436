export const clamp = (value: number, min: number, max: number) => {
    if (value < min) return min;
    if (value > max) return max;
    return value;
}

export const clampStrings = (value: string, min: string, max: string, precision: number = 2) => {
    const numValue = parseFloat(value);
    const numMin = parseFloat(min);
    const numMax = parseFloat(max);
    const clamped = clamp(numValue, numMin,  numMax);
    return clamped.toFixed(precision)
}

type ClampOptions = {
    defaultValue: string,
    precision: number,
}

export const clampStringsWithOptions = (value: string, min: string, max: string, optionOverrides: Partial<ClampOptions> = {}) => {
    const options: ClampOptions = {
        defaultValue: min,
        precision: 2,
        ...optionOverrides,
    }
    let valueToClamp = value;
    if (isNaN(parseFloat(value))) {
        valueToClamp = options.defaultValue;
    }
    return clampStrings(valueToClamp, min, max, options.precision);
}

