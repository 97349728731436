import {useEffect, useState} from "react";
import {meetingApiClient} from "./MeetingApiClient";
import {useAppDispatch} from "../../store/hooks";
import {HistoricalMeeting, Meeting, MeetingStatus} from "./Meeting";
import {initialMeetingState, setActiveMeeting} from "./meetingSlice";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import moment from "moment";

const useMeetingHistory = () => {
    const [meetings, updateMeetings] = useState<Meeting[]>([]);
    const [historicalMeetings, setHistoricalMeetings] = useState<HistoricalMeeting[]>([]);
    const [expiredMeetings, setExpiredMeetings] = useState<HistoricalMeeting[]>([])
    const [isHistoryLoading, setIsHistoryLoading] = useState<boolean>(true);
    const {id: profileId} = useParams<RouteWithId>();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (profileId) {
            meetingApiClient.getMeetingsHistory(profileId)
                .then(allMeetingsForProfile => {
                    setHistoricalMeetings(allMeetingsForProfile.filter((meeting) => meeting.status === MeetingStatus.ENDED))
                    setExpiredMeetings(allMeetingsForProfile.filter(meeting =>
                        meeting.status === MeetingStatus.SCHEDULED &&
                        moment(meeting.startDate).add(14, 'days').isSameOrBefore(moment())
                    ));
                    updateMeetings(allMeetingsForProfile);
                })
                .catch((error) => {
                    console.error('Could not fetch meeting history', error.message);
                    updateMeetings([]);
                })
                .finally(() => {
                    setIsHistoryLoading(false);
                });
        } else {
            updateMeetings([]);
            setIsHistoryLoading(false);
        }
    }, [profileId]);

    useEffect(() => {
        const activeMeeting = meetings.find((meeting) => {
            return meeting.status !== MeetingStatus.ENDED && meeting.status !== MeetingStatus.SCHEDULED;
        }) || {...initialMeetingState.activeMeeting};
        dispatch(setActiveMeeting(activeMeeting as Meeting));
    }, [meetings])

    return {
        meetings: historicalMeetings,
        isHistoryLoading,
        expiredMeetings
    };
}

export default useMeetingHistory;