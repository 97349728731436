import React from "react";
import AddFormHeader from "./components/AddFormHeader";
import { RequiredFieldsSubheader, UnderlinedHeader } from "src/components";

const AddRevocableTrust: React.FC = () => {

    return (
        <div>
            <AddFormHeader title="Add Revocable Trust"/>
            <div className="form-container">
                <section aria-label="Basic Info">
                    <UnderlinedHeader leftAlignedContent={<><h4>Basic Information</h4> <RequiredFieldsSubheader /></>} />
                </section>
            </div>
        </div>
    )
}

export default AddRevocableTrust;