import React, {ReactElement} from "react";
import {Mapping as ClassNamesMapping} from "classnames";
import {CellClassParamsReport, TableRowComponentReport} from "./TableRowComponentReport";
import {ExpandableTableRowReport} from "./ExpandableTableRowReport";
import {COLOR_NT_GREY} from "../../../constants/colors";
import useAccordionTableToggle from "../../../components/AccordionTable/useAccordionTableToggle";
import {TableDisplay, TableRow} from "../../../components";

const DEFAULT_CONTENT_DECORATOR_REPORT = <ValueType, TR extends TableRow<ValueType>>(
    decoratedRows_: DecoratedTableRow_<ValueType, TR>[]
): ReactElement => {
    return <>{decoratedRows_.map(decoratedRow_ => decoratedRow_.reactElement_)}</>;
}

const DEFAULT_ROW_DECORATOR_REPORT = <ValueType, TR extends TableRow<ValueType>>(
    _index_: number,
    decoratedRow_: DecoratedTableRow_<ValueType, TR>
): DecoratedTableRow_<ValueType, TR> => {
    return {
        row_: decoratedRow_.row_,
        reactElement_: decoratedRow_.reactElement_
    }
};

export type DecoratedTableRow_<ValueType, TR extends TableRow<ValueType> = TableRow<ValueType>> = {
    row_: TR;
    reactElement_: ReactElement;
}

export type AccordionTablePropsForReport<ValueType, TR extends TableRow<ValueType>> = {
    accordionTableId_: string;
    additionalCellClasses_?: (params: CellClassParamsReport) => ClassNamesMapping[];
    collapsedAccentColor_: string;
    contentDecorator_?: (decoratedRows: DecoratedTableRow_<ValueType, TR>[]) => ReactElement;
    ariaLabel_: string;
    titleCellWidth_: number;
    tableDisplayData_: TableDisplay<ValueType, TR>;
    onTitleCellMouseEnter_?: (row: TR, rowIndex?: number) => void;
    onTitleCellMouseLeave_?: (row: TR) => void;
    rowDecorator_?: (index: number, decoratedRow: DecoratedTableRow_<ValueType, TR>) => DecoratedTableRow_<ValueType, TR>;
    valueColumnSize_?: number;
    isReportExpanded_?: boolean;
};

export const AccordionTableReport = <ValueType, TR extends TableRow<ValueType>>({
                                                                              accordionTableId_,
                                                                              additionalCellClasses_,
                                                                              collapsedAccentColor_,
                                                                              contentDecorator_ = DEFAULT_CONTENT_DECORATOR_REPORT,
                                                                              ariaLabel_,
                                                                              titleCellWidth_,
                                                                              tableDisplayData_,
                                                                              onTitleCellMouseEnter_,
                                                                              onTitleCellMouseLeave_,
                                                                              valueColumnSize_,
                                                                              rowDecorator_ = DEFAULT_ROW_DECORATOR_REPORT,
                                                                              isReportExpanded_ = false
                                                                          }: AccordionTablePropsForReport<ValueType, TR>) => {
    const {toggleExpanded} = useAccordionTableToggle(accordionTableId_);

    const renderChildRows = () => tableDisplayData_.rows.map((row_, rowIndex_) =>
        rowDecorator_(
            rowIndex_,
            {
                row_: row_,
                reactElement_: <ExpandableTableRowReport key={row_.uniqueIdentifier}
                                                  accordionTableId_={accordionTableId_}
                                                  additionalCellClasses_={additionalCellClasses_}
                                                  ariaLabel_={ariaLabel_}
                                                  row_={row_}
                                                  rowIndex_={rowIndex_}
                                                  titleCellWidth_={titleCellWidth_}
                                                  onTitleCellMouseEnter_={onTitleCellMouseEnter_}
                                                  onTitleCellMouseLeave_={onTitleCellMouseLeave_}
                                                  valueColumnSize_={valueColumnSize_}
                                                  isReportExpanded_={isReportExpanded_}
                />
            }
        )
    );
    const childRows_ = contentDecorator_(renderChildRows());
    return <>
        <TableRowComponentReport
            row_={{
                accentColor: isReportExpanded_ ? COLOR_NT_GREY : collapsedAccentColor_,
                label: tableDisplayData_.header.label,
                subtitle: tableDisplayData_.header.subtitle,
                values: tableDisplayData_.header.values,
                uniqueIdentifier: 'header-row',
                children: []
            }}
            additionalCellClasses_={additionalCellClasses_}
            isSummaryRow_={true}
            ariaLabel_={ariaLabel_}
            titleCellWidth_={titleCellWidth_}
            onClick_={tableDisplayData_.rows.length > 0 ? toggleExpanded : undefined}
            valueColumnSize_={valueColumnSize_}
            isExpanded_={isReportExpanded_}
        />
        {isReportExpanded_ && childRows_}
    </>;
}