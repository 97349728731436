import {
    CurrentAllocationResponse,
    EMPTY_CURRENT_ALLOCATION_RESPONSE
} from "../../ClientManagement/AssetAllocation/CurrentAllocationResponse";
import React, {useEffect, useState} from "react";
import {
    EMPTY_PROPOSED_ALLOCATION_RESPONSE,
    ProposedAllocationResponse
} from "../../ClientManagement/AssetAllocation/ProposedAllocationResponse";
import {wealthManagementApiClient} from "../../ClientManagement/WealthManagementApiClient";
import {Col, Container, Row} from "react-grid-system";
import {Loader} from "xps-react";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {setHeaderTickerTabsProposedAllocation} from "../../ClientManagement/ClientProfile/HeaderTickerTabsSlice";
import {useAppDispatch} from "../../store/hooks";

export const AssetAllocationCard = (allGoalsForAAChangeStr: { allGoalsForAAChangeStr: string }) => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [proposedAssetAllocation, setProposedAssetAllocation] = useState<ProposedAllocationResponse>(EMPTY_PROPOSED_ALLOCATION_RESPONSE);
    const [currentAssetAllocation, setCurrentAssetAllocation] = useState<CurrentAllocationResponse>(EMPTY_CURRENT_ALLOCATION_RESPONSE);
    const {id} = useParams<RouteWithId>();
    const activePortfolioReserve = useProfileAndProposals(id).profile.activePortfolioReserve;
    const dispatch = useAppDispatch();

    useEffect(() => {
       let isMounted = true;
        const apiRequests: [
            Promise<ProposedAllocationResponse>,
            Promise<CurrentAllocationResponse>
        ] = [
            wealthManagementApiClient.getProposedAllocation(id),
            wealthManagementApiClient.getCurrentAllocation(id)
        ]
        const promises = Promise.all(apiRequests)
        promises.then(wealthResponses => {
            let [
                proposedAllocationResponse,
                currentAllocationResponse
            ] = wealthResponses
            if(isMounted){
                setCurrentAssetAllocation(currentAllocationResponse)
                setProposedAssetAllocation(proposedAllocationResponse)
                dispatch(setHeaderTickerTabsProposedAllocation({
                    totalRiskControlAssetsPercent: proposedAllocationResponse.totalRiskControlAssetsPercent,
                    totalRiskAssetsPercent:  proposedAllocationResponse.totalRiskAssetsPercent
                }))
                setIsLoading(false)
            }
        })
        return () => {
            isMounted = false;
            setIsLoading(true)
        }
    }, [id, allGoalsForAAChangeStr, activePortfolioReserve])

    if (isLoading) {
        return <Container>
            <div className='goal-prioritization-card asset-allocation-card'>
                <Loader size="sm"/>
            </div>
        </Container>
    }

    const hasCurrentAllocation = () =>
        currentAssetAllocation.totalRiskAssetsAmount > 0 || currentAssetAllocation.totalRiskControlAssetsAmount > 0;

    return (
        <Container>
            <div data-testid='asset-allocation-card' className='goal-prioritization-card asset-allocation-card'>
                <Row>
                    <Col xs={8} className='goal-prioritization-card-header'>
                        Asset Allocation
                    </Col>
                    <Col xs={2} className='current-and-proposed'>
                        CURRENT
                    </Col>
                    <Col xs={2} className='current-and-proposed'>
                        PROPOSED
                    </Col>
                </Row>
                <Row>
                    <Col xs={8} className='goal-prioritization-card-first-column'>
                        Risk Assets
                    </Col>
                    <Col xs={2}>
                        {Math.round(currentAssetAllocation.totalRiskAssetsPercent)}%
                    </Col>
                    <Col xs={2}>
                        {hasCurrentAllocation() ? Math.round(proposedAssetAllocation!.totalRiskAssetsPercent) : 0}%

                    </Col>
                </Row>
                <Row>
                    <Col xs={8} className='goal-prioritization-card-first-column'>
                        Risk Control Assets
                    </Col>
                    <Col xs={2}>
                        {Math.round(currentAssetAllocation.totalRiskControlAssetsPercent)}%
                    </Col>
                    <Col xs={2}>
                        {hasCurrentAllocation() ? Math.round(proposedAssetAllocation!.totalRiskControlAssetsPercent) : 0}%
                    </Col>
                </Row>
            </div>
        </Container>
    );
}