import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {SimplifiedProposedAllocationResponse} from "../AssetAllocation/ProposedAllocationResponse";
import {EMPTY_HEADER_TICKER_TABS_RESPONSE, SimplifiedAssetRelianceResponse} from "./HeaderTickerTabType";
import {RootState} from "../../store/store";

export type HeaderTickerTabsState = {
    assetReliance: SimplifiedAssetRelianceResponse;
    proposedAllocation: SimplifiedProposedAllocationResponse;
};

export const initialHeaderTickerTabsState: HeaderTickerTabsState = EMPTY_HEADER_TICKER_TABS_RESPONSE;

const headerTickerTabsSlice = createSlice({
    name: "headerTickerTabs",
    initialState: initialHeaderTickerTabsState,
    reducers: {
        setHeaderTickerTabs: (state, action: PayloadAction<HeaderTickerTabsState>) => {
            state.assetReliance = action.payload.assetReliance;
            state.proposedAllocation = action.payload.proposedAllocation;
        },
        setHeaderTickerTabsProposedAllocation: (state, action: PayloadAction<SimplifiedProposedAllocationResponse>) => {
            state.proposedAllocation = action.payload;
        }
    }
});

export const selectHeaderTickerTabs = (state: RootState): HeaderTickerTabsState => {
    return state.client.headerTickerTabs;
};

export const {setHeaderTickerTabs, setHeaderTickerTabsProposedAllocation} = headerTickerTabsSlice.actions;

export default headerTickerTabsSlice.reducer;
