import React, {useMemo} from "react";
import AssetHoldingHeader from "./AssetHoldingHeader";
import {Accordion, AccordionItem} from "../../components";
import CurrentVsProposedHorizontalDivider from "./CurrentVsProposedHorizontalDivider";
import {AssetSubclassesSummary} from "./AssetSubclassesSummary";
import {Col, Row} from "react-grid-system";
import {camelize} from "../../utils/stringUtils";
import classNames from "classnames";
import {
    AllocationDifference,
    findSubclassSummary,
    getDifference,
    getTotalInvestibleAmount,
    getTotalInvestiblePercent,
    sortAssetClassHierarchy,
    getTotalProposedInvestibleAmount,
    getTotalProposedInvestiblePercent,
    getTotalInvestiblePercentForClassification,
    getTotalInvestiblePercentCurrent,
    getDifferenceOfPortfolio,
    getTotalInvestiblePercentForClassificationNoFormat,
    getTotalInvestiblePercentCurrentNoFormat, getTotalProposedInvestiblePercentNoFormat
} from "./assetAllocationUtils";
import {ReviseAssetSubclassesSummary} from "./ReviseAssetAllocation/ReviseAssetSubclassesSummary";

type AssetHoldingProps = {
    id: string
    assetClassName: string
    total: number
    totalProposed?: number
    totalPercentage: number
    totalProposedPercentage?: number
    accentColor: string
    assetSubclasses: string[]
    currentAssetSubclassSummaries: ReviseAssetSubclassesSummary[]
    proposedAssetSubclassSummaries: ReviseAssetSubclassesSummary[]
    totalProposedInvestableAmount: number,
    totalCurrentInvestableAmount: number
}

const FIRST_COLUMN_WIDTH = 315;
const NOT_FIRST_COLUMN_WIDTH = 300;

const AssetHoldings: React.FC<AssetHoldingProps> = ({
                                                        id,
                                                        assetClassName,
                                                        total,
                                                        totalProposed,
                                                        totalPercentage,
                                                        totalProposedPercentage,
                                                        assetSubclasses,
                                                        currentAssetSubclassSummaries,
                                                        proposedAssetSubclassSummaries,
                                                        accentColor,
                                                        totalProposedInvestableAmount,
                                                        totalCurrentInvestableAmount
                                                    }) => {

    const filteredAssetSubclassSummaries = useMemo(() => {
        return getFilteredAssetSubclassSummaries(
            assetSubclasses,
            currentAssetSubclassSummaries,
            proposedAssetSubclassSummaries
        );
    }, [proposedAssetSubclassSummaries, currentAssetSubclassSummaries, assetSubclasses])

    const {level2AssetClassGrouping, totalsForAssetClasses} = useMemo(() => {
        return sortAssetClassHierarchy(filteredAssetSubclassSummaries);
    }, [filteredAssetSubclassSummaries]);

    const hasSubclassSummaryRows = filteredAssetSubclassSummaries.length > 0;
    return (
        <>
            <CurrentVsProposedTableHeader allocationType={assetClassName.toUpperCase()}/>
            <Row className={"row"}>
                <Accordion accordionId={id}
                           className="asset-stack-table-accordion">
                    <AccordionItem
                        uuid={assetClassName}
                        primaryText={assetClassName}
                        accentColor={accentColor}
                        HeaderComponent={({expanded}) => <AssetHoldingHeader
                            expanded={expanded}
                            title={assetClassName}
                            total={total}
                            totalProposed={totalProposed}
                            totalPercentage={totalPercentage}
                            totalProposedPercentage={totalProposedPercentage}
                            showChevron={hasSubclassSummaryRows}
                        />}>
                        <AssetSubclassSummaries
                            sortedAssetClassHierarchy={level2AssetClassGrouping}
                            assetTotals={totalsForAssetClasses}
                            proposedTotalInvestableAmount={totalProposedInvestableAmount}
                            currentTotalInvestableAmount={totalCurrentInvestableAmount}
                        />
                    </AccordionItem>
                </Accordion>
            </Row>
        </>

    )
};

type CurrentVsProposedTableHeaderProps = {
    allocationType: string;
}

export const CurrentVsProposedTableHeader: React.FC<CurrentVsProposedTableHeaderProps> = ({
                                                                                              allocationType
                                                                                          }) => {
    return (
        <Row className={"row-component"}>
            <Col width={FIRST_COLUMN_WIDTH}
                 style={{minWidth: FIRST_COLUMN_WIDTH}}
                 className="allocation-type">
                <span className='paddingleft-xxl'>{allocationType}</span>
            </Col>
            <Col width={NOT_FIRST_COLUMN_WIDTH}
                 style={{minWidth: NOT_FIRST_COLUMN_WIDTH}}
                 className="sub-header-container">
                <CurrentVsProposedHorizontalDivider/>
                <div className="amounts flex">
                    <span className="total-dollar-amount flex-grow-1">Total $</span>
                    <span className="total-percent-amount paddingright-lg" style={{width: '135px'}}>Total %</span>
                </div>
            </Col>
            <Col width={NOT_FIRST_COLUMN_WIDTH}
                 style={{minWidth: NOT_FIRST_COLUMN_WIDTH}}
                 className="sub-header-container proposed">
                <CurrentVsProposedHorizontalDivider/>
                <div className="amounts flex">
                    <span className="total-dollar-amount flex-grow-1">Total $</span>
                    <span className="total-percent-amount paddingright-lg" style={{width: '135px'}}>Total %</span>
                </div>
            </Col>
            <Col width={NOT_FIRST_COLUMN_WIDTH}
                 style={{minWidth: NOT_FIRST_COLUMN_WIDTH}}
                 className="sub-header-container">
                <CurrentVsProposedHorizontalDivider/>
                <div className="amounts">
                    <span className="total-dollar-amount flex-grow-1">Total $ (DIFF)</span>
                    <span className="total-percent-amount paddingright-lg" style={{width: '135px'}}>Total % (DIFF)</span>
                </div>
            </Col>
        </Row>
    );
}

export type AssetSubclassSummary = {
    subclass: string
    current: ReviseAssetSubclassesSummary | null
    proposed: ReviseAssetSubclassesSummary | null
}

export const getFilteredAssetSubclassSummaries = (
    assetSubclasses: string[],
    currentAssetSubclassSummaries: ReviseAssetSubclassesSummary[],
    proposedAssetSubclassSummaries: ReviseAssetSubclassesSummary[]
): AssetSubclassSummary[] => {
    return assetSubclasses
        .map(subclass => ({
            subclass: subclass,
            current: findSubclassSummary(subclass, currentAssetSubclassSummaries),
            proposed: findSubclassSummary(subclass, proposedAssetSubclassSummaries),
        }))
        .filter(subclassSummary => {
            return !!subclassSummary.current?.currentInvestableAmount
                || !!subclassSummary.proposed?.proposedInvestableAmount;
        });
}

export type AssetSubclassSummariesProps = {
    sortedAssetClassHierarchy?: { [key: string]: AssetSubclassSummary[] }
    assetTotals?: { [key: string]: AssetSubclassSummary }
    proposedTotalInvestableAmount: number,
    currentTotalInvestableAmount: number
}

export const AssetSubclassSummaries: React.FC<AssetSubclassSummariesProps> = ({
                                                                                  sortedAssetClassHierarchy = {},
                                                                                  assetTotals = {},
                                                                                  proposedTotalInvestableAmount,
                                                                                  currentTotalInvestableAmount
                                                                              }) => {
    const assetClasses = Object.keys(sortedAssetClassHierarchy);
    return (
        <div data-testid={'asset-subclass-summaries'} className='asset-subclass-summary-container'>
            {assetClasses.map((assetClassName) => {
                return <div key={assetClassName} role={"row"}>
                    <   AssetClassSummaryRow key={assetClassName}
                                          name={assetClassName}
                                          totalInvestableAmount={getTotalInvestibleAmount(assetTotals[assetClassName]?.current)}
                                          totalInvestablePercent={getTotalInvestiblePercentForClassification(assetTotals[assetClassName]?.current?.currentInvestableAmount, currentTotalInvestableAmount)}
                                          totalInvestableAmountProposed={getTotalProposedInvestibleAmount(assetTotals[assetClassName]?.proposed)}
                                          totalInvestablePercentProposed={getTotalInvestiblePercentForClassification(assetTotals[assetClassName]?.proposed?.proposedInvestableAmount, proposedTotalInvestableAmount)}
                                          allocationDifference={getDifferenceOfPortfolio(
                                              assetTotals[assetClassName]?.current,
                                              assetTotals[assetClassName]?.proposed,
                                              getTotalInvestiblePercentForClassificationNoFormat(assetTotals[assetClassName]?.proposed?.proposedInvestableAmount, proposedTotalInvestableAmount),
                                              getTotalInvestiblePercentForClassificationNoFormat(assetTotals[assetClassName]?.current?.currentInvestableAmount, currentTotalInvestableAmount)
                                          )}
                    />
                    {sortedAssetClassHierarchy[assetClassName].map(({subclass, current, proposed}) =>
                        <AssetSubclassSummaryRow key={subclass}
                                                 name={subclass}
                                                 totalInvestableAmount={getTotalInvestibleAmount(current)}
                                                 totalInvestablePercent={getTotalInvestiblePercentCurrent(current, currentTotalInvestableAmount)}
                                                 totalInvestableAmountProposed={getTotalProposedInvestibleAmount(proposed)}
                                                 totalInvestablePercentProposed={getTotalProposedInvestiblePercent(proposed)}
                                                 allocationDifference={getDifferenceOfPortfolio(
                                                     current,
                                                     proposed,
                                                     getTotalProposedInvestiblePercentNoFormat(proposed),
                                                     getTotalInvestiblePercentCurrentNoFormat(current, currentTotalInvestableAmount)
                                                 )}
                        />
                    )}
                </div>
            })}
        </div>
    )
};

type AssetSubclassSummaryRowProps = {
    name: string,
    totalInvestableAmount: number | string,
    totalInvestablePercent: number | string,
    totalInvestableAmountProposed: number | string,
    totalInvestablePercentProposed: number | string,
    allocationDifference: AllocationDifference
}

export const AssetSubclassSummaryRow: React.FC<AssetSubclassSummaryRowProps> = ({
                                                                             name,
                                                                             totalInvestableAmount,
                                                                             totalInvestablePercent,
                                                                             totalInvestableAmountProposed,
                                                                             totalInvestablePercentProposed,
                                                                             allocationDifference
                                                                         }) => {
    return (
        <Row className={"asset-subclass-summary-grid"} aria-label={`${name} subclass`}>
            <Col className="subclass-first-column-container" width={318} style={{minWidth: '318px'}}>
                 <span key={`asset-stack-table-header-row-${camelize(name)}-label`}
                       role="cell"
                       className="subclass-first-column">{name}
                 </span>
            </Col>
            <Col className={"subclass-total-container flex"} width={300} style={{minWidth: '300px'}}>
                 <span
                     className={"subclass-total-amount flex-grow-1"}>
                     {totalInvestableAmount}
                 </span>
                <span className={"subclass-total-amount paddingright-lg"} style={{width: '135px'}}>
                 {`${totalInvestablePercent}`}
                </span>
            </Col>
            <Col className={"subclass-total-container flex"} width={300} style={{minWidth: '300px'}}>
                 <span
                     className={"subclass-total-amount flex-grow-1"}>
                     {totalInvestableAmountProposed}
                 </span>
                <span className={"subclass-total-amount paddingright-lg"} style={{width: '135px'}}>
                     {`${totalInvestablePercentProposed}`}
                 </span>
            </Col>
            <Col className={"subclass-total-container flex textalign-right"} width={300} style={{minWidth: '300px'}}>
                  <span
                      className={classNames("subclass-total-amount flex-grow-1", {"negative-difference": allocationDifference.isNegative})}>
                         {allocationDifference.differenceAmount}
                     </span>
                <span className='paddingright-lg' style={{width: '135px'}}>
                    <span
                        className={classNames("subclass-total-amount", {"negative-difference": allocationDifference.isNegative})}>
                         {allocationDifference.differencePercentage}
                     </span>
                </span>
            </Col>
        </Row>
    )
        ;
};

type AssetClassSummaryRowProps = {
    name: string,
    totalInvestableAmount: number | string,
    totalInvestablePercent: number | string,
    totalInvestableAmountProposed: number | string,
    totalInvestablePercentProposed: number | string,
    allocationDifference: AllocationDifference
}

export const AssetClassSummaryRow: React.FC<AssetClassSummaryRowProps> = ({
                                                                       name,
                                                                       totalInvestableAmount,
                                                                       totalInvestablePercent,
                                                                       totalInvestableAmountProposed,
                                                                       totalInvestablePercentProposed,
                                                                       allocationDifference
                                                                   }) => {
    return (
        <Row className={"asset-subclass-summary-grid fontweight-500"} aria-label={`${name} asset class`}>
            <Col className="subclass-first-column-container" width={318} style={{minWidth: '318px'}}>
                 <span key={`asset-stack-table-header-row-${camelize(name)}-label`}
                       role="cell"
                       className="asset-class-first-column">{name}
                 </span>
            </Col>
            <Col className={"subclass-total-container flex"} width={300} style={{minWidth: '300px'}}>
                 <span
                     className={"subclass-total-amount flex-grow-1"}>
                     {totalInvestableAmount}
                 </span>
                <span className={"subclass-total-amount paddingright-lg"} style={{width: '135px'}}>
                 {`${totalInvestablePercent}`}
                </span>
            </Col>
            <Col className={"subclass-total-container flex"} width={300} style={{minWidth: '300px'}}>
                 <span
                     className={"subclass-total-amount flex-grow-1"}>
                     {totalInvestableAmountProposed}
                 </span>
                <span className={"subclass-total-amount paddingright-lg"} style={{width: '135px'}}>
                     {`${totalInvestablePercentProposed}`}
                 </span>
            </Col>
            <Col className={"subclass-total-container flex textalign-right"} width={300} style={{minWidth: '300px'}}>
                  <span
                      className={classNames("subclass-total-amount flex-grow-1", {"negative-difference": allocationDifference.isNegative})}>
                         {allocationDifference.differenceAmount}
                     </span>
                <span className='paddingright-lg' style={{width: '135px'}}>
                    <span
                        className={classNames("subclass-total-amount", {"negative-difference": allocationDifference.isNegative})}>
                         {allocationDifference.differencePercentage}
                     </span>
                </span>
            </Col>
        </Row>
    );
};


export default AssetHoldings;