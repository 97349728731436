import {useHistory} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {Location} from "history";
import ModalWrapper from "../Modal/ModalWrapper/ModalWrapper";

export type HistoryBlockDiscardModalProps = {
    when: boolean,
};

export function HistoryBlockDiscardModal({
                                      when,
                                  }: HistoryBlockDiscardModalProps) {

    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [navLocation, setNavLocation] = useState<Location | null>(null);

    const unblockRef = useRef();

    useEffect(() => {
        unblockRef.current = history.block((newLocation: Location) => {
            // @ts-ignore
            const forceNavigate = newLocation.state?.forceNavigate ? newLocation.state.forceNavigate : false
            if (when && !forceNavigate) {
                setNavLocation(newLocation);
                setShowModal(true);
                return false;
            } else if (forceNavigate) {
                return true;
            }
            return true;
        });
        return () => {
            // @ts-ignore -- this ref is callable
            unblockRef.current && unblockRef.current();
        };
    }, [when]);

    function navigateAway(newLocation: Location | null = navLocation) {
        if (unblockRef && unblockRef.current) {
            // @ts-ignore TS2349 -- this ref is callable
            unblockRef.current();
        }
        history.push(newLocation);
    }

    function onDiscard() {
        navigateAway();
    }

    function onKeepEditing() {
        setShowModal(false);
    }

    return (
        <ModalWrapper
            id="discard-changes-modal"
            isOpen={showModal}
            headerText="Discard Changes"
            buttons={[
                {
                    text: 'Keep Editing',
                    onClick: onKeepEditing,
                },
                {
                    text: 'Discard Changes',
                    onClick: onDiscard,
                    destructive: true,
                    primary: true,
                }
            ]}
        >
            <div className="font-md">If you leave this page, any changes you have made will not be saved.</div>
        </ModalWrapper>
    );
}
