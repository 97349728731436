import {FamilyRelationshipType} from "./FamilyRelationshipType";
import {FamilyRelationship} from "./FamilyRelationship";


export enum Gender {
    Male = "Male",
    Female = "Female",
    Undisclosed = "Undisclosed"
}

export enum LifeStatus {
    Living = "Living",
    Deceased = "Deceased"
}

export type RelatesToType = {
    memberId: string
    type: FamilyRelationshipType | null
}

// common fields for all members
export type BaseMemberType = {
    firstName: string,
    lastName: string,
    middleInitial: string | null,
    gender: Gender,
    stateOfResidency: string | null,
    birthdate: string | null,
    personalPlanningHorizon: number,
    lifeStatus: LifeStatus,
    deceasedDate: string | null,
    age: number,
    primaryParent?: boolean
}

export type FamilyMemberFormValidation = null | {
    firstName: string | null,
    lastName: string | null,
    age: string | null,
    personalPlanningHorizon: string | null,
    deceasedDate: string | null,
}
export type FamilyMemberFormValidationContainer = {
    familyMemberForm: null | FamilyMemberFormValidation
}

// this is what comes back from the server
// when reading member data
export type MemberType = BaseMemberType & {
    id: string,
    stateAbbr?: string | null,
    family: FamilyRelationship[]
    otherMembers: FamilyRelationship[]
}

export type FamilyMemberForm = BaseMemberType & {
    id?: string | null,
    relatesTo?: RelatesToType[],
    children?: string[]
}

export const getFamilyMemberForm: (memberType: MemberType, relatesTo?: RelatesToType[]) => FamilyMemberForm =
    (memberType: MemberType, relatesTo?: RelatesToType[]) => ({
        firstName: memberType.firstName,
        lastName: memberType.lastName,
        middleInitial: memberType.middleInitial,
        gender: memberType.gender,
        stateOfResidency: memberType.stateOfResidency,
        birthdate: memberType.birthdate,
        personalPlanningHorizon: memberType.personalPlanningHorizon,
        lifeStatus: memberType.lifeStatus,
        age: memberType.age,
        id: memberType.id,
        relatesTo: relatesTo,
        deceasedDate: memberType.deceasedDate,
        primaryParent: memberType.primaryParent
    })