import React, {ChangeEvent, useMemo, useState} from "react";
import {FamilyMemberForm, MemberType} from "../../../models/MemberType";
import {Checkbox} from "xps-react";
import {Dropdown, DropdownItem, Icon, Label, Name} from "../../../../components";
import {
    findAllPartnersForMemberId,
    findCommonChildren,
    findExtendedParents,
    findPartners,
    getAllPartnerRelationshipStatesForMemberId,
    getChildForExtendedParent,
    getParentsForPrimaryMemberOrLateral,
    getPrimaryParentForMemberWhenMemberIsNotPrimaryOrLateral,
    isExtendedFamily,
    isMemberPrimaryOrLateral,
    isPartnerRelationship
} from "../../../FamilyRelationshipService";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import {
    FamilyRelationshipState,
    SaveAction,
    selectAddMemberChildren,
    selectEditMemberType,
    selectFamilyMemberForm,
    selectFamilyTree,
    selectRelatedTo,
    selectRelationshipStates,
    selectSaveAction,
    setAddMemberChildren,
    setFamilyMemberForm,
    setRelationshipStates
} from "../../FamilyTreeSlice";
import {
    getDisplayPartnerRelationshipTypes,
    getRelationshipType,
    getRelationshipTypeDisplayValue
} from "../../RelationshipTypeUtil";
import {FamilyRelationshipType} from "../../../models/FamilyRelationshipType";
import {InlineAlert} from "../../../../components/AlertBanner/InlineAlert";
import {FamilyMemberRelationshipsInlineAlertVisibility} from "../AddOrEditFamilyMember";
import util from "./FamilyMemberRelationshipsUtil";
import useProfileEditableState from "../../../../hooks/useProfileEditableState";


type FamilyMemberRelationshipsProps = {
    isMemberAssetOwner: boolean,
    isPartnerAssetOwner: boolean,
    familyMemberRelationshipsInlineAlertVisibility: FamilyMemberRelationshipsInlineAlertVisibility
    setFamilyMemberRelationshipsInlineAlertVisibility: (value: FamilyMemberRelationshipsInlineAlertVisibility) => void,
}

export const FamilyMemberRelationships: React.FC<FamilyMemberRelationshipsProps> = ({
                                                                                        isMemberAssetOwner,
                                                                                        isPartnerAssetOwner,
                                                                                        familyMemberRelationshipsInlineAlertVisibility,
                                                                                        setFamilyMemberRelationshipsInlineAlertVisibility,
                                                                                    }) => {
    const dispatch = useAppDispatch();
    const createEditAction = useAppSelector(selectSaveAction);
    const familyTree = useAppSelector(selectFamilyTree)!;
    const familyMemberForm: FamilyMemberForm = useAppSelector(selectFamilyMemberForm)!;
    const relationshipStates = useAppSelector(selectRelationshipStates)!;
    const relatedTo = useAppSelector(selectRelatedTo)!;
    const editingMember = useAppSelector(selectEditMemberType)!;
    const saveAction = useAppSelector(selectSaveAction)!;

    const isActionEdit = useMemo(() => createEditAction === SaveAction.Edit, [createEditAction]);

    // passing primaryContact because they are the root of the tree
    const partners = findAllPartnersForMemberId(familyMemberForm.id!, familyTree.primaryContact).values().next();
    const partner: MemberType = partners.value ? partners.value[0] : undefined
    const partnerRelationshipState: FamilyRelationshipState = getAllPartnerRelationshipStatesForMemberId(familyMemberForm.id!, familyTree.primaryContact)[0];

    const isPrimaryOrLateral = isMemberPrimaryOrLateral(familyTree.primaryContact, editingMember?.id);

    let primaryParent: MemberType | undefined;
    let otherDirectParent: FamilyRelationshipState | undefined;
    let allPossibleParents: FamilyRelationshipState[] = [];

    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    if (isPrimaryOrLateral) {
        const extendedParents = getParentsForPrimaryMemberOrLateral(familyTree.primaryContact, familyMemberForm.id!)
        primaryParent = extendedParents.find(p => p.primaryParent)

        otherDirectParent = extendedParents.filter(m => m !== primaryParent)
            .map(m => util.memberAsFamilyRelationshipState(m))
            .find(m => m)
    } else if (relationshipStates.find(state => state.type === FamilyRelationshipType.CHILD)) {
        primaryParent = createEditAction === SaveAction.Create
            ? relatedTo
            : getPrimaryParentForMemberWhenMemberIsNotPrimaryOrLateral(familyMemberForm.id!, familyTree.primaryContact);

        otherDirectParent = relationshipStates.filter(state => state.type === FamilyRelationshipType.CHILD)
            .filter(r => r.memberId !== primaryParent?.id)
            .find(r => r)
    }

    if (primaryParent) {
        const possibleParents = [...findPartners(primaryParent)]
            .map(member => util.memberAsFamilyRelationshipState(member));
        if (otherDirectParent) possibleParents.push(otherDirectParent)

        allPossibleParents = possibleParents.filter(util.removeDuplicates);
    }


    const singleParentMessage = primaryParent ? `None (${util.formatFullName(primaryParent)} is a Single Parent)` : '';
    const otherParentOptions: string[] = [
        singleParentMessage,
        ...(allPossibleParents.map(p => p.fullName))
    ];
    const [selectedOtherParent, updateSelectedOtherParent] = useState<FamilyRelationshipState | undefined>(otherDirectParent)

    const [selectedPartnerType, updateSelectedPartnerType] = useState((isActionEdit ? partnerRelationshipState : relatedTo)
        && relationshipStates.find(state => state.memberId === (isActionEdit ? partnerRelationshipState.memberId : relatedTo.id))?.type)

    const hasPartner = useMemo(() => isActionEdit ? !!partnerRelationshipState : relationshipStates.find(state => isPartnerRelationship(state.type)), [partnerRelationshipState, relatedTo]);

    const updateOtherParent = (option: string) => {
        const newRelationshipsStates = [
            ...relationshipStates
                .filter(state => !allPossibleParents.find(other => other.memberId === state?.memberId))
        ]
        const newOtherParent = allPossibleParents?.find(other => other.fullName === option)
        if (newOtherParent) {
            updateSelectedOtherParent(newOtherParent)
            newRelationshipsStates.push({
                memberId: newOtherParent.memberId,
                fullName: newOtherParent.fullName,
                type: isPrimaryOrLateral ? FamilyRelationshipType.PARENT : FamilyRelationshipType.CHILD
            })
        } else {
            updateSelectedOtherParent(undefined)
        }
        dispatch(setRelationshipStates(newRelationshipsStates))
    }

    const updatePartnerType = (option: string) => {
        let familyRelationshipType = getRelationshipType(option);
        updateSelectedPartnerType(familyRelationshipType)
        dispatch(setRelationshipStates(relationshipStates.map(state => isPartnerRelationship(state.type)
            ? ({
                ...state,
                type: familyRelationshipType
            })
            : state
        )))
    }

    const disableRelationshipDropdown = (): boolean => {
        if (saveAction === SaveAction.Edit) {
            return (familyMemberForm.id === familyTree.primaryContact.id && isPartnerAssetOwner) ||
                (familyTree.primaryContact.id === partnerRelationshipState.memberId && isMemberAssetOwner);
        }

        return false;
    }

    const partnerRelationshipChangeDisabledMessage = (): string => {
        const partnerName = (familyMemberForm.id === familyTree.primaryContact.id) ? partnerRelationshipState.fullName : `${familyMemberForm.firstName} ${familyMemberForm.lastName}`;

        if (partnerRelationshipState.type === FamilyRelationshipType.SPOUSE || partnerRelationshipState.type === FamilyRelationshipType.DOMESTIC_PARTNER) {
            return `Partner Type cannot be changed while ${partnerName} has In Estate Ownership.`;
        }
        return `Partner Type cannot be changed while ${partnerName} has Out of Estate Ownership.`;
    }

    const getPartnerLabelText = () => isActionEdit ? partnerRelationshipState.fullName : `${relatedTo.firstName} ${relatedTo.lastName}`;

    return <div className="relationships-tab">
        {!!primaryParent && (
            <div>
                <div className="header-container">
                    {util.displayRelationshipTitle(familyMemberForm, "Parents")}
                </div>
                <div>
                    <Name
                        name={primaryParent ? util.formatFullName(primaryParent) : ''}
                        className="layout-data-entry-form__field parent"
                        label={"Parent"}
                        required={false}
                        readOnly={true}/>
                    <div className="layout-data-entry-form__field other-parent">
                        <Label label={"Other Parent"} required={false}/>
                        <Dropdown
                            name="otherParent"
                            aria-label="otherParent"
                            aria-labelledby="otherParent"
                            className="other-parent"
                            id="other_parent_dropdown"
                            panelWidth="100%"
                            value={selectedOtherParent?.fullName ?? singleParentMessage}
                            onChange={(e: { itemText: string }) => updateOtherParent(e.itemText)}
                            disabled={isProfileWithProposalsOrArchived}
                        >
                            {otherParentOptions
                                .map(option => <DropdownItem key={option} itemText={option} value={option}/>)}
                        </Dropdown>
                    </div>
                </div>
            </div>
        )}


        <div>
            <div className="header-container">
                {util.displayRelationshipTitle(familyMemberForm, "Partner")}
            </div>
            {hasPartner ?
                <div className="layout-data-entry-form__field other-parent">
                    <Label label={getPartnerLabelText()}
                           required={false}/>
                    <Dropdown
                        name="partner"
                        aria-label="partner"
                        aria-labelledby="partner"
                        className="partner"
                        id="partner_dropdown"
                        panelWidth="100%"
                        value={(selectedPartnerType && getRelationshipTypeDisplayValue(selectedPartnerType))
                            ?? getDisplayPartnerRelationshipTypes()[0]}
                        onChange={(e: { itemText: string }) => {
                            dispatch(setFamilyMemberForm({
                                ...familyMemberForm,
                                deceasedDate: null
                            }));
                            updatePartnerType(e.itemText)
                            setFamilyMemberRelationshipsInlineAlertVisibility({
                                ...familyMemberRelationshipsInlineAlertVisibility,
                                partnerDropdown: e.itemText === 'Spouse' || e.itemText === 'Domestic Partner'
                            })
                        }}
                        disabled={disableRelationshipDropdown() || isProfileWithProposalsOrArchived}
                    >
                        {getDisplayPartnerRelationshipTypes()
                            .map(option => <DropdownItem key={option} itemText={option} value={option}/>)}
                    </Dropdown>
                </div>
                : <div className="empty-relationship-message">This person has no partner added.</div>
            }
            {(hasPartner && saveAction === SaveAction.Edit && familyMemberRelationshipsInlineAlertVisibility.partnerDropdown
                    && (familyMemberForm.id === familyTree.primaryContact.id || partner.id === familyTree.primaryContact.id))
                &&
                <InlineAlert
                    className="inline-alert-delete-goal"
                    message={"Any family goals for this individual will be deleted after changing the relationship status to Spouse or Domestic Partner."}
                />
            }

            {(hasPartner && saveAction === SaveAction.Edit && disableRelationshipDropdown()) &&
                <div className='partner-relationship-alert'>
                    <Icon className="info-icon" ariaHidden name="status_info_outline" type="info"/>
                    <p>{partnerRelationshipChangeDisabledMessage()}</p></div>
            }
        </div>

        <div>
            <div className="header-container">
                {util.displayRelationshipTitle(familyMemberForm, "Children")}
            </div>
            <div>
                <ChildRelationshipsForm isActionEdit={isActionEdit}
                                        partnerRelationshipState={partnerRelationshipState}
                                        selectedPartnerType={selectedPartnerType}
                />

            </div>
        </div>
    </div>;
}

type EditChildrenProps = {
    partnerRelationshipState: FamilyRelationshipState,
    childrenWithPartner: MemberType[],
    singleParentChildren: MemberType[]
}

const EditChildren: React.FC<EditChildrenProps> = ({
                                                       partnerRelationshipState,
                                                       childrenWithPartner,
                                                       singleParentChildren
                                                   }) => {
    return <div>
        {partnerRelationshipState && childrenWithPartner.length > 0 && (
            <div className="margintop-20">
                <div className="layout-data-entry-form__field">
                    <Label label={`with ${partnerRelationshipState.fullName}`}/>
                    <span>{childrenWithPartner.map(child => `${child.firstName} ${child.lastName}`).join(', ')}</span>
                </div>
            </div>
        )}
        {singleParentChildren.length > 0 && (
            <div className="margintop-20">
                <div className="layout-data-entry-form__field">
                    <Label label={"as a Single Parent"}/>
                    <span>{singleParentChildren.map(child => `${child.firstName} ${child.lastName}`).join(', ')}</span>
                </div>
            </div>
        )}
    </div>;
}

type AddChildrenProps = {
    childrenOfPartner: MemberType[],
    addMemberChildren: Array<string>,
    updateChildren: (event: React.ChangeEvent<HTMLInputElement>, childId: string) => void
}

const AddChildren: React.FC<AddChildrenProps> = ({childrenOfPartner, addMemberChildren, updateChildren}) =>
    (<>
        {childrenOfPartner.map(child =>
            <Checkbox
                key={`family-member-relationship-checkbox-${child.id}`}
                aria-label={`${child.firstName} ${child.lastName}`}
                value={`${child.firstName} ${child.lastName}`}
                name={`${child.firstName} ${child.lastName}`}
                checked={addMemberChildren ? !!addMemberChildren.find(id => id === child.id) : false}
                onChange={(event: ChangeEvent<HTMLInputElement>) => updateChildren(event, child.id)}
            />)}
    </>);

const NoChildren: React.FC = () => <div className="empty-relationship-message">This person has no children added.</div>


type ChildRelationshipsSectionProps = {
    isActionEdit: boolean
    partnerRelationshipState: FamilyRelationshipState,
    selectedPartnerType: FamilyRelationshipType | null | undefined
}

const ChildRelationshipsForm: React.FC<ChildRelationshipsSectionProps> =
    ({
         isActionEdit,
         partnerRelationshipState,
         selectedPartnerType
     }) => {
        const dispatch = useAppDispatch();
        const addMemberChildren = useAppSelector(selectAddMemberChildren)!;
        const familyTree = useAppSelector(selectFamilyTree)!;
        const relatedTo = useAppSelector(selectRelatedTo)!;
        const editingMember = useAppSelector(selectEditMemberType)!;
        const familyMemberForm: FamilyMemberForm = useAppSelector(selectFamilyMemberForm)!;

        let childrenWithPartner: MemberType[];
        let singleParentChildren: MemberType[];
        const extendedFamily = isExtendedFamily(familyTree.primaryContact, editingMember?.id);
        const childrenOfPartner = useMemo(() => relatedTo && relatedTo.family && isPartnerRelationship(selectedPartnerType) ?
                relatedTo.family
                    .filter(relationship => relationship.type === FamilyRelationshipType.CHILD)
                    .map(relationship => relationship.fromMember)
                : [],
            [relatedTo, selectedPartnerType]);

        if (extendedFamily) {
            const childOfExtendedFamilyMember = getChildForExtendedParent(familyTree.primaryContact, editingMember?.id);
            const allParents = findExtendedParents(childOfExtendedFamilyMember);
            const extendedFamilyInDualParentRelationship = allParents.flatMap(p => p.family)
                .filter(r => isPartnerRelationship(r.type))
                .some(r => allParents.find((m) => m.id === r.fromMember.id));
            const extendedFamilyInSingleParentRelationship = childOfExtendedFamilyMember && !extendedFamilyInDualParentRelationship;

            childrenWithPartner = childOfExtendedFamilyMember && extendedFamilyInDualParentRelationship? [childOfExtendedFamilyMember] : [];
            singleParentChildren = extendedFamilyInSingleParentRelationship? [childOfExtendedFamilyMember] : [];
        } else {
            const partners = findAllPartnersForMemberId(familyMemberForm.id!, familyTree.primaryContact).values().next();
            const partner: MemberType = partners.value ? partners.value[0] : undefined
            childrenWithPartner = findCommonChildren(editingMember, partner);
            singleParentChildren = isActionEdit ? editingMember.family
                    .filter(relationship => relationship.type === FamilyRelationshipType.CHILD)
                    .map(relationship => relationship.fromMember)
                    .filter(child => !childrenWithPartner.find(commonChild => commonChild.id === child.id))
                    .sort((child1, child2) => child2.age >= child1.age ? 1 : -1)
                : [];
        }

        const updateChildren = (event: ChangeEvent<HTMLInputElement>, childId: string) => {
            const childrenList = addMemberChildren ? addMemberChildren : [];
            if (event.target.checked) {
                dispatch(setAddMemberChildren(childrenList.concat(childId)))
            } else {
                dispatch(setAddMemberChildren(childrenList.filter(id => id !== childId)))
            }
        }

        if (isActionEdit && (childrenWithPartner.length > 0 || singleParentChildren.length > 0)) {
            return <EditChildren partnerRelationshipState={partnerRelationshipState}
                                 childrenWithPartner={childrenWithPartner}
                                 singleParentChildren={singleParentChildren}/>;
        } else if (!isActionEdit && childrenOfPartner.length > 0) {
            return <AddChildren childrenOfPartner={childrenOfPartner}
                                addMemberChildren={addMemberChildren}
                                updateChildren={updateChildren}/>
        } else {
            return <NoChildren/>;
        }
    }

