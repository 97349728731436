import {formatCurrency, formatNumberRoundedToTwoDecimals, formatNumberRoundedToWholeNumber,} from "../../utils/format";
import {AssetSubclassSummary} from "./AssetHoldings";
import {ReviseAssetSubclassesSummary} from "./ReviseAssetAllocation/ReviseAssetSubclassesSummary";

export const findSubclassSummary = (
    subclass: string,
    summaries: ReviseAssetSubclassesSummary[]
): NullableAssetSubclassesSummary => {
    const subclassSummaries = summaries.filter(
        (summary) => summary.subclassName === subclass
    );
    return subclassSummaries && subclassSummaries.length > 0
        ? subclassSummaries[0]
        : null;
};

export const EMPTY_ALLOCATION = "--";

export const getTotalInvestibleAmount = (
    summary: NullableAssetSubclassesSummary,
): number | string => {
    return summary && summary.currentInvestableAmount
        ? formatCurrency(summary.currentInvestableAmount)
        : EMPTY_ALLOCATION;
};

export const getTotalInvestiblePercent = (
    summary: NullableAssetSubclassesSummary
): string => {
    return summary && summary.currentInvestablePercent
        ? formatNumberRoundedToTwoDecimals(summary.currentInvestablePercent) + "%"
        : EMPTY_ALLOCATION;
};

export const getTotalInvestiblePercentCurrent = (
    summary: NullableAssetSubclassesSummary,
    total: number
): string => {
    if(summary && summary.currentInvestablePercent){
        return formatNumberRoundedToTwoDecimals((summary.currentInvestableAmount / total) * 100) + "%";
    }else{
        return EMPTY_ALLOCATION;
    }
};

export const getTotalInvestiblePercentCurrentNoFormat = (
    summary: NullableAssetSubclassesSummary,
    total: number
): number => {
    if(summary && summary.currentInvestablePercent){
        return (summary.currentInvestableAmount / total) * 100;
    }else{
        return 0;
    }
};

export const getTotalProposedInvestibleAmount = (
    summary: NullableAssetSubclassesSummary,
): number | string => {
    return summary && summary.proposedInvestableAmount
        ? formatCurrency(summary.proposedInvestableAmount)
        : EMPTY_ALLOCATION;
};

export const getProposedInvestiblePercent = (
    summary: NullableAssetSubclassesSummary
): string => {
    return summary && summary.proposedInvestablePercent
        ? formatNumberRoundedToTwoDecimals(summary.proposedInvestablePercent) + "%"
        : EMPTY_ALLOCATION;
};

export const getTotalProposedInvestiblePercent = (
    summary: NullableAssetSubclassesSummary
): string => {
    return summary && summary.proposedInvestablePercentOfTotalPortfolio
        ? formatNumberRoundedToTwoDecimals(summary.proposedInvestablePercentOfTotalPortfolio) + "%"
        : EMPTY_ALLOCATION;
};

export const getTotalProposedInvestiblePercentNoFormat = (
    summary: NullableAssetSubclassesSummary
): number => {
    return summary && summary.proposedInvestablePercentOfTotalPortfolio
        ? summary.proposedInvestablePercentOfTotalPortfolio
        : 0;
};

export const getTotalInvestiblePercentForClassification = (
    totalInvestableAmount?: number, totalClassificationAmount?: number
): string => {
    if (totalInvestableAmount && totalClassificationAmount) {
        const totalInvestablePercentInClassification = (totalInvestableAmount / totalClassificationAmount) * 100;
        return formatNumberRoundedToTwoDecimals(totalInvestablePercentInClassification) + "%";
    } else {
        return EMPTY_ALLOCATION;
    }
};


export const getTotalInvestiblePercentForClassificationNoFormat = (
    totalInvestableAmount?: number, totalClassificationAmount?: number
): number => {
    if (totalInvestableAmount && totalClassificationAmount) {
        return (totalInvestableAmount / totalClassificationAmount) * 100;
    } else {
        return 0;
    }
};

export const getDifference = (
    current: NullableAssetSubclassesSummary,
    proposed: NullableAssetSubclassesSummary
): AllocationDifference => {
    if (!current?.currentInvestableAmount && !proposed?.proposedInvestableAmount) {
        return {
            differenceAmount: EMPTY_ALLOCATION,
            isNegative: false,
            differencePercentage: EMPTY_ALLOCATION,
        };
    }
    const currentAmount = current?.currentInvestableAmount
        ? current.currentInvestableAmount
        : 0;
    const proposedAmount = proposed?.proposedInvestableAmount
        ? proposed.proposedInvestableAmount
        : 0;
    const differenceAmt = proposedAmount - currentAmount;

    const currentPercent = current?.currentInvestablePercent
        ? current.currentInvestablePercent
        : 0;
    const proposedPercent = proposed?.proposedInvestablePercent
        ? proposed.proposedInvestablePercent
        : 0;
    const differencePercent = proposedPercent - currentPercent;

    return {
        differenceAmount: formatCurrency(differenceAmt),
        differencePercentage:
            formatNumberRoundedToTwoDecimals(differencePercent) + "%",
        isNegative: differenceAmt < 0,
    };
};

export const getDifferenceOfPortfolio = (
    current: NullableAssetSubclassesSummary,
    proposed: NullableAssetSubclassesSummary,
    proposedTotalInvestableAmount: number,
    currentTotalInvestableAmount: number
): AllocationDifference => {
    if (!current?.currentInvestableAmount && !proposed?.proposedInvestableAmount) {
        return {
            differenceAmount: EMPTY_ALLOCATION,
            isNegative: false,
            differencePercentage: EMPTY_ALLOCATION,
        };
    }
    const currentAmount = current?.currentInvestableAmount
        ? current.currentInvestableAmount
        : 0;
    const proposedAmount = proposed?.proposedInvestableAmount
        ? proposed.proposedInvestableAmount
        : 0;
    const differenceAmt = proposedAmount - currentAmount;

    const differencePercent = proposedTotalInvestableAmount - currentTotalInvestableAmount;

    return {
        differenceAmount: formatCurrency(differenceAmt),
        differencePercentage:
            formatNumberRoundedToTwoDecimals(differencePercent) + "%",
        isNegative: differenceAmt < 0,
    };
};

type NullableAssetSubclassesSummary = ReviseAssetSubclassesSummary | null;

export type AllocationDifference = {
    differenceAmount: string;
    differencePercentage: string;
    isNegative: boolean;
};

export const formatAllocationPercentage = (value: number) => {
    if (value === 0 || value === 100) {
        return formatNumberRoundedToWholeNumber(value) + "%";
    }
    return formatNumberRoundedToTwoDecimals(value) + "%";
};

function calculateAssetClassTotals(assetSubClassList: AssetSubclassSummary[] | any[],
                                   assetAllocation: 'current' | 'proposed') {
    return Object.values(assetSubClassList)
        .reduce((total, assetSubClass) => {
            const subClassValue = (assetSubClass[assetAllocation] ? assetSubClass[assetAllocation]['currentInvestableAmount'] : 0);
            return total + Math.round(subClassValue);
        }, 0);
}

function calculateAssetClassPercentTotals(assetSubClassList: AssetSubclassSummary[] | any[],
                                          assetAllocation: 'current' | 'proposed') {
    return Object.values(assetSubClassList)
        .reduce((total, assetSubClass) => {
            const subClassValue = (assetSubClass[assetAllocation] ? assetSubClass[assetAllocation]['currentInvestablePercent'] : 0);
            return total + Math.round(subClassValue * 100) / 100;
        }, 0);
}

function calculateProposedAssetClassTotals(assetSubClassList: AssetSubclassSummary[] | any[],
                                   assetAllocation: 'current' | 'proposed') {
    return Object.values(assetSubClassList)
        .reduce((total, assetSubClass) => {
            const subClassValue = (assetSubClass[assetAllocation] ? assetSubClass[assetAllocation]['proposedInvestableAmount'] : 0);
            return total + Math.round(subClassValue);
        }, 0);
}

function calculateProposedAssetClassPercentTotals(assetSubClassList: AssetSubclassSummary[] | any[],
                                          assetAllocation: 'current' | 'proposed') {
    return Object.values(assetSubClassList)
        .reduce((total, assetSubClass) => {
            const subClassValue = (assetSubClass[assetAllocation] ? assetSubClass[assetAllocation]['proposedInvestablePercent'] : 0);
            return total + Math.round(subClassValue * 100) / 100;
        }, 0);
}

export const sortAssetClassHierarchy = (assetSubclassSummaries: AssetSubclassSummary[]) => {
    const totalsForAssetClasses: {
        [key: string]: AssetSubclassSummary
    } = {};

    const level2AssetClassGrouping = assetSubclassSummaries.reduce((group: { [key: string]: AssetSubclassSummary[] }, item: AssetSubclassSummary) => {
        if (item['current'] && item['current']['assetClassName']) {
            if (!group[item['current']['assetClassName']]) {
                group[item['current']['assetClassName']] = [];
            }
            group[item['current']['assetClassName']].push(item);
        } else if (item['proposed'] && item['proposed']['assetClassName']) {
            if (!group[item['proposed']['assetClassName']]) {
                group[item['proposed']['assetClassName']] = [];
            }
            group[item['proposed']['assetClassName']].push(item);
        }
        return group;

    }, {});

    for (const key in level2AssetClassGrouping) {
        totalsForAssetClasses[key] = {
            subclass: "",
            current: {
                subclassName: key,
                currentInvestableAmount: calculateAssetClassTotals(level2AssetClassGrouping[key], 'current'),
                currentInvestablePercent: calculateAssetClassPercentTotals(level2AssetClassGrouping[key], 'current'),
                recommendedProposedTargetAmount:0,
                recommendedProposedTargetPercent:0,
                minimumProposedInvestableAmount:0,
                minimumProposedInvestablePercent:0,
                maximumProposedInvestableAmount:0,
                maximumProposedInvestablePercent:0,
                proposedInvestableAmount: calculateProposedAssetClassTotals(level2AssetClassGrouping[key], 'proposed'),
                proposedInvestablePercent: calculateProposedAssetClassPercentTotals(level2AssetClassGrouping[key], 'proposed'),
                proposedInvestablePercentOfTotalPortfolio:0,
                differenceAmount: 0,
                excludeSubclass: false,
                editedManually: false,
                excludedProposedAllocation: 0,
                isDisableAsset: false,
                readonly: true,
                lockedAmount: 0,
            },
            proposed: {
                subclassName: key,
                currentInvestableAmount: calculateAssetClassTotals(level2AssetClassGrouping[key], 'current'),
                currentInvestablePercent: calculateAssetClassPercentTotals(level2AssetClassGrouping[key], 'current'),
                recommendedProposedTargetAmount:0,
                recommendedProposedTargetPercent:0,
                minimumProposedInvestableAmount:0,
                minimumProposedInvestablePercent:0,
                maximumProposedInvestableAmount:0,
                maximumProposedInvestablePercent:0,
                proposedInvestableAmount: calculateProposedAssetClassTotals(level2AssetClassGrouping[key], 'proposed'),
                proposedInvestablePercent: calculateProposedAssetClassPercentTotals(level2AssetClassGrouping[key], 'proposed'),
                proposedInvestablePercentOfTotalPortfolio:0,
                differenceAmount: 0,
                excludeSubclass: false,
                editedManually: false,
                excludedProposedAllocation: 0,
                isDisableAsset: false,
                readonly: true,
                lockedAmount: 0,
            }
        }

    }

    return {
        level2AssetClassGrouping,
        totalsForAssetClasses
    }
}