import {Tab as XpsTab} from 'xps-react';
import React, {MouseEventHandler, ReactNode} from "react";

type TabProps = {
    name: string,
    onClick?: MouseEventHandler,
    className?: string,
    children?: ReactNode,
    active?: boolean,
    size?: 'small' | 'medium' | 'large',
    ariaControls?: string,
    id?:	string,
}

const Tab = ({name, onClick, className, children, active, ...rest}: TabProps) => {
    return <XpsTab name={name}
                   onClick={onClick}
                   className={className}
                   active={active}
                   {...rest}>{children}</XpsTab>;
}

export default Tab;
