interface RoundedPercentages {
    percentage1: number,
    percentage2: number
}

export function roundPercentagesToHundred(percentage1: number, percentage2: number): RoundedPercentages {
    if (canNotRoundToHundred(percentage1, percentage2) || alreadyRoundsToHundred(percentage1, percentage2)) {
        return {
            percentage1: Math.round(percentage1),
            percentage2: Math.round(percentage2),
        };
    }

    const [percentage1Rounded, percentage2Rounded] = roundUpLowest(percentage1, percentage2);
    return {
        percentage1: percentage1Rounded,
        percentage2: percentage2Rounded
    };
}

function canNotRoundToHundred(percentage1: number, percentage2: number) {
    return percentage1 + percentage2 !== 100;
}

function alreadyRoundsToHundred(percentage1: number, percentage2: number) {
    return Math.round(percentage1) + Math.round(percentage2) === 100;
}

function roundUpLowest(percentage1: number, percentage2: number) {
    return [percentage1, percentage2]
        .map((percentage, index) => ({
            originalIndex: index,
            value: percentage
        }))
        .sort(sortByKey("value"))
        .map((percentageWithIndex, index) => {
            const roundFunction = index === 0 ? Math.ceil : Math.floor;
            return {
                ...percentageWithIndex,
                value: roundFunction(percentageWithIndex.value)
            }
        })
        .sort(sortByKey("originalIndex"))
        .map(percentageWithIndex => percentageWithIndex.value);
}

function sortByKey<T>(key: keyof T) {
    return (a: T, b: T) => {
        if (a[key] > b[key]) {
            return 1;
        }
        if (a[key] < b[key]) {
            return -1;
        }
        return 0;
    };
}