import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {QuickSlidesPDF} from "../../components/QuickSlides/QuickSlidesPDF";
import {RouteWithIdAndMenuDetails} from "../../routes/types";
import {resourcesApiClient} from "../ResourcesApiClient";
import LoadingIndicator from "../../pages/LoadingIndicator";
import usePageViewTimer from "../../hooks/usePageViewTimer";

const AdvisorDocuments: React.FC = () => {
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const {id, menuTitle, subMenuLabel, documentLabel, currPage} = useParams<RouteWithIdAndMenuDetails>();
    const [documentUri, setDocumentUri] = useState<any>("");
    usePageViewTimer('Advice Library Page Load Timer (milliseconds)', isPageLoading);

    const getAdviceLibraryDoc = (): void => {
        resourcesApiClient.getAdviceLibraryDocument(menuTitle, documentLabel, subMenuLabel).then(response => {
            const blobUrl = URL.createObjectURL(response);
            setDocumentUri(blobUrl);
            setIsLoading(false);
        }).catch(error => {
            console.error("Failed to search Advice Library Document", error.message);
            setIsPageLoading(false);
            setDocumentUri('');
            setIsLoading(false);
        })
    }

    useEffect(() => {
        setIsLoading(true);
        getAdviceLibraryDoc();
    }, [documentLabel]);

    if (isLoading) {
        return <LoadingIndicator/>
    }

    return (
        <QuickSlidesPDF
            compId="life-table-quick-slides"
            isOpen={true}
            pdf={documentUri}
            redirectUrl={`/Profile/${id}/ClientProfile/Resources/${menuTitle}/${subMenuLabel}/${documentLabel}/`}
            currPage={+currPage}
            getDocument={getAdviceLibraryDoc}
            setIsPageLoading={setIsPageLoading}
        />
    );

}

export default AdvisorDocuments;
