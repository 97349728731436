import {EmptyStateContainer} from "../../../components";
import React from "react";

export type PartiallyOwnedInvestmentMessageState = '' | 'NoSearchResults';

export type PartiallyOwnedInvestmentMessageProps = {
    state: PartiallyOwnedInvestmentMessageState
};

export function PartiallyOwnedInvestmentMessage({state}: PartiallyOwnedInvestmentMessageProps) {
    const message = (() => {
        switch(state) {
            case '':
                return 'Search for an account by the full account number. No partial matches will be returned.';
            case 'NoSearchResults':
                return 'No matching accounts were found.';
            default:
                return ''
        }
    })();

    return (
        <EmptyStateContainer
            className="partially-owned-investment-message"
            title={message}
            size="large"
        />
    )
}