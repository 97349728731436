import React from "react";
import {truncateCurrency} from "../../utils/format";

interface AssetReliancePillProps {
    excessValue: number;
    position?: string;
    color: string;
}

const AssetReliancePill: React.FC<AssetReliancePillProps> = ({excessValue, color}) => {

    return (
        <div
            className={"asset-reliance-pill-container"}
            style={{
                color: color,
            }}>
            <span className="pill-text"> {(excessValue < 0 ? '–' : '') + truncateCurrency(Math.abs(excessValue))}</span>
        </div>
    )
}

export default AssetReliancePill;