import {formatCurrency} from "../../../utils/format";
import {TableActionDropdownMenu} from "../../../components/Table/TableActionDropdownMenu";
import {DropdownItem} from "../../../components";
import React from "react";
import {TableCell} from "../../../components/Table/TableCell";
import {DropdownGroup} from "xps-react";
import classNames from "classnames";
import {
    PartiallyOwnedInvestmentAccountReadModel,
    PartiallyOwnedLegalAgreement
} from "../../models/PartiallyOwnedInvestmentAccount";
import useProfileEditableState from "../../../hooks/useProfileEditableState";

type AssetSummaryPartiallyOwnedInvestmentAccountProps = {
    partiallyOwnedInvestmentAccount: PartiallyOwnedLegalAgreement;
    onClickEdit: (id: string) => void;
    onClickDelete: (partiallyOwnedInvestmentAccount: PartiallyOwnedLegalAgreement) => void;
    onClickViewHoldings: (id: string) => void;
    renderPartiallyOwnedInvestmentAccountDetails: (partiallyOwnedInvestmentAccount: PartiallyOwnedLegalAgreement) => React.ReactNode;
    actionsDisabled: boolean;
    gridClassName: string,
    hideActionMenu?: boolean
};
export const PartiallyOwnedInvestmentAccountRow = ({
                                                       partiallyOwnedInvestmentAccount,
                                                       onClickEdit,
                                                       onClickDelete,
                                                       onClickViewHoldings,
                                                       renderPartiallyOwnedInvestmentAccountDetails,
                                                       actionsDisabled,
                                                       gridClassName,
                                                       hideActionMenu = false
                                                   }: AssetSummaryPartiallyOwnedInvestmentAccountProps) => {
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    const presentValue = partiallyOwnedInvestmentAccount.marketEstateValue.inEstateValue;
    return (<div key={`partially-owned-investment-account-row-${partiallyOwnedInvestmentAccount.id}`}
                 role="row"
                 className={classNames("assets-grid-table-row", gridClassName)}
                 tabIndex={0}
                 aria-label={partiallyOwnedInvestmentAccount.legalAgreementName}>
        {renderPartiallyOwnedInvestmentAccountDetails(partiallyOwnedInvestmentAccount)}
        <TableCell text={formatCurrency(presentValue)} className="textalign-right"/>
        {!hideActionMenu &&
            <TableActionDropdownMenu
                ariaLabel={`${partiallyOwnedInvestmentAccount.legalAgreementName} Menu`}
                disabled={actionsDisabled}>
                <DropdownGroup groupName=' '>
                    <DropdownItem
                        itemText="Edit Account"
                        value="Edit Account"
                        onClick={() => onClickEdit(partiallyOwnedInvestmentAccount.id)}
                    />
                    <DropdownItem
                        itemText="Edit Holdings"
                        value="View Holdings"
                        onClick={() => onClickViewHoldings(partiallyOwnedInvestmentAccount.id)}
                    />
                </DropdownGroup>
                <DropdownGroup groupName=' '>
                    <DropdownItem
                        itemText="Remove Account"
                        value="Remove Account"
                        disabled={isProfileWithProposalsOrArchived}
                        onClick={() => onClickDelete(partiallyOwnedInvestmentAccount)}
                    />
                </DropdownGroup>
            </TableActionDropdownMenu>}
    </div>);

};
