import React from 'react';
import GoalBarChartSideBar from "./GoalBarChartSidebar";
import {LifestyleSpendingGoal} from "../models/LifestyleSpendingGoal";
import {formatCurrency} from "../../utils/format";

type TableRowProps = {
    leftText: string,
    rightText: string,
    isHeader: boolean,
}

const TableRow = (props: TableRowProps) => {

    const cellRole = props.isHeader ? "columnheader" : "cell";

    return <div className={"bar-chart-summary-lifestyle-present-value"} role="row">
        <div>
            <span data-testid={"lifestyle-goal-bar-chart-summary-text"} role={cellRole}>{props.leftText}</span>
        </div>
        <div data-testid={"lifestyle-goal-bar-chart-summary-present-value"} role={cellRole}>{props.rightText}</div>
    </div>
}

function LifestyleFormBarChartSideBar({lifestyleSpendingGoal}: { lifestyleSpendingGoal: LifestyleSpendingGoal }) {

    return <GoalBarChartSideBar lifestyleSpendingGoal={lifestyleSpendingGoal}>
        <div className="bar-chart-summary-table">
            <TableRow
                isHeader={true}
                leftText={"Lifestyle Spending"}
                rightText={`${formatCurrency(lifestyleSpendingGoal.calculatedFields.presentValue)}`}
            />
        </div>
    </GoalBarChartSideBar>
}

export default LifestyleFormBarChartSideBar;
