import React, {useMemo} from "react";
import {GoalModelType} from "../models/GoalModelType";
import {AccordionItem, DropdownItem, Icon, TableActionMenu} from "../../components";
import {formatCurrency} from "../../utils/format";
import {FamilyGoalType} from "../models/FamilyGoalType";
import BeneficiaryGoalsTable from "../components/BeneficiaryGoalsTable";
import {InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import {MemberType} from "../../ClientManagement/models/MemberType";
import FamilyGoalUtils from "../Summary/FamilyGoalUtils";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../controller/GoalsModelSlice";
import GoalsTypePopover from "../Family/GoalsTypePopover";
import {marginRight} from "html2canvas/dist/types/css/property-descriptors/margin";

type FamilyGoalsSummaryItemsProps = {
    familyGoals: GoalModelType['familyGoals'],
    investorGroup: InvestorGroupType,
    onEditGoal: (selectedGoalType: string, savedGoal: FamilyGoalType) => void,
    onViewGoal: (memberSelected: MemberType) => void,
    allFamilyGoalsForReport?: GoalModelType['familyGoals'],
    isReport?: boolean
};

const FamilyGoalsSummaryItems = ({
                                     familyGoals,
                                     investorGroup,
                                     onEditGoal,
                                     onViewGoal,
                                     allFamilyGoalsForReport,
                                     isReport=false
                                 }: FamilyGoalsSummaryItemsProps) => {

    const profile = useAppSelector(selectProfile)

    const sortedBeneficiariesGoals = [...Object.values(familyGoals)]
        .sort((bg1, bg2) =>
            Date.parse(bg1.birthdate!).valueOf() - Date.parse(bg2.birthdate!).valueOf());

    const memoizedBeneficiariesGoals = useMemo(() => {
        return sortedBeneficiariesGoals
            .map((beneficiaryGoal, index) => {

                const memberSelected: MemberType = FamilyGoalUtils.getBeneficiaryFromProfile(profile, beneficiaryGoal.beneficiaryId);

                const isAnyGoalFundedByNonInstAsst: boolean = Object.values(beneficiaryGoal.goals).some((goal: FamilyGoalType) => {
                    return goal.userInputs.isFundedByNonInvestableAssets
                })

                const beneficiaryGoalForReport = allFamilyGoalsForReport && Object.values(allFamilyGoalsForReport)
                    .find(allBeneficiaryGoal => allBeneficiaryGoal.beneficiaryId === beneficiaryGoal.beneficiaryId);

                const totalFamilyAssets = FamilyGoalUtils.getTotalFamilyAssets(beneficiaryGoal);
                const totalMyResponsibility = FamilyGoalUtils.getTotalMyResponsibility(beneficiaryGoalForReport || beneficiaryGoal);
                const totalPresentValue = FamilyGoalUtils.getTotalGoalsPresentValue(beneficiaryGoal);

                return (
                    <ul key={`FamilyGoalAccordionItem${index}`}>
                        <AccordionItem
                            uuid={`FamilyGoalAccordionItem${index}`}
                            className="borderstyle-none"
                            HeaderComponent={({expanded}: { expanded: boolean }) => (
                                <div className="family-grid-table family-goal-table"
                                     data-testid={`beneficiary-summary-row-${index}`}>
                                    <span className="paddingleft-md">
                                        <div role="cell">
                                            <div className="cell-with-popover">
                                      <Icon name={expanded ? 'chevron_down' : 'chevron_right'}
                                            size="large"/>
                                        <span className="cell-text-content"> {beneficiaryGoal.name}</span>
                                                <span className="beneficiary-age">, {beneficiaryGoal.age}</span>
                                                {!expanded && (isAnyGoalFundedByNonInstAsst || totalFamilyAssets > 0) &&
                                                    <GoalsTypePopover
                                                        beneficiaryId={beneficiaryGoal.beneficiaryId}
                                                        totalPresentValue={totalPresentValue}
                                                        appliedFamilyAssets={totalFamilyAssets}
                                                        myResponsibility={totalMyResponsibility}
                                                        isFundedByNonInvestableAssets={isAnyGoalFundedByNonInstAsst}
                                                        hasOneOrMoreGoals={true}
                                                    />
                                                }
                                            </div>
                                    </div>
                                    </span>
                                    <span className="textalign-right" style={{marginRight: -10}}>
                                                        {formatCurrency(totalMyResponsibility)}
                                                </span>
                                    <TableActionMenu className="beneficiaryGoalsActionMenu" panelWidth={240}>
                                        <DropdownItem
                                            onClick={() => onViewGoal(memberSelected)}
                                            itemText={`View Family Member Goals`}
                                            value={`View Family Member Goals`}
                                        />
                                    </TableActionMenu>
                                </div>
                            )}
                        >

                            <BeneficiaryGoalsTable
                                beneficiaryGoals={Object.values(beneficiaryGoal.goals)}
                                investorGroup={investorGroup}
                                onEditGoal={onEditGoal}
                                showApplyFamilyAssets={false}
                                isReport={isReport}
                            />
                        </AccordionItem>
                    </ul>
                );
            });
    }, [familyGoals, investorGroup]);
    return <div className="family-summary-accordions" data-testid='family-summary-table-parent-div'>
        {memoizedBeneficiariesGoals}
    </div>
}

export default FamilyGoalsSummaryItems;
