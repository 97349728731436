import React, {useContext, useEffect, useState} from "react";
import PersonalAssetForm from "./PersonalAssetForm";
import {
    CreatePersonalAssetRequest,
    PersonalAssetDetailsType,
    PersonalLiabilityType,
    UnsavedPersonalAsset
} from "../models/PersonalAsset";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {assetsApiClient} from "../AssetsApiClient";
import {useHistory} from "react-router-dom";
import AddFormContainer from "../AddFormContainer";
import {getDefaultOwnershipDetails} from "../formHelpers";
import {MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {LegalEntityFormData} from "../models/Ownership";
import AssetsViewContext from "../common/AssetsViewContext";

const AddPersonalAsset = () => {
    const profile = useAppSelector(selectProfile);
    const viewType = useContext(AssetsViewContext);
    const history = useHistory();

    const defaultPersonalAssetDetails: PersonalAssetDetailsType = {
        type: "Primary Home",
        description: "Primary Home",
        presentValue: 0,
    };

    const personalLiability: PersonalLiabilityType = {
        personalLiabilities: [
            {
                description: " ",
                loanBalance: 0
            }
        ]
    };

    const [memberGroup, setMemberGroup] = useState<MemberGroup>();
    const [legalEntities, updateLegalEntities] = useState<LegalEntityFormData[]>();

    useEffect(() => {
        Promise.all([clientManagementApiClient.getMemberGroup(profile.id),
            assetsApiClient.getLegalEntities(profile.id)])
            .then(([memberGroupResponse, legalEntitiesResponse]) => {
                setMemberGroup(memberGroupResponse);
                updateLegalEntities(legalEntitiesResponse);
            }).catch(error => console.error('Could not fetch personal asset data', error.message));
    }, [])

    const defaultOwnershipDetails = getDefaultOwnershipDetails(profile.primaryContact.id!);

    const handleSave = async (unsavedData: UnsavedPersonalAsset) => {
        const personalAsset: CreatePersonalAssetRequest = {
            ...unsavedData,
            profileId: profile.id,
        };
        const response = await assetsApiClient.postPersonalAsset(personalAsset);
        if (response.status === 201) {
            history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
        }
    }

    return <div className='personal-asset'>
        <AddFormContainer
            modalTitle="Asset"
            form={(handleCancel) => (
                <PersonalAssetForm
                    formatTitle={() => 'Add Personal Asset'}
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                    defaultPersonalAsset={defaultPersonalAssetDetails}
                    defaultOwnershipDetails={defaultOwnershipDetails}
                    memberGroup={memberGroup!}
                    legalEntities={legalEntities!}
                    updateLegalEntities={updateLegalEntities}
                    personalLiability={personalLiability}
                />
            )}
        />
    </div>
}

export default AddPersonalAsset;
