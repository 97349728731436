import React from "react";
import {useAppSelector} from "../../store/hooks";

import FutureEstateValueCard from "./FutureEstateValueCard";
import SummaryCard from "./SummaryCard";
import ImpactCard from "./ImpactCard";
import {selectEstimatedImpactSummary} from "../WealthPlanOptimizerSlice";

const StrategySummarySection: React.FC = () => {
    const estimatedImpactSummary = useAppSelector(selectEstimatedImpactSummary);

    return (
        <section className={"estimated-impact-summary-section"}>
            <FutureEstateValueCard/>
            <div className="summary-container">
                <SummaryCard/>
                <div className="impact-section">
                    <ImpactCard impactHeaderText={"Amount to Beneficiaries"}
                                impactValue={
                                    estimatedImpactSummary.onlyStateOfResidencyStrategySelected
                                        ? null
                                        : estimatedImpactSummary.totalImpactToAmountToBeneficiaries
                                }
                                impactTypeClass={"amount-to-beneficiaries"}/>

                    <ImpactCard impactHeaderText={"Est. Estate Tax"}
                                impactValue={estimatedImpactSummary.totalImpactToEstEstateTax}
                                impactTypeClass={"estate-tax-savings"}/>
                </div>
            </div>
        </section>
    );
}

export default StrategySummarySection;