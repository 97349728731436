import {
    CreatePersonalLiabilityRequest,
    PersonalLiability,
    PersonalLiabilityFormData
} from "../models/PersonalLiability";
import {mapToOwnershipDetailsFormData, mapToOwnershipWriteModel} from "../Ownership/mappers";

export function mapPersonalLiabilityToFormData(personalLiability: PersonalLiability) {
    const {
        profileId,
        interestRate,
        ownershipCategory,
        memberOwnerships,
        legalEntityOwnerships,
        loanBalanceEstateValue,
        ordinal,
        ...rest
    } = personalLiability;

    const formData: PersonalLiabilityFormData = {
        ...rest,
        ...mapToOwnershipDetailsFormData(personalLiability),
        interestRate: personalLiability.interestRate.toString()
    };

    return formData;
}

export function mapFormDataToCreatePersonalLiabilityRequest(formData: PersonalLiabilityFormData, profileId: string) {
    const {
        soleOwnerType,
        memberOwnerships,
        nonClientMemberOwnerships,
        legalEntityOwnerships,
        ...rest
    } = formData;

    const request: CreatePersonalLiabilityRequest = {
        ...rest,
        ...mapToOwnershipWriteModel(formData),
        profileId,
        interestRate: parseFloat(formData.interestRate)
    };

    return request;
}