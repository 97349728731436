import React, {useEffect, useRef, useState} from "react";
import {Location} from "history";
import {useHistory} from "react-router-dom";
import CustomModal from "../../components/Modal/Custom/CustomModal";
import {
    selectPortfolioReserveModel,
    selectPortfolioReserveUserPreferences,
    setPortfolioReserveUserPreferences,
    setPortfolioReserveYears
} from "./PortfolioReserveSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import useProfileEditableState from "../../hooks/useProfileEditableState";

interface EditPortfolioReserveTableWarningProps {
    editedReserveLength: boolean,
    editedFBNI: boolean
}

const EditPortfolioReserveTableWarningModal = ({editedReserveLength, editedFBNI}: EditPortfolioReserveTableWarningProps) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const unblockRef = useRef();
    const [showModal, setShowModal] = useState(false);
    const [navLocation, setNavLocation] = useState<Location | null>(null);
    const portfolioReserveUserPreferences = useAppSelector(selectPortfolioReserveUserPreferences)
    const portfolioReserveModel = useAppSelector(selectPortfolioReserveModel)
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();
    useEffect(() => {
        unblockRef.current = history.block((newLocation: Location) => {
            // @ts-ignore
            const forceNavigate = newLocation.state?.forceNavigate ? newLocation.state.forceNavigate : false
            if ((editedReserveLength || editedFBNI) && !forceNavigate) {
                setNavLocation(newLocation);
                if(!isProfileWithProposalsOrArchived) {
                    setShowModal(true);
                }else{
                    navigateAway(newLocation);
                }
                return false;
            } else if (forceNavigate) {
                return true;
            }
            return true;
        });
        return () => {
            // @ts-ignore -- this ref is callable
            unblockRef.current && unblockRef.current();
        };
    }, [editedReserveLength, editedFBNI]);

    function navigateAway(newLocation: Location | null = navLocation) {
        if (unblockRef && unblockRef.current) {
            // @ts-ignore TS2349 -- this ref is callable
            unblockRef.current();
        }
        setShowModal(false);
        history.push(newLocation);
    }

    const displayWarningMessage = (
        <>
            <div>
                If you leave this page, any changes you have made will not be saved.
            </div>
        </>
    )

    return <CustomModal
        isOpen={showModal}
        title={"Discard Changes?"}
        content={displayWarningMessage}
        onClickCancel={() => {
            setShowModal(false)
            dispatch(setPortfolioReserveUserPreferences({
                ...portfolioReserveUserPreferences,
                navigatedBeforeSavingPortfolioReserve: true
            }))
        }}
        onClickConfirm={() => {
            dispatch(setPortfolioReserveYears({
                ...portfolioReserveUserPreferences,
                prTargetInputYears: portfolioReserveModel.profile.portfolioReserveTargetLength != null ? portfolioReserveModel.profile.portfolioReserveTargetLength : portfolioReserveModel.portfolioReserve.initialPortfolioReserveTargetLength,
                minimumRiskInputYears: portfolioReserveModel.portfolioReserve.minimumRiskReserveLength
            }));
            navigateAway()
        }}
        cancelText={'KEEP EDITING'}
        confirmText={'DISCARD CHANGES'}/>
}

export default EditPortfolioReserveTableWarningModal;