import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from '../../store/store';
import {emptyProfileResponse, ProfileResponse} from 'src/ClientManagement/models/ProfileResponse';

export type ProfileState = ProfileResponse;

const initialState: ProfileState = {...emptyProfileResponse}

// the approved profile represents the client's actual profile that has been put into effect by NT's partners.
// The clientProfileSlice represent the profile that the spa is currently viewing, whether it is the approved profile or a proposal.
export const approvedProfileSlice = createSlice({
    name: 'approvedProfile',
    initialState,
    reducers: {
        patchApprovedProfile: (state, action: PayloadAction<Partial<ProfileResponse>>): ProfileResponse => {
            return {
                ...state,
                ...action.payload
            }
        },
        // reducers need to return the new state, given the existing state and the action. In this case, the new state is
        // just the payload of the action; in other cases, you can mutate the state object, and changes to that state will
        // be recorded to create the new state.
        setApprovedProfile: (_state, action: PayloadAction<ProfileResponse>) => action.payload,
    }
});

export const {
    patchApprovedProfile,
    setApprovedProfile
} = approvedProfileSlice.actions;

export const selectApprovedProfile = (state: RootState) => state.client.approvedProfile;
export const selectDisplayName = (state: RootState) => state.client.approvedProfile?.displayName;

export default approvedProfileSlice.reducer;
