type AddFormContainerProps = {
    title: string,
    subheader?: string
}

export default function AddFormContainer({title, subheader}: AddFormContainerProps) {
    return (
        <div className="add-form-header">
            {subheader ? <span>{subheader}</span> : <></>}
            <h3>{title}</h3>
        </div>
    )
}