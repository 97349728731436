import React, {useEffect, useReducer} from 'react';
import {MeetingContainerSchema} from "./types/RelayContext";
import {
    RegisterMutatorFunction,
    RegisterValueChangeAction,
    RelayEventHook,
    ValueChangeHandlers
} from "./types/RelayEventHook";

type SharedMapValueChanged = {
    key: string;
    previousValue: unknown;
}

type RelayEventManagerProps = {
    sharedObjects: MeetingContainerSchema | null,
    registerSharedObjectMutator: RegisterMutatorFunction,
    relayEventHooks: RelayEventHook<any>[],
};

export const RelayEventManager: React.FC<RelayEventManagerProps> = (
    {
        sharedObjects,
        registerSharedObjectMutator,
        relayEventHooks = [],
    }
) => {
    // Skip setup if relay connection has not established any data
    if (!sharedObjects) {
        return <></>;
    }

    // Otherwise setup the relay event handler with valueChangeHandlers for each shared object
    const [valueChangeHandlers, dispatchValueChangeHandlers] = useReducer(
        valueChangeHandlersReducer,
        (Object.keys(sharedObjects) as Array<keyof MeetingContainerSchema>)
            .reduce((previous, current) => {
                previous[current] = {};
                return previous;
            }, {} as ValueChangeHandlers),
    );

    // Register relay event hooks
    for (let relayEventHook of relayEventHooks) {
        relayEventHook(sharedObjects, registerSharedObjectMutator, dispatchValueChangeHandlers);
    }

    useEffect(() => {
        const sharedMapNames = Object.keys(valueChangeHandlers) as Array<keyof typeof valueChangeHandlers>;
        const sharedMapChangeHandlers = sharedMapNames.map((sharedMapName) => {
            const sharedMap = sharedObjects[sharedMapName];
            const onMapValueChanged = (valueChanged: SharedMapValueChanged, isLocal: boolean) => {
                const valueChangeHandler = valueChangeHandlers[sharedMapName][valueChanged.key];
                if (valueChangeHandler) {
                    valueChangeHandler(sharedMap.get(valueChanged.key), isLocal);
                }
            };
            sharedMap.on("valueChanged", onMapValueChanged);
            return {sharedMap, onMapValueChanged};
        });

        return () => {
            sharedMapChangeHandlers.forEach(({sharedMap, onMapValueChanged}) => {
                sharedMap.off("valueChanged", onMapValueChanged);
            });
        };
    }, [sharedObjects, valueChangeHandlers]);

    return <></>;
};

const valueChangeHandlersReducer = (
    state: ValueChangeHandlers,
    action: RegisterValueChangeAction
) => ({
    ...state,
    [action.sharedMapName]: {
        ...state[action.sharedMapName],
        [action.key]: action.callback,
    }
});