import {LifeStatus} from "./MemberType";

export type PlanningPeriodType = {
    numberOfYears: number,
    memberType: InvestorGroupMemberType,
    ageFrom: number,
    startYear: number,
}

export enum InvestorGroupMemberType {
    PRIMARY = "PRIMARY",
    PARTNER = "PARTNER",
}

export type InvestorGroupType = {
    primaryMember: InvestorGroupMember,
    partnerMember?: InvestorGroupMember,
    planningPeriod: PlanningPeriodType
}

export type Member = {
    id: string,
    name: string,
}

export type MemberGroupMember = {
    id: string,
    firstName: string,
    lastName: string,
    age: number,
    relationshipType: string | null,
    lifeStatus: LifeStatus,
    deceasedDate: string | null
};

export interface MemberGroup {
    primaryMember: MemberGroupMember,
    partnerMember: MemberGroupMember | null,
    additionalMembers: MemberGroupMember[]
}

export type InvestorGroupMember = Member & {
    firstName: string,
    birthdate: string,
    planningPeriod?: PlanningPeriodType
}

export const emptyInvestorGroup: InvestorGroupType = {
    primaryMember: {
        id: "",
        firstName: "",
        name: "",
        birthdate: "",
        planningPeriod: {
            numberOfYears: 0,
            memberType: InvestorGroupMemberType.PRIMARY,
            ageFrom: 0,
            startYear: 0,
        }
    },
    partnerMember: {
        id: "",
        firstName: "",
        name: "",
        birthdate: "",
        planningPeriod: {
            numberOfYears: 0,
            memberType: InvestorGroupMemberType.PARTNER,
            ageFrom: 0,
            startYear: 0,
        }
    },
    planningPeriod: {
        numberOfYears: 0,
        memberType: InvestorGroupMemberType.PRIMARY,
        ageFrom: 0,
        startYear: 0,
    }
}
