import {RadioYesNoOptional} from "./models/Common";
import {OwnershipDetailsFormData} from "./models/Ownership";
import {Accounts} from "./models/Assets";
import {InvestmentProgram} from "./models/InvestmentProgram";
import {
    PartiallyOwnedInvestmentAccountReadModel,
    PartiallyOwnedLegalAgreement
} from "./models/PartiallyOwnedInvestmentAccount";

export function booleanToRadioButton(value: boolean | null): RadioYesNoOptional {
    switch (value) {
        case true:
            return 'Yes';
        case false:
            return 'No';
        default:
            return value;
    }
}

export function radioButtonToBoolean(value: RadioYesNoOptional) {
    return value === "Yes";
}

export function getDefaultOwnershipDetails(primaryMemberId: string): OwnershipDetailsFormData {
    return {
        ownershipCategory: "Sole",
        soleOwnerType: "Individual",
        memberOwnerships: [{
            memberId: primaryMemberId,
            percentage: "100",
            isRevocableTrust: false,
        }],
        legalEntityOwnerships: [],
        nonClientMemberOwnerships: [],
    };
}

export function getTaxableLiabilityPaidByPortfolioForTaxableAccounts(accounts: Accounts,
                                                                     investmentPrograms: InvestmentProgram|null,
                                                                     partiallyOwnedInvestmentAccounts: PartiallyOwnedLegalAgreement[]) {

    const taxableAccounts = accounts.data.filter(account => account.taxStatus === "Taxable");
    if (taxableAccounts.length > 0) {
        return taxableAccounts[0].isLiabilityPaidByPortfolio;
    }

    if(investmentPrograms) {
        const taxableInvestmentPrograms = investmentPrograms.legalAgreements.filter(legalAgreement => legalAgreement.taxStatus === "Taxable");
        if (taxableInvestmentPrograms.length > 0) {
            return taxableInvestmentPrograms[0].isLiabilityPaidByPortfolio;
        }
    }

    const taxablePOIA = partiallyOwnedInvestmentAccounts.filter(poia => poia.taxStatus === "Taxable");
    if(taxablePOIA.length > 0 ){
        return taxablePOIA[0].isLiabilityPaidByPortfolio;
    }
    return false;
}


export function getTaxableLiabilityPaidByPortfolioForDeferredAccounts(accounts: Accounts,
                                                                     investmentProgram: InvestmentProgram|null,
                                                                     partiallyOwnedInvestmentAccounts: PartiallyOwnedLegalAgreement[]) {

    const deferredStandaloneAccounts = accounts.data.filter(account => account.taxStatus === "Deferred");
    if (deferredStandaloneAccounts.length > 0) {
        return deferredStandaloneAccounts[0].isLiabilityPaidByPortfolio;
    }

    if(investmentProgram) {
        const deferredInvestmentProgram = investmentProgram.legalAgreements.filter(legalAgreement => legalAgreement.taxStatus === "Deferred");
        if (deferredInvestmentProgram.length > 0) {
            return deferredInvestmentProgram[0].isLiabilityPaidByPortfolio;
        }
    }

    const deferredPOIA = partiallyOwnedInvestmentAccounts.filter(poia => poia.taxStatus === "Deferred");
    if(deferredPOIA.length > 0 ){
        return deferredPOIA[0].isLiabilityPaidByPortfolio;
    }

    return true;
}

