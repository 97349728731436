import React, {useEffect, useState} from "react";
import useProfileAndProposals from "../hooks/useProfileAndProposals";
import {AssetsSummary, AssetTotals} from "../Assets/models/Assets";
import {assetsApiClient} from "../Assets/AssetsApiClient";

export const useAssetTitling = (id: string) => {
    const {profile: approvedProfile} = useProfileAndProposals(id);
    const [assets, setAssets] = useState<AssetsSummary>();
    const [assetsTotal, setAssetsTotal] = useState<AssetTotals>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            assetsApiClient.getAssetsSummary(approvedProfile.id),
            assetsApiClient.getAssetTotals(approvedProfile.id)
        ]).then(([assetSummaryResponse, assetsTotalsResponse]) => {
            setAssets(assetSummaryResponse);
            setAssetsTotal(assetsTotalsResponse);
        }).finally(() => setIsLoading(false));
    }, [approvedProfile]);

    return {
        assets,
        assetsTotal,
        isLoading,
        approvedProfile
    }
}