import {EstateValue, HoldingAssetSubclassDetails, HoldingType} from "../../models/Holding";
import {useEffect, useState} from "react";
import {Button, Input} from "../../../components";
import {EditableHoldingFields, EditHoldingValues} from "./EditHoldingValues";
import {shortenString} from "../../common/textUtils";

export type EditProductsSubclassDetailsRowProps = {
    assetSubclassDetail: HoldingAssetSubclassDetails,
    onDelete: (index: number) => void,
    onUpdate: (value: number | string | null | undefined | EstateValue,
               index: number,
               valueType: EditableHoldingFields) => void,
    index: number,
    isProductNameInvalid?: boolean,
    inEstateOwnershipPercentage: number
    focusOnField?: EditableHoldingFields
};

export function EditProductsSubclassDetailsRow({
                                                   assetSubclassDetail,
                                                   onDelete,
                                                   onUpdate,
                                                   index,
                                                   isProductNameInvalid,
                                                   inEstateOwnershipPercentage,
                                                   focusOnField,
                                               }: EditProductsSubclassDetailsRowProps) {

    const [ticker, setTicker] = useState<string | undefined>(assetSubclassDetail.ticker);
    const [productName, setProductName] = useState<string | undefined>(assetSubclassDetail.productName);
    const [cusip, setCusip] = useState<string | undefined>(assetSubclassDetail.cusip);
    const label = `${index} - ${assetSubclassDetail.productName}`;
    const [shouldFocus, setShouldFocus] = useState<boolean>(
        focusOnField === 'ticker' || focusOnField === 'productName' || focusOnField === 'cusip'
    );

    useEffect(() => {
        setTicker(assetSubclassDetail.ticker);
        setProductName(assetSubclassDetail.productName)
        setCusip(assetSubclassDetail.cusip);
    }, [assetSubclassDetail])

    useEffect(() => {
        if(shouldFocus) {
            const newlyAddedElement = document.getElementById(label);

            if(newlyAddedElement) {
                newlyAddedElement.focus();
            }
        }
    })

    const renderInputOrText = (labelType: string,
                               text: string | undefined,
                               state: string | undefined,
                               setState: (value: string | undefined) => void,
                               isInvalid: boolean = false,
                               setFocus: boolean = false
    ) => {
        const error = isInvalid ? ' ' : undefined;
        const focusId = setFocus ? label : undefined;

        return <>
            {assetSubclassDetail.type === HoldingType.CustomProduct ?
                <Input
                    id={focusId}
                    aria-label={`${label} - ${labelType}`}
                    value={state}
                    onChange={(event) => {
                        setState(event.target.value);
                    }}
                    onBlur={() => setShouldFocus(false)}
                    size="small"
                    error={error}
                    forceShowErrors={isInvalid}
                /> : text}
        </>
    }

    const handleUpdateTicker = (value: string | undefined) => {
        onUpdate(value, index, "ticker");
        setTicker(value);
    };
    const handleUpdateProductName = (value: string | undefined) => {
        onUpdate(value, index, "productName");
        setProductName(value);
    };
    const handleUpdateCusip = (value: string | undefined) => {
        onUpdate(value, index, "cusip");
        setCusip(value);
    };

    return (
        <div role="row"
             aria-label={assetSubclassDetail.productName}
             className="grid-display-contents holding-subclass-detail-row"
             key={`asset-subclass-details-${index}`}>
            <span className="line"/>
            <span role="cell"
                  aria-label={`${assetSubclassDetail.assetSubclassDetailsName}`}
                  className="holding-subclass-detail-name">{assetSubclassDetail.assetSubclassDetailsName}</span>
            <span role="cell"
                  className="ticker-name"
                  aria-label={`Ticker - ${label}`}>
                {renderInputOrText(
                    "Ticker",
                    shortenString(assetSubclassDetail.ticker, 6),
                    ticker,
                    handleUpdateTicker,
                    false,
                    focusOnField === 'ticker')}
            </span>
            <span role="cell"
                  className="product-name"
                  aria-label={`Product - ${label}`}>
                {renderInputOrText(
                    "Product",
                    assetSubclassDetail.productName,
                    productName,
                    handleUpdateProductName,
                    isProductNameInvalid,
                    focusOnField === 'productName')}
            </span>
            <span role="cell"
                  className="cusip-name"
                  aria-label={`Cusip - ${label}`}>
                {renderInputOrText(
                    "Cusip",
                    assetSubclassDetail.cusip,
                    cusip,
                    handleUpdateCusip,
                    false,
                    focusOnField === 'cusip')}
            </span>
            <EditHoldingValues
                holdingWithIndex={{holding: assetSubclassDetail, index}}
                label={label}
                onUpdate={onUpdate}
                inEstateOwnershipPercentage={inEstateOwnershipPercentage}
                focusOnField={focusOnField}
            />
            <span>
                <Button
                    onClick={() => onDelete(index)}
                    icon="only"
                    iconName="clear"
                    type="button"
                    kind="borderless"
                    className="delete-holding-button"
                    ariaLabel="delete holding"
                />
            </span>
        </div>
    );
}