import React, {useContext, useEffect, useState} from "react";

import AddFormContainer from "../AddFormContainer";
import {SocialSecurityForm} from "./SocialSecurityForm";
import {
    SocialSecurity,
    SocialSecurityBenefits,
    SocialSecurityDetails,
    UnsavedSocialSecurity
} from "../models/SocialSecurity";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {useHistory} from "react-router-dom";
import {assetsApiClient} from "../AssetsApiClient";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {NO_OP} from "../../constants/common";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {InvestorGroupType, MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import {mapSoleMemberOwnershipFormData} from "../Ownership/mappers";
import AssetsViewContext from "../common/AssetsViewContext";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";


const AddSocialSecurity: React.FC = () => {
    const history = useHistory();
    const profile = useAppSelector(selectProfile);
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const viewType = useContext(AssetsViewContext);
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType>();
    const [existingSocialSecurities, setExistingSocialSecurities] = useState<SocialSecurity[]>([]);
    const [defaultSocialSecurityBenefits, setDefaultSocialSecurityBenefits] = useState<SocialSecurityBenefits>();
    const [memberGroup, setMemberGroup] = useState<MemberGroup>();

    useEffect(() => {
        let componentUnmounted = false;
        const memberId = getMemberIdWithoutSocialSecurity();
        if (memberId) {
            assetsApiClient.evaluateSocialSecurity(profile.id, memberId, {
                inflowReserveLength: null,
                isInflowWillFundLifestyleGoal: true
            })
                .then(socialSecurityBenefitsResponse => {
                    if (componentUnmounted) return;
                    setDefaultSocialSecurityBenefits(socialSecurityBenefitsResponse);
                })
        }
        return () => {
            componentUnmounted = true;
        }
    }, [investorGroup, existingSocialSecurities])

    useEffect(() => {
        let componentUnmounted = false;

        Promise.all([
            clientManagementApiClient.getInvestorGroup(profile.id),
            assetsApiClient.getSocialSecuritiesByProfileId(profile.id),
            clientManagementApiClient.getMemberGroup(profile.id)
        ]).then(([ investorGroupResponse, socialSecuritiesResponse, memberGroupResponse]) => {
            if (componentUnmounted) return;
            setInvestorGroup(investorGroupResponse);
            setExistingSocialSecurities(socialSecuritiesResponse);
            setMemberGroup(memberGroupResponse);
        }).catch(error => console.error('Could not fetch asset details', error.message));

        return () => {
            componentUnmounted = true;
        }
    }, [profile.id]);

    if (!investorGroup || !existingSocialSecurities || !defaultSocialSecurityBenefits || !memberGroup) {
        return <LoadingIndicator/>;
    }

    function getMemberIdWithoutSocialSecurity() {
        if (investorGroup && (existingSocialSecurities && existingSocialSecurities.length > 0)) {
            if (investorGroup.primaryMember && !hasSocialSecurity(investorGroup.primaryMember.id)) {
                return investorGroup.primaryMember.id;
            }

            if (investorGroup.partnerMember && !hasSocialSecurity(investorGroup.partnerMember.id)) {
                return investorGroup.partnerMember.id;
            }
        }
        return profile.primaryContact.id;
    }

    function hasSocialSecurity(ownerId: string) {
        return existingSocialSecurities.filter(socialSecurity => socialSecurity.memberOwnership.member.id === ownerId).length > 0;
    }

    const handleSave = async (unsavedData: UnsavedSocialSecurity) => {
        const response = await assetsApiClient.postSocialSecurity({
            ...unsavedData,
            profileId: profile.id
        });
        if (response.status === 201) {
            history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
        }
    }

    const initialSocialSecurity: SocialSecurityDetails = {
        memberOwnership: mapSoleMemberOwnershipFormData(getMemberIdWithoutSocialSecurity()!),
        startAge: defaultSocialSecurityBenefits.startAge,
        grossAnnualFlow: defaultSocialSecurityBenefits.grossAnnualFlow,
        netAnnualFlow: defaultSocialSecurityBenefits.netAnnualFlow,
        yearsUntilFlow: defaultSocialSecurityBenefits.yearsUntilFlow,
        yearsOfFlow: defaultSocialSecurityBenefits.yearsOfFlow,
        presentValue: defaultSocialSecurityBenefits.presentValue,
        taxRate: defaultSocialSecurityBenefits.taxRate,
        interestRate: defaultSocialSecurityBenefits.interestRate,
        isInflowWillFundLifestyleGoal: defaultSocialSecurityBenefits.isInflowWillFundLifestyleGoal,
        lifestyleGoalAligned: defaultSocialSecurityBenefits.lifestyleGoalAligned,
        excessFutureInflow: defaultSocialSecurityBenefits.excessFutureInflow,
        inflowReserveLength: defaultSocialSecurityBenefits.inflowReserveLength,
        inflowReservePresentValue: defaultSocialSecurityBenefits.inflowReservePresentValue,
    };

    return (
        <div className="social-security">
            <AddFormContainer
                modalTitle="Asset"
                form={(handleCancel) => {
                    return (
                        <SocialSecurityForm
                            formatTitle={() => "Add Social Security"}
                            handleSave={handleSave}
                            handleCancel={handleCancel}
                            initialSocialSecurity={initialSocialSecurity}
                            updateOwnerName={NO_OP}
                            investorGroup={investorGroup}
                            existingSocialSecurities={existingSocialSecurities}
                            memberGroup={memberGroup}
                            releaseToggles={releaseToggles!}
                        />
                    );
                }}
            />
        </div>
    )
}
export default AddSocialSecurity;
