
export enum InflowTimeframeFieldType {
    YearsOfFlow = "YearsOfFlow",
    YearsUntilFlow = "YearsUntilFlow",
    InflowYearsFrom = "InflowYearsFrom",
    InflowYearsTo = "InflowyearsTo",
    OwnersAgeRangeFrom = "OwnersAgeRangeFrom",
    OwnersAgeRangeTo = "OwnersAgeRangeTo",
}

export type ClampingError = {
    showMessage: boolean,
    inflowTimeframeField?: InflowTimeframeFieldType
}
