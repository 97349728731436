import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import type {RootState} from "../../store/store";
import {ProfileResponse} from "src/ClientManagement/models/ProfileResponse";

// A proposal is a copy or draft of a profile. Therefore, a proposal is an unapproved profile.
// This is why we are using the type ProfileResponse
export type ProfilesState = ProfileResponse[];

// initialState can be a value or a function. In this case, it's a function because `null` doesn't have enough type
// information by itself to say that it can also be of type ProfileResponse.
function initialState(): ProfilesState {
    return [];
}

export const proposalsSlice = createSlice({
    name: "proposals",
    initialState,
    reducers: {
        patchProposals: (state, action: PayloadAction<Partial<ProfileResponse>>): ProfilesState => {
            return state.map(proposal => {
                return {
                    ...proposal,
                    ...action.payload
                }
            });
        },
        // reducers need to return the new state, given the existing state and the action. In this case, the new state is
        // just the payload of the action; in other cases, you can mutate the state object, and changes to that state will
        // be recorded to create the new state.
        setProposals: (_state, action: PayloadAction<Array<ProfileResponse>>) => action.payload,
    },
});

export const {
    patchProposals,
    setProposals
} = proposalsSlice.actions;

export const selectProposals = (state: RootState) => state.client.proposals;

export default proposalsSlice.reducer;
