import {FamilyRelationshipType} from "../models/FamilyRelationshipType";
import {MemberType} from "../models/MemberType";
import {
    findAllPartnersForMemberId,
    findExtendedParents,
    findPrimaryContactPartners
} from "../FamilyRelationshipService";
import {FamilyRelationship} from "../models/FamilyRelationship";
import {PrimaryContact} from "../models/PrimaryContact";

export const displayedRelationshipTypes = {
    PARENT: "Parent",
    CHILD: "Child",
    SPOUSE: "Spouse",
    DOMESTIC_PARTNER: "Domestic Partner",
    SIGNIFICANT_OTHER: "Significant Other",
    EX_SPOUSE: "Ex-Spouse",
    OTHER: "Other"
};

export const getRelationshipType: ((relType: string) => FamilyRelationshipType) = relType => {
    return Object.entries(displayedRelationshipTypes)
        .filter(([, value]) => relType === value)
        .map(([key,]) => key)[0] as FamilyRelationshipType;
}

export const getRelationshipTypeDisplayValue: ((_type: FamilyRelationshipType) => string) = (relType: FamilyRelationshipType) => {
    return displayedRelationshipTypes[relType];
}

const MAX_MEMBER_PARENTS = 2;

export const getValidRelationshipTypes = (member: MemberType, primaryContact: MemberType): string[] => {
    let allowedRelations: string[];

    const primaryContactSpouses = findPrimaryContactPartners(primaryContact);

    const isMemberPartnerOfPrimaryContact = primaryContactSpouses.some(fromMember => fromMember.id === member.id);

    const parentsOfPrimaryContactSpouse = primaryContactSpouses
        .flatMap(fromMember => findExtendedParents(fromMember))

    const parentsOfPrimaryContact = primaryContact.family.filter(familyRelationship => familyRelationship.type === FamilyRelationshipType.PARENT)
        .flatMap(familyRelationship => familyRelationship.fromMember);

    const isMemberParentOfPrimaryOrParentOfPrimarySpouse = [...parentsOfPrimaryContact, ...parentsOfPrimaryContactSpouse]
        .some(fromMember => fromMember.id === member.id);

    const getParentRelationshipIfAllowed = (numberOfParents: number) => {
        return (numberOfParents < MAX_MEMBER_PARENTS ? [displayedRelationshipTypes.PARENT] : [])
    }

    if (findAllPartnersForMemberId(member.id, primaryContact).size > 0) {
        allowedRelations = [displayedRelationshipTypes.CHILD];
    } else {
        allowedRelations = getDisplayPartnerRelationshipTypes().concat(displayedRelationshipTypes.CHILD);
    }

    if (member.id === primaryContact.id) {
        allowedRelations = [...allowedRelations, ...getParentRelationshipIfAllowed(parentsOfPrimaryContact.length)];
    } else if (isMemberPartnerOfPrimaryContact) {
        allowedRelations = [...allowedRelations, ...getParentRelationshipIfAllowed(parentsOfPrimaryContactSpouse.length)];
    } else if (isMemberParentOfPrimaryOrParentOfPrimarySpouse) {
        allowedRelations = findAllPartnersForMemberId(member.id, primaryContact).size > 0
            ? []
            : getDisplayPartnerRelationshipTypes();
    }

    return allowedRelations;
}

export const findAllRelationshipTypesFor = (contact: PrimaryContact): FamilyRelationship[] => {
    const getAllNodes = (relation: FamilyRelationship): FamilyRelationship[] => {
        if (relation.fromMember.family.length === 0) {
            return [relation]
        } else {
            return [...relation.fromMember.family.flatMap(getAllNodes), relation]
        }
    }

    const unique = (relation: FamilyRelationship, currentIndex: number, allNodes: FamilyRelationship[]) => {
        const firstIndexForThisRelation = allNodes.findIndex((otherRelation) => otherRelation.fromMember.id === relation.fromMember.id);
        return firstIndexForThisRelation === currentIndex
    };

    const { family, otherMembers = [] } = contact;

    return [...family, ...otherMembers]
        .flatMap(getAllNodes)
        .filter(unique);
}

export const findFamilyRelationshipTypesFor = (contact: PrimaryContact): FamilyRelationship[] => {
    const getAllFamilyNodes = (relation: FamilyRelationship): FamilyRelationship[] => {
        if(relation.type === FamilyRelationshipType.OTHER) {
            return [];
        }
        if (relation.fromMember.family.length === 0) {
            return [relation]
        } else {
            return [...relation.fromMember.family.flatMap(getAllFamilyNodes), relation]
        }
    }

    const unique = (relation: FamilyRelationship, currentIndex: number, allNodes: FamilyRelationship[]) => {
        const firstIndexForThisRelation = allNodes.findIndex((otherRelation) => otherRelation.fromMember.id === relation.fromMember.id);
        return firstIndexForThisRelation === currentIndex
    };

    const { family= [] } = contact;

    return [...family]
        .flatMap(getAllFamilyNodes)
        .filter(unique);
}

export const findAllFamilyMembersFor: (member: MemberType) => MemberType[] = (member) => [
    member,
    ...findAllRelationshipTypesFor(member)
        .map((familyRelationship) => familyRelationship.fromMember)
];

export const findAllFamilyMembersForExcludingOthers: (member: MemberType) => MemberType[] = (member) => [
    member,
    ...findFamilyRelationshipTypesFor(member)
        .map((familyRelationship) => familyRelationship.fromMember)
];

export const getDisplayPartnerRelationshipTypes: () => string[] = () => {
    return [
        displayedRelationshipTypes.SPOUSE,
        displayedRelationshipTypes.DOMESTIC_PARTNER,
        displayedRelationshipTypes.SIGNIFICANT_OTHER,
        displayedRelationshipTypes.EX_SPOUSE,
    ]
}