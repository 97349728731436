import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store/store";
import {
    initialAnnualGiftingStrategy,
    initialStateOfResidencyStrategy,
    initialWealthPOSummaryResponse,
    WealthPOSummaryResponse,
} from "./WealthPOTypes";

export type WealthPlanOptimizerState = {
    wealthPOSummaryResponse: WealthPOSummaryResponse;
}

export const initialWealthPlanOptimizerState: WealthPlanOptimizerState = {
    wealthPOSummaryResponse: initialWealthPOSummaryResponse,
}

const wealthPlanOptimizerSlice = createSlice({
    name: "wealthPlanOptimizer",
    initialState: initialWealthPlanOptimizerState,
    reducers: {
        setWealthPlanOptimizerState: (_state, action: PayloadAction<WealthPlanOptimizerState>) => action.payload,
        resetStateOfResidencyStrategy: (state) => {
            state.wealthPOSummaryResponse.stateOfResidencyStrategy = initialStateOfResidencyStrategy;
        },
        resetWealthPlanOptimizerState: () => ({...initialWealthPlanOptimizerState}),
    }
});

export const {
    setWealthPlanOptimizerState,
    resetWealthPlanOptimizerState,
} = wealthPlanOptimizerSlice.actions

export const selectStateOfResidencyEstimatedImpact = (state: RootState) => {
    return state.client.wealthPlanOptimizer.wealthPOSummaryResponse.stateOfResidencyStrategy.estimatedImpact;
}

export const selectEstimatedImpactSummary = (state: RootState) => state.client.wealthPlanOptimizer.wealthPOSummaryResponse.estimatedImpactSummary
export const selectFutureEstateValueWithStrategies = (state: RootState) => state.client.wealthPlanOptimizer.wealthPOSummaryResponse.futureEstateValueWithStrategies
export const selectFutureEstateValueWithoutStrategies = (state: RootState) => state.client.wealthPlanOptimizer.wealthPOSummaryResponse.futureEstateValueWithoutStrategies

export const selectHasStateOfResidencyStrategy = (state: RootState): boolean => {
    return state.client.wealthPlanOptimizer.wealthPOSummaryResponse.stateOfResidencyStrategy.id
        !== initialWealthPOSummaryResponse.stateOfResidencyStrategy.id
}

export const selectStateOfResidencyStrategy = (state: RootState) => state.client.wealthPlanOptimizer.wealthPOSummaryResponse.stateOfResidencyStrategy;
export const selectAnnualGiftingStrategies = (state: RootState) => state.client.wealthPlanOptimizer.wealthPOSummaryResponse.annualGiftingStrategies;
export const selectGiftToGrantorTrustStrategies = (state: RootState) => state.client.wealthPlanOptimizer.wealthPOSummaryResponse.giftToGrantorTrustStrategies;

export default wealthPlanOptimizerSlice.reducer;