import DataEntrySideDrawer from "../../components/DataEntry/DataEntrySideDrawer";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import {Accordion, AccordionItem, Icon} from "../../components";
import {Checkbox} from "xps-react";
import React, {ChangeEvent, useMemo} from "react";
import {
    ClientMeetingDocumentItem,
    ClientMeetingDocumentsResponse,
    ClientMeetingFolderItem,
} from "src/Resources/models/ClientDocuments";
import {AccordionItemInfoWithNoDocuments} from "../../components/Accordion/AccordionItemInfoWithNoDocuments";

type DocumentSetupSectionProps = {
    drawerLabel: string;
    onAttach: () => void;
    openDocumentDrawer: boolean;
    selectedDocuments: ClientMeetingDocumentItem[];
    documentsSubMenus: ClientMeetingDocumentsResponse;
    setSelectedDocuments: (state: ClientMeetingDocumentItem[]) => void;
    setShowDrawerForDocument: (state: boolean) => void;
    onRefreshSharePointDocs: () => void,
};

function DocumentSetupSection({
                                  drawerLabel,
                                  onAttach,
                                  openDocumentDrawer,
                                  selectedDocuments,
                                  documentsSubMenus,
                                  setSelectedDocuments,
                                  setShowDrawerForDocument,
                                  onRefreshSharePointDocs
                              }: DocumentSetupSectionProps) {


    const hRef = documentsSubMenus.webUrl;
    const isClientDocsAbsent = documentsSubMenus.documentItems.length === 0 && documentsSubMenus.folderItems.length === 0;


    const selectedDocs: ClientMeetingDocumentItem[] = useMemo(() => {
        return openDocumentDrawer
            ? JSON.parse(JSON.stringify(selectedDocuments))
            : [];
    }, [openDocumentDrawer]);

    const handleToggleCheckbox = (
        e: ChangeEvent<HTMLInputElement>,
        selectedDocument: ClientMeetingDocumentItem
    ) => {
        let updatedDocumentNames = [...selectedDocuments];
        const { checked } = e.target;
        if (checked) {
            updatedDocumentNames.push(selectedDocument);
        } else {
            updatedDocumentNames = updatedDocumentNames.filter(
                (document) => document.id !== selectedDocument.id
            );
        }
        setSelectedDocuments(updatedDocumentNames);
    };

    const isDocumentChecked = (currentDocument: ClientMeetingDocumentItem) => {
        return selectedDocuments.map(document => document.id).includes(currentDocument.id);
    };

    const handleClose = () => {
        setSelectedDocuments([...selectedDocs]);
        setShowDrawerForDocument(false);
    };

    if (!openDocumentDrawer) {
        return null;
    }

    return (
        <DataEntrySideDrawer isOpen={openDocumentDrawer}>
            <DataEntryHeader
                title={drawerLabel}
                primaryButtonText={"Attach"}
                hideSecondaryButton={true}
                onPrimaryButtonClick={onAttach}
                showRefreshButton={true}
                onRefreshButtonClick={onRefreshSharePointDocs}
                showCloseButton={true}
                onCloseButtonClick={handleClose}
                buttonSize="small"
                hidePrimaryButton={isClientDocsAbsent}
            />
            {!isClientDocsAbsent &&
                <>
                <div className="reporting-drawer__share-point-link">
                    <a
                        href={hRef}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        OPEN SHAREPOINT
                        <i className="icon dds-icons marginleft-sm">
                            launch
                        </i>
                    </a>
                </div>
                <div className="reporting-drawer__content">
                    <DocumentItems
                        documents={documentsSubMenus.documentItems}
                        isDocumentChecked={isDocumentChecked}
                        handleToggleCheckbox={handleToggleCheckbox}
                    />
                    <DocumentFolders
                        documentFolders={documentsSubMenus.folderItems}
                        id={"clientDocumentsMenu"}
                        isDocumentChecked={isDocumentChecked}
                        handleToggleCheckbox={handleToggleCheckbox}
                    />
                </div></>}

            {isClientDocsAbsent &&
                <AccordionItemInfoWithNoDocuments hRef={hRef} isReportSection={true} title={drawerLabel} onRefreshSharePointDocs={onRefreshSharePointDocs} isSharePointDown={documentsSubMenus.isSharePointDown}/>}

        </DataEntrySideDrawer>
    );
}

type DocumentItemsProps = {
    documents: ClientMeetingDocumentItem[];
    isDocumentChecked: (document: ClientMeetingDocumentItem) => boolean;
    handleToggleCheckbox: (
        e: ChangeEvent<HTMLInputElement>,
        selectedDocument: ClientMeetingDocumentItem
    ) => void;
};

function DocumentItems({
    documents,
    isDocumentChecked,
    handleToggleCheckbox,
}: DocumentItemsProps) {
    return (
        <ul className="client-documents-accordion__items">
            {documents.map((document) => (
                <li
                    className="client-documents-accordion__item"
                    key={document.id}
                >
                    <Checkbox
                        className="client-documents-accordion__item__checkbox"
                        aria-label={document.name}
                        value={document.name}
                        name={document.name}
                        checked={isDocumentChecked(document)}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleToggleCheckbox(e, document)
                        }
                    />
                </li>
            ))}
        </ul>
    );
}

type DocumentFoldersProps = {
    id: string;
    documentFolders: ClientMeetingFolderItem[];
    isDocumentChecked: (document: ClientMeetingDocumentItem) => boolean;
    handleToggleCheckbox: (
        e: ChangeEvent<HTMLInputElement>,
        selectedDocument: ClientMeetingDocumentItem
    ) => void;
};

function DocumentFolders({
    documentFolders,
    id,
    isDocumentChecked,
    handleToggleCheckbox,
}: DocumentFoldersProps) {
    const expandedSections = documentFolders.map(
        (documentFolder) => documentFolder.id
    );
    return (
        <>
            <Accordion
                className="client-documents-accordion"
                allowMultipleExpanded={true}
                expanded={expandedSections}
                accordionId={id}
            >
                {documentFolders.map((documentFolder) => {
                    return (
                        <AccordionItem
                            uuid={documentFolder.id}
                            key={documentFolder.id}
                            HeaderComponent={({ expanded }) => (
                                <div className="accordion-header-content marginbottom-12">
                                    <span className="display-flex align-items-center">
                                        <Icon
                                            name={
                                                expanded
                                                    ? "chevron_down"
                                                    : "chevron_right"
                                            }
                                            size="large"
                                        />
                                        <span className="paddingleft-md">
                                            <div className="h5 fontweight-normal margin-none">
                                                {documentFolder.name}
                                            </div>
                                        </span>
                                    </span>
                                </div>
                            )}
                        >
                            <DocumentItems
                                documents={documentFolder.driveItems}
                                isDocumentChecked={isDocumentChecked}
                                handleToggleCheckbox={handleToggleCheckbox}
                            />
                            <DocumentFolders
                                documentFolders={documentFolder.folderItems}
                                id={documentFolder.id}
                                isDocumentChecked={isDocumentChecked}
                                handleToggleCheckbox={handleToggleCheckbox}
                            />
                        </AccordionItem>
                    );
                })}
            </Accordion>
        </>
    );
}

export default DocumentSetupSection;
