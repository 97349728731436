import React from 'react';
import {NonClientMemberSummary,} from "../../../Assets/mappers";
import AccordionWithHeader from "../../../components/Accordion/AccordionWithHeader";
import {AccordionItem} from "../../../components";
import {COLOR_NT_GREY_200} from "../../../constants/colors";
import {GroupedTable} from "../../../components/Table/GroupedTable";
import {AssetAccordionHeader} from "../../../Assets/AssetSummary/common/AssetAccordionHeader";
import {formatCurrency} from "../../../utils/format";
import {NO_OP} from "../../../constants/common";


export interface OutOfEstateAssetListReportProps {
    allItems: any
    nonClientMemberSummariesForPage: NonClientMemberSummary[],
    allNonClientMemberSummaries: NonClientMemberSummary[],
    accordionIdForView:string
}

const OutOfEstateNonClientSummariesView = ({
                                            allItems,
                                               nonClientMemberSummariesForPage,
                                               allNonClientMemberSummaries,
                                               accordionIdForView
                                        }: OutOfEstateAssetListReportProps) => {

    return  <div className="asset-list">
            <div>
                <AccordionWithHeader
                    accordionId={accordionIdForView}
                    allItems={allItems}
                    columnTitles={[]}
                    renderHeader={false}
                    headerProps={
                        undefined
                    }
                >
                    <div className="assets">
                        <article className="layout-split-left">

                            <div>
                                {
                                    nonClientMemberSummariesForPage && nonClientMemberSummariesForPage.map((summary: NonClientMemberSummary, index: number) => {
                                        return <div key={summary.memberName+index}>
                                                <AccordionItem
                                                    key={summary.memberName}
                                                    uuid={`${summary.memberName}-assets-summary-report`}
                                                    accentColor={COLOR_NT_GREY_200}
                                                    HeaderComponent={({expanded}) => {
                                                        const caption = undefined;

                                                        return <AssetAccordionHeader
                                                            expanded={true}
                                                            caption={caption}
                                                            title={summary.memberName}
                                                            formattedTotalPresentValue={formatCurrency(summary.totalValue)}
                                                            gridClassName={"assets-grid"}
                                                        />;
                                                    }
                                                    }>

                                                    <GroupedTable columnHeaders={["Name", "Present Value"]}
                                                                  tableData={summary.assets}
                                                                  menuClickHandler={NO_OP}
                                                    />
                                                </AccordionItem>
                                        </div>
                                    })
                                }
                            </div>
                        </article>

                    </div>
                </AccordionWithHeader>
            </div>
        </div>
}


export default OutOfEstateNonClientSummariesView;
