import React, {ReactNode, useContext, useState} from "react";
import DiscardAssetModal from "./DiscardAssetModal";
import {useHistory} from "react-router-dom";
import {useAppSelector} from "../store/hooks";
import {selectProfile} from "../ClientManagement/ClientProfile/activeProfileSlice";
import AssetsViewContext from "./common/AssetsViewContext";

type AddFormContainerProps = {
    form: (handleCancel: (isFormChanged: boolean) => void) => ReactNode,
    modalTitle: "Asset" | "Liability"
}

export default function AddFormContainer({ form, modalTitle }: AddFormContainerProps) {
    const history = useHistory();
    const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false);
    const profile = useAppSelector(selectProfile);
    const viewType = useContext(AssetsViewContext);

    const navigateToAssetsView = () => {
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
    }

    const handleCancel = (isFormChanged: boolean) => {
        if(isFormChanged) {
            setShowDiscardChangesModal(true);
            return;
        }
        navigateToAssetsView();
    };

    return <>
        {form(handleCancel)}
        <DiscardAssetModal
            isOpen={showDiscardChangesModal}
            title={`Discard this ${modalTitle}?`}
            content={`Any data entered for this ${modalTitle.toLowerCase()} will not be saved.`}
            onClickKeepEditing={() => setShowDiscardChangesModal(false)}
            onClickDiscardChanges={navigateToAssetsView}
        />
    </>;
}
