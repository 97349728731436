import React from "react";
import {EventHandler} from "../../constants/common";
import Input from './Input';
import {camelize, sanitizeStrings} from "../../utils/stringUtils";
import {Label} from "../Label/Label";

type MiddleInitialInputProps = {
    name: string | null,
    className?: string,
    label: string,
    maxLength?: number,
    readOnly?: boolean,
    required?: boolean,
    onChange: EventHandler
}

export const MiddleInitial: React.FC<MiddleInitialInputProps> = ({
                                                                     name,
                                                                     className = "",
                                                                     label,
                                                                     readOnly = false,
                                                                     required = false,
                                                                     maxLength = 256,
                                                                     onChange
                                                                 }) => {
    const camelized = camelize(label);
    return (
        <div>
            <div className={`h4 parent ${className}`}>
                <Label label={label} required={required}/>
                <Input
                    className={`${camelized}Field profileDetails`}
                    name={camelized}
                    aria-label={camelized}
                    aria-labelledby={`${camelized}-label`}
                    id={`${camelized}Input`}
                    onChange={(e: any) => {
                        e.target.value = sanitizeStrings(e.target.value)
                        onChange(e)
                    }}
                    removeMarginTop
                    readOnly={readOnly}
                    size="medium"
                    type="text"
                    value={name || ""}
                    maxLength={maxLength}
                />
            </div>
        </div>
    );
}
