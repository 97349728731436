import {MeetingStatus, Presenter} from "../../Meeting";
import {NO_OP} from "../../../../constants/common";
import {MeetingControlRequest} from "./MeetingControlRequest";

export type SharedObjectMutators = {
    updateMeetingStatus: (status: MeetingStatus) => void;
    updatePresenterState: (presenter: Presenter) => void;
    requestControl: (meetingControlRequest: MeetingControlRequest | undefined) => void;
};

export const defaultSharedObjectMutators: SharedObjectMutators = {
    updateMeetingStatus: NO_OP,
    updatePresenterState: NO_OP,
    requestControl: NO_OP,
}