import React from 'react';
import {Dropdown} from '../Dropdown';
import classNames from 'classnames';

interface TableActionMenuProps {
    className?: string;
    panelWidth?: number;
    children?: React.ReactNode;
    id?: string,
    disabled?: boolean,
}

const TableActionMenu = ({ 
    className, 
    panelWidth = 155, 
    children, ...rest
}: TableActionMenuProps) => (
    <span className={classNames('table-action-menu', className)}>
        <Dropdown
            alignRight
            buttonIcon="only"
            buttonKind="borderless"
            buttonSize="small"
            className="table-action-menu__dropdown"
            // drop="down"
            dropdownKind="menu"
            iconNameOpen="menu_dots"
            iconNameClose="menu_dots"
            onClick={e => {
                e.stopPropagation();
            }}
            panelWidth={panelWidth}
            size="small"
            {...rest}
            >
            {children}
        </Dropdown>
  </span>
);

export default TableActionMenu;