import * as go from 'gojs';
import {Diagram, Link} from 'gojs';
import {
    FamilyTreeMemberNodeType,
    FamilyTreeNode,
    FamilyTreeNodeStyle,
    instanceOfFamilyTreeNodeType,
    TemplateCategory
} from "../models/FamilyTreeType";
import {FamilyRelationshipType} from "../models/FamilyRelationshipType";
import {NO_OP} from "../../constants/common";


export const initializeTreeDiagramWithOtherMembers = (enableFamilyTreeUpdates: boolean | undefined): Diagram => {
    const graph = makeGraph(enableFamilyTreeUpdates);
    const diagram: go.Diagram = makeVerticalGridLayoutDiagram(graph);

    diagram.animationManager.isInitial = false;
    diagram.animationManager.isEnabled = false;

    diagram.toolManager.standardPinchZoomStart = function () {
        go.ToolManager.prototype.standardPinchZoomStart.call(this);
    }

    diagram.toolManager.standardPinchZoomMove = function () {
        go.ToolManager.prototype.standardPinchZoomMove.call(this);
    }

    const nodeHeight = enableFamilyTreeUpdates ? 90 : 60;
    const nodeWidth = enableFamilyTreeUpdates ? undefined : 220;

    diagram.nodeTemplateMap.add(TemplateCategory.DEFAULT, createNodeTemplate(graph, enableFamilyTreeUpdates, nodeHeight, nodeWidth));
    diagram.nodeTemplateMap.add(TemplateCategory.ADD_OTHER_MEMBERS_BUTTON, createAddOtherMembersButtonTemplate(graph, diagram));

    diagram.linkTemplate = createLinkTemplate(graph, diagram);

    diagram.groupTemplateMap.add(TemplateCategory.PARTNER_RELATIONSHIP, createPartnerRelationshipGroupTemplate(graph, diagram, enableFamilyTreeUpdates));
    diagram.groupTemplateMap.add(TemplateCategory.IMMEDIATE_FAMILY, createImmediateFamilyGroupTemplate(graph, diagram));
    diagram.groupTemplateMap.add(TemplateCategory.EXTENDED_FAMILY, createExtendedFamilyGroupTemplate(graph));
    diagram.groupTemplateMap.add(TemplateCategory.OTHER_MEMBERS_WRAPPER, createOtherMembersWrapperGroupTemplate(graph, diagram));
    diagram.groupTemplateMap.add(TemplateCategory.OTHER_MEMBERS_CONTENT, createOtherMembersContentGroupTemplate(graph));

    diagram.model = getDiagramModel(graph);

    return diagram;
};

export const generateMemberRectangleFigure = (enableFamilyTreeUpdates: boolean | undefined) => {
    // Member style
    go.Shape.defineFigureGenerator("MemberRectangle", function (shape, w, h) {
        // this figure takes one parameter, the size of the corner
        let p1 = enableFamilyTreeUpdates ? 10 : 30;  // default corner size
        if (shape !== null) {
            const param1 = shape.parameter1;
            if (!isNaN(param1) && param1 >= 0) p1 = param1;  // can't be negative or NaN
        }
        p1 = Math.min(p1, w / 2);
        p1 = Math.min(p1, h / 2);  // limit by whole height or by half height?
        let geo = new go.Geometry();
        // a single figure consisting of straight lines and quarter-circle arcs
        geo.add(new go.PathFigure(0, p1)
            .add(new go.PathSegment(go.PathSegment.Arc, 180, 90, p1, p1, p1, p1))
            .add(new go.PathSegment(go.PathSegment.Line, w - p1, 0))
            .add(new go.PathSegment(go.PathSegment.Arc, 270, 90, w - p1, p1, p1, p1))
            .add(new go.PathSegment(go.PathSegment.Arc, 360, 90, w - p1, (enableFamilyTreeUpdates ? (h - p1) : p1), p1, p1))
            .add(new go.PathSegment(go.PathSegment.Line, w - p1, h))
            .add(new go.PathSegment(go.PathSegment.Arc, 90, 90, p1, h - p1, p1, p1).close()));
        // don't intersect with two top corners when used in an "Auto" Panel
        geo.spot1 = new go.Spot(0, 0, 0.3 * p1, 0.3 * p1);
        geo.spot2 = new go.Spot(1, 1, -0.3 * p1, 0);
        return geo;
    });
}

const compareMembers = (partA: go.Part | null, partB: go.Part | null) => {
    const dataA = partA?.data;
    const dataB = partB?.data;

    if (dataA?.siblingGroup < dataB?.siblingGroup) return 1;
    if (dataA?.siblingGroup > dataB?.siblingGroup) return -1;
    if (dataA?.style === FamilyTreeNodeStyle.PRIMARY_CONTACT) return 1;
    if (dataB?.style === FamilyTreeNodeStyle.PRIMARY_CONTACT) return -1;
    if (dataA?.relationshipType === FamilyRelationshipType.CHILD && (
        dataB?.relationshipType === FamilyRelationshipType.SPOUSE ||
        dataB?.relationshipType === FamilyRelationshipType.SIGNIFICANT_OTHER ||
        dataB?.relationshipType === FamilyRelationshipType.DOMESTIC_PARTNER ||
        dataB?.relationshipType === FamilyRelationshipType.EX_SPOUSE)
    ) return 1;
    if ((
            dataA?.relationshipType === FamilyRelationshipType.SPOUSE ||
            dataA?.relationshipType === FamilyRelationshipType.SIGNIFICANT_OTHER ||
            dataA?.relationshipType === FamilyRelationshipType.DOMESTIC_PARTNER ||
            dataA?.relationshipType === FamilyRelationshipType.EX_SPOUSE)
        && dataB?.relationshipType === FamilyRelationshipType.CHILD) return -1;
    if (dataA?.relationshipType === FamilyRelationshipType.PARENT && (
        dataB?.relationshipType === FamilyRelationshipType.SPOUSE ||
        dataB?.relationshipType === FamilyRelationshipType.SIGNIFICANT_OTHER ||
        dataB?.relationshipType === FamilyRelationshipType.DOMESTIC_PARTNER ||
        dataB?.relationshipType === FamilyRelationshipType.EX_SPOUSE)
    ) return 1;
    if ((
            dataA?.relationshipType === FamilyRelationshipType.SPOUSE ||
            dataA?.relationshipType === FamilyRelationshipType.SIGNIFICANT_OTHER ||
            dataA?.relationshipType === FamilyRelationshipType.DOMESTIC_PARTNER ||
            dataA?.relationshipType === FamilyRelationshipType.EX_SPOUSE)
        && dataB?.relationshipType === FamilyRelationshipType.PARENT) return -1;
    if (dataA?.age < dataB?.age) return -1;
    if (dataA?.age > dataB?.age) return 1;
    if (dataA?.name < dataB?.name) return 1;
    if (dataA?.name > dataB?.name) return -1;
    if (dataA?.key < dataB?.key) return 1;
    if (dataA?.key > dataB?.key) return -1;
    return 0;
};

const treeLayoutSortComparerFn = (vertexA: go.TreeVertex, vertexB: go.TreeVertex) => {
    let nodeA: go.Part | null = vertexA.node;
    let nodeB: go.Part | null = vertexB.node;
    return compareMembers(nodeA, nodeB);
}

const makeVerticalGridLayoutDiagram = (graph: any): go.Diagram => {
    return graph(go.Diagram, {
        layout: graph(go.GridLayout, {
            wrappingColumn: 1, sorting: go.GridLayout.Ascending,
            comparer: (pa: go.Part, pb: go.Part) => {
                const da = pa.data;
                const db = pb.data;
                if (da.key < db.key) return -1;
                if (da.key > db.key) return 1;
                return 0;
            }
        }),
        model: graph(go.GraphLinksModel,
            {
                linkKeyProperty: 'key',  // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
            }),
        allowSelect: false,
        allowZoom: true,
        maxScale: 2,
        minScale: 0.3,
    });
}

function checkIfOnlyOneOtherNode(targetLink: Link, sourceNode: go.Node | null, comparison: number) {
    const nodes = targetLink?.containingGroup?.memberParts
        .filter((part) => part instanceof go.Node && part !== sourceNode);
    if (nodes?.count === 1) {
        return compareMembers(sourceNode, nodes.first());
    }
    return comparison;
}

// Style and settings for links / edges on a graph

export const createLinkTemplate = ($: any, diagram: go.Diagram,) => {
    return $(go.Link,
        {
            routing: go.Link.Orthogonal, // the kind of line to draw
            corner: 15,
            fromEndSegmentLength: 32,
            toEndSegmentLength: 18,
            fromSpot: go.Spot.BottomCenter, // default, left/right calculated based on order in group
            toSpot: go.Spot.TopCenter,
            fromPortId: 'out',
            toPortId: 'in'
        },
        $(go.Shape, {stroke: "#CECECE", strokeWidth: 2}),
        new go.Binding('fromSpot', 'to', (_toMemberId: string, targetLink: go.Link) => {
            if (targetLink.containingGroup) {
                // If the link is in a group, there is a partner relationship
                const sourceNode = diagram.findNodeForKey(targetLink.data?.from);
                const targetNode = diagram.findNodeForKey(targetLink.data?.to);
                let comparison = 0;
                let isTargetGroup = false;
                // Determine which side the line should emit from the source node
                if (targetNode?.data?.isGroup) {
                    isTargetGroup = true;
                    targetLink.corner = 0;
                    targetLink.fromEndSegmentLength = 19;
                    // sets comparison to the comparison between this node and one other
                    // we should refactor this method
                    comparison = checkIfOnlyOneOtherNode(targetLink, sourceNode, comparison);
                } else {
                    comparison = compareMembers(sourceNode, targetNode);
                }
                if (comparison < 0) {
                    return drawLinkFromLeftCenterOfNode(isTargetGroup, targetLink);
                } else if (comparison > 0) {
                    return drawLinkFromRightCenterOfNode(isTargetGroup, targetLink);
                }
            }
            return go.Spot.BottomCenter;
        }),
    );
};

const drawLinkFromLeftCenterOfNode = (isTargetGroup: boolean, targetLink: go.Link) => {
    if (!isTargetGroup) {
        targetLink.toSpot = go.Spot.RightCenter;
    }
    return go.Spot.LeftCenter;
}

const drawLinkFromRightCenterOfNode = (isTargetGroup: boolean, targetLink: go.Link) => {
    if (!isTargetGroup) {
        targetLink.toSpot = go.Spot.LeftCenter;
    }
    return go.Spot.RightCenter;
}
// styles and settings for group (primary + partner)

export const createPartnerRelationshipGroupTemplate = ($: any, diagram: Diagram, enableFamilyTreeUpdates: boolean | undefined) => {
    return $(go.Group, go.Group.Vertical,
        {
            layout: $(go.GridLayout, {
                    spacing: new go.Size(38, 0),
                    comparer: compareMembers,
                    sorting: go.GridLayout.Descending,
                },
            ),
            selectable: false
        },
        $(go.Panel, go.Panel.Auto, $(go.Placeholder)),
        $(go.Shape,
            {
                portId: '',
                alignment: go.Spot.BottomCenter,
                fromLinkable: false,
                toLinkable: false,
                stroke: null,
                fill: null,
                desiredSize: new go.Size(0, 0)
            },
            new go.Binding("margin", '', (nodeData: FamilyTreeMemberNodeType) => {
                if (enableFamilyTreeUpdates) {
                    let node1Width = 0;
                    let node2Width = 0;

                    // Find node 1 and get it's node width
                    const node1Part: go.Part | null = diagram.findPartForKey(nodeData.key.split(":")[0]);
                    if (node1Part) {
                        node1Part.ensureBounds();
                        const node1Bounds = node1Part ? node1Part.naturalBounds : {width: 0};
                        node1Width = node1Bounds.width;
                    }

                    // Find node 2 and get it's node width
                    const node2Part: go.Part | null = diagram.findPartForKey(nodeData.key.split(":")[1]);
                    if (node2Part) {
                        node2Part.ensureBounds();
                        const node2Bounds = node2Part ? node2Part.naturalBounds : {width: 0};
                        node2Width = node2Bounds.width;
                    }

                    // Node widths are equal - Short circuit and return 0 margin
                    if (node1Width === node2Width) {
                        return new go.Margin(0, 0, 0, 0);
                    }

                    // Calculate the offset by taking the absolute value of the difference in the node widths
                    const offset = Math.abs(node1Width - node2Width);

                    // Figure out which member is on the left and right side of the PartnerRelationship group
                    //  - Check for primary flag which is always on the left
                    //  - Check for Partner Relationship types that are always on the right (Spouse, Ex-Spouse, etc.)
                    if (node1Width > node2Width) {
                        // Node 1 is larger, check if it's on the left or right
                        return isMemberOnTheLeft(node1Part) ? new go.Margin(0, 0, 0, offset) : new go.Margin(0, offset, 0, 0);
                    } else {
                        // Node 1 is smaller, check if it's on the left or right
                        return isMemberOnTheLeft(node1Part) ? new go.Margin(0, offset, 0, 0) : new go.Margin(0, 0, 0, offset);
                    }
                }
            })
        ),
    );
};

// Checks to see if a member in a partner relationship is on the left side of the group
const isMemberOnTheLeft = (node: go.Part | null) => {
    if (node === null) return false;
    return node?.data.primary || (node?.data.relationshipType !== FamilyRelationshipType.SPOUSE &&
        node?.data.relationshipType !== FamilyRelationshipType.EX_SPOUSE &&
        node?.data.relationshipType !== FamilyRelationshipType.SIGNIFICANT_OTHER &&
        node?.data.relationshipType !== FamilyRelationshipType.DOMESTIC_PARTNER);
}

const getFill = (nodeData: FamilyTreeNode) => {
    if (!instanceOfFamilyTreeNodeType(nodeData)) {
        return null;
    }
    return nodeData.style !== FamilyTreeNodeStyle.BUTTON ? '#FFFFFF' : '#F6F6F6';
};

const getStroke = (nodeData: FamilyTreeNode) => {
    let stroke;
    if (!instanceOfFamilyTreeNodeType(nodeData)) {
        return null;
    }
    switch (nodeData?.style) {
        case FamilyTreeNodeStyle.PRIMARY_CONTACT:
        case FamilyTreeNodeStyle.PRIMARY_LEGAL_PARTNER:
            stroke = '#37A085';
            break;
        case FamilyTreeNodeStyle.DECEASED:
            stroke = '#CECECE';
            break;
        case FamilyTreeNodeStyle.BUTTON:
            stroke = '#F6F6F6';
            break;
        default:
            stroke = '#8AD2C6';
    }
    return stroke;
};

const getStrokeDashArray = (nodeData: FamilyTreeNode) => {
    if (!instanceOfFamilyTreeNodeType(nodeData)) {
        return null;
    }
    let strokeDashArray;
    if (nodeData?.style === FamilyTreeNodeStyle.DOTTED) {
        strokeDashArray = [3, 4];
    } else {
        strokeDashArray = [0, 0]
    }
    return strokeDashArray;
};

const getFont = (nodeData: FamilyTreeNode) => {
    if (!instanceOfFamilyTreeNodeType(nodeData)) {
        return null;
    }
    let fontStyle: string;
    switch (nodeData?.style) {
        case FamilyTreeNodeStyle.DECEASED:
        case FamilyTreeNodeStyle.DOTTED:
            fontStyle = 'italic';
            break;
        default:
            fontStyle = 'normal';
    }
    return `${fontStyle} normal 400 15px roboto`;
}
// Style for the node & node settings

export const createNodeTemplate = (graph: any, enableFamilyTreeUpdates: boolean | undefined, nodeHeight: number, nodeWidth: number | undefined) => {
    const addMemberNameWithSubtitle = () => graph(go.Panel, "Table",
        graph(go.RowColumnDefinition, {column: 1, width: 4}),
        graph(go.TextBlock,
            {row: 0, column: 0, columnSpan: 3, alignment: go.Spot.Center},
            {font: "normal normal 500 18px roboto"},
            strokeStyle,
            {spacingBelow: 2},
            new go.Binding("text", "name")),
        graph(go.TextBlock,
            {row: 1, column: 0, alignment: go.Spot.Center, stroke: "#6B6E6F"},
            {spacingBelow: 7},
            new go.Binding("text", "subtitle"),
            new go.Binding("font", "", (nodeData: FamilyTreeNode) => getFont(nodeData))
        ),
    );

    const strokeStyle = new go.Binding('stroke', '', (nodeData: FamilyTreeNode) => {
        if (!instanceOfFamilyTreeNodeType(nodeData)) {
            return null;
        }
        return nodeData.style !== FamilyTreeNodeStyle.BUTTON ? '#3D4042' : '#05676E';
    });

    const addMemberFullNameWithSubtitle = () => graph(go.Panel, "Table",
        graph(go.RowColumnDefinition, {column: 0, minimum: 200},
            new go.Binding('minimum', '', (nodeData: FamilyTreeNode) => {
                if (!instanceOfFamilyTreeNodeType(nodeData)) {
                    return null;
                }
                return (nodeData.firstNameMiddleInitial.length > 15 || nodeData.lastName.length > 15) ? 260 : 200;
            })),
        graph(go.TextBlock,
            {
                row: 0,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                margin: new go.Margin(0, 10, 0, 10)
            },
            {font: "normal normal 500 18px roboto"},
            strokeStyle,
            {spacingBelow: 2},
            new go.Binding("text", "firstNameMiddleInitial").makeTwoWay()),
        graph(go.TextBlock,
            {
                row: 1,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                margin: new go.Margin(0, 10, 0, 10)
            },
            {font: "normal normal 500 18px roboto"},
            strokeStyle,
            {spacingBelow: 2},
            new go.Binding("text", "lastName").makeTwoWay()),
        graph(go.TextBlock,
            {
                row: 2,
                column: 0,
                columnSpan: 3,
                alignment: go.Spot.Left,
                stroke: "#6B6E6F",
                margin: new go.Margin(0, 10, 0, 10)
            },
            {spacingBelow: 7},
            new go.Binding("text", "subtitle"),
            new go.Binding("font", "", (nodeData: FamilyTreeNode) => getFont(nodeData))
        ),
    );

    return graph(go.Node,
        go.Node.Auto,
        graph(go.Shape, "MemberRectangle", {
                width: nodeWidth,
                height: nodeHeight,
                strokeWidth: 2,
            },
            new go.Binding('margin', '', (nodeData: FamilyTreeNode) => {
                if (instanceOfFamilyTreeNodeType(nodeData) && nodeData.relationshipType === FamilyRelationshipType.OTHER) {
                    return new go.Margin(30, 0, 0, 0);
                }
                return new go.Margin(0, 0, 0, 0);
            }),
            new go.Binding('fill', '', (nodeData: FamilyTreeNode) => getFill(nodeData)),
            new go.Binding('stroke', '', (nodeData: FamilyTreeNode) => getStroke(nodeData)),
            new go.Binding('strokeDashArray', '', (nodeData: FamilyTreeNode) => getStrokeDashArray(nodeData)),
        ),
        enableFamilyTreeUpdates ? addMemberFullNameWithSubtitle() : addMemberNameWithSubtitle(),
        new go.Binding('click', 'onClick'),
        new go.Binding('cursor', 'onClick', (onClick) => !!onClick ? 'pointer' : ''));
};

export const createAddOtherMembersButtonTemplate = (graph: any, diagram: go.Diagram) => {
    return graph(go.Node,
        go.Node.Auto,
        graph(go.Shape, "MemberRectangle", {
                width: 215, height: 60, strokeWidth: 2, stroke: '#F6F6F6'
            },
            new go.Binding('fill', '', (nodeData: FamilyTreeNode) => getFill(nodeData)),
            new go.Binding('stroke', '', (nodeData: FamilyTreeNode) => getStroke(nodeData)),
        ),
        graph(go.Panel, "Horizontal",
            graph(go.TextBlock,
                {
                    text: 'add_circle_outline',
                    font: "normal 400 18px nt-dds-icons",
                    stroke: '#05676E',
                    margin: new go.Margin(3, 0, 0, 0)
                }
            ),
            graph(go.Shape, {width: 3, opacity: 0}),
            graph(go.TextBlock,
                {font: "normal normal 500 12px roboto", stroke: '#05676E'},
                new go.Binding("text", "name"),
            )
        ),
        new go.Binding("location", '', NO_OP, locationCenterBelow(diagram)),
        new go.Binding('click', 'onClick'),
        new go.Binding('cursor', 'onClick', (onClick) => !!onClick ? 'pointer' : ''));
};

const makeGraph = (enableFamilyTreeUpdates: boolean | undefined) => {
    generateMemberRectangleFigure(enableFamilyTreeUpdates);
    go.Diagram.licenseKey = "73f944e7bb6031b700ca0d2b113f69ee1bb37b369e821ff55d5641a7ef0a691c2bc9ec7e59db8e90d5f94ffd197bc28d8ec16d2d855c026bb465d6da17e3d5aab23073b61c09438eac0a26c39ffb2af2fb7d63e2c4e027a4da2adcf3f9b8c09d5febecd657cc";
    return go.GraphObject.make;
}

const getDiagramModel = (graph: any) => graph(go.GraphLinksModel, {
    linkKeyProperty: 'key',  // IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
})

const createImmediateFamilyGroupTemplate = (graph: any, diagram: go.Diagram) => {
    return graph(go.Group, go.Group.Auto,
        {
            layout: graph(go.TreeLayout,
                {
                    layerStyle: go.TreeLayout.LayerUniform,
                    treeStyle: go.TreeLayout.StyleLayered,
                    arrangement: go.TreeLayout.ArrangementFixedRoots,
                    angle: 90,
                    alternateAlignment: go.TreeLayout.AlignmentBus,
                    nodeSpacing: 38,
                    comparer: treeLayoutSortComparerFn,
                    sorting: go.TreeLayout.SortingDescending,
                    layerSpacing: 50,
                    alignment: go.TreeLayout.AlignmentCenterChildren,
                },
            ),
            selectable: false
        },
        graph(go.Panel, go.Panel.Auto, graph(go.Placeholder)),
        new go.Binding("location", '', NO_OP, locationCenterBelow(diagram)),
    );
};

const createExtendedFamilyGroupTemplate = (graph: any) => {
    return graph(go.Group, go.Group.Auto,
        {
            layout: graph(go.GridLayout, {
                    spacing: new go.Size(38, 0),
                    comparer: compareMembers,
                    sorting: go.GridLayout.Descending,
                    wrappingWidth: 10_000,
                    cellSize: new go.Size(217, 60),
                },
            ),
            selectable: false
        },
        graph(go.Panel, go.Panel.Auto, graph(go.Placeholder, {
            // Padding bottom to provide space between extended and immediate
            margin: new go.Margin(0, 0, 50, 0)
        })),
    );
};

const createOtherMembersWrapperGroupTemplate = (graph: any, diagram: go.Diagram) => {
    return graph(go.Group, go.Group.Auto, {layout: graph(go.GridLayout, {wrappingColumn: 1})},
        graph(go.Panel, go.Panel.Auto,
            graph(go.TextBlock, {
                stroke: '#3D4042',
                font: "normal normal 500 18px roboto",
                text: 'Other Members',
                textAlign: "center",
                name: 'OtherMembersLabel'
            }),
            graph(go.Placeholder),
        ),
        new go.Binding("location", '', NO_OP, locationCenterBelow(diagram, {marginTop: 110})),
    );
};

const createOtherMembersContentGroupTemplate = (graph: any) => {
    return graph(go.Group, go.Group.Auto, {
            layout: graph(go.GridLayout, {
                spacing: new go.Size(38, 0),
            })
        },
        graph(go.Panel, go.Panel.Auto,
            graph(go.Placeholder, {
                // Padding top needs to account for the height of 'Other Members' TextBlock (18px)f
                margin: new go.Margin(18, 0, 5, 0)
            })),
    );
};

const locationCenterBelow = (diagram: go.Diagram, {marginTop = 0}: { marginTop?: number } = {}) => {
    return (currentLocation: go.Point, targetPartData: FamilyTreeNode) => {
        let centerPoint = currentLocation;
        const targetPart: go.Part | null = diagram.findPartForKey(targetPartData.key);
        if (targetPart) {
            const relativePart: go.Part | null = diagram.findPartForKey(targetPartData.centerRelativeToPartKey);
            relativePart?.ensureBounds();
            targetPart.ensureBounds();
            const relativePartBounds = relativePart ? relativePart.actualBounds : {x: 0, y: 0, width: 0, height: 0};
            const targetPartBounds = targetPart ? targetPart.actualBounds : {width: 0};
            const relativePartCenterX = (relativePartBounds.width / 2) + relativePartBounds.x;
            const targetPartCenterX = targetPartBounds.width / 2;
            centerPoint = new go.Point(
                relativePartCenterX - targetPartCenterX,
                relativePartBounds.y + relativePartBounds.height + marginTop
            );
            targetPart.move(centerPoint, true);
        }
        return go.Point.stringify(centerPoint);
    };
}

