import Button from '@nt/dds-react/core/Button';
import PortalPanel from '@nt/dds-react/core/Panel/PortalPanel';
import {ClickListener, useEscape, useListFocus, useTabOut} from '@nt/dds-utils';
import throttle from 'lodash/throttle';
import React, {Children, cloneElement, ReactNode, useCallback, useEffect, useRef, useState} from 'react';

type DropdownMenuProps = {
    id: string,
    buttonText: string,
    buttonKind: 'primary' | 'secondary' | 'tertiary' | 'borderless',
    buttonIcon?: 'right' | 'left' | 'none' | 'only',
    buttonIconName?: string,
    buttonSize: 'small' | 'medium' | 'large',
    children: ReactNode,
    disabled: boolean,
    panelHeight?: string | number,
    panelWidth: number,
    alignRight: boolean,
    scrollableRegionId: string
};

const DropdownMenu = ({
                          id,
                          buttonText,
                          buttonKind,
                          buttonIcon,
                          buttonIconName,
                          buttonSize,
                          children,
                          disabled,
                          panelHeight = 'auto',
                          panelWidth = 240,
                          alignRight = false,
                          scrollableRegionId
                      }: DropdownMenuProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useState<HTMLElement[]>([]);
    const [panelLeftPos, setPanelLeftPos] = useState(0);
    const [isActive, setActive] = useState<boolean>(false);
    const [panelTopPos, setPanelTopPos] = useState(0);
    const [startIndex, setStartIndex] = useState(-1);
    const [keepFocus, setKeepFocus] = useState<boolean>(false);
    const [clampedPanelHeight, setClampedPanelHeight] = useState(panelHeight);

    let buttonRef = useRef<HTMLElement>(null);
    let panelRef = useRef<HTMLElement>(null);
    const scrollableRegionRef = useRef(document.getElementById(scrollableRegionId));

    useEffect(() => {
        let items: any = [];
        if (panelRef.current) {
            items = panelRef.current.querySelectorAll('[role="menuitem"]');
        }
        setOptions(Array.from(items));
    }, [panelRef, isOpen]);

    const setFocus = (item: HTMLElement) => {
        item.focus();
    };
    const handleSubMenu = (props: any) => {
        setActive(!props);
        if (!props) {
            setKeepFocus(false);
        } else {
            setKeepFocus(true);
        }
    };

    useListFocus(panelRef, '[role="menuitem"]', isActive, setFocus, options, startIndex, keepFocus);

    const close = useCallback(() => {
        if (isOpen) {
            setIsOpen(false);
            setStartIndex(-1);
        }
    }, [isOpen]);

    useEscape(close, isOpen, buttonRef);
    useTabOut(close, isOpen);

    const calculateScrollableRegionOffset = () => {
        if (scrollableRegionRef.current) {
            const scrollableRegionRect = scrollableRegionRef.current.getBoundingClientRect();
            return {
                scrollableLeftOffset: scrollableRegionRect.left,
                scrollableTopOffset: scrollableRegionRect.top
            }
        } else {
            return {
                scrollableLeftOffset: 0,
                scrollableTopOffset: 0
            }
        }
    }

    const calculatePanelPosition = () => {
        const buttonRect = buttonRef.current!.getBoundingClientRect();
        const {scrollableLeftOffset, scrollableTopOffset} = calculateScrollableRegionOffset();

        let leftPos;
        // Set left position
        if (buttonRect.left + panelWidth > window.innerWidth || alignRight) {
            leftPos = buttonRect.left + buttonRect.width - panelWidth - scrollableLeftOffset;
        } else {
            leftPos = buttonRect.left - scrollableLeftOffset;
        }
        //set top position
        const getTopOffsetSum = (ele: any) => {
            let top = 0;
            while (ele) {
                top += ele.offsetTop;
                ele = ele.offsetParent;
            }
            return top;
        };
        let topOffsetSum = getTopOffsetSum(buttonRef.current);
        let topPos = topOffsetSum + buttonRect.height - scrollableTopOffset;

        return {
            leftPos,
            topPos
        }
    }

    const clampPanelHeight = (topPos: number) => {
        let clampedHeight: number | string = 'auto';

        if(typeof panelHeight === 'number') {
            const bottomPos = topPos + panelHeight;

            if (bottomPos > window.innerHeight) {
                const minHeight = 100;
                const bottomMargin = 115;
                const containerScrollOffset = scrollableRegionRef.current?.scrollTop ?? 0;
                clampedHeight = Math.max(minHeight, window.innerHeight - topPos - bottomMargin + containerScrollOffset);
            }
        }

        return clampedHeight;
    };

    const update = useCallback(() => {
        const {leftPos, topPos} = calculatePanelPosition();
        const clampedHeight = clampPanelHeight(topPos);

        setPanelLeftPos(leftPos);
        setPanelTopPos(topPos);
        setClampedPanelHeight(clampedHeight);
    }, [alignRight, buttonRef, panelWidth]);

    useEffect(() => {
        window.addEventListener('resize', () => {
            close();
            throttle(update, 200);
        });
        update();
        return () => window.removeEventListener('resize', close);
    }, [isOpen, close, update]);

    const handleKeyDown = (e: any) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setIsOpen(true);
            setActive(true);
        }
        if (e.key === 'ArrowUp') {
            e.preventDefault();
            setIsOpen(false);
            setActive(false);
        }
    }

    const portalContainerId = scrollableRegionRef.current ? scrollableRegionId : undefined;

    return (
        <ClickListener onClickOutside={close}>
            <Button
                id={id}
                kind={buttonKind}
                icon={buttonIcon || 'right'}
                iconName={buttonIconName || (isOpen ? 'menu_up' : 'menu_down')}
                size={buttonSize}
                disabled={disabled}
                onClick={() => {
                    !disabled && setIsOpen(!isOpen);
                    setActive(true);
                }}
                ref={buttonRef}
                aria-haspopup="true"
                aria-controls={`${id}_Menu`}
                aria-expanded={isOpen.toString()}
                onKeyDown={!disabled && handleKeyDown}
            >
                {buttonText}
            </Button>
            {isOpen && (
                <div className='position-relative'>
                    <PortalPanel
                        noPadding
                        className='paddingtop-md paddingbottom-md'
                        height={clampedPanelHeight}
                        width={panelWidth}
                        isOpen={isOpen}
                        triggerSize={buttonSize}
                        top={panelTopPos}
                        left={panelLeftPos}
                        ref={panelRef}
                        portalContainerId={portalContainerId}
                    >
                        <ul role='menu' id={`${id}_Menu`} aria-labelledby={id} data-testid={`${id}_Menu`}>
                            {Children.map(children, (child: any) => {
                                    return cloneElement(child, {
                                        onClick: () => {
                                            child.props.onClick();
                                            close();
                                        },
                                        onSubMenuOpen: handleSubMenu
                                    })
                                }
                            )}
                        </ul>
                    </PortalPanel>
                </div>
            )}
        </ClickListener>
    );
};

export default DropdownMenu;