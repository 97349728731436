import {DropdownItem, PageActionMenu, RadioGroup} from "../../components";
import React, {ChangeEvent,useEffect, useState} from "react";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import moment from "moment";
import {DropdownGroup, MenuDivider} from "xps-react";
import {NO_OP} from "../../constants/common";
import {DataDisplayView, FundingDisplayOptions} from "../../Goals/Funding/models/Funding";
import GoalFundingWrapper from "./GoalFundingWrapper";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import {ReportConfig} from "../models/Reporting";
import {
    selectSelectedPagesForReport,
    setOptionsForGoalFundingReport,
    setSelectedPagesForAnnualReport
} from "../CreateReport/ReportingSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";


export const EditableGoalFundingReport = () => {

    const history = useHistory();
    const dispatch = useAppDispatch();
    const [showFundedByInflows, setShowFundedByInflows] = useState<boolean>(false);
    const [showFundedByNonInvestable, setShowFundedByNonInvestable] = useState<boolean>(false);
    const {id} = useParams<RouteWithId>();
    const [displayOptions, setDisplayOptions] = useState<FundingDisplayOptions>(FundingDisplayOptions.ONLY_PORTFOLIO);
    const [displayView, setDisplayView] = useState<DataDisplayView>(DataDisplayView.PERCENTAGE_VIEW);
    const selectedPagesForAnnualReportFromRedux = useAppSelector(selectSelectedPagesForReport);
    const selectedPagesForAnnualReport: ReportConfig = JSON.parse(JSON.stringify(selectedPagesForAnnualReportFromRedux));
    const [fundingAge, setFundingAge] = useState<number>(0);

    useEffect(() => {
        let updatedDisplayOptions = FundingDisplayOptions.ONLY_PORTFOLIO;
        if(showFundedByInflows && !showFundedByNonInvestable) {
            updatedDisplayOptions = FundingDisplayOptions.PORTFOLIO_AND_INFLOWS
        } else if(!showFundedByInflows && showFundedByNonInvestable) {
            updatedDisplayOptions = FundingDisplayOptions.PORTFOLIO_AND_NON_INVESTABLE
        } else if(showFundedByInflows && showFundedByNonInvestable) {
            updatedDisplayOptions = FundingDisplayOptions.PORTFOLIO_AND_INFLOWS_AND_NON_INVESTABLE
        }
        setDisplayOptions(updatedDisplayOptions);
    }, [showFundedByInflows, showFundedByNonInvestable]);

    const toggleFundedByInflows = () => {
        setShowFundedByInflows(!showFundedByInflows);
    }

    const toggleFundedByNonInvestable = () => {
        setShowFundedByNonInvestable(!showFundedByNonInvestable);
    }
    const handleUpdateDataDisplay = (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setDisplayView(value as DataDisplayView);
    }

    const cancelEditGoalFundingReport = () => {
        history.push(`/Profile/${id}/ClientProfile/CreateReport`);
    };

    const takeScreenShot = () => {
        const selectedPagesConfig: ReportConfig = {
            ...selectedPagesForAnnualReport,
            goalFunding: {
                ...selectedPagesForAnnualReport["goalFunding"],
                isEnabled: true,
            }
        };
        dispatch(setSelectedPagesForAnnualReport(selectedPagesConfig));
        dispatch(setOptionsForGoalFundingReport({displayView: displayView, displayOptions: displayOptions, fundingAge:fundingAge}))
        history.push(`/Profile/${id}/ClientProfile/CreateReport`);
    }



    const component = (
        <PageActionMenu
            className="edit-family-tree-menu marginright-12"
            buttonKind="secondary"
            rounded={false}
            panelWidth={240}
        >
            <DropdownGroup groupName={` `} key={`ls-dropdown-group`} show={true}>
                <DropdownItem className="funding-page-action-menu-options" value="do_not_use" itemText="" onClick={NO_OP}>
                    <RadioGroup
                        id="dataDisplay"
                        name="dataDisplay"
                        label="Data Table"
                        layout="vertical"
                        values={[DataDisplayView.DOLLAR_VIEW, DataDisplayView.PERCENTAGE_VIEW]}
                        onChange={handleUpdateDataDisplay}
                        selected={displayView}
                    />
                </DropdownItem>
                <MenuDivider
                    className="funding-menu-divider"
                    role="separator"
                    itemText=""
                    value="do-not-use"
                />
                <DropdownItem
                    key="showFundedByInflows"
                    itemText={showFundedByInflows ? 'Hide Funded by Inflows' : 'Show Funded by Inflows'}
                    value={showFundedByInflows ? 'Hide Funded by Inflows' : 'Show Funded by Inflows'}
                    onClick={toggleFundedByInflows}
                />
                <DropdownItem
                    key="showFundedByNonInvestable"
                    itemText={showFundedByNonInvestable ? 'Hide Funded by Non Investable' : 'Show Funded by Non Investable'}
                    value={showFundedByNonInvestable ? 'Hide Funded by Non Investable' : 'Show Funded by Non Investable'}
                    onClick={toggleFundedByNonInvestable}
                />
            </DropdownGroup>

        </PageActionMenu>
    );


    return (
        <div>
            <DataEntryHeader
                title={"Goal Funding Over Time Report Preview"}
                primaryButtonText="Take Screenshot"
                secondaryButtonText="Cancel"
                onPrimaryButtonClick={takeScreenShot}
                onSecondaryButtonClick={cancelEditGoalFundingReport}
                isExtraHTMLElementRequired={true}
                extraHTMLElementComponent={component}
                SubtitleComponent={() => <span
                    className='holdings-subtitle'>{`As of ${moment().format('MM/DD/YYYY hh:mm A')}`}</span>}
            />

            {

                    <GoalFundingWrapper displayView={displayView} displayOptions={displayOptions} setFundingAtAge={setFundingAge} fundingAgeToShowReport={0} isEditPage={true}/>

            }

        </div>
    )


};