import React, {useEffect} from 'react';
import {hot} from 'react-hot-loader';
import Routes from './routes/';
import './App.css';

import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from "@azure/msal-react";
import {msalUtils} from "./MsalUtils";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import * as releaseToggleClientApi from "./ReleaseToggles/ReleaseToggleApiClient";
import {ReleaseTogglesType} from "./ReleaseToggles/ReleaseToggles";
import {loadToonimoTrainingContent} from "./toonimoUtils";
import {useAppDispatch} from "./store/hooks";
import {setReleaseToggles} from "./ReleaseToggles/releaseTogglesSlice";
import {resourcesApiClient} from "./Resources/ResourcesApiClient";
import {MenuStructureResponse} from "./Resources/models/AdviceLibrary";
import {setAdviceLibraryMenu, setPracticeDocumentsMenu} from "./Resources/menuStructureSlice";
import {MeetingPortal} from "./MeetingPortal/MeetingPortal";
import RelayProvider from "./ClientManagement/Meeting/Relay/RelayProvider";
import CommunicationsProvider from "./ClientManagement/Meeting/CommunicationsProvider";
import ReportingPrintView from "./Reporting/ReportingPrintView";

const App = () => {
    const msal = useMsal();
    
    return (
        <div>
            <Router>
                <CommunicationsProvider>
                    <RelayProvider>
                        <Switch>
                            <Route exact path="/ReportPreview/:id" component={ReportingPrintView}/>
                            <Route exact path="/meetings" component={MeetingPortal}/>
                            <Route render={() =>
                                <>
                                    <UnauthenticatedTemplate>
                                        {() => msalUtils.handleLogin(msal)}
                                    </UnauthenticatedTemplate>
                                    <AuthenticatedTemplate>
                                        <InitialLoad/>
                                        <Routes/>
                                    </AuthenticatedTemplate>
                                </>
                            }/>
                        </Switch>
                    </RelayProvider>
                </CommunicationsProvider>
            </Router>
        </div>);
};

export const InitialLoad = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        releaseToggleClientApi.getReleaseToggles()
            .then((updatedReleaseToggles: ReleaseTogglesType) => {
                dispatch(setReleaseToggles({...updatedReleaseToggles, enableGoalsSummaryReport: true}));
                if (updatedReleaseToggles?.enableToonimo) {
                    loadToonimoTrainingContent();
                }
            })
            .catch(console.error);

        resourcesApiClient.getMenuStructure()
            .then((menuStructureResponse: MenuStructureResponse) => {
                dispatch(setAdviceLibraryMenu(menuStructureResponse.menu["AdviceLibrary"]));
                dispatch(setPracticeDocumentsMenu(menuStructureResponse.menu["PracticeDocuments"]));
            })
            .catch(console.error);
    }, []);

    return <></>
}

export default hot(module)(App);