import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {RouteWithIdAndMenuDetails} from "../../routes/types";
import usePageViewTimer from "../../hooks/usePageViewTimer";
import {resourcesApiClient} from "../ResourcesApiClient";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {QuickSlidesPDF} from "../../components/QuickSlides/QuickSlidesPDF";

const ClientMeetingDocuments: React.FC = () => {
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const {id, menuTitle, documentLabel, currPage} = useParams<RouteWithIdAndMenuDetails>();
    const [documentUri, setDocumentUri] = useState<any>("");
    usePageViewTimer('Client Meeting Documents Page Load Timer (milliseconds)', isPageLoading);

    useEffect(() => {
        setIsLoading(true);
        getClientDocumentBlob();
    }, [documentLabel]);

    const getClientDocumentBlob = (): void => {
        const documentType = (menuTitle === "ClientDocuments") ? "CLIENT_DOCUMENTS" : "MY_DOCUMENTS";
        let extension = "PDF";
        let itemId = documentLabel;
        if(documentLabel) {
            extension = documentLabel.substring(documentLabel.lastIndexOf(".")+1, documentLabel.length);
            itemId = documentLabel.substring(0, documentLabel.lastIndexOf("."));
        }

        resourcesApiClient.getClientMeetingDocumentBlob(itemId, documentType, id, extension).then(response => {
            const blobUrl = URL.createObjectURL(response);
            setDocumentUri(blobUrl);
            setIsLoading(false);
        }).catch(error => {
            console.error("Failed to get blob for Client Document", error.message);
            setIsPageLoading(false);
            setDocumentUri('');
            setIsLoading(false);
        })
    }

    if (isLoading) {
        return <LoadingIndicator/>
    }

    return (
        <QuickSlidesPDF
            compId="life-table-quick-slides"
            isOpen={true}
            pdf={documentUri}
            redirectUrl={`/Profile/${id}/ClientProfile/Resources/${menuTitle}/${documentLabel}/`}
            currPage={+currPage}
            getDocument={getClientDocumentBlob}
            setIsPageLoading={setIsPageLoading}
        />
    );

}

export default ClientMeetingDocuments;