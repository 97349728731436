import React, {Fragment} from "react";
import {
    PresentationPaneHeader,
} from "../../components";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {
    ProfileAwareActivatePortfolioReserveSwitch
} from "./ActivatePortfolioReserve/ProfileAwareActivatePortfolioReserveSwitch";
import {LabelPosition} from "./ActivatePortfolioReserve/ActivatePortfolioReserveSwitch";

type SetPortfolioReserveHeaderProps = {
    displaySetReserveTargetButton: boolean;
    isSetReserve: boolean;
    onTogglePortfolioReserve?: (activePortfolioReserve: boolean, profileId: string, dispatch: Function) => void
}

const SetPortfolioReserveHeader = (props: SetPortfolioReserveHeaderProps) => {
    const {id} = useParams<RouteWithId>();
    const displayName = useProfileAndProposals(id).approvedProfile.displayName;
    const {displaySetReserveTargetButton} = props;

    const titleRowChildren = () => (
        displaySetReserveTargetButton ?
            <div>
                <ProfileAwareActivatePortfolioReserveSwitch
                    className="set-portfolio-reserve-header-text__header-toggle-text"
                    labelPosition={LabelPosition.Dark}
                    onTogglePortfolioReserve={props.onTogglePortfolioReserve}
                />
            </div> :
            <Fragment/>)
    return <PresentationPaneHeader className="set-portfolio-reserve-page__header"
                                   displayName={displayName}
                                   title="The Portfolio Reserve safeguards your lifestyle spending, particularly during a market downturn."
                                   titleRow={titleRowChildren()}
    />
}

SetPortfolioReserveHeader.defaultProps = {
    displaySetReserveTargetButton: false
}

export default SetPortfolioReserveHeader;
