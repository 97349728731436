import classNames from 'classnames';
import React, {ReactNode} from 'react';

export type DataEntrySummaryItem = {
    label?: string | ReactNode,
    value?: string | ReactNode,
    subValue?: string | ReactNode,
    className?: string,
    testId?: string,
}

export type DataEntrySummaryProps = {
    children?: ReactNode,
    className?: string,
    items?: DataEntrySummaryItem[],
    title?: string,
}

export default function DataEntrySummary(
    {children, className, items = [], title}: DataEntrySummaryProps
) {
    return (
        <div className={classNames('data-entry-summary', className)}>
            <div className="data-entry-summary__title" data-theme="aqua">
                <div className="margin-none h4">{title}</div>
            </div>
            <div className="data-entry-summary__items" >
                {items.map((item, index) => {
                    const empty = item.value === undefined || item.value === false || item.value === '' || Number.isNaN(item.value);
                    return (
                        <div key={index} className={classNames('data-entry-summary__item', item.className)} data-testid={item.testId}>
                            <span className="data-entry-summary__item__label">
                                <b>{item.label}</b>
                            </span>
                            <span
                                data-testid={"summary item - " + item.label?.toString()}
                                aria-label={item.label?.toString() ?? undefined}
                                className={classNames('data-entry-summary__item__value', {
                                    'data-entry-summary__item__value--empty': empty,
                                })}
                            >
                                {empty ? '—' : item.value}
                            </span>
                            {item.subValue &&
                                <span className="data-entry-summary__item__subvalue">{item.subValue}</span>}
                        </div>
                    );
                })}
            </div>
            {children && <div className="data-entry-summary__custom-content">{children}</div>}
        </div>
    );
}



