import {get, post, put} from "../../core/api";
import {Meeting, MeetingParticipant} from "./Meeting";
import {base64ToString, stringToBase64} from "../../utils/base64Utils";

export const getMeetingsHistory = (profileId: string): Promise<Meeting[]> => {
    return get(`/client-management/profiles/${profileId}/meetings`)
        .then(extractBody)
        .then(meetings => (meetings as Meeting[]).map(meeting => processMeetingResponse(meeting)));
}


export const getMeeting = (profileId: string, meetingId: string): Promise<Meeting> => {
    return get(`/client-management/profiles/${profileId}/meeting/${meetingId}`)
        .then(extractBody)
        .then(processMeetingResponse)
}

export const createMeeting = (meeting: Meeting): Promise<Meeting> => {
    const modifiedMeetingRequest = {...meeting, onlineMeetingJoinUrl: stringToBase64(meeting.onlineMeetingJoinUrl)}

    return post(`/client-management/profiles/${meeting.profileId}/meetings`, modifiedMeetingRequest)
        .then(extractBody)
        .then(processMeetingResponse);
};

export const scheduleMeeting = (meeting: Meeting): Promise<Meeting> => {
    const modifiedMeetingRequest = {...meeting, onlineMeetingJoinUrl: stringToBase64(meeting.onlineMeetingJoinUrl)}

    return post(`/client-management/profiles/${meeting.profileId}/meetings/scheduled`, modifiedMeetingRequest)
        .then(extractBody)
        .then(processMeetingResponse);
}

export const getScheduledMeetings = (profileId: string): Promise<Meeting[]> => {
    return get(`/client-management/profiles/${profileId}/meetings/scheduled`)
        .then(extractBody)
        .then(meetings => (meetings as Meeting[]).map(meeting => processMeetingResponse(meeting)));
}

export const updateMeeting = (meeting: Meeting): Promise<Meeting> => {
    const modifiedMeetingRequest = {...meeting, onlineMeetingJoinUrl: stringToBase64(meeting.onlineMeetingJoinUrl)}

    return put(`/client-management/profiles/${meeting.profileId}/meeting/${meeting.id}`, modifiedMeetingRequest)
        .then(extractBody)
        .then(processMeetingResponse)
};

export const addParticipant = (profileId: string, meetingParticipant: MeetingParticipant): Promise<MeetingParticipant> => {
    return post(`/client-management/profiles/${profileId}/meetingParticipant`, meetingParticipant).then(extractBody);
};

export const getMeetingParticipants = (profileId: string, onlineMeetingId: string): Promise<MeetingParticipant[]> => {
    return get(`/client-management/profiles/${profileId}/meetingParticipants`, {
        queryParams: {
            onlineMeetingId
        }
    }).then(extractBody)
}

export const meetingApiClient = {
    getMeetingsHistory: getMeetingsHistory,
    getMeeting: getMeeting,
    createMeeting: createMeeting,
    updateMeeting: updateMeeting,
    scheduleMeeting: scheduleMeeting,
    getScheduledMeetings: getScheduledMeetings,
    addParticipant: addParticipant,
    getMeetingParticipants: getMeetingParticipants,
}

const extractBody = (res: Response) => res.json();

const processMeetingResponse = (meetingResponse: Meeting) => {
    if (isURL(meetingResponse.onlineMeetingJoinUrl)) return meetingResponse;

    return ({
        ...meetingResponse,
        onlineMeetingJoinUrl: base64ToString(meetingResponse.onlineMeetingJoinUrl)
    })
}

const isURL = (testString: string) => {
    let exp = new RegExp("^https?:\/\/.*");
    return exp.test(testString);
}