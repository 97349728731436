import React, {ChangeEventHandler} from "react";

type AssetStackCheckboxProps = {
    isChecked: boolean;
    isDisabled: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
}

const AssetStackCheckbox: React.FC<AssetStackCheckboxProps> = ({isChecked, isDisabled, onChange}) => {
    return <input className={"asset-stack-checkbox"}
                  type="checkbox"
                  checked={isChecked}
                  disabled={isDisabled}
                  onChange={onChange}/>;
}

export default AssetStackCheckbox;