import React from "react";
import {NO_OP} from "../../constants/common";
import {camelize} from "../../utils/stringUtils";
import {Label} from "../Label";
import NumberInput from "./NumberInput";

type YearsInputProps = {
    className?: string,
    inputDescription?: string,
    label: string,
    onBlur?: (e: any) => void,
    onChange: (e: any) => void,
    required?: boolean,
    error?: string | null,
    hasInteractions?: boolean,
    value: number,
    whenUserHasInteracted?: () => void,
    forceShowErrors?: boolean
    readOnly?: boolean,
}

export const YearsInput = (props: YearsInputProps) => {
    const {
        className = "",
        inputDescription,
        label,
        onBlur = NO_OP,
        onChange,
        required = false,
        value,
        error,
        hasInteractions,
        whenUserHasInteracted = NO_OP,
        forceShowErrors,
        readOnly = false
    } = props;
    const lowerLabel = camelize(label);
    const errorsWillBeShown = !!error && (forceShowErrors || hasInteractions);
    return (
        <div>
            <div className={className} style={{marginBottom: '8px'}}>
                <Label label={label} required={required}/>
                <NumberInput
                    className={`${lowerLabel}Field profileDetails`}
                    name={lowerLabel}
                    aria-label={lowerLabel}
                    aria-labelledby={`${lowerLabel}-label`}
                    id={`${lowerLabel}Input`}
                    onChangeValue={onChange}
                    onBlur={(e: any) => {
                        onBlur(e);
                        whenUserHasInteracted()
                    }}
                    removeMarginTop
                    size="medium"
                    value={value}
                    error={errorsWillBeShown ? error : undefined}
                    inlineLabel="years"
                    inlineLabelAlign="right"
                    readOnly={readOnly}
                />
                {inputDescription && <div
                    className={(errorsWillBeShown ? "yearsInputDescriptionWithErrors" : "yearsInputDescriptionSansErrors")}
                    id={`ageInput__description`}>{inputDescription}</div>}
            </div>
        </div>
    );

}
