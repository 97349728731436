import React, {useContext, useMemo, useState} from "react";
import {AccordionItem, AccordionItemListHeader} from "../components/Accordion";
import classNames from "classnames";
import {AccordionItemPresentValueHeader} from "../components/Accordion/AccordionItemPresentValueHeader";
import {Accordion} from "../components";
import {GroupedTable} from "../components/Table/GroupedTable";
import {AssetTitlingAccordionItem} from "./mappers";
import {NO_OP} from "../constants/common";

export type AssetTitlingAccordionProps = {
    accordionItems: AssetTitlingAccordionItem[],
    isFromReport?: boolean,
    onMainExpandChange?: Function,
    sendUuidToAt?: Function
};

export function AssetTitlingAccordion({accordionItems, isFromReport}: AssetTitlingAccordionProps) {
    const expandedAccordionItems = useMemo(() => {
        return isFromReport ? accordionItems.map(value => `asset-titling-${value.id}`) : [];
    }, [isFromReport, accordionItems]);

    const [isExpandedMain, updateIsExpandedMain] = useState(false);
    const onExpandedChanged  = (isExpanded: boolean) => {
        updateIsExpandedMain(isExpanded)
        if (isExpanded) {
            for (const element of accordionItems) {
                let uuid = "asset-titling-" + element.id;
                if(!expandedAccordionItems.includes(uuid)) {
                    expandedAccordionItems.push(uuid)
                }
            }
        } else {
            expandedAccordionItems.splice(0, expandedAccordionItems.length)
        }
    }

    const sendUuidToAssetTitling = (uuid: string) => {
        const index = expandedAccordionItems.indexOf(uuid);
        if (index > -1) {
            expandedAccordionItems.splice(index, 1);
        }
        updateIsExpandedMain(false);
    }
    return (
        <>
            <AccordionItemListHeader
                testId={"asset-titling-accordion-header"}
                firstColumnTitle="Distributed By"
                lastColumnTitle="Present Value"
                className={classNames("asset-titling-summary-grid", "asset-titling-grid-table")}
                onExpandedChanged={onExpandedChanged}
                initialIsExpanded={false}
                isExpandedMain={isExpandedMain}/>
            <Accordion expanded={expandedAccordionItems}>
                {
                    accordionItems.map(item => (
                        <AccordionItem
                            key={item.id}
                            uuid={`asset-titling-${item.id}`}
                            primaryText={item.primaryText}
                            accentColor={item.accentColor}
                            rightAlignedContent={
                                <AccordionItemPresentValueHeader value={item.totalValue}/>
                            }
                            expandable={item.hasData}
                            sendUuidToAssetTitling={sendUuidToAssetTitling}
                            expandedAccordionItems={expandedAccordionItems}
                            isMainExpanded={isExpandedMain}>
                            {
                                item.hasData &&
                                <GroupedTable
                                    columnHeaders={["Name", "Present Value"]}
                                    tableData={item.tableData}
                                />
                            }
                        </AccordionItem>
                    ))
                }
            </Accordion>
        </>
    );
}