import {ClientPersonaId} from "../models/Reporting";
import React, {useEffect, useState} from "react";
import ModalWrapper from "../../components/Modal/ModalWrapper/ModalWrapper";
import {RadioGroup} from "../../components";

type UploadReportToDchProps = {
    showPersonaModal: boolean,
    label: string,
    clientPersonaIds: Array<ClientPersonaId>
    onCancel: () => void,
    sendToDCH: (clientPersonaId: ClientPersonaId) => void
}

export function UploadReportToDch({
                                      showPersonaModal,
                                      label,
                                      clientPersonaIds,
                                      onCancel,
                                      sendToDCH
                                  }: UploadReportToDchProps) {
    const [selectedPersonaId, setSelectedPersonaId] = useState<ClientPersonaId | null>( {
        ppIdentityName: "Test Client",
        personaId: "10023456",
        pploginId: "abc"
    });

    useEffect(() => {
        if (clientPersonaIds.length) {
            setSelectedPersonaId(clientPersonaIds[0]);
        }
    }, [clientPersonaIds]);

    const setSelectedClientUser = (selectedPploginId: string) => {
        const personaId = clientPersonaIds.find(persona => getClientNameAlongWithPPId(persona) === selectedPploginId);
        setSelectedPersonaId(personaId || null);
    }

    function onSubmit() {
        sendToDCH(selectedPersonaId!)!
    }

    function getClientNameAlongWithPPId(clientPersonaId : ClientPersonaId)
    {
        return `${clientPersonaId?.ppIdentityName} (${clientPersonaId!.pploginId})`
    }

    return <ModalWrapper
        id="persona-modal"
        isOpen={showPersonaModal}
        headerText={"Send Report To Client"}
        size="small"
        buttons={[
            {
                text: "Cancel",
                onClick: onCancel
            },
            {
                text: "Send to Private Passport",
                primary: true,
                onClick: onSubmit
            }
        ]}
    >
        <div>
            <div className="font-md">
                Select client user ID to upload report to their corresponding Private Passport account.
                Report PDF is also available for download.
            </div>
            <div>
                <RadioGroup name={""}
                            layout="vertical"
                            label={label}
                            values={clientPersonaIds.map(persona => getClientNameAlongWithPPId(persona))}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                let newValue = e.target.value;
                                setSelectedClientUser(newValue);
                            }}
                            selected={getClientNameAlongWithPPId(selectedPersonaId!)}
                />
            </div>
        </div>

    </ModalWrapper>;
}