import {ColumnCounter, TableCell, TableDisplay, TableRow} from "../../../components";
import {formatCurrency} from "../../../utils/format";
import {getGoalColor} from "../../../utils/colorPicker";
import {
    AssetRelianceAssetType,
    AssetRelianceDescribedValueDTO,
    AssetRelianceGoalSummaryDTO,
    AssetRelianceGoalTypeDTO,
    AssetRelianceStack,
    GoalAmounts,
    GoalAmountType
} from "../../../ClientManagement/models/AssetRelianceResponse";
import {GoalType} from "../../../Goals/models/GoalType";


export class GoalTableDisplayReport extends TableDisplay<number> {
    constructor(
        columnCounter: ColumnCounter,
        private readonly goalsSummary_: AssetRelianceGoalSummaryDTO,
        private readonly stacks_: AssetRelianceStack[],
        private readonly isGoalsContinued_ : boolean,
        private readonly originalGoalsSummaryAfterRemovingPreviousGoals_: AssetRelianceGoalSummaryDTO,
        private readonly originalGoalsSummary_: AssetRelianceGoalSummaryDTO
        ) {
        super(columnCounter, (value) => formatCurrency(value > 0 ? -value : value));
    }

    get rows(): TableRow<number>[] {
        return this.goalsSummary_.goalTypes.map((goalType_, rowIndex_): TableRow<number> => ({
            accentColor: getGoalColor(goalType_.type),
            uniqueIdentifier: `${rowIndex_}-${goalType_.type}-${goalType_.description}`,
            label: goalType_.description,
            values: this.generateColumnValues_(goalType_, goalType_.totalAmounts),
            children: goalType_.data.map((goal_, goalIndex_): TableRow<number> => ({
                accentColor: getGoalColor(goalType_.type),
                uniqueIdentifier: `${goalIndex_}-${goalType_.type}-${goalType_.description}-${goal_.description}`,
                label: goal_.description,
                subtitle: GoalTableDisplayReport.createSubtitle_(goal_),
                values: this.generateColumnValues_(goalType_, goal_.amounts, true, goal_.isFundedByNonInvestableAssets),
                children: []
            }))
        }));
    }

    private generateColumnValues_(
        goalType_: AssetRelianceGoalTypeDTO,
        amounts_: GoalAmounts[],
        individualGoal_: boolean = false,
        fundedByNonInvestable_: boolean = false
    ): TableCell<number>[] {
        const columnValues_ = Array(this.columnCount).fill(
            amounts_.find(value_ => value_.goalAmountType === GoalAmountType.PresentValue)?.amount
        );

        for (let columnIndex_ = 0; columnIndex_ < columnValues_.length; columnIndex_++) {
            const goalAmountTypeForStack_ = this.stacks_[columnIndex_]
                .goalAmountTypes?.find(value_ => value_.goalType === goalType_.type)?.goalAmountType;

            if (goalAmountTypeForStack_) {
                columnValues_[columnIndex_] = amounts_.find(value_ => value_.goalAmountType === goalAmountTypeForStack_)?.amount
            }

            if (this.stacks_[columnIndex_].stackAssetType === AssetRelianceAssetType.INVESTABLE_PORTFOLIO_ASSET) {
                if (goalType_.type === GoalType.DISCRETIONARY || goalType_.type === GoalType.FAMILY || goalType_.type === GoalType.PHILANTHROPIC) {
                    if (individualGoal_) {
                        if (fundedByNonInvestable_) {
                            columnValues_[columnIndex_] = null
                        }
                    }
                    else {
                        if (goalType_.type === GoalType.DISCRETIONARY) {
                            const goalsDiscretionary : AssetRelianceGoalTypeDTO = this.originalGoalsSummary_.goalTypes.find(ele => ele.type === GoalType.DISCRETIONARY)!
                            columnValues_[columnIndex_] = this.recalculateValue(goalAmountTypeForStack_, goalsDiscretionary.data)
                        } else if (goalType_.type === GoalType.FAMILY) {
                            const goalsFamily : AssetRelianceGoalTypeDTO = this.originalGoalsSummary_.goalTypes.find(ele => ele.type === GoalType.FAMILY)!
                            columnValues_[columnIndex_] = this.recalculateValue(goalAmountTypeForStack_, goalsFamily.data)
                        } else if (goalType_.type === GoalType.PHILANTHROPIC) {
                            const goalsPhilanthropic : AssetRelianceGoalTypeDTO = this.originalGoalsSummary_.goalTypes.find(ele => ele.type === GoalType.PHILANTHROPIC)!
                            columnValues_[columnIndex_] = this.recalculateValue(goalAmountTypeForStack_, goalsPhilanthropic.data)
                        }

                    }
                }
            }
        }

        return columnValues_.map(value => this.createTableCell({value}));
    }

    private generateColumnValues__(
        goalType_: AssetRelianceGoalTypeDTO,
        amounts_: GoalAmounts[],
        individualGoal_: boolean = false,
        fundedByNonInvestable_: boolean = false
    ): TableCell<number>[] {
        const columnValues_ = Array(this.columnCount).fill(
            amounts_.find(value_ => value_.goalAmountType === GoalAmountType.PresentValue)?.amount
        );

        for (let columnIndex_ = 0; columnIndex_ < columnValues_.length; columnIndex_++) {
            const goalAmountTypeForStack_ = this.stacks_[columnIndex_]
                .goalAmountTypes?.find(value_ => value_.goalType === goalType_.type)?.goalAmountType;

            if (goalAmountTypeForStack_) {
                columnValues_[columnIndex_] = amounts_.find(value_ => value_.goalAmountType === goalAmountTypeForStack_)?.amount
            }

            if (this.stacks_[columnIndex_].stackAssetType === AssetRelianceAssetType.INVESTABLE_PORTFOLIO_ASSET) {
                if (goalType_.type === GoalType.DISCRETIONARY || goalType_.type === GoalType.FAMILY || goalType_.type === GoalType.PHILANTHROPIC) {
                    if (individualGoal_) {
                        if (fundedByNonInvestable_) {
                            columnValues_[columnIndex_] = null
                        }
                    } else {
                        columnValues_[columnIndex_] = this.recalculateValue(goalAmountTypeForStack_, goalType_.data)
                    }
                }
            }
        }

        return columnValues_.map(value => this.createTableCell({value}));
    }

    private recalculateValue(
        goalAmountTypeForStack_: GoalAmountType | undefined,
        goals_: AssetRelianceDescribedValueDTO[]
    ): number {
        let totalAmount_ = 0;

        goals_.forEach(goal_ => {
            if (!goal_.isFundedByNonInvestableAssets) {
                let goalValue_;
                if (goalAmountTypeForStack_) {
                    goalValue_ = goal_.amounts.find(value_ => value_.goalAmountType === goalAmountTypeForStack_)?.amount;
                } else {
                    goalValue_ = goal_.amounts.find(value_ => value_.goalAmountType === GoalAmountType.PresentValue)?.amount
                }
                if (goalValue_) {
                    totalAmount_ = totalAmount_ + goalValue_
                }
            }
        })

        return totalAmount_;
    }

    protected get headerLabel(): string {
        let headerLabel = "Goals";
        if (this.isGoalsContinued_) {
            headerLabel = headerLabel + " (continued)";
        }
        return headerLabel;
    }

    protected get headerValues(): TableCell<number>[] {
        const headerValues_: number[] = Array(this.columnCount).fill(null);
        if(this.isGoalsContinued_ === false)
        {
            for (let columnIndex_ = 0; columnIndex_ < this.columnCount; columnIndex_++) {
                this.getOriginalStackRows().forEach(row => {
                    headerValues_[columnIndex_] += Math.abs(row.values[columnIndex_].originalValue);
                });
            }
        }
        return headerValues_.map((value) => this.createTableCell({value}));
    }

    private getOriginalStackRows(): TableRow<number>[] {
        return this.originalGoalsSummaryAfterRemovingPreviousGoals_.goalTypes.map((goalTypeOfAsset__, rowIndex_): TableRow<number> => ({
            accentColor: getGoalColor(goalTypeOfAsset__.type),
            uniqueIdentifier: `${rowIndex_}-${goalTypeOfAsset__.type}-${goalTypeOfAsset__.description}`,
            label: goalTypeOfAsset__.description,
            values: this.generateColumnValues__(goalTypeOfAsset__, goalTypeOfAsset__.totalAmounts),
            children: goalTypeOfAsset__.data.map((goal_, goalIndex_): TableRow<number> => ({
                accentColor: getGoalColor(goalTypeOfAsset__.type),
                uniqueIdentifier: `${goalIndex_}-${goalTypeOfAsset__.type}-${goalTypeOfAsset__.description}-${goal_.description}`,
                label: goal_.description,
                subtitle: GoalTableDisplayReport.createSubtitle_(goal_),
                values: this.generateColumnValues__(goalTypeOfAsset__, goal_.amounts, true, goal_.isFundedByNonInvestableAssets),
                children: []
            }))
        }));
    }

    private static createSubtitle_(goal_: AssetRelianceDescribedValueDTO): string | undefined {
        if (!goal_.firstName || !goal_.lastName || !goal_.age) {
            return undefined;
        }

        return `${goal_.firstName} ${goal_.lastName}, ${goal_.age}`;
    }
}