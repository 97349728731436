import React from "react";
import {InfoPopover} from "../../components";
import {formatFundingCurrency} from "./fundingUtils";

type GoalAchievedPopoverProps = {
    presentValueAtYear: number[];
    startAge: number;
    isHeader?: boolean;
};

const GoalAchievedPopover: React.FC<GoalAchievedPopoverProps> = ({ presentValueAtYear, startAge, isHeader = false }) => {
    let achievedAgeIndex = presentValueAtYear.findIndex(presentValueForYear => !presentValueForYear);
    achievedAgeIndex = (achievedAgeIndex > -1) ? achievedAgeIndex : presentValueAtYear.length

    const achievedAge = startAge + achievedAgeIndex
    const presentValueToday = presentValueAtYear?.[0];

    return <div className="goal-funding-achieved-column">
        <span className={isHeader ? "goal-funding-achieved-column__text-header" : "goal-funding-achieved-column__text"}>Achieved</span>
        <InfoPopover
            content={
                <div className="goal-funding-achieved-popover">
                    <div className="goal-funding-achieved-popover__title">
                        <span>{"Goal" + (isHeader ? "s " : " ") + "achieved or funded at the age of " + achievedAge + ":"}</span>
                    </div>
                    <div className="font-md goal-funding-achieved-popover__present-value">
                        <span>Present Value Today</span>
                        <span>{formatFundingCurrency(presentValueToday)}</span>
                    </div>
                </div>
            }
            direction="left"
            width="300"
            arrow={true}
            size={isHeader ? "medium" : "small"}
        />
    </div>
}

export default GoalAchievedPopover;