import React from "react";

import {Dropdown, DropdownItem} from "../components";
import {ServiceTeamMemberAgenda} from "./models/AgendaDetails";

export type EditableDropdownAgendaProps = {
    defaultText ?:string
    label: string,
    value: string,
    error?: string,
    items: ServiceTeamMemberAgenda[],
    onAdd: (newValue: string) => void
    onChange: (newValue: string) => void,
    onBlur?: () => void,
};

export function EditableDropdownAgenda({
                                           defaultText,
                                           label,
                                           value,
                                           error,
                                           items,
                                           onAdd,
                                           onChange,
                                           onBlur,
                                       }: EditableDropdownAgendaProps) {
    return <Dropdown
        defaultText={defaultText}
        aria-label={label}
        searchable
        id={label}
        value={value}
        error={error}
        onChange={(data) => data.value && onChange(data.value)}
        onBlur={onBlur}
        onKeyPress={(data: any) => {
            const trimmedValue = data.target.value.trim();
            if (data.key === 'Enter' && trimmedValue) {
                onAdd(trimmedValue);
            }
        }}
    >
        {items.map(v => getDropdownItem(v))}
    </Dropdown>

}

function getDropdownItem(value: ServiceTeamMemberAgenda) {
    return <DropdownItem itemText={value.partnerName} value={value.partnerCode} key={value.partnerCode} disabled={value.disabled}/>
}
