import {TabsProvider as XpsTabsProvider} from 'xps-react';
import React, {ReactNode} from "react";

type TabsProviderProps = {
    defaultActiveIndex?: number,
    tabIdArray: string[],
    children: ReactNode
}

const TabsProvider = ({
                          defaultActiveIndex = 0,
                          tabIdArray,
                          children,
                      }: TabsProviderProps) => {
    return <XpsTabsProvider
        defaultActiveIndex={defaultActiveIndex}
        tabIdArray={tabIdArray}>
        {children}
    </XpsTabsProvider>
}

export default TabsProvider;
