import {EstateValue, HoldingAssetSubclassDetails} from "src/Assets/models/Holding";
import {Icon} from "src/components";
import {NO_OP} from "src/constants/common";
import {EditProductsSubclassDetailsRow} from "../EditProductsSubclassDetailsRow";
import {HoldingSummaryAccordion} from "../HoldingSummaryAccordion";
import {AssetClassifications} from "../../../models/AssetClassifications";
import {HoldingsScrollableContainer} from '../HoldingsScrollableContainer';
import {EditProductHolding} from "../EditProductHolding";
import {EditableHoldingFields} from "../EditHoldingValues";

type HoldingsProps = {
    selectedHoldings: HoldingAssetSubclassDetails[],
    hasInvalidProducts: boolean,
    assetClassifications: AssetClassifications,
    isValidHolding: (holding: HoldingAssetSubclassDetails) => boolean | "" | undefined,
    onUpdateHolding: (value: number | string | null | undefined | EstateValue,
                      index: number,
                      valueType: EditableHoldingFields) => void,
    inEstateOwnershipPercentage: number
    focusOnIndex?: number,
    focusOnField?: EditableHoldingFields,
    expanded?: string[],
    onChange?: (items?: string[]) => void,
    onDeleteHolding: (index: number) => void
}

const Holdings = ({
                      selectedHoldings,
                      hasInvalidProducts,
                      isValidHolding,
                      onUpdateHolding,
                      assetClassifications,
                      inEstateOwnershipPercentage,
                      focusOnIndex,
                      focusOnField,
                      expanded,
                      onChange = NO_OP,
                      onDeleteHolding
                  }: HoldingsProps) => {
    const assetSubclassDetailsRowRenderer = (assetSubclassDetail: HoldingAssetSubclassDetails, index: number) =>
        <EditProductsSubclassDetailsRow
            key={`asset-subclass-details-${index}`}
            index={index}
            assetSubclassDetail={assetSubclassDetail}
            onDelete={onDeleteHolding}
            onUpdate={onUpdateHolding}
            isProductNameInvalid={!isValidHolding(selectedHoldings[index]) && hasInvalidProducts}
            inEstateOwnershipPercentage={inEstateOwnershipPercentage}
            focusOnField={index === focusOnIndex ? focusOnField : undefined}
        />;

    return (
        <div className="holding-grid-container">
            <div className="paddingleft-12 holding-grid grid-display-contents" role="row">
                    <span className="display-flex align-items-center paddingbottom-md" role="cell">
                    <Icon name="chevron_double_right"/>
                    <span className="condensed-subtitle paddingleft-md">Asset Class / Subclass</span>
                    </span>
                <span className="condensed-subtitle" role="cell">Ticker</span>
                <span className="condensed-subtitle" role="cell">Product</span>
                <span className="condensed-subtitle" role="cell">Cusip</span>
                <span className="condensed-subtitle textalign-right" role="cell">Tax Cost</span>
                <span className="condensed-subtitle textalign-right" role="cell">Market Value</span>
                <span className="condensed-subtitle textalign-right" role="cell">Investable Value</span>
                <span role="cell"></span>
            </div>
            <HoldingsScrollableContainer bottomMargin={75}>
                <HoldingSummaryAccordion
                    riskAssetClasses={assetClassifications.riskAssetClasses}
                    riskControlAssetClasses={assetClassifications.riskControlAssetClasses}
                    assetSubclassRowRenderer={(assetSubclass, holdingsWithIndex, subclassIndex) => {
                        return <EditProductHolding
                            key={subclassIndex}
                            assetSubclass={assetSubclass}
                            holdingsWithIndex={holdingsWithIndex}
                            subclassIndex={subclassIndex}
                            assetSubclassDetailsRowRenderer={assetSubclassDetailsRowRenderer}/>
                    }}
                    selectedHoldings={selectedHoldings}
                    expanded={expanded}
                    onChange={onChange}
                />
            </HoldingsScrollableContainer>
        </div>
    )
};

export default Holdings;
