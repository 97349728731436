import {formatCurrency} from "../../utils/format";
import {getRiskAndRiskControlWithPercent} from "../StandaloneAccount/Holdings/holdingUtils";
import DataEntrySummary from "../../components/DataEntry/DataEntrySummary";
import classNames from "classnames";
import {Button} from "../../components";
import React from "react";
import {AssetType} from "../models/Assets";
import {HoldingSummary} from "../models/Holding";
import {AssetClassifications} from "../models/AssetClassifications";

type AccountSummaryProps = {
    assetType: AssetType,
    holdings?: HoldingSummary
    unrealizedCapitalGainsTax?: number | null
    deferredTaxLiability?: number | null
    onClick: () => Promise<void>,
    classifications: AssetClassifications
};

export function AccountSummary({
                                   assetType,
                                   holdings,
                                   unrealizedCapitalGainsTax,
                                   deferredTaxLiability,
                                   onClick,
                                   classifications
                               }: AccountSummaryProps) {
    const hasTotalTaxCost = holdings?.totalTaxCost != null;
    const hasTotalMarketValue = holdings?.totalMarketValue != null;
    const hasTotalInvestableValue = holdings?.totalInvestableValue != null;
    const hasHoldings = hasTotalTaxCost || hasTotalMarketValue || hasTotalInvestableValue;

    const hasUnrealizedCapitalGains = unrealizedCapitalGainsTax != null;
    const hasDeferredTaxLiability = deferredTaxLiability != null;

    const accountSummarySidePanelItems = [];

    if (hasHoldings) {
        accountSummarySidePanelItems.push({
            label: 'Market Value',
            value: formatCurrency(holdings?.totalMarketValue!),
        });

        accountSummarySidePanelItems.push({
            label: 'Investable Value',
            value: formatCurrency(holdings?.totalInvestableValue!)
        });
    }

    if (hasUnrealizedCapitalGains) {
        accountSummarySidePanelItems.push({
            label: 'Estimated Unrealized Capital Gains Tax',
            value: formatCurrency(unrealizedCapitalGainsTax),
        });
    }

    if (hasDeferredTaxLiability) {
        accountSummarySidePanelItems.push({
            label: 'Estimated Deferred Tax Liability',
            value: formatCurrency(deferredTaxLiability),
        })
    }

    if (hasHoldings && (assetType === 'standaloneAccount' || assetType === 'partiallyOwnedInvestmentAccount'  || assetType === 'investmentProgramHAA' || assetType === 'bankingAccount')) {
        const {
            totalRiskAssets,
            totalRiskControl,
            totalRiskAssetsInPercent,
            totalRiskControlInPercent
        } = getRiskAndRiskControlWithPercent(holdings.allHoldings, classifications, "accountSummaryInfo");

        accountSummarySidePanelItems.push({
            label: 'Risk Assets',
            value: (<><span>{totalRiskAssetsInPercent}%</span> | <span>{formatCurrency(totalRiskAssets)}</span></>)
        });

        accountSummarySidePanelItems.push({
            label: 'Risk Control Assets',
            value: (<><span>{totalRiskControlInPercent}%</span> | <span>{formatCurrency(totalRiskControl)}</span></>)
        });
    }

    return <aside aria-label="Account Summary" className="account-summary-section">
        <DataEntrySummary title="Account Summary"
                          items={accountSummarySidePanelItems}>
            <div
                className={classNames("account-summary-content", {
                    'account-summary-empty-content': !hasHoldings
                })}
            >
                {
                    (!hasHoldings) &&
                    <span className="marginbottom-lg no-asset-details">No asset details entered.</span>
                }
                <Button
                    size="medium"
                    className="edit-holdings-button"
                    icon="left"
                    iconName="chart_bar"
                    onClick={onClick}>
                    {'Edit Holdings'}
                </Button>
            </div>
        </DataEntrySummary>
    </aside>;
}