import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {ProfileResponse} from "../../ClientManagement/models/ProfileResponse";
import {DiscountRateType} from "../models/DiscountRate";
import {InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import {AssetTotals} from "../../Assets/models/Assets";
import {goalsApiClient} from "../GoalsApiClient";
import {assetsApiClient} from "../../Assets/AssetsApiClient";
import {
    AllGoalsType,
    BeneficiaryGoals,
    BeneficiaryGoalsResponse,
    emptyGoalModel,
    GoalModelType
} from "../models/GoalModelType";
import {findAllFamilyMembersFor} from "../../ClientManagement/FamilyTree/RelationshipTypeUtil";
import {MemberType} from "../../ClientManagement/models/MemberType";
import {formatTaxLiabilities} from "../GoalsUtils";
import {GoalType, NonLifestyleGoalType} from "../models/GoalType";
import FamilyGoalUtils from "../Summary/FamilyGoalUtils";
import {parseLifestyleGoalResponse} from "../parseLifestyleGoalResponse";


function transformBeneficiaryGoals(response: BeneficiaryGoalsResponse, profile: ProfileResponse): BeneficiaryGoals {
    const memberForGoal = findAllFamilyMembersFor(profile.primaryContact as MemberType)
        .find((member) => member.id === response.beneficiaryId)

    if (!memberForGoal) {
        throw new Error(`Unable to find family member with ID ${response.beneficiaryId}`);
    }

    FamilyGoalUtils.transformFamilyGoalResponse(response)

    return {
        ...response,
        name: `${memberForGoal?.firstName} ${memberForGoal?.lastName}`,
        birthdate: memberForGoal?.birthdate,
        age: memberForGoal?.age
    }
}

const processFamilyGoalsResponse = (familyGoalsResponse: { [key: string]: BeneficiaryGoalsResponse }, proposal: ProfileResponse): GoalModelType['familyGoals'] => {
    let familyGoals: GoalModelType['familyGoals'] = {}

    for (const [beneficiaryId, beneficiaryGoalsResponse] of Object.entries(familyGoalsResponse)) {
        familyGoals[beneficiaryId] = transformBeneficiaryGoals(beneficiaryGoalsResponse, proposal)
    }

    return familyGoals
}

const getApprovedProfileFromProposal = async (proposal: ProfileResponse): Promise<ProfileResponse> => {
    if (!!proposal.profileIdForProposal) {
        return clientManagementApiClient.getProfile(proposal.profileIdForProposal)
    } else {
        return Promise.resolve(proposal)
    }
}

const getDiscountRateSelection = (profileId: string, previousGoalModel: GoalModelType): DiscountRateType => {
    const oldProposalId = previousGoalModel.proposal.id
    let discountSelection;
    if (profileId === oldProposalId) {
        discountSelection = previousGoalModel.discountRateSelection;
    } else {
        discountSelection = DiscountRateType.TRIPLE_NET
    }
    return discountSelection;
}

const getGoalsModel = async (profileId: string, previousGoalModel: GoalModelType, handleError: Function): Promise<GoalModelType> => {
    const apiRequests: [
        Promise<ProfileResponse>,
        Promise<InvestorGroupType>,
        Promise<AssetTotals>,
        Promise<AllGoalsType>
    ] = [
         clientManagementApiClient.getProfile(profileId),
         clientManagementApiClient.getInvestorGroup(profileId),
         assetsApiClient.getAssetTotals(profileId),
         goalsApiClient.getAllGoals(profileId)
    ]
    try {
        const promisesPromises = Promise.all(apiRequests)
        let [
            proposal,
            investorGroup,
            assets,
            allGoalsResponse
        ] = await promisesPromises

        const familyGoals: { [key: string]: BeneficiaryGoals } = processFamilyGoalsResponse(allGoalsResponse.family, proposal)

        const approvedProfile = await getApprovedProfileFromProposal(proposal)
        const lifestyleSpendingGoal = parseLifestyleGoalResponse(allGoalsResponse.lifeStyle, investorGroup.planningPeriod)
        const discretionaryGoals: NonLifestyleGoalType[] = []
        const philanthropicGoals: NonLifestyleGoalType[] = []

        allGoalsResponse.nonLifestyle.forEach((nonLifestyleGoal) => {
            if (nonLifestyleGoal.goalType === GoalType.DISCRETIONARY) {
                discretionaryGoals.push(nonLifestyleGoal)
            } else if (nonLifestyleGoal.goalType === GoalType.PHILANTHROPIC) {
                philanthropicGoals.push(nonLifestyleGoal)
            }
        })


        return {
            discountRateSelection: getDiscountRateSelection(profileId, previousGoalModel),
            lifestyleSpendingGoal,
            proposal,
            investorGroup,
            assets,
            familyGoals,
            discretionaryGoals,
            philanthropicGoals,
            approvedProfile,
            nonLifestyleGoals: allGoalsResponse.nonLifestyle,
            taxLiabilities: formatTaxLiabilities(assets.totalTaxLiabilities),
            nonLifestyleGoalChanged: false
        }
    } catch (e: Error | any) {
        if(e.headers) {
            handleError(e.headers.get('trace-id'));
        } else {
            handleError(e)
        }
        return emptyGoalModel
    }
}
export default {getGoalsModel, transformBeneficiaryGoals}
