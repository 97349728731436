import {ColumnCounter, TableCell, TableDisplay, TableRow} from "../../../components";
import {ReactElement} from "react";
import {AssetRelianceStack} from "../../models/AssetRelianceResponse";
import {getStackIdentity} from "../AssetRelianceUtil";

export class ShowInReviewTableDisplay extends TableDisplay<string> {
    constructor(
        columnCounter: ColumnCounter,
        private readonly assetStacks: AssetRelianceStack[],
        private readonly renderCheckboxFn?: (uniqueId: string) => ReactElement | string
    ) {
        super(
            columnCounter,
            renderCheckboxFn
                ? (uniqueId: string) => renderCheckboxFn(uniqueId)
                : undefined
        );
    }

    protected get headerLabel(): string {
        return "Show in Review Asset Reliance";
    }

    protected get headerValues(): TableCell<string>[] {
        return Array(this.columnCount)
            .fill(0)
            .map((_, index) => {
                const stack = this.assetStacks[index];
                return this.createTableCell(
                    {value: stack ? getStackIdentity(stack) : ''}
                );
            });
    }

    get rows(): TableRow<string>[] {
        return [];
    }
}