import {InvestorGroupMemberType, InvestorGroupType} from "../models/InvestorGroupType";
import {FutureValueOfExcessAssetsDTO} from "../../Assets/models/Assets";
import Highcharts from "highcharts";
import {formatCurrency} from "../../utils/format";
import {COLOR_RISK_CONTROL_ASSETS, NET_WORTH_OVER_TIME_PLOT_LINE} from "../../constants/colors";
import {FveDiscountRateType} from "../AssetReliance/AssetRelianceButtonState";

export type NetWorthOverTimeGraphProps = {
    investorGroup: InvestorGroupType,
    projectedNetWorthAge: number | undefined,
    redrawPlotLineTooltip: boolean,
    netWealthAtCurrentAge: number | undefined,
    futureValueOfExcessAssetsDTO: FutureValueOfExcessAssetsDTO,
    discountRate: FveDiscountRateType,
    includeLifeInsurance: boolean | undefined,
    handleClickPointer: (confirmedProjectedNetWorthAge: number | string) => void
    disableAnimation?: boolean;
}

function getFveValues({
                          netWealthAtCurrentAge,
                          discountRate,
                          futureValueOfExcessAssetsDTO,
                          includeLifeInsurance
                      }: NetWorthOverTimeGraphProps) {
    let fveByAge: number[];
    let useLifeInsuranceValues: string = includeLifeInsurance === true ? "yesLifeInsurance" : "noLifeInsurance";
    let fveByAgeReference: {
        [discountRate in FveDiscountRateType]: {
            [lifeInsuranceStatus: string]: number[]
        }
    } = {
        [FveDiscountRateType.TRIPLE_NET]: {
            noLifeInsurance: futureValueOfExcessAssetsDTO.futureValueOfExcessAssetsByAgeWithTNDR,
            yesLifeInsurance: futureValueOfExcessAssetsDTO.fveWithLifeInsuranceDeathValueByAgeTNDR,
        },
        [FveDiscountRateType.NOMINAL_NET]: {
            noLifeInsurance: futureValueOfExcessAssetsDTO.futureValueOfExcessAssetsByAgeWithNNDR,
            yesLifeInsurance: futureValueOfExcessAssetsDTO.fveWithLifeInsuranceDeathValueByAgeNNDR,
        },
    };
    fveByAge = fveByAgeReference[discountRate][useLifeInsuranceValues] || [];
    let maxFveValue: number = netWealthAtCurrentAge ?? 0;
    for (var i = 0; i < fveByAge.length; i++) {
        maxFveValue = Math.max(maxFveValue, fveByAgeReference[discountRate][useLifeInsuranceValues][i])
    }

    return {
        fveValues: [
            netWealthAtCurrentAge ?? 0,
            ...fveByAge,
        ],
        maxFveValue: maxFveValue
    }
}

export const createGraphOptions: (props: NetWorthOverTimeGraphProps) => Highcharts.Options
    = (props: NetWorthOverTimeGraphProps) => {
    let {
        investorGroup,
        projectedNetWorthAge,
        redrawPlotLineTooltip,
        handleClickPointer,
        disableAnimation = false,
    } = props;

    const chartFirstName = investorGroup.planningPeriod.memberType === InvestorGroupMemberType.PARTNER ?
        investorGroup.partnerMember?.firstName! : investorGroup.primaryMember?.firstName!;

    const drawPlotLineOnGraph = function (chart: Highcharts.Chart) {
        drawPlotLineWithVariableHeight(chart, (projectedNetWorthAge ?? 0) - investorGroup.planningPeriod.ageFrom);
        if (redrawPlotLineTooltip) {
            redrawPlotLineTooltip = false;
            drawPlotlineLabelTooltip(chart, (projectedNetWorthAge ?? 0) - investorGroup.planningPeriod.ageFrom, projectedNetWorthAge ?? 0);
        }
    }

    const lightFillColorHex = 'rgba(0, 160, 170, 0.2)';
    const darkFillColorHex = 'rgba(0, 160, 170, 0.4)';

    const zonesOptions: Highcharts.SeriesZonesOptionsObject[] = [{
        value: 0,
        color: COLOR_RISK_CONTROL_ASSETS,
        fillColor: darkFillColorHex,
    }, {
        value: projectedNetWorthAge,
        color: COLOR_RISK_CONTROL_ASSETS,
        fillColor: darkFillColorHex,
    }, {
        color: COLOR_RISK_CONTROL_ASSETS,
        fillColor: lightFillColorHex,
    }];

    return {
        title: {
            text: `NET WORTH OVER TIME, BY ${chartFirstName.toUpperCase()}'S AGE`,
            verticalAlign: 'bottom',
            align: 'left',
            x: 72,
            style: {
                fontSize: '15px',
                fontFamily: 'RobotoCondensed',
                fontWeight: '400',
                color: '#6B6E6F',
            }
        },
        accessibility: {
            enabled: true
        },
        credits: {
            enabled: false
        },
        chart: {
            marginRight: 80,
            marginLeft: 80,
            events: {
                load: function () {
                    drawPlotLineOnGraph(this);
                },
                redraw: function () {
                    drawPlotLineOnGraph(this);
                },
            }
        },
        tooltip: {
            formatter: function (
                this: Highcharts.TooltipFormatterContextObject,
                tooltip: Highcharts.Tooltip
            ) {
                return `<b>${formatCurrency(this.y ?? 0)}</b><br> Age ${this.x}`;
            },
            shared: true
        },
        xAxis: {
            type: 'linear',
            tickWidth: 0,
            lineColor: '#CECECE',
            gridLineWidth: 1,
            gridLineColor: '#EAEAEA',
            plotLines: getPlotLines(projectedNetWorthAge ?? 0),
            labels: {
                distance: 8,
                style: {
                    fontSize: '13px',
                    fontFamily: 'RobotoCondensed',
                    fontWeight: '400',
                    color: '#6B6E6F',
                    textAlign: 'center'
                }
            },
            minPadding: 0,
            maxPadding: 0,
        },
        yAxis: {
            title: {
                text: ''
            },
            max: getFveValues(props).maxFveValue,
            min: 0,
            tickIntervals: getFveValues(props).maxFveValue / 2,
            tickAmount: 3,
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            labels: {
                distance: 8,
                format: '${text}',
                style: {
                    fontSize: '13px',
                    fontFamily: 'RobotoCondensed',
                    fontWeight: '400',
                    color: '#6B6E6F',
                }
            },
            showFirstLabel: false,
        },
        plotOptions: {
            series: {
                animation: disableAnimation ? false : true,
                cursor: 'pointer',
                color: '#104866',
                point: {
                    events: {
                        click: (event) => {
                            let age: number | string = event.point.category
                            handleClickPointer(age);
                        }
                    }
                }
            }
        },
        series: [{
            events: {
                click: function (this: Highcharts.Series, event: any) {
                    zonesOptions[1].value = event.point.x;
                    this.update({
                        type: 'area',
                        zones: zonesOptions,
                    });
                }
            },
            type: 'area',
            zoneAxis: "x",
            zones: zonesOptions,
            pointStart: investorGroup.planningPeriod.ageFrom,
            marker: {
                enabled: false,
            },
            showInLegend: false,
            lineWidth: 3,
            data: getFveValues(props).fveValues,
        }]
    };
}

let elementSVG: any;

function drawPlotLineWithVariableHeight(chart: Highcharts.Chart, dataIndex: number) {
    var xAxis = chart.xAxis[0],
        yAxis = chart.yAxis[0],
        xValue = xAxis.toPixels(chart.series[0].data[dataIndex].x ?? 0, false),
        yValue = yAxis.toPixels(chart.series[0].data[dataIndex].y ?? 0, false);

    if (elementSVG) {
        elementSVG.destroy();
    }

    elementSVG = chart.renderer.rect(xValue - 2, yValue, 4, chart.plotHeight + chart.plotTop - yValue, 0, 1)
        .attr({
            stroke: NET_WORTH_OVER_TIME_PLOT_LINE,
            fill: NET_WORTH_OVER_TIME_PLOT_LINE,
            zIndex: 2,
        })
        .add();
}

function drawPlotlineLabelTooltip(chart: Highcharts.Chart, dataIndex: number, netWorthAge: number) {
    let xAxis = chart.xAxis[0],
        yAxis = chart.yAxis[0],
        yValue = yAxis.toPixels(chart.series[0].data[dataIndex].y ?? 0, false);

    let plotLineLabel = (xAxis.options?.plotLines as Highcharts.XAxisPlotLinesOptions[])[0].label;

    if (plotLineLabel) {
        plotLineLabel.y = yValue + ((chart.plotHeight + chart.plotTop - yValue) / 2) - 20;
        xAxis.update({
            ...xAxis.options,
            ...{
                options: {
                    plotLines: getPlotLines(netWorthAge)
                }
            }
        });
    }
}

const getPlotLines: (age: number) => Highcharts.XAxisPlotLinesOptions[] = (age: number) => {
    const horizontalOffsetToCenterLabel: number = (Math.log10(age) + 1) * -0.5;
    const horizontalRotationAngle: number = 0;
    return [{
        value: age,
        width: 0,
        label: {
            align: 'center',
            textAlign: 'center',
            color: '#0A7A82',
            rotation: horizontalRotationAngle,
            useHTML: true,
            x: horizontalOffsetToCenterLabel,
            formatter: function () {
                return `<div class="net-worth-over-time-chart-plot-line">${age}</div>`;
            }
        },
    }];
}