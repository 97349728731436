import {goalsApiClient} from "./GoalsApiClient";
import {
    LifestyleAndNonLifestylePresentValueRequest,
    LifestyleAndNonLifestylePresentValueResponse,
    NonLSPresentValueRequest
} from "./models/PresentValue";
import {FamilyGoalType} from "./models/FamilyGoalType";
import {NonLifestyleGoalType} from "./models/GoalType";
import {LifestyleSpendingGoal} from "./models/LifestyleSpendingGoal";


function buildPresentValueRequestForNonLifestyleGoal(nonlifestyleGoal: NonLifestyleGoalType, nonPortfolioFunded: number): NonLSPresentValueRequest {
    const {startDate, endDate} = nonlifestyleGoal.userInputs
    const annualFlow = String(nonlifestyleGoal.userInputs.annualFlow)
    const riskControlAsset = null !== nonlifestyleGoal.userInputs.riskControl
        ? String((nonlifestyleGoal.userInputs.riskControl).toFixed(2))
        : undefined

    return riskControlAsset
        ? {annualFlow, startDate, endDate, nonPortfolioFunded, riskControlAsset}
        : {annualFlow, startDate, endDate, nonPortfolioFunded};
}

const calculateLifestyleAndNonLSPresentValue = async (
    profileId: string,
    nonLifestyleGoal: FamilyGoalType | NonLifestyleGoalType,
    nonPortfolioFunded: number,
    lifestyleGoal?: LifestyleSpendingGoal,
): Promise<LifestyleAndNonLifestylePresentValueResponse> => {
    const presentValueRequest: NonLSPresentValueRequest = buildPresentValueRequestForNonLifestyleGoal(nonLifestyleGoal, nonPortfolioFunded);

    const request: LifestyleAndNonLifestylePresentValueRequest = {
        lifestylePresentValueRequest: lifestyleGoal?lifestyleGoal.userInputs:null,
        nonLifestyleGoal: {
            ...presentValueRequest,
            ...{
                goalId: nonLifestyleGoal.id || "",
                isFundedByNonInvestableAssets: nonLifestyleGoal.userInputs.isFundedByNonInvestableAssets,

            }
        }
    }
    return goalsApiClient.postLifestyleAndNonLSPresentValue(profileId, request);
}

export default {
    calculateLifestyleAndNonLSPresentValue
};
