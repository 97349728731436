import {AssetsSummary, CurrentNetWorthAsset} from "../../models/Assets";
import {COLOR_LIABILITIES} from "../../../constants/colors";
import {AssetAccordionHeader} from "../../AssetSummary/common/AssetAccordionHeader";
import {AccordionItemWithActionMenu} from "../../../components";
import React, {useContext} from "react";
import {PersonalLiability, PersonalLiabilitySummary} from "../../models/PersonalLiability";
import {InvestorGroupMember, InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {AssetAccordionContentHeader} from "../../AssetSummary/common/AssetAccordionContentHeader";
import {TableCell} from "../../../components/Table/TableCell";
import {PersonalLiabilityRow} from "../../AssetSummary/InEstate/PersonalLiabilityRow";
import {useHistory} from "react-router-dom";
import AssetsViewContext from "../../common/AssetsViewContext";
import {DeleteModalProps} from "../../common/AssetsView";
import {shortenName} from "../../common/textUtils";
import {assetListData} from "../../AssetSummary/common/utils";
import {
    calculateCNWAssetsJointlyOwnedTotalPresentValue,
    calculateCNWAssetsMemberOwnedTotalPresentValue,
    calculateJointlyOwnedValue,
    calculateMemberOwnedValue
} from "../../AssetSummary/common/AssetSummaryCalculator";

export interface PersonalLiabilitiesProps {
    profileId: string,
    assetsData: AssetsSummary;
    investorGroup: InvestorGroupType
    onRemoveAssetClick: (deleteModalProps: DeleteModalProps) => void;
}

const PersonalLiabilities = ({profileId, assetsData, investorGroup, onRemoveAssetClick}: PersonalLiabilitiesProps) => {

    const history = useHistory();
    const viewType = useContext(AssetsViewContext);

    const {
        getFormattedTotalPresentValue,
        getFormattedPresentValueForCurrentNetWorth,
        hasInEstatePersonalLiability,
        inEstatePersonalLiabilities
    } = assetListData(assetsData);

    const currentNetWorthAssets: CurrentNetWorthAsset[] = assetsData.personalLiabilities.map(pl => (
        {
            id: pl.id,
            name: pl.description,
            presentValue: pl.loanBalanceEstateValue.totalValue,
            assetType: 'liability',
            ownershipCategory: pl.ownershipCategory,
            memberOwnerships: pl.memberOwnerships,
        }
    ));

    const getFormattedMemberOwnedTotalPresentValue = (member?: InvestorGroupMember) => {
        return member
            ? getFormattedTotalPresentValue(calculateCNWAssetsMemberOwnedTotalPresentValue(member.id, currentNetWorthAssets) * -1)
            : undefined;
    };

    const getFormattedJointlyOwnedTotalPresentValue = () => {
        return investorGroup.partnerMember
            ? getFormattedTotalPresentValue(calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthAssets) * -1)
            : undefined;
    };

    return (
        <>
            {hasInEstatePersonalLiability &&
                <AccordionItemWithActionMenu
                    uuid="PersonalLiabilities"
                    accentColor={COLOR_LIABILITIES}
                    HeaderComponent={({expanded}) =>
                        <AssetAccordionHeader
                            expanded={expanded}
                            title="Personal Liabilities"
                            formattedPrimaryMemberTotalPresentValue={getFormattedMemberOwnedTotalPresentValue(investorGroup.primaryMember)}
                            formattedSecondaryMemberTotalPresentValue={getFormattedMemberOwnedTotalPresentValue(investorGroup.partnerMember)}
                            formattedJointTotalPresentValue={getFormattedJointlyOwnedTotalPresentValue()}
                            formattedTotalPresentValue={getFormattedTotalPresentValue(assetsData.liabilitiesValue.inEstateValue * -1)}
                            gridClassName={"current-net-worth-grid"}
                        />
                    }>

                    <div role="table" className="current-net-worth-grid-with-actionmenu assets-grid-table"
                         aria-label="personal-liabilities-table">
                        <AssetAccordionContentHeader investorGroup={investorGroup}/>

                        {inEstatePersonalLiabilities.map((personalLiability: PersonalLiabilitySummary) => {
                            return <PersonalLiabilityRow personalLiability={personalLiability}
                                                         actionsDisabled={false}
                                                         onClickEdit={(personalLiabilityId: string) => {
                                                             history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditPersonalLiability/${personalLiabilityId}`);
                                                         }}
                                                         onClickDelete={(personalLiabilityDelete: PersonalLiability) => {
                                                             onRemoveAssetClick({
                                                                 showDeleteModal: true,
                                                                 modalTitle: "Asset",
                                                                 assetDescription: personalLiabilityDelete.description,
                                                                 assetType: "liability",
                                                                 assetId: personalLiabilityDelete.id,
                                                             });
                                                         }}
                                                         key={personalLiability.id}
                                                         gridClassName={"current-net-worth-grid-with-actionmenu"}
                                                         renderPersonalLiabilityDetails={(liability: PersonalLiabilitySummary) => {
                                                             return <>
                                                                 <TableCell text={shortenName(liability.description)}
                                                                            className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}/>
                                                                 {investorGroup.partnerMember && <>
                                                                     <TableCell
                                                                         text={getFormattedPresentValueForCurrentNetWorth(
                                                                             calculateMemberOwnedValue(
                                                                                 investorGroup.primaryMember.id,
                                                                                 liability.memberOwnerships,
                                                                                 liability.ownershipCategory,
                                                                                 liability.loanBalanceEstateValue.totalValue
                                                                             ) * -1
                                                                         )}
                                                                         className="textalign-right"/>
                                                                     <TableCell
                                                                         text={getFormattedPresentValueForCurrentNetWorth(
                                                                             calculateMemberOwnedValue(
                                                                                 investorGroup.partnerMember.id,
                                                                                 liability.memberOwnerships,
                                                                                 liability.ownershipCategory,
                                                                                 liability.loanBalanceEstateValue.totalValue
                                                                             ) * -1
                                                                         )}
                                                                         className="textalign-right"/>
                                                                     <TableCell
                                                                         text={getFormattedPresentValueForCurrentNetWorth(
                                                                             calculateJointlyOwnedValue(
                                                                                 liability.ownershipCategory,
                                                                                 liability.loanBalanceEstateValue.totalValue
                                                                             ) * -1
                                                                         )}
                                                                         className="textalign-right"/>
                                                                 </>
                                                                 }
                                                             </>
                                                         }}/>;
                        })}
                    </div>

                </AccordionItemWithActionMenu>}
        </>
    );
}

export default PersonalLiabilities;
