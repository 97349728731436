import {
    emptyLifestyleGoalForm,
    LifestyleSpendingGoal,
    LifestyleSpendingGoalResponse
} from "./models/LifestyleSpendingGoal";
import {PlanningPeriodType} from "../ClientManagement/models/InvestorGroupType";

export const parseLifestyleGoalResponse = (
    response: LifestyleSpendingGoalResponse | undefined,
    planningPeriod: PlanningPeriodType
): LifestyleSpendingGoal => {
    if (response) {

        const filteredLifestyleSpendingPeriods = response.userInputs.lifestyleSpendingPeriods
            .filter(period => period.endYear > planningPeriod.startYear);
        const [
            firstSpendingPeriod,
            ...lifestyleSpendingPeriods
        ] = filteredLifestyleSpendingPeriods

        return {
            ...response,
            userInputs: {
                ...response.userInputs,
                lifestyleSpendingPeriods: [
                    {
                        ...firstSpendingPeriod,
                        startYear: planningPeriod.startYear
                    },
                    ...lifestyleSpendingPeriods
                ]
            }
        }
    }
    return emptyLifestyleGoalForm(planningPeriod);
}