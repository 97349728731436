import {Dropdown} from "../../../components";
import React, {ReactElement} from "react";

interface ProfileDetailsActionMenuProps {
    dropdownItems: ReactElement[]
}

export function ProfileDetailsActionMenu(props: ProfileDetailsActionMenuProps) {
    return (
        <div className={"profile-details-action-menu"}>
            <Dropdown
                id="profileDetailsActionMenu"
                name="dropdown-grouped"
                aria-label="profileDetailsMenu"
                className="marginright-sm"
                alignRight={true}
                buttonAriaLabel="Menu"
                buttonIcon="only"
                buttonSize="medium"
                defaultPageSize={15}
                disabled={false}
                dropUp={false}
                dropdownKind="menu"
                buttonKind="secondary"
                dynamicDropAlign={false}
                dynamicDropDirection={false}
                iconNameClose="menu_dots"
                iconNameOpen="menu_dots"
                labelWidth="100%"
                nativeOnMobile={false}
                open={false}
                panelHeight="auto"
                panelWidth={240}
                required={false}
                searchable={false}
                size="medium"
                value={null}
                virtualScroll={false}
            >
                {props.dropdownItems}
            </Dropdown>
        </div>
    )
}