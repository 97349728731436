import React, {MouseEvent, ReactNode, useEffect, useRef, useState} from 'react';
import {Loader, Search} from 'xps-react';

export interface PageableSearchResults<TResult> {
    hasMoreResults: boolean,
    results: TResult[]
}

type TypeaheadSearchBarProps<Type> = {
    performSearch: (searchTerm: string, page?: number) => Promise<PageableSearchResults<Type>>,
    minSearchTermLength?: number,
    placeholder: string,
    onSelect?: (result: Type) => void,
    displayResult: (result: Type) => ReactNode,
    resultHeader?: ReactNode,
    searchType?: string
};

export default function TypeaheadSearchBar<Type>({
                                                     performSearch,
                                                     minSearchTermLength = 1,
                                                     placeholder,
                                                     onSelect,
                                                     displayResult,
                                                     resultHeader,
                                                     searchType = ""
                                                 }: TypeaheadSearchBarProps<Type>) {
    const [results, setResults] = useState<Type[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMoreResults, setHasMoreResults] = useState(false);
    const [searchResultsType, setSearchResultsType] = useState<string>(searchType);
    const [isSearching, setIsSearching] = useState(false);

    let componentUnmounted = useRef(false);
    useEffect(() => {
        return () => {
            componentUnmounted.current = true;
        }
    }, []);

    useEffect(() => {
        if (searchResultsType !== searchType) {
            clearResults();
            setSearchResultsType(searchType)
        }
    }, [searchType])

    const clearResults = () => {
        setResults([]);
        setCurrentPage(1);
        setHasMoreResults(false);
    };

    const search = (searchText: string, page = 1) => {
        setSearchTerm(searchText);
        setCurrentPage(page);
        setIsSearching(true);

        performSearch(searchText, page)
            .then((response) => {
                if (componentUnmounted.current) return;
                setResults(
                    page > 1
                        ? [...results, ...response.results]
                        : response.results
                );
                setHasMoreResults(response.hasMoreResults);
            })
            .finally(() => {
                if (componentUnmounted.current) return;
                setIsSearching(false)
            });
    };

    const handleOnScroll = (e: MouseEvent<HTMLDivElement>) => {
        const target = e.currentTarget;
        const scrollBottom = target.scrollHeight - target.offsetHeight - 5;

        if (target.scrollTop >= scrollBottom && hasMoreResults && !isSearching) {
            search(searchTerm, currentPage + 1);
        }
    };

    return <div className="typeahead">
        <Search placeholder={placeholder}
                name={placeholder}
                handleChangeVal={async (searchInput: any) => {
                    if (searchInput.target.value.length === 0) {
                        clearResults();
                    } else if (searchInput.target.value.length >= minSearchTermLength) {
                        search(searchInput.target.value);
                    }
                }}
                onClearListener={clearResults}
        />
        {(results.length > 0) &&
            <div aria-label="Search Results" className={"dropdown__panel"} onScroll={handleOnScroll}>
                {resultHeader}
                {results.map((result, index) => {
                    return <div
                        className="dropdown__item"
                        key={`dropdown-item-${index}`}
                        onClick={() => {
                            onSelect && onSelect(result);
                            clearResults();
                        }}
                    >
                        {displayResult && displayResult(result)}
                    </div>
                })}
                {
                    hasMoreResults &&
                    <div className="more-results-loader">
                        <Loader
                            message="Loading more results..."
                            size="md"
                        />
                    </div>
                }
            </div>
        }
    </div>
}
