import {
    HasPercentageNumber,
    HasPercentageString,
    MemberOwnership,
    MemberOwnershipFormData,
    MemberOwnershipWriteModel,
    OwnershipCategory,
    OwnershipDetailsFormData,
    OwnershipReadModel,
    OwnershipWriteModel,
    SoleOwnerType,
} from "../models/Ownership";
import {InvestorGroupMember, MemberGroupMember} from "../../ClientManagement/models/InvestorGroupType";
import {calculateBirthdateFromAge} from "../../utils/dateUtils";

export function mapToOwnershipWriteModel(formData: OwnershipDetailsFormData): OwnershipWriteModel {
    const nonClientMemberWriteModels: MemberOwnershipWriteModel[] = formData.nonClientMemberOwnerships
        .map(mapPercentageToFloat);
    const memberOwnershipWriteModels: MemberOwnershipWriteModel[] = formData.memberOwnerships
        .map(mapPercentageToFloat);
    memberOwnershipWriteModels.push(...nonClientMemberWriteModels);

    return {
        ownershipCategory: formData.ownershipCategory,
        memberOwnerships: memberOwnershipWriteModels,
        legalEntityOwnerships: formData.legalEntityOwnerships.map(ownership => {
            return {
                legalEntity: {
                    type: ownership.type!, name: ownership.name,
                },
                showWithinOutOfEstateReport: ownership.showWithinOutOfEstateReport,
                percentage: mapPercentageToFloat(ownership).percentage
            }
        })
    };
}

export function mapToOwnershipDetailsFormData(ownershipReadModel: OwnershipReadModel): OwnershipDetailsFormData {
    return {
        soleOwnerType: getSoleOwnerType(ownershipReadModel),
        ownershipCategory: ownershipReadModel.ownershipCategory,
        memberOwnerships: ownershipReadModel.memberOwnerships.filter(isClientMember).map(mapToMemberOwnershipFormData),
        nonClientMemberOwnerships: ownershipReadModel.memberOwnerships.filter(o => !isClientMember(o)).map(mapToMemberOwnershipFormData),
        legalEntityOwnerships: ownershipReadModel.legalEntityOwnerships.map(readModel => {
            return {
                legalEntityId: readModel.legalEntity.id,
                name: readModel.legalEntity.name,
                type: readModel.legalEntity.type,
                percentage: mapPercentageToString(readModel).percentage,
                showWithinOutOfEstateReport: readModel.showWithinOutOfEstateReport
            }
        }),
    };
}

export function extractOwnershipDetailsFormData(formData: OwnershipDetailsFormData): OwnershipDetailsFormData {
    return {
        soleOwnerType: formData.soleOwnerType,
        ownershipCategory: formData.ownershipCategory,
        memberOwnerships: formData.memberOwnerships,
        nonClientMemberOwnerships: formData.nonClientMemberOwnerships,
        legalEntityOwnerships: formData.legalEntityOwnerships,
    };
}

export function mapSoleMemberOwnershipFormData(ownerMemberId: string): MemberOwnershipFormData {
    return {
        memberId: ownerMemberId,
        percentage: "100.00",
        isRevocableTrust: false
    };
}

export function mapSoleMemberOwnershipWriteModel(ownerMemberId: string): MemberOwnershipWriteModel {
    return {
        memberId: ownerMemberId,
        percentage: 100,
        isRevocableTrust: false
    };
}

export function mapSoleMemberOwnership(ownerMemberId: string): MemberOwnership {
    return {
        member: {
            id: ownerMemberId,
            name: '',
        },
        percentage: 100,
        isRevocableTrust: false,
        isInEstateMember: true,
    };
}

export function doesCategoryAllowNonClientOwnerships(category: OwnershipCategory): boolean {
    return ["TIC (Tenants in Common)", "Partial"].includes(category);
}

export function mapMemberOwnershipToWriteModel(memberOwnership: MemberOwnership): MemberOwnershipWriteModel {
    return {
        memberId: memberOwnership.member.id,
        percentage: memberOwnership.percentage,
        isRevocableTrust: memberOwnership.isRevocableTrust
    };
}

export function mapToMemberOwnershipWriteModel(memberOwnership: MemberOwnershipFormData): MemberOwnershipWriteModel {
    return {
        memberId: memberOwnership.memberId,
        percentage: mapPercentageToFloat(memberOwnership).percentage,
        isRevocableTrust: memberOwnership.isRevocableTrust,
    };
}

export function mapToMemberOwnershipFormData(memberOwnership: MemberOwnership): MemberOwnershipFormData {
    return {
        memberId: memberOwnership.member.id,
        percentage: mapPercentageToString(memberOwnership).percentage,
        isRevocableTrust: memberOwnership.isRevocableTrust,
    };
}

export function mapToInvestorMemberGroupMember(member: MemberGroupMember | undefined): InvestorGroupMember | undefined {
    if (member !== null && member !== undefined) {
        return {
            id: member.id,
            name: member.firstName + " " + member.lastName,
            firstName: member.firstName,
            birthdate: calculateBirthdateFromAge(member.age),
        }
    } else {
        return undefined;
    }
}

function isClientMember(memberOwnership: MemberOwnership): boolean {
    return memberOwnership.isInEstateMember;
}

function mapPercentageToFloat<T extends HasPercentageString>(ownership: T): Omit<T, 'percentage'> & HasPercentageNumber {
    return {
        ...ownership, percentage: parseFloat(ownership.percentage)
    };
        }

function mapPercentageToString<T extends HasPercentageNumber>(ownership: T): Omit<T, 'percentage'> & HasPercentageString {
    return {
        ...ownership, percentage: ownership.percentage.toString()
    };
}

function getSoleOwnerType(ownershipDetails: OwnershipReadModel): SoleOwnerType | undefined {
    const isNonSoleOwnership = ownershipDetails.legalEntityOwnerships.length && ownershipDetails.memberOwnerships.length;
    if (isNonSoleOwnership) {
        return undefined;
    }
    return ownershipDetails.legalEntityOwnerships.length ? ownershipDetails.legalEntityOwnerships[0].legalEntity.type : "Individual";
}
