import React, {useEffect, useRef, useState} from "react";
import {Col, Container, Row} from "react-grid-system";
import CurrentVsProposedLegend from "../../../ClientManagement/AssetAllocation/CurrentVsProposedLegend";
import EmptyAssetAllocation from "../../../ClientManagement/AssetAllocation/EmptyAssetAllocation";
import {
    COLUMN_WIDTH,
    CurrentVsProposedTableFooter,
    hasCurrentAllocation
} from "../../../ClientManagement/AssetAllocation/CurrentVsProposed";
import {
    AssetSubclassSummary,
    getFilteredAssetSubclassSummaries
} from "../../../ClientManagement/AssetAllocation/AssetHoldings";
import {riskAssetClasses, riskControlClasses} from "../../../ClientManagement/AssetAllocation/AssetClasses";
import {PresentationPaneHeader} from "../../../components";
import PrintViewWrapper from "../PrintViewWrapper";
import {COLOR_ASSETS_ACCOUNTS, COLOR_RISK_CONTROL_ASSETS} from "../../../constants/colors";
import AssetHoldingsReport from "./AssetHoldingsReport";
import {splitCurrentVsProposed} from "./CurrentVsProposedReportUtil";
import {ReviseAssetAllocationResponse} from "../../../ClientManagement/AssetAllocation/ReviseAssetAllocation";
import AssetAllocationRiskDonut from "../../../ClientManagement/AssetAllocation/AssetAllocationRiskDonut";
import {AssetClass, AssetClassifications} from "../../../Assets/models/AssetClassifications";

export type CurrentVsProposedReportPageData = {
    riskAssets: AssetSubclassSummary[],
    riskControl: AssetSubclassSummary[],
    continueLabel: string
}
type CurrentVsProposedContentProps = {
    currentAllocation: ReviseAssetAllocationResponse;
    proposedAllocation: ReviseAssetAllocationResponse;
    displayName: string;
    isDetailedReport: boolean,
    classifications: AssetClassifications
}

const InvestableAssets = <div className="investable-assets" style={{
    maxWidth: '315px',
    minWidth: '315px',
}}></div>;

const CurrentVsProposedReportView: React.FC<CurrentVsProposedContentProps> = ({
                                                                                  currentAllocation,
                                                                                  proposedAllocation,
                                                                                  displayName,
                                                                                  isDetailedReport,
                                                                                  classifications
                                                                              }) => {
    const [currentVsProposedPages, setCurrentVsProposedPages] = useState<CurrentVsProposedReportPageData[]>([]);
    const totalCurrentAllocationAmount = currentAllocation ? currentAllocation.totalCurrentRiskAssetsAmount + currentAllocation.totalCurrentRiskControlAssetsAmount : 0;
    const totalProposedAllocationAmount = proposedAllocation ? proposedAllocation.totalProposedRiskAssetsAmount + proposedAllocation.totalProposedRiskControlAssetsAmount : 0;
    const totalProposedAllocationPercentage = 100;
    const totalCurrentAllocationPercentage = 100;

    let totalRiskAssetsAmount: number | undefined;
    let totalRiskControlAssetsAmount: number | undefined;
    if (proposedAllocation.totalProposedRiskControlAssetsAmount !== 0 || proposedAllocation.totalProposedRiskAssetsAmount !== 0) {
        totalRiskAssetsAmount = proposedAllocation.totalProposedRiskAssetsAmount;
        totalRiskControlAssetsAmount = proposedAllocation.totalProposedRiskControlAssetsAmount;
    }

    useEffect(() => {
        const filteredRiskAssetSubclassSummaries = getFilteredAssetSubclassSummaries(
            riskAssetClasses,
            currentAllocation.riskAssets,
            proposedAllocation.riskAssets
        );

        let riskAssetSubclassSummary : AssetSubclassSummary[] = [];

        getAssetSubclassSummaryWithAssetsOrder(filteredRiskAssetSubclassSummaries,riskAssetSubclassSummary,classifications.riskAssetClasses);

        const filteredRiskControlSubclassSummaries = getFilteredAssetSubclassSummaries(
            riskControlClasses,
            currentAllocation.riskControlAssets,
            proposedAllocation.riskControlAssets
        );
        let riskControlAssetSubclassSummary : AssetSubclassSummary[] = [];

        getAssetSubclassSummaryWithAssetsOrder(filteredRiskControlSubclassSummaries,riskControlAssetSubclassSummary,classifications.riskControlAssetClasses);

        const currentVsProposedPage = {
            riskAssets: riskAssetSubclassSummary,
            riskControl: riskControlAssetSubclassSummary,
            continueLabel: ""
        }

        if (!isDetailedReport) {
            setCurrentVsProposedPages([{...currentVsProposedPage}]);
        } else {
            splitCurrentVsProposed(currentVsProposedPage, currentAllocation, proposedAllocation).then((res: CurrentVsProposedReportPageData[]) => {
                setCurrentVsProposedPages(res);
            });
        }


    }, [currentAllocation, proposedAllocation]);

    const containerRef = useRef<Container & HTMLDivElement>(null);
    const totalNoOfPages = currentVsProposedPages.length;

    function getAssetAccordionId() {
        return isDetailedReport ? "CurrentProposedRiskAssetsAccordionReportId" : "CurrentProposedRiskAssetsAccordionReportIdCollapsed";
    }

    function getRCAssetAccordionId() {
        return isDetailedReport ? "CurrentProposedRiskControlAssetsReportId" : "CurrentProposedRiskControlAssetsReportIdCollapsed";
    }

    function getAssetSubclassSummaryWithAssetsOrder(filteredAssetSubclassSummaries:AssetSubclassSummary[],assetSubclassSummary:AssetSubclassSummary[],assetClasses: AssetClass[])
    {
        assetClasses.forEach(asset => {
            const assetClassName = asset.assetClassName
            let currentAssetTotalInvestableAmount: number = 0
            let currentAssetTotalInvestablePercent: number = 0
            let proposedAssetTotalInvestableAmount: number = 0
            let proposedAssetTotalInvestablePercent: number = 0

            let assetSubclasses : AssetSubclassSummary[] = [];

            asset.assetSubclasses.forEach(assetSubclass => {
            const assetSubclassSummaryObj = filteredAssetSubclassSummaries.find(assetSubclassEle => assetSubclassEle.subclass.trim() === assetSubclass.assetSubclassName.trim())
                if (assetSubclassSummaryObj) {
                    if (assetSubclassSummaryObj.current) {
                        currentAssetTotalInvestableAmount = currentAssetTotalInvestableAmount + Math.round(assetSubclassSummaryObj.current?.currentInvestableAmount!)
                        currentAssetTotalInvestablePercent = currentAssetTotalInvestablePercent + (assetSubclassSummaryObj.current?.currentInvestableAmount! / totalCurrentAllocationAmount) * 100
                    }

                    if (assetSubclassSummaryObj.proposed) {
                        proposedAssetTotalInvestableAmount = proposedAssetTotalInvestableAmount + Math.round(assetSubclassSummaryObj.proposed?.proposedInvestableAmount!)
                        proposedAssetTotalInvestablePercent = proposedAssetTotalInvestablePercent +  (assetSubclassSummaryObj.proposed?.proposedInvestableAmount!/totalProposedAllocationAmount) * 100
                    }
                    assetSubclasses.push(assetSubclassSummaryObj)
                }

            })

            let mostRecentAssetSubclass = assetSubclasses[assetSubclasses.length - 1]
            if ((currentAssetTotalInvestableAmount !== 0 || proposedAssetTotalInvestableAmount !== 0 )) {
                assetSubclassSummary.push({
                    subclass: assetClassName,
                    current: {
                        ...mostRecentAssetSubclass?.current!,
                        assetClassName: "",
                        subclassName: assetClassName,
                        currentInvestableAmount: currentAssetTotalInvestableAmount,
                        currentInvestablePercent: currentAssetTotalInvestablePercent,

                    },
                    proposed: {
                        ...mostRecentAssetSubclass?.proposed!,
                        assetClassName: "",
                        subclassName: assetClassName,
                        proposedInvestableAmount: proposedAssetTotalInvestableAmount,
                        proposedInvestablePercent: proposedAssetTotalInvestablePercent,
                    }
                })
            }
            assetSubclassSummary.push.apply(assetSubclassSummary, assetSubclasses)
        })
    }

    const totalCurrentRiskAssetsPercentOfTotalPortfolio = (currentAllocation.totalCurrentRiskAssetsAmount / (currentAllocation.totalCurrentRiskAssetsAmount + currentAllocation.totalCurrentRiskControlAssetsAmount)) * 100
    const totalCurrentRiskControlAssetsPercentOfTotalPortfolio = (currentAllocation.totalCurrentRiskControlAssetsAmount / (currentAllocation.totalCurrentRiskAssetsAmount + currentAllocation.totalCurrentRiskControlAssetsAmount)) * 100


    return (
        <>{currentVsProposedPages.map((currentPage: CurrentVsProposedReportPageData, index) => {
            const isLastPage = (index === totalNoOfPages - 1)
            const isFirstPage = index === 0;
            const nonZeroRiskAssets = !!currentPage.riskAssets.length;
            const nonZeroRiskControl = !!currentPage.riskControl.length;
            const riskAssetContinueLabel = currentPage.continueLabel === "RA" ? "Risk Assets (continued)" : "Risk Assets";
            const riskControlContinueLabel = currentPage.continueLabel === "RCA" ? "Risk Control Assets (continued)" : "Risk Control Assets";

            return (
                <PrintViewWrapper displayName={displayName} key={index}>
                    <div data-testid="current-proposed-page" className="current-proposed-page">
                        <PresentationPaneHeader
                            displayName=""
                            title={index > 0 ? "Current vs. Proposed Asset Allocation (continued)" : "Current vs. Proposed Asset Allocation"}
                        />
                        <Container
                            ref={containerRef}
                            fluid className="current-proposed-container">
                            {hasCurrentAllocation(currentAllocation) ? <>
                                    <Row className={"row"}>
                                        {InvestableAssets}
                                        <>
                                            <Col width={COLUMN_WIDTH}
                                                 className={"allocation-donut"}
                                                 data-testid={'current-allocation-donut'}>
                                                <span className="title">Current Allocation</span>
                                                <CurrentVsProposedLegend
                                                    totalRiskAssetsPercent={totalCurrentRiskAssetsPercentOfTotalPortfolio}
                                                    totalRiskControlAssetsPercent={totalCurrentRiskControlAssetsPercentOfTotalPortfolio}
                                                />
                                                <AssetAllocationRiskDonut data={currentAllocation}
                                                                          allocation={'current'}
                                                           donutSize={'md'}/>
                                            </Col>
                                        </>
                                        <Col width={COLUMN_WIDTH}
                                             className={"allocation-donut"}
                                             data-testid={'proposed-allocation-donut'}>
                                            <span className="title">Proposed Allocation</span>
                                            <CurrentVsProposedLegend
                                                totalRiskAssetsPercent={proposedAllocation.totalProposedRiskAssetsPercentOfTotalPortfolio}
                                                totalRiskControlAssetsPercent={proposedAllocation.totalProposedRiskControlAssetsPercentOfTotalPortfolio}
                                            />
                                            <AssetAllocationRiskDonut data={currentAllocation}
                                                                      allocation={'proposed'}
                                                                      donutSize={'md'}/>
                                        </Col>
                                        <Col width={COLUMN_WIDTH}
                                             style={{minWidth: COLUMN_WIDTH}}
                                             className={"investable-assets"}>
                                        </Col>
                                    </Row>
                                    {(nonZeroRiskAssets || (nonZeroRiskAssets === false && isFirstPage)) && <AssetHoldingsReport
                                        id={getAssetAccordionId()}
                                        assetClassName={"Risk Assets"}
                                        total={currentAllocation.totalCurrentRiskAssetsAmount}
                                        totalProposed={totalRiskAssetsAmount}
                                        totalPercentage={totalCurrentRiskAssetsPercentOfTotalPortfolio}
                                        totalProposedPercentage={proposedAllocation.totalProposedRiskAssetsPercentOfTotalPortfolio}
                                        accentColor={COLOR_ASSETS_ACCOUNTS}
                                        assetSubclassSummaries={currentPage.riskAssets}
                                        continueLabel={riskAssetContinueLabel}
                                        classifications={classifications}
                                        isDetailedReport={isDetailedReport}
                                        totalProposedInvestableAmount={totalProposedAllocationAmount}
                                        totalCurrentInvestableAmount={totalCurrentAllocationAmount}
                                    />
                                    }
                                    {(nonZeroRiskControl || (nonZeroRiskControl === false && isLastPage))&& <AssetHoldingsReport
                                        id={getRCAssetAccordionId()}
                                        assetClassName={"Risk Control Assets"}
                                        total={currentAllocation.totalCurrentRiskControlAssetsAmount}
                                        totalProposed={totalRiskControlAssetsAmount}
                                        totalPercentage={totalCurrentRiskControlAssetsPercentOfTotalPortfolio}
                                        totalProposedPercentage={proposedAllocation.totalProposedRiskControlAssetsPercentOfTotalPortfolio}
                                        accentColor={COLOR_RISK_CONTROL_ASSETS}
                                        assetSubclassSummaries={currentPage.riskControl}
                                        continueLabel={riskControlContinueLabel}
                                        classifications={classifications}
                                        isDetailedReport={isDetailedReport}
                                        totalProposedInvestableAmount={totalProposedAllocationAmount}
                                        totalCurrentInvestableAmount={totalCurrentAllocationAmount}/>
                                    }
                                    {isLastPage && <CurrentVsProposedTableFooter
                                        currentAllocationAmount={totalCurrentAllocationAmount}
                                        proposedAllocationAmount={totalProposedAllocationAmount}
                                        currentAllocationPercentage={totalCurrentAllocationPercentage}
                                        proposedAllocationPercentage={totalProposedAllocationPercentage}/>}
                                </>
                                : <EmptyAssetAllocation/>
                            }
                        </Container>
                    </div>
                </PrintViewWrapper>
            )
        })
        }</>
    )
}

export default CurrentVsProposedReportView;