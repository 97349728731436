import {GeneralInflow, TrustInflowType} from "../models/GeneralInflow";
import {GeneralInflows} from "../models/Assets";

export const isTrustInflowTypeWithDisclosures = (trustInflowType? : TrustInflowType | null) => {
    return trustInflowType === "NT - Base Budget" || trustInflowType === "NT - Unitrust";
}

export const isGeneralInflowPendingDisclosureReview = (generalInflow: GeneralInflow) => {
    return isTrustInflowTypeWithDisclosures(generalInflow.trustInflowType) && !generalInflow.isDisclosureReviewed
}

export const isAnyGeneralInflowPendingDisclosureReview = (generalInflows: GeneralInflows) => {
    return generalInflows.data.some(generalInflow => isGeneralInflowPendingDisclosureReview(generalInflow));
}