import React, {useContext} from 'react';
import classNames from 'classnames';
import {Icon} from '../Icon';
import {ReactComponentLike} from 'prop-types';
import {LinkProps, LocationProps} from '../../models/routeData/RouteParamTypes';
import NavContext from './NavContext';

type LinkComponentProps = {
    children?: React.ReactNode,
    className?: string,
    link: LinkProps,
    location: LocationProps | undefined,
    Renderer?: ReactComponentLike | string,
    DisabledRenderer?: ReactComponentLike | string
}

export const Link = ({
                         children,
                         className,
                         link,
                         location = {pathname: '', hash: '', search: '', state: ''},
                         Renderer: EnabledRenderer = 'a',
                         DisabledRenderer = 'span'
                     }: LinkComponentProps) => {
    const {toggleMenu} = useContext(NavContext);
    const enabled = link['enabled'];
    let Renderer;
    const path: string | LocationProps = (link.pathFunction && link.pathFunction(location)) || link.path || '/';
    let tabIndex: number = -1;
    let exactLink: boolean | undefined = undefined;
    let rendererPath: string | LocationProps | undefined = undefined;

    if (enabled) {
        tabIndex = 0;
        exactLink = link.exact;
        rendererPath = path;
        Renderer = (link.subMenuType === 'right' ? 'span' : EnabledRenderer);
    } else {
        Renderer = DisabledRenderer;
    }

    const toggleMenuItem = () => {
        const subMenuPath: string = typeof link.path === 'string' ? link.path : '';
        if (enabled && subMenuPath) {
            toggleMenu(link, subMenuPath);
        }
    }

    return (
        <>
            <Renderer
                className={classNames(className, {disabled: !enabled})}
                exact={exactLink}
                key={link.id || path}
                role="menuitem"
                tabIndex={tabIndex}
                to={rendererPath}
                onKeyDown={(e: KeyboardEvent) => {
                    if (e.key === 'Enter' && link.subMenuType === 'right') {
                        toggleMenuItem();
                    }
                }}
                onClick={toggleMenuItem}
            >
                {link.name}
                {link.subMenuType && link.subMenuType === 'right' && <Icon name={'chevron_right'}/>}
                {children}
            </Renderer>
        </>
    )
}
