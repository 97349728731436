import {get, post, remove} from "../core/api";
import {
    ClientReportProgress,
    DisclaimerTableData,
    GenerateReportRequest,
    ReportConfig,
    SendReportRequest,
    PersonaIdListResponse, DchResponse
} from "./models/Reporting";
import {App_Conf} from "../core/app_conf";


const generateReport = async (reqBody: GenerateReportRequest, profileId: string): Promise<ClientReportProgress> => {
    const response = await post(`/advisory/reporting/generateReport/${profileId}`, reqBody);
    return response.json();
}

const getClientPersonaIds = async (profileId: string, resourceCode: string): Promise<PersonaIdListResponse> => {
    const response = await get(`/advisory/reporting/getClientPersonaList/${profileId}?resourceCode=${resourceCode}`);
    return response.json();
}

const getReportConfig = async (): Promise<ReportConfig> => {
    const response = await fetch(`${App_Conf.APP_URL}/advisory/reportConfig`);
    return response.json();
}

const getBlobFromReportObjectUrl = async (generatedReportUrl: string): Promise<Blob> => {
    const response = await fetch(generatedReportUrl);
    return response.blob();
}

export const getDisclaimerData = async (): Promise<DisclaimerTableData> => {
    const response = await get(`/advisory/reporting/getDisclaimerData`);
    return response.json();
}

export const getGeneratedReport = async (profileId: string): Promise<Blob> => {
    const response = await get(`/advisory/reporting/getClientReport/${profileId}`);
    return response.blob();
}

export const getReportingStatus = async (profileId: string): Promise<ClientReportProgress> => {
    const response = await get(`/advisory/reporting/getReportStatus/${profileId}`);
    return response.json();
}

const sendReportToDCH = async (profileId: string, reqBody: SendReportRequest): Promise<DchResponse> => {
    const response = await post(`/advisory/reporting/sendClientReport/${profileId}`, reqBody);
    return response.json();
}

const deleteClientReport = async (profileId: string): Promise<String> => {
    const response = await remove(`/advisory/reporting/deleteClientReport/${profileId}`);
    return response.json();
}

export const reportingApiClient = {
    generateReport,
    getReportConfig,
    getClientPersonaIds,
    getBlobFromReportObjectUrl,
    getDisclaimerData,
    getGeneratedReport,
    getReportingStatus,
    sendReportToDCH,
    deleteClientReport
};
