import {Input, UnderlinedHeader} from "../../components";
import React, {ChangeEvent, useState} from "react";
import TaxDetails from "../TaxDetails/TaxDetails";
import {LegalAgreement} from "../models/InvestmentProgram";
import {TaxDetailsType} from "../models/TaxDetails";
import Ownership from "../Ownership";
import {LegalEntityFormData, OwnershipDetailsFormData} from "../models/Ownership";
import {MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import useProfileEditableState from "../../hooks/useProfileEditableState";

type LegalAgreementFormProps = {
    legalAgreement: LegalAgreement,
    ownershipDetailsFormData: OwnershipDetailsFormData,
    isRequiredFieldsBannerShown: boolean,
    isOwnershipPercentageErrorBannerShown: boolean,
    handleLegalAgreementChange: Function,
    investmentProgramName: string | null,
    taxDetails: TaxDetailsType,
    handleTaxDetailsChange: (taxDetails: TaxDetailsType) => void,
    unrealizedCapitalGainsTax: number | null,
    deferredTaxLiability: number | null,
    totalInvestablePresentValue?: number | null,
    getInvestablePresentValue: (liabilityPaidByPortfolio: boolean) => void
    handleUnrealizedCapitalGainsTaxChange: (isEntityCapitalGains: boolean) => void,
    memberGroup: MemberGroup,
    legalEntities: LegalEntityFormData[],
    handleLegalEntitiesChange: (legalEntities: LegalEntityFormData[]) => void,
    onOwnershipFormChange: (ownershipFormData: OwnershipDetailsFormData) => void
}

export const LegalAgreementForm = ({
                                       legalAgreement,
                                       ownershipDetailsFormData,
                                       isRequiredFieldsBannerShown,
                                       isOwnershipPercentageErrorBannerShown,
                                       handleLegalAgreementChange,
                                       investmentProgramName,
                                       taxDetails,
                                       handleTaxDetailsChange,
                                       unrealizedCapitalGainsTax,
                                       deferredTaxLiability,
                                       totalInvestablePresentValue,
                                       getInvestablePresentValue,
                                       handleUnrealizedCapitalGainsTaxChange,
                                       memberGroup,
                                       legalEntities,
                                       handleLegalEntitiesChange,
                                       onOwnershipFormChange
                                   }: LegalAgreementFormProps) => {

    const [isNameEmptyOnBlur, setIsNameEmptyOnBlur] = useState(false);
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    const totalAssetValue = legalAgreement.marketValue ?? null;

    const isNameInlineErrorShown = () => {
        const isNameEmptyOnSave = isRequiredFieldsBannerShown && !legalAgreement?.name.trim();
        return isNameEmptyOnBlur || isNameEmptyOnSave;
    };

    const handleOwnershipFormChange = (ownershipFormData: OwnershipDetailsFormData) => {
        onOwnershipFormChange(ownershipFormData);
    }

    return (
        <article>
            <section className="account-details">
                <UnderlinedHeader primaryText="Account Details"/>
                <div className="layout-data-entry-form__field">
                    <label id="nameInput-label"><b>Name</b></label>
                    <Input
                        aria-labelledby="nameInput-label"
                        value={legalAgreement.name}
                        error={isNameInlineErrorShown() ? "Name is required." : undefined}
                        onBlur={() => setIsNameEmptyOnBlur(!legalAgreement.name.trim())}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            handleLegalAgreementChange({
                                ...legalAgreement,
                                name: e.target.value
                            })
                        }}
                        readOnly={isProfileWithProposalsOrArchived}
                    />
                </div>
                <div className="layout-data-entry-form__field">
                    <label id="investmentProgramName-label">
                        <b>Investment Program</b>
                    </label>
                    <span role="note" aria-labelledby="investmentProgramName-label"
                          className="paddingleft-md">
                                        {investmentProgramName}
                                    </span>
                </div>

                <div className="layout-data-entry-form__field">
                    <label id="legalAgreementNumber-label">
                        <b>Legal Agreement Number</b>
                    </label>
                    <span role="note" aria-labelledby="legalAgreementNumber-label"
                          className="paddingleft-md">
                                        {legalAgreement.legalAgreementNumber}
                                    </span>
                </div>

                <div className="layout-data-entry-form__field">
                    <label className="align-self-start" id="accountNumber-label">
                        <b>Account Number</b>
                    </label>
                    <ul role="note" aria-labelledby="accountNumber-label" className="paddingleft-md">{
                        legalAgreement.accountNumbers.map(accountNumber => (
                            <li key={accountNumber}>{accountNumber}</li>
                        ))
                    }
                    </ul>
                </div>

                <div className="layout-data-entry-form__field">
                    <label id="entityType-label">
                        <b>Entity Type</b>
                    </label>
                    <span role="note" aria-labelledby="entityType-label" className="paddingleft-md">
                                        {legalAgreement.entityType ? legalAgreement.entityType : '––'}
                                    </span>
                </div>

                <div className="layout-data-entry-form__field">
                    <label id="institution-label">
                        <b>Institution</b>
                    </label>
                    <span role="note" aria-labelledby="institution-label" className="paddingleft-md">
                                        {legalAgreement.institution}
                                    </span>
                </div>

                <div className="layout-data-entry-form__field">
                    <label id="taxStatus-label">
                        <b>Tax Status</b>
                    </label>
                    <span role="note" aria-labelledby="taxStatus-label" className="paddingleft-md">
                                        {legalAgreement.taxStatus}
                                    </span>
                </div>

                <div className="layout-data-entry-form__field">
                    <label id="permitBeneficiary-label">
                        <b>Does this account permit a beneficiary?</b>
                    </label>
                    <span role="note" aria-labelledby="permitBeneficiary-label"
                          className="paddingleft-md">
                                        {legalAgreement.permitBeneficiary ? 'Yes' : 'No'}
                                    </span>
                </div>
            </section>
            {(legalAgreement.taxStatus === "Taxable" || legalAgreement.taxStatus === "Deferred") &&
                <TaxDetails taxDetails={taxDetails}
                            updateTaxDetails={handleTaxDetailsChange}
                            taxStatus={legalAgreement.taxStatus}
                            isRequiredFieldsBannerShown={isRequiredFieldsBannerShown}
                            unrealizedCapitalGainsTax={unrealizedCapitalGainsTax}
                            deferredTaxLiability={deferredTaxLiability}
                            totalInvestablePresentValue={totalInvestablePresentValue}
                            handleUnrealizedCapitalGainsTaxChange={handleUnrealizedCapitalGainsTaxChange}
                            handleLiabilityPaidByPortfolioChange={getInvestablePresentValue}
                            isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                />
            }
            <Ownership
                onFormDataChange={handleOwnershipFormChange}
                formData={ownershipDetailsFormData}
                totalAssetValue={totalAssetValue}
                isOwnershipPercentageErrorBannerShown={isOwnershipPercentageErrorBannerShown}
                memberGroup={memberGroup}
                legalEntities={legalEntities}
                updateLegalEntities={handleLegalEntitiesChange}
                disableOutOfEstate={true}
                isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
            />
        </article>
    )
}