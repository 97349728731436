import {useAppDispatch} from "../../../store/hooks";
import React, {useState} from "react";
import {FamilyGoalType} from "../../models/FamilyGoalType";
import {goalsApiClient} from "../../GoalsApiClient";
import {deleteFamilyGoal} from "../../controller/GoalsModelSlice";
import BeneficiaryGoalActionMenu from "./BeneficiaryGoalActionMenu";
import DeleteGoalModal from "../DeleteGoalModal";
import {GoalSubType, GoalType} from "../../models/GoalType";
import ApplyFamilyAssetsModal from "../../Family/ApplyFamilyAssetsModal";
import {FamilyAssets} from "../../../Assets/models/FamilyAssets";
import {BeneficiaryTableRow} from "../types";

type BeneficiaryGoalActionMenuContainerProps = {
    beneficiaryGoalRow: BeneficiaryTableRow,
    onEditGoal: (selectedGoalType: string, savedGoal: FamilyGoalType) => void,
    showApplyFamilyAssets?: boolean,
    availableAssets?: number,
    onApplyFamilyAssets?: (goal: FamilyGoalType, assetsAmountToApply: number, selectedGoalType?: GoalSubType) => void,
    familyAssets?: FamilyAssets
}

const BeneficiaryGoalActionMenuContainer = ({
                                                beneficiaryGoalRow,
                                                onEditGoal,
                                                showApplyFamilyAssets,
                                                availableAssets,
                                                onApplyFamilyAssets,
                                                familyAssets
                                            }: BeneficiaryGoalActionMenuContainerProps) => {
    const dispatch = useAppDispatch();
    const [showDeleteGoalModal, setShowDeleteGoalModal] = useState(false);
    const [showApplyFamilyAssetsModal, setShowApplyFamilyAssetsModal] = useState(false);

    const handleDeleteGoal = (familyGoal: FamilyGoalType) => {
        goalsApiClient.deleteFamilyGoal(familyGoal.id!)
            .then(response => {
                if (response.status === 204) {
                    dispatch(deleteFamilyGoal({goal: familyGoal}))
                }
            })
    }

    return (
        <span className="table-action-menu">
            <BeneficiaryGoalActionMenu
                onEditGoal={() => onEditGoal(beneficiaryGoalRow.selectedGoalSubType, beneficiaryGoalRow.savedGoal)}
                onDeleteGoal={() => setShowDeleteGoalModal(true)}
                onApplyFamilyAsset={() => setShowApplyFamilyAssetsModal(true)}
                showApplyFamilyAssets={showApplyFamilyAssets}
                enableApplyFamilyAssets={(availableAssets || 0) > 0 || (beneficiaryGoalRow.savedGoal.familyAssetsAmount || 0) > 0}
            />
            <DeleteGoalModal
                showDeleteGoalModal={showDeleteGoalModal}
                onCancel={() => setShowDeleteGoalModal(false)}
                onDelete={() => {
                    handleDeleteGoal(beneficiaryGoalRow.savedGoal);
                    setShowDeleteGoalModal(false);
                }}
                goalType={GoalType.FAMILY}
                description={beneficiaryGoalRow.savedGoal.userInputs.description}
                cancelButtonText={'CANCEL'}/>
            {familyAssets &&
                <ApplyFamilyAssetsModal
                    isOpen={showApplyFamilyAssetsModal}
                    onCancel={() => setShowApplyFamilyAssetsModal(false)}
                    onApply={(familyGoal: FamilyGoalType, assetAmountToApply: number, selectedGoalType?: GoalSubType) => {
                        if (onApplyFamilyAssets) {
                            onApplyFamilyAssets(familyGoal, assetAmountToApply, selectedGoalType);
                            setShowApplyFamilyAssetsModal(false);
                        }
                    }}
                    familyGoal={beneficiaryGoalRow.savedGoal}
                    familyAssets={familyAssets}
                    availableAssets={availableAssets || 0}
                />}
        </span>
    )
}

export default BeneficiaryGoalActionMenuContainer;