import {ChangeEventHandler, ReactNode} from "react";
import classNames from 'classnames';
import {RadioGroup as XpsRadioGroup} from 'xps-react';

type RadioGroupProps = {
    children?: ReactNode;
    className?: string;
    disabled?: boolean;
    required?: boolean;
    error?: boolean;
    errorMessage?: string;
    id?: string;
    label?: string;
    layout?: 'vertical' | 'horizontal';
    name: string;
    selected?: string | number | null;
    values?: (string | number)[];
    onChange?: ChangeEventHandler;
}

const RadioGroup = ({className, layout = 'vertical', required, errorMessage, ...rest}: RadioGroupProps) => (
    <>
        <XpsRadioGroup
            className={classNames({
                [`radiogroup--${layout}`]: layout,
                [`${className}`]: className,
                "show-red-asterisk": required
            })}
            {...rest}
        />
        {rest.error && errorMessage &&
            <p className="font-default color-text--error font-weight-400 inlineInputError margintop-sm">
                <span>{errorMessage}</span>
            </p>}
    </>
);

export default RadioGroup;
