import FamilyGoalUtils from "./FamilyGoalUtils";
import {formatCurrency} from "../../utils/format";
import React from "react";
import {useAppSelector} from "../../store/hooks";
import {selectGoalModel} from "../controller/GoalsModelSlice";
import GoalUtils from "./GoalUtils";

export const NonLifestyleCumulativePresentValue = () => {
    const {familyGoals, discretionaryGoals, philanthropicGoals} = useAppSelector(selectGoalModel)

    const hasFamilyGoals = Object.keys(familyGoals).length > 0
    const hasDiscretionaryGoals = discretionaryGoals.length > 0
    const hasPhilanthropicGoals = philanthropicGoals.length > 0
    if (!hasFamilyGoals && !hasDiscretionaryGoals && !hasPhilanthropicGoals) {
        return null
    }

    const familyGoalTotalPresentValue = FamilyGoalUtils.getCumulativeTotalMyResponsibility(familyGoals)
    const discretionaryGoalTotalPresentValue = GoalUtils.getNonLifestyleGoalCumulativeTotalPresentValue(discretionaryGoals)
    const philanthropicGoalTotalPresentValue = GoalUtils.getNonLifestyleGoalCumulativeTotalPresentValue(philanthropicGoals)

    const cumulativeTotalPresentValue = Math.round(familyGoalTotalPresentValue) +
        Math.round(discretionaryGoalTotalPresentValue) +
        Math.round(philanthropicGoalTotalPresentValue)

    return (
        <div className="section-header" data-testid="family-goal-list-header-text">
            <h3>
                You plan to fund <b>{formatCurrency(cumulativeTotalPresentValue)}</b> in other goals:
            </h3>
        </div>);
}