import React from "react";
import {COLOR_NT_GREY} from "../../constants/colors";
import {Col, Row} from "react-grid-system";
import EditAssetRelianceButton from "./EditAssetRelianceButton";

interface AssetRelianceTitleGridProps {
    additionalStacksTitles: string[];
    stackContainerWidth: number;
    titleCellWidth: number;
    id: string;
    isEditScreen?: boolean,
}

export const AssetRelianceLeftBorder = <div
    style={{
        height: '56px',
        borderLeft: `1px solid ${COLOR_NT_GREY}`
    }}
/>;

export const AssetRelianceRightBorder = <div
    style={{
        height: '56px',
        borderRight: `1px solid ${COLOR_NT_GREY}`,
    }}
/>;

const AssetRelianceTitleGrid: React.FC<AssetRelianceTitleGridProps> = ({
                                                                           additionalStacksTitles,
                                                                           stackContainerWidth,
                                                                           titleCellWidth,
                                                                           id,
                                                                           isEditScreen =false
                                                                       }) => {
    return (
        <Row role="row" style={{flexFlow: "nowrap", paddingTop: "24px", paddingBottom: "18px"}}>
            <Col width={titleCellWidth} style={{minWidth: titleCellWidth}}></Col>
            <Col md={stackContainerWidth} className="asset-reliance-title-grid-container">
                <div className="title">
                    {AssetRelianceLeftBorder}
                    <span data-testid="column-header">All Assets</span>
                    {AssetRelianceRightBorder}
                </div>
            </Col>
            {
                additionalStacksTitles.length > 0 ? additionalStacksTitles.map((title) => (
                        <Col md={stackContainerWidth} key={title} className="asset-reliance-title-grid-container">
                            <div className="title">
                                {AssetRelianceLeftBorder}
                                <span className="padding-lg" data-testid="column-header">{title}</span>
                                {AssetRelianceRightBorder}
                            </div>
                        </Col>
                    )) :
                    <Col md={stackContainerWidth}>
                        {isEditScreen === false && <EditAssetRelianceButton id={id}/>}
                    </Col>
            }
        </Row>
    )
}

export default AssetRelianceTitleGrid;

