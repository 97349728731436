import {MemberOwnership, MemberOwnershipWriteModel} from "../models/Ownership";
import {formatCurrency} from "../../utils/format";

export function hasSomeInEstateOwnershipInForm(memberOwnerships: MemberOwnershipWriteModel[]) {
    const totalInEstatePercentage = memberOwnerships.reduce((total, ownership) => total + ownership.percentage, 0)
    return totalInEstatePercentage > 0;
}

export function hasSomeInEstateOwnership(memberOwnerships: MemberOwnership[]) {
    return totalInEstateOwnershipPercentage(memberOwnerships) > 0;
}

export function hasSomeOutOfEstateOwnership(memberOwnerships: MemberOwnership[]) {
    return totalInEstateOwnershipPercentage(memberOwnerships) < 100;
}

export function calculateTotalOwnedValue(percentage: string, totalAssetValue?: number | null): string {
    if (totalAssetValue && totalAssetValue > 0 && percentage.trim()) {
        return formatCurrency((totalAssetValue * parseFloat(percentage)) / 100);
    }
    return "--";
}

function totalInEstateOwnershipPercentage(memberOwnerships: MemberOwnership[]) {
    return memberOwnerships
        //.filter(ownership => ownership.isInEstateMember)
        //.reduce((total, ownership) => total + ownership.percentage, 0);
        ?.filter(ownership => ownership.isInEstateMember)
        ?.reduce((total, ownership) => total + ownership.percentage, 0);
}

export function showOwnershipType(category: string) {
    if (category === "TIC (Tenants in Common)") {
        return "Tenants in Common";
    } else if (category === "Community Property") {
        return category;
    }
    return ""
}