import {
    CameraButton,
    ControlBar,
    ControlBarButton,
    MicrophoneButton,
    usePropsFor,
    VideoGallery,
    VideoGalleryProps,
    VideoGalleryRemoteParticipant
} from "@azure/communication-react";
import {Stack} from "@fluentui/react";
import React, {useCallback} from "react";
import {msalUtils} from "../../MsalUtils";
import {useMsal} from "@azure/msal-react";
import {useAppDispatch} from "../../store/hooks";
import {setShowExpandedVideoGallery} from "./meetingSlice";
import {
    controlBarStyles,
    customCameraOffIcon,
    customCameraOnIcon,
    customExpandIcon,
    customMicOffIcon,
    customMicOnIcon
} from "./AudioVisual/CustomIcons";

const ParticipantAudioVideo: React.FC = () => {
    const videoGalleryProps = usePropsFor(VideoGallery);
    const microphoneProps = usePropsFor(MicrophoneButton);
    const cameraProps = usePropsFor(CameraButton);
    const msal = useMsal();
    const dispatch = useAppDispatch();


    const findRemoteParticipantsToDisplay = useCallback((): VideoGalleryRemoteParticipant[] => {
        const dominantSpeaker = videoGalleryProps.remoteParticipants.find((participant) => {
            return participant.userId === videoGalleryProps.dominantSpeakers?.[0];
        }) ?? videoGalleryProps.remoteParticipants[0];
        return dominantSpeaker ? [dominantSpeaker] : [];
    }, [videoGalleryProps.remoteParticipants, videoGalleryProps.dominantSpeakers]);

    const videoGalleryPropOverrides: VideoGalleryProps = {
        ...videoGalleryProps,
        showMuteIndicator: true,
        remoteParticipants: findRemoteParticipantsToDisplay(),
        localParticipant: {
            ...videoGalleryProps.localParticipant,
            displayName: msalUtils.getAccountName(msal),
        },
        remoteVideoViewOptions: {
            scalingMode: 'Fit',
        },
        localVideoViewOptions: {
            scalingMode: 'Fit',
        },
        styles: {
            root: {
                '.ms-Modal': {
                    display: 'none',
                },
            },
        },
        maxRemoteVideoStreams: 1,
        layout: 'floatingLocalVideo',
    };

    return (
        <div className='meeting-participants'>
            <Stack className='participant-audio-video-container'>
                <div className='participant-control-bar'>
                    <ControlBar styles={controlBarStyles} layout='horizontal'>
                        {cameraProps && <CameraButton  {...{
                            ...cameraProps,
                            showLabel: true,
                            strings: {
                                onLabel: 'Camera',
                                offLabel: 'Camera'
                            },
                            onRenderOnIcon: () => {
                                return customCameraOnIcon()
                            },
                            onRenderOffIcon: () => {
                                return customCameraOffIcon()
                            }
                        }} />}
                        {microphoneProps && <MicrophoneButton {...
                            {
                                ...microphoneProps,
                                showLabel: true,
                                strings: {
                                    onLabel: 'Mic',
                                    offLabel: 'Mic'
                                },
                                onRenderOnIcon: () => {
                                    return customMicOnIcon()
                                },
                                onRenderOffIcon: () => {
                                    return customMicOffIcon()
                                },
                            }}
                        />}
                        <ControlBarButton showLabel={true}
                                          strings={{label: 'Expand'}}
                                          onRenderIcon={() => {
                                              return customExpandIcon()
                                          }}
                                          onClick={() => {dispatch(setShowExpandedVideoGallery(true))}}
                        />
                    </ControlBar>
                </div>
                <div className='participant-video-gallery'>
                    {videoGalleryProps &&
                        <VideoGallery {...videoGalleryPropOverrides}/>}
                </div>
            </Stack>
        </div>
    )
};

export default ParticipantAudioVideo;