import {ReportConfig} from "../../models/Reporting";
import {ProfileResponse} from "../../../ClientManagement/models/ProfileResponse";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import React, {useEffect, useState} from "react";
import {clientManagementApiClient} from "../../../ClientManagement/ClientManagementApiClient";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {wealthManagementApiClient} from "../../../ClientManagement/WealthManagementApiClient";
import {selectAssetRelianceReportResponse, setAssetRelianceReportResponse} from "./AssetRelianceReportSlice";
import AssetRelianceSummaryReport from "./AssetRelianceSummaryReport";
import AssetRelianceContainer from "./AssetRelianceContainer";
import {assetsApiClient} from "../../../Assets/AssetsApiClient";
import {setClientAssets} from "../../../Assets/clientAssetsSlice";
import {FveDiscountRateType} from "../../../ClientManagement/AssetReliance/AssetRelianceButtonState";

type AssetRelianceReportsProps = {
    reportConfig: ReportConfig,
    profile: ProfileResponse,
    approvedProfile: ProfileResponse
}
const AssetRelianceReports = ({reportConfig, profile, approvedProfile}: AssetRelianceReportsProps) => {

    const dispatch = useAppDispatch();

    const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);

    const assetRelianceData = useAppSelector(selectAssetRelianceReportResponse);

    const activeProfileId = profile?.id;

    const displayName = approvedProfile.displayName;

    useEffect(() => {
        if (!isDataLoaded && activeProfileId) {
            Promise.all([
                wealthManagementApiClient.getAssetReliance(activeProfileId),
                clientManagementApiClient.getInvestorGroup(activeProfileId),
                assetsApiClient.getAssetsSummary(activeProfileId),
            ])
                .then(([assetRelianceResponse,
                           investorGroupResponse,
                           assetsSummaryResponse]) => {
                    dispatch(setAssetRelianceReportResponse({
                        ...assetRelianceResponse,
                        investorGroupResponse: investorGroupResponse
                    }));
                    dispatch(setClientAssets(assetsSummaryResponse));
                    setIsDataLoaded(true);
                })
                .catch(error => {
                    console.error('Could not fetch asset reliance data', error.message);
                    setIsDataLoaded(true);
                });
        }
    }, [activeProfileId]);

    if (!isDataLoaded || !assetRelianceData) {
        return <LoadingIndicator/>;
    }

    let selectedFveDiscountRateForSummaryReport = FveDiscountRateType.TRIPLE_NET;
    if (reportConfig.assetRelianceWithFutureValue.isEnabled && reportConfig.assetRelianceWithFutureValue.assetRelianceOptions!.selectedFveDiscountRate === FveDiscountRateType.NOMINAL_NET) {
        selectedFveDiscountRateForSummaryReport = FveDiscountRateType.NOMINAL_NET;
    }
    let selectedFveDiscountRateForDetailedReport = FveDiscountRateType.TRIPLE_NET;
    if (reportConfig.assetRelianceDetailedWithFutureValue.isEnabled && reportConfig.assetRelianceDetailedWithFutureValue.assetRelianceOptions!.selectedFveDiscountRate === FveDiscountRateType.NOMINAL_NET) {
        selectedFveDiscountRateForDetailedReport = FveDiscountRateType.NOMINAL_NET;
    }

    return <>
        {
            reportConfig.assetReliance.isEnabled &&
            <AssetRelianceSummaryReport displayName={displayName} profileId={activeProfileId}/>
        }
        {
            reportConfig.assetRelianceWithFutureValue.isEnabled &&
            <AssetRelianceSummaryReport
                showExpectedExcessAsset={reportConfig.assetRelianceWithFutureValue.assetRelianceOptions!.showExpectedExcessAsset}
                includeLifeInsuranceAtDeath={reportConfig.assetRelianceWithFutureValue.assetRelianceOptions!.includeLifeInsuranceAtDeath}
                selectedFveDiscountRate={selectedFveDiscountRateForSummaryReport} displayName={displayName}
                profileId={activeProfileId}/>
        }
        {
            reportConfig.assetRelianceDetailed.isEnabled &&
            <AssetRelianceContainer
                displayName={displayName} profileId={activeProfileId}/>
        }
        {
            reportConfig.assetRelianceDetailedWithFutureValue.isEnabled &&
            <AssetRelianceContainer
                displayName={displayName}  showExpectedExcessAsset={reportConfig.assetRelianceDetailedWithFutureValue.assetRelianceOptions!.showExpectedExcessAsset}
                includeLifeInsuranceAtDeath={reportConfig.assetRelianceDetailedWithFutureValue.assetRelianceOptions!.includeLifeInsuranceAtDeath}
                selectedFveDiscountRate={selectedFveDiscountRateForDetailedReport} profileId={activeProfileId}/>
        }
    </>

}
export default AssetRelianceReports;