import React from "react";
import {formatCurrency} from "../../../utils/format";
import {getRiskAndRiskControlWithPercent, groupHoldingsByLiquidityCategory, sumByField} from "./holdingUtils";
import {Donut} from "xps-react";
import RiskLegend, {riskLegendColor} from "../../../components/Legend/RiskLegend";
import {HoldingAssetSubclassDetails} from "../../models/Holding";
import {presentAccountSubtitle, presentOwnershipSummary} from "./presenter";
import {InvestorGroupMember} from "../../../ClientManagement/models/InvestorGroupType";
import {TaxStatusOptions} from "../../models/TaxDetails";
import {LegalEntityOwnership, MemberOwnership, OwnershipCategory} from "../../models/Ownership";
import {AssetClassifications} from "../../models/AssetClassifications";

export type Account = {
    institution?: string,
    accountNumber?: string,
    taxStatus: TaxStatusOptions,
    memberOwnerships: MemberOwnership[],
    legalEntityOwnerships: LegalEntityOwnership[],
    ownershipCategory: OwnershipCategory
}

type HoldingInfoProps = {
    account: Account
    primaryMember?: InvestorGroupMember
    partnerMember?: InvestorGroupMember
    allHoldings: HoldingAssetSubclassDetails[]
    classifications: AssetClassifications
}

const aqua = '#00a0aa';
const darkBlue = '#104866';
const ntGrey = '#cecece';

const HoldingsInfo = ({
                          account,
                          partnerMember,
                          primaryMember,
                          allHoldings,
                          classifications
                      }: HoldingInfoProps) => {

    const {
        totalRiskAssets,
        totalRiskControl,
        totalRiskAssetsInPercent,
        totalRiskControlInPercent
    } = getRiskAndRiskControlWithPercent(allHoldings, classifications, "holdingsInfo");

    const isMarketValue = totalRiskAssets + totalRiskControl > 0;
    const donutProps = (isMarketValue)
        ? {data: [{value: totalRiskAssetsInPercent}, {value: totalRiskControlInPercent}], colors: [aqua, darkBlue]}
        : {data: [{value: 100}], colors: [ntGrey]};

    const liquidityTotals = groupHoldingsByLiquidityCategory(allHoldings, classifications);
    const accountSubtitle = presentAccountSubtitle(account);
    const accountSummaryWithOwnership = accountSubtitle + (primaryMember ? `  |  ${(presentOwnershipSummary(primaryMember, partnerMember, account))}` : '');
    return <div className="holdings-info-container">
        <div className="holdings-info-chart" data-testid="holdings-info-chart">
            <div className="grid-span-3 account-info">
                {accountSummaryWithOwnership}
            </div>
            <Donut
                role="figure"
                className="grid-row-span-2 display-flex align-items-center"
                data={donutProps.data}
                colors={donutProps.colors}
                outerRadius={48}
            />
            <div className="display-flex align-items-end">
                <RiskLegend legendColor={riskLegendColor.RISK_ASSETS} label={'Risk Assets'}/>
            </div>
            <span className="display-flex align-items-end justify-content-end" data-testid="risk-assets-test-id">
                {formatCurrencyWhenPresent(totalRiskAssets)}
            </span>
            <div className="display-flex align-items-start">
                <RiskLegend legendColor={riskLegendColor.RISK_CONTROL_ASSETS} label={'Risk Control'}/>
            </div>
            <span className="display-flex align-items-start justify-content-end" data-testid="risk-control-test-id">
                {formatCurrencyWhenPresent(totalRiskControl)}
            </span>
        </div>
        <div className="holdings-by-liquidity-category" data-testid="holdings-by-liquidity-category">
            <div role="table" className="holdings-by-liquidity-category__table">
                <div role="row"
                     className="holdings-by-liquidity-category__table__row holdings-by-liquidity-category__table__header">
                    <span role="columnheader">Asset Category</span>
                    <span role="columnheader">Market Value</span>
                    <span role="columnheader">Investable</span>
                </div>
                <div role="row">
                    <hr/>
                </div>
                {liquidityTotals
                    .map((holding, index) =>
                        (<div role="row" key={`holding-${index}`}
                              className="holdings-by-liquidity-category__table__row holdings-by-liquidity-category__table__data">
                            <span role="cell">{holding.liquidityCategory}</span>
                            <span role="cell">{formatCurrency(holding.marketValue)}</span>
                            <span role="cell">{formatCurrency(holding.investableValue)}</span>
                        </div>))}
                <div role="row">
                    <hr/>
                </div>
                <div role="row" className="holdings-by-liquidity-category__table__row fontweight-500">
                    <span role="cell">Total</span>
                    <span role="cell">{formatCurrency(sumByField(liquidityTotals, l => l.marketValue))}</span>
                    <span role="cell">{formatCurrency(sumByField(liquidityTotals, l => l.investableValue))}</span>
                </div>
            </div>
        </div>
    </div>
};

function formatCurrencyWhenPresent(totalAssets: number) {
    return totalAssets === 0 ? <>&ndash;&ndash;</> : formatCurrency(totalAssets);
}

export default HoldingsInfo;