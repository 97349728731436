export const getScrollWidth = (element: Element) => element.scrollWidth;
export const getScrollLeft = (element: Element) => element.scrollLeft;
export const getScrollHeight = (element: Element) => element.scrollHeight;
export const getScrollTop = (element: Element) => element.scrollTop;

export const setScrollLeft = (element: Element, scrollLeft: number) => element.scrollLeft = scrollLeft;
export const setScrollTop = (element: Element, scrollTop: number) => element.scrollTop = scrollTop;

export const getClosestScrollContainerFromPoint = (x: number, y: number) => {
    const ele = document.elementFromPoint(x, y);
    return ele ? ele.closest('[data-syncscrollposition]') : undefined;
}