import {FundingDisplayOptions, GoalFundingItem} from "./models/Funding";
import React from "react";
import {
    formatFundingCurrency,
    formatFundingPercentage,
    isShowFundingForInflows,
    isShowFundingForNonInvestable,
    isShowOnlyPortfolio
} from "./fundingUtils";
import GoalAchievedPopover from "./GoalAchievedPopover";
import {Icon} from "../../components";

export type GoalFundingTableProps = {
    goals: GoalFundingItem[];
    ageIndex: number;
    goalType: string;
    displayOptions: FundingDisplayOptions;
    dollarView: boolean;
    startAge: number;
    enableAchieved?: boolean;
};

const GoalFundingTable: React.FC<GoalFundingTableProps> = ({
                                                               goals,
                                                               ageIndex,
                                                               startAge,
                                                               goalType,
                                                               displayOptions,
                                                               dollarView,
                                                               enableAchieved = true
                                                           }) => {
    return (
        <div className="funding-table" role="table" aria-label={`${goalType} Table`}>
            {goals.map((goal) => {
                const isAchieved = enableAchieved && !goal.presentValueAtYear?.[ageIndex];
                return (
                    <div
                        role="row"
                        className="funding-grid funding-table-row"
                        key={goal.description}
                    >
                        <span role="cell" className="font-md paddingleft-xxxl" aria-label="Goal Description">
                            <span>{goal.description}</span>
                            {isAchieved && <span className="paddingleft-md">
                                <Icon name="status_validated" type="success" size="small" color="#37A085" />
                            </span>}
                        </span>
                        <span role="cell" className="font-md textalign-right" aria-label="Funded by Risk Assets">
                            {dollarView? formatFundingCurrency(goal.fundedByRiskAssetsAtYear?.[ageIndex]) : formatFundingPercentage(goal.fundedByRiskAssetsAtYearPercentage?.[ageIndex])}
                        </span>
                        <span role="cell" className="font-md textalign-right" aria-label="Funded by Risk Control">
                            {dollarView? formatFundingCurrency(goal.fundedByRiskControlAtYear?.[ageIndex]) : formatFundingPercentage(goal.fundedByRiskControlAtYearPercentage?.[ageIndex])}
                        </span>
                        {!isShowOnlyPortfolio(displayOptions) && <span role="cell" className="font-md textalign-right" aria-label="Funded by Portfolio">
                            {formatFundingCurrency(goal.fundedByPortfolioAtYear?.[ageIndex])}
                        </span>}
                        {isShowFundingForInflows(displayOptions) &&
                            <span role="cell" className="font-md textalign-right" aria-label="Funded by Inflows">
                                {formatFundingCurrency(goal.fundedByInflowsAtYear?.[ageIndex])}
                            </span>
                        }
                        {isShowFundingForNonInvestable(displayOptions) &&
                            <span role="cell" className="font-md textalign-right" aria-label="Funded by Non Investable">
                                {formatFundingCurrency(goal.fundedByNonInvestableAtYear?.[ageIndex])}
                            </span>
                        }
                        <span role="cell" className="font-md textalign-right" aria-label="Present Value">
                            {isAchieved ? <GoalAchievedPopover startAge={startAge} presentValueAtYear={goal.presentValueAtYear}/>
                                : formatFundingCurrency(goal.presentValueAtYear?.[ageIndex])}
                        </span>
                        <span />
                    </div>
                )}
            )}
        </div>
    );
};

export default GoalFundingTable;