import {PersonalAsset} from "../../../models/PersonalAsset";
import {AccordionItemWithActionMenu} from "../../../../components";
import {COLOR_PERSONAL_ASSETS} from "../../../../constants/colors";
import {AssetAccordionHeader} from "../../common/AssetAccordionHeader";
import {formatCurrency} from "../../../../utils/format";
import {calculatePersonalAssetInEstateTotalValue} from "../../common/AssetSummaryCalculator";
import React from "react";
import PersonalAssetsTable from "../../InEstate/PersonalAssetsTable";
import {NO_OP} from "../../../../constants/common";
import {AccordionItemIds} from "../constants";
import {DropResult} from "react-beautiful-dnd";

type PersonalAssetsProps = {
    profileId: string,
    personalAssets: PersonalAsset[],
    capturePersonalAssetsOrder?: ({source, destination}: DropResult) => void
}

const PersonalAssets = ({
                            profileId,
                            personalAssets,
                            capturePersonalAssetsOrder = NO_OP
                        }: PersonalAssetsProps) => {

    if (personalAssets.length === 0) {
        return <></>;
    }

    return (
        <AccordionItemWithActionMenu
            uuid={AccordionItemIds.personalAssets}
            expandable={false}
            accentColor={COLOR_PERSONAL_ASSETS}
            HeaderComponent={({expanded}) =>
                <AssetAccordionHeader
                    expanded={expanded}
                    title="Personal Assets"
                    formattedTotalPresentValue={formatCurrency(calculatePersonalAssetInEstateTotalValue(personalAssets))}
                    gridClassName={"assets-grid"}
                    showChevron={false}
                />
            }
        >
            <PersonalAssetsTable
                inEstatePersonalAssets={personalAssets}
                profileId={profileId}
                viewType="AssetSummary"
                onDeleteAssetClick={NO_OP}
                hideActionMenu={true}
                isDraggable={true}
                capturePersonalAssetsOrder={capturePersonalAssetsOrder}
            />
        </AccordionItemWithActionMenu>
    )
};

export default PersonalAssets;