import React, {FC} from 'react';
import ModalWrapper from "../../components/Modal/ModalWrapper/ModalWrapper";


export type RefreshContingencyModalProps = {
    isOpen: boolean
    onRefreshContingency: () => void
    onTurningOffContingency: () => void
    closeHandler: () => void
}

const DiscardModal: FC<RefreshContingencyModalProps> = ({
                        isOpen = false,
                        onRefreshContingency,
                        onTurningOffContingency,
                        closeHandler
}) => (
    <ModalWrapper
        id=""
        isOpen={isOpen}
        headerText={'Changing Lifestyle spending will impact the amount of the Lifestyle goal funded by non-investable assets'}
        showCloseButton={true}
        onRequestClose={closeHandler}
        buttons={[
            {
                text: 'REFRESH CONTINGENCY',
                onClick: onRefreshContingency,
            },
            {
                text: 'TURN OFF CONTINGENCY',
                onClick: onTurningOffContingency,
                destructive: true,
                primary: true,
            }
        ]}
    >
        <div className="font-md">By refreshing the amount of Lifestyle spending funded by non-investable assets, non-Lifestyle goals will be prioritized during periods of Portfolio Reserve activation.</div>
        <div className="font-md">By turning off funded by non-investable assets for this Lifestyle goal, Lifestyle spending will instead be prioritized on the Goal Prioritization page.</div>
    </ModalWrapper>
)

export default DiscardModal;
