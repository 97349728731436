import React from "react";
import {formatCurrency} from "../utils/format";

export type AssetTitlingHeaderProps = {
    currentNetWorthAtDeath: number
};

export function AssetTitlingHeader({currentNetWorthAtDeath}: AssetTitlingHeaderProps) {
    return (
        <h3>
            <div>
                Your current net worth
                is <b>{formatCurrency(currentNetWorthAtDeath)}</b>
            </div>
        </h3>
    );
}