import {TableActionDropdownMenu} from "../../../components/Table/TableActionDropdownMenu";
import {DropdownItem} from "../../../components";
import {Checkbox} from "xps-react";
import React, {ChangeEvent, useState} from "react";

type ReviseAllocationSubclassMenuProps = {
    subclassName: string,
    excludeSubclass: boolean,
    isDisableMenu: boolean,
    toggleExcludeSubclass: (subclassName: string, excludeSubclass: boolean) => Promise<void>
};

function ReviseAllocationSubclassMenu(props: ReviseAllocationSubclassMenuProps) {
    const [currentSubclassName, _setCurrentSubclassName] = useState(props.subclassName);

    return <TableActionDropdownMenu ariaLabel={`${props.subclassName} Action Menu`} disabled={props.isDisableMenu}>
        <DropdownItem
            itemText=""
            value="do_not_use"
            onClick={() => {
                // Ignore this. Using checkbox here directly as a menuitem is overriding some attributes like role
            }}
        >
            <Checkbox
                aria-label={`${currentSubclassName} Exclude Subclass`}
                name={`${currentSubclassName} Exclude Subclass`}
                value="Exclude Subclass"
                checked={props.excludeSubclass}
                onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                    await props.toggleExcludeSubclass(currentSubclassName, e.target.checked)
                }}
            />
        </DropdownItem>
    </TableActionDropdownMenu>;
}

export default ReviseAllocationSubclassMenu