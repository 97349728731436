import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from '../../store/store';

export type AccordionState = {
    expandedItems: string[];
}

export type AccordionStateChangePayload = {
    accordionId: string;
    state: AccordionState;
}

export type AccordionStates = {
    [key: string]: AccordionState
}

export const initialAccordionState: AccordionStates = {};

export const accordionSlice = createSlice({
    initialState: initialAccordionState,
    name: 'accordion',
    reducers: {
        setAccordionState: (state, action: PayloadAction<AccordionStateChangePayload>) => {
            state[action.payload.accordionId] = action.payload.state;
        },
        removeAccordionState: (state, action: PayloadAction<string>) => {
            delete state[action.payload];
        },
    }
});

export const {
    setAccordionState,
    removeAccordionState,
} = accordionSlice.actions;

export const selectAccordionStates = (state: RootState) => state.client.accordion;

export default accordionSlice.reducer;
