import React, {ChangeEvent, useEffect, useMemo, useState} from "react";
import {
    AgeInput,
    BirthdateInput,
    Dropdown,
    DropdownItem,
    GenderInput,
    LifeStatusInput,
    MiddleInitial,
    Name,
    PersonalPlanningHorizonInput,
    StateOfResidencyInput,
} from "../../../components";
import {
    displayedRelationshipTypes as humanReadableRelationshipType,
    findAllFamilyMembersForExcludingOthers,
    getRelationshipType,
    getRelationshipTypeDisplayValue,
    getValidRelationshipTypes
} from "../RelationshipTypeUtil";
import {
    calculateAgeFromBirthdate,
    calculateBirthdateFromAge,
    momentToISO,
    personalPlanningHorizonFromAge,
    toDisplayDateFormat,
    toISO
} from "../../../utils/dateUtils";
import {FamilyMemberForm, FamilyMemberFormValidationContainer, LifeStatus, MemberType} from "../../models/MemberType";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {
    SaveAction,
    selectFamilyFormInteractions,
    selectFamilyFormValidation,
    selectFamilyMemberForm,
    selectFamilyMode,
    selectFamilyTree,
    selectIsSaveButtonClicked,
    selectListOfStates,
    selectRelatedTo,
    selectRelationshipStates,
    selectSaveAction,
    setFamilyFormInteractions,
    setFamilyFormValidation,
    setFamilyMemberForm,
    setIsValidForm,
    setRelatedTo,
    setRelationshipStates
} from "../FamilyTreeSlice";
import {FamilyRelationshipType} from "../../models/FamilyRelationshipType";
import {FamilyMode} from "./FamilyMode";
import {InlineAlert} from "../../../components/AlertBanner/InlineAlert";
import {InlineAlerts} from "./AddOrEditFamilyMember";
import {
    findAllPartnersForMemberId,
    getPrimaryParentForMemberWhenMemberIsNotPrimaryOrLateral
} from "../../FamilyRelationshipService";
import {validateInputRemovingSpaces} from "../../../utils/stringUtils";
import moment from "moment/moment";
import {DeceasedDateInput} from "./DeceasedDateInput";
import {selectProfile} from "../../ClientProfile/activeProfileSlice";
import useProfileEditableState from "../../../hooks/useProfileEditableState";

const formatFullName = (member: MemberType | null) => {
    if(member) {
        const middleInitial = member.middleInitial ? member.middleInitial + ". " : "";
        return member.firstName + " " + middleInitial + member.lastName + " (" + member.age + ")";
    }
    return '';
}

type MemberDetailsFormProps = {
    setInlineAlertVisibility: (value: InlineAlerts) => void,
    inlineAlertVisibility: InlineAlerts,
    isMemberAssetOwner: boolean,
}

export const MemberDetailsForm: React.FC<MemberDetailsFormProps> = ({
                                                                        setInlineAlertVisibility,
                                                                        inlineAlertVisibility,
                                                                        isMemberAssetOwner,
                                                                    }) => {
    const dispatch = useAppDispatch();
    const createEditAction = useAppSelector(selectSaveAction);
    const familyMode = useAppSelector(selectFamilyMode);
    const familyFormValidation = useAppSelector(selectFamilyFormValidation)!;
    const formInteractions = useAppSelector(selectFamilyFormInteractions)!;
    const familyTree = useAppSelector(selectFamilyTree)!;
    const familyMember: FamilyMemberForm = useAppSelector(selectFamilyMemberForm)!;
    const listOfStates = useAppSelector(selectListOfStates)!;
    const isSaveButtonClicked = useAppSelector(selectIsSaveButtonClicked);
    const relationshipStates = useAppSelector(selectRelationshipStates)!;
    const relatedTo = useAppSelector(selectRelatedTo)!;
    const {activeClient} = useAppSelector(selectProfile)
    const {isProfileWithProposalsOrArchived} = useProfileEditableState()

    const [originalFormData, setOriginalFormData] = useState<FamilyMemberForm>(familyMember);
    useEffect(() => {
        if (familyMember.id !== originalFormData.id) {
            setOriginalFormData(familyMember);
        }
    }, [familyMember.id]);

    const isPrimaryContact = familyMember?.id === familyTree?.primaryContact?.id;
    const showRelatedToField = createEditAction === SaveAction.Create && familyMode === FamilyMode.IMMEDIATE
    const showInlineAlerts = createEditAction === SaveAction.Edit
    const formFieldsReadOnly = isProfileWithProposalsOrArchived && activeClient;

    const relationshipType = useMemo(() => relatedTo
            && relationshipStates.find(state => state.memberId === relatedTo.id)?.type,
        [relatedTo, relationshipStates]);
    let memberRelationshipType: FamilyRelationshipType | null = null;
    if (familyMember.id && !isPrimaryContact) {
        memberRelationshipType = getPrimaryParentForMemberWhenMemberIsNotPrimaryOrLateral(familyMember.id, familyTree.primaryContact)
            ? FamilyRelationshipType.CHILD
            : findAllPartnersForMemberId(familyMember.id, familyTree.primaryContact).values().next().value?.[1] || null;
    }
    const isActivePartnerMemberOfPrimaryContact = !!relationshipType &&
        [FamilyRelationshipType.SPOUSE, FamilyRelationshipType.DOMESTIC_PARTNER]
            .includes(relationshipType)
        && relatedTo.id === familyTree.primaryContact.id;

    const updateRelationshipStates = (option: string) => {
        let familyRelationshipType = getRelationshipType(option);
        dispatch(setRelationshipStates([{
            memberId: relatedTo.id,
            fullName: `${relatedTo.firstName} ${relatedTo.lastName}`,
            type: familyRelationshipType
        }]))
    }


    const inputChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setFamilyMemberForm({
            ...familyMember,
            [e.target.name]: validateInputRemovingSpaces(e.target.value)
        }));
    }

    const relatedToCandidates: [string, MemberType][] = useMemo(() => {
        return findAllFamilyMembersForExcludingOthers(familyTree.primaryContact)
            .map(member => [formatFullName(member), member]);
    }, [familyTree]);

    useEffect(() => {
        checkFormIsValid(familyMember);
    }, [familyMember, relationshipStates, familyMode]);

    const checkFormIsValid = (memberToValidate: FamilyMemberForm) => {
        const familyMemberValidation: FamilyMemberFormValidationContainer = validateFamilyMemberForm(memberToValidate, isActivePartnerMemberOfPrimaryContact);
        dispatch(setFamilyFormValidation(familyMemberValidation));

        const hasNoFormValidationErrors = Object.values(familyMemberValidation).every(x => x === null);
        const hasNoFormValidationOrRelationshipErrors = hasNoFormValidationErrors
            && (relationshipStates?.length !== 0)
            && relationshipStates.every((relationship) => !!relationship.type)

        const isValid = isPrimaryContact ? hasNoFormValidationErrors
            : hasNoFormValidationOrRelationshipErrors;
        dispatch(setIsValidForm(isValid));
        return isValid;
    }

    const handleAgeInteraction = () => dispatch(setFamilyFormInteractions({
        ...formInteractions,
        age: true
    }));

    return (
        <div className="details-tab">
            {showRelatedToField &&
                <div data-testid="add-or-edit-family-member-tab">
                    <div className="layout-data-entry-form__field related_to familyMemberFormField">
                        <label id="related_to">
                            <b>Related To </b><i className="color-text--error">*</i>
                        </label>
                        <Dropdown
                            name="related_to"
                            aria-label="related_to"
                            aria-labelledby="related_to"
                            className="related_to"
                            id="relation_to_dropdown"
                            panelWidth="100%"
                            value={formatFullName(relatedTo)}
                            onChange={(e: { itemText: string }) => {
                                const newRelatedTo = relatedToCandidates
                                    .filter(([fullName]) => fullName == e.itemText)
                                    .map(([, member]) => member)[0];
                                dispatch(setRelatedTo(newRelatedTo))
                                dispatch(setRelationshipStates([{
                                    memberId: newRelatedTo.id,
                                    type: getValidRelationshipTypes(newRelatedTo, familyTree.primaryContact).includes(humanReadableRelationshipType.CHILD)
                                        ? FamilyRelationshipType.CHILD
                                        : null,
                                    fullName: `${newRelatedTo.firstName} ${newRelatedTo.lastName}`
                                }]))
                            }}
                        >
                            {relatedToCandidates
                                .map(([fullName]) => <DropdownItem key={fullName} itemText={fullName}
                                                                   value={fullName}/>)}
                        </Dropdown>
                    </div>
                    <div className="layout-data-entry-form__field relationship-type familyMemberFormField">
                        <label htmlFor="relationshipType" id="relationship_type_label">
                            <b>Relationship Type </b><i className="color-text--error">*</i>
                        </label>
                        <Dropdown
                            name="relationshipType"
                            aria-label="relationship-type"
                            className="relationship-type"
                            id="relationship_type_dropdown"
                            panelWidth="100%"
                            defaultText="Select..."
                            value={relationshipType && getRelationshipTypeDisplayValue(relationshipType)}
                            error={isSaveButtonClicked && (
                                relationshipStates.length === 0
                                || relationshipStates.some((relationship) => !relationship.type)
                            )
                                ? 'Relationship Type is required.'
                                : ''
                            }
                            onChange={(e: { itemText: string }) => {
                                updateRelationshipStates(e.itemText);
                                dispatch(setFamilyMemberForm({
                                    ...familyMember,
                                    deceasedDate: null
                                }));
                            }}>
                            {familyTree.primaryContact ? getValidRelationshipTypes(relatedTo, familyTree.primaryContact).map(_type => {
                                return <DropdownItem key={_type} itemText={_type} value={_type}/>
                            }) : undefined}
                        </Dropdown>
                    </div>
                </div>}
            <div className="parent">
                <Name name={familyMember.firstName}
                      className="layout-data-entry-form__field first-name familyMemberFormField"
                      label={"First Name"}
                      onChange={inputChangeHandler}
                      required={true}
                      error={familyFormValidation.familyMemberForm?.firstName}
                      hasInteractions={formInteractions.firstName}
                      whenUserHasInteracted={() => dispatch(setFamilyFormInteractions({
                          ...formInteractions,
                          firstName: true
                      }))}
                      forceShowErrors={isSaveButtonClicked}
                      readOnly={formFieldsReadOnly}
                      maxLength={25}
                />
            </div>
            <MiddleInitial name={familyMember.middleInitial ? familyMember.middleInitial : ''} required={false}
                           className="layout-data-entry-form__field middle-initial familyMemberFormField"
                           label={"Middle Initial"} onChange={inputChangeHandler}
                           maxLength={1} readOnly={formFieldsReadOnly}/>
            <Name name={familyMember.lastName}
                  className="layout-data-entry-form__field last-name familyMemberFormField"
                  label={"Last Name"}
                  onChange={inputChangeHandler}
                  required={true}
                  error={familyFormValidation.familyMemberForm?.lastName}
                  hasInteractions={formInteractions.lastName}
                  whenUserHasInteracted={() => dispatch(setFamilyFormInteractions({
                      ...formInteractions,
                      lastName: true
                  }))}
                  forceShowErrors={isSaveButtonClicked}
                  readOnly={formFieldsReadOnly}
                  maxLength={25}
            />
            <BirthdateInput birthdate={familyMember.birthdate}
                            className="layout-data-entry-form__field birthDate familyMemberFormField"
                            width={'284px'}
                            disabled={formFieldsReadOnly}
                            onChange={(birthdate: string) => {
                                dispatch(setFamilyMemberForm({
                                    ...familyMember,
                                    age: calculateAgeFromBirthdate(birthdate),
                                    birthdate: toISO(birthdate),
                                    deceasedDate: familyMember.deceasedDate ? momentToISO(moment.max(moment(familyMember.deceasedDate), moment(toISO(birthdate)))) : familyMember.deceasedDate,
                                }));
                                setInlineAlertVisibility({
                                    ...inlineAlertVisibility,
                                    birthdate: toDisplayDateFormat(originalFormData.birthdate) !== birthdate
                                })
                            }}/>
            <AgeInput label={"Age"} age={familyMember.age}
                      className="layout-data-entry-form__field age familyMemberFormField"
                      onChange={(age: number) => {
                          const birthdateCalculatedFromAge = originalFormData.age === age ? originalFormData.birthdate : calculateBirthdateFromAge(age);
                          dispatch(setFamilyMemberForm({
                              ...familyMember,
                              age: age,
                              birthdate: birthdateCalculatedFromAge,
                              deceasedDate: birthdateCalculatedFromAge && familyMember.deceasedDate ? momentToISO(moment.max(moment(familyMember.deceasedDate), moment(birthdateCalculatedFromAge))) : familyMember.deceasedDate,
                              personalPlanningHorizon: personalPlanningHorizonFromAge(age, familyMember.personalPlanningHorizon)
                          }));
                          setInlineAlertVisibility({
                              ...inlineAlertVisibility,
                              age: originalFormData.age !== age,
                              birthdate: originalFormData.birthdate !== birthdateCalculatedFromAge,
                          })
                      }}
                      error={familyFormValidation.familyMemberForm?.age}
                      hasInteractions={formInteractions.age}
                      whenUserHasInteracted={handleAgeInteraction}
                      forceShowErrors={isSaveButtonClicked}
                      readOnly={formFieldsReadOnly}
            />
            {showInlineAlerts && !familyFormValidation.familyMemberForm?.age && (inlineAlertVisibility.age || inlineAlertVisibility.birthdate) &&
                <InlineAlert
                    className="inline-alert"
                    message={createInlineAlertMessage(
                        isPrimaryContact,
                        memberRelationshipType,
                        familyMode,
                    )}
                />
            }
            <GenderInput className="layout-data-entry-form__field gender familyMemberFormField"
                         panelWidth="100%"
                         disabled={formFieldsReadOnly}
                         onChange={(e: any) => {
                             dispatch(setFamilyMemberForm({
                                 ...familyMember,
                                 gender: e.itemText
                             }))
                         }}
                         value={familyMember.gender.trim()}/>
            <StateOfResidencyInput listOfStates={listOfStates}
                                   className="layout-data-entry-form__field stateOfResidency familyMemberFormField"
                                   panelWidth="100%"
                                   disabled={formFieldsReadOnly}
                                   onSelected={(e: any) => {
                                       dispatch(setFamilyMemberForm({
                                           ...familyMember,
                                           stateOfResidency: e.itemText
                                       }))
                                   }
                                   }
                                   value={familyMember.stateOfResidency || undefined}
            />
            <PersonalPlanningHorizonInput
                personalPlanningHorizon={familyMember.personalPlanningHorizon}
                age={familyMember.age}
                className="layout-data-entry-form__field personalPlanningHorizon familyMemberFormField"
                onChange={(personalPlanningHorizon: number) => {
                    dispatch(setFamilyMemberForm({
                        ...familyMember,
                        personalPlanningHorizon: personalPlanningHorizon
                    }))
                    setInlineAlertVisibility({
                        ...inlineAlertVisibility,
                        personalPlanningHorizon: originalFormData.personalPlanningHorizon !== personalPlanningHorizon
                    });
                }
                }
                error={familyFormValidation.familyMemberForm?.personalPlanningHorizon}
                hasInteractions={formInteractions.age}
                whenUserHasInteracted={handleAgeInteraction}
                forceShowErrors={isSaveButtonClicked}
                readOnly={formFieldsReadOnly}
            />
            {showInlineAlerts && !familyFormValidation.familyMemberForm?.personalPlanningHorizon && inlineAlertVisibility.personalPlanningHorizon &&
                <InlineAlert
                    className="inline-alert"
                    message={createInlineAlertMessage(
                        isPrimaryContact,
                        memberRelationshipType,
                        familyMode,
                    )}
                />
            }
            <LifeStatusInput className="layout-data-entry-form__field lifeStatus"
                             selected={familyMember.lifeStatus}
                             disabled={isPrimaryContact || isMemberAssetOwner || formFieldsReadOnly}
                             onChange={(e: any) => {
                                 dispatch(setFamilyMemberForm({
                                     ...familyMember,
                                     lifeStatus: e.target.value,
                                     deceasedDate: null,
                                 }))
                                 setInlineAlertVisibility({
                                     ...inlineAlertVisibility,
                                     lifeStatus: e.target.value == LifeStatus.Deceased
                                 })
                             }}
            />
            {showInlineAlerts && inlineAlertVisibility.lifeStatus &&
                <InlineAlert
                    className="inline-alert"
                    message={"All goals for this member will be deleted."}
                />
            }
            {
                isActivePartnerMemberOfPrimaryContact && familyMember.lifeStatus === LifeStatus.Deceased &&
                <DeceasedDateInput
                    deceasedDate={familyMember.deceasedDate}
                    birthdate={familyMember.birthdate}
                    disabled={formFieldsReadOnly}
                    onChange={(deceasedDate) => {
                        dispatch(setFamilyMemberForm({
                            ...familyMember,
                            deceasedDate: momentToISO(deceasedDate)
                        }));
                    }}/>
            }
        </div>
    );
}

const createInlineAlertMessage = (
    isPrimaryContact: boolean,
    memberRelationshipType: FamilyRelationshipType | null,
    familyMode: FamilyMode | undefined,
) => {
    if (isPrimaryContact) {
        return 'All lifestyle goals, discretionary goals, and inflows that start after the planning period ends will be deleted.';
    } else if (familyMode === FamilyMode.OTHER) {
        return 'For this member, family goals that start after the planning period ends will be deleted from this proposal.';
    }
    switch (memberRelationshipType) {
        case FamilyRelationshipType.SPOUSE:
        case FamilyRelationshipType.DOMESTIC_PARTNER:
            return 'All lifestyle goals, discretionary goals, and inflows that start after the planning period ends will be deleted.';
        case FamilyRelationshipType.EX_SPOUSE:
        case FamilyRelationshipType.SIGNIFICANT_OTHER:
        case FamilyRelationshipType.CHILD:
            return 'For this member, family goals that start after the planning period ends will be deleted from this proposal.';
        default:
            return '';
    }
};

const validateFamilyMemberForm = (familyMemberForm: FamilyMemberForm, isActivePartnerMemberOfPrimaryContact: boolean)
    : FamilyMemberFormValidationContainer => {
    const familyMemberFormValidation = {
        firstName: familyMemberForm.firstName ? null : "First Name is required.",
        lastName: familyMemberForm.lastName ? null : "Last Name is required.",
        age: (familyMemberForm.age != null && familyMemberForm.age >= 0) ? null : "Age is required.",
        personalPlanningHorizon: familyMemberForm.personalPlanningHorizon ? null : "Personal Planning Horizon is required.",
        deceasedDate: isActivePartnerMemberOfPrimaryContact && familyMemberForm.lifeStatus === LifeStatus.Deceased && familyMemberForm.deceasedDate === null ? "" : null,
    }

    const familyMemberFormHasErrors = Object.values(familyMemberFormValidation).some((validation) => validation !== null)

    return (
        {familyMemberForm: familyMemberFormHasErrors ? familyMemberFormValidation : null}
    )
}
