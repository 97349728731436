import React, {useEffect} from 'react';
import TickerTab from "./TickerTab";
import {TickerValueFormat} from "./TickerValueFormat";
import {wealthManagementApiClient} from "../WealthManagementApiClient";
import {useHistory} from "react-router-dom";
import usePrevious from "../../utils/usePrevious";
import {HeaderTickerTabsResponse} from "./HeaderTickerTabType";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectHeaderTickerTabs, setHeaderTickerTabs} from "./HeaderTickerTabsSlice";

type HeaderTickerTabsProps = {
    profileId: string;
    isResourcePage: boolean;
}

const HeaderTickerTabs: React.FC<HeaderTickerTabsProps> = ({profileId, isResourcePage}) => {
    const dispatch = useAppDispatch();
    const headerTickerTabsResponse = useAppSelector(selectHeaderTickerTabs);

    fetchData(profileId, (abortSignal: AbortSignal) => {
        wealthManagementApiClient.getHeaderTickerTabs(profileId, abortSignal).then(res => {
            dispatch(setHeaderTickerTabs(res))
        }).catch((error) => {
            if (error.name === "AbortError") {
                console.error('Could not fetch header ticker tabs', error.message);
            }
        });
    });

    return <div className="ticker-tabs-container">
        <AssetRelianceTickerTabs assetReliance={headerTickerTabsResponse.assetReliance} isResourcePage={isResourcePage}/>
        <TickerTabSpacer/>
        <ProposedAllocationTickerTabs proposedAllocation={headerTickerTabsResponse.proposedAllocation}/>
    </div>;
}

const AssetRelianceTickerTabs: React.FC<Pick<HeaderTickerTabsResponse, 'assetReliance'> & {isResourcePage: boolean}> = ({assetReliance, isResourcePage}) => {
    const {excludedAssetStacks, allAssetsStack} = assetReliance;
    const updatedExcludedAssetStacks = (isResourcePage && excludedAssetStacks.length > 3) ? [...excludedAssetStacks].slice(0, excludedAssetStacks.length - 2): excludedAssetStacks;

    return <>
        <TickerTab title={'All Excess Assets'}
                   value={allAssetsStack.excessAssets}
                   format={TickerValueFormat.CURRENCY}
        />
        {updatedExcludedAssetStacks.length > 0 && updatedExcludedAssetStacks.map(excludedAssetStack =>
            <TickerTab key={`HeaderTicker_${excludedAssetStack.name}`} title={`${excludedAssetStack.name}`}
                       value={excludedAssetStack.excessAssets}
                       format={TickerValueFormat.CURRENCY}
            />
        )}
    </>;
}

const TickerTabSpacer = () => {
    return <span className="ticker-tab-spacer"/>
};

const ProposedAllocationTickerTabs: React.FC<Pick<HeaderTickerTabsResponse, 'proposedAllocation'>> = ({proposedAllocation}) => {
    return <>
        <TickerTab title={"PROPOSED RA"}
                   value={proposedAllocation.totalRiskAssetsPercent}
                   format={TickerValueFormat.PERCENTAGE}
                   extraMargin={true}
        />
        <TickerTab title={"PROPOSED RCA"}
                   value={proposedAllocation.totalRiskControlAssetsPercent}
                   format={TickerValueFormat.PERCENTAGE}
                   extraMargin={true}
        />
    </>;
}

const fetchData = (profileId: string, callback: (abortSignal: AbortSignal) => void) => {
    const history = useHistory();
    const previousProfileId = usePrevious(profileId);

    const controller = new AbortController();
    const {signal} = controller;

    useEffect(() => {
        // This boolean is intended to cache-bust after saving assets/goals
        const shouldUpdateAssetRelianceData =
            history.location.pathname.includes(`AssetSummary`)
            || history.location.pathname.includes(`Goals/Summary`)
            || history.location.pathname.includes(`AssetReliance`)
            || history.location.pathname.includes(`ReviewPortfolioReserve`);

        if (profileId !== '' && (profileId !== previousProfileId || shouldUpdateAssetRelianceData)) {
            callback(signal);
        }

        // Abort previous wealthManagement call while unmounting
        return () => {
            controller.abort();
        }
    }, [profileId, window.location.pathname]);
}

export default HeaderTickerTabs;