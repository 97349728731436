import React from "react";
import CurrentVsProposedReportView from "./CurrentVsProposedReportView";
import {ReviseAssetAllocationResponse} from "../../../ClientManagement/AssetAllocation/ReviseAssetAllocation";
import {AssetClassifications} from "../../../Assets/models/AssetClassifications";

type currentVsProposedDetailsReportProps = {
    displayName: string,
    currentAllocation: ReviseAssetAllocationResponse,
    proposedAllocation: ReviseAssetAllocationResponse,
    isDetailedReport: boolean,
    classifications: AssetClassifications
}

const CurrentVsProposedDetailsReport = ({
                                            displayName,
                                            currentAllocation,
                                            proposedAllocation,
                                            isDetailedReport,
                                            classifications
                                        }: currentVsProposedDetailsReportProps) => {

    return <CurrentVsProposedReportView currentAllocation={currentAllocation}
                                        proposedAllocation={proposedAllocation}
                                        displayName={displayName}
                                        isDetailedReport={isDetailedReport}
                                        classifications={classifications}
    />
}

export default CurrentVsProposedDetailsReport;