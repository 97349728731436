import React, {ChangeEvent, useState} from "react";
import {RadioYesNoOptional} from "../models/Common";
import {booleanToRadioButton, radioButtonToBoolean} from "../formHelpers";
import TaxLiabilitiesTable from "./TaxLiabilitiesTable";
import {Button, UnderlinedHeader} from "../../components";
import RadioGroup from './RadioGroupWithErrorMessage';
import {TaxDetailsType, TaxStatusOptions} from "../models/TaxDetails";

type TaxDetailsProps = {
    taxDetails: TaxDetailsType,
    updateTaxDetails: (taxDetails: TaxDetailsType) => void,
    taxStatus: TaxStatusOptions,
    isRequiredFieldsBannerShown: boolean,
    unrealizedCapitalGainsTax?: number | null,
    deferredTaxLiability?: number | null,
    totalInvestablePresentValue?: number | null,
    handleUnrealizedCapitalGainsTaxChange: (isEntityCapitalGains: boolean) => void
    handleLiabilityPaidByPortfolioChange: (liabilityPaidByPortfolio: boolean) => void,
    isProfileWithProposalsOrArchived?: boolean
};

const TaxDetails: React.FC<TaxDetailsProps> = ({
                                                   taxDetails,
                                                   updateTaxDetails,
                                                   taxStatus,
                                                   isRequiredFieldsBannerShown,
                                                   unrealizedCapitalGainsTax,
                                                   deferredTaxLiability,
                                                   totalInvestablePresentValue,
                                                   handleUnrealizedCapitalGainsTaxChange,
                                                   handleLiabilityPaidByPortfolioChange,
                                                   isProfileWithProposalsOrArchived = false
                                               }) => {
    const [isTaxLiabilityTooltipVisible, setTaxLiabilityTooltipVisible] = useState(false);

    const handleUpdateTaxDetails = (e: ChangeEvent<HTMLInputElement>) => {
        const {value, name} = e.target;

        const booleanizedValue = radioButtonToBoolean(value as RadioYesNoOptional);
        const updatedTaxDetails = {...taxDetails}
        switch (name) {
            case "isEntityCapitalGains":
                updatedTaxDetails.isEntityCapitalGains = booleanizedValue;
                updatedTaxDetails.isLiabilityPaidByPortfolio = taxDetails.isLiabilityPaidByPortfolio;
                handleUnrealizedCapitalGainsTaxChange(booleanizedValue);
                break;
            case "isLiabilityPaidByPortfolio":
                updatedTaxDetails.isLiabilityPaidByPortfolio = booleanizedValue;
                handleLiabilityPaidByPortfolioChange(booleanizedValue)
                break;
            default:
                break;
        }

        updateTaxDetails(updatedTaxDetails);
    };

    const isLiabilityEmptyOnSave = () => {
        return isRequiredFieldsBannerShown && taxDetails.isLiabilityPaidByPortfolio === null;
    };

    return (
        <section className="tax-details" data-testid="tax-details">
            <UnderlinedHeader
                className="tax-details-section-header"
                primaryText="Tax Details"
            />
            {taxStatus === "Taxable" && (
                <RadioGroup
                    id="isEntityCapitalGains"
                    name="isEntityCapitalGains"
                    label="Is this entity subject to capital gains?"
                    layout="horizontal"
                    values={["Yes", "No"]}
                    onChange={handleUpdateTaxDetails}
                    selected={booleanToRadioButton(taxDetails.isEntityCapitalGains)}
                    disabled={isProfileWithProposalsOrArchived}
                />
            )}
            {(taxDetails.isEntityCapitalGains !== false || taxStatus === 'Deferred') && (
                <>
                    <RadioGroup
                        id="isLiabilityPaidByPortfolio"
                        name="isLiabilityPaidByPortfolio"
                        error={isLiabilityEmptyOnSave()}
                        errorMessage="Tax Detail is required."
                        label="Will the tax liability be paid by the investable portfolio?"
                        required
                        layout="horizontal"
                        values={["Yes", "No"]}
                        onChange={handleUpdateTaxDetails}
                        selected={booleanToRadioButton(taxDetails?.isLiabilityPaidByPortfolio)}
                        disabled={isProfileWithProposalsOrArchived}
                    >
                        <Button
                            icon="only"
                            iconName="info_outline"
                            kind="borderless"
                            onClick={() => {
                                setTaxLiabilityTooltipVisible(!isTaxLiabilityTooltipVisible);
                            }}
                            size="small"
                        />
                    </RadioGroup>

                    {isTaxLiabilityTooltipVisible &&
                        <div className="tax-info-card">
                            <div className="padding-lg tax-info-card__content font-default">
                                <div className="marginbottom-lg">If no, then no asset allocation will be created for it
                                    within
                                    the
                                    investable portfolio today.
                                </div>
                                <div>Non-Investable assets may include:</div>
                                <ul className="paddingleft-xl">
                                    <li>Future inflows such as an inheritance, bonus payment, or a lump sum of cash</li>
                                    <li>Future liquidations such as a sale of a business, investment property, or
                                        home.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    }
                    <TaxLiabilitiesTable taxStatus={taxStatus}
                                         unrealizedCapitalGainsTax={unrealizedCapitalGainsTax}
                                         deferredTaxLiability={deferredTaxLiability}
                                         totalInvestablePresentValue={totalInvestablePresentValue}
                                         liabilityPaidByPortfolio={taxDetails.isLiabilityPaidByPortfolio}/>
                </>
            )}


        </section>
    );
};

export default TaxDetails;
