import {useEffect} from 'react';
import {useRelayContext} from "../types/RelayContext";
import {RelayEventHook} from "../types/RelayEventHook";
import {MeetingStatus} from "../../Meeting";
import {selectActiveMeeting, setMeetingStatus} from "../../meetingSlice";
import {useAppDispatch, useAppSelector} from "../../../../store/hooks";
import useMeetingUtils from "../../useMeetingUtils";
import {useCommunicationsContext} from "../../CommunicationsContext";
import {selectReleaseToggles} from "../../../../ReleaseToggles/releaseTogglesSlice";

const meetingStatusKey = 'status';

const relayMeetingStatus: RelayEventHook<MeetingStatus> = (
    sharedObjects,
    registerMutatorFunction,
    registerValueChangeHandler
) => {
    const {meetingControlDDS} = sharedObjects;
    const {sharedObjectMutators, unloadMeetingContainer} = useRelayContext();
    const communicationsContext = useCommunicationsContext();
    const {isCurrentUserPresenting} = useMeetingUtils();
    const dispatch = useAppDispatch();
    const meeting = useAppSelector(selectActiveMeeting)!;
    const releaseToggles = useAppSelector(selectReleaseToggles)

    // Register Mutator Function
    useEffect(() => {
        // Register function to update the meeting status
        registerMutatorFunction({
            key: 'updateMeetingStatus',
            callback: (status: MeetingStatus) => {
                meetingControlDDS.set(meetingStatusKey, status);
            }
        });
    }, []);

    // Emit Changes via Mutator
    useEffect(() => {
        // Emit local changes to meeting status if the current user is presenting
        if (isCurrentUserPresenting && meeting.status) {
            sharedObjectMutators.updateMeetingStatus(meeting.status);
        }
        if (meeting.status === MeetingStatus.ENDED) {
            unloadMeetingContainer();
            if (releaseToggles?.enableMeetingManagementRemote) {
                communicationsContext.disconnect().then();
            }
        }
    }, [sharedObjectMutators.updateMeetingStatus, meeting.status, isCurrentUserPresenting, unloadMeetingContainer, communicationsContext.disconnect]);

    // Register ValueChange Handler
    useEffect(() => {
        // Capture remote changes to meeting status
        if (meeting.id) {
            registerValueChangeHandler({
                sharedMapName: 'meetingControlDDS',
                key: meetingStatusKey,
                callback: (status) => {
                    dispatch(setMeetingStatus(status));
                },
            });
        }
    }, []);
};

export default relayMeetingStatus;