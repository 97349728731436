import React from "react";
import {NO_OP} from "../../constants/common";

interface TagProps {
    name: string;
    disabled?: boolean;
    backgroundColor: string;
    textColor: string;
    toggle?: () => void;
}

const FamilyTreeTag: React.FC<TagProps> = ({
                                               name, disabled = false,
                                               backgroundColor,
                                               textColor,
                                               toggle = NO_OP
                                           }) => {

    return (
        <div className={"family-tree-tag"}>
            <button
                style={{
                    backgroundColor: backgroundColor,
                    color: textColor
                }}
                disabled={disabled}
                onClick={() => {
                    toggle();
                }}
            >
                {name}
            </button>
        </div>
    )
}

export default FamilyTreeTag;