import {PersonalAsset, PersonalAssetDetailsType} from "../models/PersonalAsset";

export function mapPersonalAsset(personalAsset: PersonalAsset): PersonalAssetDetailsType {
    return {
        id: personalAsset.id,
        type: personalAsset.type,
        description: personalAsset.description,
        presentValue: personalAsset.presentValue,
    };
}
