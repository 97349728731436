import React, {ReactNode} from 'react';
import classNames from "classnames";

interface UnderlinedHeaderProps {
    className?: string,
    primaryText?: string,
    secondaryContent?: ReactNode,
    leftAlignedContent?: ReactNode,
    rightAlignedContent?: ReactNode,
}

const UnderlinedHeader = ({
                              className,
                              primaryText,
                              secondaryContent,
                              leftAlignedContent,
                              rightAlignedContent,
                          }: UnderlinedHeaderProps) => {
    return (
        <header className={classNames('underlined-header', className)}>
              <span className="underlined-header__left-content">
                {primaryText ? <h4>{primaryText}</h4> : leftAlignedContent}
                {secondaryContent ? secondaryContent : <React.Fragment/>}
              </span>
            {rightAlignedContent ? <span className="underlined-header__right-content">{rightAlignedContent}</span> : <React.Fragment/>}
        </header>
    );
};

export default UnderlinedHeader;
