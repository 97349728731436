import React, {useEffect, useState} from "react";
import {Icon} from "../Icon";
import classNames from "classnames";

type AccordionItemListHeaderProps = {
    firstColumnTitle: string,
    secondColumnTitle?: string,
    lastColumnTitle: string,
    className?: string,
    isExpandedMain?: boolean,
    testId?: string,
    onExpandedChanged: Function,
    initialIsExpanded?: boolean
}

export default function AccordionItemListHeader({   firstColumnTitle,
                                                    secondColumnTitle,
                                                    lastColumnTitle,
                                                    className,
                                                    testId,
                                                    onExpandedChanged,
                                                    initialIsExpanded = false,
                                                    isExpandedMain
                                                }: AccordionItemListHeaderProps) {
    const [isExpanded, updateIsExpanded] = useState(initialIsExpanded);
    const handleOnClick = () => {
        updateIsExpanded(!isExpanded)
        onExpandedChanged(!isExpanded)
    };

    useEffect(() => {
        if(!isExpandedMain){
            updateIsExpanded(false)
        }
    }, [isExpandedMain]);

    return <div className={classNames(className, 'paddingleft-12', 'margintop-xl')} data-testid={testId}>
        <span className={"display-flex align-items-center paddingleft-md marginbottom-md grid-span-3"}
              onClick={handleOnClick}
              role="button"
              aria-pressed={isExpanded ? 'true' : 'false'}
        >
                <Icon name={isExpanded ? "chevron_double_down" : "chevron_double_right"}/>
                <span className="condensed-subtitle paddingleft-md">{firstColumnTitle}</span>
            </span>
        <span role="cell" className="condensed-subtitle textalign-right">{secondColumnTitle}</span>
        <span role="cell" className="condensed-subtitle textalign-right">{lastColumnTitle}</span></div>
}