import {formatCurrency} from "../../../utils/format";
import {TableActionDropdownMenu} from "../../../components/Table/TableActionDropdownMenu";
import {DropdownItem, Icon} from "../../../components";
import React from "react";
import {TableCell} from "../../../components/Table/TableCell";
import {PersonalLiabilitySummary} from "../../models/PersonalLiability";
import classNames from "classnames";
import useProfileEditableState from "../../../hooks/useProfileEditableState";

type AssetSummaryPersonalLiabilityProps = {
    personalLiability: PersonalLiabilitySummary;
    onClickEdit: (id: string) => void;
    onClickDelete: (personalLiability: PersonalLiabilitySummary) => void;
    renderPersonalLiabilityDetails: (personalLiability: PersonalLiabilitySummary) => React.ReactNode;
    actionsDisabled: boolean;
    gridClassName: string,
    hideActionMenu?: boolean
};
export const PersonalLiabilityRow = ({
                                         personalLiability,
                                         onClickEdit,
                                         onClickDelete,
                                         renderPersonalLiabilityDetails,
                                         actionsDisabled,
                                         gridClassName,
                                         hideActionMenu = false
                                     }: AssetSummaryPersonalLiabilityProps) => {
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    return (<div key={`life-insurance-row-${personalLiability.id}`}
                 aria-label={`life-insurance-row-${personalLiability.id}`}
                 role="row"
                 className={classNames("assets-grid-table-row", gridClassName)}
                 tabIndex={0}>
        {renderPersonalLiabilityDetails(personalLiability)}
        <TableCell text={formatCurrency(-1 * personalLiability.loanBalanceEstateValue.inEstateValue)} className="textalign-right"/>
        {!hideActionMenu && <TableActionDropdownMenu
            disabled={actionsDisabled}
            ariaLabel={`${personalLiability.description} Menu`}>
            <DropdownItem
                itemText="Edit Liability"
                value="Edit Liability"
                onClick={() => onClickEdit(personalLiability.id)}
            />
            <DropdownItem
                itemText="Delete Liability"
                value="Delete Liability"
                disabled={isProfileWithProposalsOrArchived}
                onClick={() => onClickDelete(personalLiability)}
            >
                <Icon name="delete"
                      className="asset-table-delete-icon"/>
            </DropdownItem>
        </TableActionDropdownMenu>}
    </div>);
};
