type AccordionHeaderProps = {
    columns: string[],
    className?: string,
    gridSpanClass?: string,
    testId?: string,
}

function SectionHeader({
                           columns,
                           className = 'assets-header-grid-with-actionmenu',
                           gridSpanClass = "grid-span-3",
                           testId,
                       }: AccordionHeaderProps) {

    return (
        <div className={`${className} assets-grid-table paddingleft-12`.trim()} data-testid={testId}>
              <span
                  className={`"display-flex align-items-center paddingleft-md marginbottom-md cursor-pointer ${gridSpanClass}`.trim()}
                  role="button"
              >
                <span className="condensed-subtitle paddingleft-md">{columns[0]}</span>
            </span>
            {
                columns.slice(1).map((column, index) => {
                    return <span key={`${className}-col-${index}`} role="cell"
                                 className="condensed-subtitle textalign-right">{column}</span>
                })
            }
        </div>
    );
}

export default SectionHeader;