import React, {FC} from "react";
import ModalWrapper from "../components/Modal/ModalWrapper/ModalWrapper";

export type DiscardAssetModalProps = {
    isOpen: boolean;
    onClickKeepEditing: () => void,
    onClickDiscardChanges: () => void,
    title: string,
    content: string
}

const DiscardAssetModal: FC<DiscardAssetModalProps> = ({   isOpen = false,
                                                           onClickKeepEditing,
                                                           onClickDiscardChanges,
                                                           title,
                                                           content,}) => (
    <ModalWrapper
        id="discard-changes-modal"
        isOpen={isOpen}
        headerText = {title}
        buttons={[
            {
                text: 'Keep Editing',
                onClick: onClickKeepEditing,
            },
            {
                text: 'Discard Changes',
                onClick: onClickDiscardChanges,
                destructive: true,
                primary: true,
            }
        ]}
    >
        <div className="font-md">{content}</div>
    </ModalWrapper>
);

export default DiscardAssetModal;