import React, {useState} from "react";
import NewWindow from "react-new-window";
import {App_Conf} from "../../core/app_conf";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectActiveMeeting, setShowClientView} from "./meetingSlice";

const ClientViewWindow: React.FC = () => {
    const dispatch = useAppDispatch();
    const meeting = useAppSelector(selectActiveMeeting);
    const [newWindow, setNewWindow] = useState<Window | null>(null);

    return <NewWindow
        url={`${App_Conf.APP_URL}/meetings?id=${meeting?.onlineMeetingId}&code=${meeting?.onlineMeetingCode}&firstName=Guest`}
        onOpen={(window) => {
            window.onload = () => {
                setNewWindow(window);
            }
        }}
        onUnload={() => {
            dispatch(setShowClientView(false));
            newWindow?.close();
        }}
        features={{width: 1366, height: 1024}}>
    </NewWindow>
}

export default ClientViewWindow;