import classNames from "classnames";
import { Icon } from "../../components";
import { isShowFundingForInflows, isShowFundingForNonInvestable, isShowOnlyPortfolio } from "./fundingUtils";
import { FundingDisplayOptions } from "./models/Funding";

type FundingAccordionHeaderProps = {
    expanded: boolean;
    toggle: () => void;
    displayOptions: FundingDisplayOptions;
};

export function FundingAccordionHeader({
    expanded,
    toggle,
    displayOptions
}: FundingAccordionHeaderProps) {
    return (
        <div
            className="funding-accordion-header paddingleft-12 funding-grid"
            data-testid='funding-accordion-header'
        >
            <span
                className={classNames("display-flex align-items-center paddingleft-md cursor-pointer", {
                    "grid-row-span-2": !isShowOnlyPortfolio(displayOptions)
                })}
                onClick={toggle}
                role="button"
                aria-pressed={expanded ? 'true' : 'false'}
            >
                <Icon name={expanded ? "chevron_double_down" : "chevron_double_right"} />
                <span className="condensed-subtitle paddingleft-md">Goal Type</span>
            </span>
            {isShowOnlyPortfolio(displayOptions) 
                ? (<>
                    <span role="cell" className="condensed-subtitle textalign-right">Risk Assets</span>
                    <span role="cell" className="condensed-subtitle textalign-right">Risk Control</span>
                </>)
                : <span role="cell" className="condensed-subtitle textalign-center grid-col-span-2-5 row-divider paddingbottom-sm">Funded by Investable Portfolio</span>
            }
            {isShowFundingForInflows(displayOptions) && <span
                role="cell"
                className={classNames("condensed-subtitle textalign-right", {
                    "grid-row-span-2": !isShowOnlyPortfolio(displayOptions)
                })}
            >
                Funded by Inflows
            </span>}
            {isShowFundingForNonInvestable(displayOptions) && <span
                role="cell"
                className={classNames("condensed-subtitle textalign-right", {
                    "grid-row-span-2": !isShowOnlyPortfolio(displayOptions)
                })}
            >
                Funded by Non-Investable
            </span>}
            <span
                role="cell"
                className={classNames("condensed-subtitle textalign-right", {
                    "grid-row-span-2": !isShowOnlyPortfolio(displayOptions)
                })}
            >
                Present Value
            </span>
            <span />
            {!isShowOnlyPortfolio(displayOptions) && (<>
                <span role="cell" className="condensed-subtitle-small textalign-right grid-col-span-2-3 paddingtop-sm">Risk Assets</span>
                <span role="cell" className="condensed-subtitle-small textalign-right grid-col-span-3-4 paddingtop-sm">Risk Control</span>
                <span role="cell" className="condensed-subtitle-small textalign-right grid-col-span-4-5 paddingtop-sm">Total</span>
            </>)}
        </div>
    );
}