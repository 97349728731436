import {AssetRelianceStack} from "../../models/AssetRelianceResponse";
import {COLOR_ASSETS_ACCOUNTS, COLOR_NT_GREY_050} from "../../../constants/colors";
import AssetBarChartColumn from "./AssetBarChartColumn";

export default class AssetBarChartInvestableColumn extends AssetBarChartColumn {

    constructor(
        investableStack: AssetRelianceStack,
        isAssetSufficient: boolean,
        overlayMaxValue: number = 1
    ) {
        super(investableStack, isAssetSufficient, overlayMaxValue)
    }

    override getColumnMaxValue() {
        const goals = this.getGoalsForStack();
        const assetsAndExcluded = this.stack.netAssets

        return goals > assetsAndExcluded ? goals : assetsAndExcluded;
    }

    static override getColumnMaxValueFromStack(stack: AssetRelianceStack) {
        const goals = AssetBarChartColumn.getGoalsFromStack(stack);
        const assetsAndExcluded = stack.netAssets;

        return goals > assetsAndExcluded ? goals : assetsAndExcluded;
    }

    override getChartItems() {
        let chartItems;

        if (this.stack.excessAssets >= 0) {
            chartItems = [
                {
                    label: "Excess Assets",
                    total: this.stack.excessAssets,
                    color: COLOR_ASSETS_ACCOUNTS,
                    highlight: 0
                },
                {
                    label: "Goals",
                    total: this.stack.netAssets - this.stack.excessAssets,
                    color: COLOR_ASSETS_ACCOUNTS,
                    highlight: this.stack.netAssets - this.stack.excessAssets
                },
            ]
        } else {
            chartItems = [
                {
                    label: "Asset Shortfall",
                    total: Math.abs(this.stack.excessAssets),
                    color: COLOR_NT_GREY_050,
                    highlight: 0
                }
            ]

            if (this.stack.netAssets >= 0) {
                chartItems.push(
                    {
                        label: "Net Assets",
                        total: this.stack.netAssets,
                        color: COLOR_ASSETS_ACCOUNTS,
                        highlight: this.stack.netAssets
                    }
                )
            }
        }

        return chartItems;

    }

}