import React from "react";

type Overflow = 'auto' | 'unset' | 'hidden'

export interface ScrollableContainerProps {
    id: string;
    className?: string;
    overflowX?: Overflow;
    overflowY?: Overflow;
    height?: string;
    width?: string;
    customStyles?: {};
}

const ScrollableContainer: React.FC<ScrollableContainerProps> = ({
                                                                     className,
                                                                     overflowX = 'auto',
                                                                     overflowY = 'auto',
                                                                     height = '100%',
                                                                     width = '100%',
                                                                     customStyles = undefined,
                                                                     id,
                                                                     children,
                                                                 }) => {
    return (
        <div
            data-testid='scrollable-container'
            id={id}
            data-syncscrollposition=""
            className={className}
            style={{
                overflowX: overflowX,
                overflowY: overflowY,
                height: height,
                width: width,
                ...customStyles
            }}
        >
            {children}
        </div>
    );
};

export default ScrollableContainer;