import DataEntryHeader from "../../../components/DataEntry/DataEntryHeader";
import {AlertBanner, Dropdown, DropdownItem, Search} from "../../../components";
import React, {KeyboardEvent, useState} from "react";

type HeaderAndSearchBarProps = {
    onCancel: () => void,
    onSearch: () => void,
    onClear: () => void,
    onChangeSearchText: (searchText: string) => void,
    onChangeSearchType: (searchOption: string) => void,
    searchType: string,
    searchText: string,
    searchOptions: { label: string, value: string }[],
    errorMessage?: string,
    detailErrorMessage?: string
}

const HeaderAndSearchBar = ({
                                onCancel,
                                onSearch,
                                onClear,
                                onChangeSearchText,
                                onChangeSearchType,
                                searchText,
                                searchType,
                                searchOptions,
                                errorMessage,
                                detailErrorMessage
                            }: HeaderAndSearchBarProps) => {

    const [displayErrorMessage, setDisplayErrorMessage] = useState<boolean>(true);

    const renderSearchBar = () => <div className="search-bar-container marginbottom-lg">
        <Dropdown
            className="search-dropdown"
            name="searchDropdown"
            id="search-dropdown-input"
            aria-label="Search Dropdown"
            labelWidth="180px"
            panelHeight="auto"
            panelWidth="180px"
            size="medium"
            onChange={(data) => onChangeSearchType(data.value)}
            value={searchType}
        >
            {
                searchOptions.map(({label, value}) =>
                    <DropdownItem key={value}
                                  itemText={label}
                                  value={value}/>
                )
            }
        </Dropdown>
        <Search
            id="search-input"
            name="search_investment_program"
            size="medium"
            onClearListener={onClear}
            placeholder="Search..."
            handleChangeVal={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeSearchText(e.target.value)
            }}
            onKeyDownListener={(e: KeyboardEvent) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    onSearch();
                }
            }}
            value={searchText}
        />
    </div>

    return (
        <div>
            <DataEntryHeader
                className="dataEntryHeader"
                title="Add Investment Program"
                primaryButtonText="Search"
                onPrimaryButtonClick={onSearch}
                secondaryButtonText="Cancel"
                onSecondaryButtonClick={onCancel}
            />
            <div className="investment-programs-container">
                <AlertBanner
                    fullWidth={false}
                    showAlert={!!errorMessage && displayErrorMessage}
                    icon="warning"
                    type="error"
                    showCloseBtn
                    onClose={() => setDisplayErrorMessage(false)}
                    message={errorMessage}
                    detail={detailErrorMessage}
                />
                {renderSearchBar()}
            </div>
        </div>
    );
}

export default HeaderAndSearchBar;