import {AdvisorDocumentMenus} from "./models/AdviceLibrary";
import {ClientMeetingDocumentsResponse, OldServiceTeam} from "./models/ClientDocuments";

export const DEFAULT_MENU_STRUCTURE_RESPONSE: AdvisorDocumentMenus = {
    advisorDocumentSubMenus: []
}
export const emptyMenuStructure: AdvisorDocumentMenus = {
    advisorDocumentSubMenus: []
};

export const emptyClientMeetingDocumentsSubMenusData: ClientMeetingDocumentsResponse = {
    documentItems: [],
    folderItems: [],
    webUrl: undefined,
    folderItem: undefined,
    isSharePointDown: false
};

export const emptyServiceTeam: OldServiceTeam = {
    resourceCode: undefined,
    partners: []
};
