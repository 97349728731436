import React from "react";
import {ReportType} from "../models/Reporting";
import {Col, Row} from "xps-react";
import {RadioGroup} from "../../components";

type ReportSetupHeaderProps = {
    onChangeReportType: (e: React.ChangeEvent<HTMLInputElement>) => void,
    selectedReportType: ReportType,
    usePlanSummaryAsCoverPage: boolean,
    toggleUsePlanSummaryAsCoverPage: (e: React.ChangeEvent<HTMLInputElement>) => void
};

function ReportSetupHeader(props: ReportSetupHeaderProps) {
    return <Row>
        <Col>
            <div className="section-title">Report Type</div>
            <hr className="section"/>
            <div className="marginleft-xl">
                <RadioGroup
                    className="reportType"
                    id="reportType"
                    name="Report Type"
                    onChange={props.onChangeReportType}
                    selected={props.selectedReportType}
                    //values={["Annual Report"]}
                     values={["Annual Report", "Other Report"]}
                />
            </div>
        </Col>
        <Col>
            {/*<div className="section-title">Report Settings</div>*/}
            {/*<hr className="section"/>*/}
            {/*<div className="marginleft-xl">*/}
            {/*    <Checkbox*/}
            {/*        className="page-item"*/}
            {/*        aria-label="Use Plan Summary as Cover Page"*/}
            {/*        value="Use Plan Summary as Cover Page"*/}
            {/*        checked={props.usePlanSummaryAsCoverPage}*/}
            {/*        onChange={props.toggleUsePlanSummaryAsCoverPage}*/}
            {/*    />*/}
            {/*</div>*/}
        </Col>
        <Col/>
    </Row>;
}

export default ReportSetupHeader;
