import React from "react";
import {Toast} from "xps-react";

type DCHToastsProps = {
    showMessageFromDCH: boolean,
    messageFromDCH: MessageFromDCH
    onCloseSuccessMessage: () => void,
    onCloseFailureMessage: () => void,
    showFailureMessageFromWDS: boolean,
    failureMessageFromWDS: string
}

export type MessageFromDCH =
    {
        title: string,
        message: string,
        type: string
    }

export function DCHToasts({
                              showMessageFromDCH,
                              messageFromDCH,
                              onCloseSuccessMessage,
                              onCloseFailureMessage,
                              showFailureMessageFromWDS,
                              failureMessageFromWDS
                          }: DCHToastsProps) {

    function onCloseSuccess() {
        onCloseSuccessMessage()
    }

    function onCloseFailure() {
        onCloseFailureMessage()
    }

    return <>
        <div className="dch-toasts">
            {showMessageFromDCH && <Toast
                title={messageFromDCH.title!}
                message={messageFromDCH.message!}
                iconName={"status_validated"}
                onClose={onCloseSuccess}
                showCloseBtn={true}
                theme="light"
                type={messageFromDCH.type!}
                width={360}
            />}
            {showFailureMessageFromWDS && <Toast
                title={"Report could not be sent."}
                message={failureMessageFromWDS}
                iconName={"status_validated"}
                onClose={onCloseFailure}
                showCloseBtn={true}
                theme="light"
                type={"error"}
                width={360}
            />}
        </div>
    </>
}