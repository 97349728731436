import React, {useEffect, useState} from "react";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import NonLifestyleGoalForm from "../components/NonLifestyleGoalForm";
import GoalUtils from "../Summary/GoalUtils";
import NonlifestyleGoalLivePreview from "../NonLifestyleGoals/NonlifestyleGoalLivePreview";
import DataEntryFooter from "../../components/DataEntry/DataEntryFooter";
import {GoalType, NonLifestyleGoalType, PhilanthropicGoalsType} from "../models/GoalType";
import {useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {GoalModelType} from "../models/GoalModelType";
import {
    addPhilanthropicGoal,
    deleteNonLifestyleGoal,
    selectGoalModel,
    updateLifestyleSpendingGoal,
    updateNonLifestyleGoalReducer
} from "../controller/GoalsModelSlice";
import AddNewPhilanthropicGoalBarChartSideBar from "../BarChartSidebar/AddNewPhilanthropicGoalBarChartSideBar";
import {goalsApiClient} from "../GoalsApiClient";
import DeleteGoalModal from "../components/DeleteGoalModal";
import {LifestyleSpendingGoal} from "../models/LifestyleSpendingGoal";
import DiscardModal from "../../components/DiscardModal/DiscardModal";
import {GoalCharacteristics} from "../components/GoalCharacteristics";
import {RequiredFieldsBanner} from "../../components";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import ScrollableContainer from "../../components/ScrollableContainer/ScrollableContainer";

const AddPhilanthropicGoal = ({isEditing = false}: { isEditing?: boolean }) => {
    const history = useHistory();
    const {
        investorGroup,
        discountRateSelection,
        proposal,
        lifestyleSpendingGoal
    } = useAppSelector<GoalModelType>(selectGoalModel)
    const dispatch = useAppDispatch();
    let philanthropicGoalState: NonLifestyleGoalType;
    const [isRequiredFieldsBannerShown, setRequiredFieldsBannerShown] = useState(false);
    let lifestyleGoalState: LifestyleSpendingGoal = lifestyleSpendingGoal;
    const savedGoalInHistory: boolean = (history.location.state && !!history.location.state.savedGoal);
    let viewPage = (history.location.state && history.location.state.sourcePage) ? history.location.state.sourcePage : "Summary";
    let isSourcePageGoalsPrioritization: boolean = (viewPage === "Prioritization")
    if (savedGoalInHistory) {
        philanthropicGoalState = history.location.state.savedGoal;
    } else {
        philanthropicGoalState = GoalUtils.initializeNonLifestyleGoal(proposal.id, GoalType.PHILANTHROPIC, PhilanthropicGoalsType.ANNUAL_GIFT, investorGroup);
    }
    const [philanthropicGoal, replacePhilanthropicGoal] = useState<NonLifestyleGoalType>(philanthropicGoalState)
    const [showDeleteGoalModal, setShowDeleteGoalModal] = useState(false);
    const [lifestyleGoal, replaceLifestyleGoal] = useState<LifestyleSpendingGoal>(lifestyleGoalState)
    const portfolioReserveIsSet = proposal.portfolioReserveTargetLength !== undefined && proposal.portfolioReserveTargetLength !== null;
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const [isSaveButtonDisabled, updateSaveButtonDisabled] = useState(false);
    const [originalGoalState] = useState(philanthropicGoalState);
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    useEffect(() => {
        setRequiredFieldsBannerShown(isRequiredFieldsBannerShown && isAnyRequiredFieldEmpty());
    }, [philanthropicGoal.userInputs.description]);

    function isNonLifestyleGoalFormDataMissing(nonLifestyleGoal: NonLifestyleGoalType) {
        const isDescriptionEmpty = !nonLifestyleGoal.userInputs.description.trim();
        return isDescriptionEmpty;
    }

    const handleModalDiscardButton = () => {
        setShowCancelModal(false);
        dispatch(updateNonLifestyleGoalReducer(originalGoalState));
        history.push(`/Profile/${proposal.id}/ClientProfile/Goals/${viewPage}`, {forceNavigate: true});
    };

    const handleCancelButtonClick = () => {
        if (philanthropicGoal === philanthropicGoalState || isProfileWithProposalsOrArchived) {
            history.push(`/Profile/${proposal.id}/ClientProfile/Goals/${viewPage}`, {forceNavigate: true});
        } else {
            setShowCancelModal(true);
        }
    }

    const isAnyRequiredFieldEmpty = () => {
        return isNonLifestyleGoalFormDataMissing(philanthropicGoal);
    }

    const handleSaveButtonClick = async () => {
        const isRequiredFieldEmpty = isAnyRequiredFieldEmpty();
        if (isRequiredFieldEmpty) {
            setRequiredFieldsBannerShown(true);
            return false;
        }
        updateSaveButtonDisabled(true);
        if (philanthropicGoal.userInputs.riskAsset != null && philanthropicGoal.userInputs.riskAsset > 100) {
            philanthropicGoal.userInputs.riskAsset = 100
        } else if (philanthropicGoal.userInputs.riskAsset != null && philanthropicGoal.userInputs.riskAsset < 0) {
            philanthropicGoal.userInputs.riskAsset = 0
        } else if (philanthropicGoal.userInputs.riskControl != null && philanthropicGoal.userInputs.riskControl > 100) {
            philanthropicGoal.userInputs.riskControl = 100
        } else if (philanthropicGoal.userInputs.riskControl != null && philanthropicGoal.userInputs.riskControl < 0) {
            philanthropicGoal.userInputs.riskControl = 0
        }
        const savedGoal = await goalsApiClient.postNonLifestyleGoal(proposal.id, GoalUtils.toNonLifestyleGoalRequest(philanthropicGoal))
        dispatch(addPhilanthropicGoal({goal: savedGoal}))
        dispatch(updateLifestyleSpendingGoal(lifestyleGoal));
        if (isSourcePageGoalsPrioritization) {
            document.location.replace(`/Profile/${proposal.id}/ClientProfile/Goals/${viewPage}`);
        } else {
            history.push(`/Profile/${proposal.id}/ClientProfile/Goals/${viewPage}`);
        }
    }

    const handleOnDeleteClick = async () => {
        const response = await goalsApiClient.deleteNonLifestyleGoal(philanthropicGoal.id!);
        if (response.status === 204) {
            dispatch(deleteNonLifestyleGoal({goal: philanthropicGoal}))
            history.goBack()
        }
    }

    const renderGoalCharacteristics = () => {
        return <GoalCharacteristics
            nonLifestyleGoal={philanthropicGoal}
            replaceNonLifestyleGoal={replacePhilanthropicGoal}
            isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
        />;
    }

    return (
        <>
            <div className="layout-split-sidebar">
                <DiscardModal
                    itemType={"Goal"}
                    isOpen={showCancelModal}
                    onClickKeepEditing={() => setShowCancelModal(false)}
                    onClickDiscardChanges={handleModalDiscardButton}
                    isEditing={isEditing}/>
                <AddNewPhilanthropicGoalBarChartSideBar nonLifestyleGoalFromForm={philanthropicGoal}
                                                        lifestyleSpendingGoalFromForm={lifestyleGoal}/>

                <ScrollableContainer id={'add-edit-philanthropicgoal-scroll-container'}>
                    <div className='layout-data-entry-form add-new-goal-entry-form'>
                        <DataEntryHeader
                            className="add-philanthropic-goals_title"
                            title={`${savedGoalInHistory ? 'Edit ' : 'Add '}` + philanthropicGoal.userInputs.selectedGoalSubType}
                            primaryButtonText="Save"
                            onPrimaryButtonClick={handleSaveButtonClick}
                            disablePrimaryButton={isSaveButtonDisabled}
                            secondaryButtonText="Cancel"
                            onSecondaryButtonClick={handleCancelButtonClick}
                            isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                        />
                        <RequiredFieldsBanner showAlert={isRequiredFieldsBannerShown}
                                              itemType="philanthropicGoal goal"/>
                        <div className="family-goal-container">
                            <NonLifestyleGoalForm
                                nonLifestyleGoal={philanthropicGoal}
                                replaceNonLifestyleGoal={replacePhilanthropicGoal}
                                typeDropdownItems={GoalUtils.getGoalSubTypeDropdownItems(Object.values(PhilanthropicGoalsType))}
                                discountRateSelection={discountRateSelection}
                                lifestyleGoal={lifestyleGoal}
                                replaceLifestyleGoal={replaceLifestyleGoal}
                                portfolioReserveIsSet={portfolioReserveIsSet}
                                goalCharacteristics={renderGoalCharacteristics()}
                            />
                            <NonlifestyleGoalLivePreview nonLifestyleGoal={philanthropicGoal}/>
                        </div>

                        {
                            savedGoalInHistory &&
                            <DataEntryFooter
                                className="color-text--error"
                                hideDuplicate={true}
                                hideAddNew={true}
                                sticky={false}
                                onDelete={() => setShowDeleteGoalModal(true)}
                                disableDelete={isProfileWithProposalsOrArchived}
                            />
                        }
                        <DeleteGoalModal showDeleteGoalModal={showDeleteGoalModal}
                                         onCancel={() => setShowDeleteGoalModal(false)}
                                         onDelete={handleOnDeleteClick}
                                         goalType={GoalType.PHILANTHROPIC}
                                         description={philanthropicGoal.userInputs.description}/>
                    </div>
                </ScrollableContainer>
            </div>
        </>
    )
}


export default AddPhilanthropicGoal;
