import {RelayEventHook} from "../types/RelayEventHook";
import {MeetingControlRequest} from "../types/MeetingControlRequest";
import {useAppDispatch} from "../../../../store/hooks";
import {setMeetingControlRequest} from "../../meetingSlice";
import {useEffect} from "react";

const meetingControlRequestKey = 'meetingControlRequest';

const relayMeetingControlRequest: RelayEventHook<MeetingControlRequest> = (
    sharedObjects,
    registerMutatorFunction,
    registerValueChangeHandler
) => {
    const {meetingControlDDS} = sharedObjects;
    const dispatch = useAppDispatch();

    // Register mutator function
    useEffect(() => {
        registerMutatorFunction({
            key: 'requestControl',
            callback: (controlRequest: MeetingControlRequest | undefined) => {
                meetingControlDDS.set(meetingControlRequestKey, controlRequest)
            }
        })
    }, []);

    // Register value change handler
    useEffect(() => {
        registerValueChangeHandler({
            sharedMapName: "meetingControlDDS",
            key: meetingControlRequestKey,
            callback: (controlRequest) => {
                dispatch(setMeetingControlRequest(controlRequest));
            }
        });
    }, []);
};

export default relayMeetingControlRequest;