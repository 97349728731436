import React from 'react';
import {Button} from "../Button";

type AccordionItemInfoWithNoDocumentsProps = {
    hRef?: string
    isReportSection?: boolean,
    title?: string,
    onRefreshSharePointDocs?: () => void,
    isSharePointDown?: boolean
}

export const AccordionItemInfoWithNoDocuments = ({
                                                     hRef,
                                                     isReportSection,
                                                     title,
                                                     onRefreshSharePointDocs,
                                                     isSharePointDown
                                                 }: AccordionItemInfoWithNoDocumentsProps) => {
    if (title === "Client Documents" || title === "My Documents") {

        if (hRef === undefined && isSharePointDown === false) {
            return (<AccordionItemInfoWithNoFolderExist isReportSection={isReportSection} onRefreshSharePointDocs={onRefreshSharePointDocs}/>);
        } else if (isSharePointDown === true) {
            return (
                <AccordionItemInfoWithUnableToLoadFiles isReportSection={isReportSection} onRefreshSharePointDocs={onRefreshSharePointDocs}/>
            );
        } else {
            return (
                <AccordionItemInfoWithNoDocumentsUploaded hRef={hRef} isReportSection={isReportSection}/>
            );
        }
    } else {
        return (<>
            </>
        );
    }
}



const AccordionItemInfoWithNoFolderExist = ({isReportSection,onRefreshSharePointDocs}: AccordionItemInfoWithNoDocumentsProps) => {

            return ( <>
                <div className={`${isReportSection ? 'reporting-drawer__no-folder-exists':'no-folder-exists'}`}><span className={`${isReportSection ? 'reporting-drawer__no-folder-exists__title1':'no-folder-exists__title1'}`}>No folder exists for this</span>
                    <div className={`${isReportSection ? 'reporting-drawer__no-folder-exists__title2':'no-folder-exists__title2'}`}>client.</div>
                    <div className={`${isReportSection ? 'reporting-drawer__no-folder-exists__message1':'no-folder-exists__message1'}`}><br></br>There was an error creating the SharePoint</div>
                    <div className={`${isReportSection ? 'reporting-drawer__no-folder-exists__message2':'no-folder-exists__message2'}`}>folder for this client. Please try again.</div>
                    <div className={`${isReportSection ? 'reporting-drawer__no-folder-exists__message3':'no-folder-exists__message3'}`}>If the problem persists, contact</div>
                    <div className={`${isReportSection ? 'reporting-drawer__no-folder-exists__message4':'no-folder-exists__message4'}`}>Technical Support at</div>
                    <div className={`${isReportSection ? 'reporting-drawer__no-folder-exists__message5':'no-folder-exists__message5'}`}>GPIITSUPPORT@ntrs.com</div>
                </div>
                <div className={`${isReportSection ? 'reporting-drawer__no-folder-exists__refresh-button':'no-folder-exists__refresh-button'}`}>
                    <Button
                        className="margin-lg"
                        icon="right"
                        iconName="add"
                        rounded
                        size="medium"
                        onClick={onRefreshSharePointDocs}
                        kind="secondary"
                    >
                        CREATE SHARE POINT FOLDER
                    </Button>
                </div>
            </>);
}

const AccordionItemInfoWithUnableToLoadFiles = ({isReportSection,onRefreshSharePointDocs}: AccordionItemInfoWithNoDocumentsProps) => {

    return (
        <>
            <div className={`${isReportSection ? 'reporting-drawer__unable-to-load-documents':'unable-to-load-documents'}`}><span className={`${isReportSection ? 'reporting-drawer__unable-to-load-documents__title':'unable-to-load-documents__title'}`}>Unable to load files.</span>
                <div className={`${isReportSection ? 'reporting-drawer__unable-to-load-documents__message1':'unable-to-load-documents__message1'}`}><br></br>There was an error loading the </div>
                <div className={`${isReportSection ? 'reporting-drawer__unable-to-load-documents__message2':'unable-to-load-documents__message2'}`}>SharePoint folders or documents. Please try</div>
                <div className={`${isReportSection ? 'reporting-drawer__unable-to-load-documents__message3':'unable-to-load-documents__message3'}`}>again. If the problem persists, contact</div>
                <div className={`${isReportSection ? 'reporting-drawer__unable-to-load-documents__message4':'unable-to-load-documents__message4'}`}>Technical Support at</div>
                <div className={`${isReportSection ? 'reporting-drawer__unable-to-load-documents__message5':'unable-to-load-documents__message5'}`}>GPIITSUPPORT@ntrs.com</div>
            </div>
            <div className={`${isReportSection ? 'reporting-drawer__unable-to-load-documents__refresh-button':'unable-to-load-documents__refresh-button'}`}>
                <Button
                    className="margin-lg"
                    icon="right"
                    iconName="refresh"
                    rounded
                    size="medium"
                    kind="secondary"
                    onClick={onRefreshSharePointDocs}
                >
                    RETRY
                </Button>
            </div>
        </>
    );
}


export const AccordionItemInfoWithNoDocumentsUploaded = ({hRef,isReportSection}: AccordionItemInfoWithNoDocumentsProps) => {

    return (
        <>
            <div className={`${isReportSection ? 'reporting-drawer__no-docs-content':'nav-drawer-no-documents'}`}><span className={`${isReportSection ? 'reporting-drawer__no-docs-content__title':'nav-drawer-no-documents__title'}`}>No documents uploaded.</span>
                <div className={`${isReportSection ? 'reporting-drawer__no-docs-content__message1':'nav-drawer-no-documents__message1'}`}><br></br>Documents uploaded in SharePoint will</div>
                <div className={`${isReportSection ? 'reporting-drawer__no-docs-content__message2':'nav-drawer-no-documents__message2'}`}>be available here. Refresh to view recently</div>
                <div className={`${isReportSection ? 'reporting-drawer__no-docs-content__message3':'nav-drawer-no-documents__message3'}`}>uploaded documents.</div>
            </div>
            <div className={`${isReportSection ? 'reporting-drawer__no-docs-content__share-point-link':'nav-drawer-no-documents__share-point-link'}`}>
                <a
                    className=""
                    href={hRef}
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    CLICK HERE TO ACCESS YOUR SHAREPOINT
                    <i className="icon dds-icons marginleft-sm">
                        launch
                    </i>
                </a>
            </div>

        </>
    );
}