import React from "react";
import {useAppSelector} from "../../../store/hooks";
import {selectFamilyMemberForm} from "../FamilyTreeSlice";
import ModalWrapper from "../../../components/Modal/ModalWrapper/ModalWrapper";
import {FamilyMode} from "./FamilyMode";

type DeleteFamilyMemberModalProps = {
    showDeleteMemberModal: boolean,
    handleModelDeleteButton: () => void,
    handleModalCancelButton: () => void,
    familyMode: FamilyMode
}

const determineDeleteMessage = (familyMode: FamilyMode): string => {
    switch (familyMode) {
        case FamilyMode.IMMEDIATE:
            return 'Delete Family Member';
        case FamilyMode.OTHER:
            return 'Delete Other Member';
        case FamilyMode.EXTENDED:
            return 'Delete Extended Family Member'
    }
}

const DeleteMemberModal: React.FC<DeleteFamilyMemberModalProps> = (
    {
        showDeleteMemberModal,
        handleModelDeleteButton,
        handleModalCancelButton,
        familyMode
    }) => {

    const {firstName, lastName} = useAppSelector(selectFamilyMemberForm)!;
    const deleteMessage = determineDeleteMessage(familyMode);

    return (
        <ModalWrapper
            id="discard-changes-modal"
            isOpen={showDeleteMemberModal}
            headerText={`Delete ${firstName} ${lastName}?`}
            buttons={[
                {
                    text: "Cancel",
                    onClick: handleModalCancelButton
                },
                {
                    text: deleteMessage,
                    onClick: handleModelDeleteButton,
                    destructive: true,
                    primary: true,
                },
            ]}
        >
            <div className="font-md">
                Any data for this member—including all goals—will also be deleted.
            </div>
        </ModalWrapper>
    )
};

export default DeleteMemberModal;