import classnames from 'classnames';

import '@nt/dds-style/core/switch.css';
import {useEffect, useState} from "react";

export type SwitchPropTypes = {
    /** id to be used by label component */
    id: string,
    /** Pass the id of an external label component so that the switch is labelled by your label id correctly */
    ariaLabelledBy?: string,
    /** on state of the switch (default is false) */
    isOn?: boolean,
    /** callback function when switch button is clicked */
    onChange?: (newValue: boolean) => void,
    /** position for state label (ON/OFF). Default is right */
    stateTextPosition: string,
    /** text to display for on state. Default is "ON" */
    onText: string,
    /** text to display for off state. Default is "OFF" */
    offText: string,
    /** on the state of the page*/
    isDisabled?: boolean,
    flipSwitch?: boolean,
    toggleSwitch?: () => Promise<void>
    FBNIToggle?:() => void
};

export function Switch({
                           id,
                           ariaLabelledBy,
                           isOn,
                           onChange,
                           stateTextPosition,
                           onText,
                           offText,
                           isDisabled = false,
                           flipSwitch = true,
                           toggleSwitch,
                           FBNIToggle
                       }: SwitchPropTypes) {
    const [isOnState, setIsOn] = useState(isOn);
    const isOnStartingClassName = isOnState ? 'switch--start-on' : 'switch--start-off';
    const switchContainerIsOnClassName = isOnState ? 'switch-container--on' : 'switch-container--off';
    const switchIsOnClassName = isOnState ? 'switch--on' : 'switch--off';

    useEffect(() => {
        setIsOn(isOn);
    }, [isOn]);

    const renderState = () => {
        const marginClass = stateTextPosition === 'left' ? 'marginright-md' : 'marginleft-md';
        return (
            <span role='presentation' className={`${marginClass} fontweight-500 font-sm letterspacing-md`}>
                {isOnState ? onText : offText}
            </span>
        );
    };

    return (
        <div className='display-flex align-items-center'>
            {stateTextPosition === 'left' && renderState()}
            <button
                id={id}
                type='button'
                role='switch'
                aria-checked={isOnState}
                aria-labelledby={ariaLabelledBy}
                className={classnames('switch-container', switchContainerIsOnClassName)}
                onClick={toggleSwitch || FBNIToggle}
                disabled={isDisabled}
            >
                <div className={classnames('switch', isOnStartingClassName, switchIsOnClassName)}/>
            </button>
            {stateTextPosition === 'right' && renderState()}
        </div>
    );
};
