export const stringToBase64 = (stringToEncode: string) => {
    //per MDN docs
    let byteArray = new window.TextEncoder().encode(stringToEncode);
    const binString = Array.from(byteArray, (x) => String.fromCodePoint(x)).join("");
    return window.btoa(binString);
}

export const base64ToString = (base64: string) => {
    //per MDN docs
    const binString = window.atob(base64);
    return new window.TextDecoder().decode(Uint8Array.from(binString, (m) => {
        const codePoint = m.codePointAt(0);
        if (!codePoint) throw new Error('unknown element in binaryString')
        return codePoint;
    }));
}