import {
    AlertMessageForRevisedAllocation,
    DataDisplayView,
    GoalsSummaryForOneYear,
    ProposedAllocationEntry,
    ReviseAssetAllocationResponse
} from "../ReviseAssetAllocation";
import {AlertBanner, Button} from "../../../components";
import {
    calculateTotalsOfAssetClass,
    formatAllocationAmount,
    formatAllocationPercentage,
    isDollarView
} from "./reviseAssetAllocationUtils";
import React, {useMemo} from "react";
import classNames from "classnames";
import {formatNumberRoundedToTwoDecimals, formatNumberRoundedToWholeNumber} from "../../../utils/format";
import {ReviseAssetClassLevelTwo} from "./ReviseAssetClassLevelTwo";
import {ReviseSubClassAssets} from "./ReviseSubClassAssets";
import {ReviseAssetSubclassesSummary} from "./ReviseAssetSubclassesSummary";

export type ReviseAssetClassTableProps = {
    assetClassificationName: string;
    assetClassHierarchy: { [key: string]: ReviseAssetSubclassesSummary[] };
    accentColor: string;
    totalCurrentAllocation: number;
    totalRecommendedAllocation: number;
    totalProposedAllocation: number;
    totalProposedAllocationOfTotalPortfolio: number;
    totalDifferenceAmount: number;
    updateProposedAllocationEntry: (value: ProposedAllocationEntry) => void;
    totalRemainingAllocation: AlertMessageForRevisedAllocation | undefined;
    displayView: DataDisplayView;
    showIPCRange: boolean,
    areInputsDisabled: boolean,
    isCompletelyAllocated: boolean,
    toggleExcludeSubclass: (subclassName: string, excludeSubclass: boolean) => Promise<void>;
    allocateRemainingAssetsProRata: () => Promise<ReviseAssetAllocationResponse>;
    showAssetsNotAllocatedWarning: boolean;
    setShowAssetsNotAllocatedWarning: (showAssetsNotAllocatedWarning: boolean) => void;
    totalProposedAmount: number;
    showWarning: boolean;
    goalsSummaryForOneYear: GoalsSummaryForOneYear;
    isProposedAllocationValuesLoadedFirstTime: boolean;
    averageTargetMaturity: number | null;
    isProfileWithProposalsOrArchived?: boolean
};

const ReviseAssetClassTable = (props: ReviseAssetClassTableProps) => {
    const {
        assetClassificationName,
        assetClassHierarchy,
        accentColor,
        totalCurrentAllocation,
        totalRecommendedAllocation,
        totalProposedAllocation,
        totalProposedAllocationOfTotalPortfolio,
        totalDifferenceAmount,
        updateProposedAllocationEntry,
        totalRemainingAllocation,
        displayView,
        showIPCRange = false,
        areInputsDisabled,
        isCompletelyAllocated,
        toggleExcludeSubclass,
        allocateRemainingAssetsProRata,
        showAssetsNotAllocatedWarning,
        setShowAssetsNotAllocatedWarning,
        totalProposedAmount,
        showWarning,
        goalsSummaryForOneYear,
        isProposedAllocationValuesLoadedFirstTime,
        averageTargetMaturity,
        isProfileWithProposalsOrArchived = false
    } = props;
    const dollarView = isDollarView(displayView);

    const assetLevel2Classes = Object.keys(assetClassHierarchy);

    const totalsForLevel2Class = useMemo(() => {
        return calculateTotalsOfAssetClass(assetClassHierarchy);
    }, [assetClassHierarchy]);

    const gridViewClasses = {
        'revise-allocation-dollar-view': dollarView && showIPCRange,
        'revise-allocation-dollar-view-without-ipc': dollarView && !showIPCRange,
        'revise-allocation-percentage-view': !dollarView && showIPCRange,
        'revise-allocation-percentage-view-without-ipc': !dollarView && !showIPCRange,
    };

    return (
        totalsForLevel2Class &&
        <div className="revise-asset-allocation__table" role="table"
             aria-label={`${assetClassificationName} ${displayView}`}>
            <ReviseAssetClassTableHeader
                assetClassificationName={assetClassificationName}
                isAllocationError={!!totalRemainingAllocation}
                dollarView={dollarView}
                showIPCRange={showIPCRange}
                gridViewClasses={gridViewClasses}
                allocateRemainingAssetsProRata={allocateRemainingAssetsProRata}
                disableProRataButton={isCompletelyAllocated}
                showAssetsNotAllocatedWarning={showAssetsNotAllocatedWarning}
                setShowAssetsNotAllocatedWarning={setShowAssetsNotAllocatedWarning}
                showWarning={showWarning}
            />
            <div
                className="revise-asset-allocation__table__content"
                style={{borderLeft: `12px solid ${accentColor}`}}
            >
                {assetLevel2Classes.map((assetClassName) => {
                    return <div key={assetClassName}>
                        <ReviseAssetClassLevelTwo args={gridViewClasses} assetClassName={assetClassName}
                                                  dollarView={dollarView} totalsForLevel2Class={totalsForLevel2Class}
                                                  showIPCRange={showIPCRange}
                        />
                        <ReviseSubClassAssets
                            reviseAssetSubClassAllocation={assetClassHierarchy[assetClassName]}
                            dollarView={dollarView}
                            totalProposedAmount={totalProposedAmount}
                            showIPCRange={showIPCRange}
                            gridViewClasses={gridViewClasses}
                            areInputsDisabled={areInputsDisabled}
                            averageTargetMaturity={averageTargetMaturity}
                            goalsSummaryForOneYear={goalsSummaryForOneYear}
                            updateProposedAllocationEntry={updateProposedAllocationEntry}
                            displayView={displayView}
                            totalProposedAllocation={totalProposedAllocation}
                            toggleExcludeSubclass={toggleExcludeSubclass}
                            isProposedAllocationValuesLoadedFirstTime={isProposedAllocationValuesLoadedFirstTime}
                            isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                        />
                    </div>
                })}
            </div>
            <ReviseAssetClassTableFooter
                assetClassificationName={assetClassificationName}
                totalRecommendedAllocation={totalRecommendedAllocation}
                totalCurrentAllocation={totalCurrentAllocation}
                totalProposedAllocation={totalProposedAllocation}
                totalProposedAllocationOfTotalPortfolio={totalProposedAllocationOfTotalPortfolio}
                totalDifferenceAmount={totalDifferenceAmount}
                totalRemainingAllocation={totalRemainingAllocation}
                dollarView={dollarView}
                showIPCRange={showIPCRange}
                gridViewClasses={gridViewClasses}
                showWarning={showWarning}
            />
        </div>

    );
};

const ReviseAssetClassTableHeader: React.FC<{
    assetClassificationName: string;
    isAllocationError: boolean;
    dollarView: boolean;
    showIPCRange: boolean;
    gridViewClasses: any;
    allocateRemainingAssetsProRata: () => Promise<ReviseAssetAllocationResponse>;
    disableProRataButton: boolean;
    showAssetsNotAllocatedWarning: boolean;
    setShowAssetsNotAllocatedWarning: (showAssetsNotAllocatedWarning: boolean) => void;
    showWarning: boolean
}> = ({
          assetClassificationName,
          isAllocationError,
          dollarView,
          showIPCRange,
          gridViewClasses,
          allocateRemainingAssetsProRata,
          disableProRataButton,
          showAssetsNotAllocatedWarning,
          setShowAssetsNotAllocatedWarning,
          showWarning
      }) => {
    return (
        <>
            <AlertBanner
                className="marginbottom-xl"
                showAlert={showAssetsNotAllocatedWarning}
                showCloseBtn={true}
                icon="info"
                type="warning"
                onClose={() => setShowAssetsNotAllocatedWarning(false)}
            >
                {`${assetClassificationName} not allocated pro-rata due to manually editing all subclasses. Manually edit values to total 100% or reset values.`}
            </AlertBanner>
            <div className="revise-asset-allocation__table__header__buttons marginbottom-lg textalign-right">
                <Button
                    icon="none"
                    kind="borderless"
                    size="medium"
                    onClick={allocateRemainingAssetsProRata}
                    disabled={disableProRataButton}
                >
                    {`Allocate Remaining ${assetClassificationName} Pro-Rata`}
                </Button>
            </div>
            <div
                className={classNames(gridViewClasses, 'revise-allocation-subclass-grid revise-asset-allocation__table__header revise-allocation-subclass-row')}>
                <div className="grid-left-contents">
                <span role="columnheader" className="condensed-subtitle grid-row-span-2">
                    {assetClassificationName}
                </span>
                    <span
                        role="columnheader"
                        className="condensed-subtitle textalign-right grid-row-span-2"
                    >
                    {`Current ${dollarView ? "$" : "%"}`}
                </span>
                    <span
                        role="columnheader"
                        className="condensed-subtitle textalign-right grid-row-span-2"
                    >
                    {`Recommended Target ${dollarView ? "$" : "%"}`}
                </span>
                    {showIPCRange && <span
                        role="columnheader"
                        className="condensed-subtitle textalign-center ipc-range"
                    >
                    IPC Range
                </span>}
                    <div
                        className={`${isAllocationError && showWarning ? "grid-row-span-2-error-bg" :
                            "condensed-subtitle grid-row-span-2"} textalign-right paddingright-xl`}>
                    <span role="columnheader">
                        {`Proposed ${dollarView ? "$" : "%"}`}
                    </span>
                    </div>
                    {showIPCRange &&
                        <>
                        <span
                            role="columnheader"
                            className="condensed-subtitle textalign-right grid-col-span-4-5 margintop-sm marginbottom-sm"
                        >
                            {`Minimum ${dollarView ? "$" : "%"}`}
                        </span>
                            <span
                                role="columnheader"
                                className="condensed-subtitle textalign-right grid-col-span-5-6 margintop-sm marginbottom-sm"
                            >
                            {`Maximum ${dollarView ? "$" : "%"}`}
                        </span>
                        </>
                    }
                </div>
                <span className="grid-divider grid-row-span-2"></span>
                <div className="grid-right-contents">
                <span
                    role="columnheader"
                    className="condensed-subtitle textalign-right grid-row-span-2"
                >
                    {`${dollarView ? "Difference $" : "% of Total \n" + "Portfolio"}`}
                </span>
                    <span className="empty-grid-column grid-row-span-2"/>
                </div>
            </div>
        </>

    );
};

const ReviseAssetClassTableFooter: React.FC<{
    assetClassificationName: string;
    totalCurrentAllocation: number;
    totalRecommendedAllocation: number;
    totalProposedAllocation: number;
    totalProposedAllocationOfTotalPortfolio: number;
    totalDifferenceAmount: number;
    totalRemainingAllocation: AlertMessageForRevisedAllocation | undefined;
    dollarView: boolean;
    showIPCRange: boolean;
    gridViewClasses: any;
    showWarning: boolean
}> = ({
          assetClassificationName,
          totalRecommendedAllocation,
          totalCurrentAllocation,
          totalProposedAllocation,
          totalProposedAllocationOfTotalPortfolio,
          totalDifferenceAmount,
          totalRemainingAllocation,
          dollarView,
          showIPCRange,
          gridViewClasses,
          showWarning
      }) => (
    <div
        className={classNames(gridViewClasses, 'revise-asset-allocation__table__footer revise-allocation-subclass-grid')}>
        <>
            <div className="grid-left-contents">
                <span role="gridcell">Total {assetClassificationName}</span>
                <span role="gridcell" className="textalign-right"
                      aria-label={`Total ${assetClassificationName} Current ${dollarView ? "$" : "%"}`}>
                {dollarView ? formatAllocationAmount(totalCurrentAllocation) : formatAllocationPercentage(totalCurrentAllocation)}
            </span>
                <span role="gridcell" className="textalign-right"
                      aria-label={`Total ${assetClassificationName} Recommended Target ${dollarView ? "$" : "%"}`}>
                {dollarView ? formatAllocationAmount(totalRecommendedAllocation) : formatAllocationPercentage(totalRecommendedAllocation)}
            </span>
                {showIPCRange &&
                    <>
                    </>
                }

                {showIPCRange && <span role="gridcell"
                                       className={`textalign-right paddingtop-xl grid-span-2`}>
                {``}
            </span>}

                <span role="gridcell"
                      className={`${totalRemainingAllocation && showWarning ? "error-bg" : ""} textalign-right paddingright-xl`}
                      aria-label={`Total ${assetClassificationName} Proposed ${dollarView ? "$" : "%"}`}>

                {!totalRemainingAllocation &&
                    <div aria-label={`Total ${assetClassificationName} Proposed`}>
                        {dollarView ? formatNumberRoundedToWholeNumber(totalProposedAllocation) : formatAllocationPercentage(totalProposedAllocation)}
                    </div>
                }
                    {totalRemainingAllocation &&
                        <>
                            <div className={!showWarning ? `color-text--error` : ''}
                                 aria-label={`Total ${assetClassificationName} Proposed`}>
                                {dollarView ? formatNumberRoundedToTwoDecimals(totalProposedAllocation) : formatAllocationPercentage(totalProposedAllocation)}
                            </div>
                        </>
                    }
            </span>
            </div>

            <span className="grid-divider"></span>
            <div className="grid-right-contents">
            <span role="gridcell" className="textalign-right"
                  aria-label={`Total ${assetClassificationName} ${dollarView ? "Difference $" : "% of Total Portfolio"}`}>
                {dollarView ? formatAllocationAmount(totalDifferenceAmount) : formatAllocationPercentage(totalProposedAllocationOfTotalPortfolio)}
            </span>
                <span className="empty-grid-column"/>
            </div>
        </>
        <>
            <div className="grid-left-contents grid-footer-alloc-error">
                <span role="gridcell"></span>
                <span role="gridcell" className="textalign-right"></span>
                <span role="gridcell" className="textalign-right">
                    {!showIPCRange && totalRemainingAllocation &&
                        <div>
                            {totalRemainingAllocation.differenceInTotalProposedAllocationAmount > 0 ?
                                (<div className={`paddingbottom-sm fontstyle-italic`}>Under Allocation:</div>) :
                                (<div className={`paddingbottom-sm fontstyle-italic`}>Over Allocation:</div>)
                            }
                        </div>}
                </span>
                {showIPCRange && <span role="gridcell"
                                       className={`textalign-right grid-span-2`}>
                            {``}
                    {totalRemainingAllocation &&
                        <div>
                            {totalRemainingAllocation.differenceInTotalProposedAllocationAmount > 0 ?
                                (<div className={`paddingbottom-sm fontstyle-italic`}>Under Allocation:</div>) :
                                (<div className={`paddingbottom-sm fontstyle-italic`}>Over Allocation:</div>)
                            }
                        </div>
                    }
            </span>}

                <span role="gridcell"
                      className={`${totalRemainingAllocation && showWarning ? "error-bg" : ""} textalign-right paddingright-xl`}>

                    {totalRemainingAllocation &&
                        <>
                            <div className={!showWarning ? `color-text--error` : ''}
                                 aria-label={`${assetClassificationName} Error Difference`}>
                                {dollarView ?
                                    formatNumberRoundedToTwoDecimals(totalRemainingAllocation.differenceInTotalProposedAllocationAmount) :
                                    formatAllocationPercentage(totalRemainingAllocation.differenceInTotalProposedAllocationPercent)}
                            </div>
                        </>
                    }
            </span>
            </div>
        </>
    </div>
);

export default ReviseAssetClassTable;
