import React from "react";
import {AssetsSummary} from "../../models/Assets";
import {InvestorGroupType, MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import {formatCurrency} from "../../../utils/format";
import {calculateInEstateTotalValue} from "../common/AssetSummaryCalculator";
import {DropdownItem, PageActionMenu} from "../../../components";
import AssetList from "./AssetList";
import AddAssetButtonDropdown from "../common/AddAssetButtonDropdown";
import {DeleteModalProps} from "../../common/AssetsView";
import {hasInEstateAssets} from "../common/utils";
import {GeneralInflow} from "../../models/GeneralInflow";
import InfoBannerForAccountAndPCR from "../../InfoBannerForAccountAndPCR";

export interface InEstateAssetsSummaryProps {
    assets: AssetsSummary;
    investorGroup: InvestorGroupType;
    profileId: string;
    onDeleteAssetClick: (deleteModalProps: DeleteModalProps) => void;
    onRemoveInvestmentProgram: () => void;
    onRefreshInvestmentProgramHoldings: () => void;
    onSaveReviewTrustDisclosure: (generalInflow: GeneralInflow) => void;
    memberGroup: MemberGroup,
    onArrangeAssets: () => void,
    shouldDisableArrangeAssets: boolean
}

const InEstateAssetsSummary = (props: InEstateAssetsSummaryProps) => {
    return <>
        <div className="section-header">
            <h3>
                <div>You have <b>{formatCurrency(calculateInEstateTotalValue(props.assets))}</b> in assets:</div>
                {!hasInEstateAssets(props.assets) &&
                    <div className="condensed-subtitle">Begin by entering assets or entities</div>}
            </h3>
            <div>
                <PageActionMenu className="marginright-md">
                    <DropdownItem
                        disabled={props.shouldDisableArrangeAssets}
                        itemText={"Arrange Assets"}
                        value={"Arrange Assets"}
                        onClick={props.onArrangeAssets}
                    />
                </PageActionMenu>
                <AddAssetButtonDropdown
                    assets={props.assets}
                    investorGroup={props.investorGroup}
                    memberGroup={props.memberGroup}
                />
            </div>
        </div>
        <InfoBannerForAccountAndPCR assets={props.assets}/>
        <AssetList
            assetsData={props.assets}
            investorGroup={props.investorGroup}
            profileId={props.profileId}
            onDeleteAssetClick={props.onDeleteAssetClick}
            onRemoveInvestmentProgram={props.onRemoveInvestmentProgram}
            onRefreshInvestmentProgramHoldings={props.onRefreshInvestmentProgramHoldings}
            onSaveReviewTrustDisclosure={props.onSaveReviewTrustDisclosure}
            memberGroup={props.memberGroup}
        />
    </>;
}

export default InEstateAssetsSummary;
