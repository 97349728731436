import React from "react";
import {Icon} from "../../components";
import {formatCurrency} from "../../utils/format";
import classNames from "classnames";
import {Col, Row} from "react-grid-system";
import {formatAllocationPercentage} from "./assetAllocationUtils";

interface AssetAllocationHeaderAccordionProps {
    expanded: boolean;
    title: string;
    total: number;
    totalProposed?: number;
    totalPercentage: number;
    totalProposedPercentage?: number;
    showChevron?: boolean;
}

export const AssetHoldingHeaderBorder = <div
    style={{
        height: '65px',
    }}
/>;

const AssetHoldingHeader: React.FC<AssetAllocationHeaderAccordionProps> = ({
                                                                               expanded,
                                                                               title,
                                                                               total,
                                                                               totalProposed,
                                                                               totalPercentage,
                                                                               totalProposedPercentage,
                                                                               showChevron = true
                                                                           }) => {
    let iconName: string = '';
    if (showChevron) {
        iconName = expanded ? 'chevron_down' : 'chevron_right';
    }

    const totalDifferenceAmount = totalProposed! - total;
    const totalDifferencePercentage = totalProposedPercentage! - totalPercentage!;

    return (
        <>
            <Row className="row"
                 data-testid="asset-reliance-accordion-header">
                <Col className="asset-holding-title-container" width={318} style={{minWidth: '318px'}}>
                    <Icon className="asset-holding-accordion-icon" name={iconName} size="large"/>
                    <span className={"title"} data-testid="accordion-title">{title}</span>
                </Col>
                <>
                    <Col className={"amount-header-container flex"} width={300} style={{minWidth: '300px'}}>
                        <span className="amount-header flex-grow-1"
                              data-testid="asset-allocation-total-current-assets">{formatCurrency(total)}</span>
                        {<span className="amount-header paddingright-lg" style={{width: '135px'}}
                               data-testid="asset-allocation-total-current-assets-percentage">{
                            formatAllocationPercentage(totalPercentage)
                        }</span>}
                    </Col>
                    {AssetHoldingHeaderBorder}
                </>
                <Col className={"amount-header-container flex"} width={300} style={{minWidth: '300px'}}>
                    <span className="amount-header flex-grow-1"
                          data-testid="asset-allocation-total-proposed-assets">{
                        typeof totalProposed === 'number'
                            ? formatCurrency(totalProposed)
                            : '--'
                    }</span>
                    <span className="amount-header paddingright-lg"
                          style={{width: '135px'}}
                          data-testid="asset-allocation-total-proposed-assets-percentage">{
                        typeof totalProposedPercentage === 'number'
                            ? formatAllocationPercentage(totalProposedPercentage)
                            : '--'
                    }</span>
                </Col>
                <Col className={"amount-header-container flex textalign-right"} width={300} style={{minWidth: '300px'}}>
                    <AllocationDifference differenceAmount={totalDifferenceAmount}
                                          classes={["amount-header flex-grow-1"]}
                                          formatter={formatCurrency}
                    />
                    <span style={{width: '135px'}} className='paddingright-lg'>
                    <AllocationDifference
                        differenceAmount={totalDifferencePercentage}
                        classes={["amount-header"]}
                        formatter={formatAllocationPercentage}
                    />
                </span>
                </Col>
            </Row>
        </>
    )
};

interface AllocationDifferenceProps {
    differenceAmount?: number
    formatter?: (amount: number) => string
    classes: string[]
}

const AllocationDifference: React.FC<AllocationDifferenceProps> = ({
                                                                       differenceAmount,
                                                                       formatter = value => `${value}`,
                                                                       classes = []
                                                                   }) => {
    const isNegative = typeof differenceAmount === 'number' && differenceAmount < 0;
    return (
        <span className={classNames(...classes, {"negative-difference": isNegative})}>
            {
                typeof differenceAmount === 'number'
                    ? formatter(differenceAmount)
                    : '--'
            }
        </span>
    );
};

export default AssetHoldingHeader;