import React from "react";
import {Dropdown, DropdownItem, Label} from "../../../components";

type MemberOwnershipDropdownProps = {
    owner: string;
    owners: { label: string, value: string }[];
    onChange: (value: string) => void;
    disabled?: boolean
}
const OwnerDropdown = ({
                           owner,
                           owners,
                           onChange,
                           disabled
                       }: MemberOwnershipDropdownProps) => {

    return (
        <div className="layout-data-entry-form__field">
            <Label labelId="owner" label="Ownership"/>
            <div style={{whiteSpace: "pre", width: "140px"}}>
                <Dropdown
                    aria-labelledby="owner"
                    size="medium"
                    id="ownershipDropdown"
                    value={owner}
                    disabled={disabled}
                    onChange={({value}) => onChange(value)}
                >
                    {
                        owners.map(({label, value}) => {
                            return (
                                <DropdownItem
                                    itemText={label}
                                    value={value}
                                    key={value}
                                />
                            )
                        })
                    }
                </Dropdown>
            </div>
        </div>
    )
}

export default OwnerDropdown;