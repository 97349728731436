import React from "react";
import {Button} from "../Button";

const ConnectionFailed: React.FC<{ onRefresh: () => void }> = ({onRefresh}) => {
    return (
        <div className="connection-failed display-flex justify-content-center align-items-center">
            <div className="connection-failed__container">
                <span className="connection-failed__header">Problem connecting</span>
                <span className="connection-failed__message">
                    There is a connection problem due to internal network error.
                </span>
                <Button
                    className="margin-lg"
                    icon="left"
                    iconName="refresh"
                    rounded
                    size="medium"
                    onClick={onRefresh}
                >
                    Refresh
                </Button>
                <span className="connection-failed__footer">
                    If the issue persists, contact the GPI IT SUPPORT at GPIITSUPPORT@ntrs.com
                </span>
            </div>
        </div>
    )
};

export default ConnectionFailed;