import React from "react";
import {Col, Container, Row} from "react-grid-system";
import {
    ProposedAllocationResponse
} from "../../../ClientManagement/AssetAllocation/ProposedAllocationResponse";
import {
    CurrentAllocationResponse,
} from "../../../ClientManagement/AssetAllocation/CurrentAllocationResponse";

type AssetAllocationCardReportProps = {
    currentAssetAllocation: CurrentAllocationResponse,
    proposedAssetAllocation: ProposedAllocationResponse
}
export const AssetAllocationCardReport = ({
                                              currentAssetAllocation,
                                              proposedAssetAllocation
                                          }: AssetAllocationCardReportProps) => {

    const hasCurrentAllocation = () => {
        return currentAssetAllocation.totalRiskAssetsAmount > 0 || currentAssetAllocation.totalRiskControlAssetsAmount > 0
    };

    return (
        <Container>
            <div data-testid='asset-allocation-card' className='goal-prioritization-card asset-allocation-card'>
                <Row>
                    <Col xs={8} className='goal-prioritization-card-header'>
                        Asset Allocation
                    </Col>
                    <Col xs={2} className='current-and-proposed'>
                        CURRENT
                    </Col>
                    <Col xs={2} className='current-and-proposed'>
                        PROPOSED
                    </Col>
                </Row>
                <Row>
                    <Col xs={8} className='goal-prioritization-card-first-column'>
                        Risk Assets
                    </Col>
                    <Col xs={2}>
                        {Math.round(currentAssetAllocation.totalRiskAssetsPercent)}%
                    </Col>
                    <Col xs={2}>
                        {hasCurrentAllocation() ? Math.round(proposedAssetAllocation!.totalRiskAssetsPercent) : 0}%

                    </Col>
                </Row>
                <Row>
                    <Col xs={8} className='goal-prioritization-card-first-column'>
                        Risk Control Assets
                    </Col>
                    <Col xs={2}>
                        {Math.round(currentAssetAllocation.totalRiskControlAssetsPercent)}%
                    </Col>
                    <Col xs={2}>
                        {hasCurrentAllocation() ? Math.round(proposedAssetAllocation!.totalRiskControlAssetsPercent) : 0}%
                    </Col>
                </Row>
            </div>
        </Container>
    );
}