import {ReleaseTogglesType} from "./ReleaseToggles";
import {get} from "../core/api";
import {RELEASE_TOGGLE_ALL_OFF} from "./ReleaseTogglesTestSupport";

export const getReleaseToggles = (): Promise<ReleaseTogglesType> => {
    return get(`/featureflags/releaseToggles`).then((res: Response) => {
        if (res.status === 200 || res.status === 201) {
            return res.json();
        } else {
            return RELEASE_TOGGLE_ALL_OFF;
        }
    });
}

export const releaseToggleApiClient = {
    getReleaseToggles: getReleaseToggles,
}