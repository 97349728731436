import {EffectiveTaxRateReferenceData} from "../models/referenceData/effectiveTaxRateReferenceData";
import {get} from "./api";
import {ReferenceDataType} from "../models/referenceData/ReferenceDataType";


const extractBody = (res: Response) => res.json();

const getEffectiveTaxRate: () => Promise<EffectiveTaxRateReferenceData> = () => {
    return get(`/reference/taxRates`).then(extractBody);
};

const getStatesOfResidency: () => Promise<object> = () => {
    return get(`/reference/statesOfResidency`).then(extractBody);
};

const getReferenceData: () => Promise<ReferenceDataType> = () => {
        return get(`/reference`).then(extractBody);
};

export const referenceDataClient = {
    getEffectiveTaxRate: getEffectiveTaxRate,
    getStatesOfResidency: getStatesOfResidency,
    getReferenceData: getReferenceData,
};