import {
    CameraButton,
    ControlBar,
    darkTheme,
    FluentThemeProvider,
    MicrophoneButton,
    usePropsFor,
    VideoGallery,
    VideoGalleryProps,
} from "@azure/communication-react";
import React, {useEffect, useState} from "react";
import {Stack} from "@fluentui/react";
import {CallContext, useCommunicationsContext} from "../CommunicationsContext";
import {
    controlBarStyles,
    customCameraOffIcon,
    customCameraOnIcon,
    customMicOffIcon,
    customMicOnIcon
} from "../AudioVisual/CustomIcons";
import {msalUtils} from "../../../MsalUtils";
import {useMsal} from "@azure/msal-react";
import globalWindowUtils from "../../../utils/globalWindowUtils";
import {MeetingParticipant} from "../Meeting";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {ACSTokenProviderClient} from "../ACSTokenProviderClient";

interface ExpandedVideoGalleryProps {
    meetingParticipants: MeetingParticipant[];
}

const ExpandedVideoGallery: React.FC<ExpandedVideoGalleryProps> = ({meetingParticipants}) => {
    const videoGalleryProps = usePropsFor(VideoGallery);
    const microphoneProps = usePropsFor(MicrophoneButton);
    const cameraProps = usePropsFor(CameraButton);

    const msal = useMsal();

    const callRemoteParticipants = videoGalleryProps.remoteParticipants;

    const matchedParticipants = callRemoteParticipants.map((remoteParticipant) => {
        const displayName = meetingParticipants?.find(
            meetingParticipant => meetingParticipant.communicationsId === remoteParticipant.userId
        )?.userName;

        return {...remoteParticipant, displayName: displayName || remoteParticipant.displayName};
    });

    const videoGalleryPropOverrides: VideoGalleryProps = {
        ...videoGalleryProps,
        showMuteIndicator: true,
        localParticipant: {
            ...videoGalleryProps.localParticipant,
            displayName: msalUtils.getAccountName(msal),
        },
        remoteParticipants: matchedParticipants,
        remoteVideoViewOptions: {
            scalingMode: 'Fit',
        },
        localVideoViewOptions: {
            scalingMode: 'Fit',
        },
    };

    return <FluentThemeProvider fluentTheme={{...darkTheme, semanticColors: {bodyBackground: '#000000'}}}>
        <Stack style={{height: '100vh'}}>
            <div className='expanded-participants-control-bar'><ControlBar styles={controlBarStyles}
                                                                           layout='horizontal'>
                {cameraProps && <CameraButton  {...{
                    ...cameraProps,
                    showLabel: true,
                    strings: {
                        onLabel: 'Camera',
                        offLabel: 'Camera'
                    },
                    onRenderOnIcon: () => {
                        return customCameraOnIcon()
                    },
                    onRenderOffIcon: () => {
                        return customCameraOffIcon()
                    }
                }} />}
                {microphoneProps && <MicrophoneButton {...
                    {
                        ...microphoneProps,
                        showLabel: true,
                        strings: {
                            onLabel: 'Mic',
                            offLabel: 'Mic'
                        },
                        onRenderOnIcon: () => {
                            return customMicOnIcon()
                        },
                        onRenderOffIcon: () => {
                            return customMicOffIcon()
                        },
                    }}
                />}
            </ControlBar></div>
            {videoGalleryProps && <VideoGallery {...videoGalleryPropOverrides}/>}
        </Stack>
    </FluentThemeProvider>;

}

const ExpandedParticipantsModal: React.FC = () => {
    const msal = useMsal();
    const {callManagers, connect, audioVideoSettings} = useCommunicationsContext();
    const [callContext, setCallContext] = useState<CallContext>();

    useEffect(() => {
        const messageHandler = (event: MessageEvent) => {
            if (event.data && globalWindowUtils.getWindowLocationOrigin() === event.origin) {
                try {
                    const parsedCallContext = JSON.parse(event.data);
                    setCallContext(parsedCallContext);
                } catch (e) {
                    return;
                }
            }
        };
        window.addEventListener("message", messageHandler, false);
        return () => {
            window.removeEventListener("message", messageHandler);
        };
    }, []);

    const hasCallManagers = callManagers
        && callManagers.call
        && callManagers.callAgent
        && callManagers.statefulCallClient;

    const tokenRefresher = () => {
        return ACSTokenProviderClient.getACSTeamsUserToken(msal)
            .then(acsToken => acsToken);
    }

    useEffect(() => {
        if (hasCallManagers) {
            window.opener.postMessage(JSON.stringify({
                eventType: 'audioVideoSettingsChanged',
                payload: audioVideoSettings,
            }));
        }
    }, [hasCallManagers, audioVideoSettings]);

    useEffect(() => {
        if (hasCallManagers) {
            window.opener.postMessage(JSON.stringify({
                eventType: 'callConnected',
            }));
        }
    }, [hasCallManagers]);

    useEffect(() => {
        if (callContext && callContext.acsToken && callContext.joinUrl && callContext.userId) {
            connect({
                acsTokenDetails: {
                    accessToken: callContext.acsToken,
                    tokenRefresher
                },
                userId: callContext.userId,
                displayName: msalUtils.getAccountName(msal),
                joinUrl: callContext.joinUrl,
                isAudioOn: callContext.audioVideoSettings.audioEnabled,
                isVideoOn: callContext.audioVideoSettings.videoEnabled,
            }).then();
        }
    }, [callContext]);

    return <div className='expanded-participants'>
        {
            hasCallManagers
                ? <ExpandedVideoGallery meetingParticipants={callContext?.audienceMembers ?? []}/>
                : <LoadingIndicator style={{
                    margin: 0,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}/>
        }
    </div>
}

export default ExpandedParticipantsModal;