import React from "react";
import {Col, Row} from "react-grid-system";
import BarChartLegend, {barChartLegend} from "../../components/Legend/BarChartLegend";
import {Dropdown, DropdownItem} from "../../components";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import {fontWeight} from "html2canvas/dist/types/css/property-descriptors/font-weight";

const ComparePlansContent:React.FC = () => {
    const {id} = useParams<RouteWithId>();
    const {profile, proposals, isLoading} = useProfileAndProposals(id);
    const chartLegend = <div>
        <div className="compare-plans-sub-header">Excess Assets</div>
        <div className="bar-chart-legend-container">
            <div className="asset-reliance-barchart-legend-label">
                <BarChartLegend legend={barChartLegend.NET_ASSETS} label={"ASSETS"}/>
            </div>
            <div className="asset-reliance-barchart-legend-label">
                <BarChartLegend legend={barChartLegend.GOALS} label={"GOALS"}/>
            </div>
            <div className="asset-reliance-barchart-legend-label">
                <BarChartLegend legend={barChartLegend.EXCESS_ASSETS} label={"EXCESS ASSETS"}/>
            </div>
        </div>
    </div>;
    return (
        <main className="compare-plans-content">
            <Row>
                <Col md={3.5} className="compare-plans-column">
                    {chartLegend}
                </Col>
                <Col className="compare-plans-column">
                    <div className="compare-plans-sub-header sub-title">
                        <span>Profile (Current)</span>
                    </div>
                </Col>
                <Col>
                    <Dropdown value={!isLoading ? profile.id : ''}
                              className="compare-plans-dropdown"
                              label="Select a plan"
                              inlineLabel
                              panelHeight="auto"
                              panelWidth="300px"
                              size='medium'
                              disabled={proposals.length == 0}>
                        {proposals.map(proposal => <DropdownItem key={proposal.id} value={proposal.id}
                                                                 itemText={proposal.displayName}
                                                                 style={{paddingLeft: '35px'}}
                        />)}
                    </Dropdown>
                </Col>
            </Row>

        </main>
    )
}

export default ComparePlansContent;