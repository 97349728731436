import { Icon } from "../../components";
import classNames from "classnames";
import React, { ReactElement } from "react";
import { FundingDisplayOptions } from "./models/Funding";
import { isShowFundingForInflows, isShowFundingForNonInvestable, isShowOnlyPortfolio } from "./fundingUtils";
import {TaxLiabilityPopover} from "./TaxLiabilityPopover";

type FundingAccordionItemHeaderProps = {
    expanded: boolean;
    toggle: () => void;
    title: string | ReactElement;
    fundedByRiskAssets: string;
    fundedByRiskControl: string;
    fundedByPortfolio: string;
    fundedByNonInvestable: string;
    fundedByInflows: string;
    presentValue: string | ReactElement;
    gridClassName: string;
    showChevron?: boolean;
    displayOptions: FundingDisplayOptions;
    isAchieved: boolean
};

export function FundingAccordionItemHeader({
    expanded,
    toggle,
    title,
    gridClassName,
    showChevron = true,
    fundedByRiskAssets,
    fundedByRiskControl,
    fundedByPortfolio,
    fundedByNonInvestable,
    fundedByInflows,
    presentValue,
    displayOptions,
    isAchieved
}: FundingAccordionItemHeaderProps) {
    const expandIconName = expanded ? "chevron_down" : "chevron_right";
    const showTaxLiabilityPopover = presentValue === "--" && title === "Tax Liabilities";
    const headerClass = title == "Investable Shortfall" ? "font-lg margin-none shortfall" : "font-lg margin-none";
    const subClass = title == "Investable Shortfall" ? "font-lg textalign-right shortfall" : "font-lg textalign-right";
    return (
        <div
            className={classNames("accordion-header-content", gridClassName, {
                "accordion-header-content__achieved-and-expanded": isAchieved && expanded
            })}
            data-testid={`accordion-header-${title}`}
        >
            <span
                className={`paddingleft-md display-flex align-items-center cursor-pointer`}
                onClick={toggle}
                role="button"
                aria-pressed={expanded ? 'true' : 'false'}
            >
                {showChevron ? (
                    <Icon
                        name={expandIconName}
                        size="large"
                    />
                ) : <span className="paddingleft-lg" />}
                <span className='textalign-left display-flex paddingleft-md'>
                    <div className={headerClass} aria-label="Goal Type">{title}</div>
                    {showTaxLiabilityPopover &&<TaxLiabilityPopover/>}
                </span>
                {isAchieved && !expanded && <span className="paddingleft-md marginbottom-sm">
                    <Icon name="status_validated" type="success" size="medium" color="#37A085" />
                </span>}
            </span>

                    <span className={subClass} aria-label="Total Funded by Risk Assets">
                {fundedByRiskAssets}
            </span>
            <span className={subClass} aria-label="Total Funded by Risk Control">
                {fundedByRiskControl}
            </span>
            {!isShowOnlyPortfolio(displayOptions) && <span className={subClass} aria-label="Total Funded by Portfolio">
                {fundedByPortfolio}
            </span>}
            {isShowFundingForInflows(displayOptions) &&
                <span className={subClass} aria-label="Total Funded by Inflows">
                    {fundedByInflows}
                </span>
            }
            {isShowFundingForNonInvestable(displayOptions) &&
                <span className={subClass} aria-label="Total Funded by Non Investable">
                    {fundedByNonInvestable}
                </span>
            }
            <span className={subClass} aria-label="Total Present Value">
                {presentValue}
            </span>
            <span />
        </div>
    );
}
