import React, {useContext, useEffect, useRef, useState} from "react";
import {
    HeldAwayAccountFormData, HeldAwayAccount,
} from "../models/InvestmentProgram";
import {assetsApiClient} from "../AssetsApiClient";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithAssetId} from "../../routes/types";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {RequiredFieldsBanner} from '../../components';
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import {TaxDetailsType} from "../models/TaxDetails";
import {HoldingSummary} from "../models/Holding";
import {AccountSummary} from "../AccountsCommon/AccountSummary";
import DiscardAssetModal from "../DiscardAssetModal";
import deepEquals from "fast-deep-equal";
import {AssetClassifications} from "../models/AssetClassifications";
import {setActiveFormAsset} from "../clientAssetsSlice";
import AssetsViewContext from "../common/AssetsViewContext";
import {MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import {LegalEntityFormData, OwnershipDetailsFormData} from "../models/Ownership";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {isOwnershipPercentageNotEqual100} from "../Ownership/validation";
import {
    extractOwnershipDetailsFormData,
    mapToOwnershipDetailsFormData,
    mapToOwnershipWriteModel
} from "../Ownership/mappers";
import {HistoryBlockModal} from "../../components/HistoryBlockModal/HistoryBlockModal";
import {HeldAwayAccountForm} from "./HeldAwayAccountForm";
import {hasSomeInEstateOwnership} from "../Ownership/helpers";

export default function EditHeldAwayAccount(props: { investmentProgramName: string | null }) {
    const history = useHistory();
    const viewType = useContext(AssetsViewContext);
    const profile = useAppSelector(selectProfile);
    const [isSaveButtonDisabled, updateSaveButtonDisabled] = useState(false);
    const [showDiscardModal, setShowDiscardModal] = useState(false);

    const [heldAwayAccount, setHeldAwayAccount] = useState<HeldAwayAccount>();
    const [initialHeldAwayAccount, setInitialHeldAwayAccount] = useState<HeldAwayAccount>();
    const [taxDetails, setTaxDetails] = useState<TaxDetailsType>();
    const [initialTaxDetails, setInitialTaxDetails] = useState<TaxDetailsType>();
    const [heldAwayAccountHoldings, setHeldAwayAccountHoldings] = useState<HoldingSummary>();
    const [unrealizedCapitalGainsTax, updateUnrealizedCapitalGainsTax] = useState<number | null>(null);
    const [deferredTaxLiability, updateDeferredTaxLiability] = useState<number | null>(null);

    const {assetId: heldAwayAccountId} = useParams<RouteWithAssetId>();
    const [totalInvestablePresentValue, updateTotalInvestablePresentValue] = useState<number | null>(null);
    const [isRequiredFieldsBannerShown, setRequiredFieldsBannerShown] = useState(false);
    const [memberGroup, setMemberGroup] = useState<MemberGroup>();
    const [legalEntities, updateLegalEntities] = useState<LegalEntityFormData[]>();
    const [ownershipDetailsFormData, updateOwnershipDetailsFormData] = useState<OwnershipDetailsFormData>();
    const [initialOwnershipDetailsFormData, setInitialOwnershipDetailsFormData] = useState<OwnershipDetailsFormData>();
    const [isOwnershipPercentageErrorBannerShown, setOwnershipPercentageErrorBannerShown] = useState(false);
    const [showNavigationModal, setShowNavigationModal] = useState(true);
    const [classifications, setClassifications] = useState<AssetClassifications>();
    const dispatch = useAppDispatch();
    const mounted = useRef(false);

    useEffect(() => {
        Promise.all([
            assetsApiClient.getHoldingsForHeldAwayAccounts(profile.id, heldAwayAccountId),
            assetsApiClient.getHeldAwayAccount(profile.id, heldAwayAccountId),
            clientManagementApiClient.getMemberGroup(profile.id),
            assetsApiClient.getLegalEntities(profile.id)
        ]).then(([   holdingsResponse,
                     heldAwayAccountResponse,
                     memberGroupResponse,
                     legalEntitiesResponse]) => {
            setTaxDetails({
                isEntityCapitalGains: heldAwayAccountResponse.isEntityCapitalGains,
                isLiabilityPaidByPortfolio: heldAwayAccountResponse.isLiabilityPaidByPortfolio,
            });

            setInitialTaxDetails({
                isEntityCapitalGains: heldAwayAccountResponse.isEntityCapitalGains,
                isLiabilityPaidByPortfolio: heldAwayAccountResponse.isLiabilityPaidByPortfolio,
            });

            setHeldAwayAccountHoldings(holdingsResponse);
            updateUnrealizedCapitalGainsTax(holdingsResponse.unrealizedCapitalGainsTax);
            updateDeferredTaxLiability(holdingsResponse.deferredTaxLiability);
            updateTotalInvestablePresentValue(holdingsResponse.totalInvestablePresentValue || null);
            setHeldAwayAccount(heldAwayAccountResponse);
            setInitialHeldAwayAccount(heldAwayAccountResponse);
            updateOwnershipDetailsFormData(extractOwnershipDetailsFormData(
                mapToFormData(heldAwayAccountResponse)));
            setInitialOwnershipDetailsFormData(extractOwnershipDetailsFormData(
                mapToFormData(heldAwayAccountResponse)));
            setMemberGroup(memberGroupResponse);
            updateLegalEntities(legalEntitiesResponse);

            dispatch(setActiveFormAsset({
                assetType: 'investmentProgramHAA',
                id: heldAwayAccountResponse.id || undefined,
                inEstateValue: holdingsResponse.marketValue?.inEstateValue || 0,
                description: heldAwayAccountResponse.financialAccountName,
                hasInEstateOwnership: hasSomeInEstateOwnership(heldAwayAccountResponse.memberOwnerships),
            }));
        }).catch(error => console.error('Could not fetch holding data', error.message));
    }, [profile.id, heldAwayAccountId]);

    useEffect(() => {
        mounted.current = true;
        if (heldAwayAccount?.taxStatus === "Deferred") {
            const updatedTaxDetails: TaxDetailsType = {
                isEntityCapitalGains: null,
                isLiabilityPaidByPortfolio: heldAwayAccount.isLiabilityPaidByPortfolio
            };
            setTaxDetails(updatedTaxDetails);
            handleDeferredTaxLiabilityChange()
                .catch(reason => console.log(reason));
            updateUnrealizedCapitalGainsTax(null);
        } else if (heldAwayAccount?.taxStatus === "Taxable" && taxDetails?.isEntityCapitalGains !== null) {
            handleUnrealizedCapitalGainsTaxChange(taxDetails?.isEntityCapitalGains)
                .catch(reason => console.log(reason));
            updateDeferredTaxLiability(null);
        } else {
            updateUnrealizedCapitalGainsTax(null);
            updateDeferredTaxLiability(null);
        }

        if((heldAwayAccount?.taxStatus === "Deferred" || heldAwayAccount?.taxStatus === "Taxable") && taxDetails?.isLiabilityPaidByPortfolio) {
            getInvestablePresentValue(taxDetails?.isLiabilityPaidByPortfolio).then();
        }
        return () => {
            mounted.current = false;
        }
    }, [heldAwayAccount?.taxStatus]);


    useEffect(() => {
        setRequiredFieldsBannerShown(isRequiredFieldsBannerShown && isAnyRequiredFieldEmpty());
    }, [taxDetails, heldAwayAccount?.financialAccountName, ownershipDetailsFormData?.legalEntityOwnerships]);

    useEffect(() => {
        setOwnershipPercentageErrorBannerShown(isOwnershipPercentageErrorBannerShown &&
            isOwnershipPercentageNotEqual100(ownershipDetailsFormData!));
    }, [ownershipDetailsFormData?.legalEntityOwnerships, ownershipDetailsFormData?.memberOwnerships]);

    useEffect(() => {
        assetsApiClient.getAssetClassifications().then(assetClassificationResponse => setClassifications(assetClassificationResponse));
    }, []);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
            dispatch(setActiveFormAsset(null));
        }
    }, []);

    const handleDeferredTaxLiabilityChange = async () => {
        if (initialHeldAwayAccount?.id) {
            let deferredTaxLiabilityResponse = await assetsApiClient.getDeferredTaxLiabilityForHeldAwayAccount(profile.id, initialHeldAwayAccount?.id);
            if (mounted.current) updateDeferredTaxLiability(deferredTaxLiabilityResponse);
        }
    }

    const isAnyRequiredFieldEmpty = () => {
        const isNameBlank = !heldAwayAccount?.financialAccountName.trim();
        const isLiabilityQuestionVisibleForTaxable = heldAwayAccount!.taxStatus === "Taxable" && !!taxDetails!.isEntityCapitalGains;
        const isLiabilityQuestionVisibleForDeferred = heldAwayAccount!.taxStatus === "Deferred";
        const isLiabilityQuestionVisible = isLiabilityQuestionVisibleForTaxable || isLiabilityQuestionVisibleForDeferred;
        const isLiabilityQuestionBlank = taxDetails!.isLiabilityPaidByPortfolio === null;
        const isOwnershipDataMissing = ownershipDetailsFormData?.legalEntityOwnerships.some((ownership) => {
            return !ownership.name.trim() || !ownership.type;
        });
        return isNameBlank || isOwnershipDataMissing || (isLiabilityQuestionBlank && isLiabilityQuestionVisible);
    }
    const onOwnershipFormChange = (ownershipFormData: OwnershipDetailsFormData) => {
        updateOwnershipDetailsFormData(ownershipFormData);
    }
    const handleSave = async () => {
        const {isValid} = validateForm();
        if (isValid) {
            updateSaveButtonDisabled(true);
            setShowNavigationModal(false);
            const response = await assetsApiClient.putHeldAwayAccount(profile.id, heldAwayAccountId, {
                ...mapToHeldAwayAccountModel(heldAwayAccount!),
                isEntityCapitalGains: taxDetails!.isEntityCapitalGains,
                isLiabilityPaidByPortfolio: taxDetails!.isLiabilityPaidByPortfolio,
                ...mapToOwnershipWriteModel(ownershipDetailsFormData!),
                doesPermitBeneficiary: heldAwayAccount?.doesPermitBeneficiary ?? false,
            });
            if (response.status === 200) {
                navigateToAssetsView();
            }
            return true;
        }
        return false;
    }
    const navigateToAssetsView = () => {
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
    }

    const isFormChanged = () => {
        const updated = {
            ...heldAwayAccount,
            ...taxDetails,
            ...ownershipDetailsFormData
        };
        const initial = {
            ...initialHeldAwayAccount,
            ...initialTaxDetails,
            ...initialOwnershipDetailsFormData
        }
        return !deepEquals(initial, updated);
    }
    const handleCancel = () => {
        if (isFormChanged()) {
            setShowDiscardModal(true);
            setShowNavigationModal(false);
            return;
        }
        navigateToAssetsView();
    }
    const handleUnrealizedCapitalGainsTaxChange = async (isEntityCapitalGains: boolean=false) => {
        updateUnrealizedCapitalGainsTax(await assetsApiClient.getUnrealizedCapitalGainsTaxHeldAwayAccount(
            profile.id,
            heldAwayAccountId,
            isEntityCapitalGains
        ));
    }
    const handleClickViewHoldings = async () => {
        const {isValid} = validateForm();
        if (isValid) {
            setShowNavigationModal(false);
            const response = await assetsApiClient.putHeldAwayAccount(profile.id, heldAwayAccountId, {
                ...mapToHeldAwayAccountModel(heldAwayAccount!),
                isEntityCapitalGains: taxDetails!.isEntityCapitalGains,
                isLiabilityPaidByPortfolio: taxDetails!.isLiabilityPaidByPortfolio,
                ...mapToOwnershipWriteModel(ownershipDetailsFormData!)
            });
            if (response.status === 200) {
                history.push(`/Profile/${profile.id}/ClientProfile/${viewType}/HeldAwayAccountHoldings/${heldAwayAccountId}`);
            }
        }
    };
    const validateForm = () => {
        const isRequiredFieldEmpty = isAnyRequiredFieldEmpty();
        if (isRequiredFieldEmpty) {
            setRequiredFieldsBannerShown(true);
        }
        const isOwnershipPercentageInvalid = isOwnershipPercentageNotEqual100(ownershipDetailsFormData!);
        if (isOwnershipPercentageInvalid) {
            setOwnershipPercentageErrorBannerShown(true);
        }
        return {
            isValid: !isRequiredFieldEmpty && !isOwnershipPercentageInvalid
        };
    };
    const getInvestablePresentValue = async (liabilityPaidByPortfolio: boolean) => {
        if (liabilityPaidByPortfolio && heldAwayAccount?.id) {
            let totalInvestablePresentValueResponse = await assetsApiClient.getInvestablePresentValueForHeldAwayAccount(
                profile.id, heldAwayAccount.id, heldAwayAccount.taxStatus);
            if (mounted.current) updateTotalInvestablePresentValue(totalInvestablePresentValueResponse);
        } else {
            updateTotalInvestablePresentValue(null);
        }
    }
    if (!(
        heldAwayAccount
        && heldAwayAccountHoldings
        && ownershipDetailsFormData
        && taxDetails
        && classifications
        && memberGroup
        && legalEntities)) {
        return <LoadingIndicator/>;
    }
    return (
        <div className="held-away-account asset-form">
            <div className="layout-data-entry-form">
                <HistoryBlockModal
                    when={isFormChanged() && showNavigationModal}
                    itemType={'page'}
                    onSave={handleSave}
                />
                <DataEntryHeader
                    className='dataEntryHeader'
                    title={`Edit ${heldAwayAccount.financialAccountName}`}
                    onPrimaryButtonClick={handleSave}
                    onSecondaryButtonClick={handleCancel}
                    disablePrimaryButton={isSaveButtonDisabled}
                    primaryButtonText="Save"
                    secondaryButtonText="Cancel"
                />
                <RequiredFieldsBanner showAlert={isRequiredFieldsBannerShown} itemType="held away account"/>
                <div className="held-away-account__form layout-data-entry-form">
                    <HeldAwayAccountForm
                        ownershipDetailsFormData={ownershipDetailsFormData}
                        isRequiredFieldsBannerShown={isRequiredFieldsBannerShown}
                        isOwnershipPercentageErrorBannerShown={isOwnershipPercentageErrorBannerShown}
                        handleHeldAwayAccountChange={setHeldAwayAccount}
                        investmentProgramName={props.investmentProgramName}
                        taxDetails={taxDetails}
                        handleTaxDetailsChange={setTaxDetails}
                        unrealizedCapitalGainsTax={unrealizedCapitalGainsTax}
                        deferredTaxLiability={deferredTaxLiability}
                        totalInvestablePresentValue={totalInvestablePresentValue}
                        getInvestablePresentValue={getInvestablePresentValue}
                        handleUnrealizedCapitalGainsTaxChange={handleUnrealizedCapitalGainsTaxChange}
                        memberGroup={memberGroup}
                        legalEntities={legalEntities}
                        handleLegalEntitiesChange={updateLegalEntities}
                        onOwnershipFormChange={onOwnershipFormChange}
                        heldAwayAccount={heldAwayAccount}
                        asOfDate={heldAwayAccountHoldings.asOfDate}
                        heldAwayAccountHoldings={heldAwayAccountHoldings}
                    />
                    <AccountSummary
                        assetType={'investmentProgramHAA'}
                        holdings={heldAwayAccountHoldings}
                        unrealizedCapitalGainsTax={unrealizedCapitalGainsTax}
                        deferredTaxLiability={deferredTaxLiability}
                        onClick={handleClickViewHoldings}
                        classifications={classifications}
                    />
                </div>
            </div>
            <DiscardAssetModal
                isOpen={showDiscardModal}
                title={`Discard changes to this Asset?`}
                content={`Any data entered for this asset will not be saved.`}
                onClickKeepEditing={() => setShowDiscardModal(false)}
                onClickDiscardChanges={navigateToAssetsView}
            />
        </div>
    );
}

function mapToFormData(heldAwayAccount: HeldAwayAccount): HeldAwayAccountFormData {
    return {
        ...heldAwayAccount,
        ...mapToOwnershipDetailsFormData(heldAwayAccount),
    };
}

function mapToHeldAwayAccountModel(heldAwayAccount: HeldAwayAccount) {
    const {ownershipCategory, memberOwnerships, legalEntityOwnerships, ...heldAway} = heldAwayAccount;

    return heldAway;
}
