import {PersonalLiabilitySummary} from "../../../models/PersonalLiability";
import {AccordionItemWithActionMenu} from "../../../../components";
import PersonalLiabilitiesTable from "../../InEstate/PersonalLiabilitiesTable";
import {NO_OP} from "../../../../constants/common";
import {COLOR_LIABILITIES} from "../../../../constants/colors";
import {AssetAccordionHeader} from "../../common/AssetAccordionHeader";
import React from "react";
import {formatCurrency} from "../../../../utils/format";
import {AccordionItemIds} from "../constants";
import {DropResult} from "react-beautiful-dnd";

type PersonalLiabilitiesProps = {
    totalLiabilitiesValue: number,
    personalLiabilities: PersonalLiabilitySummary[]
    capturePersonaLiabilitiesOrder?: ({source, destination}: DropResult) => void
}

const PersonalLiabilities = ({
                                 totalLiabilitiesValue,
                                 personalLiabilities,
                                 capturePersonaLiabilitiesOrder = NO_OP
                             }: PersonalLiabilitiesProps) => {

    if (personalLiabilities.length === 0) {
        return <></>;
    }

    return (
        <AccordionItemWithActionMenu
            uuid={AccordionItemIds.personalLiabilities}
            expandable={false}
            accentColor={COLOR_LIABILITIES}
            HeaderComponent={({expanded}) =>
                <AssetAccordionHeader
                    expanded={expanded}
                    formattedTotalPresentValue={formatCurrency(totalLiabilitiesValue)}
                    title="Personal Liabilities"
                    gridClassName={"assets-grid"}
                    showChevron={false}
                />
            }
        >
            <PersonalLiabilitiesTable
                personalLiabilities={personalLiabilities}
                onEdit={NO_OP}
                onDelete={NO_OP}
                hideActionMenu={true}
                isDraggable={true}
                capturePersonaLiabilitiesOrder={capturePersonaLiabilitiesOrder}
            />
        </AccordionItemWithActionMenu>
    )
}

export default PersonalLiabilities;