import {EquityCompensationFormData} from "src/Assets/models/EquityCompensation";
import {AssetsSummary, CurrentNetWorthAsset, InvestmentProgramSummary} from "../../models/Assets";
import {HeldAwayAccountSummary, LegalAgreement} from "../../models/InvestmentProgram";
import {LifeInsurance} from "../../models/LifeInsurance";
import {
    LegalEntityOwnership,
    MemberOwnership,
    MemberOwnershipFormData,
    OwnershipCategory
} from "../../models/Ownership";
import {PersonalAsset} from "../../models/PersonalAsset";
import {StandaloneAccount} from "../../models/StandaloneAccount";
import {PersonalLiability} from "../../models/PersonalLiability";
import {InvestorGroupType} from "src/ClientManagement/models/InvestorGroupType";
import {formatCurrency} from "src/utils/format";
import {AssetsViewContextValue} from "src/Assets/common/AssetsViewContext";
import {PartiallyOwnedLegalAgreement} from "../../models/PartiallyOwnedInvestmentAccount";
import {BankingAccountsSummary} from "../../models/BankingAccounts";

export function calculateOutOfEstateTotalValue(assets: AssetsSummary, useDeathBenefitForLifeInsurance = false): number {
    const personalAssetsTotal = assets.personalAssets.data.reduce((previousValue, currentValue) => {
        const outOfEstateValue = currentValue.presentValue - currentValue.inEstateValue;
        return previousValue + outOfEstateValue;
    }, 0);

    const lifeInsuranceTotal = calculateOutOfEstateLifeInsuranceTotalValue(assets.lifeInsurances.data, useDeathBenefitForLifeInsurance);

    const standaloneAccountsTotal = assets.accounts.data.reduce((previousValue, standaloneAccount) => {
        const totalMarketValue = standaloneAccount.holdings.totalMarketValue ? standaloneAccount.holdings.totalMarketValue : 0;
        const outOfEstateValue = totalMarketValue - standaloneAccount.inEstateValue;
        return previousValue + outOfEstateValue;
    }, 0);

    const investmentProgramTotal = assets.investmentProgram
        ? getOutOfEstateTotalValueForInvestmentProgram(assets.investmentProgram.legalAgreements)
        : 0;

    const heldAwayAccountTotal = assets.investmentProgram
        ? getOutOfEstateTotalValueForHeldAwayAccount(assets.investmentProgram.heldAwayAccounts)
        : 0;


    const bankingAccountTotal = assets.bankingAccounts
        ? getOutOfEstateTotalValueForBankingAccount(assets.bankingAccounts)
        : 0;



    const partiallyOwnedTotal = assets.partiallyOwnedLegalAgreements ? getOutOfEstateTotalValueForPartiallyOwnedInvestmentAccount(assets.partiallyOwnedLegalAgreements)
        : 0;

    const personalLiabilityTotal = calculatePersonalLiabilityOutOfEstateTotalValue(assets.personalLiabilities);

    return personalAssetsTotal + standaloneAccountsTotal + heldAwayAccountTotal  + bankingAccountTotal + partiallyOwnedTotal + lifeInsuranceTotal + investmentProgramTotal - personalLiabilityTotal;
}

export function calculateInEstateTotalValue(assets: AssetsSummary): number {
    const accountTotal = getInEstateTotalPresentValue(assets.accounts.data);
    const equityCompensationTotal = calculateInEstateEquityCompensationTotalValue(assets.equityCompensations.data);
    const personalAssetTotal = getInEstateTotalPresentValue(assets.personalAssets.data);
    const lifeInsuranceTotal = calculateTotalValue(assets.lifeInsurances.data);
    const generalInflowsTotal = assets.generalInflows.totalPresentValue;
    const socialSecuritiesTotal = assets.socialSecurities.totalPresentValue;
    let heldAwayAccountsTotal = 0;
    if(assets.investmentProgram !== null  && assets.investmentProgram.heldAwayAccounts !== null){
        heldAwayAccountsTotal =  calculateInEstateTotalPresentValueForHeldAwayAccounts(assets.investmentProgram.heldAwayAccounts);
    }
    let bankAccountsTotal = 0;
    if(assets.bankingAccounts && assets.bankingAccounts.length > 0 ){
        bankAccountsTotal =  calculateInEstateTotalPresentValueForBankingAccounts(assets.bankingAccounts);
    }
   const investmentProgramTotal = assets.investmentProgram ? getInEstateTotalValueForInvestmentProgram(assets.investmentProgram.legalAgreements) : 0;
    const partiallyOwnedTotal = getInEstateTotalValueForPartiallyOwnedInvestmentAccount(assets.partiallyOwnedLegalAgreements)

    return heldAwayAccountsTotal + bankAccountsTotal + accountTotal  + partiallyOwnedTotal + personalAssetTotal + lifeInsuranceTotal + generalInflowsTotal + socialSecuritiesTotal + investmentProgramTotal + equityCompensationTotal;
}

export function calculateCNWInEstateTotalValue(assets: AssetsSummary, useDeathBenefitForLifeInsurance = false): number {
    const accountTotal = getInEstateTotalPresentValue(assets.accounts.data);
    const equityCompensationTotal = calculateInEstateCNWEquityCompensationTotalValue(assets.equityCompensations.data);
    const personalAssetTotal = getInEstateTotalPresentValue(assets.personalAssets.data);
    const lifeInsuranceTotal = calculateTotalValueForCNW(assets.lifeInsurances.data, useDeathBenefitForLifeInsurance);
    let heldAwayAccountsTotal = 0;
    if(assets.investmentProgram !== null  && assets.investmentProgram.heldAwayAccounts !== null && assets.investmentProgram.heldAwayAccounts.length > 0 ){
        heldAwayAccountsTotal =  calculateInEstateTotalPresentValueForHeldAwayAccounts(assets.investmentProgram.heldAwayAccounts);
    }
    let bankAccountsTotal = 0;
    if(assets.bankingAccounts && assets.bankingAccounts.length > 0 ){
        bankAccountsTotal =  calculateInEstateTotalPresentValueForBankingAccounts(assets.bankingAccounts);
    }
    const investmentProgramTotal = assets.investmentProgram ? getInEstateTotalValueForInvestmentProgram(
        assets.investmentProgram.legalAgreements) : 0;
    const partiallyOwnedTotal = getInEstateTotalValueForPartiallyOwnedInvestmentAccount(assets.partiallyOwnedLegalAgreements)
    const totalInEstateLiabilitiesValue = assets.liabilitiesValue.inEstateValue;
    return (accountTotal + partiallyOwnedTotal + equityCompensationTotal + personalAssetTotal + lifeInsuranceTotal + investmentProgramTotal + heldAwayAccountsTotal + bankAccountsTotal) - totalInEstateLiabilitiesValue;
}

export function calculateCNWAssetsMemberOwnedTotalPresentValue(memberId: string, assets: CurrentNetWorthAsset[]): number {
    return assets.reduce((total, asset) => {
        return total + calculateMemberOwnedValue(memberId, asset.memberOwnerships, asset.ownershipCategory, asset.presentValue)
    }, 0)
}

export function calculateCNWAssetsJointlyOwnedTotalPresentValue(assets: CurrentNetWorthAsset[]): number {
    return assets.reduce((total, asset) => {
        return total + calculateJointlyOwnedValue(asset.ownershipCategory, asset.presentValue);
    }, 0);
}

export function calculateMemberOwnedValue(memberId: string, assetMemberOwnerships: MemberOwnership[], ownershipCategory: OwnershipCategory, assetValue: number): number {
    if (ownershipCategory === "JTWROS (Joint Tenants with Rights of Survivorship)") return 0;
    const percentageOwned = assetMemberOwnerships.find(memberOwnership => memberOwnership.member?.id === memberId)?.percentage
    return (assetValue && assetValue > 0 && percentageOwned) ? (assetValue * percentageOwned) / 100 : 0;
}

export function calculateEquityCompensationsPrimaryMemberOwnedValue(equityCompensation: EquityCompensationFormData, investorGroup: InvestorGroupType): string {
    const isPrimaryOwner = equityCompensation.owner === investorGroup.primaryMember.id;
    const isPrimaryRevTrust = (equityCompensation.owner.startsWith(investorGroup.primaryMember.id) && equityCompensation.owner.endsWith("revTrust"))
    if (isPrimaryOwner || isPrimaryRevTrust ) {
        return formatCurrency(equityCompensation.afterTaxVestedValue || undefined);
    }
    return '--';
}

export function calculateEquityCompensationsPartnerMemberOwnedValue(equityCompensation: EquityCompensationFormData, investorGroup: InvestorGroupType): string {
    const partnerMemberId = investorGroup.partnerMember ? investorGroup.partnerMember.id : "";
    const isPartnerOwner = equityCompensation.owner === partnerMemberId;
    const isPartnerRevTrust = (equityCompensation.owner.startsWith(partnerMemberId) && equityCompensation.owner.endsWith("revTrust"))
    if (isPartnerOwner || isPartnerRevTrust) {
        return formatCurrency(equityCompensation.afterTaxVestedValue || undefined);
    }
    return '--';
}

export function calculateEquityCompensationJointValue(equityCompensation: EquityCompensationFormData) {
    if (equityCompensation.owner === 'joint') {
        return formatCurrency(equityCompensation.afterTaxVestedValue || undefined);
    }
    return '--';
}

export function calculateJointlyOwnedValue(ownershipCategory: OwnershipCategory, assetValue: number): number {
    return ownershipCategory === "JTWROS (Joint Tenants with Rights of Survivorship)" ? assetValue : 0;
}

export function calculatePersonalAssetInEstateTotalValue(personalAssets: PersonalAsset[]): number {
    return personalAssets.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.inEstateValue;
    }, 0);
}
export function calculateTotalValue(lifeInsurances: LifeInsurance[], useDeathBenefitForLifeInsurance = false): number {
    return lifeInsurances
        .reduce((previousValue, currentValue) => {
            const lifeInsuranceValue = useDeathBenefitForLifeInsurance && !currentValue.isCashValueWillFundGoals ? currentValue.deathBenefitValue : currentValue.cashValue;
            return previousValue + calculateInEstateFormValueForLifeInsurance(lifeInsuranceValue, currentValue.memberOwnerships);
        }, 0);
}

export function calculateTotalValueForCNW(lifeInsurances: LifeInsurance[], useDeathBenefitForLifeInsurance = false): number {
    return lifeInsurances
        .reduce((previousValue, currentValue) => {
            let lifeInsuranceValue = useDeathBenefitForLifeInsurance && !currentValue.isCashValueWillFundGoals ? currentValue.deathBenefitValue : currentValue.cashValue;
            if(currentValue.type === 'Term' && (isPolicyExpired(currentValue) || isPolicyIssuedFutureYear(currentValue))){
                lifeInsuranceValue = 0;
            }
            return previousValue + calculateInEstateFormValueForLifeInsurance(lifeInsuranceValue, currentValue.memberOwnerships);
        }, 0);
}
export function calculateInEstateLifeInsuranceTotalValue(lifeInsurances: LifeInsurance[], useDeathBenefitForLifeInsurance = false): number {
    return lifeInsurances
        .filter(lifeInsurance => useDeathBenefitForLifeInsurance || lifeInsurance.isCashValueWillFundGoals)
        .reduce((previousValue, currentValue) => {
            const lifeInsuranceIfNotFundingGoals = useDeathBenefitForLifeInsurance ? currentValue.deathBenefitValue : currentValue.cashValue
            const lifeInsuranceValue = useDeathBenefitForLifeInsurance && currentValue.isCashValueWillFundGoals ? 0 : lifeInsuranceIfNotFundingGoals;
            return previousValue + calculateInEstateFormValueForLifeInsurance(lifeInsuranceValue, currentValue.memberOwnerships);
        }, 0);
}

export function calculateInEstateEquityCompensationTotalValue(equityCompensations: EquityCompensationFormData[]): number {
    return equityCompensations
        .reduce((previousValue, currentValue) => {
            const equityCompensationValue = calculateEquityCompensationValue(currentValue);
            return previousValue + equityCompensationValue;
        }, 0);
}

export function calculateInEstateCNWEquityCompensationTotalValue(equityCompensations: EquityCompensationFormData[]): number {
    return equityCompensations
        .reduce((previousValue, currentValue) => {
            const equityCompensationValue = currentValue.afterTaxVestedValue || 0;
            return previousValue + equityCompensationValue;
        }, 0);
}

export function calculateTotalEquityCompensationsPrimaryOwnedValue(equityCompensations: EquityCompensationFormData[], investorGroup: InvestorGroupType): number {
    return equityCompensations
        .reduce((previousValue, currentValue) => {
            const equityCompensationValue = (currentValue.owner === investorGroup.primaryMember.id) 
                ? currentValue.afterTaxVestedValue || 0 
                : 0;
            return previousValue + equityCompensationValue;
        }, 0);
}

export function calculateTotalEquityCompensationsPartnerOwnedValue(equityCompensations: EquityCompensationFormData[], investorGroup: InvestorGroupType): number {
    return equityCompensations
        .reduce((previousValue, currentValue) => {
            const equityCompensationValue = (currentValue.owner === investorGroup.partnerMember?.id) 
                ? currentValue.afterTaxVestedValue || 0 
                : 0;
            return previousValue + equityCompensationValue;
        }, 0);
}

export function calculateEquityCompensationValue(currentValue: EquityCompensationFormData, viewType: AssetsViewContextValue = "AssetSummary") {
    if(viewType === 'AssetSummary') {
        return (currentValue.afterTaxVestedValue || 0) + (currentValue.afterTaxUnvestedValue || 0);
    }
    return (currentValue.afterTaxVestedValue || 0);
}

export function calculateInEstateFormValueForLifeInsurance(value: number, memberOwnerships: MemberOwnership[]): number {
    const inEstatePercentageTotal = memberOwnerships.filter(member => member.isInEstateMember).reduce((previous, current) => {
        return previous + current.percentage;
    }, 0);
    return inEstatePercentageTotal * value / 100;
}

export function calculateOutOfEstateLifeInsuranceTotalValue(lifeInsurances: LifeInsurance[], useDeathBenefitForLifeInsurance = false): number {
    return lifeInsurances
        .filter(lifeInsurance => useDeathBenefitForLifeInsurance || lifeInsurance.isCashValueWillFundGoals)
        .reduce((previousValue, currentValue) => {
            const lifeInsuranceValue = useDeathBenefitForLifeInsurance ? currentValue.deathBenefitValue : currentValue.cashValue;
            return previousValue + calculateOutOfEstateFormValueForLifeInsurance(lifeInsuranceValue, currentValue.memberOwnerships, currentValue.legalEntityOwnerships);
        }, 0);
}

export function calculateOutOfEstateFormValueForLifeInsurance(value: number, memberOwnerships: MemberOwnership[], legalEntityOwnerships: LegalEntityOwnership[]): number {
    const outOfEstateMemberOwnershipsTotal = memberOwnerships.filter(member => !member.isInEstateMember).reduce((previous, current) => {
        return previous + current.percentage;
    }, 0);

    const legalEntityOwnershipsTotal = legalEntityOwnerships.reduce((previous, current) => {
        return previous + current.percentage;
    }, 0);

    return (outOfEstateMemberOwnershipsTotal + legalEntityOwnershipsTotal) * value / 100;
}

export function calculatePersonalLiabilityOutOfEstateTotalValue(personalLiabilities: PersonalLiability[]): number {
    return personalLiabilities.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.loanBalanceEstateValue.outOfEstateValue;
    }, 0);
}

export function calculateAllAccountsInEstateTotalMarketValue(
    standaloneAccounts: StandaloneAccount[],
    investmentProgram: InvestmentProgramSummary | null,
    partiallyOwnedInvestmentAccounts: PartiallyOwnedLegalAgreement[],
    bankingAccounts: BankingAccountsSummary[] ) {
    const heldAwayAccounts = investmentProgram?.heldAwayAccounts || [];
    return calculateInEstateTotalMarketValueForAccounts(standaloneAccounts, investmentProgram?.legalAgreements || [],
        heldAwayAccounts , partiallyOwnedInvestmentAccounts, bankingAccounts)
}

export function calculateInEstateTotalMarketValueForAccounts(
    standaloneAccounts: StandaloneAccount[],
    legalAgreements: LegalAgreement[],
    heldAwayAccounts: HeldAwayAccountSummary[],
    partiallyOwnedInvestmentAccounts: PartiallyOwnedLegalAgreement[],
    bankingAccounts: BankingAccountsSummary[] ) {
    const standaloneAccountTotal = getInEstateTotalPresentValue(standaloneAccounts);
    const bankingAccountsTotal = calculateInEstateTotalPresentValueForBankingAccounts(bankingAccounts);
    const heldAwayAccountTotal = calculateInEstateTotalPresentValueForHeldAwayAccounts(heldAwayAccounts);
    const investmentProgramTotal = getInEstateTotalValueForInvestmentProgram(legalAgreements);
    const PartiallyOwnedInvestmentAccountTotal = getInEstateTotalValueForPartiallyOwnedInvestmentAccount(partiallyOwnedInvestmentAccounts);
    return standaloneAccountTotal + investmentProgramTotal + PartiallyOwnedInvestmentAccountTotal + heldAwayAccountTotal + bankingAccountsTotal;
}

export function calculateAllAccountsInEstateInvestableTotalValue(
    standaloneAccounts: StandaloneAccount[],
    investmentProgram: InvestmentProgramSummary | null,
    bankAccounts: BankingAccountsSummary[],
    isHeldAwayAccountToShow?:boolean) {
    const heldAwayAccounts = isHeldAwayAccountToShow && investmentProgram?.heldAwayAccounts || [];
    return calculateInEstateInvestableTotalValueForAccounts(standaloneAccounts, investmentProgram?.legalAgreements || []
        , heldAwayAccounts,bankAccounts)
}

export function calculateInEstateInvestableTotalValueForAccounts(
    standaloneAccounts: StandaloneAccount[], legalAgreements: LegalAgreement[], heldAwayAccount: HeldAwayAccountSummary[],bankAccounts: BankingAccountsSummary[]) {
    const standaloneAccountTotal = calculateInEstateInvestableStandaloneAccountsTotalValue(standaloneAccounts);
    const heldAwayAccountTotal = calculateInEstateInvestableHeldAwayAccountsTotalValue(heldAwayAccount);
    const investmentProgramTotal = getInvestableValueForInvestmentProgram(legalAgreements);
    const bankAccountsTotal = calculateInEstateInvestableBankAccountsTotalValue(bankAccounts);
    return standaloneAccountTotal + investmentProgramTotal + heldAwayAccountTotal + bankAccountsTotal;
}

export function getInvestableValueForInvestmentProgram(legalAgreements: LegalAgreement[]): number {
    const legalAgreementValue = legalAgreements.map(legalAgreement => {
        const totalPercentage = legalAgreement.memberOwnerships
            .filter(member => member.isInEstateMember)
            .reduce((previous, current) => {
                return previous + current.percentage;
            }, 0)
        return totalPercentage * (legalAgreement.investableValue ? legalAgreement.investableValue : 0) / 100;
    }).reduce((previous, current) => previous + current, 0);

    return legalAgreementValue
}

export function calculateInEstateInvestableStandaloneAccountsTotalValue(standaloneAccounts: StandaloneAccount[]): number {
    return standaloneAccounts.reduce((previousValue, currentValue) => {
        const value = currentValue.holdings.totalInvestableValue;
        return previousValue + calculateInEstateValue(value ? value : 0, currentValue.memberOwnerships);
    }, 0);
}

export function calculateInEstateInvestableHeldAwayAccountsTotalValue(heldAwayAccounts: HeldAwayAccountSummary[]): number {
    return heldAwayAccounts.reduce((previousValue, currentValue) => {
        const value = currentValue.holdings.totalInvestableValue || 0;
        return previousValue + value
    }, 0);
}

export function calculateInEstateTotalPresentValueForHeldAwayAccounts(heldAwayAccounts: HeldAwayAccountSummary[]): number {
    return heldAwayAccounts.reduce((previousValue, currentValue) => {
        const value = currentValue.holdings.marketValue?.inEstateValue || 0;
        return previousValue + value;
    }, 0);
}


export function calculateInEstateInvestableBankAccountsTotalValue(bankingAccounts: BankingAccountsSummary[]): number {
    return bankingAccounts.reduce((previousValue, currentValue) => {
        const value = currentValue.holdings ? currentValue.holdings.totalInvestableValue || 0 : 0;
        return previousValue + value;
    }, 0);
}

export function calculateInEstateTotalPresentValueForBankingAccounts(bankingAccounts: BankingAccountsSummary[]): number {
    return bankingAccounts.reduce((previousValue, currentValue) => {
        const value = currentValue.holdings ? currentValue.holdings.marketValue?.inEstateValue || 0 : 0;
        return previousValue + value;
    }, 0);
}


export function calculateInEstateValue(value: number, memberOwnerships: MemberOwnership[]): number {
    const inEstatePercentageTotal = memberOwnerships?.reduce((previous, current) => {
        return previous + current.percentage;
    }, 0);
    return inEstatePercentageTotal * value / 100;
}

export function calculateInEstateFormValue(value: number, memberOwnerships: MemberOwnershipFormData[]): number {
    const inEstatePercentageTotal = memberOwnerships.reduce((previous, current) => {
        return previous + parseFloat(current.percentage);
    }, 0);
    return inEstatePercentageTotal * value / 100;
}

export function getInEstateTotalPresentValue(assets: { inEstateValue: number }[]) {
    return assets.reduce((previousValue, currentValue) => previousValue + currentValue.inEstateValue, 0);
}

export function getOutOfEstateTotalValueForInvestmentProgram(legalAgreements: LegalAgreement[]): number {
    return legalAgreements.map(legalAgreement => {
        const totalMemberOwnershipsPercentage = legalAgreement.memberOwnerships
            .filter(member => !member.isInEstateMember)
            .reduce((previous, current) => {
                return previous + current.percentage;
            }, 0)
        const totalLegalEntityPercentage = legalAgreement.legalEntityOwnerships
            .reduce((previous, current) => {
                return previous + current.percentage;
            }, 0)
        return ((totalMemberOwnershipsPercentage + totalLegalEntityPercentage) * legalAgreement.marketValue) / 100;
    }).reduce((previous, current) => previous + current, 0);
}

export function getOutOfEstateTotalValueForHeldAwayAccount(heldAwayAccounts: HeldAwayAccountSummary[]): number {
    return heldAwayAccounts.map(heldAwayAccount => {
        const totalMemberOwnershipsPercentage = heldAwayAccount.memberOwnerships
            .filter(member => !member.isInEstateMember)
            .reduce((previous, current) => {
                return previous + current.percentage;
            }, 0)
        const totalLegalEntityPercentage = heldAwayAccount.legalEntityOwnerships
            .reduce((previous, current) => {
                return previous + current.percentage;
            }, 0)
        return ((totalMemberOwnershipsPercentage + totalLegalEntityPercentage) * heldAwayAccount.marketEstateValue.totalValue) / 100;
    }).reduce((previous, current) => previous + current, 0);
}

export function getOutOfEstateTotalValueForBankingAccount(bankAccounts: BankingAccountsSummary[]): number {
    return bankAccounts.map(bankAccount => {
        const totalMemberOwnershipsPercentage = bankAccount.memberOwnerships
            .filter(member => !member.isInEstateMember)
            .reduce((previous, current) => {
                return previous + current.percentage;
            }, 0)
        const totalLegalEntityPercentage = bankAccount.legalEntityOwnerships
            .reduce((previous, current) => {
                return previous + current.percentage;
            }, 0)
        return ((totalMemberOwnershipsPercentage + totalLegalEntityPercentage) * bankAccount.eodNtBalance) / 100;
    }).reduce((previous, current) => previous + current, 0);
}

export function getOutOfEstateTotalValueForPartiallyOwnedInvestmentAccount(partiallyOwnedInvestmentAccounts: PartiallyOwnedLegalAgreement[]): number {
    return partiallyOwnedInvestmentAccounts && partiallyOwnedInvestmentAccounts.map(partiallyOwnedInvestmentAccount => {
        const totalMemberOwnershipsPercentage = partiallyOwnedInvestmentAccount.memberOwnerships
            .filter(member => !member.isInEstateMember)
            .reduce((previous, current) => {
                return previous + current.percentage;
            }, 0)
        const totalpartiallyOwnedPercentage = partiallyOwnedInvestmentAccount.legalEntityOwnerships
            .reduce((previous, current) => {
                return previous + current.percentage;
            }, 0)
        return ((totalMemberOwnershipsPercentage + totalpartiallyOwnedPercentage) * partiallyOwnedInvestmentAccount.marketEstateValue.totalValue) / 100;
    }).reduce((previous, current) => previous + current, 0);
}
export function getInEstateTotalValueForInvestmentProgram(legalAgreements: LegalAgreement[]): number {
    const legalAgreementValue = legalAgreements.map(legalAgreement => {
        const totalPercentage = legalAgreement.memberOwnerships
            .filter(member => member.isInEstateMember)
            .reduce((previous, current) => {
                return previous + current.percentage;
            }, 0)
        return totalPercentage * legalAgreement.marketValue / 100;
    }).reduce((previous, current) => previous + current, 0);

    return legalAgreementValue
}
export function getInEstateTotalValueForPartiallyOwnedInvestmentAccount(partiallyOwnedInvestmentAccounts: PartiallyOwnedLegalAgreement[]): number {
    return partiallyOwnedInvestmentAccounts && partiallyOwnedInvestmentAccounts.map(partiallyOwnedInvestmentAccount => {
        const totalPercentage = partiallyOwnedInvestmentAccount.memberOwnerships
            .filter(member => member.isInEstateMember)
            .reduce((previous, current) => {
                return previous + current.percentage;
            }, 0)
        return totalPercentage * partiallyOwnedInvestmentAccount.marketEstateValue.totalValue / 100;
    }).reduce((previous, current) => previous + current, 0);
}

export function isPolicyExpired(lifeInsurance: LifeInsurance) {
    return lifeInsurance.expirationDate  && (parseInt(lifeInsurance.expirationDate.split('-')[0])  < (new Date().getFullYear()));
}

export function isPolicyIssuedFutureYear(lifeInsurance: LifeInsurance) {
    return lifeInsurance.issueDate  && (parseInt(lifeInsurance.issueDate.split('-')[0]) > (new Date(Date.now()).getFullYear()));
}