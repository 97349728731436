import {PersonalLiabilityRow} from "./PersonalLiabilityRow";
import {PersonalLiability, PersonalLiabilitySummary} from "../../models/PersonalLiability";
import AssetSummaryPersonalLiabilityDetails from "./AssetSummaryPersonalLiabilityDetails";
import React from "react";
import DragDrop from '@nt/dds-react/core/DragDrop';
import {NO_OP} from "../../../constants/common";
import {Icon} from "../../../components";
import {DropResult} from "react-beautiful-dnd";
import {sortByOrdinalAndLoanBalance} from "../common/utils";

type PersonalLiabilitiesTableProps = {
    personalLiabilities: PersonalLiabilitySummary[],
    onEdit: (personalLiabilityId: string) => void,
    onDelete: (personalLiability: PersonalLiability) => void,
    hideActionMenu?: boolean,
    isDraggable?: boolean,
    capturePersonaLiabilitiesOrder?: ({source, destination}: DropResult) => void
}

const PersonalLiabilitiesTable = ({
                                      personalLiabilities,
                                      onDelete,
                                      onEdit,
                                      hideActionMenu = false,
                                      isDraggable = false,
                                      capturePersonaLiabilitiesOrder = NO_OP
                                  }: PersonalLiabilitiesTableProps) => {

    const sortedPersonalLiabilities: PersonalLiabilitySummary[] = sortByOrdinalAndLoanBalance(personalLiabilities) as PersonalLiabilitySummary[]

    const renderPersonalLiabilityRows = () => {
        if (isDraggable) {
            return (
                <DragDrop
                    className="draggable"
                    direction="vertical"
                    draggableId="personalLiabilityDraggableId1"
                    droppableId="personalLiabilityDroppableId1"
                    onDragEnd={capturePersonaLiabilitiesOrder}
                    items={
                        sortedPersonalLiabilities.map((personalLiability) =>
                            <div className="display-flex align-items-right" tabIndex={-1} key={personalLiability.id}>
                                <Icon ariaHidden className="drag__icon" name="drag_vertical"/>
                                <PersonalLiabilityRow key={personalLiability.id}
                                                      onClickEdit={onEdit}
                                                      onClickDelete={onDelete}
                                                      personalLiability={personalLiability}
                                                      actionsDisabled={false}
                                                      hideActionMenu={hideActionMenu}
                                                      gridClassName={"assets-grid-with-actionmenu"}
                                                      renderPersonalLiabilityDetails={(personalLiabilitySummary: PersonalLiabilitySummary) =>
                                                          <AssetSummaryPersonalLiabilityDetails
                                                              personalLiabilitySummary={personalLiabilitySummary}/>}
                                />
                            </div>
                        )
                    }>
                </DragDrop>
            )
        }

        return (
            <>
                {sortedPersonalLiabilities.map(personalLiability =>
                    <PersonalLiabilityRow key={personalLiability.id}
                                          onClickEdit={onEdit}
                                          onClickDelete={onDelete}
                                          personalLiability={personalLiability}
                                          actionsDisabled={false}
                                          hideActionMenu={hideActionMenu}
                                          gridClassName={"assets-grid-with-actionmenu"}
                                          renderPersonalLiabilityDetails={(personalLiabilitySummary: PersonalLiabilitySummary) =>
                                              <AssetSummaryPersonalLiabilityDetails
                                                  personalLiabilitySummary={personalLiabilitySummary}/>}
                    />)}
            </>
        )
    }

    return (
        <div role="table" className="assets-grid-table"
             aria-label="personal-liabilities-table">
            <div role="row"
                 className={`${hideActionMenu ? 'assets-grid-table-header_arrange' : 'assets-grid-table-header'} assets-grid-with-actionmenu`}
                 tabIndex={-1}>
                <span role="columnheader" className='paddingleft-xxl textalign-left'>Name</span>
                <span role="columnheader" className='textalign-left'>Institution</span>
                <span role="columnheader" className='textalign-right'>Interest Rate</span>
                <span role="columnheader" className='textalign-right'>Maturity Date</span>
                <span role="columnheader" className='textalign-right'>Loan Balance</span>
            </div>
            {renderPersonalLiabilityRows()}
        </div>
    )
}

export default PersonalLiabilitiesTable;