import {PresentationPaneHeader, Tab, TabBar, TabPanel, TabsProvider,} from "../../components";
import React, {useEffect, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {RouteWithIdAndActiveTab} from "../../routes/types";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import ReportSetup from "./ReportSetup";
import {TabsConsumer} from "xps-react";
import moment from "moment";
import PreviewReport from "./PreviewReport";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    selectAdviceLibraryDocumentsCount,
    selectClientDocumentsCount,
    selectMyDocumentsCount,
    selectPracticeDocumentsCount,
    selectSelectedPagesForReport,
    selectSelectedReportType
} from "./ReportingSlice";
import {selectProfile, selectResourceCode} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {reportingApiClient} from "../ReportingApiClient";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import {clientManagementApiClient} from "src/ClientManagement/ClientManagementApiClient";
import {ISO8601_DATE_FORMAT} from "src/constants/common";
import {resetFamilyTreeToDefaults} from "../../ClientManagement/FamilyTree/FamilyTreeSlice";
import {ClientPersonaId, SendReportRequest} from "../models/Reporting";
import {UploadReportToDch} from "./UploadReportToDCH";
import {ReportHeaderControls} from "./ReportHeaderControls";
import {MessageFromDCH} from "./DCHToasts";
import {
    EMPTY_ASSET_RELIANCE_BUTTON_STATE_FOR_REPORT
} from "../../ClientManagement/AssetReliance/AssetRelianceButtonState";
import {
    setAssetReliancePageActionMenuForDetailedReport,
    setAssetReliancePageActionMenuForSummaryReport
} from "../../ClientManagement/AssetReliance/AssetRelianceSlice";


const CreateReport = () => {
    const {id} = useParams<RouteWithIdAndActiveTab>();
    const history = useHistory();
    const location = useLocation();
    const [isGenerating, setIsGenerating] = useState<boolean>(false);
    const [showFailureMessageFromWDS, setShowFailureMessageFromWDS] = useState<boolean>(false);
    const [showPersonaModal, setShowPersonaModal] = useState<boolean>(false);

    const [showMessageFromDCH, setShowMessageFromDCH] = useState<boolean>(false);
    const [messageFromDCH, setMessageFromDCH] = useState<MessageFromDCH>({
        title: "",
        message: "",
        type: ""
    });

    const [clientUserList, setClientUserList] = useState<Array<ClientPersonaId>>([]);

    const [generatedReportUrl, setGeneratedReportUrl] = useState<string>('');
    const {approvedProfile} = useProfileAndProposals(id);
    const displayName = approvedProfile.displayName;
    const profile = useAppSelector(selectProfile);
    const selectedPagesForAnnualReportFromRedux = useAppSelector(selectSelectedPagesForReport);
    const adviceLibraryDocumentsCount = useAppSelector(selectAdviceLibraryDocumentsCount);
    const practiceDocumentsCount = useAppSelector(selectPracticeDocumentsCount);
    const clientDocumentsCount = useAppSelector(selectClientDocumentsCount);
    const myDocumentsCount = useAppSelector(selectMyDocumentsCount);
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const resourceCode = useAppSelector(selectResourceCode);
    const reportType = useAppSelector(selectSelectedReportType);
    const dispatch = useAppDispatch();
    const [failureMessageFromWDS, setFailureMessageFromWDS] = useState<string>("");
    const [uploadReportDisabled, setUploadReportDisabled] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [hasError, setHasError] = useState(false);

    const isAnyPageSelected = Object.values(selectedPagesForAnnualReportFromRedux).some(page => page.isEnabled);

    useEffect(() => {
        return () => {
            dispatch(resetFamilyTreeToDefaults());
            if (history.location.pathname.includes("EditAssetRelianceDetailedWithFutureValue") === false && history.location.pathname.includes("EditAssetRelianceWithFutureValue") === false) {
                dispatch(setAssetReliancePageActionMenuForDetailedReport({...EMPTY_ASSET_RELIANCE_BUTTON_STATE_FOR_REPORT}));
                dispatch(setAssetReliancePageActionMenuForSummaryReport({...EMPTY_ASSET_RELIANCE_BUTTON_STATE_FOR_REPORT}));
            }
        };
    }, []);

    const navigateToEditReportPage = (param:string) => {

        const familyTreePageLocation = location.pathname + `/EditFamilyTree`;
        const nwotPageLocation = location.pathname + `/EditNetWorthOverTime`;
        const goalFundingPageLocation = location.pathname + `/EditGoalFunding`;
        const assetRelianceWithFutureValuePageLocation = location.pathname + `/EditAssetRelianceWithFutureValue`;
        const assetRelianceDetailedWithFutureValuePageLocation = location.pathname + `/EditAssetRelianceDetailedWithFutureValue`;

        if(param === "netWorthOverTime") {
            history.push(nwotPageLocation);
        }
        else if(param === "goalFunding") {
            history.push(goalFundingPageLocation);
        }
        else if(param === "assetRelianceWithFutureValue") {
            history.push(assetRelianceWithFutureValuePageLocation);
        }
        else if(param === "assetRelianceDetailedWithFutureValue") {
            history.push(assetRelianceDetailedWithFutureValuePageLocation);
        }
        else {
            history.push(familyTreePageLocation);
        }
    };

    const getClientPersonaIds = () => {
        reportingApiClient.getClientPersonaIds(id, resourceCode).then(clientUserIds => {

            if(clientUserIds.clientStatus === "Onboarding")
            {
                setFailureMessageFromWDS("Client does not have active Private Passport login credentials. Please contact the GPI IT SUPPORT at GPIITSUPPORT@ntrs.com.");
                setShowFailureMessageFromWDS(true);
                setUploadReportDisabled(true);
            }
            else if(clientUserIds.clientStatus === "Former Client" || clientUserIds.clientStatus === "Inactive")
            {
                setFailureMessageFromWDS("Client does not have active Private Passport login credentials. Please contact the GPI IT SUPPORT at GPIITSUPPORT@ntrs.com.")
                setShowFailureMessageFromWDS(true);
                setUploadReportDisabled(true)
            }
            else if (clientUserIds?.clientPersonaList && clientUserIds.clientPersonaList.length) {
                setShowPersonaModal(true);
                setClientUserList(clientUserIds.clientPersonaList);
            } else {
                setFailureMessageFromWDS("Unable to fetch the client details. Please contact the GPI IT SUPPORT at GPIITSUPPORT@ntrs.com.")
                setShowFailureMessageFromWDS(true);
                setUploadReportDisabled(true);
            }
        }).catch(() => {
            setFailureMessageFromWDS("Something appears to have went wrong. Please contact the GPI IT SUPPORT at GPIITSUPPORT@ntrs.com.")
            setShowFailureMessageFromWDS(true);
            setUploadReportDisabled(true);
        })
    };

    function sendAnnualReportToDch(clientPersonaId: ClientPersonaId) {
        setShowPersonaModal(false)
        setIsUploading(true);
        if (clientPersonaId !== null) {
            const sendReportRequest: SendReportRequest = {
                fileName: formatFileName(),
                clientPersonaId: clientPersonaId.personaId!,
                ulek: resourceCode
            }

            reportingApiClient.sendReportToDCH(id, sendReportRequest).then(dchResponse => {
                setIsUploading(false);
                if (dchResponse !== null && dchResponse.userStatus === true) {
                    sendAnnualReportDate();
                    setShowMessageFromDCH(true);
                    setMessageFromDCH({
                        title: "Report Uploaded Successfully.",
                        message: "Visit partner workstation to send email notification to client.",
                        type: "success"
                    })
                } else if (dchResponse !== null && dchResponse.userStatus === false) {
                    setShowMessageFromDCH(true)
                    setMessageFromDCH({
                        title: "Report could not be sent.",
                        message: "Client data mismatch observed in the system. Please contact the GPI IT SUPPORT at GPIITSUPPORT@ntrs.com.",
                        type: "error"
                    })
                    setUploadReportDisabled(true);
                } else if (dchResponse === null) {
                    setShowMessageFromDCH(true);
                    setMessageFromDCH({
                        title: "Report could not be sent.",
                        message: "Something appears to have went wrong. Please contact the GPI IT SUPPORT at GPIITSUPPORT@ntrs.com.",
                        type: "error"
                    })
                    setUploadReportDisabled(true);
                }

            }).catch(() => {
                setIsUploading(false);
                setShowMessageFromDCH(true);
                setMessageFromDCH({
                    title: "Report could not be sent.",
                    message: "Something appears to have went wrong. Please contact the GPI IT SUPPORT at GPIITSUPPORT@ntrs.com.",
                    type: "error"
                })
                setUploadReportDisabled(true);
            })
        }
    }


    function onCloseSuccess() {
        setShowMessageFromDCH(false)
    }

    function onCloseFailure() {
        setShowFailureMessageFromWDS(false)
    }

    function formatFileName() {
        const displayNameWithRegex = profile.displayName.replace(/[^a-zA-Z0-9 ]/g, '_').trim()
        const reportTypeLabel = reportType === "Annual Report" ? "Annual_Report" : "Report";
        return `${displayNameWithRegex}_${reportTypeLabel}_${moment().format('MM-DD-YYYY')}`;
    }

    const sendAnnualReportDate = () => {
        if (profile?.id && reportType === "Annual Report") {
            clientManagementApiClient.putAnnualReportSentDate(profile.id, moment().format(ISO8601_DATE_FORMAT))
                .catch((e) => console.error("Failed to send report generated date: ", e.message));
        }
    }

    function checkReportForSelectedConfig() {
        return !isAnyPageSelected && adviceLibraryDocumentsCount === 0 && practiceDocumentsCount === 0 && clientDocumentsCount === 0 && myDocumentsCount === 0;
    }


    const titleRowChildren = () => (
        <TabsConsumer>
            {({activeIndex, setActive}: any) =>
                <ReportHeaderControls
                    activeIndex={activeIndex}
                    setActive={setActive}
                    sendAnnualReportDate={sendAnnualReportDate}
                    getClientPersonaIds={getClientPersonaIds}
                    isPreviewDisabled={checkReportForSelectedConfig()}
                    isUploadReportDisabled={checkReportForSelectedConfig() || isGenerating || !resourceCode || !generatedReportUrl || uploadReportDisabled || hasError}
                    isDownloadDisabled={checkReportForSelectedConfig() || isGenerating || !generatedReportUrl || hasError}
                    releaseToggles={releaseToggles!}
                    formatFileName={formatFileName}
                    generatedReportUrl={generatedReportUrl}
                />
            }
        </TabsConsumer>
    );

    const bottomRowChildren = () => (
        <div className="tab-bar">
            <TabBar theme="light">
                <Tab name="1. Report Setup"/>
                <Tab name="2. Preview"/>
            </TabBar>
        </div>
    )

    return (
        <div className="create-report">
            <TabsProvider tabIdArray={["report-setup", "preview"]}>
                <section data-theme="aqua" className="header">
                    <PresentationPaneHeader className="header"
                                            displayName={displayName}
                                            title="Create a Report"
                                            bottomRow={bottomRowChildren()}
                                            titleRow={titleRowChildren()}
                    />
                </section>
                <TabPanel className="marginbottom-xl">
                    <ReportSetup
                        navigateToEditReportPage={navigateToEditReportPage}
                    />
                    <PreviewReport
                        displayName={displayName}
                        isGenerating={isGenerating}
                        isUploading={isUploading}
                        setIsGenerating={setIsGenerating}
                        generatedReportUrl={generatedReportUrl}
                        setGeneratedReportUrl={setGeneratedReportUrl}
                        showMessageFromDCH={showMessageFromDCH}
                        messageFromDCH={messageFromDCH}
                        onCloseSuccessMessage={onCloseSuccess}
                        onCloseFailureMessage={onCloseFailure}
                        showFailureMessageFromWDS={showFailureMessageFromWDS}
                        failureMessageFromWDS={failureMessageFromWDS}
                        hasError={hasError}
                        setHasError={setHasError}
                    />
                </TabPanel>
            </TabsProvider>
            <UploadReportToDch
                showPersonaModal={showPersonaModal}
                onCancel={() => setShowPersonaModal(false)}
                label={displayName}
                clientPersonaIds={clientUserList}
                sendToDCH={sendAnnualReportToDch}
            />
        </div>
    );
};

export default CreateReport;