import React, {useEffect, useState} from "react";
import {Beneficiary} from "../models/LifeInsurance";
import {MemberGroupMember} from "../../ClientManagement/models/InvestorGroupType";
import {Button, Icon, LeftRightAlertBanner, PercentInput, UnderlinedHeader} from "../../components";
import {BeneficiaryDropdown} from "./BeneficiaryDropdown";
import {Placeholder} from "../../components/DataFormTable/Placeholder";
import {deduplicateArray} from "../../utils/deduplicateArray";
import {calculateTotalAllocation} from "./validation";

type BeneficiaryFormProps = {
    onDelete: (index: number, beneficiaryList: Array<Beneficiary>) => void
    beneficiaries: Array<Beneficiary>
    members: Array<MemberGroupMember>
    beneficiaryMembers: Array<MemberGroupMember>
    onMemberChange: (memberIdOrEntityName: string, index: number) => void,
    onPercentChange: (percentage: string, index: number) => void
    onAdd: () => void,
    showAlert: boolean,
    setShowAlert: (showAlert: boolean) => void,
    isProfileWithProposalsOrArchived?: boolean
}

const BeneficiariesForm = ({
                               beneficiaries,
                               beneficiaryMembers,
                               members,
                               onAdd,
                               onDelete,
                               onMemberChange,
                               onPercentChange,
                               showAlert,
                               setShowAlert,
                               isProfileWithProposalsOrArchived = false
                           }: BeneficiaryFormProps) => {

    const [totalBeneficiaryAllocation, setTotalBeneficiaryAllocation] = useState<number>(0.00);

    useEffect(() => {
        getTotalBeneficiaryAllocation();
    }, [beneficiaries]);

    const getTotalBeneficiaryAllocation = () => {
        const total = calculateTotalAllocation(beneficiaries);
        setTotalBeneficiaryAllocation(total);
        if (total === 100.00) {
            setShowAlert(false);
        }
    };

    const eligibleBeneficiaries = (selectedBeneficiary: Beneficiary): MemberGroupMember[] => {
        return deduplicateArray([
            ...beneficiaryMembers,
            ...members.filter(m => (m.id === selectedBeneficiary.memberId || m.id === selectedBeneficiary.entityName))
        ]);
    }

    const handlePercentChange = (e: string, index: number) => {
        onPercentChange(e, index);
        getTotalBeneficiaryAllocation();
    }

    const handleDelete = (deleteIndex: number) => {
        const beneficiaryList = beneficiaries.filter((_b, index) => deleteIndex !== index);

        onDelete(deleteIndex, beneficiaryList);
    }

    return (
        <section className="beneficiary-section">
            <LeftRightAlertBanner
                showAlert={showAlert}
                leftText={"Beneficiary Details"}
                rightText={`${totalBeneficiaryAllocation.toFixed(2)}%`}
            />
            <UnderlinedHeader
                primaryText="Beneficiary Details"
                rightAlignedContent={<Button
                    data-testid="beneficiary-button"
                    onClick={onAdd}
                    icon="left"
                    iconName="add"
                    kind="borderless"
                    disabled={!beneficiaryMembers.length || isProfileWithProposalsOrArchived}
                >Add Beneficiary</Button>}
            />

            <div className="beneficiary-section-table">
                <div className="beneficiary-section-header" role="row">
                    <div>NAME</div>
                    <div>% ALLOCATED</div>
                </div>
                {members.length && beneficiaries.map((beneficiary, index) => {
                    return <div className="beneficiary-table-row" key={index}>
                        <div role={"row"} className="beneficiary-table-sub-row">
                            <BeneficiaryDropdown
                                data-testid={`beneficiaries-dropdown-${index}`}
                                value={beneficiary.memberId || beneficiary.entityName}
                                members={eligibleBeneficiaries(beneficiary)}
                                onChange={(e) => onMemberChange(e, index)}
                                disabled={isProfileWithProposalsOrArchived}
                            />
                            <PercentInput
                                label={`Beneficiary Allocation Percentage ${index}`}
                                value={beneficiary.percentage}
                                defaultValue={"0"}
                                hideInlineLabel
                                hideLabel
                                size="medium"
                                textAlign="right"
                                minValue={0}
                                maxValue={100}
                                onChange={(e) => handlePercentChange(e, index)}
                                disabled={isProfileWithProposalsOrArchived}
                            />
                            <Button
                                className="beneficiary-table-sub-row delete"
                                destructive
                                icon="left"
                                iconName="close"
                                aria-label={`delete beneficiary`}
                                kind="borderless"
                                onClick={() => handleDelete(index)}
                                disabled={isProfileWithProposalsOrArchived}
                            />
                        </div>
                        <Placeholder/>
                    </div>
                })}
                {beneficiaries.length > 0 && <div className="total-owned-container" role={"row"}>
                    <div className="fontweight-medium paddingleft-lg">
                        Total % Allocated
                    </div>
                    <div className={`text-center total-allocation`} data-testid={"beneficiary-total-allocation"}>
                        {showAlert &&
                            <Icon className="beneficiary-allocation-info-icon" name="status_warning"
                                  ariaHidden={false}
                                  ariaLabel="Invalid beneficiary allocation"
                                  type={"warning"}/>}
                        <span className={`${showAlert ? 'allocation-warning' : ''}`}>
                            {totalBeneficiaryAllocation.toFixed(2)}
                        </span>
                    </div>
                </div>}
            </div>
        </section>
    )

}

export default BeneficiariesForm;
