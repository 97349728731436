import camelcase from "camelcase";

export const sanitize = (value: string) => value.replace(/[^0-9.-]/g, '');
export const sanitizeStrings = (value: string) => value.replace(/[0-9.-]/g, '');

export const camelize = (str: string) => camelcase(str);

export const kebabCase = (s: string) => s.replace(/([a-z])([A-Z])/g, "$1-$2").replace(/[\s_]+/g, '-').toLowerCase();

export const capitalizeFirstLetter = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

type NullableString = string | null | undefined;

export const caseInsensitiveEquals = (str1: NullableString, str2: NullableString) => {
    return (str1 ? str1.toUpperCase() : '') === (str2 ? str2.toUpperCase() : '');
}

export const isEmpty = (value: NullableString) => {
    return value === '' || value === undefined || value === null;
}

export const safeParseFloat = (value: string) => {
    return !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
}

export const validateInputRemovingSpaces = (value: string) => {
    return value.replace(/\s/g, '') != '' ? value : null
}