import React from "react";
import {MemberType} from "../../ClientManagement/models/MemberType";
import {AccordionItem} from "../../components";
import {formatCurrency} from "../../utils/format";
import {BeneficiaryGoals} from "../models/GoalModelType";
import BeneficiaryGoalsTable from "../components/BeneficiaryGoalsTable";
import {InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import classNames from "classnames";
import {FamilyGoalType} from "../models/FamilyGoalType";
import {FamilyAssets} from "../../Assets/models/FamilyAssets";
import FamilyGoalUtils from "../Summary/FamilyGoalUtils";
import {GoalSubType} from "../models/GoalType";

type  BeneficiaryGoalsAccordionItemProps = {
    memberSelected: MemberType,
    beneficiaryGoals: BeneficiaryGoals,
    investorGroup: InvestorGroupType,
    onEditGoal: (selectedGoalType: string, savedGoal: FamilyGoalType) => void,
    onApplyFamilyAssets: (goal: FamilyGoalType, assetAmountToApply: number, selectedGoalType?: GoalSubType) => void,
    familyAssets: FamilyAssets,
    availableAssets: number
}

export const BeneficiaryGoalsAccordionItem = ({
                                                  memberSelected,
                                                  beneficiaryGoals,
                                                  investorGroup,
                                                  onEditGoal,
                                                  onApplyFamilyAssets,
                                                  familyAssets,
                                                  availableAssets
                                              }: BeneficiaryGoalsAccordionItemProps) => (
    <AccordionItem
        uuid="FamilyGoal"
        accentColor="#FFC24B"
        expandable={false}
        HeaderComponent={() => {

            const totalPresentValue = FamilyGoalUtils.getTotalGoalsPresentValue(beneficiaryGoals);
            const totalFamilyAssets = FamilyGoalUtils.getTotalFamilyAssets(beneficiaryGoals);
            const totalMyResponsibility = FamilyGoalUtils.getTotalMyResponsibility(beneficiaryGoals);

            return <div className={classNames("accordion-header-content family-goals-accordion-header-grid")}
                        data-testid="family-goal-accordion-item">
                 <span className={`paddingleft-md display-flex align-items-center grid-span-3`}>
                     <span className="paddingleft-md">
                       <div className="h4 fontweight-normal margin-none">{`${memberSelected.firstName}'s Goals`}</div>
                     </span>
                 </span>
                <span className="font-lg textalign-right" data-testid="family-goal-present-value">
                    {formatCurrency(totalPresentValue)}
                </span>
                <span className="font-lg textalign-right" data-testid="family-goal-family-assets">
                    {formatCurrency(totalFamilyAssets)}
                </span>
                <span className="font-lg textalign-right" data-testid="family-goal-my-responsibility">
                    {formatCurrency(totalMyResponsibility)}
                </span>
            </div>
        }}
    >
        <BeneficiaryGoalsTable
            beneficiaryGoals={Object.values(beneficiaryGoals.goals)}
            investorGroup={investorGroup}
            onEditGoal={onEditGoal}
            onApplyFamilyAssets={onApplyFamilyAssets}
            showApplyFamilyAssets={true}
            familyAssets={familyAssets}
            availableAssets={availableAssets}
        />
    </AccordionItem>
)
