import {useAppSelector} from "../../store/hooks";
import {BeneficiaryGoals, GoalModelType} from "../models/GoalModelType";
import {selectGoalModel} from "../controller/GoalsModelSlice";
import {AccordionItem,} from "../../components";
import {formatCurrency} from "../../utils/format";
import React from "react";
import FamilyGoalUtils from '../Summary/FamilyGoalUtils'
import FamilyGoalsSummaryItems from "./FamilyGoalsSummaryItems";
import {GoalType} from "../models/GoalType";
import {FamilyGoalType} from "../models/FamilyGoalType";
import {MemberType} from "../../ClientManagement/models/MemberType";


export function FamilySummaryColumnHeader() {
    return <div className="ReactTable table" style={{height: "auto"}}
                data-testid="static-family-goal-summary-tab-header">
        <div className="rt-table" role="table">
            <div className="rt-thead -header">
                <div className="rt-tr table__row--large" role="row">
                    <div role="cell" className="rt-th"
                         style={{flex: "30 0 auto", width: "30px", maxWidth: "30px"}}>
                        <div className=""></div>
                    </div>
                    <div role="cell" className="rt-th"
                         style={{flex: "100 0 auto", width: "100px"}}>
                        <div className="">Name</div>
                    </div>
                    <div role="cell" className="rt-th display-flex justify-content-end"
                         style={{
                             flex: "100 0 auto",
                             width: "100px",
                             maxWidth: "128px"
                         }}>
                        <div className="">My Responsibility</div>
                    </div>
                    <div role="cell" className="rt-th"
                         style={{flex: "30 0 auto", width: "30px", maxWidth: "30px"}}>
                        <div className=""></div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

type SummaryFamilyGoalListProps = {
    familyGoals: { [key: string]: BeneficiaryGoals },
    onEditGoal: (selectedGoalType: string, savedGoal: FamilyGoalType) => void,
    onViewGoal: (memberSelected: MemberType) => void
};

const SummaryFamilyGoalList = ({
                                   familyGoals,
                                   onEditGoal,
                                   onViewGoal
                               }: SummaryFamilyGoalListProps) => {
    const {
        investorGroup
    } = useAppSelector<GoalModelType>(selectGoalModel);

    return (
        <>
            <section>
                <div className="accordion-tables" data-testid={`${GoalType.FAMILY}-goal-list`}>
                    <AccordionItem
                        uuid="Family Goals"
                        accentColor="#FFC24B"
                        primaryText="Family Goals"
                        rightAlignedContent={
                            <div
                                className="display-flex align-items-center"
                                data-testid="family-goal-accordion-item"
                            >
                                <span className="font-lg paddingright-xl marginright-lg">
                                    {formatCurrency(FamilyGoalUtils.getCumulativeTotalMyResponsibility(familyGoals))}
                                </span>
                            </div>
                        }
                    >
                        <FamilySummaryColumnHeader/>
                        <FamilyGoalsSummaryItems
                            familyGoals={familyGoals}
                            investorGroup={investorGroup}
                            onEditGoal={onEditGoal}
                            onViewGoal={onViewGoal}
                        />
                    </AccordionItem>
                </div>
            </section>
        </>
    )
}

export default SummaryFamilyGoalList;
