import React from "react";
import {
    AssetClassSummaryRow,
    AssetSubclassSummary,
    AssetSubclassSummaryRow,
    CurrentVsProposedTableHeader
} from "../../../ClientManagement/AssetAllocation/AssetHoldings";
import {Row} from "react-grid-system";
import {Accordion, AccordionItem} from "../../../components";
import AssetHoldingHeader from "../../../ClientManagement/AssetAllocation/AssetHoldingHeader";
import {
    getDifference, getDifferenceOfPortfolio, getProposedInvestiblePercent,
    getTotalInvestibleAmount, getTotalInvestiblePercent,
    getTotalInvestiblePercentForClassification, getTotalInvestiblePercentForClassificationNoFormat,
    getTotalProposedInvestibleAmount
} from "../../../ClientManagement/AssetAllocation/assetAllocationUtils";
import {AssetClassifications} from "../../../Assets/models/AssetClassifications";


type AssetSubclassSummariesProps = {
    assetSubclassSummaries: AssetSubclassSummary[],
    classifications: AssetClassifications,
    assetClassName: string,
    proposedTotalInvestableAmount: number,
    currentTotalInvestableAmount: number
}


const AssetSubclassSummariesReport: React.FC<AssetSubclassSummariesProps> = ({ assetSubclassSummaries, classifications, assetClassName, proposedTotalInvestableAmount, currentTotalInvestableAmount }) => {
    let assetClassNames: string[] = [];
    if (assetClassName === "Risk Assets") {
        classifications.riskAssetClasses.forEach(riskAsset => assetClassNames.push(riskAsset.assetClassName))
    } else {
        classifications.riskControlAssetClasses.forEach(riskControlAsset => assetClassNames.push(riskControlAsset.assetClassName))
    }
    return (
        <div className='asset-subclass-summary-container'>
            {assetSubclassSummaries.map(({subclass, current, proposed}) =>

                assetClassNames.find(assetClass => (assetClass === subclass && current && current.assetClassName === "")) === undefined ?

                    <AssetSubclassSummaryRow key={subclass}
                                             name={subclass}
                                             totalInvestableAmount={getTotalInvestibleAmount(current)}
                                             totalInvestablePercent={getTotalInvestiblePercentForClassification(current?.currentInvestableAmount, currentTotalInvestableAmount)}
                                             totalInvestableAmountProposed={getTotalProposedInvestibleAmount(proposed)}
                                             totalInvestablePercentProposed={getTotalInvestiblePercentForClassification(proposed?.proposedInvestableAmount, proposedTotalInvestableAmount)}
                                             allocationDifference={getDifferenceOfPortfolio(
                                                 current,
                                                 proposed,
                                                 getTotalInvestiblePercentForClassificationNoFormat(proposed?.proposedInvestableAmount, proposedTotalInvestableAmount),
                                                 getTotalInvestiblePercentForClassificationNoFormat(current?.currentInvestableAmount, currentTotalInvestableAmount)
                                             )}
                    /> :
                    <AssetClassSummaryRow key={subclass+"_parentClass"}
                                          name={subclass}
                                          totalInvestableAmount={getTotalInvestibleAmount(current)}
                                          totalInvestablePercent={getTotalInvestiblePercent(current)}
                                          totalInvestableAmountProposed={getTotalProposedInvestibleAmount(proposed)}
                                          totalInvestablePercentProposed={getProposedInvestiblePercent(proposed)}
                                          allocationDifference={getDifference(current, proposed)}
                    />
            )}
        </div>
    )
};

type AssetHoldingsReportProps = {
    id: string,
    assetClassName: string,
    total: number,
    totalProposed?: number,
    totalPercentage: number,
    totalProposedPercentage?: number,
    accentColor: string,
    assetSubclassSummaries: AssetSubclassSummary[],
    continueLabel: string,
    classifications: AssetClassifications,
    isDetailedReport: boolean,
    totalProposedInvestableAmount: number,
    totalCurrentInvestableAmount: number
}
const AssetHoldingsReport: React.FC<AssetHoldingsReportProps> = ({
                                                                     id,
                                                                     assetClassName,
                                                                     total,
                                                                     totalProposed,
                                                                     totalPercentage,
                                                                     totalProposedPercentage,
                                                                     assetSubclassSummaries,
                                                                     accentColor,
                                                                     continueLabel,
                                                                     classifications,
                                                                     isDetailedReport,
                                                                     totalProposedInvestableAmount,
                                                                     totalCurrentInvestableAmount
                                                                 }) => {
    return (
        <>
            <Row className={"row"} data-testid="asset-header-text">
                <CurrentVsProposedTableHeader allocationType={assetClassName.toUpperCase()}/>
            </Row>
            <Row className={"row"}>
                <Accordion accordionId={id}
                           className="asset-stack-table-accordion"
                           expanded={isDetailedReport ? [assetClassName] : []}>
                    <AccordionItem
                        uuid={assetClassName}
                        primaryText={assetClassName}
                        accentColor={accentColor}
                        HeaderComponent={({expanded}) => <AssetHoldingHeader
                            expanded={expanded}
                            title={continueLabel}
                            total={total}
                            totalProposed={totalProposed}
                            totalPercentage={totalPercentage}
                            totalProposedPercentage={totalProposedPercentage}
                            showChevron={true}/>}>
                        <AssetSubclassSummariesReport assetSubclassSummaries={assetSubclassSummaries}
                                                      classifications={classifications}
                                                      assetClassName={assetClassName}
                                                      proposedTotalInvestableAmount={totalProposedInvestableAmount}
                                                      currentTotalInvestableAmount={totalCurrentInvestableAmount}
                        />
                    </AccordionItem>
                </Accordion>
            </Row>
        </>);
}


export default AssetHoldingsReport;