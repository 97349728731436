import React from 'react';
import {useAppSelector} from "../../store/hooks";
import GoalBarChartSideBar from "./GoalBarChartSidebar";
import {selectGoalModel} from "../controller/GoalsModelSlice";
import {GoalModelType} from "../models/GoalModelType";

function SummaryBarChartSideBar({pageIndex}: { pageIndex?: number }) {

    const {
        investorGroup,
    } = useAppSelector<GoalModelType>(selectGoalModel)

    return (<GoalBarChartSideBar emptyTotalGoalsAndTaxLiabilities={"__"} pageIndex={pageIndex}>
        <>
            <span className="condensed-subtitle xxxl">PLANNING PERIOD</span>
            <div
                className="h1 margintop-sm">{investorGroup.planningPeriod.numberOfYears} years
            </div>
        </>
    </GoalBarChartSideBar>);
}

export default SummaryBarChartSideBar;
