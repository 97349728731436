import React from "react";
import {Button, Icon} from "../../../components";
import {SaveAction, selectEditMemberType, selectFamilyTree} from "../FamilyTreeSlice";
import {FamilyRelationshipType} from "../../models/FamilyRelationshipType";
import {useAppSelector} from "../../../store/hooks";
import {FamilyMode} from "./FamilyMode";
import {findExtendedParents, isPartnerRelationship} from "../../FamilyRelationshipService";
import useProfileEditableState from "../../../hooks/useProfileEditableState";

type DeleteMemberFooterProps = {
    handleDeleteButton: () => void,
    isMemberAssetOwner: boolean,
    familyMode: FamilyMode,
    saveAction: SaveAction,
}

const DeleteMemberFooter: React.FC<DeleteMemberFooterProps> = ({
                                                                   handleDeleteButton,
                                                                   isMemberAssetOwner,
                                                                   familyMode,
                                                                   saveAction
                                                               }) => {
    const familyTree = useAppSelector(selectFamilyTree);
    const memberToEdit = useAppSelector(selectEditMemberType);
    const isPrimaryContact = memberToEdit?.id === familyTree?.primaryContact?.id;
    const showDeleteButton = saveAction === SaveAction.Edit && !isPrimaryContact
    const isMemberWithChild = saveAction === SaveAction.Edit && !!(memberToEdit?.family?.find(relationship => relationship.type === FamilyRelationshipType.CHILD));
    const isMemberWithPartner = saveAction === SaveAction.Edit && !!(memberToEdit?.family?.find(relationship => (isPartnerRelationship(relationship.type))));
    const isMemberWithParent = saveAction === SaveAction.Edit && findExtendedParents(memberToEdit).length > 0;
    const memberCannotBeDeleted = isPrimaryContact || isMemberWithChild || isMemberAssetOwner || isMemberWithPartner || isMemberWithParent;
    const cannotBeDeletedMessageToDisplay = () => {
        if (isPrimaryContact) return 'This client cannot be deleted from the family tree, moved to other members, or have life status changed.';
        else if (isMemberAssetOwner && familyMode == FamilyMode.OTHER) return 'Other members with asset ownership cannot be deleted from other members, moved to the immediate family tree, or have life status changed.';
        else if (isMemberAssetOwner) return 'Family members with asset ownership cannot be deleted from the family tree, moved to other members, or have life status changed.';
        else if (isMemberWithChild || isMemberWithPartner || isMemberWithParent) return 'Family members that are the only connection point for one or one members on the tree cannot be deleted.';
        else return '';
    }
    const {isArchiveReadOnly, isProfileWithProposalsOrArchived} = useProfileEditableState();

    return (
        <div className="footer">
            {memberCannotBeDeleted &&
                <div className="edit-family-member-footer-message">
                    <Icon className="info-icon" ariaHidden name="status_info_outline" type="info"/>
                    <p>{cannotBeDeletedMessageToDisplay()}</p>
                </div>}
            {showDeleteButton &&
                <div className="edit-family-member-footer">
                    <Button
                        ariaLabel="delete"
                        icon="left"
                        iconName="delete"
                        size="medium"
                        kind="borderless"
                        noPadding
                        destructive
                        onClick={handleDeleteButton}
                        disabled={memberCannotBeDeleted || isArchiveReadOnly || isProfileWithProposalsOrArchived}
                    >
                        Delete
                    </Button>
                </div>}
        </div>
    )
}

export default DeleteMemberFooter;