import Highcharts, {SeriesOptionsType} from "highcharts";
import {GoalFundingResponse} from "./models/Funding";
import {shortenName} from "../../Assets/common/textUtils";

export type FundingGraphProps = {
    displayName: string;
    fundingAge: number;
    maxAge: number;
    startAge: number;
    fundingInfo: GoalFundingResponse;
    handleClickPointer: (fundingAge: number) => void;
    isEmpty: boolean;
    isShortfall: boolean;
    functionCallWhenGraphIsLoaded?: () => void,
    isFunctionUsedForReportGeneration?: boolean
}

export const CreateFundingGraphOptions: (props: FundingGraphProps) => Highcharts.Options
    = (props: FundingGraphProps) => {
    let {displayName, fundingAge, maxAge, startAge, fundingInfo, handleClickPointer, isEmpty, isShortfall, functionCallWhenGraphIsLoaded,isFunctionUsedForReportGeneration= false} = props;

    const getRARCAPercentage = (ageIndex: number, type: string) => {
        return (type === 'RA' ?
            fundingInfo.fundedByRiskAssetsPercentageAtYear?.[ageIndex] :
            fundingInfo.fundedByRiskControlPercentageAtYear?.[ageIndex]);
    }

    const getRARCAValue = (ageIndex: number, type: string) => {
        return (type === 'RA' ?
            fundingInfo.fundedByRiskAssetsAtYear?.[ageIndex] :
            fundingInfo.fundedByRiskControlAtYear?.[ageIndex]);
    }

    const getRAValues = (info: GoalFundingResponse) => {
        let raValues = info.fundedByRiskAssetsAtYear;
        let excessValues = info.investableExcess.fundedByPortfolioAtYear;
        if (raValues && excessValues != null && excessValues?.length != 0) {
            return raValues.map((value, index) => value - excessValues![index]);
        }
        else {
            return raValues;
        }
    }

    return {
        chart: {
            type: 'area',
            marginRight: 50,
            marginLeft: 50,
            events: {
                click: function (event) {
                    // @ts-ignore
                    let age: number = Math.round(event.xAxis[0].value);
                    handleClickPointer(age);
                },
                load: function () {
                        if (isFunctionUsedForReportGeneration && functionCallWhenGraphIsLoaded) {
                            functionCallWhenGraphIsLoaded()!;
                        }
                }
            },
            backgroundColor: "transparent"
        },
        title: {
            text: "Asset Allocation and Excess, by " + shortenName(displayName,50) + "'s age",
            align: 'left',
            x: 40,
            y: 42,
            style: {
                fontSize: '15px',
                fontFamily: 'RobotoCondensed',
                fontWeight: '400',
                color: '#6B6E6F',
                display: 'flex',
                justifyContent: 'space-between',
                textTransform: 'uppercase'
            }
        },
        subtitle: {
            text: `${maxAge - startAge} year planning period`,
            align: 'right',
            x: -40,
            y: 42,
            style: {
                fontSize: '15px',
                fontFamily: 'RobotoCondensed',
                fontWeight: '400',
                color: '#6B6E6F',
                display: 'flex',
                justifyContent: 'space-between',
                textTransform: 'uppercase'
            }
        },
        accessibility: {
            enabled: true
        },
        legend: {
            borderWidth: 0,
            align: 'right',
            itemStyle: {
                color: '#000000',
                fontWeight: '500',
                fontSize: '13px',
                fontFamily: 'Roboto'
            },
            x: -40,
            labelFormatter: function () {
                return getLabelName(this.name, isShortfall);
            },
            enabled: !isEmpty
        },
        credits: {
            enabled: false
        },
        xAxis: {
            type: 'linear',
            tickWidth: 0,
            lineColor: '#EAEAEA',
            gridLineWidth: 1,
            gridLineColor: '#CECECE',
            gridZIndex: 5,
            labels: {
                distance: 15,
                style: {
                    fontSize: '13px',
                    fontFamily: 'RobotoCondensed',
                    fontWeight: '400',
                    color: '#6B6E6F',
                    textAlign: 'center'
                }
            },
            minPadding: 0,
            maxPadding: 0,
            min: startAge,
            max: maxAge,
            plotLines: getPlotLines(fundingAge ?? 0),
        },
        yAxis: {
            title: {
                text: ''
            },
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            labels: {
                enabled: false
            },
            showFirstLabel: false,
        },
        tooltip: {
            formatter(this: Highcharts.TooltipFormatterContextObject) {
                const points = this.points;

                let fundedMessage: string = '';

                let tooltip = '<span style="font-family: Roboto, sans-serif; font-weight: 800; color: #085459;"><b>AGE ' + this.key + '</b></span>' +
                    '<table>';

                if (points) {
                    fundedMessage = getTooltipFooterMessage(points[0].point.index,fundingInfo);

                    points.forEach((point: Highcharts.TooltipFormatterContextObject) => {
                        switch (point.series.name) {
                            case 'Excess':
                                return;
                            case 'RA':
                                tooltip += getTooltip(point, getRARCAPercentage(point.point.index, 'RA') + '%', getRARCAValue(point.point.index, 'RA'));
                                tooltip += getExcessTooltip(points);
                                break;
                            case 'RCA':
                                tooltip += getTooltip(point, getRARCAPercentage(point.point.index, 'RCA') + '%', getRARCAValue(point.point.index, 'RCA'));
                                break;
                            default:
                                break;
                        }
                    });
                }
                tooltip += '</table>';

                if (fundedMessage) {
                    tooltip += '<hr style="width: 105%;margin-left: -6px"/> ' +
                        '<div style="padding: 8px; text-align: left;font-family: Roboto, sans-serif;font-size: 14px; color:#3D4042"><i>' + fundedMessage + '</i></div>';
                }

                return tooltip;
            },
            useHTML: true,
            shared: true,
            enabled: !isEmpty,
            style: {
                width: 220
            }
        },
        plotOptions: {
            series: {
                pointStart: startAge,
                states: {
                    hover: {
                        enabled: false
                    }
                },
                cursor: 'pointer',
                events: {
                    legendItemClick: (event) => event.preventDefault()
                }
            },
            area: {
                stacking: 'percent',
                marker: {
                    enabled: false
                }
            }
        },
        series: isEmpty ? [
            {
                name: 'No Data',
                data: Array.from({length: maxAge - startAge + 1}, () => 1),
                color: "#D9D9D9",
                fillColor: "#D9D9D9",
                fillOpacity: 1,
                threshold: null,
                legendIndex: 1
            } as SeriesOptionsType,
            {
                name: 'No Data2',
                data: Array.from({length: maxAge - startAge + 1}, () => 0),
                color: "#D9D9D9",
                fillColor: "#D9D9D9",
                fillOpacity: 1,
                threshold: null,
                legendIndex: 1
            } as SeriesOptionsType
        ] : [
            {
                name: 'Excess',
                data: fundingInfo.investableExcess.fundedByPortfolioAtYear,
                color: isShortfall ? "#B1B1B1" : "#7DE0E6",
                fillColor: "#7DE0E6",
                fillOpacity: 1,
                threshold: null,
                legendIndex: 1
            } as SeriesOptionsType,
            {
                name: 'RA',
                data: getRAValues(fundingInfo),
                color: "#00A0AA",
                fillColor: "#00A0AA",
                fillOpacity: 1,
                threshold: null,
                legendIndex: 0
            } as SeriesOptionsType,
            {
                name: 'RCA',
                data: fundingInfo.fundedByRiskControlAtYear,
                color: "#104866",
                fillColor: "#104866",
                fillOpacity: 1,
                threshold: null,
                legendIndex: 2,
            } as SeriesOptionsType
        ]
    }
}

const getPlotLines: (age: number) => Highcharts.XAxisPlotLinesOptions[] = (age: number) => {
    const horizontalOffsetToCenterLabel: number = (Math.log10(age) + 1) * -0.5;
    const horizontalRotationAngle: number = 0;
    return [{
        value: age,
        width: 5,
        color: 'white',
        zIndex: 10,
        label: {
            align: 'center',
            textAlign: 'center',
            color: '#0A7A82',
            rotation: horizontalRotationAngle,
            useHTML: true,
            y: 125,
            x: horizontalOffsetToCenterLabel,
            formatter: function () {
                return `<div class="funding-chart-plot-line">${age}</div>`;
            },
        },
    }];
}

const getTooltip = (point: Highcharts.TooltipFormatterContextObject, displayPercentage: string, value?: number) => {
    return '<tr><td style="color:' + point.color + '; fill:' + point.color + '; font-family: Roboto, sans-serif; font-size: 20px; padding-right:1px"><b>\u25CF</b></td>' +
        '<td style="font-family: Roboto, sans-serif; font-weight:400; color:#3D4042; padding-right: 7px; text-align: center">' + point.series.name + '</td>' +
        '<td style="font-family: Roboto, sans-serif; font-weight:400; color:#3D4042; padding-right: 7px; text-align: center">' + displayPercentage + '</td>' +
        '<td style="font-family: Roboto, sans-serif; font-weight:400; color:#3D4042; text-align: left">' +
        value?.toLocaleString('en-US', {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0
        }) + '</td>' +
        '</tr>'
}

const getTooltipFooterMessage = (ageIndex: number,fundingInfo: GoalFundingResponse) => {
    let message: string = '';
    if (fundingInfo.fundedByInflowsAtYear?.[ageIndex] && fundingInfo.fundedByNonInvestableAtYear?.[ageIndex]) {
        message = 'One or more goals are funded by inflows & non-investable assets.';
    } else if (fundingInfo.fundedByInflowsAtYear?.[ageIndex]) {
        message = 'One or more goals are funded by inflows.';
    } else if (fundingInfo.fundedByNonInvestableAtYear?.[ageIndex]) {
        message = 'One or more goals are funded by non-investable assets.';
    }
    return message
}

const getLabelName = (name: string, isShortfall: boolean) => {
    let n: string = "";
    switch (name) {
        case 'Excess':
            if(isShortfall) {
                n = '<span style="color: #B1B1B1;"> Investable Excess </span>'
            }
            else {
                n = 'Investable Excess'
            }
            break;
        case 'RA':
            n = 'Risk Assets'
            break;
        case 'RCA':
            n = 'Risk Control'
            break;
        default:
            break;
    }
    return n;
}

const getExcessTooltip = (points: Highcharts.TooltipFormatterContextObject[]) => {
    let excessTag = points[0].y == 0 ? 'Shortfall' : 'Excess';
    let excessColor = excessTag == 'Shortfall' ? '#BF5E00' : '#6B6E6F';
    let excessContent = excessTag == 'Shortfall' ? '-' : points[0].y?.toLocaleString('en-US', {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
    }) ;
    return '<tr>' +
        '<td><span style="position: absolute;border-left: 2px solid #eaeaea;border-bottom: 2px solid #eaeaea;' +
        'width: 24px;height: 23px;border-bottom-left-radius: 5px;top: 38px;left: 8px"></span></td>' +
        '<td style="color:' + points[0].color + '; fill:' + points[0].color + '; font-family: Roboto, sans-serif; font-size: 20px; padding-right: 1px"><b>\u25CF</b></td>' +
        '<td style="font-family: Roboto, sans-serif; font-weight:400; color:' +  excessColor + '; padding-right: 7px; text-align: left; font-size: 12px">' + excessTag + '</td>' +
        '<td style="font-family: Roboto, sans-serif; font-weight:400; color:#6B6E6F; text-align: left; font-size: 12px">' + excessContent + '</td>' +
        '</tr>';
}
