import React from 'react';

type HeaderIconProps = {
    title: string,
    sortType: string
}

const HeaderIcon: React.FC<HeaderIconProps> = ({title, sortType}) => {
    if (sortType.includes('ascending')) {
        return (
            <span>
                {title}
                <i
                    className="dds-icons icon rt-resizable-header-icon header-icon"
                    aria-hidden="false"
                    aria-label="sorted in ascending order."
                >
                    arrow_upward
                </i>
            </span>
        )
    }
    if (sortType.includes('descending')) {
        return (
            <span>
                {title}
                <i
                    className="dds-icons icon rt-resizable-header-icon header-icon"
                    aria-hidden="false"
                    aria-label="sorted in descending order."
                >
                    arrow_downward
                </i>
            </span>
        )
    }
    return (
        <span>{title}</span>
    )
};

export default HeaderIcon;
