import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../store/hooks";
import {selectGoalModel} from "../../../Goals/controller/GoalsModelSlice";
import {BeneficiaryGoals, GoalModelType} from "../../../Goals/models/GoalModelType";
import SummaryBarChartSideBar from "../../../Goals/BarChartSidebar/SummaryBarChartSideBar";
import NoGoalsLandingPage from "../../../Goals/Summary/NoGoalsLandingPage";
import GoalListReport from "./GoalListReport";
import {splitGoalsSummaryData} from "./reportUtil";
import PrintViewWrapper from "../PrintViewWrapper";
import {NonLifestyleGoalType} from "../../../Goals/models/GoalType";
import {
    makeNonLifestyleGoalListTableRowData,
    NonLifestyleGoalRow
} from "../../../Goals/components/NonLifestyleGoalsTable";
import moment from "moment";
import {FamilyGoalType} from "../../../Goals/models/FamilyGoalType";

interface GoalsSummaryWrapperProps {
    goalsSummary: GoalModelType,
    displayName: string
}

const GoalsSummaryWrapper = ({goalsSummary, displayName}: GoalsSummaryWrapperProps) => {
    const {
        lifestyleSpendingGoal,
        familyGoals,
        discretionaryGoals,
        philanthropicGoals
    } = goalsSummary

    const {investorGroup} = useAppSelector(selectGoalModel)
    let sortedDiscretionaryGoals : NonLifestyleGoalType[] = [];
    let sortedPhilanthropicGoals : NonLifestyleGoalType[] = [];
    const discretionaryGoalsRowList : NonLifestyleGoalRow[] = makeNonLifestyleGoalListTableRowData(discretionaryGoals,investorGroup)
    const philanthropicGoalsRowList : NonLifestyleGoalRow[] = makeNonLifestyleGoalListTableRowData(philanthropicGoals,investorGroup)
    discretionaryGoalsRowList.forEach(ele => {
        sortedDiscretionaryGoals.push(discretionaryGoals.find(ele1 => ((ele1.userInputs.description === ele.name) && (ele1.calculatedFields.presentValue === ele.presentValue)))!)
    })

    philanthropicGoalsRowList.forEach(ele => {
        sortedPhilanthropicGoals.push(philanthropicGoals.find(ele1 => ((ele1.userInputs.description === ele.name) && (ele1.calculatedFields.presentValue === ele.presentValue)))!)
    })
    let sortedFamilyGoals : { [p: string]: BeneficiaryGoals } = sortFamilyMemberGoals(goalsSummary.familyGoals);

    const clientHasFamilyGoals = !!familyGoals && Object.values(familyGoals).length > 0;
    const clientHasLifestyleGoals = !!lifestyleSpendingGoal.id;
    const clientHasNonLifestyleGoals = [...discretionaryGoals, ...philanthropicGoals].some(nonLifestyleGoal => !!nonLifestyleGoal.id);

    const clientHasSavedGoals = clientHasLifestyleGoals || clientHasFamilyGoals || clientHasNonLifestyleGoals;

    const [splittedGoalsSummary, setSplittedGoalsSummary] = useState<Array<GoalModelType>>([]);


    function compareValues(firstValue: number, secondValue: number) {
        return Math.sign(firstValue - secondValue);
    }

    function isFirstGoalBeforeSecond(firstGoal: FamilyGoalType, secondGoal: FamilyGoalType) {
        const firstGoalYUF = moment(firstGoal.userInputs.startDate).year() - moment().year();
        const firstGoalYOF = moment(firstGoal.userInputs.endDate).year() - moment(firstGoal.userInputs.startDate).year();
        const firstGoalSumOfYufAndYof = firstGoalYOF + firstGoalYUF;
        const secondGoalYUF = moment(secondGoal.userInputs.startDate).year() - moment().year();
        const secondGoalYOF = moment(secondGoal.userInputs.endDate).year() - moment(secondGoal.userInputs.startDate).year();
        const secondGoalSumOfYufAndYof = secondGoalYOF + secondGoalYUF;
        const secondGoalReponsibility = secondGoal.calculatedFields.presentValue ;
        const firstGoalResponsibility = firstGoal.calculatedFields.presentValue ;

        const isFirstGoalNameBeforeSecondGoal = firstGoal.userInputs.description.localeCompare(secondGoal.userInputs.description);
        return compareValues(firstGoalSumOfYufAndYof, secondGoalSumOfYufAndYof) || compareValues(firstGoalYUF, secondGoalYUF) || compareValues(secondGoalReponsibility, firstGoalResponsibility) || isFirstGoalNameBeforeSecondGoal || 0;
    }

    function sortFamilyMemberGoals(allFamilyGoals: { [p: string]: BeneficiaryGoals }) {
        let sortedBeneficiaryGoals: { [p: string]: BeneficiaryGoals } = JSON.parse(JSON.stringify(allFamilyGoals));

        for (let key in sortedBeneficiaryGoals) {
            let familyGoalsValue: BeneficiaryGoals = allFamilyGoals[key];

            let memberGoal: BeneficiaryGoals = {
                totalPresentValue: familyGoalsValue.totalPresentValue,
                beneficiaryId: familyGoalsValue.beneficiaryId,
                name: familyGoalsValue.name,
                birthdate: familyGoalsValue.birthdate,
                age: familyGoalsValue.age,
                goals: {}
            };

            const sortedGoals = [...Object.keys(familyGoalsValue.goals)].sort((firstGoalKey: string, secondGoalkey: string) => {
                const firstGoal = familyGoalsValue.goals[firstGoalKey];
                const secondGoal = familyGoalsValue.goals[secondGoalkey];
                return isFirstGoalBeforeSecond(firstGoal,secondGoal);
            })
            for (let k = 0; k < sortedGoals.length; k++) {
                memberGoal.goals[sortedGoals[k]] = familyGoalsValue.goals[sortedGoals[k]];
            }
            sortedBeneficiaryGoals[key] = memberGoal;
        }
        return sortedBeneficiaryGoals;

    }

    useEffect(() => {
        const defaultData = {
            ...goalsSummary,
            discretionaryGoals: [],
            philanthropicGoals: [],
            lifestyleSpendingGoal: {},
            familyGoals: {},
            taxLiabilities: {}
        }


        splitGoalsSummaryData({
            ...goalsSummary,
            discretionaryGoals: sortedDiscretionaryGoals,
            philanthropicGoals: sortedPhilanthropicGoals,
            familyGoals:sortedFamilyGoals
        }, defaultData).then((res: Array<GoalModelType>) => {
            setSplittedGoalsSummary(res);
        });
    }, []);

    return (<>
            {splittedGoalsSummary.map((goalsData, index) => {
                return (
                    <PrintViewWrapper displayName={displayName} key={index}>
                        <div className="goals-summary-container layout-split-sidebar">
                            <SummaryBarChartSideBar pageIndex={index}/>
                            {clientHasSavedGoals ? <GoalListReport goalModel={goalsData} pageNo={index} allGoalsModel={goalsSummary}
                                                                   goalsSummaryList={splittedGoalsSummary}/> :
                                <NoGoalsLandingPage/>}
                        </div>
                    </PrintViewWrapper>
                )
            })}
        </>
    )
}

type goalsSummaryContainerProps = {
    displayName: string
}

const GoalsSummaryContainer = ({displayName}: goalsSummaryContainerProps) => {

    const goalModel = useAppSelector<GoalModelType>(selectGoalModel);
    return <GoalsSummaryWrapper goalsSummary={goalModel} displayName={displayName}/>
}

export default GoalsSummaryContainer;
