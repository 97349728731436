import {AssetsSummary, CurrentNetWorthAsset} from "../../models/Assets";
import {COLOR_PERSONAL_ASSETS} from "../../../constants/colors";
import {AssetAccordionHeader} from "../../AssetSummary/common/AssetAccordionHeader";
import {AccordionItemWithActionMenu} from "../../../components";
import React, {useContext} from "react";
import {assetListData} from "../../AssetSummary/common/utils";
import {
    calculateCNWAssetsJointlyOwnedTotalPresentValue,
    calculateCNWAssetsMemberOwnedTotalPresentValue,
    calculateJointlyOwnedValue,
    calculateMemberOwnedValue,
    calculatePersonalAssetInEstateTotalValue
} from "../../AssetSummary/common/AssetSummaryCalculator";
import {PersonalAsset} from "../../models/PersonalAsset";
import {TableCell} from "../../../components/Table/TableCell";
import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {AssetAccordionContentHeader} from "../../AssetSummary/common/AssetAccordionContentHeader";
import {PersonalAssetRow} from "../../AssetSummary/InEstate/PersonalAssetRow";
import {showOwnershipType} from "../../Ownership/helpers";
import {getOwnershipDescription} from "./ownershipUtils";
import {useHistory} from "react-router-dom";
import AssetsViewContext from "../../common/AssetsViewContext";
import {DeleteModalProps} from "../../common/AssetsView";
import {shortenName} from "../../common/textUtils";

export interface InEstatePersonalAssetsProps {
    profileId: string,
    assetsData: AssetsSummary;
    investorGroup: InvestorGroupType;
    onRemoveAssetClick: (deleteModalProps: DeleteModalProps) => void;
}

const PersonalAssets = ({profileId, assetsData, investorGroup, onRemoveAssetClick}: InEstatePersonalAssetsProps) => {

    const {
        getFormattedTotalPresentValue,
        getFormattedPresentValueForCurrentNetWorth,
        inEstatePersonalAssets
    } = assetListData(assetsData);

    const history = useHistory();
    const viewType = useContext(AssetsViewContext);

    const currentNetWorthPersonalAssets: CurrentNetWorthAsset[] = inEstatePersonalAssets.map(personalAsset => ({
        id: personalAsset.id,
        name: personalAsset.description,
        presentValue: personalAsset.presentValue,
        assetType: "personalAsset",
        ownershipCategory: personalAsset.ownershipCategory,
        memberOwnerships: personalAsset.memberOwnerships
    }));

    const primaryMember = investorGroup.primaryMember;
    const partnerMember = investorGroup.partnerMember;

    function generateAssetPopOverContent(personalAsset: PersonalAsset) {
        const ownershipDescription = getOwnershipDescription(personalAsset, primaryMember, partnerMember)
        return ownershipDescription ? <>{ownershipDescription}</> : undefined;
    }

    return (
        <>
            {inEstatePersonalAssets.length > 0 && <AccordionItemWithActionMenu
                uuid="PersonalAssets"
                accentColor={COLOR_PERSONAL_ASSETS}
                HeaderComponent={({expanded}) =>
                    <AssetAccordionHeader
                        expanded={expanded}
                        title="Personal Assets"
                        formattedPrimaryMemberTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateCNWAssetsMemberOwnedTotalPresentValue(investorGroup.primaryMember.id, currentNetWorthPersonalAssets)) : undefined}
                        formattedSecondaryMemberTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateCNWAssetsMemberOwnedTotalPresentValue(investorGroup.partnerMember?.id, currentNetWorthPersonalAssets)) : undefined}
                        formattedJointTotalPresentValue={investorGroup.partnerMember ? getFormattedTotalPresentValue(calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthPersonalAssets)) : undefined}
                        formattedTotalPresentValue={getFormattedTotalPresentValue(calculatePersonalAssetInEstateTotalValue(assetsData.personalAssets.data))}
                        gridClassName={"current-net-worth-grid"}/>
                }>
                <div role="table" className="current-net-worth-grid-with-actionmenu assets-grid-table"
                     aria-label="personal-assets-table">
                    <AssetAccordionContentHeader investorGroup={investorGroup}/>

                    {inEstatePersonalAssets.map((personalAsset: PersonalAsset) => {
                        return <PersonalAssetRow personalAsset={personalAsset}
                                                 actionsDisabled={false}
                                                 onClickEdit={(personalAssetId: string) => {
                                                     history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditPersonalAsset/${personalAssetId}`);
                                                 }}
                                                 onClickDelete={(personalAssetDelete: PersonalAsset) => {
                                                     onRemoveAssetClick({
                                                         showDeleteModal: true,
                                                         modalTitle: "Asset",
                                                         assetDescription: personalAssetDelete.description ?? "",
                                                         assetType: "personal asset",
                                                         assetId: personalAssetDelete.id,
                                                     });
                                                 }}
                                                 key={personalAsset.id}
                                                 gridClassName={"current-net-worth-grid-with-actionmenu"}
                                                 renderPersonalAssetDetails={(asset: PersonalAsset) => {
                                                     return <>
                                                         <TableCell text={shortenName(asset.description)}
                                                                    subtext={showOwnershipType(asset.ownershipCategory)}
                                                                    className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}
                                                                    popoverContent={generateAssetPopOverContent(asset)}
                                                                    popoverWidth={"288px"}
                                                         />
                                                         {investorGroup.partnerMember && <>
                                                             <TableCell
                                                                 text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.primaryMember.id, asset.memberOwnerships, asset.ownershipCategory, asset.presentValue))}
                                                                 className="textalign-right"/>
                                                             <TableCell
                                                                 text={getFormattedPresentValueForCurrentNetWorth(calculateMemberOwnedValue(investorGroup.partnerMember.id, asset.memberOwnerships, asset.ownershipCategory, asset.presentValue))}
                                                                 className="textalign-right"/>
                                                             <TableCell
                                                                 text={getFormattedPresentValueForCurrentNetWorth(calculateJointlyOwnedValue(asset.ownershipCategory, asset.presentValue))}
                                                                 className="textalign-right"/>
                                                         </>
                                                         }
                                                     </>
                                                 }}/>;
                    })}
                </div>
            </AccordionItemWithActionMenu>}
        </>
    );

}

export default PersonalAssets;
