import {Button} from "./index";
import React from "react";

interface DeleteButtonProps {
    onDelete: () => void
    className?: string
    disabled?: boolean
}

export const DeleteButton = ({onDelete, className = 'delete', disabled = false, ...rest}: DeleteButtonProps) =>
    <Button
        icon='left'
        iconName="close"
        kind="borderless"
        destructive
        size='medium'
        className={className}
        {...rest}
        onClick={onDelete}
        disabled={disabled}
    />;

