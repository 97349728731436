import React, {useEffect, useState} from 'react';
import {AssetsSummary} from "./models/Assets";
import {AlertBanner} from "../components";

export interface InfoBannerForAccountAndPCRProps {
    assets: AssetsSummary;
}

const InfoBannerForAccountAndPCR = ({
                                        assets
                                    }: InfoBannerForAccountAndPCRProps) => {

    const [showInfoBanner, setShowInfoBanner] = useState<boolean>(false);
    useEffect(() => {
        if (assets.accounts.data.length > 0 && (assets.investmentProgram && assets.investmentProgram.heldAwayAccounts.length > 0)) {
            setShowInfoBanner(true);
        } else {
            setShowInfoBanner(false);
        }
    }, [assets]);

    return <AlertBanner
        className={'marginbottom-lg'}
        fullWidth={false}
        icon="info"
        type="info"
        showAlert={showInfoBanner}
        showCloseBtn={true}
        message={"Aggregated accounts have been added. Please review standalone accounts to ensure duplicates are removed."}
        onClose={() => setShowInfoBanner(false)}
    />
}

export default InfoBannerForAccountAndPCR;