import classNames from "classnames";
import React, {ReactNode} from "react";
import {Button} from "../Button";
import {shortenName} from "../../Assets/common/textUtils";

type DataEntryHeaderProps = {
    className?: string;
    title?: string;
    SubtitleComponent?: () => ReactNode;
    primaryButtonText?: string;
    secondaryButtonText?: string;
    tertiaryButtonText?: string;
    hidePrimaryButton?: boolean;
    hideSecondaryButton?: boolean;
    hideTertiaryButton?: boolean;
    showCloseButton?: boolean;
    showRefreshButton?: boolean;
    disablePrimaryButton?: boolean;
    disableSecondaryButton?: boolean;
    disableTertiaryButton?: boolean;
    onPrimaryButtonClick?: () => void;
    onSecondaryButtonClick?: () => void;
    onTertiaryButtonClick?: () => void;
    onCloseButtonClick?: () => void;
    onRefreshButtonClick?: () => void;
    sticky?: boolean;
    extraHTMLElementComponent?: ReactNode;
    isExtraHTMLElementRequired?: boolean;
    buttonSize?: 'large' | 'medium' | 'small';
    isProfileWithProposalsOrArchived?: boolean;
};

export default function DataEntryHeader({
                                            className,
                                            title,
                                            SubtitleComponent,
                                            primaryButtonText = "Done",
                                            secondaryButtonText = "Discard Changes",
                                            tertiaryButtonText,
                                            hidePrimaryButton = false,
                                            hideSecondaryButton = false,
                                            hideTertiaryButton = true,
                                            showCloseButton = false,
                                            showRefreshButton = false,
                                            disablePrimaryButton = false,
                                            disableSecondaryButton = false,
                                            disableTertiaryButton = false,
                                            onPrimaryButtonClick,
                                            onSecondaryButtonClick,
                                            onTertiaryButtonClick,
                                            onCloseButtonClick,
                                            onRefreshButtonClick,
                                            sticky = false,
                                            extraHTMLElementComponent,
                                            isExtraHTMLElementRequired = false,
                                            buttonSize = "medium",
                                            isProfileWithProposalsOrArchived = false
                                        }: DataEntryHeaderProps) {
    return (
        <header
            className={classNames(
                "data-entry-header",
                {"data-entry-header--sticky": sticky},
                className
            )}
        >
      <span className="data-entry-header__title">
        <div className="h3 fontweight-medium margin-none">{shortenName(title!,50)}</div>
          {SubtitleComponent && SubtitleComponent()}
      </span>
            <span className="data-entry-header__buttons">
        {isExtraHTMLElementRequired && extraHTMLElementComponent}
                {!hideTertiaryButton && (
                    <Button
                        className="marginright-12"
                        disabled={Boolean(disableTertiaryButton)}
                        icon="none"
                        kind="secondary"
                        onClick={onTertiaryButtonClick}
                        size={buttonSize}
                        type="button"
                    >
                        {tertiaryButtonText}
                    </Button>
                )}
                {!hideSecondaryButton && (
                    <Button
                        className="marginright-sm"
                        disabled={Boolean(disableSecondaryButton)}
                        icon="none"
                        kind="secondary"
                        onClick={onSecondaryButtonClick}
                        size={buttonSize}
                        type="button"
                    >
                        {secondaryButtonText}
                    </Button>
                )}
                {!hidePrimaryButton && (
                    <Button
                        className="marginleft-md"
                        disabled={Boolean(disablePrimaryButton) || disableTertiaryButton || isProfileWithProposalsOrArchived}
                        icon="none"
                        kind="primary"
                        onClick={onPrimaryButtonClick}
                        size={buttonSize}
                        type="button"
                    >
                        {primaryButtonText}
                    </Button>
                )}
                {showRefreshButton && (
                    <Button
                        className="marginleft-md refresh-button"
                        icon="only"
                        iconName="refresh"
                        kind="borderless"
                        size={buttonSize}
                        onClick={onRefreshButtonClick}
                        type="button"
                    />
                )}
                {showCloseButton && (
                    <Button
                        className="marginleft-md"
                        icon="only"
                        iconName="close"
                        kind="borderless"
                        onClick={onCloseButtonClick}
                        size={buttonSize}
                        type="button"
                    />
                )}
      </span>
        </header>
    );
}
