import {AssetsSummary} from "../models/Assets";

import {InvestorGroupType, MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {LegalEntityReadModel} from "../models/Ownership";
import usePageViewTimer from "../../hooks/usePageViewTimer";
import {AssetAddEditSideDrawer} from "../common/AssetAddEditSideDrawer";
import {DeleteModalProps} from "../common/AssetsView";
import {AssetSummaryContent} from "./AssetSummaryContent";
import {GeneralInflow} from "../models/GeneralInflow";

export type AssetSummaryProps = {
    clientAssets?: AssetsSummary,
    legalEntities: LegalEntityReadModel[],
    investorGroup?: InvestorGroupType,
    isLoading: boolean,
    openDrawer: boolean,
    openHoldings: boolean,
    profileId: string,
    onRemoveAssetClick: (deleteModalProps: DeleteModalProps) => void,
    onRemoveInvestmentProgram: () => void,
    onDeleteLegalEntity: (legalEntityId: string) => void,
    onRefreshInvestmentProgramHoldings: () => void,
    onSaveReviewTrustDisclosure: (generalInflow: GeneralInflow) => void,
    memberGroup: MemberGroup,
    onArrangeAssets: () => void
}

const AssetSummary = ({
                          clientAssets,
                          legalEntities,
                          investorGroup,
                          memberGroup,
                          isLoading,
                          openDrawer,
                          profileId,
                          onRemoveAssetClick,
                          onRemoveInvestmentProgram,
                          onDeleteLegalEntity,
                          onRefreshInvestmentProgramHoldings,
                          onSaveReviewTrustDisclosure,
                          onArrangeAssets
                      }: AssetSummaryProps) => {

    const isLoadingContent = isLoading || !clientAssets || !investorGroup || !memberGroup;
    usePageViewTimer('Asset Summary Page Load Timer (milliseconds)', isLoadingContent);

    return (
        <>
            <section>
                {isLoadingContent
                    ? <LoadingIndicator/>
                    : <AssetSummaryContent clientAssets={clientAssets}
                                           legalEntities={legalEntities}
                                           investorGroup={investorGroup}
                                           profileId={profileId}
                                           onDeleteAssetClick={onRemoveAssetClick}
                                           onRemoveInvestmentProgram={onRemoveInvestmentProgram}
                                           onDeleteLegalEntity={onDeleteLegalEntity}
                                           onRefreshInvestmentProgramHoldings={onRefreshInvestmentProgramHoldings}
                                           onSaveReviewTrustDisclosure={onSaveReviewTrustDisclosure}
                                           memberGroup={memberGroup}
                                           onArrangeAssets={onArrangeAssets}
                    />}
                <AssetAddEditSideDrawer
                    open={openDrawer}
                    profileId={profileId}
                    clientAssets={clientAssets}
                    memberGroup={memberGroup}
                    investorGroup={investorGroup}
                />
            </section>
        </>
    )
}


export default AssetSummary;
