import {
    InvestorGroupMember,
    InvestorGroupMemberType,
    InvestorGroupType
} from "../../ClientManagement/models/InvestorGroupType";
import moment from "moment";
import {LifestyleSpendingGoal} from "../models/LifestyleSpendingGoal";
import {DiscountRateType} from "../models/DiscountRate";
import {formatCurrency, formatNumber, formatPercentWithWholeNumberAndTwoDecimals} from "../../utils/format";
import React from "react";
import {InfoPopover} from "../../components";
import DataEntrySummary, {DataEntrySummaryItem} from "../../components/DataEntry/DataEntrySummary";
import {ProfileResponse} from "../../ClientManagement/models/ProfileResponse";
import {generateErrorableItem} from "../../components/DataEntry/DataEntryUtils";
import {PortfolioReserveResponse} from "../models/PortfolioReserve";


function getMemberAgeAtOnset(investorGroupMember: InvestorGroupMember) {
    return `${investorGroupMember.firstName} ${(moment().diff(investorGroupMember.birthdate, 'years'))}`;
}

function getAgeAtOnset({planningPeriod, partnerMember, primaryMember}: InvestorGroupType): DataEntrySummaryItem {
    if (!!partnerMember && planningPeriod.memberType === InvestorGroupMemberType.PARTNER) {
        return {
            label: "Age at Onset",
            value: getMemberAgeAtOnset(partnerMember),
            subValue: getMemberAgeAtOnset(primaryMember),
            testId: "age-at-onset",
        }
    } else if (!!partnerMember && planningPeriod.memberType === InvestorGroupMemberType.PRIMARY) {
        return {
            label: "Age at Onset",
            value: getMemberAgeAtOnset(primaryMember),
            subValue: getMemberAgeAtOnset(partnerMember),
            testId: "age-at-onset",
        }
    } else {
        return {
            label: "Age at Onset",
            value: getMemberAgeAtOnset(primaryMember),
            testId: "age-at-onset",
        }
    }
}

export default function LifestyleSpendingLivePreview({
                                                         lifestyleSpendingGoal,
                                                         investorGroup,
                                                         discountRateSelection,
                                                         profile,
                                                         isSufficient,
                                                         hasError,
                                                         reserveTargetIsSet,
                                                         portfolioReserve,
                                                     }: {
    lifestyleSpendingGoal: LifestyleSpendingGoal,
    investorGroup: InvestorGroupType,
    discountRateSelection: DiscountRateType,
    profile: ProfileResponse,
    isSufficient: boolean,
    hasError: boolean,
    reserveTargetIsSet?: boolean,
    portfolioReserve: PortfolioReserveResponse,
}) {
    const presentValueLabel = (
        <div style={{height: "24px"}}> Present Value
            {!reserveTargetIsSet &&
                <InfoPopover
                    content={<div
                        className={"tax-info-card__content hover-text"}>This assumes a
                        default Portfolio Reserve target equal to the number of years
                        of lifestyle spending that your current risk control dollars
                        can support. When assets and goals have been captured,
                        the Set Portfolio Reserve page will help you select a custom
                        reserve target</div>}
                    direction="bottom"
                    width="196px"
                />}
        </div>
    )

    const fundedByInflowsLabel = (
        <div style={{height: "24px"}}>Funded by Inflows
            {lifestyleSpendingGoal.calculatedFields.totalAlignedInflowPresentValue > 0 &&
                <InfoPopover
                    content={
                        <div>
                            <div className="aligned-inflows-popover condensed-subtitle">
                                <span>Inflow Name</span>
                                <span>Present Value</span>
                            </div>
                            <hr style={{width:"100%"}}/>
                            {
                                lifestyleSpendingGoal.calculatedFields.inflowPresentValues
                                    .filter(inflowPresentValue => inflowPresentValue.lifestyleGoalAligned > 0)
                                    .map((inflowPresentValue, index) =>
                                        (<div
                                            key={`info-popover-${index}`}
                                            className="aligned-inflows-popover aligned-inflows-popover-data">
                                                <span
                                                    className="inflow-description">{inflowPresentValue.description}</span>
                                            <span
                                                className="inflow-value">{formatCurrency(inflowPresentValue.lifestyleGoalAligned)}</span>
                                        </div>))
                            }
                        </div>
                    }
                    direction="left"
                    width="288px"
                />}
        </div>
    )

    const fundedByInflowsValue = (
        <span>
            {lifestyleSpendingGoal.calculatedFields.totalAlignedInflowPresentValue === 0 ? "$0" : `-${formatCurrency(lifestyleSpendingGoal.calculatedFields.totalAlignedInflowPresentValue)}`}
        </span>
    )

    const fundedByNonInvestablesValue = (
        <span data-testid="fundedByNonInvestablesAmount">
            {formatCurrency(lifestyleSpendingGoal.calculatedFields.presentValueForInsufficientYears)}
        </span>);

    let annualSpendingItem: DataEntrySummaryItem = (lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods.length <= 1) ?
        {
            label: 'Annual Spending',
            value: formatCurrency(lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods[0].annualSpend)
        }
        : {};
    let lengthOfSpendItem: DataEntrySummaryItem = (lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods.length <= 1) ?
        {
            label: 'Length Of Spend',
            value: `${investorGroup.planningPeriod.numberOfYears} years`
        } : {};
    let ageAtOnsetItem: DataEntrySummaryItem = (lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods.length <= 1 || !investorGroup.partnerMember) ?
        {
            ...getAgeAtOnset(investorGroup)
        } : {};
    let fundedByNonInvestableAssetsItem: DataEntrySummaryItem = lifestyleSpendingGoal.userInputs.isFundedByNonInvestableAssets && !isSufficient
        ? generateErrorableItem("Funded by Non-Investable", fundedByNonInvestablesValue, hasError) : {};

    let discountRateItem: DataEntrySummaryItem;
    if(portfolioReserve.tripleNetDiscountRate !==null &&
        portfolioReserve.portfolioReserveTargetLength !== undefined && profile.activePortfolioReserve){
        discountRateItem = discountRateSelection === DiscountRateType.TRIPLE_NET
            ? generateErrorableItem('Triple Net Discount Rate', `${formatNumber(portfolioReserve.lifestylePresentValues.reserveTarget.tripleNetDiscountRate)}%`, hasError)
            : generateErrorableItem('Gross Discount Rate', `${formatNumber(portfolioReserve.lifestylePresentValues.reserveTarget.grossDiscountRate)}%`, hasError);

    }else {
        discountRateItem = discountRateSelection === DiscountRateType.TRIPLE_NET
            ? generateErrorableItem('Triple Net Discount Rate', `${formatNumber(lifestyleSpendingGoal.calculatedFields.tripleNetDiscountRate)}%`, hasError)
            : generateErrorableItem('Gross Discount Rate', `${formatNumber(lifestyleSpendingGoal.calculatedFields.grossDiscountRate)}%`, hasError);
    }


    const items = [
        annualSpendingItem,
        lengthOfSpendItem,
        ageAtOnsetItem,
        generateErrorableItem(presentValueLabel, `${formatCurrency(lifestyleSpendingGoal.calculatedFields.presentValue)}`, hasError),
        generateErrorableItem(fundedByInflowsLabel, fundedByInflowsValue, hasError),
        fundedByNonInvestableAssetsItem,
        generateErrorableItem('Required Portfolio Spending', `${formatCurrency(lifestyleSpendingGoal.calculatedFields.requiredPortfolioSpendingPresentValue)}`, hasError),
        generateErrorableItem('Risk Assets / Risk Control', `${Math.round(lifestyleSpendingGoal.calculatedFields.riskAsset)}% / ${Math.round(lifestyleSpendingGoal.calculatedFields.riskControl)}%`, hasError),
        discountRateItem,
    ].filter(item => Object.values(item).length !== 0);

    return <div style={{flex: "1 0 33%"}}>
        <section>
            <DataEntrySummary title="Goal Summary"
                              items={items}
            />
        </section>
    </div>;
}
