export enum FamilyRelationshipType {
    PARENT = "PARENT",
    CHILD = "CHILD",
    SPOUSE = "SPOUSE",
    DOMESTIC_PARTNER = "DOMESTIC_PARTNER",
    SIGNIFICANT_OTHER = "SIGNIFICANT_OTHER",
    EX_SPOUSE = "EX_SPOUSE",
    OTHER = "OTHER"
}

export enum RelationshipStatusType {
    SINGLE = "SINGLE",
    SINGLE_PARENT = "SINGLE_PARENT",
    PARTNERED = "PARTNERED",
    PARTNERED_PARENT = "PARTNERED_PARENT",
}
