import {useEffect, useState} from "react";
import {assetsApiClient} from "../../Assets/AssetsApiClient";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {goalsApiClient} from "../../Goals/GoalsApiClient";
import {setClientAssets} from "../../Assets/clientAssetsSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import {emptyFutureValueOfExcessAssets, FutureValueOfExcessAssetsDTO} from "../../Assets/models/Assets";
import {emptyInvestorGroup, InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import {AllGoalsType} from "../../Goals/models/GoalModelType";
import LoadingIndicator from "../../pages/LoadingIndicator";
import NetWorthOverTimeReport from "./index";
import {FveDiscountRateType} from "../../ClientManagement/AssetReliance/AssetRelianceButtonState";
import {selectDisplayName} from "../../ClientManagement/ClientProfile/approvedProfileSlice";

interface NetWorthOverTimeWrapperProps {
    discountRate?: FveDiscountRateType;
    isInsuranceIncluded?: boolean

}

const NetWorthOverTimeWrapper = ({
                                     discountRate=FveDiscountRateType.NOMINAL_NET,
                                     isInsuranceIncluded=false
                                 }: NetWorthOverTimeWrapperProps) => {
    const displayName = useAppSelector(selectDisplayName) ?? '';
    const dispatch = useAppDispatch();
    const {id} = useParams<RouteWithId>();
    const [isLoading, setIsLoading] = useState(true);
    const [futureValueOfExcessAssetsDTO, setFutureValueOfExcessAssetsDTO] = useState<FutureValueOfExcessAssetsDTO>(emptyFutureValueOfExcessAssets);
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType>(emptyInvestorGroup);
    const [projectedNetWorthAge, setProjectedNetWorthAge] = useState<number>();
    const [confirmedProjectedNetWorthAge, setConfirmedProjectedNetWorthAge] = useState<number>();
    const [goals, setGoals] = useState<AllGoalsType>();

    useEffect(() => {
        Promise.all([
            assetsApiClient.getFutureValueOfExcessAssets(id),
            assetsApiClient.getAssetsSummary(id),
            clientManagementApiClient.getInvestorGroup(id),
            goalsApiClient.getAllGoals(id),
        ]).then(([
                     futureValueOfExcessAssetsResponseForReport,
                     assetsSummaryResponseForReport,
                     investorGroupResponseForReport,
                     goalsResponseForReport,
                 ]) => {
            dispatch(setClientAssets(assetsSummaryResponseForReport));
            setFutureValueOfExcessAssetsDTO(futureValueOfExcessAssetsResponseForReport);
            setInvestorGroup(investorGroupResponseForReport);
            setProjectedNetWorthAge(investorGroupResponseForReport.planningPeriod.numberOfYears + investorGroupResponseForReport.planningPeriod.ageFrom);
            setConfirmedProjectedNetWorthAge(investorGroupResponseForReport.planningPeriod.numberOfYears + investorGroupResponseForReport.planningPeriod.ageFrom);
            setGoals(goalsResponseForReport);
            setIsLoading(false);
        }).catch(error => console.error('Could not fetch asset details', error.message));
    }, [id])


    if (isLoading) {
        return <LoadingIndicator/>
    }

    return <NetWorthOverTimeReport investorGroup={investorGroup}
                                   confirmedProjectedNetWorthAge={confirmedProjectedNetWorthAge}
                                   projectedNetWorthAge={projectedNetWorthAge} goals={goals}
                                   discountRate={discountRate}
                                   displayName={displayName}
                                   futureValueOfExcessAssetsDTO={futureValueOfExcessAssetsDTO}
                                   includeLifeInsurance={isInsuranceIncluded}/>
}

export default NetWorthOverTimeWrapper;