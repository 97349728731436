import React, {useEffect, useState} from "react";
import {Col, Grid, Row} from "xps-react";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import MeetingHistoryDetails from "./MeetingHistoryDetails";
import {Button, Dropdown, DropdownItem, Search} from "../../components";
import useMeetingHistory from "./useMeetingHistory";
import {HistoricalMeeting} from "./Meeting";
import {useAppSelector} from "../../store/hooks";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";

export type FilterMeetingOptions =
    "completeMeetings"
    | "expiredMeetings"
    | "allMeetings";


const MeetingsHistory: React.FC = () => {
    const history = useHistory();
    const {id} = useParams<RouteWithId>();
    const {profile} = useProfileAndProposals(id);
    const [searchText, updateSearchText] = useState("");
    const [meetingSubset, updateMeetingSubset] = useState<FilterMeetingOptions>("completeMeetings");
    const {meetings: historicalMeetings, expiredMeetings} = useMeetingHistory();
    const [filteredMeetings, setFilteredMeetings] = useState<HistoricalMeeting[]>([...historicalMeetings]);
    const [allMeetings, setAllMeetings] = useState<HistoricalMeeting[]>([...historicalMeetings, ...expiredMeetings]);
    const releaseToggles = useAppSelector(selectReleaseToggles)

    useEffect(() => {
        setFilteredMeetings([...historicalMeetings]);
        setAllMeetings([...historicalMeetings, ...expiredMeetings]);
    }, [historicalMeetings, expiredMeetings])

    const searchPlaceholderText = "Type to search...";

    const handleProfileDetailsNavigation = () => {
        history.push(`/Profile/${profile.id}`);
    };

    const handleSearch = (search: string, viewOption: FilterMeetingOptions) => {
        updateSearchText(search);
        let updatedMeetings: HistoricalMeeting[];
        if (viewOption === "completeMeetings") {
            updatedMeetings = historicalMeetings;
        } else if (viewOption === "expiredMeetings") {
            updatedMeetings = expiredMeetings;
        } else {
            updatedMeetings = allMeetings;
        }

        if (search.length > 0 && search !== searchPlaceholderText) {
            updatedMeetings = updatedMeetings.filter(meeting => meeting.title.includes(search));
        }

        setFilteredMeetings(updatedMeetings);
    };

    const handleDropdown = (viewOption: FilterMeetingOptions) => {
        updateMeetingSubset(viewOption)
        handleSearch(searchText, viewOption);
    };

    return (
        <Grid fluid={true}>
            <Row>
                <Col>

                    <div className="page-container meetings-details-container">
                        <div className="meetings-details-cta">
                            <div className="h1">Meeting History - {profile?.displayName}</div>
                            <div className="right-aligned">
                                <Button
                                    id="NavigateToProfileDetails"
                                    className="marginright-sm"
                                    icon="none"
                                    includeRef={false}
                                    kind="secondary"
                                    size="medium"
                                    tabIndex={0}
                                    type="button"
                                    onClick={handleProfileDetailsNavigation}
                                >
                                    <span className="new-client-text">CLOSE</span>
                                </Button>
                            </div>
                        </div>
                        <div>
                            <Search
                                id="search_container"
                                name="search_standalone"
                                size="medium"
                                onClearListener={() => handleSearch("", meetingSubset)}
                                placeholder={searchPlaceholderText}
                                handleChangeVal={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => handleSearch(e.target.value, meetingSubset)}
                                value={searchText}
                            />
                            {releaseToggles?.enableMeetingManagementScheduler &&
                                <Dropdown
                                    className="clientsFilterField marginleft-lg"
                                    data-testid='clients-filter'
                                    name="clientsFilter"
                                    id="clientsFilterInput"
                                    aria-label="clientsFilter"
                                    aria-labelledby="clientsFilterLabel"
                                    alignRight={false}
                                    buttonIcon="right"
                                    buttonKind="secondary"
                                    defaultText="Complete Meetings"
                                    label="SHOW"
                                    inlineLabel
                                    defaultPageSize={15}
                                    dropdownKind="select"
                                    dynamicDropAlign={false}
                                    dynamicDropDirection={false}
                                    iconNameClose="arrow_drop_up"
                                    iconNameOpen="arrow_drop_down"
                                    labelWidth="300px"
                                    nativeOnMobile={false}
                                    value="completeMeetings"
                                    panelHeight="auto"
                                    panelWidth="300px"
                                    size="medium"
                                    virtualScroll={false}
                                    onChange={(data: any) => handleDropdown(data?.value as FilterMeetingOptions)}
                                >
                                    <DropdownItem
                                        key="comnpleteMeetings"
                                        itemText='Complete Meetings'
                                        value="completeMeetings"
                                    />
                                    <DropdownItem
                                        key="expiredMeetings"
                                        itemText='Expired Meetings'
                                        value="expiredMeetings"
                                    />
                                    <DropdownItem
                                        key="allMeetings"
                                        itemText='All Meetings'
                                        value="allMeetings"
                                    />
                                </Dropdown>}
                        </div>
                        <MeetingHistoryDetails meetings={filteredMeetings} meetingSubset={meetingSubset}/>
                    </div>
                </Col>
            </Row>
        </Grid>
    )
}
export default MeetingsHistory;
