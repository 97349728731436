import { formatCurrency } from "src/utils/format";
import { FundingDisplayOptions } from "./models/Funding";
import {DataDisplayView} from "../../ClientManagement/AssetAllocation/ReviseAssetAllocation";

export function isShowFundingForNonInvestable(displayOptions: FundingDisplayOptions) {
    return displayOptions === FundingDisplayOptions.PORTFOLIO_AND_NON_INVESTABLE || displayOptions === FundingDisplayOptions.PORTFOLIO_AND_INFLOWS_AND_NON_INVESTABLE;
}

export function isShowFundingForInflows(displayOptions: FundingDisplayOptions) {
    return displayOptions === FundingDisplayOptions.PORTFOLIO_AND_INFLOWS || displayOptions === FundingDisplayOptions.PORTFOLIO_AND_INFLOWS_AND_NON_INVESTABLE;
}

export function isShowOnlyPortfolio(displayOptions: FundingDisplayOptions) {
    return displayOptions === FundingDisplayOptions.ONLY_PORTFOLIO;
}

export const EMPTY_VALUE = "--";

export const formatFundingCurrency = (value?: number) => {
    return value ? formatCurrency(value) : EMPTY_VALUE
}

export const formatFundingPercentage = (value?: number) => {
    return value ? value.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0}) + "%" : EMPTY_VALUE;
};

export const formatFundingPercentWithTwoDecimals = (value: number) => {
    return value ? value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + "%" : EMPTY_VALUE;
}

export const isDollarView = (displayView: DataDisplayView): boolean => {
    return displayView === DataDisplayView.DOLLAR_VIEW
}
