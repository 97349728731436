import {formatCurrency} from "../../../utils/format";
import {TableActionDropdownMenu} from "../../../components/Table/TableActionDropdownMenu";
import {DropdownItem, Icon} from "../../../components";
import {TableCell} from "../../../components/Table/TableCell";
import classNames from "classnames";
import {EquityCompensationFormData} from "src/Assets/models/EquityCompensation";
import {shortenName} from "src/Assets/common/textUtils";
import {InvestorGroupType} from "src/ClientManagement/models/InvestorGroupType";
import {
    calculateEquityCompensationJointValue,
    calculateEquityCompensationsPartnerMemberOwnedValue,
    calculateEquityCompensationsPrimaryMemberOwnedValue
} from "src/Assets/AssetSummary/common/AssetSummaryCalculator";
import useProfileEditableState from "../../../hooks/useProfileEditableState";

type EquityCompensationTableRowProps = {
    equityCompensation: EquityCompensationFormData;
    investorGroup: InvestorGroupType;
    onClickEdit: (id: string) => void;
    onClickDelete: (equityCompensation: EquityCompensationFormData) => void;
    actionsDisabled: boolean;
    gridClassName: string,
    hideActionMenu?: boolean
};
export const EquityCompensationTableRow = ({
                                     equityCompensation,
                                     investorGroup,
                                     onClickEdit,
                                     onClickDelete,
                                     actionsDisabled,
                                     gridClassName,
                                     hideActionMenu = false
                                 }: EquityCompensationTableRowProps) => {
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    function generatePopoverContent() {
        const partnerMemberId = investorGroup.partnerMember ? investorGroup.partnerMember.id : "";
        const equityOwner = equityCompensation.owner;
        const isPrimaryRevTrust = (equityOwner.startsWith(investorGroup.primaryMember.id) && equityOwner.endsWith("revTrust"))
        const isPartnerRevTrust = (equityOwner.startsWith(partnerMemberId) && equityOwner.endsWith("revTrust"))
        let popoverText;
        if (isPrimaryRevTrust) {
            popoverText = `${investorGroup.primaryMember.firstName}'s Revocable Trust`
        }
        else if (isPartnerRevTrust) {
            popoverText = `${investorGroup.partnerMember?.firstName}'s Revocable Trust`
        }
        return popoverText ? <>{popoverText}</> : undefined;
    }

    return (
        <div key={`equity-compensation-row-${equityCompensation.id}`}
            role="row"
            className={classNames("assets-grid-table-row", gridClassName)}
            tabIndex={0}
        >
            <TableCell
                ariaLabel="Name"
                text={shortenName(equityCompensation.entityOrAccountName)}
                className={`textalign-left`}
                popoverContent={generatePopoverContent()}
            />
            <TableCell
                ariaLabel={investorGroup.primaryMember.firstName}
                text={calculateEquityCompensationsPrimaryMemberOwnedValue(equityCompensation, investorGroup)}
                className="textalign-right"/>
            <TableCell
                ariaLabel={investorGroup.partnerMember?.firstName || "Partner Member"}
                text={calculateEquityCompensationsPartnerMemberOwnedValue(equityCompensation, investorGroup)}
                className="textalign-right"/>
            <TableCell
                ariaLabel='equity-compensation-joint'
                text={calculateEquityCompensationJointValue(equityCompensation)}
                className="textalign-right"/>
            <TableCell
                ariaLabel="Present Value"
                text={formatCurrency(equityCompensation.afterTaxVestedValue || undefined)}
                className="textalign-right"/>
            {!hideActionMenu && <TableActionDropdownMenu
                disabled={actionsDisabled}
                ariaLabel={`${equityCompensation.companyOrStockName} Menu`}>
                <DropdownItem
                    itemText="Edit Asset"
                    value="Edit Asset"
                    onClick={() => onClickEdit(equityCompensation.id)}
                />
                <DropdownItem
                    itemText="Delete Asset"
                    value="Delete Asset"
                    disabled={isProfileWithProposalsOrArchived}
                    onClick={() => onClickDelete(equityCompensation)}
                >
                    <Icon name="delete" className="asset-table-delete-icon" />
                </DropdownItem>
            </TableActionDropdownMenu>}
        </div>
    );
};
