import React from "react";
import {PresentationPaneHeader} from "../../components";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";

const ComparePlansHeader: React.FC = () => {
    const {id} = useParams<RouteWithId>();
    const displayName = useProfileAndProposals(id).approvedProfile.displayName

    return (
        <div>
            <PresentationPaneHeader
                displayName={displayName}
                title="Compare Plans"
            />
        </div>
    );
}

export default ComparePlansHeader;