import {HoldingAssetSubclassDetails, HoldingType, HoldingWriteModel} from "../../models/Holding";
import {AssetClass, AssetClassifications, AssetSubclassDetails} from "../../models/AssetClassifications";

export function getHoldingsWithDefaultPlaceholders(holdings: HoldingAssetSubclassDetails[], assetClassifications: AssetClassifications): HoldingAssetSubclassDetails[] {
    let holdingsWithDefaultPlaceholders: HoldingAssetSubclassDetails[] = holdings;
    const existingDetailIds = holdings.map(holding => holding.assetSubclassDetailsId);

    const defaultSubclasses = assetClassifications.riskAssetClasses.concat(assetClassifications.riskControlAssetClasses)
        .flatMap(assetClasses => assetClasses.assetSubclasses)
        .filter(assetSubclass => assetSubclass.defaultAssetSubclassDetailId !== null);

    defaultSubclasses.forEach(subclass => {
        const subclassDetailIds = subclass.assetSubclassDetails.map(detail => detail.assetSubclassDetailsId);
        const holdingExistsForThisSubclass = existingDetailIds.some(id => subclassDetailIds.includes(id));

        if (!holdingExistsForThisSubclass) {
            const defaultSubclassDetail = subclass.assetSubclassDetails
                .find(detail => detail.assetSubclassDetailsId === subclass.defaultAssetSubclassDetailId)

            if (defaultSubclassDetail) {
                holdingsWithDefaultPlaceholders.push({
                    assetSubclassDetailsId: defaultSubclassDetail.assetSubclassDetailsId,
                    assetSubclassDetailsName: defaultSubclassDetail.assetSubclassDetailsName,
                    id: null,
                    marketValue: null,
                    marketEstateValue: {
                        inEstateValue: 0,
                        outOfEstateValue: 0,
                        totalValue: 0
                    },
                    taxCost: null,
                    investableValue: null,
                    locked: false,
                    concentrated: false,
                    type: HoldingType.Subclass
                })
            }
        }
    });

    return holdingsWithDefaultPlaceholders;
}

export const getRiskAndRiskControlWithPercent = (holdings: HoldingAssetSubclassDetails[], classifications: AssetClassifications, accountHoldingInfoType: "holdingsInfo" | "accountSummaryInfo") => {
    const risk = filterHoldingsByDetailIds(holdings, getDetailIds(classifications.riskAssetClasses));
    const riskControl = filterHoldingsByDetailIds(holdings, getDetailIds(classifications.riskControlAssetClasses));

    const totalRiskAssets = sumByField(risk, holding => accountHoldingInfoType === 'holdingsInfo' ? holding.marketEstateValue.inEstateValue : holding.marketEstateValue.totalValue);
    const totalRiskControl = sumByField(riskControl, holding => accountHoldingInfoType === 'holdingsInfo' ? holding.marketEstateValue.inEstateValue : holding.marketEstateValue.totalValue);
    const isTotalValueZero = totalRiskAssets + totalRiskControl === 0;
    let totalRiskAssetsInPercent = Math.round((totalRiskAssets / (totalRiskAssets + totalRiskControl)) * 100);
    let totalRiskControlInPercent = Math.round((totalRiskControl / (totalRiskAssets + totalRiskControl)) * 100);

    if (isTotalValueZero) {
        totalRiskAssetsInPercent = 0
        totalRiskControlInPercent = 0
    }

    return {
        totalRiskAssets,
        totalRiskControl,
        totalRiskAssetsInPercent,
        totalRiskControlInPercent
    }
}

export function getDetailIds(assetClasses: AssetClass[]) {
    return assetClasses
        .flatMap(assetClass => assetClass.assetSubclasses)
        .flatMap(subclass => subclass.assetSubclassDetails)
        .map(details => details.assetSubclassDetailsId);
}

export function filterHoldingsByDetailIds(editableHoldings: HoldingAssetSubclassDetails[], detailIds: string[]) {
    return editableHoldings.filter(holding => detailIds.includes(holding.assetSubclassDetailsId));
}

export type LiquidityTotals = {
    liquidityCategory: string,
    marketValue: number,
    investableValue: number
}

export const groupHoldingsByLiquidityCategory = (holdings: HoldingAssetSubclassDetails[], classifications: AssetClassifications): LiquidityTotals[] => {
    const detailsGroupedByLiquidityCategory: Record<string, AssetSubclassDetails[]> = groupBy(
        classifications.riskAssetClasses.concat(classifications.riskControlAssetClasses)
            .flatMap(assetClass => assetClass.assetSubclasses)
            .flatMap(subclass => subclass.assetSubclassDetails),
        detail => detail.liquidityCategory
    );

    const liquidityTotals: LiquidityTotals[] = [];

    for (const key in detailsGroupedByLiquidityCategory) {
        const detailIds = detailsGroupedByLiquidityCategory[key].map(detail => detail.assetSubclassDetailsId);
        const holdingAssetSubclassDetails = holdings
            .filter(holding => !holding.concentrated
                && detailIds.includes(holding.assetSubclassDetailsId))

        addLiquidityCategoryTotals(key, liquidityTotals, holdingAssetSubclassDetails);
    }

    const concentratedHoldings = holdings.filter(holding => holding.concentrated);
    addLiquidityCategoryTotals('Concentrated Investments', liquidityTotals, concentratedHoldings);

    return sortByLiquidityCategory(liquidityTotals);
}

function sortByLiquidityCategory(liquidityTotals: LiquidityTotals[]) {
    const liquidityCategorySortOrder = [
        'Concentrated Investments',
        'Other',
        'Equity Derivatives',
        'Liquid Risk Assets',
        'Semi Liquid Risk Assets',
        'Illiquid Risk Assets',
        'Liquid Risk Control'
    ];

    return liquidityTotals.sort((a, b) => {
        return liquidityCategorySortOrder.indexOf(a.liquidityCategory) < liquidityCategorySortOrder.indexOf(b.liquidityCategory)
            ? -1
            : 1;
    });
}

function addLiquidityCategoryTotals(liquidityCategory: string, liquidityTotals: LiquidityTotals[], holdingAssetSubclassDetails: HoldingAssetSubclassDetails[]) {
    if (holdingAssetSubclassDetails.length !== 0) {
        liquidityTotals.push({
            liquidityCategory: liquidityCategory,
            marketValue: sumByField(holdingAssetSubclassDetails, holding => holding.marketEstateValue.inEstateValue),
            investableValue: sumByField(holdingAssetSubclassDetails, holding => holding.investableValue)
        })
    }
}

export function sumByField<T>(items: T[], fn: (item: T) => number | null, fallback: number = 0): number {
    return items
        .map(value => fn(value) ?? fallback)
        .map(value => isNaN(value) ? fallback : value)
        .reduce((a, b) => a + b, 0);
}

function groupBy<T>(arr: T[], fn: (item: T) => any) {
    return arr.reduce<Record<string, T[]>>((prev, curr) => {
        const groupKey = fn(curr);
        if (groupKey) {
            const group = prev[groupKey] || [];
            group.push(curr);
            return {...prev, [groupKey]: group};
        } else {
            return prev;
        }
    }, {});
}

export function allSelectedAssetClassNames(classifications: AssetClassifications, selectedHoldings: HoldingAssetSubclassDetails[]) {
    const selectedRiskAssetClasses = filterAssetClassesWithNoSelectedHoldings(
        classifications?.riskAssetClasses ?? [],
        selectedHoldings
    );

    const selectedRiskControlAssetClasses = filterAssetClassesWithNoSelectedHoldings(
        classifications?.riskControlAssetClasses ?? [],
        selectedHoldings
    );

    return allAssetClassNames(selectedRiskAssetClasses, selectedRiskControlAssetClasses);
}

export function allAssetClassNames(selectedRiskAssetClasses: AssetClass[], selectedRiskControlAssetClasses: AssetClass[]) {
    return selectedRiskAssetClasses.concat(selectedRiskControlAssetClasses)
        .map(assetClass => assetClass.assetClassName);
}

export function allAssetSubclassNames(selectedRiskAssetClasses: AssetClass[], selectedRiskControlAssetClasses: AssetClass[], suffix: string = '') {
    return selectedRiskAssetClasses.concat(selectedRiskControlAssetClasses)
        .flatMap(assetClass => assetClass.assetSubclasses)
        .map(assetSubclass => assetSubclass.assetSubclassName+suffix);
}

export function filterAssetClassesWithNoSelectedHoldings(
    assetClasses: AssetClass[],
    selectedHoldings: HoldingAssetSubclassDetails[]
): AssetClass[] {
    return assetClasses
        .map((assetClass) => ({
            ...assetClass,
            assetSubclasses: assetClass.assetSubclasses
                .map(subclass => ({
                    ...subclass,
                    assetSubclassDetails: subclass.assetSubclassDetails
                        .filter(detail =>
                            selectedHoldings.some(holding => holding.assetSubclassDetailsId === detail.assetSubclassDetailsId)),
                }))
                .filter(subclass => subclass.assetSubclassDetails.length > 0),
        }))
        .filter((assetClass: AssetClass) => assetClass.assetSubclasses.length > 0);
}

export function mapHoldingAssetSubclassDetailsToHoldingWriteModel(holding: HoldingAssetSubclassDetails): HoldingWriteModel {
    const {
        id,
        accountNumber,
        assetSubclassDetailsName,
        productCode,
        productName,
        ticker,
        cusip,
        marketEstateValue,
        ...rest
    } = holding;

    return {
        ...rest,
        id: id ? id : undefined,
        productId: holding.type === HoldingType.Product ? holding.productId : undefined,
        productCode: holding.type === HoldingType.ApprovedProduct ? holding.productCode : undefined,
        productName: holding.type === HoldingType.CustomProduct ? holding.productName : undefined,
        ticker: holding.type === HoldingType.CustomProduct ? holding.ticker : undefined,
        cusip: holding.type === HoldingType.CustomProduct ? holding.cusip : undefined
    }
}

export const assetSubclassAccordionSuffix = '-subclass';