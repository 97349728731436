import NonLifestyleGoalList from "../components/NonLifestyleGoalList";
import {GoalType, NonLifestyleGoalType} from "../models/GoalType";
import SummaryFamilyGoalList from "./SummaryFamilyGoalList";
import React from "react";
import {NonLifestyleCumulativePresentValue} from "./NonLifestyleCumulativePresentValue";
import {BeneficiaryGoals} from "../models/GoalModelType";
import AccordionWithHeader from "../../components/Accordion/AccordionWithHeader";
import {FamilyGoalType} from "../models/FamilyGoalType";
import {MemberType} from "../../ClientManagement/models/MemberType";
import {GoalBarChartKeyColors} from "../BarChartSidebar/GoalBarChartKeyColors";

type NonLifestyleGoalsProps = {
    familyGoals: { [key: string]: BeneficiaryGoals },
    discretionaryGoals: NonLifestyleGoalType[],
    philanthropicGoals: NonLifestyleGoalType[],
    onEditFamilyGoal: (selectedGoalType: string, savedGoal: FamilyGoalType) => void,
    onViewFamilyGoal: (memberSelected: MemberType) => void,
    onDeleteNonLifestyleGoal: (goal: NonLifestyleGoalType) => void,
    onEditNonLifestyleGoal: (goal: NonLifestyleGoalType, type: GoalType) => void,
    isProfileWithProposalsOrArchived?: boolean
};

const NonLifestyleGoals = ({
                               discretionaryGoals,
                               philanthropicGoals,
                               familyGoals,
                               onEditFamilyGoal,
                               onViewFamilyGoal,
                               onDeleteNonLifestyleGoal,
                               onEditNonLifestyleGoal,
                               isProfileWithProposalsOrArchived
                           }: NonLifestyleGoalsProps) => {

    const hasFamilyGoals = familyGoals && Object.values(familyGoals).length > 0
    const hasDiscretionaryGoals = discretionaryGoals.length > 0
    const hasPhilanthropicGoals = philanthropicGoals.length > 0

    const familyGoalAccordionUUIDs = Object.values(familyGoals)
        .map((goal, index) => `FamilyGoalAccordionItem${index}`)

    const allItems = [
        ...(hasDiscretionaryGoals ? ["Discretionary Goals"] : []),
        ...(hasPhilanthropicGoals ? ["Philanthropic Goals"] : []),
        ...(hasFamilyGoals ? ["Family Goals", ...familyGoalAccordionUUIDs] : []),
    ];

    const columnTitles = [
        "Goal Type",
        "Present Value"
    ];

    if (!hasFamilyGoals && !hasDiscretionaryGoals && !hasPhilanthropicGoals) {
        return <></>
    }

    return (
        <div className="goal-list">
            <NonLifestyleCumulativePresentValue/>
            <AccordionWithHeader
                headerProps={
                    {
                        className: "goal-list-header-grid",
                        gridSpanClass: "grid-span-1"
                    }
                }
                accordionId={"nonLifestyelGoalsAccordion"}
                allItems={allItems}
                columnTitles={columnTitles}
                renderHeader={true}
            >
                <NonLifestyleGoalList
                    label={"Discretionary Goals"}
                    type={GoalType.DISCRETIONARY}
                    goalsForDisplay={discretionaryGoals}
                    color={GoalBarChartKeyColors.DISCRETIONARY}
                    onDeleteGoal={onDeleteNonLifestyleGoal}
                    onEditGoal={onEditNonLifestyleGoal}
                    isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                />
                <NonLifestyleGoalList
                    label={"Philanthropic Goals"}
                    type={GoalType.PHILANTHROPIC}
                    goalsForDisplay={philanthropicGoals}
                    color={GoalBarChartKeyColors.PHILANTHROPIC}
                    onDeleteGoal={onDeleteNonLifestyleGoal}
                    onEditGoal={onEditNonLifestyleGoal}
                    isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                />
                {hasFamilyGoals &&
                    <SummaryFamilyGoalList
                        familyGoals={familyGoals}
                        onEditGoal={onEditFamilyGoal}
                        onViewGoal={onViewFamilyGoal}
                    />
                }
            </AccordionWithHeader>
        </div>
    )
}

export default NonLifestyleGoals;