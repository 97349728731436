import {AccordionItemWithActionMenu} from "../../../../components";
import {COLOR_LIFE_INSURANCES} from "../../../../constants/colors";
import {AssetAccordionHeader} from "../../common/AssetAccordionHeader";
import {formatCurrency} from "../../../../utils/format";
import {calculateInEstateLifeInsuranceTotalValue} from "../../common/AssetSummaryCalculator";
import {NO_OP} from "../../../../constants/common";
import React from "react";
import {LifeInsurancesTable} from "../../InEstate/LifeInsurancesTable";
import {LifeInsurance} from "../../../models/LifeInsurance";
import {MemberGroup} from "../../../../ClientManagement/models/InvestorGroupType";
import {AccordionItemIds} from "../constants";
import {DropResult} from "react-beautiful-dnd";

type LifeInsurancesProps = {
    lifeInsurances: LifeInsurance[],
    memberGroup: MemberGroup,
    captureLifeInsurancesOrder?: ({source, destination}: DropResult) => void
}

const LifeInsurances = ({
                            lifeInsurances,
                            memberGroup,
                            captureLifeInsurancesOrder = NO_OP
                        }: LifeInsurancesProps) => {
    if (lifeInsurances.length === 0) {
        return <></>;
    }

    return (
        <AccordionItemWithActionMenu
            uuid={AccordionItemIds.lifeInsurances}
            expandable={false}
            accentColor={COLOR_LIFE_INSURANCES}
            HeaderComponent={({expanded}) =>
                <AssetAccordionHeader
                    expanded={expanded}
                    title="Life Insurance"
                    formattedTotalPresentValue={formatCurrency(calculateInEstateLifeInsuranceTotalValue(lifeInsurances))}
                    gridClassName={"assets-grid"}
                    showChevron={false}
                />
            }
        >
            <LifeInsurancesTable
                lifeInsurances={lifeInsurances}
                memberGroup={memberGroup}
                onEdit={NO_OP}
                onDelete={NO_OP}
                hideActionMenu={true}
                isDraggable={true}
                captureLifeInsurancesOrder={captureLifeInsurancesOrder}
            />
        </AccordionItemWithActionMenu>
    )
}

export default LifeInsurances;