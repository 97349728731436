import {PersonalAssetDetailsType} from "../models/PersonalAsset";
import {OwnershipDetailsFormData} from "../models/Ownership";

export function isPersonalAssetFormDataMissing(asset: PersonalAssetDetailsType, ownershipDetails: OwnershipDetailsFormData) {
    const isDescriptionEmpty = !asset.description.trim();

    const isOwnershipDataMissing = ownershipDetails.legalEntityOwnerships.some((ownership) => {
        return !ownership.name.trim() || !ownership.type;
    });

    return isDescriptionEmpty || isOwnershipDataMissing;
}
