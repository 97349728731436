import React from "react";
import {useHistory} from "react-router-dom";
import {Button, Icon} from "../../components";

interface EditAssetRelianceButtonProps {
    id: string;
}

const EditAssetRelianceButton: React.FC<EditAssetRelianceButtonProps> = ({id}) => {
    const history = useHistory();

    return (
        <div className="edit-asset-reliance-button-container">
            <Icon size= 'large' name={'add_circle_outline'}/>
            <Button
                size= "large"
                kind="borderless"
                onClick={() => {
                    history.push(`/Profile/${id}/ClientProfile/EditAssetReliance`)
                }}>
                Edit Asset Reliance
            </Button>
    </div>);
}

export default EditAssetRelianceButton;