import React from 'react'
import {VestingPeriod} from 'src/Assets/models/EquityCompensation';
import VestingPeriodRow from './VestingTableRow';

export interface VestingTableProps {
    vestingPeriods: VestingPeriod[];
    deleteVestingPeriod: (id: string) => void;
    updateVestingPeriod: (updatedVestingPeriod: VestingPeriod) => void;
    totalShares: number;
    grantDate: string;
    expirationDate: string;
}

const VestingTable = ({ vestingPeriods, deleteVestingPeriod, updateVestingPeriod, totalShares, grantDate, expirationDate }: VestingTableProps) => {
  return (
        <div role="table" className="vesting-table vesting-grid" aria-label="Vesting Table">
        <VestingTableHeader />
        {vestingPeriods.map((vestingPeriod) =>
            <VestingPeriodRow
                key={vestingPeriod.id}
                vestingPeriod={vestingPeriod}
                deleteVestingPeriod={deleteVestingPeriod}
                updateVestingPeriod={updateVestingPeriod}
                totalShares={totalShares}
                grantDate={grantDate}
                expirationDate={expirationDate}
            />
        )}
    </div>
  )
}

function VestingTableHeader() {
    return <div role="row" className="vesting-grid vesting-table__header" tabIndex={-1}>
        <span role="columnheader" className="textalign-left">Next Vesting Date</span>
        <span role="columnheader" className="textalign-right">Percent (%)</span>
        <span role="columnheader" className="textalign-right">Shares Vesting Per Period</span>
    </div>;
}

export default VestingTable