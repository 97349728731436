import {OwnershipDetailsFormData} from "../models/Ownership";
import {Beneficiary, LifeInsuranceFormData, lifeInsuranceTypes} from "../models/LifeInsurance";
import moment from "moment/moment";

export function isLifeInsuranceFormDataMissing(asset: LifeInsuranceFormData, ownershipDetails: OwnershipDetailsFormData) {
    const isDescriptionEmpty = !asset.description.trim();
    let isIssueDateEmpty=null;
    let isExpirationDateEmpty=null;

    const isOwnershipDataMissing = ownershipDetails.legalEntityOwnerships.some((ownership) => {
        return !ownership.name.trim() || !ownership.type;
    });

    if(asset.type==lifeInsuranceTypes.TERM) {
        isIssueDateEmpty = !asset.issueDate?.trim();
        isExpirationDateEmpty = !asset.expirationDate?.trim();
        return isDescriptionEmpty || isOwnershipDataMissing || isIssueDateEmpty || isExpirationDateEmpty;
    }

    return isDescriptionEmpty || isOwnershipDataMissing;
}

export function calculateTotalAllocation(beneficiaries: Array<Beneficiary>) {
    return (beneficiaries.reduce((total, beneficiary) =>
        total + parseFloat(beneficiary.percentage || "0.00"), 0.00))
}

