import React from 'react';
import ModalWrapper from "../ModalWrapper/ModalWrapper";

export interface ErrorModalData {
    isOpen: boolean;
    header: string;
    message: string;
    operationId: string;
}

export const emptyErrorModalData: ErrorModalData = {
    isOpen: false,
    header: '',
    message: '',
    operationId: ''
}

interface ErrorModalProps {
    onClickRetry: () => void;
    onClickCancel: () => void;
    errorModalData: ErrorModalData;
}

const ErrorModal: React.FC<ErrorModalProps> = ({
                                                           onClickRetry,
                                                           onClickCancel,
                                                           errorModalData,
                                                       }) => {
    return (
        <ModalWrapper
            id={"Error Modal"}
            headerText={errorModalData.header}
            isOpen={errorModalData.isOpen}
            showCloseButton={true}
            alertIconType={'error'}
            alertIcon={'warning'}
            buttons={[
                {
                    text: 'Retry',
                    onClick: onClickRetry,
                    primary: true
                },
                {
                    text: 'Cancel',
                    onClick: onClickCancel
                },
            ]}
            onRequestClose={onClickCancel}
        >
            <div>{errorModalData.message}</div>
            <div>{`Operation Id: ${errorModalData.operationId}`}</div>
        </ModalWrapper>
    )
}

export default ErrorModal;