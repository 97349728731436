import React, {ReactNode, useRef} from "react";
import ScrollableContainer from '../../../components/ScrollableContainer/ScrollableContainer';
import useWindowSize from '../../../hooks/useWindowSize';

export type HoldingsScrollableContainerProps = {
    children: ReactNode,
    bottomMargin?: number
}

export function HoldingsScrollableContainer({children, bottomMargin = 50}: HoldingsScrollableContainerProps) {
    const windowSize = useWindowSize();
    const containerRef = useRef<HTMLDivElement | null>(null);

    const calculateScrollableHeight = () => {
        const minHeight = 150;
        let height = minHeight;

        if (windowSize?.height && containerRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect();
            height = windowSize.height - containerRect.top - bottomMargin;
        }

        return Math.max(minHeight, height);
    }

    return (
        <div ref={containerRef} className="holding-scrollable-wrapper">
            <ScrollableContainer id={'holding-scrollable-wrapper-scroll-container'} height={`${calculateScrollableHeight()}px`} customStyles={{"paddingRight": "8px"}}>
                {children}
            </ScrollableContainer>
        </div>
    );
}
