import {ColumnCounter, TableCell, TableDisplay, TableRow} from "../../../components";
import {formatCurrency} from "../../../utils/format";
import {getGoalColor} from "../../../utils/colorPicker";
import {
    AssetRelianceAssetType,
    AssetRelianceDescribedValueDTO,
    AssetRelianceGoalSummaryDTO,
    AssetRelianceGoalTypeDTO,
    AssetRelianceStack,
    GoalAmounts,
    GoalAmountType
} from "../../models/AssetRelianceResponse";
import {GoalType} from "../../../Goals/models/GoalType";

export class GoalTableDisplay extends TableDisplay<number> {
    constructor(
        columnCounter: ColumnCounter,
        private readonly goalsSummary: AssetRelianceGoalSummaryDTO,
        private readonly stacks: AssetRelianceStack[]) {
        super(columnCounter, (value) => formatCurrency(value > 0 ? -value : value));
    }

    get rows(): TableRow<number>[] {
        return this.goalsSummary.goalTypes.map((goalType, rowIndex): TableRow<number> => ({
            accentColor: getGoalColor(goalType.type),
            uniqueIdentifier: `${rowIndex}-${goalType.type}-${goalType.description}`,
            label: goalType.description,
            values: this.generateColumnValues(goalType, goalType.totalAmounts),
            children: goalType.data.map((goal, goalIndex): TableRow<number> => ({
                accentColor: getGoalColor(goalType.type),
                uniqueIdentifier: `${goalIndex}-${goalType.type}-${goalType.description}-${goal.description}`,
                label: goal.description,
                subtitle: GoalTableDisplay.createSubtitle(goal),
                values: this.generateColumnValues(goalType, goal.amounts, true, goal.isFundedByNonInvestableAssets),
                children: []
            }))
        }));
    }

    private generateColumnValues(
        goalType: AssetRelianceGoalTypeDTO,
        amounts: GoalAmounts[],
        individualGoal: boolean = false,
        fundedByNonInvestable: boolean = false
    ): TableCell<number>[] {
        const columnValues = Array(this.columnCount).fill(
            amounts.find(value => value.goalAmountType === GoalAmountType.PresentValue)?.amount
        );

        for (let columnIndex = 0; columnIndex < columnValues.length; columnIndex++) {
            const goalAmountTypeForStack = this.stacks[columnIndex]
                .goalAmountTypes?.find(value => value.goalType === goalType.type)?.goalAmountType;

            if (goalAmountTypeForStack) {
                columnValues[columnIndex] = amounts.find(value => value.goalAmountType === goalAmountTypeForStack)?.amount
            }

            if (this.stacks[columnIndex].stackAssetType === AssetRelianceAssetType.INVESTABLE_PORTFOLIO_ASSET) {
                if (goalType.type === GoalType.DISCRETIONARY || goalType.type === GoalType.FAMILY || goalType.type === GoalType.PHILANTHROPIC) {
                    if (individualGoal) {
                        if (fundedByNonInvestable) {
                            columnValues[columnIndex] = null
                        }
                    } else {
                        columnValues[columnIndex] = this.recalculateValue(goalAmountTypeForStack, goalType.data)
                    }
                }
            }
        }

        return columnValues.map(value => this.createTableCell({value}));
    }

    private recalculateValue(
        goalAmountTypeForStack: GoalAmountType | undefined,
        goals: AssetRelianceDescribedValueDTO[]
    ): number {
        let totalAmount = 0;

        goals.forEach(goal => {
            if (!goal.isFundedByNonInvestableAssets) {
                let goalValue;
                if (goalAmountTypeForStack) {
                    goalValue = goal.amounts.find(value => value.goalAmountType === goalAmountTypeForStack)?.amount;
                } else {
                    goalValue = goal.amounts.find(value => value.goalAmountType === GoalAmountType.PresentValue)?.amount
                }
                if (goalValue) {
                    totalAmount = totalAmount + goalValue
                }
            }
        })

        return totalAmount;
    }

    protected get headerLabel(): string {
        return "Goals";
    }

    protected get headerValues(): TableCell<number>[] {
        const headerValues: number[] = Array(this.columnCount).fill(0);
        for (let columnIndex = 0; columnIndex < this.columnCount; columnIndex++) {
            this.rows.forEach(row => {
                headerValues[columnIndex] += Math.abs(row.values[columnIndex].originalValue);
            });
        }
        return headerValues.map((value) => this.createTableCell({value}));
    }

    private static createSubtitle(goal: AssetRelianceDescribedValueDTO): string | undefined {
        if (!goal.firstName || !goal.lastName || !goal.age) {
            return undefined;
        }

        return `${goal.firstName} ${goal.lastName}, ${goal.age}`;
    }
}