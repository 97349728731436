import {MemberGroup, MemberGroupMember} from "./models/InvestorGroupType";
import {LifeStatus} from "./models/MemberType";

export function mapMemberGroupToList(memberGroup: MemberGroup, disableOutOfEstate = false): MemberGroupMember[] {
    const members = [memberGroup.primaryMember];

    if (memberGroup.partnerMember) {
        members.push(memberGroup.partnerMember)
    }
    if (!disableOutOfEstate) {
        memberGroup.additionalMembers.forEach(member => members.push(member));
    }

    return members;
}

export function mapLivingMemberGroupToList(memberGroup: MemberGroup, disableOutOfEstate = false): MemberGroupMember[] {
    const memberList = mapMemberGroupToList(memberGroup, disableOutOfEstate);
    return memberList.filter(isLivingMember);
}

export function isLivingMember(member: MemberGroupMember) {
    return member.lifeStatus === LifeStatus.Living;
}
