import React from "react";
import {Button} from "../../components";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    resetFamilyTreeZoomViewportBounds,
    selectFamilyTreeViewportBounds,
    setFamilyTreeViewportBounds
} from "./FamilyTreeSlice";

const ZOOM_SCALE_INCREMENT = 0.1;
const ZOOM_SCALE_MAX = 2;
const ZOOM_SCALE_MIN = 0.3;

const FamilyTreeZoomControls: React.FC = () => {
    const viewportBounds = useAppSelector(selectFamilyTreeViewportBounds);
    const dispatch = useAppDispatch();

    return (
        <div className="family-tree-zoom">
            <Button
                iconName={'zoom_in'}
                className={'zoom-button'}
                icon={"only"}
                ariaLabel={'zoom_in'}
                role={'Family-Tree-Zoom-In'}
                kind={"borderless"}
                onClick={() => {
                    if (viewportBounds) {
                        dispatch(setFamilyTreeViewportBounds({
                            ...viewportBounds,
                            scale: Math.min(ZOOM_SCALE_MAX, viewportBounds.scale + ZOOM_SCALE_INCREMENT),
                        }));
                    }
                }}
            />
            <Button
                iconName={'zoom_out'}
                className={'zoom-button'}
                icon={"only"}
                ariaLabel={'zoom_out'}
                role={'Family-Tree-Zoom-Out'}
                kind={"borderless"}
                onClick={() => {
                    if (viewportBounds) {
                        dispatch(setFamilyTreeViewportBounds({
                            ...viewportBounds,
                            scale: Math.max(ZOOM_SCALE_MIN, viewportBounds.scale - ZOOM_SCALE_INCREMENT),
                        }));
                    }
                }}
            />
            <Button
                iconName={'fullscreen_exit'}
                className={'zoom-button'}
                icon={"only"}
                ariaLabel={'zoom_reset'}
                role={'Family-Tree-Zoom-Reset'}
                kind={"borderless"}
                onClick={() => {
                    dispatch(resetFamilyTreeZoomViewportBounds());
                }}
            />
        </div>
    )
}
export default FamilyTreeZoomControls;