import React, {MouseEventHandler} from 'react';
import {Button} from "../Button";
import {ButtonKind} from "../Button/Button";

type PresentationPaneButtonProps = {
    className?: string;
    iconName?: string,
    kind?: ButtonKind,
    onClick: MouseEventHandler,
    disabled?: boolean
};

const PresentationPaneButton: React.FC<PresentationPaneButtonProps> = (props: PresentationPaneButtonProps) => {
    return (
        <Button
            icon={props.iconName ? 'left' : undefined}
            rounded
            size="medium"
            {...props}
        />
    );
};

export default PresentationPaneButton;