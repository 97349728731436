import {DataDisplayView} from "../ReviseAssetAllocation";
import React from "react";
import {isDollarView} from "./reviseAssetAllocationUtils";
import {NumberInput} from "../../../components";
import classNames from "classnames";

type ProposedAllocationInputProps = {
    displayView: DataDisplayView,
    assetSubclassName: string,
    minValue: number,
    totalProposedAmount: number,
    value: string | null,
    onChangeValue: (_event: React.ChangeEvent<HTMLInputElement>, value: (number | string)) => void,
    onBlur: (event: React.FocusEvent<HTMLInputElement>) => void,
    showIpcRangeWarning: boolean,
    disabled: boolean
}
export function ProposedAllocationInput(props: ProposedAllocationInputProps) {

    const maxValue = isDollarView(props.displayView) ? props.totalProposedAmount : 100;

    return <NumberInput
        removeMarginTop
        aria-label={isDollarView(props.displayView) ? `${props.assetSubclassName} Proposed $` : `${props.assetSubclassName} Proposed %`}
        size="small"
        minValue={props.minValue}
        maxValue={maxValue}
        maxLength={15}
        allowDecimals
        formatOptions={isDollarView(props.displayView) ? {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0,
            style: "decimal",
        } : {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: "decimal",
        }}
        value={props.value}
        onChangeValue={props.onChangeValue}
        onBlur={props.onBlur}
        tabIndex={0}
        className={classNames({"warning-bg": props.showIpcRangeWarning && !props.disabled})}
        disabled={props.disabled}
    />;
}