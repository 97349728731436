import {AssetSubclassesSummary} from "./AssetSubclassesSummary";


export type ProposedAllocationResponse = {
    totalRiskAssetsAmount: number;
    totalRiskControlAssetsAmount: number;
    totalRiskAssetsPercent: number;
    totalRiskControlAssetsPercent: number;
    riskAssets: AssetSubclassesSummary[];
    riskControlAssets: AssetSubclassesSummary[];
};

export type SimplifiedProposedAllocationResponse = Pick<ProposedAllocationResponse,
    'totalRiskControlAssetsPercent' | 'totalRiskAssetsPercent'>;

export const EMPTY_PROPOSED_ALLOCATION_RESPONSE: ProposedAllocationResponse = {
    totalRiskAssetsAmount: 0,
    totalRiskControlAssetsAmount: 0,
    totalRiskControlAssetsPercent: 0,
    totalRiskAssetsPercent: 0,
    riskAssets: [],
    riskControlAssets: []
};