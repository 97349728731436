import {DropdownItem, Icon, TableActionMenu} from "../../../components";
import React from "react";
import useProfileEditableState from "../../../hooks/useProfileEditableState";

type BeneficiaryGoalActionMenuProps = {
    onEditGoal: () => void,
    onDeleteGoal: () => void,
    onApplyFamilyAsset: () => void,
    showApplyFamilyAssets?: boolean,
    enableApplyFamilyAssets?: boolean
}

const BeneficiaryGoalActionMenu = ({
                                       onEditGoal,
                                       onDeleteGoal,
                                       onApplyFamilyAsset,
                                       showApplyFamilyAssets,
                                       enableApplyFamilyAssets
                                   }: BeneficiaryGoalActionMenuProps) => {
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();
    return (
        <TableActionMenu className="paddingright-0" panelWidth={240}>
            <DropdownItem
                id="editBeneficiaryGoal"
                onClick={onEditGoal}
                itemText={`Edit Goal`}
                value={'Edit Goal'}
            />
            {showApplyFamilyAssets && <DropdownItem
                id="applyFamilyAssets"
                onClick={onApplyFamilyAsset}
                itemText={`Apply Family Assets`}
                disabled={!enableApplyFamilyAssets}
                value={'Apply Family Assets'}
            />}
            <DropdownItem
                id="deleteBeneficiaryGoal"
                onClick={onDeleteGoal}
                itemText={`Delete Goal`}
                value={'Delete Goal'}
                disabled={isProfileWithProposalsOrArchived}
            >
                <Icon name="delete" className="goals-table-delete-icon"/>
            </DropdownItem>
        </TableActionMenu>
    )
};

export default BeneficiaryGoalActionMenu;