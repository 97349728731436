import PrintViewWrapper from "./DetailedEmailReports/PrintViewWrapper";
import {PresentationPaneHeader} from "../components";
import React, {ReactNode} from "react";

type DisclaimerWrapperProps = {
    children: ReactNode,
    displayName: string,
    title:string,
    subtitle?:string
    keyValue:string
}

const DisclaimerWrapper = ({children, displayName, title, subtitle,keyValue} : DisclaimerWrapperProps) => {

    return (
        <PrintViewWrapper displayName={displayName} showFooter={false} key={keyValue}>
            <PresentationPaneHeader
                displayName=""
                title={title}
                subtitle={subtitle}
            />
            <div className="disclaimer-page">
                {children}
            </div>
        </PrintViewWrapper>
    );
};
export default DisclaimerWrapper;