import React, {useEffect, useState} from "react";
import {Checkbox} from "xps-react";
import ModalWrapper from "../../../components/Modal/ModalWrapper/ModalWrapper";
import {TrustInflowType} from "../../models/GeneralInflow";


type ConfirmReviewDisclosureProps = {
    trustInflowType: TrustInflowType,
    isOpen: boolean;
    isDisclosureReviewed: boolean;
    onCancel: () => void,
    onSave: (isReviewed: boolean) => void,
}

const ConfirmReviewDisclosure = ({
                                     trustInflowType,
                                     isOpen,
                                     isDisclosureReviewed,
                                     onCancel,
                                     onSave,
                                 }: ConfirmReviewDisclosureProps) => {

    const [isChecked, setIsChecked] = useState(isDisclosureReviewed);

    useEffect(() => {
        setIsChecked(isDisclosureReviewed);
    }, [isDisclosureReviewed]);
    
    const handleOnClickCancel = () => {
        setIsChecked(isDisclosureReviewed);
        onCancel();
    };

    const getTextContent = () => {
        let headerText = '';
        let bodyText = '';

        switch(trustInflowType) {
            case 'NT - Base Budget':
                headerText = 'Discretionary Distribution Disclosure';
                bodyText = 'This amount represents historical discretionary distributions from trusts for which ' +
                    'Northern Trust serves as trustee. Future distributions are subject to Northern Trust\'s ' +
                    'discretionary distribution approval process. Historical figures have been incorporated ' +
                    'for illustrative purposes only and cannot be relied upon as a commitment by Northern Trust ' +
                    'to provide discretionary distributions from a trust in future years or as a measure of ' +
                    'investment performance.';
                break;
            case 'NT - Unitrust':
                headerText = 'Northern Trust Unitrust Disclosure';
                bodyText = 'The actual payment amount may increase or decrease based on actual market conditions. ' +
                    'The numbers reflected are an estimate and should not be relied upon.';
                break;
        }

        return {
            title: headerText,
            body: bodyText
        };
    };

    const {title, body} = getTextContent();

    return (
        <ModalWrapper
            id="discard-changes-modal"
            isOpen={isOpen}
            headerText={title}
            size="large"
            buttons={[
                {
                    text: 'Cancel',
                    onClick: handleOnClickCancel
                },
                {
                    text: 'Save',
                    onClick: () => onSave(isChecked),
                    ariaLabel: 'Save Disclosure',
                    primary: isChecked
                }
            ]}
        >
            <div className="display-flex flex-column">
                <div aria-label={`${title} Body`} className="font-sm">
                    {body}
                </div>
                <br/>
                <div>
                    <Checkbox
                        name="ackDisclosureCheckBox"
                        aria-label="I acknowledge that I have reviewed this information with a client."
                        value="I acknowledge that I have reviewed this information with a client."
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                    />
                </div>
            </div>
        </ModalWrapper>
    );
}

export default ConfirmReviewDisclosure;
