import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {GoalModelType} from "../../Goals/models/GoalModelType";
import {selectGoalModel, setGoalModel} from "../../Goals/controller/GoalsModelSlice";
import {NO_OP} from "../../constants/common";
import LoadingIndicator from "../../pages/LoadingIndicator";
import Summary from "../../Goals/Summary/Summary";
import {ReportConfig} from "../models/Reporting";
import PrintViewWrapper from "../DetailedEmailReports/PrintViewWrapper";
import GoalsSummaryContainer from "../DetailedEmailReports/GoalsDetailsReport/GoalsSummaryContainer";
import GoalsService from "../../Goals/controller/GoalsService";
import {genericEmptyErrorModalData, GenericErrorModalData} from "../../components/Modal/Error/GenericErrorModal";
import GoalsPrioritizationContentReport
    from "../DetailedEmailReports/GoalsPrioritizationReport/GoalsPrioritizationContentReport";

type GoalsSummaryReportsProps = {
    reportConfig: ReportConfig,
    displayName: string
}

const GoalsSummaryReport = ({reportConfig, displayName}: GoalsSummaryReportsProps) => {
    const {id} = useParams<RouteWithId>();
    const previousGoalModel = useAppSelector<GoalModelType>(selectGoalModel);
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const handleError = ((traceId: string) => {
        setGenericError({
            isOpen: true,
            header: 'Communication Failure',
            message: 'There has been a communication failure.  The data you see may not be accurate, please refresh your browser.  If this error continues, please contact the support team.',
            operationId: traceId
        });
    });

    useEffect(() => {
        GoalsService.getGoalsModel(id, previousGoalModel, handleError)
            .then(goalResponse => {
                dispatch(setGoalModel(goalResponse))
                setIsLoading(false)
            })
    }, [id]);

    if (isLoading) {
        return <LoadingIndicator/>
    }
    return <>
        {reportConfig.goalsSummary.isEnabled &&
            <PrintViewWrapper displayName={displayName}>
                <Summary
                    onEditFamilyGoal={NO_OP}
                    onViewFamilyGoal={NO_OP}
                    onEditNonLifestyleGoal={NO_OP}
                    onDeleteNonLifestyleGoal={NO_OP}
                /></PrintViewWrapper>}
        {
            reportConfig.goalsSummaryDetailed.isEnabled &&
            <GoalsSummaryContainer displayName={displayName} />
        }
        {
            reportConfig.goalsPrioritization.isEnabled &&
            <GoalsPrioritizationContentReport displayName={displayName}/>
        }
    </>

}

export default GoalsSummaryReport;