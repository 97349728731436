import UnderlinedHeader from "../../../components/UnderlinedHeader/UnderlinedHeader";
import Button from "../../../components/Button/Button";
import Icon from "../../../components/Icon/Icon";
import React from "react";

type DisclosureForTrustInflowProps = {
    onReviewDisclosure: () => void,
    isDisclosureReviewed: boolean
}

const DisclosureForTrustInflow = ({
                                      onReviewDisclosure,
                                      isDisclosureReviewed
                                  }: DisclosureForTrustInflowProps) => {
    return (
        <>
            <UnderlinedHeader
                className="inflow-characteristics-section-header"
                primaryText="Disclosures"
            />
            <Button
                className="marginright-md"
                disabled={false}
                icon="none"
                kind="primary"
                onClick={onReviewDisclosure}
                size="medium"
                type="button"
            >
                Review Disclosures
            </Button>&nbsp;&nbsp;
            {isDisclosureReviewed ?
                <><Icon name={'status_validated'} color={'#64a70b'}/> <span
                    className="paddingleft-sm">Reviewed with Client</span></>
                 :
                <><Icon
                    name="warning"
                    type="warning"/><span> <b>Not Reviewed with Client</b></span></>
            }
        </>
    )
}

export default DisclosureForTrustInflow;