import {OwnershipReadModel, OwnershipWriteModel} from "./Ownership";
import {Asset} from "./Asset";

// Domain types
export type LifeInsurance = Asset & {
    description: string,
    type: lifeInsuranceType,
    insuredMemberId: string,
    deathBenefitValue: number,
    insuranceCompanyName: string,
    issueDate: string | null,
    cashValue: number,
    isCashValueWillFundGoals: boolean,
    expirationDate: string | null
    term: number,
    beneficiaries: Beneficiary[],
};

type lifeInsuranceType = "Term" | "Whole" | "Universal";

export type Beneficiary = {
    id: string,
    memberId : string | null,
    percentage: string,
    entityName : string | null
}

// UI Type
export type LifeInsuranceFormData = Omit<LifeInsurance, 'profileId' | 'ordinal' | keyof OwnershipReadModel>;

// Network types
export type CreateLifeInsuranceRequest =
    LifeInsuranceFormData & OwnershipWriteModel;

export type UpdateLifeInsuranceRequest = LifeInsuranceFormData & OwnershipWriteModel & {
    id: string,
    profileId: string
};

export enum lifeInsuranceTypes {
    TERM = 'Term',
    WHOLE = 'Whole',
    UNIVERSAL = 'Universal'
}

export const SECOND_TO_DIE_ID = "a8cd85ce-5a05-433b-836e-5e25b6cb70dd";
export const SECOND_TO_DIE= "Second To Die"
export const EX_SPOUSE_LIFE_INSURANCE= "EX_SPOUSE"