import React from "react";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectApprovedProfile} from "./approvedProfileSlice";
import {selectProfile} from "./activeProfileSlice";
import {useHistory} from "react-router-dom";
import {Button} from "../../components";
import {resetShowOutOfEstateAssets} from "../../Assets/common/OutOfEstate/OutOfEstateAssetsSlice";
import {resetAccordionPreferencesState} from "../../Assets/common/accordionPreferencesSlice";


type ProfileControlsProps = {
    pathName: string
}

const CloseProfile = ({pathName}:ProfileControlsProps) => {
    const dispatch = useAppDispatch();
    const id = useAppSelector(selectApprovedProfile)?.id;
    const profileIdForProposal = useAppSelector(selectProfile)?.profileIdForProposal;
    const history = useHistory();
    const closeButtonText = profileIdForProposal ? 'CLOSE PROPOSAL' : 'CLOSE PROFILE';
    const disableCloseButton = pathName.includes("Resources") ? true : false;
    return (
        <Button
            className={"close-profile-button"}
            id="closeProfile"
            icon="none"
            includeRef={false}
            kind="primary"
            size="medium"
            tabIndex={0}
            type="button"
            disabled={disableCloseButton}
            onClick={() => {
                dispatch(resetAccordionPreferencesState(id!));
                dispatch(resetShowOutOfEstateAssets());
                history.push(`/Profile/${id}`)
            }}
        >
            {closeButtonText}
        </Button>
    )
};

const ProfileControls = ({pathName}:ProfileControlsProps) => {
    return (
        <div
            className="profile-controls"
        >
            <CloseProfile pathName={pathName}/>
        </div>
    )
}


export default ProfileControls;
