import React, {ReactNode} from 'react';
import classNames from "classnames";

interface UnderlinedHeaderProps {
    className?: string,
    primaryText?: string,
    rightAlignedContent?: ReactNode,
}

const UnderlinedSubHeader = ({
                                 className,
                                 primaryText,
                                 rightAlignedContent,
                             }: UnderlinedHeaderProps) => {
    return (
        <header className={classNames('underlined-sub-header', className)}>
            <h5>{primaryText}</h5>
            {rightAlignedContent ? <span className="underlined-sub-header__right-content">{rightAlignedContent}</span> :
                <React.Fragment/>}
        </header>
    );
};

export default UnderlinedSubHeader;
