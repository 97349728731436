export const InstitutionOptions = [
    { label: "Fidelity", value: "Fidelity" },
    { label: "Charles Schwab", value: "Charles Schwab" },
    { label: "Morgan Stanley", value: "Morgan Stanley" },
    { label: "Vanguard", value: "Vanguard" },
    { label: "JP Morgan", value: "JP Morgan"},
    { label: "Chase", value: "Chase"},
    { label: "Goldman Sachs", value: "Goldman Sachs" },
    { label: "UBS", value: "UBS" },
    { label: "Merrill Lynch", value: "Merrill Lynch" },
    { label: "Wells Fargo", value: "Wells Fargo" },
    { label: "Bank of America", value: "Bank of America" },
    { label: "Northwestern Mutual", value: "Northwestern Mutual" },
    { label: "Raymond James", value: "Raymond James" },
    { label: "TD Ameritrade", value: "TD Ameritrade" },
    { label: "BMO Harris", value: "BMO Harris" },
    { label: "Eaton Vance", value: "Eaton Vance" },
    { label: "Bessemer Trust", value: "Bessemer Trust" },
    { label: "U.S. Bank", value: "U.S. Bank" },
    { label: "E*Trade", value: "E*Trade" },
    { label: "Plante Moran", value: "Plante Moran" },
    { label: "Bernstein", value: "Bernstein" },
    { label: "Edward Jones", value: "Edward Jones" },
    { label: "BNY Mellon", value: "BNY Mellon" },
    { label: "RBC", value: "RBC" },
    { label: "U.S. Trust", value: "U.S. Trust" },
    { label: "Northern Trust", value: "Northern Trust"},
    { label: "ADP", value: "ADP"},
    { label: "AIG", value: "AIG"},
    { label: "American Express", value: "American Express"},
    { label: "Allianz", value: "Allianz"},
    { label: "Ally Bank", value: "Ally Bank"},
    { label: "Ameriprise Financial", value: "Ameriprise Financial"},
    { label: "Ariel Investments", value: "Ariel Investments"},
    { label: "Barclays", value: "Barclays"},
    { label: "Blackrock", value: "Blackrock"},
    { label: "Capital One", value: "Capital One"},
    { label: "CIBC", value: "CIBC"},
    { label: "Citibank", value: "Citibank"},
    { label: "Citizens Bank", value: "Citizens Bank"},
    { label: "Coinbase", value: "Coinbase"},
    { label: "Deutsche Bank", value: "Deutsche Bank"},
    { label: "Equitable", value: "Equitable"},
    { label: "First American", value: "First American"},
    { label: "First Citizens Bank", value: "First Citizens Bank"},
    { label: "First Community Bank", value: "First Community Bank"},
    { label: "First Republic", value: "First Republic"},
    { label: "Fifth Third Bank", value: "Fifth Third Bank"},
    { label: "HSBC", value: "HSBC"},
    { label: "Invesco", value: "Invesco"},
    { label: "Lincoln Mutual", value: "Lincoln Mutual"},
    { label: "Mass Mutual", value: "Mass Mutual"},
    { label: "MetLife", value: "MetLife"},
    { label: "Navy Federal Credit Union", value: "Navy Federal Credit Union"},
    { label: "Nationwide", value: "Nationwide"},
    { label: "Old National Bank", value: "Old National Bank"},
    { label: "Peoples Bank", value: "Peoples Bank"},
    { label: "PNC Bank", value: "PNC Bank"},
    { label: "Prudential", value: "Prudential"},
    { label: "Robinhood", value: "Robinhood"},
    { label: "T. Rowe Price", value: "T. Rowe Price"},
    { label: "Transamerica", value: "Transamerica"},
    { label: "TIAA", value: "TIAA"},
    { label: "USAA", value: "USAA"},
    { label: "Wintrust", value: "Wintrust"},
    { label: "William Blair", value: "William Blair"},
    { label: "None", value: "None" },
    { label: "Other", value: "Other" }
];