import {AssetRelianceAssetType, AssetRelianceStack} from "../models/AssetRelianceResponse";
import {Col} from "react-grid-system";

export const getStackIdentity = (stack: AssetRelianceStack): string => {
    return stack && (stack.stackAssetType ? String(stack.stackAssetType) : '')
        + (stack.stackAssetId ? '_' + String(stack.stackAssetId) : '');
};

export const getAssetIdentity = (assetType: AssetRelianceAssetType | null | undefined, assetId: string | null | undefined) => {
    return (assetType ? String(assetType) : '')
        + (assetId ? '_' + String(assetId) : '');
};

export const getRefWidth: (element: Col & HTMLDivElement | null) => number =
    (element) => element?.getBoundingClientRect().width ?? 0;

export const hasPositiveExcessAssets = (excessAssets: number): boolean => {
    return excessAssets >= 0;
}