import React from "react";
import {formatCurrency} from "../../utils/format";
import {InfoPopover} from "../../components";


export const NetWorthOverTimeGoalsAchievedTable = ({
                                                       selectedAge,
                                                       planningPeriodAgeFrom,
                                                       familyGoalValues,
                                                       discretionaryGoalValues,
                                                       philanthropicGoalValues,
                                                       lifestyleGoalValues,
                                                   }: {
    selectedAge: number,
    planningPeriodAgeFrom: number,
    familyGoalValues: number[],
    discretionaryGoalValues: number[],
    philanthropicGoalValues: number[],
    lifestyleGoalValues: number[],
}) => {
    const indexOfSelectedAge = selectedAge - planningPeriodAgeFrom;
    const lifestyleGoalsAchieved = lifestyleGoalValues
        .slice(0, indexOfSelectedAge)
        .reduce((accum, value) => accum + value, 0);
    const discretionaryGoalsAchieved = discretionaryGoalValues
        .slice(0, indexOfSelectedAge)
        .reduce((accum, value) => accum + value, 0);
    const philanthropicGoalsAchieved = philanthropicGoalValues
        .slice(0, indexOfSelectedAge)
        .reduce((accum, value) => accum + value, 0);
    const familyGoalsAchieved = familyGoalValues
        .slice(0, indexOfSelectedAge)
        .reduce((accum, value) => accum + value, 0);
    const goalsPriorToSelectedAge =
        familyGoalsAchieved +
        discretionaryGoalsAchieved +
        philanthropicGoalsAchieved +
        lifestyleGoalsAchieved;
    const familyGoalAtSelectedAge = familyGoalValues[indexOfSelectedAge] || 0;
    const discretionaryGoalAtSelectedAge = discretionaryGoalValues[indexOfSelectedAge] || 0;
    const philanthropicGoalAtSelectedAge = philanthropicGoalValues[indexOfSelectedAge] || 0;
    const lifestyleGoalAtSelectedAge = lifestyleGoalValues[indexOfSelectedAge] || 0;
    const goalsAtSelectedAge = familyGoalAtSelectedAge +
        discretionaryGoalAtSelectedAge +
        philanthropicGoalAtSelectedAge +
        lifestyleGoalAtSelectedAge;
    const totalGoals = goalsPriorToSelectedAge + goalsAtSelectedAge;

    return (<div className="net-worth-over-time-goal-table">
        <div className="goals-table-title">
            <span>Goals Achieved at Age {selectedAge}</span>
        </div>
        <div>
            <div className="goals-table-details">
                <span>Achieved Goals</span>
                <div>
                    <span className="goals-table-values">{formatCurrency(goalsPriorToSelectedAge)}</span>
                    <InfoPopover
                        content={
                            <div>
                                <div className="goals-popover-condensed-subtitle">
                                    <span>Goals achieved or funded from today to age {selectedAge}:</span>
                                </div>
                                <hr/>
                                    <ul className="goals-popover-categories-and-data">
                                    <li>
                                        <div>
                                            <span className="goals-dot lifestyle-spending-dot"></span>
                                            <span>Lifestyle Spending</span>
                                        </div>
                                        <span>{lifestyleGoalsAchieved == 0 ? '-' : formatCurrency(lifestyleGoalsAchieved)}</span>
                                    </li>
                                    <li>
                                        <div>
                                            <span className="goals-dot discretionary-dot"></span>
                                            <span>Discretionary</span>
                                        </div>
                                        <span>{discretionaryGoalsAchieved == 0 ? '-' : formatCurrency(discretionaryGoalsAchieved)}</span>
                                    </li>
                                    <li>
                                        <div>
                                            <span className="goals-dot philanthropic-dot"></span>
                                            <span>Philanthropic</span>
                                        </div>
                                        <span>{philanthropicGoalsAchieved == 0 ? '-' : formatCurrency(philanthropicGoalsAchieved)}</span>
                                    </li>
                                    <li>
                                        <div>
                                            <span className="goals-dot family-dot"></span>
                                            <span>Family</span>
                                        </div>
                                        <span>{familyGoalsAchieved == 0 ? '-' : formatCurrency(familyGoalsAchieved)}</span>
                                    </li>
                                </ul>
                            </div>
                        }
                        direction="right"
                        width="375px"
                        arrow={true}
                    />
                </div>
            </div>
            <div className="goals-table-details">
                <span>Goals at Age {selectedAge}</span>
                <div>
                    <span className="goals-table-values">{formatCurrency(goalsAtSelectedAge)}</span>
                    <InfoPopover
                        content={
                            <div>
                                <div className="goals-popover-condensed-subtitle">
                                    <span>Goals to be funded at age {selectedAge}:</span>
                                </div>
                                <hr/>
                                <ul className="goals-popover-categories-and-data">
                                    <li>
                                        <div>
                                            <span className="goals-dot lifestyle-spending-dot"></span>
                                            <span>Lifestyle Spending</span>
                                        </div>
                                        <span>{lifestyleGoalAtSelectedAge == 0 ? '-' : formatCurrency(lifestyleGoalAtSelectedAge)}</span>
                                    </li>
                                    <li>
                                        <div>
                                            <span className="goals-dot discretionary-dot"></span>
                                            <span>Discretionary</span>
                                        </div>
                                        <span>{discretionaryGoalAtSelectedAge == 0 ? '-' : formatCurrency(discretionaryGoalAtSelectedAge)}</span>
                                    </li>
                                    <li>
                                        <div>
                                            <span className="goals-dot philanthropic-dot"></span>
                                            <span>Philanthropic</span>
                                        </div>
                                        <span>{philanthropicGoalAtSelectedAge == 0 ? '-' : formatCurrency(philanthropicGoalAtSelectedAge)}</span>
                                    </li>
                                    <li>
                                        <div>
                                            <span className="goals-dot family-dot"></span>
                                            <span>Family</span>
                                        </div>
                                        <span>{familyGoalAtSelectedAge == 0 ? '-' :formatCurrency(familyGoalAtSelectedAge)}</span>
                                    </li>
                                </ul>
                            </div>
                        }
                        direction="right"
                        width="375px"
                        arrow={true}
                    />
                </div>
            </div>
        </div>
        <hr style={{marginTop: "14px", marginBottom: "4px"}}></hr>
        <div className="goals-table-details">
            <span>Total Goals at Age {selectedAge}</span>
            <span className="goals-table-values goals-table-last-row">{formatCurrency(totalGoals)}</span>
        </div>
    </div>);
}