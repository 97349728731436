import {PersonalAsset} from "../../models/PersonalAsset";
import {DeleteModalProps} from "../../common/AssetsView";
import {PersonalAssetRow} from "./PersonalAssetRow";
import {AssetSummaryPersonalAssetDetails} from "./AssetSummaryPersonalAssetDetails";
import React from "react";
import {AssetsViewContextValue} from "../../common/AssetsViewContext";
import {useHistory} from "react-router-dom";
import DragDrop from '@nt/dds-react/core/DragDrop';
import {Icon} from "../../../components";
import {DropResult} from "react-beautiful-dnd";
import {NO_OP} from "../../../constants/common";

type PersonalAssetsTableProps = {
    inEstatePersonalAssets: PersonalAsset[];
    profileId: string,
    viewType: AssetsViewContextValue,
    onDeleteAssetClick: (deleteModalProps: DeleteModalProps) => void,
    hideActionMenu?: boolean,
    isDraggable?: boolean,
    capturePersonalAssetsOrder: ({source, destination}: DropResult) => void
}
export default function PersonalAssetsTableSection({
                                                       inEstatePersonalAssets,
                                                       profileId,
                                                       viewType,
                                                       onDeleteAssetClick,
                                                       hideActionMenu = false,
                                                       isDraggable = false,
                                                       capturePersonalAssetsOrder = NO_OP
                                                   }: PersonalAssetsTableProps) {

    const history = useHistory();

    function renderPersonalAssetRow(inEstatePersonalAsset: PersonalAsset) {
        return <PersonalAssetRow personalAsset={inEstatePersonalAsset}
                                 key={inEstatePersonalAsset.id}
                                 renderPersonalAssetDetails={(personalAsset: PersonalAsset) => {
                                     return <AssetSummaryPersonalAssetDetails
                                         personalAsset={personalAsset}/>
                                 }}
                                 actionsDisabled={false}
                                 hideActionMenu={hideActionMenu}
                                 gridClassName={"assets-grid-with-actionmenu"}
                                 onClickEdit={(personalAssetId: string) => {
                                     history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditPersonalAsset/${personalAssetId}`);
                                 }}
                                 onClickDelete={(personalAsset: PersonalAsset) => {
                                     onDeleteAssetClick({
                                         showDeleteModal: true,
                                         modalTitle: "Asset",
                                         assetDescription: personalAsset.description ?? "",
                                         assetType: "personal asset",
                                         assetId: personalAsset.id,
                                     });
                                 }}/>;
    }

    if (isDraggable) {
        return (
            <DragDrop
                className="draggable"
                direction="vertical"
                draggableId="personalAssetDraggableId1"
                droppableId="personalAssetDroppableId1"
                onDragEnd={capturePersonalAssetsOrder}
                items={
                    inEstatePersonalAssets.map((inEstatePersonalAsset: PersonalAsset) =>
                        <div className="display-flex align-items-right" tabIndex={-1} key={inEstatePersonalAsset.id} data-testid="draggable-personal-asset">
                            <Icon ariaHidden className="drag__icon" name="drag_vertical"/>
                            {renderPersonalAssetRow(inEstatePersonalAsset)}
                        </div>
                    )
                }>
            </DragDrop>
        );
    }

    return (
        <>
            {inEstatePersonalAssets.map(renderPersonalAssetRow)}
        </>
    );
}