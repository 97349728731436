/* data types */

import {TaxStatusOptions} from "./TaxDetails";
import {Optional} from "../../utils/customTypes";
import {
    OwnershipDetailsFormData,
    OwnershipReadModel,
    OwnershipWriteModel
} from "./Ownership";
import {Asset} from "./Asset";
import {PartiallyOwnedLegalAgreement} from "./PartiallyOwnedInvestmentAccount";
import {AccountBase, LegalAgreementBase} from "./Assets";
import {EstateValue, HoldingAssetSubclassDetails, HoldingSummary} from "./Holding";
import holdings from "../StandaloneAccount/Holdings/AddProducts/Holdings";

export type InvestmentProgramsResponse = {
    totalPages: number,
    pageNumber: number,
    pageSize: number,
    totalInvestmentPrograms: number,
    investmentPrograms: InvestmentProgram[],
}

export const EMPTY_INVESTMENT_PROGRAM_RESPONSE: InvestmentProgramsResponse = {
    totalPages: 0,
    pageNumber: 1,
    pageSize: 25,
    totalInvestmentPrograms: 0,
    investmentPrograms: []
};

export type InvestmentProgram = {
    name: string,
    id: string,
    portfolioManagerName: string,
    status: string,
    lastUpdatedDate: string,
    totalMarketValue: number,
    legalAgreements: LegalAgreement[],
    heldAwayAccounts: HeldAwayAccountSummary[],
};

export type RefreshHoldingResponse = {
    investmentProgramSummaryWithHoldingsResponse: InvestmentProgram | null,
    partiallyOwnedLegalAgreementWithHoldings: PartiallyOwnedLegalAgreement[]
}

export type DomesticTrustAccount = AccountBase;

export type LegalAgreement = LegalAgreementBase & {
    name: string,
    accountNumbers: string[],
    financialAccountPersistentIds: string[],
    domesticTrustAccounts: DomesticTrustAccount[]
}

export type HeldAwayAccountDetails = {
    financialAccountName: string,
    financialAccountNumber: string,
    financialAccountPersistentId: string,
    valueAsOfDate: string,
    institution: string,
    baseMarketValue: number,
    investableValue: number,
    entityType: string,
    isEntityCapitalGains: boolean | null,
    isLiabilityPaidByPortfolio: boolean | null,
    taxStatus: TaxStatusOptions,
    investmentProgramAssociationId: string,
    doesPermitBeneficiary: boolean,

}
export type HeldAwayAccount = HeldAwayAccountDetails & Asset

export type HeldAwayAccountSummary = {
    financialAccountName: string,
    financialAccountNumber: string,
    financialAccountPersistentId: string,
    valueAsOfDate: string,
    institution: string,
    baseMarketValue: number,
    investableValue: number,
    entityType: string,
    isEntityCapitalGains: boolean | null,
    isLiabilityPaidByPortfolio: boolean | null,
    taxStatus: TaxStatusOptions,
    investmentProgramAssociationId: string
    holdings: HoldingSummary,
    doesPermitBeneficiary: boolean,
    marketEstateValue: EstateValue,
    marketValue: {
        inEstateValue: number,
        outOfEstateValue: number,
        totalValue: number
    },
} & Asset;

export type HeldAwayAccountRequest = HeldAwayAccountDetails & OwnershipWriteModel & {
    id: string,
    profileId: string,
    ordinal: number
}

export type HeldAwayAccountHoldingsRequest = {
    allHoldings: HoldingAssetSubclassDetails[],
    asOfDate: string
}

/*---------Network Types-------------*/

export type CreateInvestmentProgramRequest = {
    investmentProgramId: string,
    profileId: string,
    isTeam: boolean,
};

export type UpdateLegalAgreementRequest = Pick<LegalAgreement, 'name' | 'isEntityCapitalGains' |
    'isLiabilityPaidByPortfolio'> & OwnershipWriteModel;

type HeldAwayAccountWithMaybeId = Optional<HeldAwayAccount, 'id'>

export type HeldAwayAccountFormData =
    Omit<HeldAwayAccountWithMaybeId, NonFormDataFields>
    & OwnershipDetailsFormData;

type LegalAgreementWithMaybeId = Optional<LegalAgreement, 'id'>

type NonFormDataFields =
    'profileId'
    | 'personalLiabilities'
    | 'inEstateValue'
    | 'marketValue'
    | keyof OwnershipReadModel;

export type LegalAgreementFormData =
    Omit<LegalAgreementWithMaybeId, NonFormDataFields>
    & OwnershipDetailsFormData;