import React, {FC} from 'react';
import ModalWrapper from "../Modal/ModalWrapper/ModalWrapper";

export type DiscardModalProps = {
    itemType: string;
    isOpen: boolean;
    onClickKeepEditing: () => void,
    onClickDiscardChanges: () => void,
    isEditing: boolean
}

const DiscardModal: FC<DiscardModalProps> = ({ itemType, isOpen = false, onClickKeepEditing, onClickDiscardChanges, isEditing = false }) => (
    <ModalWrapper
        id="discard-changes-modal"
        isOpen={isOpen}
        headerText={isEditing ? `Discard changes to this ${itemType}?` : `Discard this ${itemType}?`}
        buttons={[
            {
                text: 'Keep Editing',
                onClick: onClickKeepEditing,
            },
            {
                text: 'Discard Changes',
                onClick: onClickDiscardChanges,
                destructive: true,
                primary: true,
            }
        ]}
    >
        <div className="font-md">Any data entered for this {itemType} will not be saved.</div>
    </ModalWrapper>
)

export default DiscardModal;
