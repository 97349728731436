import classNames from "classnames";
import {formatAllocationAmount, formatAllocationPercentage} from "./reviseAssetAllocationUtils";
import React from "react";

type ReviseAssetClassLevelTwoProps = {
    assetClassName: string,
    dollarView: boolean,
    totalsForLevel2Class: any,
    showIPCRange: boolean,
    args: {
        "revise-allocation-dollar-view-without-ipc": boolean;
        "revise-allocation-percentage-view": boolean;
        "revise-allocation-dollar-view": boolean;
        "revise-allocation-percentage-view-without-ipc": boolean
    }
}

export const ReviseAssetClassLevelTwo = (props: ReviseAssetClassLevelTwoProps) => {
    const {
        assetClassName,
        dollarView,
        totalsForLevel2Class,
        showIPCRange,
        args
    } = props;
    return <div className={classNames(
        args,
        "revise-allocation-class-grid revise-allocation-assetclass-row"
    )}
                role="row"
                tabIndex={0}
                aria-label={`${assetClassName} row`}>
        <div className="grid-left-contents">
            <span role="gridcell" aria-label={`${assetClassName} Name`}>
                <span className="subclass-name">
                    <span className="marginright-12">{assetClassName}</span>
                </span>
            </span>
            <span role="gridcell" className="textalign-right"
                  aria-label={`${assetClassName} Total Current ${dollarView ? "$" : "%"}`}>
                {dollarView ?
                    formatAllocationAmount(totalsForLevel2Class[assetClassName].totalCurrentInvestableAmount) :
                    formatAllocationPercentage(totalsForLevel2Class[assetClassName].totalCurrentInvestablePercent)}
            </span>
            <span role="gridcell" className="textalign-right"
                  aria-label={`${assetClassName} Total Recommended Target ${dollarView ? "$" : "%"}`}>
                {dollarView ?
                    formatAllocationAmount(totalsForLevel2Class[assetClassName].totalRecommendedProposedTargetAmount) :
                    formatAllocationPercentage(totalsForLevel2Class[assetClassName].totalRecommendedProposedTargetPercent)}
            </span>
            {showIPCRange &&
                <>
                    <span role="gridcell" className="textalign-right grid-span-2"></span>
                </>
            }
            <span role="gridcell" className={`textalign-right ${dollarView ? "proposed-column" : "paddingright-20"}`}
                  aria-label={`${assetClassName} Total Proposed ${dollarView ? "$" : "%"}`}>
                {dollarView ?
                    formatAllocationAmount(totalsForLevel2Class[assetClassName].totalProposedInvestableAmount) :
                    formatAllocationPercentage(totalsForLevel2Class[assetClassName].totalProposedInvestablePercent)}
            </span>
        </div>

        <span className="grid-divider"></span>
        <div className="grid-right-contents">
            <span role="gridcell" className="textalign-right"
                  aria-label={`${assetClassName} ${dollarView ? " Total Difference $" : "Total % of Total Portfolio"}`}>
                {dollarView ?
                    formatAllocationAmount(totalsForLevel2Class[assetClassName].totalDifferenceAmount) :
                    formatAllocationPercentage(totalsForLevel2Class[assetClassName].totalProposedInvestablePercentOfTotalPortfolio)}
            </span>
        </div>
    </div>;
}
