import {useEffect} from "react";
import {
    selectActiveFormAsset,
    selectClientAssets,
    selectInEstateCnwAssetTotals,
    selectInEstateCnwAssetTotalsUsingDeathBenefit
} from "../../../Assets/clientAssetsSlice";
import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {LegalEntityReadModel} from "../../../Assets/models/Ownership";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {CurrentNetWorthReportView} from "./CurrentNetWorthReportView";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {setAccordionState} from "../../../components/Accordion/accordionSlice";
import {setAccordionPreferencesState} from "../../../Assets/common/accordionPreferencesSlice";
import {ProfileResponse} from "../../../ClientManagement/models/ProfileResponse";


const accordionIds = [
    {
        id: "CurrentNetWorthInEstateAssetListReport",
        expandedItems: ["AccountsCNWReport", "PersonalAssetsCNWReport", "FutureInflowsCNWReport", "LifeInsurancesCNWReport", "PersonalLiabilitiesCNWReport", "EquityCompensations"]
    }]


const accordionPreferencesIds = [
    {
        id: "CurrentNetWorthOutOfEstateAccordionReport",
        expandedItems: ["Irrevocable Trust",
            "Irrevocable Life Insurance Trust (ILIT)",
            "Charitable Foundation",
            "Donor Advised Fund (DAF)",
            "529 Plan",
            "Dynasty Trust",
            "LP, LLC, Other Partnership",
            "S Corp",
            "C Corp",
            "Other"]
    }
]

export type CurrentNetWorthReportProps = {
    showDeathBenefit: boolean,
    displayName: string,
    investorGroup: InvestorGroupType,
    legalEntities: LegalEntityReadModel[],
    isDetailed: boolean,
    profile: ProfileResponse
}

export const CurrentNetWorthReport = ({
                                          showDeathBenefit,
                                          displayName,
                                          isDetailed,
                                          investorGroup,
                                          legalEntities,
                                          profile
                                      }: CurrentNetWorthReportProps) => {
    const dispatch = useAppDispatch();

    const clientAssets = useAppSelector(selectClientAssets);
    const inEstateCnwAssetTotals = useAppSelector(showDeathBenefit ? selectInEstateCnwAssetTotalsUsingDeathBenefit : selectInEstateCnwAssetTotals)!;
    const activeFormAsset = useAppSelector(selectActiveFormAsset);

    useEffect(() => {
        accordionIds.forEach(accordion => {
            dispatch(setAccordionState({
                accordionId: accordion.id,
                state: {
                    expandedItems: accordion.expandedItems
                }
            }));
        });

        accordionPreferencesIds.forEach(accordion => {
            dispatch(setAccordionPreferencesState({
                accordionId: accordion.id,
                state: {
                    expandedItems: accordion.expandedItems
                }
            }));
        })
    }, [clientAssets]);

    if (!clientAssets || !investorGroup || !legalEntities) {
        return <LoadingIndicator/>;
    }

    return <CurrentNetWorthReportView
        profileId={profile.id}
        isDetailed={isDetailed}
        clientAssets={clientAssets}
        investorGroup={investorGroup}
        showDeathBenefit={showDeathBenefit}
        displayName={displayName}
        inEstateTotals={inEstateCnwAssetTotals}
        activeFormAsset={activeFormAsset!}
        legalEntities={legalEntities}
    />
}
