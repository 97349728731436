import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {toggleAccordionTable} from "./AccordionTableSlice";
import {useCallback} from "react";

const useAccordionTableToggle = (id: string) => {
    const dispatch = useAppDispatch();
    const isExpanded = useAppSelector((state) => {
        return Boolean(state.client.accordionTable.expanded[id]);
    });

    const toggleExpanded = useCallback(() => {
        dispatch(toggleAccordionTable(id));
    }, [id]);

    return {isExpanded, toggleExpanded};
};

export default useAccordionTableToggle;