import React from "react";

type InlineAlertProps = {
    message: string;
    className?: string;
}


export const InlineAlert: React.FC<InlineAlertProps> = ({
                                                            className,
                                                            message
                                                        }) => {
    return message
        ? <div className={className} data-testid="inlineAlert">{message}</div>
        : null;
}