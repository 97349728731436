import React from "react";
import SetPortfolioReserveHeader from "./SetPortfolioReserveHeader";
import EditPortfolioReserveTable from "./EditPortfolioReserveTable";
import ScrollableContainer from "../../components/ScrollableContainer/ScrollableContainer";

const EditPortfolioReserve = () => {
    return (
        <div className="edit-portfolio-reserve-page">
            <ScrollableContainer id={'edit-portfolio-reserve-page-scroll-container'}>
                <SetPortfolioReserveHeader displaySetReserveTargetButton={false} isSetReserve={false}/>
                <EditPortfolioReserveTable/>
            </ScrollableContainer>
        </div>
    );
};

export default EditPortfolioReserve;

