import React from "react";
import {Link} from "react-router-dom";
import {Col, Loader, Row} from "xps-react";
import {Icon} from "../../components";
import {HistoricalMeeting} from "./Meeting";
import moment from "moment";
import {DISPLAY_LONG_DATE_FORMAT} from "../../constants/common";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../ClientProfile/activeProfileSlice";

const MeetingHistoryFooter: React.FC = () => {
    const {id} = useAppSelector(selectProfile);
    return (
        <div className={"meeting-history-footer"}>
            <Link to={`/Profile/${id}/MeetingsHistory`}>
                <span className={"view-all"}>VIEW ALL</span>
            </Link>
        </div>
    )
};

const CalendarIcon: React.FC = () => <Icon name={"calendar_date"}/>;

const MeetingHistoryListItem: React.FC<{ meeting: HistoricalMeeting }> = ({meeting}) => (
    <Row role={"listitem"} className="meeting-history-item">
        <Col className="history-item__icon"><CalendarIcon/></Col>
        <Col className="history-item__details">
            <Row><b>{meeting.title}</b></Row>
            <Row><span className={"greyed-out"}>{
                moment(meeting.startDate).format(DISPLAY_LONG_DATE_FORMAT)
            }</span></Row>
        </Col>
    </Row>
);

const MeetingHistoryList: React.FC<{ meetings: HistoricalMeeting[] }> = ({meetings}) => {
    return (
        meetings?.length
            ? (<ul>{meetings.slice(0, 5).map(meeting =>
                <MeetingHistoryListItem key={meeting.id} meeting={meeting}/>)
            }</ul>)
            : <span>No meetings found</span>
    )
};

type MeetingHistorySummaryProps = {
    meetings: HistoricalMeeting[],
    isHistoryLoading: boolean
}

export const MeetingHistorySummary: React.FC<MeetingHistorySummaryProps> = ({meetings, isHistoryLoading}) => {
    return (
        <div className="meeting-history">

            {isHistoryLoading
                ? <Loader size="md"/>
                : <MeetingHistoryList meetings={meetings}/>
            }
            <MeetingHistoryFooter/>
        </div>
    )
}
