import {AssetsSummary, CurrentNetWorthAsset} from "../../../Assets/models/Assets";
import {COLOR_LIABILITIES} from "../../../constants/colors";
import {AssetAccordionHeader} from "../../../Assets/AssetSummary/common/AssetAccordionHeader";
import {AccordionItemWithActionMenu} from "../../../Assets/../components";
import React from "react";
import {formatCurrency} from "../../../utils/format";
import {PersonalLiabilitySummary} from "../../../Assets/models/PersonalLiability";
import {InvestorGroupMember, InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {AssetAccordionContentHeader} from "../../../Assets/AssetSummary/common/AssetAccordionContentHeader";
import {NO_OP} from "../../../constants/common";
import {TableCell} from "../../../components/Table/TableCell";
import {PersonalLiabilityRow} from "../../../Assets/AssetSummary/InEstate/PersonalLiabilityRow";
import {
    calculateCNWAssetsJointlyOwnedTotalPresentValue,
    calculateCNWAssetsMemberOwnedTotalPresentValue,
    calculateJointlyOwnedValue,
    calculateMemberOwnedValue
} from "../../../Assets/AssetSummary/common/AssetSummaryCalculator";
import {assetListData} from "../../../Assets/AssetSummary/common/utils";

export interface PersonalLiabilitiesProps {
    assetsDataForPage: AssetsSummary,
    investorGroup: InvestorGroupType,
    caption: string,
    allAssetsCNW: AssetsSummary
}

const PersonalLiabilitiesCNWReport = ({assetsDataForPage, investorGroup, caption, allAssetsCNW }: PersonalLiabilitiesProps) => {

    const {
        getFormattedTotalPresentValue,
        getFormattedPresentValueForCurrentNetWorth
    } = assetListData(allAssetsCNW);

    const currentNetWorthAssets: CurrentNetWorthAsset[] = allAssetsCNW.personalLiabilities.map(pl => (
        {
            id: pl.id,
            name: pl.description,
            presentValue: pl.loanBalanceEstateValue.totalValue,
            assetType: 'liability',
            ownershipCategory: pl.ownershipCategory,
            memberOwnerships: pl.memberOwnerships,
        }
    ));

    const getFormattedMemberOwnedTotalPresentValue = (member?: InvestorGroupMember) => {
        return member
            ? getFormattedTotalPresentValue(calculateCNWAssetsMemberOwnedTotalPresentValue(member.id, currentNetWorthAssets) * -1)
            : undefined;
    };

    const getFormattedJointlyOwnedTotalPresentValue = () => {
        return investorGroup.partnerMember
            ? getFormattedTotalPresentValue(calculateCNWAssetsJointlyOwnedTotalPresentValue(currentNetWorthAssets) * -1)
            : undefined;
    };

    return (
        <>
            {assetListData(assetsDataForPage).inEstatePersonalLiabilities.length > 0 &&
                <AccordionItemWithActionMenu
                    uuid="PersonalLiabilitiesCNWReport"
                    accentColor={COLOR_LIABILITIES}
                    HeaderComponent={({expanded}) =>
                        <AssetAccordionHeader
                            caption={caption}
                            expanded={expanded}
                            title="Personal Liabilities"
                            formattedPrimaryMemberTotalPresentValue={getFormattedMemberOwnedTotalPresentValue(investorGroup.primaryMember)}
                            formattedSecondaryMemberTotalPresentValue={getFormattedMemberOwnedTotalPresentValue(investorGroup.partnerMember)}
                            formattedJointTotalPresentValue={getFormattedJointlyOwnedTotalPresentValue()}
                            formattedTotalPresentValue={getFormattedTotalPresentValue(allAssetsCNW.liabilitiesValue.inEstateValue * -1)}
                            gridClassName={"current-net-worth-grid"}/>
                    }>

                    <div role="table" className="current-net-worth-grid-with-actionmenu assets-grid-table"
                         aria-label="personal-liabilities-table">
                        <AssetAccordionContentHeader investorGroup={investorGroup}/>

                        {assetListData(assetsDataForPage).inEstatePersonalLiabilities.map((personalLiability: PersonalLiabilitySummary) => {
                            return <PersonalLiabilityRow personalLiability={personalLiability}
                                                         actionsDisabled={false}
                                                         onClickEdit={NO_OP}
                                                         onClickDelete={NO_OP}
                                                         key={personalLiability.id}
                                                         gridClassName={"current-net-worth-grid-with-actionmenu"}
                                                         renderPersonalLiabilityDetails={(liability: PersonalLiabilitySummary) => {
                                                             return <>
                                                                 <TableCell text={liability.description}
                                                                            className={`${investorGroup.partnerMember ? '' : 'grid-span-4'} textalign-left`}/>
                                                                 {investorGroup.partnerMember && <>
                                                                     <TableCell
                                                                         text={getFormattedPresentValueForCurrentNetWorth(
                                                                             calculateMemberOwnedValue(
                                                                                 investorGroup.primaryMember.id,
                                                                                 liability.memberOwnerships,
                                                                                 liability.ownershipCategory,
                                                                                 liability.loanBalanceEstateValue.totalValue
                                                                             ) * -1
                                                                         )}
                                                                         className="textalign-right"/>
                                                                     <TableCell
                                                                         text={getFormattedPresentValueForCurrentNetWorth(
                                                                             calculateMemberOwnedValue(
                                                                                 investorGroup.partnerMember.id,
                                                                                 liability.memberOwnerships,
                                                                                 liability.ownershipCategory,
                                                                                 liability.loanBalanceEstateValue.totalValue
                                                                             ) * -1
                                                                         )}
                                                                         className="textalign-right"/>
                                                                     <TableCell
                                                                         text={getFormattedPresentValueForCurrentNetWorth(
                                                                             calculateJointlyOwnedValue(
                                                                                 liability.ownershipCategory,
                                                                                 liability.loanBalanceEstateValue.totalValue
                                                                             ) * -1
                                                                         )}
                                                                         className="textalign-right"/>
                                                                 </>
                                                                 }
                                                             </>
                                                         }}/>;
                        })}
                    </div>

                </AccordionItemWithActionMenu>}
        </>
    );
}
export default PersonalLiabilitiesCNWReport;
