import React from "react";
import {Mapping as ClassNamesMapping} from "classnames";
import {TableRow} from "./TableDisplay";
import {CellClassParameters, TableRowComponent} from "./TableRowComponent";
import useAccordionTableToggle from "./useAccordionTableToggle";

interface ExpandableTableRowProps<TR> {
    accordionTableId: string;
    additionalCellClasses?: (params: CellClassParameters) => ClassNamesMapping[];
    ariaLabel: string;
    row: TR;
    rowIndex: number;
    titleCellWidth: number;
    onTitleCellMouseEnter?: (row: TR, rowIndex?: number) => void;
    onTitleCellMouseLeave?: (row: TR) => void;
    valueColumnSize?: number;
    isDraggable? : boolean;
}

export const ExpandableTableRow = <ValueType, TR extends TableRow<ValueType>>({
                                                                                  accordionTableId,
                                                                                  additionalCellClasses,
                                                                                  ariaLabel,
                                                                                  rowIndex,
                                                                                  row,
                                                                                  titleCellWidth,
                                                                                  onTitleCellMouseEnter,
                                                                                  onTitleCellMouseLeave,
                                                                                  valueColumnSize,
                                                                                  isDraggable = false
                                                                              }: ExpandableTableRowProps<TR>) => {
    const rowId = `${accordionTableId}-${row.uniqueIdentifier}`;
    const {isExpanded, toggleExpanded} = useAccordionTableToggle(rowId);

    return (
        <>
            <TableRowComponent
                key={row.uniqueIdentifier}
                row={row}
                additionalCellClasses={additionalCellClasses}
                ariaLabel={ariaLabel}
                onClick={row.children.length > 0 ? toggleExpanded : undefined}
                rowIndex={rowIndex}
                titleCellWidth={titleCellWidth}
                onTitleCellMouseEnter={onTitleCellMouseEnter}
                onTitleCellMouseLeave={onTitleCellMouseLeave}
                valueColumnSize={valueColumnSize}
                isExpanded={isExpanded}
                isDraggable={isDraggable}
            />
            {isExpanded && row.children.map((childRow) =>
                <TableRowComponent
                    key={childRow.uniqueIdentifier}
                    row={childRow}
                    additionalCellClasses={additionalCellClasses}
                    ariaLabel={ariaLabel}
                    titleCellWidth={titleCellWidth}
                    indentTitle={true}
                    valueColumnSize={valueColumnSize}
                    isExpanded={false}
                    isDraggable={isDraggable}
                />
            )}
        </>
    )
}

