import React from "react";
import {UnderlinedHeader} from "../../components";
import {Checkbox} from "xps-react";
import {FamilyGoalType} from "../models/FamilyGoalType";
import GoalUtils from "../Summary/GoalUtils";

type NonLifestyleGoalCharacteristicsProps = {
    familyGoal: FamilyGoalType,
    replaceFamilyGoal: (familyGoal: FamilyGoalType) => void,
    isProfileWithProposalsOrArchived?: boolean
}

export const NonLifestyleGoalCharacteristics = ({
                                                    familyGoal,
                                                    replaceFamilyGoal,
                                                    isProfileWithProposalsOrArchived = false
                                                }: NonLifestyleGoalCharacteristicsProps) => {

    const {
        getHasGoalAlreadyStarted
    } = GoalUtils

    const goalHasStarted = (getHasGoalAlreadyStarted(familyGoal))
    const handleUpdateAssetPurchase = (isAchieved: boolean) => {
        replaceFamilyGoal({
            ...familyGoal,
            userInputs: {
                ...familyGoal.userInputs,
                isAchieved
            }
        })
    };

    return <section aria-label="Goal Characteristics">
        <UnderlinedHeader
            primaryText='Goal Characteristics'
            className="goal-timeframe-underlined-header"
        />
        <Checkbox
            name={"Mark As Achieved"}
            value="Mark As Achieved"
            checked={familyGoal.userInputs.isAchieved}
            aria-label={"Mark As Achieved"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUpdateAssetPurchase(e.target.checked)}
            disabled={!goalHasStarted || isProfileWithProposalsOrArchived}
        />
    </section>
}
