import React, {ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import {Toast} from "xps-react";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import usePrevious from "../../../utils/usePrevious";
import useMeetingUtils from "../useMeetingUtils";
import {
    resetMeetingControlRequest,
    selectActiveMeeting,
    selectAudienceMembers,
    selectMeetingControlRequest,
    setActiveMeeting,
} from "../meetingSlice";
import {NO_OP} from "../../../constants/common";
import {meetingApiClient} from "../MeetingApiClient";
import {msalUtils} from "../../../MsalUtils";
import {useMsal} from "@azure/msal-react";
import {MeetingParticipant, MeetingStatus} from "../Meeting";
import {MeetingControlResponse} from "../Relay/types/MeetingControlRequest";
import {Button} from "../../../components";
import {useRelayContext} from "../Relay/types/RelayContext";
import {isUserInMeeting} from "../utils/MeetingUtils";

const MeetingToasts = () => {
    return <div className="meeting-toast-container">
        <RequesterControlDeclinedToast/>
        <NewPresenterToast/>
        <PreviousPresenterToast/>
        <MeetingControlChangeToast/>
        <MeetingEndedToast/>
        <ParticipantJoinedToast/>
    </div>
};

type MeetingToastProps = {
    showToast: boolean;
    title: string;
    iconName: string;
    type: string;
    onClose?: () => void;
    showCloseBtn?: boolean;
    autoCloseDelay?: number;
    footer?: ReactElement;
};

const MeetingToast: React.FC<MeetingToastProps> = ({
                                                       showToast,
                                                       title,
                                                       iconName,
                                                       type,
                                                       onClose = NO_OP,
                                                       showCloseBtn = false,
                                                       autoCloseDelay,
                                                       footer,
                                                   }) => {
    useEffect(() => {

        if (autoCloseDelay && showToast) {
            const timerId = setTimeout(() => onClose(), autoCloseDelay);

            return (() => {
                clearTimeout(timerId);
            });
        }
    }, [autoCloseDelay, showToast]);

    return <>{showToast && <Toast
        title={title}
        iconName={iconName}
        onClose={onClose}
        showCloseBtn={showCloseBtn}
        theme="light"
        type={type}
        width={360}
        footer={footer}
    />}</>;
}

const RequesterControlDeclinedToast = () => {
    const [showToast, setShowToast] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const meetingControlRequest = useAppSelector(selectMeetingControlRequest);
    const msal = useMsal();
    const currentUserId = msalUtils.getLanId(msal);
    const previousRequsterId = usePrevious(meetingControlRequest?.user.id)

    useEffect(() => {
        if (currentUserId === previousRequsterId && meetingControlRequest && meetingControlRequest.response === MeetingControlResponse.DECLINED) {
            dispatch(resetMeetingControlRequest());
            setShowToast(true);
        }
    }, [meetingControlRequest?.response]);

    return <MeetingToast showToast={showToast}
                         title="Your request for presenter control has been declined by the Presenter."
                         iconName="warning"
                         type="error"
                         showCloseBtn={true}
                         onClose={() => {
                             setShowToast(false);
                         }}
                         autoCloseDelay={5000}
    />;
}

const NewPresenterToast = () => {
    const [showToast, setShowToast] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const meeting = useAppSelector(selectActiveMeeting)!;
    const prevPresenter = usePrevious(meeting.presenter);
    const {isCurrentUserPresenting} = useMeetingUtils();

    useEffect(() => {
        if (prevPresenter !== meeting.presenter && !!prevPresenter) {
            setShowToast(isCurrentUserPresenting);
            dispatch(resetMeetingControlRequest());
        }
    }, [meeting.presenter]);

    return <MeetingToast showToast={showToast}
                         title="You are now the Presenter."
                         iconName="status_validated"
                         type="success"
                         showCloseBtn={true}
                         onClose={() => {
                             setShowToast(false);
                         }}
                         autoCloseDelay={5000}
    />;
}

const PreviousPresenterToast = () => {
    const meeting = useAppSelector(selectActiveMeeting)!;
    const [showToast, setShowToast] = useState<boolean>(false);
    const prevPresenter = usePrevious(meeting.presenter);
    const {isCurrentUserPresenting} = useMeetingUtils();
    const msal = useMsal();

    useEffect(() => {
        if (prevPresenter === msalUtils.getLanId(msal)) {
            setShowToast(!isCurrentUserPresenting);
        }
    }, [meeting.presenter])

    return <MeetingToast
        showToast={showToast}
        title={`${meeting.presenterName} (${meeting.presenter?.toUpperCase()}) is now the active Presenter.`}
        iconName={'info'}
        type={'info'}
        showCloseBtn={true}
        onClose={() => {
            setShowToast(false);
        }}
        autoCloseDelay={5000}
    />
}

const MeetingEndedToast = () => {
    const meeting = useAppSelector(selectActiveMeeting)!;
    const [showToast, setShowToast] = useState<boolean>(false);
    const msal = useMsal();

    useEffect(() => {
        if (meeting.status === MeetingStatus.ENDED && meeting.presenter !== msalUtils.getLanId(msal)) {
            setShowToast(true);
        }
    }, [meeting.status])

    return <MeetingToast
        showToast={showToast}
        title={`${meeting.presenterName} (${meeting.presenter?.toUpperCase()}) has ended the client meeting.`}
        iconName={'status_stop'}
        type={'error'}
        showCloseBtn={true}
        onClose={() => {
            setShowToast(false);
        }}
    />
}

const MeetingControlChangeToast = () => {
    const [showToast, setShowToast] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const meetingControlRequest = useAppSelector(selectMeetingControlRequest);
    const meeting = useAppSelector(selectActiveMeeting)!;
    const {isCurrentUserPresenting} = useMeetingUtils();
    const dispatch = useAppDispatch();
    const {sharedObjectMutators} = useRelayContext();
    const audience = useAppSelector(selectAudienceMembers);

    useEffect(() => {
        if (isCurrentUserPresenting && meetingControlRequest
            && meetingControlRequest.user.id && meetingControlRequest.user.name && !meetingControlRequest.response) {
            setTitle(`${
                meetingControlRequest.user.name
            } (${
                meetingControlRequest.user.id.toUpperCase()
            }) is requesting presenter control`);
            setShowToast(true);
        }
    }, [meetingControlRequest]);

    const approveRequest = useCallback(() => {

        if (isCurrentUserPresenting
            && meetingControlRequest?.user.id
            && meetingControlRequest?.user.name) {

            const isMemberInMeeting = audience && isUserInMeeting(audience, meetingControlRequest.user.id);

            if (!isMemberInMeeting) {
                sharedObjectMutators.requestControl(undefined);
                dispatch(resetMeetingControlRequest());
                setShowToast(false);
                return;
            }

            meetingApiClient.updateMeeting({
                ...meeting,
                presenter: meetingControlRequest.user.id,
                presenterName: meetingControlRequest.user.name,
            }).then((updatedMeeting) => {
                setShowToast(false);
                dispatch(setActiveMeeting(updatedMeeting));
                const controlRequest = {
                    ...meetingControlRequest,
                    response: MeetingControlResponse.APPROVED
                };
                sharedObjectMutators.requestControl(controlRequest);
                sharedObjectMutators.updatePresenterState({
                    presenter: controlRequest.user.id,
                    presenterName: controlRequest.user.name,
                });
            }).catch((error) => {
                console.error('Could not update meeting', error.message);
            });
        }
    }, [meeting, meetingControlRequest, audience]);

    const approveActionButton = <Button className="marginright-xl"
                                        icon="none"
                                        includeRef={false}
                                        kind="borderless"
                                        noPadding
                                        size="small"
                                        tabIndex={0}
                                        type="button"
                                        onClick={approveRequest}>Approve</Button>;

    const declineRequest = useCallback(() => {
        setShowToast(false);

        if (meetingControlRequest?.user.id && meetingControlRequest?.user.name) {
            if (audience && isUserInMeeting(audience, meetingControlRequest.user.id)) {
                const controlRequest = {
                    ...meetingControlRequest,
                    response: MeetingControlResponse.DECLINED
                };
                sharedObjectMutators.requestControl(controlRequest);
            } else {
                sharedObjectMutators.requestControl(undefined);
                dispatch(resetMeetingControlRequest());
            }
        }
    }, [meetingControlRequest, audience]);

    const declineActionButton = <Button icon="none"
                                        includeRef={false}
                                        kind="borderless"
                                        noPadding
                                        size="small"
                                        tabIndex={0}
                                        type="button"
                                        onClick={declineRequest}>Decline</Button>;
    const actions = <>
        {approveActionButton}
        {declineActionButton}
    </>;
    return <MeetingToast showToast={showToast}
                         title={title}
                         iconName="warning"
                         type="warning"
                         footer={actions}
    />;
}


const ParticipantJoinedToast = () => {
    const audience = useAppSelector(selectAudienceMembers)!;
    const {isCurrentUserPresenting} = useMeetingUtils();
    const msal = useMsal();
    const [currentParticipants, setCurrentParticipants] = useState<MeetingParticipant[]>([]);
    const [newParticipants, setNewParticipants] = useState<MeetingParticipant[]>([]);
    const currentUserId = msalUtils.getLanId(msal);

    const currentParticipantMap = useMemo(() => {
        const map = new Map();
        currentParticipants.forEach((m) => map.set(m.communicationsId, m));
        return map;
    }, [currentParticipants])


    useEffect(() => {
        if (isCurrentUserPresenting && audience) {
            //compare audience with current meeting participants
            const participantsToAdd = audience.filter((audienceMember) =>
                audienceMember.userId !== currentUserId
                && !currentParticipantMap.has(audienceMember.communicationsId)
                && newParticipants.findIndex((p: MeetingParticipant) => audienceMember.communicationsId === p.communicationsId) === -1
            );

            setNewParticipants([...newParticipants, ...participantsToAdd])
        }
    }, [audience])


    const NewParticipantMeetingToast = (p: MeetingParticipant) => {

        const isPresentInNewParticipants = !!newParticipants.find((c) => c.communicationsId === p.communicationsId);

        return <div key={p.communicationsId}>
            <MeetingToast
                showToast={isPresentInNewParticipants}
                title={`${p.userName} has joined the meeting`}
                iconName={'person'}
                type={'warning'}
                showCloseBtn={true}
                onClose={() => {
                    setNewParticipants([...newParticipants.filter((n) => n.communicationsId !== p.communicationsId)])
                    setCurrentParticipants([...currentParticipants, p])
                }}
                autoCloseDelay={5000}
            />
        </div>;
    }

    return <>
        {newParticipants.map((p) => NewParticipantMeetingToast(p))}
    </>
}


export default MeetingToasts;
