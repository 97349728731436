import React, {useEffect, useMemo, useRef, useState} from "react";
import {COLOR_ASSETS_ACCOUNTS, COLOR_RISK_CONTROL_ASSETS,} from "../../constants/colors";
import EmptyAssetAllocation from "./EmptyAssetAllocation";
import {CurrentAllocationResponse} from "./CurrentAllocationResponse";
import AssetHoldings from "./AssetHoldings";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import {formatCurrency} from "../../utils/format";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import CurrentVsProposedLegend from "./CurrentVsProposedLegend";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {Col, Container, Row} from "react-grid-system";
import {wealthManagementApiClient} from "../WealthManagementApiClient";
import usePageViewTimer from "../../hooks/usePageViewTimer";
import {PresentationPaneButton, PresentationPaneHeader} from "../../components";
import {useAppSelector} from "../../store/hooks";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import {AssetClassifications} from "../../Assets/models/AssetClassifications";
import {assetsApiClient} from "../../Assets/AssetsApiClient";
import {riskAssetClasses, riskControlClasses} from "./AssetClasses";
import {EMPTY_REVISE_ASSET_ALLOCATION_RESPONSE, ReviseAssetAllocationResponse} from "./ReviseAssetAllocation";
import AssetAllocationRiskDonut from "./AssetAllocationRiskDonut";

export const COLUMN_WIDTH = 300;

const CurrentVsProposed: React.FC = () => {
    const {id} = useParams<RouteWithId>();
    const {approvedProfile} = useProfileAndProposals(id);
    const history = useHistory();
    const releaseToggles = useAppSelector(selectReleaseToggles);

    const displayName = approvedProfile.displayName;

    const [currentAllocation, setCurrentAllocation] = useState<ReviseAssetAllocationResponse>(EMPTY_REVISE_ASSET_ALLOCATION_RESPONSE);
    const [proposedAllocation, setProposedAllocation] = useState<ReviseAssetAllocationResponse>(EMPTY_REVISE_ASSET_ALLOCATION_RESPONSE);
    const totalCurrentAllocationAmount = currentAllocation ? currentAllocation.totalCurrentRiskAssetsAmount + currentAllocation.totalCurrentRiskControlAssetsAmount : 0;
    const totalProposedAllocationAmount = proposedAllocation ? proposedAllocation.totalProposedRiskAssetsAmount + proposedAllocation.totalProposedRiskControlAssetsAmount : 0;
    const totalCurrentAllocationPercentage = 100;
    const totalProposedAllocationPercentage = 100;
    const [pageLoaded, setPageLoaded] = useState<boolean>(false);
    const [classifications, setClassifications] = useState<AssetClassifications>({
        riskAssetClasses: [],
        riskControlAssetClasses: [],
    });

    usePageViewTimer('Current vs. Proposed Page Load Timer (milliseconds)', !pageLoaded);

    useEffect(() => {
        (async () => {
            if (id) {
                setPageLoaded(false);
                const [revisedAllocationResponse, assetClassifications] = await Promise.all([
                    wealthManagementApiClient.getReviseAssetAllocation(id),
                    assetsApiClient.getAssetClassifications()]
                );

                setPageLoaded(true);
                setCurrentAllocation(revisedAllocationResponse);
                setProposedAllocation(revisedAllocationResponse);
                setClassifications(assetClassifications);
            }
        })();
    }, [id]);

    const titleRowChildren = () => (
        <PresentationPaneButton
            iconName="edit"
            kind="borderless"
            onClick={() => history.push(`/Profile/${id}/ClientProfile/AssetAllocation/ReviseAssetAllocation`)}
        >
            Revise Subclasses
        </PresentationPaneButton>
    );

    return (
        <>
            <div data-testid="current-proposed-page" className="current-proposed-page">
                <PresentationPaneHeader
                    displayName={displayName}
                    title="Current vs. Proposed Asset Allocation"
                    titleRow={releaseToggles?.enableReviseAssetAllocation ? titleRowChildren() : undefined}
                />
                {pageLoaded ? <CurrentVsProposedContent currentAllocation={currentAllocation}
                                                        proposedAllocation={proposedAllocation}
                                                        totalCurrentAllocationAmount={totalCurrentAllocationAmount}
                                                        totalProposedAllocationAmount={totalProposedAllocationAmount}
                                                        totalCurrentAllocationPercentage={totalCurrentAllocationPercentage}
                                                        totalProposedAllocationPercentage={totalProposedAllocationPercentage}
                                                        classifications={classifications!}
                    />
                    : <LoadingIndicator displayMessage={"Page loading"}/>
                }
            </div>
        </>
    );
}

function getAssetShiftRecommendations(totalRiskAssetsAmount: number, totalRiskControlAssetsAmount: number, currentAllocation: CurrentAllocationResponse) {
    const riskAssetTotalDifference = totalRiskAssetsAmount - currentAllocation.totalRiskAssetsAmount;
    const riskControlTotalDifference = totalRiskControlAssetsAmount - currentAllocation.totalRiskControlAssetsAmount;
    const hasInvestableSufficiency = (riskAssetTotalDifference > 0 && riskControlTotalDifference < 0) || (riskControlTotalDifference > 0 && riskAssetTotalDifference < 0);
    const isRiskAssetTotalNegative = riskAssetTotalDifference < 0;

    const difference = formatCurrency(riskAssetTotalDifference < 0 ? Math.abs(riskAssetTotalDifference) : Math.abs(riskControlTotalDifference))
    const shiftFrom = isRiskAssetTotalNegative ? 'Risk Assets' : 'Risk Control Assets';
    const shiftTo = isRiskAssetTotalNegative ? 'Risk Control Assets' : 'Risk Assets';
    return {
        hasInvestableSufficiency,
        difference,
        shiftFrom,
        shiftTo
    };
}


export const hasCurrentAllocation = (currentAllocation: ReviseAssetAllocationResponse) =>
    currentAllocation.totalCurrentRiskAssetsAmount > 0 || currentAllocation.totalCurrentRiskControlAssetsAmount > 0;

type CurrentVsProposedTableFooterProps = {
    currentAllocationAmount: number,
    proposedAllocationAmount: number,
    currentAllocationPercentage: number,
    proposedAllocationPercentage: number,
}

type CurrentVsProposedContentProps = {
    currentAllocation: ReviseAssetAllocationResponse;
    proposedAllocation: ReviseAssetAllocationResponse;
    totalCurrentAllocationAmount: number;
    totalProposedAllocationAmount: number;
    totalCurrentAllocationPercentage: number;
    totalProposedAllocationPercentage: number;
    classifications: AssetClassifications
}

const CurrentVsProposedContent: React.FC<CurrentVsProposedContentProps> = ({
                                                                               currentAllocation,
                                                                               proposedAllocation,
                                                                               totalCurrentAllocationAmount,
                                                                               totalProposedAllocationAmount,
                                                                               totalCurrentAllocationPercentage,
                                                                               totalProposedAllocationPercentage,
                                                                               classifications
                                                                           }) => {


    let totalRiskAssetsAmount: number | undefined;
    let totalRiskControlAssetsAmount: number | undefined;

    if (proposedAllocation.totalProposedRiskControlAssetsAmount !== 0 || proposedAllocation.totalProposedRiskAssetsAmount !== 0) {
        totalRiskAssetsAmount = proposedAllocation.totalProposedRiskAssetsAmount;
        totalRiskControlAssetsAmount = proposedAllocation.totalProposedRiskControlAssetsAmount;
    }

    const riskAssetClassesToShow = useMemo(() => {
        return classifications && classifications.riskAssetClasses.length > 0 ? classifications.riskAssetClasses.flatMap(assetClass => assetClass.assetSubclasses)
            .map(assetSubclass => assetSubclass.assetSubclassName) : riskAssetClasses;
    }, [classifications]);


    const riskControlClassesToShow = useMemo(() => {
        return classifications && classifications.riskControlAssetClasses.length > 0 ? classifications.riskControlAssetClasses.flatMap(assetClass => assetClass.assetSubclasses)
            .map(assetSubclass => assetSubclass.assetSubclassName) : riskControlClasses;
    }, [classifications]);

    // temporarily removing this
    // const {
    //     hasInvestableSufficiency,
    //     difference,
    //     shiftFrom,
    //     shiftTo
    // } = getAssetShiftRecommendations(totalRiskAssetsAmount!, totalRiskControlAssetsAmount!, currentAllocation);
    const InvestableAssets = <div className="investable-assets" style={{
        maxWidth: '315px',
        minWidth: '315px',
    }}>
        {/*<span>You have </span>*/}
        {/*<span className="current-allocation-amount">{formatCurrency(totalCurrentAllocationAmount)}</span>*/}
        {/*<span> in investable Assets.</span>*/}
    </div>;

    const [lineOverlayHeight, setLineOverlayHeight] = useState<number>();
    const containerRef = useRef<Container & HTMLDivElement>(null);

    const resizeObserver = useMemo(() => {
        return new ResizeObserver((entries) => {
            const {height} = entries[0].contentRect
            setLineOverlayHeight(height - 20);
        });
    }, []);

    useEffect(() => {
        if (resizeObserver && containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }
        return () => {
            if (resizeObserver) {
                resizeObserver.disconnect();
            }
        }
    }, [resizeObserver, containerRef.current]);

    const LineOverlay = (
        <div className='line-overlay-container'>
            <div style={{height: lineOverlayHeight}} className='line-overlay' data-testid="line-overlay"></div>
        </div>
    );

    const totalCurrentRiskAssetsPercentOfTotalPortfolio = (currentAllocation.totalCurrentRiskAssetsAmount / (currentAllocation.totalCurrentRiskAssetsAmount + currentAllocation.totalCurrentRiskControlAssetsAmount)) * 100
    const totalCurrentRiskControlAssetsPercentOfTotalPortfolio = (currentAllocation.totalCurrentRiskControlAssetsAmount / (currentAllocation.totalCurrentRiskAssetsAmount + currentAllocation.totalCurrentRiskControlAssetsAmount)) * 100

    return (
        <>
            {hasCurrentAllocation(currentAllocation) ?
                <Container
                    ref={containerRef}
                    fluid className="current-proposed-container">
                    <Row className={"row"}>
                        {InvestableAssets}
                        <>
                            <Col width={COLUMN_WIDTH}
                                 className={"allocation-donut"}
                                 data-testid={'current-allocation-donut'}>
                                <span className="title">Current Allocation</span>
                                <CurrentVsProposedLegend
                                    totalRiskAssetsPercent={totalCurrentRiskAssetsPercentOfTotalPortfolio}
                                    totalRiskControlAssetsPercent={totalCurrentRiskControlAssetsPercentOfTotalPortfolio}
                                />
                                <AssetAllocationRiskDonut data={currentAllocation}
                                           allocation={'current'}
                                           donutSize={'md'}/>
                            </Col>
                            {LineOverlay}
                        </>
                        <Col width={COLUMN_WIDTH}
                             className={"allocation-donut"}
                             data-testid={'proposed-allocation-donut'}>
                            <span className="title">Proposed Allocation</span>
                            <CurrentVsProposedLegend
                                totalRiskAssetsPercent={proposedAllocation.totalProposedRiskAssetsPercentOfTotalPortfolio}
                                totalRiskControlAssetsPercent={proposedAllocation.totalProposedRiskControlAssetsPercentOfTotalPortfolio}
                            />
                            <AssetAllocationRiskDonut data={proposedAllocation}
                                                      allocation={'proposed'}
                                                      donutSize={'md'}/>
                        </Col>
                        <Col width={COLUMN_WIDTH}
                             style={{minWidth: COLUMN_WIDTH}}
                             className={"investable-assets"}>
                            {/*<div>*/}
                            {/*    {hasInvestableSufficiency &&*/}
                            {/*        <>*/}
                            {/*            <span> We propose shifting </span>*/}
                            {/*            <span className="current-allocation-amount">{difference} </span>*/}
                            {/*            <span>from {shiftFrom} to {shiftTo}.</span>*/}
                            {/*        </>*/}
                            {/*    }*/}
                            {/*</div>*/}
                        </Col>
                    </Row>
                    <AssetHoldings id="CurrentProposedRiskAssetsAccordionId"
                                   assetClassName={"Risk Assets"}
                                   total={currentAllocation.totalCurrentRiskAssetsAmount}
                                   totalProposed={totalRiskAssetsAmount}
                                   totalPercentage={totalCurrentRiskAssetsPercentOfTotalPortfolio}
                                   totalProposedPercentage={proposedAllocation.totalProposedRiskAssetsPercentOfTotalPortfolio}
                                   accentColor={COLOR_ASSETS_ACCOUNTS}
                                   assetSubclasses={riskAssetClassesToShow}
                                   currentAssetSubclassSummaries={currentAllocation.riskAssets}
                                   proposedAssetSubclassSummaries={proposedAllocation.riskAssets}
                                   totalProposedInvestableAmount={totalProposedAllocationAmount}
                                   totalCurrentInvestableAmount={totalCurrentAllocationAmount}
                    />
                    <div className={"current-proposed-spacer"}></div>
                    <AssetHoldings id={"CurrentProposedRiskControlAssetsAccordionId"}
                                   assetClassName={"Risk Control Assets"}
                                   total={currentAllocation.totalCurrentRiskControlAssetsAmount}
                                   totalProposed={totalRiskControlAssetsAmount}
                                   totalPercentage={totalCurrentRiskControlAssetsPercentOfTotalPortfolio}
                                   totalProposedPercentage={proposedAllocation.totalProposedRiskControlAssetsPercentOfTotalPortfolio}
                                   accentColor={COLOR_RISK_CONTROL_ASSETS}
                                   assetSubclasses={riskControlClassesToShow}
                                   currentAssetSubclassSummaries={currentAllocation.riskControlAssets}
                                   proposedAssetSubclassSummaries={proposedAllocation.riskControlAssets}
                                   totalProposedInvestableAmount={totalProposedAllocationAmount}
                                   totalCurrentInvestableAmount={totalCurrentAllocationAmount}
                    />
                    <CurrentVsProposedTableFooter currentAllocationAmount={totalCurrentAllocationAmount}
                                                  proposedAllocationAmount={totalProposedAllocationAmount}
                                                  currentAllocationPercentage={totalCurrentAllocationPercentage}
                                                  proposedAllocationPercentage={totalProposedAllocationPercentage}/>
                </Container>
                : <EmptyAssetAllocation/>
            }
        </>
    )
}

export const CurrentVsProposedTableFooter: React.FC<CurrentVsProposedTableFooterProps> = ({
                                                                                              currentAllocationAmount,
                                                                                              proposedAllocationAmount,
                                                                                              currentAllocationPercentage,
                                                                                              proposedAllocationPercentage,
                                                                                          }) => {
    return (
        <Row className="row allocation-grid allocation-grid-table"
             style={{marginTop: "50px", paddingTop: '8px'}}>
            <Col width={315} style={{minWidth: 315}}>
                <span className="footer total-allocation total-allocation-font">Total Allocation</span>
            </Col>
            <Col width={COLUMN_WIDTH}
                 style={{minWidth: COLUMN_WIDTH}}
                 className="total-allocation-container">
                <span className="total total-allocation-font">{formatCurrency(currentAllocationAmount)}</span>
                <span style={{width: '135px'}}
                      className="total total-allocation-font paddingright-lg">{currentAllocationPercentage}%</span>
            </Col>
            <Col width={COLUMN_WIDTH}
                 style={{minWidth: COLUMN_WIDTH}}
                 className="total-allocation-container proposed">
                <span className="total total-allocation-font">{formatCurrency(proposedAllocationAmount)}</span>
                <span style={{width: '135px'}}
                      className="total total-allocation-font paddingright-lg">{proposedAllocationPercentage}%</span>
            </Col>
        </Row>
    );
}


export default CurrentVsProposed;