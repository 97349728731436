import {AccordionItemWithActionMenu} from "../../../../components";
import {AccordionItemIds} from "../constants";
import {COLOR_FUTURE_INFLOWS} from "../../../../constants/colors";
import {AssetAccordionHeader} from "../../common/AssetAccordionHeader";
import {formatCurrency} from "../../../../utils/format";
import React from "react";
import FutureInflowsTable from "../../InEstate/FutureInflowsTable";
import {NO_OP} from "../../../../constants/common";
import {InvestorGroupType} from "../../../../ClientManagement/models/InvestorGroupType";
import {GeneralInflows, SocialSecurities} from "../../../models/Assets";
import {DropResult} from "react-beautiful-dnd";

type FutureInflowsProps = {
    generalInflows: GeneralInflows,
    socialSecurities: SocialSecurities,
    investorGroup: InvestorGroupType,
    captureGeneralInflowsOrder?: ({source, destination}: DropResult) => void
    captureSocialSecuritiesOrder?: ({source, destination}: DropResult) => void
}

const FutureInflows = ({
                           generalInflows,
                           socialSecurities,
                           investorGroup,
                           captureGeneralInflowsOrder = NO_OP,
                           captureSocialSecuritiesOrder = NO_OP
                       }: FutureInflowsProps) => {

    if (generalInflows.data.length === 0 && socialSecurities.data.length === 0) {
        return <></>;
    }

    const presentValue = generalInflows.totalPresentValue + socialSecurities.totalPresentValue;

    return (
        <AccordionItemWithActionMenu
            uuid={AccordionItemIds.futureInflows}
            expandable={false}
            accentColor={COLOR_FUTURE_INFLOWS}
            HeaderComponent={({expanded}) =>
                <AssetAccordionHeader
                    expanded={expanded}
                    title="Future Inflows"
                    formattedTotalPresentValue={formatCurrency(presentValue)}
                    gridClassName={"assets-grid"}
                    showChevron={false}
                />
            }
        >
            <FutureInflowsTable
                generalInflows={generalInflows}
                socialSecurities={socialSecurities}
                investorGroup={investorGroup}
                onEditSocialSecurity={NO_OP}
                onEditGeneralInflow={NO_OP}
                onDeleteAssetClick={NO_OP}
                onSaveReviewTrustDisclosure={NO_OP}
                hideActionMenu={true}
                isDraggable={true}
                captureGeneralInflowsOrder={captureGeneralInflowsOrder}
                captureSocialSecuritiesOrder={captureSocialSecuritiesOrder}
            />
        </AccordionItemWithActionMenu>
    )
}

export default FutureInflows;