import React from "react";
import {Dropdown, DropdownItem} from "../../components";
import {MemberGroupMember} from "../../ClientManagement/models/InvestorGroupType";
import {NO_OP} from "../../constants/common";
import useProfileEditableState from "../../hooks/useProfileEditableState";

type MemberDropdownProps = {
    value: string | null,
    members: MemberGroupMember[],
    onChange: (selectedMemberId: string) => void,
    className?: string,
    onBlur?: () => void,
    error?: string,
};

export function MemberDropdown({members, onChange, value, className, onBlur, ...rest}: MemberDropdownProps) {
    const dropdownItems = members.map(toDropdownItem);
    const {isProfileWithProposalsOrArchived} = useProfileEditableState()
    const formFieldsReadOnly = isProfileWithProposalsOrArchived;

    return <Dropdown
        size='medium'
        value={value ? value : null}
        onChange={(data: { value: string }) => onChange(data.value)}
        className={className}
        onBlur={onBlur ?? NO_OP}
        {...rest}
        disabled={formFieldsReadOnly}
    >
        {dropdownItems}
    </Dropdown>;
}

function toDropdownItem(member: MemberGroupMember) {
    const itemText = `${member.firstName} ${member.lastName} (Age ${member.age})`;
    return <DropdownItem itemText={itemText} value={member.id} key={member.id}/>;
}