import React, {ChangeEventHandler, KeyboardEventHandler, MouseEventHandler} from 'react';
import {Search as XpsSearch} from 'xps-react';

type SearchProps = {
    id?: string,
    name: string,
    size?: 'small' | 'medium' | 'large',
    onClearListener?: MouseEventHandler,
    placeholder?: string,
    handleChangeVal?: ChangeEventHandler,
    onKeyDownListener?: KeyboardEventHandler,
    value?: string
};

const Search = (props: SearchProps) => (<XpsSearch {...props} />);

export default Search;
