import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export type AccordionTableStates = { expanded: { [key: string]: boolean; } };

export const initialAccordionTableState: AccordionTableStates = {expanded: {}};

export const accordionTableSlice = createSlice({
    name: "accordionTable",
    initialState: initialAccordionTableState,
    reducers: {
        toggleAccordionTable: (state, action: PayloadAction<string>) => {
            const id = action.payload;
            if (Boolean(state.expanded[id])) {
                delete state.expanded[action.payload];
            } else {
                state.expanded[action.payload] = true;
            }
        },
        setAccordionTableState: (state, action: PayloadAction<AccordionTableStates>) => {
            state.expanded = {...action.payload.expanded};
        },
        resetAccordionTableState: () => ({...initialAccordionTableState}),
    }
});

export const {toggleAccordionTable, setAccordionTableState, resetAccordionTableState} = accordionTableSlice.actions;

export default accordionTableSlice.reducer;
