import React, {ReactNode, useEffect, useRef, useState} from 'react';
import cx from 'classnames';
import Icon from '@nt/dds-react/core/Icon';
import SubMenu from '@nt/dds-react/core/DropdownMenu/SubMenu';

type MenuItemProps = {
    children?: ReactNode,
    className?: string,
    disabled?: boolean,
    iconName?: string,
    panelWidth?: number,
    panelHeight?: number,
    text: string,
    onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void,
    onSubMenuOpen: (isOpen: boolean) => void,
    scrollableRegionId: string
}

const MenuItem = ({
                      children,
                      className,
                      disabled = false,
                      iconName,
                      text,
                      onClick,
                      onSubMenuOpen,
                      panelHeight,
                      panelWidth = 240,
                      scrollableRegionId
                  }: MenuItemProps) => {
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const [isHoveringOnMenuItem, setIsHoveringOnMenuItem] = useState(false);
    const [panelLeftPos, setPanelLeftPos] = useState(0);
    const [panelTopPos, setPanelTopPos] = useState(0);
    const [focusableItems, setItems] = useState<HTMLElement[]>([]);
    const [focusIndex, setFocus] = useState(0);

    let parentRef = useRef<HTMLLIElement>(null);
    let subPanelRef = useRef<HTMLElement>(null);
    const scrollableRegionRef = useRef(document.getElementById(scrollableRegionId));

    const ntClass = cx(
        {
            menu__item: true,
            'display-flex': true,
            'justify-content-between': true,
            'align-items-center': true,
            'dropdown__item--disabled': disabled
        },
        className
    );

    const panelClass = cx({
        'hover-highlight--primary': !disabled,
        'paddingleft-md': true,
        'paddingright-md': true,
        'display-flex': true,
        'justify-content-between': true,
        'align-items-center': true,
        'width-100p': true,
        'height-small': true
    });

    const onMouseLeave = () => {
        setIsHoveringOnMenuItem(false);
    };

    const calculateScrollableRegionOffset = () => {
        if (scrollableRegionRef.current) {
            const scrollableRegionRect = scrollableRegionRef.current.getBoundingClientRect();
            return {
                scrollableRegionLeftOffset: scrollableRegionRect.left - scrollableRegionRef.current.scrollLeft,
                scrollableRegionTopOffset: scrollableRegionRect.top - scrollableRegionRef.current.scrollTop,
            }
        } else {
            return {
                scrollableRegionLeftOffset: 0,
                scrollableRegionTopOffset: 0
            }
        }
    }

    const onMouseEnter = () => {
        const parentRect = parentRef.current!.getBoundingClientRect();
        const {scrollableRegionLeftOffset, scrollableRegionTopOffset} = calculateScrollableRegionOffset();

        /* subtract 9 to parent.top, padding top is 8px with border 1px */
        let topPos = parentRect.top - 9;
        if (panelHeight) {
            const bottomPos = topPos + panelHeight;
            if (bottomPos > window.innerHeight) {
                topPos -= bottomPos - window.innerHeight;
            }
        }

        setPanelLeftPos(parentRect.left - panelWidth - scrollableRegionLeftOffset);
        setPanelTopPos(topPos - scrollableRegionTopOffset);
        setIsHoveringOnMenuItem(true);
    };

    useEffect(() => {
        setIsSubMenuOpen(isHoveringOnMenuItem);
        if (children) onSubMenuOpen(isHoveringOnMenuItem);
    }, [isHoveringOnMenuItem]);

    useEffect(() => {
        let items: any = [];
        if (subPanelRef.current) {
            items = subPanelRef.current.querySelectorAll('[role="menuitem"]');
        }
        setItems(Array.from(items));
    }, [subPanelRef, isSubMenuOpen]);

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'ArrowRight' && focusableItems.length) {
            e.preventDefault();
            onMouseEnter();
            setFocus(0);
        } else if (e.key === 'ArrowLeft' && isSubMenuOpen) {
            e.preventDefault();
            onMouseLeave();
            parentRef.current!.focus();
        }
    };

    return (
        <li
            ref={parentRef}
            aria-disabled={disabled}
            className={ntClass}
            role='menuitem'
            onClick={e => !disabled && onClick && onClick(e)}
            onKeyPress={e => !disabled && onClick && onClick(e)}
            onKeyDown={e => onKeyDown(e)}
            tabIndex={-1}
            onMouseEnter={() => onMouseEnter()}
            onMouseLeave={() => onMouseLeave()}
        >
            <div className={panelClass}>
                {text}
                {iconName ? <Icon name={iconName}/> : null}
                {children ? <Icon name='chevron_left'/> : null}
            </div>
            {children && (
                <SubMenu ref={subPanelRef} width={panelWidth} left={panelLeftPos} top={panelTopPos}
                         isOpen={isSubMenuOpen}
                         focusIndex={focusIndex}>
                    {children}
                </SubMenu>
            )}
        </li>
    );
};

export default MenuItem;
