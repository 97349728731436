import {TaxLiabilitySummary} from "../Assets/models/Assets";
import {TaxLiabilities} from "./models/GoalModelType";

export const formatTaxLiabilities = (taxLiabilities: TaxLiabilitySummary): TaxLiabilities => {
    const makeLiabilityPositive = (liability: number | null) => {
        return liability !== null ? -liability : 0;
    }

    return {
        totalTaxLiabilities: makeLiabilityPositive(taxLiabilities.totalPresentTaxLiabilityForAllAccounts),
        estimatedDeferredTaxLiability: makeLiabilityPositive(taxLiabilities.totalPresentTaxLiabilityForDeferredAccounts),
        estimatedUnrealizedCapitalGainsLiability: makeLiabilityPositive(taxLiabilities.totalPresentTaxLiabilityForTaxableAccounts),
        totalTaxLiabilitiesFundedByPortfolio: makeLiabilityPositive(taxLiabilities.totalPresentTaxLiabilityForAllAccountsFundedByPortfolio),
        estimatedDeferredTaxLiabilityForAllAccountsFundedByPortfolio: makeLiabilityPositive(taxLiabilities.totalPresentTaxLiabilityForDeferredAccountsFundedByPortfolio),
        estimatedUnrealizedCapitalGainsLiabilityFundedByPortfolio: makeLiabilityPositive(taxLiabilities.totalPresentTaxLiabilityForTaxableAccountsFundedByPortfolio),
    };
}

