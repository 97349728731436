import React from "react";
import {useParams} from "react-router-dom";
import {RouteWithIdAndStrategyId} from "../routes/types";
import StrategyWithTimeFrame from "./components/StrategyWithTimeFrame";
import {AnnualGiftingStrategy, EstimatedImpact, StrategyTimeFrame} from "./WealthPOTypes";
import {wealthPOApiClient} from "./WealthPOApiClient";
import {CreateOrEditStrategyParameters} from "./WealthPOUtils";

interface AddEditAnnualGiftingProps {
    refreshStrategiesSummary: () => void;
}

const AddEditAnnualGifting: React.FC<AddEditAnnualGiftingProps> = ({
                                                                       refreshStrategiesSummary
                                                                   }: AddEditAnnualGiftingProps) => {
    const {id, strategyId} = useParams<RouteWithIdAndStrategyId>();

    const getEstimatedImpact = (requestBody: StrategyTimeFrame, signal?: AbortSignal): Promise<EstimatedImpact> => {
        if (strategyId) {
            requestBody = {
                ...requestBody,
                strategyId: strategyId
            }
        }
        return wealthPOApiClient.getEstimatedImpactForAnnualGifting(id, requestBody, signal);
    }

    const handleSaveClickAnnualGifting = (requestParameters: CreateOrEditStrategyParameters) => {
        const {description, contributionAmount, yearsUntilFlow, yearsOfFlow} = requestParameters;

        let requestBody = {
            description,
            contributionAmount,
            yearsUntilFlow,
            yearsOfFlow
        } as AnnualGiftingStrategy

        if (strategyId) {
            requestBody = {
                ...requestBody,
                strategyId: strategyId,
            };

            return wealthPOApiClient.editAnnualGiftingStrategy(id, requestBody);
        } else {
            return wealthPOApiClient.createAnnualGiftingStrategy(id, requestBody);
        }
    }

    return (
        <StrategyWithTimeFrame profileId={id}
                               strategyId={strategyId}
                               header={`${strategyId === undefined ? "Add" : "Edit"} Annual Gifting Strategy`}
                               getEstimatedImpact={getEstimatedImpact}
                               handleSaveClick={handleSaveClickAnnualGifting}
                               isAnnualGift={true}
                               refreshStrategiesSummary={refreshStrategiesSummary}
        />
    )
}

export default AddEditAnnualGifting;