import {PlanSummaryResponse} from "../models/PlanSummaryResponse";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {wealthManagementApiClient} from "../../ClientManagement/WealthManagementApiClient";
import {RootState} from "../../store/store";

export interface PlanSummaryState {
    planSummary: PlanSummaryResponse;
    isPlanSummaryLoading: boolean;
}

const initialState: PlanSummaryState = {
    planSummary: {
        proposedAllocation: {
            totalRiskAssetsPercent: 0,
            totalRiskControlAssetsPercent: 0,
        }
    } as PlanSummaryResponse,
    isPlanSummaryLoading: false,
}

export const getPlanSummary = createAsyncThunk(
    'planSummary/getPlanSummary',
    async (profileId: string) => {
        return wealthManagementApiClient.getPlanSummary(profileId)
    }
)

const planSummarySlice = createSlice({
    name: 'planSummary',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getPlanSummary.pending, (state) => {
            state.isPlanSummaryLoading = true;
        });
        builder.addCase(getPlanSummary.fulfilled, (state, action: PayloadAction<PlanSummaryResponse>) => {
            state.isPlanSummaryLoading = false;
            state.planSummary = action.payload;
        });
        builder.addCase(getPlanSummary.rejected, (state) => {
            state.isPlanSummaryLoading = false;
        })
    }
});

export const selectPlanSummary = (state: RootState) => state.client.planSummary?.planSummary;
export const selectIsPlanSummaryLoading = (state: RootState) => state.client.planSummary?.isPlanSummaryLoading;

export default planSummarySlice.reducer;