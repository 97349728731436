import React from "react";
import {Mapping as ClassNamesMapping} from "classnames";
import {CellClassParamsReport, TableRowComponentReport} from "./TableRowComponentReport";
import {TableRow} from "../../../components";
import useAccordionTableToggle from "../../../components/AccordionTable/useAccordionTableToggle";

interface ExpandableTableRowProps<TR> {
    accordionTableId_: string;
    additionalCellClasses_?: (params: CellClassParamsReport) => ClassNamesMapping[];
    ariaLabel_: string;
    row_: TR;
    rowIndex_: number;
    titleCellWidth_: number;
    onTitleCellMouseEnter_?: (row: TR, rowIndex?: number) => void;
    onTitleCellMouseLeave_?: (row: TR) => void;
    valueColumnSize_?: number;
    isReportExpanded_?: boolean
}

export const ExpandableTableRowReport = <ValueType, TR extends TableRow<ValueType>>({
                                                                                  accordionTableId_,
                                                                                  additionalCellClasses_,
                                                                                  ariaLabel_,
                                                                                  rowIndex_,
                                                                                  row_,
                                                                                  titleCellWidth_,
                                                                                  onTitleCellMouseEnter_,
                                                                                  onTitleCellMouseLeave_,
                                                                                  valueColumnSize_,
                                                                                  isReportExpanded_ = false
                                                                              }: ExpandableTableRowProps<TR>) => {
    const rowId_ = `${accordionTableId_}-${row_.uniqueIdentifier}`;
    const {toggleExpanded} = useAccordionTableToggle(rowId_);

    return (
        <>
            <TableRowComponentReport
                key={row_.uniqueIdentifier}
                row_={row_}
                additionalCellClasses_={additionalCellClasses_}
                ariaLabel_={ariaLabel_}
                onClick_={row_.children.length > 0 ? toggleExpanded : undefined}
                rowIndex_={rowIndex_}
                titleCellWidth_={titleCellWidth_}
                onTitleCellMouseEnter_={onTitleCellMouseEnter_}
                onTitleCellMouseLeave_={onTitleCellMouseLeave_}
                valueColumnSize_={valueColumnSize_}
                isExpanded_={isReportExpanded_}
            />
            {isReportExpanded_ && row_.children.map((childRow_) =>
                <TableRowComponentReport
                    key={childRow_.uniqueIdentifier}
                    row_={childRow_}
                    additionalCellClasses_={additionalCellClasses_}
                    ariaLabel_={ariaLabel_}
                    titleCellWidth_={titleCellWidth_}
                    indentTitle_={true}
                    valueColumnSize_={valueColumnSize_}
                    isExpanded_={false}
                />
            )}
        </>
    )
}

