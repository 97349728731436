import {BeneficiariesFormData} from "./ui";
import {GroupedTableData} from "../../components/Table/GroupedTable";
import {AllBequests, EstateSummary} from "./api";

export function mapToFormData(allBequests: AllBequests): BeneficiariesFormData {
    return {
        memberBequests: allBequests.memberBequests.map(bequest => ({
            memberId: bequest.memberId,
            presentValue: bequest.presentValue.toString(),
        })),
        philanthropicBequests: allBequests.philanthropicBequests.map(bequest => ({
            philanthropyName: bequest.philanthropyName,
            presentValue: bequest.value.toString(),
            value: bequest.value.toString(),
            type: bequest.type
        })),
        residuaryMemberBequests: allBequests.residuaryMemberBequests.map(bequest => ({
            percentage: bequest.percentage.toString(),
            memberId: bequest.memberId,
        }))
    };
}

export function mapEstateSummaryToGroupedTableData(estateSummary: EstateSummary): GroupedTableData[] {
    const groupTableData = [];
    if (estateSummary.philanthropicBeneficiaries.length > 0) {
        const philanthropicBeneficiaries = estateSummary.philanthropicBeneficiaries.map(beneficiary => ({
            itemName: beneficiary.philanthropyName,
            itemValue: beneficiary.value
        }));
        const philanthropicGroupTableData: GroupedTableData = {
            groupName: "Philanthropy",
            groupId: '',
            items: philanthropicBeneficiaries,
            groupValue: philanthropicBeneficiaries.reduce((previousValue: number, currentValue) => {
                return previousValue + currentValue.itemValue;
            }, 0)
        };
        groupTableData.push(philanthropicGroupTableData);
    }

    if(estateSummary.memberBeneficiaries.length > 0){
        const individualBeneficiaries = estateSummary.memberBeneficiaries.map(memberBeneficiary => ({
            itemName: `${memberBeneficiary.name} (Age ${memberBeneficiary.age})`,
            itemValue: memberBeneficiary.presentValue
        }));

        const individualGroupTableData: GroupedTableData = {
            groupName: "Individual",
            groupId: '',
            items: individualBeneficiaries,
            groupValue: individualBeneficiaries.reduce((previousValue: number, currentValue) => {
                return previousValue + currentValue.itemValue;
            }, 0)
        };
        groupTableData.push(individualGroupTableData);
    }

    if (estateSummary.unallocatedAmountToBeneficiaries > 0) {
        const remainingAmountTableData = {
            groupName: "Remaining Amount",
            groupId: '',
            items: [],
            groupValue: estateSummary.unallocatedAmountToBeneficiaries,
        }
        groupTableData.push(remainingAmountTableData);
    }
    return groupTableData;
}
