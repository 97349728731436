import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../store/store";

export type AccordionPreferencesState = {
    expandedItems: string[];
}

export type AccordionPreferencesStateChangePayload = {
    accordionId: string;
    state: AccordionPreferencesState;
}

export type AccordionPreferencesStates = {
    accordionPreferencesStates: { [key: string]: AccordionPreferencesState };
    activeProfileId?: string;
}

export const initialAccordionPreferencesStates: AccordionPreferencesStates = { accordionPreferencesStates: {}};

export const accordionPreferencesSlice = createSlice({
    name: "accordionPreferences",
    initialState: initialAccordionPreferencesStates,
    reducers: {
        setAccordionPreferencesState: (state, action: PayloadAction<AccordionPreferencesStateChangePayload>) => {
            state.accordionPreferencesStates[action.payload.accordionId] = action.payload.state;
        },
        resetAccordionPreferencesState: (_state, action: PayloadAction<string>) => ({...initialAccordionPreferencesStates, activeProfileId: action.payload}),
    }
});

export const {setAccordionPreferencesState, resetAccordionPreferencesState} = accordionPreferencesSlice.actions;

export const selectAccordionPreferencesStates = (state: RootState): AccordionPreferencesStates => state.client.accordionPreferences;

export default accordionPreferencesSlice.reducer;
