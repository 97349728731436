import React, {FC, ReactNode} from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";

export type CustomModalProps = {
    isOpen: boolean,
    title: string | ReactNode,
    content?: string | ReactNode,
    onClickConfirm: () => void,
    confirmText: string,
    confirmButtonDestructive?: boolean,
    size?: string
}

const ConfirmationModal: FC<CustomModalProps> = ({
                                                     isOpen = false,
                                                     title,
                                                     content,
                                                     onClickConfirm,
                                                     confirmText,
                                                     confirmButtonDestructive = true,
                                                     size = 'small'
                                                 }) => (
    <ModalWrapper
        id="discard-changes-modal"
        isOpen={isOpen}
        headerText={title}
        size={size}
        buttons={[
            {
                text: `${confirmText}`,
                onClick: onClickConfirm,
                destructive: confirmButtonDestructive,
                primary: true,
            }
        ]}
    >
        {content ? <div data-testid="confirmation-content" className="font-md">{content}</div> :
            <div className="no-content"/>}
    </ModalWrapper>
);

export default ConfirmationModal;
