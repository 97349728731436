import DataEntrySummary, {DataEntrySummaryItem} from "../../components/DataEntry/DataEntrySummary";
import {Icon} from "../../components";
import React from "react";

export interface StrategySidebarProps {
    strategySummary: DataEntrySummaryItem[],
    estimatedImpact: DataEntrySummaryItem[]
}

export const StrategySidebar: React.FC<StrategySidebarProps> = ({
                                                                           strategySummary = [],
                                                                           estimatedImpact
                                                                       }) => {
    return (
        <aside data-testid="strategySidebar">
            {strategySummary.length !== 0 &&
                <div id="strategyWithTimeFrameStrategySummary">
                    <DataEntrySummary
                        title="Strategy Summary"
                        items={strategySummary}
                    />
                </div>
            }
            <div id="strategyWithTimeFrameEstimatedImpact" data-testid="estimatedImpactContainer">
                <DataEntrySummary
                    title="Estimated Impact"
                    items={estimatedImpact}
                >
                    <div id="estimatedInfoTooltip">
                        <Icon name="status_info_outline" type="info" className='paddingright-sm'
                              data-testid="estimatedImpactInfoIcon"/>
                        <div style={{fontStyle: "italic"}}>These values are estimates.</div>
                    </div>
                </DataEntrySummary>
            </div>
        </aside>
    );
}