import {AllGoalsType, GoalModelType} from "./models/GoalModelType";
import {Blane, Dale, Sloan, SloanWithChild} from "../ClientManagement/FamilyTree/FamilyTestUtils";
import {FamilyGoalsType, FamilyGoalType} from "./models/FamilyGoalType";
import {v4 as generate_uuid} from "uuid";
import {emptyPresentValueResponse} from "./models/PresentValue";
import {InvestorGroupType} from "../ClientManagement/models/InvestorGroupType";
import {testInvestorGroupResponse} from "../ClientManagement/TestUtils";
import moment from "moment";
import {ISO8601_DATE_FORMAT} from "../constants/common";
import {
    DiscretionaryGoalsType,
    GoalType,
    GoalUserInputs,
    NonLifestyleGoalType,
    PhilanthropicGoalsType,
    PrioritizationType,
    UpdateNonLifestyleGoalType
} from "./models/GoalType";
import {emptyLifestyleSpendingGoalResponse} from "./testSuppport/LifestyleGoalTestHelpers";
import {number} from "prop-types";

export const emptyFamilyGoal: FamilyGoalType = {
    id: undefined,
    beneficiaryId: generate_uuid(),
    ownerId: generate_uuid(),
    createdTimestamp: "",
    userInputs: {
        riskControl: null,
        riskAsset: null,
        startDate: "",
        endDate: "",
        annualFlow: 0,
        selectedGoalSubType: FamilyGoalsType.UNKNOWN,
        description: "",
        isFundedByNonInvestableAssets: false
    },
    calculatedFields: {
        riskAsset: 0,
        riskControl: 100,
        tripleNetDiscountRate: 0.00,
        grossDiscountRate: 0,
        presentValue: 0,
        goalsPresentValue: 0,
        presentValueByYear: []
    }
}

export const emptyNonLifestyleGoal: NonLifestyleGoalType = {
    id: undefined,
    beneficiaryId: undefined,
    ownerId: generate_uuid(),
    createdTimestamp: undefined,
    userInputs: {
        riskControl: null,
        riskAsset: null,
        startDate: "",
        endDate: "",
        annualFlow: 0,
        selectedGoalSubType: DiscretionaryGoalsType.DISCRETIONARY,
        description: "",
        isFundedByNonInvestableAssets: false
    },
    calculatedFields: {
        riskAsset: 0,
        riskControl: 100,
        tripleNetDiscountRate: 0.00,
        grossDiscountRate: 0,
        presentValue: 0,
        presentValueByYear: []
    }
}

export const emptyAllGoalsType: AllGoalsType = {
    totalGoalsPresentValue: 0,
    totalAssetPurchasesPresentValue: 0,
    lifeStyle:{...emptyLifestyleSpendingGoalResponse},
    nonLifestyle: [{...emptyNonLifestyleGoal}],
    family:{}
}


export const emptyDiscretionaryNonLifestyleGoalUserInputs: GoalUserInputs = {
    ...emptyNonLifestyleGoal.userInputs,
    selectedGoalSubType: DiscretionaryGoalsType.DISCRETIONARY
} as GoalUserInputs;

export const emptyDiscretionaryNonLifestyleGoal: NonLifestyleGoalType = {
    ...emptyNonLifestyleGoal,
    goalType: GoalType.DISCRETIONARY,
    prioritizationType:PrioritizationType.NEEDS,
    userInputs: emptyDiscretionaryNonLifestyleGoalUserInputs
} as NonLifestyleGoalType;

export const emptyUpdateDiscretionaryNonLifestyleGoal : UpdateNonLifestyleGoalType = {
    id: generate_uuid(),
    goalType: GoalType.DISCRETIONARY,
    prioritizationType: PrioritizationType.WANTS
}

export const emptyFamilyNonLifestyleGoal: NonLifestyleGoalType = {
    ...emptyNonLifestyleGoal,
    beneficiaryId: generate_uuid(),
    goalType: GoalType.FAMILY,
    prioritizationType:PrioritizationType.NEEDS,
    userInputs: {...emptyNonLifestyleGoal.userInputs, selectedGoalSubType: FamilyGoalsType.CUSTOM}
} as NonLifestyleGoalType;

export const emptyPhilanthropicNonLifestyleGoalUserInputs: GoalUserInputs = {
    ...emptyNonLifestyleGoal.userInputs,
    selectedGoalSubType: PhilanthropicGoalsType.ANNUAL_GIFT
} as GoalUserInputs;

export const emptyPhilanthropicNonLifestyleGoal: NonLifestyleGoalType = {
    ...emptyNonLifestyleGoal,
    goalType: GoalType.PHILANTHROPIC,
    prioritizationType:PrioritizationType.NEEDS,
    userInputs: emptyPhilanthropicNonLifestyleGoalUserInputs
} as NonLifestyleGoalType;


// Blane is Sloan's child
export const blanesCustomGoal: FamilyGoalType = {
    id: generate_uuid(),
    ownerId: SloanWithChild.id,
    beneficiaryId: Blane.id,
    createdTimestamp: "2025-04-20T02:05:22",
    userInputs: {
        annualFlow: 1_000_000,
        description: "Something Expensive",
        endDate: "2035-02-14",
        riskAsset: 17,
        riskControl: 83,
        selectedGoalSubType: FamilyGoalsType.CUSTOM,
        startDate: "2030-02-14",
        isFundedByNonInvestableAssets: true
    },
    calculatedFields: {
        grossDiscountRate: -0.42,
        presentValue: 1_200_245,
        riskAsset: 20,
        riskControl: 80,
        tripleNetDiscountRate: 3.75
    }
}
export const blanesWeddingGoal: FamilyGoalType = {
    ...blanesCustomGoal,
    id: generate_uuid(),
    createdTimestamp: "2026-04-20T02:05:22",
    userInputs: {
        ...blanesCustomGoal.userInputs,
        description: "GETTING MARRIED 🎉🎊🎉🎊🎉💒",
        annualFlow: 2_600_000,
        selectedGoalSubType: FamilyGoalsType.WEDDING,
        endDate: "2031-02-14"
    },
    calculatedFields: {
        ...blanesCustomGoal.calculatedFields,
        presentValue: 2_400_000
    },
    familyAssetsAmount: 10_000
}

export const blanesHomeGoal: FamilyGoalType = {
    id: generate_uuid(),
    ownerId: SloanWithChild.id,
    beneficiaryId: Blane.id,
    createdTimestamp: "2025-04-20T02:05:22",
    userInputs: {
        annualFlow: 1_000_000,
        description: "Home",
        endDate: "2038-02-14",
        riskAsset: 17,
        riskControl: 83,
        selectedGoalSubType: FamilyGoalsType.CUSTOM,
        startDate: "2037-02-14",
        isFundedByNonInvestableAssets: true
    },
    calculatedFields: {
        grossDiscountRate: -0.42,
        presentValue: 1_700_245,
        riskAsset: 20,
        riskControl: 80,
        tripleNetDiscountRate: 3.75
    }
}
export const blanesGiftGoal: FamilyGoalType = {
    id: generate_uuid(),
    ownerId: SloanWithChild.id,
    beneficiaryId: Blane.id,
    createdTimestamp: "2025-04-20T02:05:22",
    userInputs: {
        annualFlow: 700_000,
        description: "Gift",
        endDate: "2038-02-14",
        riskAsset: 17,
        riskControl: 83,
        selectedGoalSubType: FamilyGoalsType.CUSTOM,
        startDate: "2037-02-14",
        isFundedByNonInvestableAssets: true
    },
    calculatedFields: {
        grossDiscountRate: -0.42,
        presentValue: 1_200_245,
        riskAsset: 20,
        riskControl: 80,
        tripleNetDiscountRate: 3.75
    }
}
export const blanesTravelGoal: FamilyGoalType = {
    id: generate_uuid(),
    ownerId: SloanWithChild.id,
    beneficiaryId: Blane.id,
    createdTimestamp: "2025-04-20T02:05:22",
    userInputs: {
        annualFlow: 1_000_000,
        description: "Travel",
        endDate: "2038-02-14",
        riskAsset: 17,
        riskControl: 83,
        selectedGoalSubType: FamilyGoalsType.CUSTOM,
        startDate: "2037-02-14",
        isFundedByNonInvestableAssets: true
    },
    calculatedFields: {
        grossDiscountRate: -0.42,
        presentValue: 1_700_245,
        riskAsset: 20,
        riskControl: 80,
        tripleNetDiscountRate: 3.75
    }
}

export const dalesCollegeGoal: FamilyGoalType = {
    ...blanesCustomGoal,
    beneficiaryId: Dale.id,
    id: generate_uuid(),
    createdTimestamp: "2026-04-24T02:05:22",
    userInputs: {
        ...blanesCustomGoal.userInputs,
        description: "Masters",
        annualFlow: 1_600_000,
        selectedGoalSubType: FamilyGoalsType.GRADUATE_COLLEGE,
        endDate: "2031-02-14"
    },
    calculatedFields: {
        ...blanesCustomGoal.calculatedFields,
        presentValue: 2_400_000
    }
}

export const sloanExclusionGiftGoal: FamilyGoalType = {
    ...blanesCustomGoal,
    beneficiaryId: Sloan.id,
    id: generate_uuid(),
    createdTimestamp: "2026-04-24T02:05:22",
    userInputs: {
        ...blanesCustomGoal.userInputs,
        description: "Bare Minimum",
        annualFlow: 38_000,
        selectedGoalSubType: FamilyGoalsType.ANNUAL_EXCLUSION_GIFT,
        endDate: "2031-02-14"
    },
    calculatedFields: {
        ...blanesCustomGoal.calculatedFields,
        presentValue: 60_000
    }
}

export const testFamilyGoals: GoalModelType["familyGoals"] = {
    [Blane.id]: {
        totalPresentValue: blanesCustomGoal.calculatedFields.presentValue + blanesWeddingGoal.calculatedFields.presentValue
            +blanesGiftGoal.calculatedFields.presentValue +blanesHomeGoal.calculatedFields.presentValue + blanesTravelGoal.calculatedFields.presentValue,
        beneficiaryId: Blane.id,
        name: `${Blane.firstName} ${Blane.lastName}`,
        birthdate: Blane.birthdate,
        age: Blane.age,
        goals: {
            [blanesCustomGoal.id as string]: blanesCustomGoal,
            [blanesWeddingGoal.id as string]: blanesWeddingGoal,
            [blanesHomeGoal.id as string]: blanesHomeGoal,
            [blanesGiftGoal.id as string]: blanesGiftGoal,
            [blanesTravelGoal.id as string]: blanesTravelGoal,
        }
    },
    [Dale.id]: {
        totalPresentValue: dalesCollegeGoal.calculatedFields.presentValue,
        beneficiaryId: Dale.id,
        name: `${Dale.firstName} ${Dale.lastName}`,
        birthdate: Dale.birthdate,
        age: Dale.age,
        goals: {
            [dalesCollegeGoal.id as string]: dalesCollegeGoal,
        }
    },
    [Sloan.id]: {
        totalPresentValue: sloanExclusionGiftGoal.calculatedFields.presentValue,
        beneficiaryId: Sloan.id,
        name: `${Sloan.firstName} ${Sloan.lastName}`,
        birthdate: Sloan.birthdate,
        age: Sloan.age,
        goals: {
            [sloanExclusionGiftGoal.id as string]: sloanExclusionGiftGoal,
        }
    }
}

export const familyGoals : GoalModelType["familyGoals"] = {[Blane.id]: {
        totalPresentValue: 333,
        beneficiaryId: Blane.id,
        name: `${Blane.firstName} ${Blane.lastName}`,
        birthdate: Blane.birthdate,
        age: Blane.age,
        goals: {
            [blanesCustomGoal.id as string]: blanesCustomGoal,
            [blanesWeddingGoal.id as string]: blanesWeddingGoal,
        }
    }
}


export const changeGoalPresentValue = (goal: FamilyGoalType, newPresentValue: number): FamilyGoalType => {
    const calculatedFields = {
            ...emptyPresentValueResponse,
            ...goal.calculatedFields,
            presentValue: newPresentValue
    }
    return {
        ...goal,
        calculatedFields
    }
}


export function createInvestorGroup(startDate: string): InvestorGroupType {
    return {
        ...testInvestorGroupResponse,
        planningPeriod: {...testInvestorGroupResponse.planningPeriod, startYear: moment(startDate).year()},
        primaryMember: {
            ...testInvestorGroupResponse.primaryMember,
            birthdate: moment(startDate).year(testInvestorGroupResponse.planningPeriod.startYear - testInvestorGroupResponse.planningPeriod.ageFrom).format(ISO8601_DATE_FORMAT)
        }
    }
}



function createMockDate() : Date {
    var mockDate = new Date(Date.now());
    mockDate.setMonth(mockDate.getMonth() - 3);
    return mockDate;
}

export function getStartDate() : string {
    return createMockDate().toISOString().split('T')[0];
}

export function getGoalYearFrom() : string {
    return createMockDate().getFullYear().toString();
}

export function getGoalYearTo() : string {
    return (Number(getGoalYearFrom()) + 1).toString();
}

export function getClientAgeFrom(goalYearFrom:string, clientAgeFrom:string) : string {
    return (Number(clientAgeFrom) + (Number(getGoalYearFrom()) - Number(goalYearFrom))).toString();
}

export function getClientAgeTo(goalYearFrom:string, clientAgeFrom:string) : string {
    return (Number(getClientAgeFrom(goalYearFrom,clientAgeFrom)) + 1).toString();
}