import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../store/hooks";
import {selectActiveMeeting, selectParticipatingInMeeting} from "./meetingSlice";
import {useCommunicationsContext} from "./CommunicationsContext";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import ModalWrapper from "../../components/Modal/ModalWrapper/ModalWrapper";
import LoadingIndicator from "../../pages/LoadingIndicator";
import useMeetingConnectionUtils from "./utils/useMeetingConnectionUtils";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";

enum ModalState {
    DEFAULT,
    RECONNECT,
    LOADING,
}

type MeetingDetails = {
    meetingId: string;
    isAudioEnabled: boolean;
    isVideoEnabled: boolean;
};

const MeetingSessionStorageManager = () => {
    const activeMeeting = useAppSelector(selectActiveMeeting);
    const isParticipatingInMeeting = useAppSelector(selectParticipatingInMeeting);
    const {id: profileId} = useParams<RouteWithId>();
    const {approvedProfile} = useProfileAndProposals(profileId);

    const {audioVideoSettings} = useCommunicationsContext();
    const history = useHistory();
    const {joinMeeting} = useMeetingConnectionUtils();

    const [reconnectModalState, setReconnectReconnectModalState] = useState<ModalState>(ModalState.DEFAULT);
    const [showMeetingEndedMessage, setShowMeetingEndedMessage] = useState<boolean>(false);

    useEffect(() => {

        if (!!window.sessionStorage.getItem('meetingDetails')) {
            setReconnectReconnectModalState(ModalState.RECONNECT);
        }
        return () => {
            window.sessionStorage.removeItem('meetingDetails');
        }
    }, []);

    useEffect(() => {
        if (activeMeeting?.id && isParticipatingInMeeting) {
            window.sessionStorage.setItem('meetingDetails', JSON.stringify({
                meetingId: activeMeeting?.id,
                isAudioEnabled: audioVideoSettings.audioEnabled,
                isVideoEnabled: audioVideoSettings.videoEnabled,
            }));
        }
    }, [
        activeMeeting?.id,
        isParticipatingInMeeting,
        audioVideoSettings,
    ]);

    return <>
        {
            reconnectModalState === ModalState.RECONNECT
            && <ModalWrapper
                id="reconnect-to-meeting-modal"
                isOpen={true}
                headerText={''}
                buttons={[
                    {
                        text: 'REJOIN CLIENT MEETING',
                        onClick: () => {
                            const ssMeetingDetails = window.sessionStorage.getItem('meetingDetails');
                            if (ssMeetingDetails) {
                                setReconnectReconnectModalState(ModalState.LOADING);
                                const meetingDetails: MeetingDetails = JSON.parse(ssMeetingDetails);
                                joinMeeting({
                                    meetingId: meetingDetails.meetingId,
                                    profileId: profileId,
                                    onClickEndedMeeting: () => {
                                        setShowMeetingEndedMessage(true);
                                    },
                                    rejoinCallOptions: {
                                        isAudioEnabled: meetingDetails.isAudioEnabled,
                                        isVideoEnabled: meetingDetails.isVideoEnabled,
                                    },
                                }).then(() => {
                                    setReconnectReconnectModalState(ModalState.DEFAULT);
                                });
                            }
                        },
                        primary: true,
                    },
                    {
                        text: 'CANCEL',
                        disabled: !approvedProfile.id,
                        onClick: () => {
                            history.push(`/Profile/${approvedProfile.id}`);
                        }
                    },
                ]}
            >
                <div className="font-xl">You have been disconnected from your active client meeting. Would you like to
                    rejoin it?
                </div>
            </ModalWrapper>
        }
        {
            reconnectModalState === ModalState.LOADING
            && <ModalWrapper
                id="meeting-loading-modal"
                isOpen={true}
                headerText={''}
                buttons={[]}
            >
                <LoadingIndicator displayMessage={"Your meeting is loading..."}/>
            </ModalWrapper>
        }
        {
            showMeetingEndedMessage
            && <ModalWrapper
                id="meeting-ended-modal"
                isOpen={true}
                headerText={'This meeting has ended'}
                buttons={[
                    {
                        text: 'OKAY',
                        onClick: () => {
                            setShowMeetingEndedMessage(false);
                        },
                        primary: true,
                        destructive: true
                    }
                ]}
            >
                <div className="font-md">
                    The meeting you are trying to join has been ended by a presenter or participant.
                </div>
            </ModalWrapper>
        }
    </>;
}

export default MeetingSessionStorageManager;