import React, {useMemo, useState} from "react";
import {GoalType, NonLifestyleGoalType, PrioritizationType, UpdateNonLifestyleGoalType} from "../models/GoalType";
import {Table} from "xps-react";
import Prioritization, {NonLifestyleGoalRow} from "./Prioritization";
import {goalsApiClient} from "../GoalsApiClient";
import {DropdownItem, Icon, TableActionMenu} from "../../components";
import {goalTypeColumn} from "./NonLifeStyleGoalsFBNITable";
import {ProgressBar} from "../components/ProgressBar";
import {
    gePresentValueCell,
    getAnnualFlowCell,
    getNameCell,
    getYearsOfFlowCell,
    getYearsUntilFlowCell
} from "../components/NonLifestyleGoalsTable";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import {wealthManagementApiClient} from "../../ClientManagement/WealthManagementApiClient";
import DeleteGoalModal from "../components/DeleteGoalModal";

type NonLifestyleGoalsNeedsTableProps = {
    goalsForDisplay: NonLifestyleGoalRow[];
    tableClassName: string,
    profileId: string,
    name: string,
    displayProgressBar?: boolean,
    handleDropDownOnClick: (updatedNonLifestyleGoal: UpdateNonLifestyleGoalType) => void,
    handleDeleteOnClick: (updatedNonLifestyleGoal: UpdateNonLifestyleGoalType) => void,
    isProfileWithProposalsOrArchived?: boolean
}


const NonLifestyleGoalsPrioritizationTable = ({
                                                  goalsForDisplay,
                                                  tableClassName,
                                                  profileId,
                                                  name,
                                                  displayProgressBar,
                                                  handleDropDownOnClick,
                                                  handleDeleteOnClick,
                                                  isProfileWithProposalsOrArchived
                                              }: NonLifestyleGoalsNeedsTableProps) => {
    const {id} = useParams<RouteWithId>();
    const history = useHistory();
    const memoizedTable = useMemo(() => {
        return <Table
            resizable={false}
            showPagination={false}
            sortable={false}
            defaultPageSize={9999}
            data={
                goalsForDisplay
            }
            columns={
                [
                    {
                        minWidth: 5,
                        maxWidth: 12,
                    },
                    {
                        minWidth: 25,
                        maxWidth: 25,
                    },
                    {
                        accessor: 'name',
                        Cell: getNameCell(),
                        Header: 'NAME',
                    },
                    goalTypeColumn,
                    {
                        accessor: 'progress-bar',
                        Header: '% FUNDED BY PORTFOLIO',
                        Cell: getProgressBarCell(),
                        headerClassName: 'display-flex justify-content-end',
                        maxWidth: 128,
                        show: displayProgressBar ? 1 : 0
                    },
                    {
                        accessor: 'progress-bar-percentage',
                        Cell: getProgressBarPercentageCell(),
                        headerClassName: 'display-flex justify-content-end',
                        className: 'table-label-15px',
                        maxWidth: 100,
                        show: displayProgressBar ? 1 : 0
                    },
                    {
                        accessor: 'annual-flow',
                        Cell: getAnnualFlowCell(),
                        headerClassName: 'display-flex justify-content-end',
                        Header: 'ANNUAL FLOW',
                        maxWidth: 128
                    },
                    {
                        accessor: 'years-until-flow',
                        Cell: getYearsUntilFlowCell(),
                        headerClassName: 'display-flex justify-content-end',
                        Header: 'YEARS UNTIL FLOW',
                        maxWidth: 128
                    },
                    {
                        accessor: 'years-of-flow',
                        Cell: getYearsOfFlowCell(),
                        headerClassName: 'display-flex justify-content-end',
                        Header: 'YEARS OF FLOW',
                        maxWidth: 128
                    },
                    {
                        accessor: 'present-value',
                        Cell: gePresentValueCell(),
                        headerClassName: 'display-flex justify-content-end',
                        Header: 'PRESENT VALUE',
                        maxWidth: 128
                    },
                    {
                        accessor: 'rowActionMenu',
                        className: "overflow-visible",
                        minWidth: 32,
                        maxWidth: 32,
                        Cell: getThreeDotMenuCell(name, profileId, handleDropDownOnClick, handleDeleteOnClick, history, id, isProfileWithProposalsOrArchived)
                    }
                ]
            }

        />
    }, [goalsForDisplay, name, profileId, displayProgressBar, handleDeleteOnClick]);

    return (
        <div data-testid={`non-lifestyle-goal-${name}-table`} className={tableClassName}>
            {memoizedTable}
        </div>
    )
}

function getProgressBarCell() {
    return ({original}: { original: NonLifestyleGoalRow }) => {
        return (
            <ProgressBar percentage={original.fundedByPortfolioPercentage!}/>
        );
    };
}

function getProgressBarPercentageCell() {
    return ({original}: { original: NonLifestyleGoalRow }) => {
        return (
            <div>
                {original.fundedByPortfolioPercentage}%
            </div>
        );
    };
}

function getThreeDotMenuCell(
    name: string, profileId: string,
    handleDropDownOnClick: (updatedNonLifestyleGoal: UpdateNonLifestyleGoalType) => void,
    handleDeleteOnClick: (updatedNonLifestyleGoal: UpdateNonLifestyleGoalType) => void,
    history: any,
    id: string,
    isProfileWithProposalsOrArchived?: boolean
) {
    return ({original}: { original: NonLifestyleGoalRow }) => {
        const updatedNonLifestyleGoalType = {
            id: original.savedGoal!.id!,
            goalType: original.goalType!,
            prioritizationType: name == "needs" ? PrioritizationType.WANTS : PrioritizationType.NEEDS,
            beneficiaryId: original.savedGoal?.beneficiaryId,
        }
        const handleClickOnDropdown = async () => {
            const response = await goalsApiClient.updateNonLifestyleGoalForPrioritizationType(profileId, updatedNonLifestyleGoalType);
            if (response.ok) {
                handleDropDownOnClick(updatedNonLifestyleGoalType)
            }
        }
        const [showDeleteGoalModal, setShowDeleteGoalModal] = useState(false);
        const handleDeleteGoalDropdown = async () => {
            let response;
            let wealthResponse;
            if (updatedNonLifestyleGoalType.goalType === GoalType.FAMILY) {
                response = await goalsApiClient.deleteFamilyGoal(updatedNonLifestyleGoalType.id!)
            } else {
                response = await goalsApiClient.deleteNonLifestyleGoal(updatedNonLifestyleGoalType.id!);
                wealthResponse = await wealthManagementApiClient.deleteAssetRelianceStack(id, updatedNonLifestyleGoalType.id);
            }
            if (response.ok && wealthResponse) {
                handleDeleteOnClick(updatedNonLifestyleGoalType)
            }
            setShowDeleteGoalModal(false);
        }

        const handleEditNonLifestyleGoalDropDown = (savedGoal: NonLifestyleGoalType | undefined) => {
            const editURL = (() => {
                switch (updatedNonLifestyleGoalType.goalType) {
                    case GoalType.DISCRETIONARY :
                        return "EditDiscretionaryGoal";
                    case GoalType.PHILANTHROPIC :
                        return "EditPhilanthropicGoal";
                    case GoalType.FAMILY:
                        return "EditFamilyGoal";
                }

            })();

            history.push(
                `/Profile/${id}/ClientProfile/Goals/${editURL}`,
                (updatedNonLifestyleGoalType.goalType == GoalType.FAMILY) ?
                    {
                        ...history.location.state,
                        sourcePage: "Prioritization",
                        selectedGoalSubType: updatedNonLifestyleGoalType.goalType,
                        savedGoal

                    } :
                    {
                        ...history.location.state,
                        sourcePage: "Prioritization",
                        savedGoal
                    }
            )
        };
        return (
            <span className="table-action-menu">
                                    <TableActionMenu className="paddingright-0" panelWidth={240}
                                                     data-testid="three-dot-menu">
                                        <DropdownItem
                                            itemText={name == "needs" ? "Move to Wants" : "Move to Needs"}
                                            data-testid={`${name}-dropdown`}
                                            value=""
                                            key={`${name}-dropdown`}
                                            onClick={handleClickOnDropdown}
                                        />
                                        <DropdownItem
                                            itemText="Edit Goal"
                                            data-testid={`edit-goal-dropdown`}
                                            value=""
                                            key="edit-goal-dropdown"
                                            onClick={() => handleEditNonLifestyleGoalDropDown(original.savedGoal)}
                                        />

                                        <DropdownItem
                                            itemText={'Delete Goal'}
                                            data-testid='delete-dropdown'
                                            value=""
                                            key='delete-goal-dropdown'
                                            onClick={() => setShowDeleteGoalModal(true)}
                                            disabled={isProfileWithProposalsOrArchived}
                                        >
                                            <Icon name="delete" className="goals-table-delete-icon"/>
                                        </DropdownItem>

                                    </TableActionMenu>
                                    <DeleteGoalModal showDeleteGoalModal={showDeleteGoalModal}
                                                     onCancel={() => setShowDeleteGoalModal(false)}
                                                     onDelete={() => handleDeleteGoalDropdown()}
                                                     goalType={original.goalType}
                                                     description={original.name}
                                                     cancelButtonText={"CANCEL"}/>

                                </span>
        )
    };
}

export default NonLifestyleGoalsPrioritizationTable;



