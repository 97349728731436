import React from "react";

type ProgressBarProps = {
    percentage: number
}

export const ProgressBar = (props: ProgressBarProps) => {
    const progressBarWidth = 120;
    return (
        <span className="display-flex flex-row align-items-center height-100p">
           <div className="flex-column">
                <div className="filler" style={{width: `${((props.percentage/100)*progressBarWidth)}px`}}/>
            </div>
            <div className="flex-column">
                <div className="progress-bar flex-column justify-content-center" data-testid={"progress-bar"}/>
            </div>
        </span>
    )
}