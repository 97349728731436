import {AgendaDetails, Presenter, PresenterType, ServiceTeamMemberAgenda} from "./models/AgendaDetails";
import React, {ChangeEvent, ReactNode, useEffect, useState} from "react";
import {Button, Input, UnderlinedHeader} from "../components";
import {ServiceTeamMember} from "../ClientManagement/models/ServiceTeam";
import {EditableDropdownAgenda} from "./EditableDropdownAgenda";

type PresentersProps = {
    partners: ServiceTeamMember[],
    updatedAgendaDetails: AgendaDetails,
    setUpdatedAgendaDetails: (updatedAgendaDetails: AgendaDetails) => void,
}

function PresentersSection(props: { title?: string, buttonText: string, onAddPresenter: () => void, children: ReactNode, leftAlignedContent?: ReactNode }) {
    return <section>
        <UnderlinedHeader
            className="agenda-details-section-header"
            primaryText={props.title}
            leftAlignedContent={props.leftAlignedContent}

            rightAlignedContent={<Button
                icon="left"
                iconName="add"
                size="medium"
                kind="borderless"
                onClick={props.onAddPresenter}
            >
                {props.buttonText}
            </Button>}
        />
        {props.children}
    </section>
}


const Presenters: React.FC<PresentersProps> = ({partners, updatedAgendaDetails, setUpdatedAgendaDetails}) => {
    const [ntPresenters, setNtPresenters] = useState<Presenter[]>([]);
    const [externalPresenters, setExternalPresenters] = useState<Presenter[]>([]);
    const [ntPartners, setNtPartners] = useState<ServiceTeamMemberAgenda[]>(partners);

    useEffect(() => {

        // form ntPartners with selected presentrs having disabled true, and non-selected presenter having disabled false.
        const customPresenters: Presenter[] = [];
        updatedAgendaDetails.presenters.filter(p => p.presenterType == 'NT_PRESENTER' && p.presenterName).forEach((np) => {
            const selectedPresenterIndexInPartners = partners.findIndex(p => (p.partnerCode == np.presenterId));

            if (selectedPresenterIndexInPartners == -1) {
                customPresenters.push(np);
            }
        });
        const copyOfNtPartners: Array<ServiceTeamMemberAgenda> = JSON.parse(JSON.stringify(ntPartners));

        customPresenters.forEach(np => {
            copyOfNtPartners.push(
                {
                    partnerCode: np.presenterId as string,
                    partnerName: np.presenterName as string,
                    phoneNumber: '',
                    email: '',
                    title: np.presenterTitle as string,
                    disabled: false
                }
            )
        });
        setNtPartners(JSON.parse(JSON.stringify(copyOfNtPartners)));
    }, [])


    useEffect(() => {
        setNtPresenters(updatedAgendaDetails.presenters.filter(presenter => presenter.presenterType === 'NT_PRESENTER'));
        setExternalPresenters(updatedAgendaDetails.presenters.filter(presenter => presenter.presenterType === 'EXTERNAL_PRESENTER'));

    }, [updatedAgendaDetails?.presenters])

    function formatDisplayName(partnerName: string, partnerTitle: string) {
        return (partnerName + (partnerTitle ? `, ${partnerTitle}` : ''));
    }

    function addNewPresenter(presenterType: PresenterType) {

        const ntPresenter = [...ntPresenters];
        const extPresenter = [...externalPresenters];

        if (presenterType === 'NT_PRESENTER') {
            ntPresenter.splice(ntPresenter.length, 0, {presenterType})
        }
        setNtPresenters(ntPresenter);
        if (presenterType === 'EXTERNAL_PRESENTER') {
            extPresenter.splice(extPresenter.length, 0, {presenterType})
        }

        setUpdatedAgendaDetails({
            ...updatedAgendaDetails,
            presenters: [...ntPresenter, ...extPresenter]
        })
    }

    function deletePresenter(presenterType: PresenterType, index: number) {
        const updatedPresenters = [...ntPresenters, ...externalPresenters];
        const presenterId = updatedPresenters[index].presenterId;
        let indexOfPresenter = index;
        if (presenterType === "EXTERNAL_PRESENTER") {
            indexOfPresenter = ntPresenters.length + index;
        }
        updatedPresenters.splice(indexOfPresenter, 1);
        setUpdatedAgendaDetails({
            ...updatedAgendaDetails,
            presenters: updatedPresenters
        })

        const copyOfPartners = ntPartners;
        const deletedPartner = copyOfPartners.find(p => p.partnerCode == presenterId);
        if (deletedPartner) deletedPartner.disabled = false;
        setNtPartners(copyOfPartners)
    }

    function getPartnerDisplayName(presenterId: string, presenterName: string) {
        if (!presenterId) return presenterName;
        const selectedPartner = ntPartners.find(partner => partner.partnerCode === presenterId);
        return (selectedPartner && selectedPartner.partnerName);
    }

    function handleChangePresenter(value: string, index: number, name: string, id?: string) {
        const existingPartner = ntPartners.find(p => p.partnerCode == (id || value));
        const updatedPresentersList = [...ntPresenters, ...externalPresenters];
        if (!existingPartner) {
           updatedPresentersList[index] = {
                ...updatedPresentersList[index],
                presenterId: value,
                [name]: value,
            }
        } else {
            const selectedPartnerIndex = ntPartners.findIndex(p => p.partnerCode == (id || value));
            const newNtPartners = ntPartners;
            if (name === 'presenterTitle'){
                newNtPartners[selectedPartnerIndex] = {...newNtPartners[selectedPartnerIndex], title: value};
            }

            newNtPartners[selectedPartnerIndex] = {...newNtPartners[selectedPartnerIndex], [name]: value};
            setNtPartners(newNtPartners);

            const existingPresenter: Presenter = {
                presenterId: existingPartner.partnerCode,
                presenterName: existingPartner.partnerName,
                presenterTitle: existingPartner.title,
                presenterType: 'NT_PRESENTER'
            }
            const updatedNTPresenters = ntPresenters;

            if (name === 'presenterTitle') {
                updatedNTPresenters[index] = {...existingPresenter, [name]: value};
                updatedPresentersList[index] = {...updatedPresentersList[index], ...existingPresenter, [name]: value}
            } else {
                updatedNTPresenters[index] = {...existingPresenter};
                updatedPresentersList[index] = {...updatedPresentersList[index], ...existingPresenter}
            }

            setNtPresenters(updatedNTPresenters);

        }


        setUpdatedAgendaDetails({
            ...updatedAgendaDetails,
            presenters: updatedPresentersList
        })
    }

    function handleChangeExternalPresenter(e: ChangeEvent<HTMLInputElement>, index: number) {
        const {name, value} = e.target;
        const updatedPresentersList = [...ntPresenters, ...externalPresenters];
        const externalPresenterIndex = ntPresenters.length + index;
        updatedPresentersList[externalPresenterIndex] = {
            ...updatedPresentersList[externalPresenterIndex],
            [name]: value
        }
        setUpdatedAgendaDetails({
            ...updatedAgendaDetails,
            presenters: updatedPresentersList
        })
    }


    function addCustomPresenter(value: any, index: number) {

        if (value.startsWith(',')) return;
        if (ntPartners.findIndex(p => (p.partnerName == value && ((p.title == value) || !value))) >= 0) {
            return;
        }
        if (value) {
            const newPresenter: Presenter = {
                id: '',
                presenterId: value,
                presenterName: value,
                presenterTitle: '',
                presenterType: 'NT_PRESENTER'
            }
            setNtPresenters([...ntPresenters, newPresenter])
            handleChangePresenter(value, index, 'presenterName');
            setNtPartners([...ntPartners, {
                partnerCode: value,
                partnerName: value,
                phoneNumber: '',
                email: '',
                title: '',
                disabled: false
            }])
        }

    }

    const NTPresenter = ({
                             presenterId,
                             index,
                             presenterName
                         }: { presenterId: string, index: number, presenterName: string }) => {
        const displayName = getPartnerDisplayName(presenterId, presenterName);
        return <div key={presenterId || presenterName}
                    aria-label={`Northern Trust Presenter - ${displayName}`}
                    className="nt-presenter-dropDown">
            <EditableDropdownAgenda
                value={presenterId}
                aria-label={`Northern Trust Presenter Dropdown - ${displayName}`}
                items={ntPartners}
                onAdd={(value) => addCustomPresenter(value, index)}
                defaultText='Select or type...'
                onChange={(value) => {
                    handleChangePresenter(value, index, 'presenterName')
                }}
                label={""}/>

        </div>
    };

    return (
        <>
            <PresentersSection
                buttonText='Add Presenter'
                onAddPresenter={() => addNewPresenter('NT_PRESENTER')}
                leftAlignedContent={<><h4>Northern Trust Presenters</h4><span className="required-fields-subheader">* Required Fields</span></>}
            >
                {ntPresenters.map(({presenterId, presenterName, presenterTitle}, index) => (
                    <div key={`NT_PRESENTER-${index}`}
                         aria-label={`NT Presenter - ${presenterName}`}
                    >
                        <div className="layout-data-entry-form__field margintop-20">
                            <label id="northernTrustPresenter">
                                <b>Presenter Name</b>
                                <i className="color-text--error">*</i>
                            </label>
                            <NTPresenter
                                key={index}
                                presenterId={presenterId!}
                                index={index}
                                presenterName={presenterName as string}
                            />
                        </div>
                        <div className="layout-data-entry-form__field nt-presenter-input">
                            <label id="northernTrustPresenter">
                                <b>Presenter Title</b>
                            </label>
                            <Input
                                name="presenterName"
                                aria-label={`NT Presenter Title - ${presenterTitle}`}
                                removeMarginTop
                                size="medium"
                                type="text"
                                value={presenterTitle || ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangePresenter(e.target.value, index, 'presenterTitle', presenterId)}
                            />

                        </div>
                        <div className="nt-delete-button">
                            <Button
                                size="medium"
                                kind="borderless"
                                onClick={() => deletePresenter('NT_PRESENTER', index)}
                            >
                                Delete
                            </Button>
                        </div>
                        <hr className="section"/>
                    </div>
                ))}
            </PresentersSection>
            <PresentersSection
                title='External Presenters (optional)'
                buttonText='Add External Presenter'
                onAddPresenter={() => addNewPresenter('EXTERNAL_PRESENTER')}
            >
                <div>
                    {externalPresenters.map(({presenterName, presenterTitle}, index) => (
                        <div
                            className='external-presenter'
                            key={`EXTERNAL_PRESENTER-${index}`}
                            aria-label={`External Presenter - ${presenterName}`}
                        >
                            <div className="layout-data-entry-form__field">
                                <label id="presenterName"><b>Presenter Name</b></label>
                                <Input
                                    name="presenterName"
                                    aria-label={`External Presenter Name - ${presenterName}`}
                                    removeMarginTop
                                    size="medium"
                                    type="text"
                                    value={presenterName || ''}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeExternalPresenter(e, index)}
                                />
                            </div>
                            <div className="layout-data-entry-form__field">
                                <label id="presenterTitle"><b>Presenter Title</b></label>
                                <Input
                                    name="presenterTitle"
                                    aria-label={`External Presenter Title - ${presenterTitle}`}
                                    removeMarginTop
                                    size="medium"
                                    type="text"
                                    value={presenterTitle || ''}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeExternalPresenter(e, index)}
                                />
                            </div>
                            <div className="external-delete-button">
                                <Button
                                    size="medium"
                                    kind="borderless"
                                    onClick={() => deletePresenter('EXTERNAL_PRESENTER', index)}
                                >
                                    Delete
                                </Button>
                            </div>
                            <hr className="section"/>
                        </div>
                    ))}
                </div>
            </PresentersSection>
        </>
    )
}

export default React.memo(Presenters);