import {StackedBarChartItemType} from "./StackedBarChartItem";

export interface BarChartDataDescription {
    total: number;
}

export const MINIMUM_BAR_CHART_HEIGHT_PERCENTAGE = 5;

export function sumBarChartTotals(items: BarChartDataDescription[]): number {
    return Object.values(items).reduce((sum, current) => sum + Math.abs(current.total), 0);
}

export function enforceMinimumDisplaySize(input: StackedBarChartItemType[]): StackedBarChartItemType[] {
    let items = [...input]
    const overallTotal = sumBarChartTotals(items);
    const minimumValue = overallTotal * MINIMUM_BAR_CHART_HEIGHT_PERCENTAGE / 100;
    let remainingTotalToFitBigItems = overallTotal;
    let remainingRoomToScaleBigItems = overallTotal;

    items.filter(
        item => Math.abs(item.total) / overallTotal * 100 <= MINIMUM_BAR_CHART_HEIGHT_PERCENTAGE
    ).forEach(
        tooSmallItem => {
            remainingTotalToFitBigItems -= Math.abs(tooSmallItem.total);
            remainingRoomToScaleBigItems -= minimumValue;
            if (tooSmallItem.total >= 0) {
                tooSmallItem.total = minimumValue;
            } else {
                tooSmallItem.total = -1 * minimumValue;
            }
        }
    );

    items.filter(
        item => Math.abs(item.total) / overallTotal * 100 > MINIMUM_BAR_CHART_HEIGHT_PERCENTAGE
    ).forEach(
        itemWithRoomToSpare => {
            if (itemWithRoomToSpare.total >= 0) {
                itemWithRoomToSpare.total = (Math.abs(itemWithRoomToSpare.total) / remainingTotalToFitBigItems) * remainingRoomToScaleBigItems;
            } else {
                itemWithRoomToSpare.total = -1 * (Math.abs(itemWithRoomToSpare.total) / remainingTotalToFitBigItems) * remainingRoomToScaleBigItems;
            }
        }
    );

    return items;
}
