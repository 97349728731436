import {AlertBanner} from "../../../components";
import React from "react";

export type PartiallyOwnedInvestmentErrorState = '' | 'AccountAlreadyAdded' | 'AccountAlreadyAddedInProfile';

export type PartiallyOwnedInvestmentErrorProps = {
    state: PartiallyOwnedInvestmentErrorState,
    onClosed: () => void
};

export function PartiallyOwnedInvestmentError({state, onClosed}: PartiallyOwnedInvestmentErrorProps) {
    const error = (() => {
        switch(state) {
            case 'AccountAlreadyAdded':
                return {
                    message: 'Unable to add the selected account.',
                    detail: 'It is already linked to a profile through an Investment Program.'
                };
            case 'AccountAlreadyAddedInProfile':
                return {
                    message: 'Unable to add the selected account.',
                    detail: 'It is already linked to this profile or proposal'
                };
            default:
                return undefined;
        }
    })();

    return (
        <AlertBanner
            fullWidth={false}
            showAlert={!!error}
            icon="warning"
            type="error"
            showCloseBtn
            onClose={onClosed}
            message={error?.message}
            detail={error?.detail}
        >
        </AlertBanner>
    )
}