import React from "react";
import {formatCurrency} from "../../utils/format";
import {useAppSelector} from "../../store/hooks";
import {selectFutureEstateValueWithStrategies} from "../WealthPlanOptimizerSlice";

const SummaryCard= () => {
    const futureEstateValueWithStrategies = useAppSelector(selectFutureEstateValueWithStrategies);
    const totalAmountToBenefeciaries = futureEstateValueWithStrategies?.totalAmountToBeneficiaries || 0;
    const totalEstateTax = futureEstateValueWithStrategies?.totalEstEstateTax || 0;

    return (
        <div className="overview-section" >
            <div className="summary-statement-card" data-testid="summary-card">
                With these included strategies, <b>{formatCurrency(totalAmountToBenefeciaries)}</b> would
                be transferred to beneficiaries and <b>{formatCurrency(totalEstateTax)}</b> to
                estimated estate taxes.
            </div>
        </div>
    );
}

export default SummaryCard;