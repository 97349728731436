import {EstateSummary, EstateType} from "./models/api";
import {BarChartSidebar} from "../components";
import {formatCurrency} from "../utils/format";
import React from "react";

const createBarChartData = (estateSummary: EstateSummary) => {
    const barChartData = [];
    if (estateSummary.beneficiaryTotalValue) {
        barChartData.push({
            label: "Beneficiaries",
            className: "",
            color: "#66AA93",
            total: estateSummary.beneficiaryTotalValue,
        });
    }

    if (estateSummary.estimatedEstateTax) {
        barChartData.push({
            label: "Estimated Estate Tax",
            className: "",
            color: "#FFDF77",
            total: estateSummary.estimatedEstateTax
        })
    }
    return barChartData
}

export function WealthTransferBarChartSidebar({estateSummary, estateType, displayName}: {
    estateSummary: EstateSummary,
    displayName: string,
    estateType: EstateType,
}) {
    return <BarChartSidebar
        data={createBarChartData(estateSummary)}
        noDataText=""
        displayName={displayName}
        title="Wealth Transfer"
        subtitle="Explore opportunities to transfer your wealth, fulfill goals, and reduce your estate tax exposure.">

        <div>
                <span className="condensed-subtitle margintop-xxxl">
                    {estateType === EstateType.CURRENT ? "CURRENT ESTATE VALUE" : "FUTURE ESTATE VALUE"}
                </span>
            <div className="h1 margintop-sm marginbottom-xxxl" aria-label={"Current Estate Value"}>
                {formatCurrency(estateSummary.estateValue)}
            </div>
        </div>
    </BarChartSidebar>;
}
