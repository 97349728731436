import React from "react";

import {InflowPresentValue} from "../models/LifestyleSpendingGoal";
import moment from "moment/moment";
import {formatCurrency} from "../../utils/format";

const AlignedInflowsTable = (props: { portfolioReserveTargetLength: number, inflowPresentValues: InflowPresentValue[] }) => {
    const {portfolioReserveTargetLength, inflowPresentValues} = props;
    const currentYear = moment().year();

    return <div className="review-portfolio-reserve-page__body__aligned-inflows">
        <div>
                <span
                    className="font-lg fontweight-300 ">Annual Aligned Inflows from {currentYear}-{currentYear + portfolioReserveTargetLength}</span>
            <div>
                <div
                    className="review-portfolio-reserve-page__body__aligned-inflows__row review-portfolio-reserve-page__body__aligned-inflows__header">
                    <span>Inflow Name</span>
                    <span>Total Value</span>
                </div>
                {inflowPresentValues
                    .filter(inflowPresentValue => inflowPresentValue.portfolioReserveAligned > 0)
                    .map((inflowPresentValue, index) =>
                        (<div key={`aligned-inflow-${index}`} className="review-portfolio-reserve-page__body__aligned-inflows__content">
                            <hr/>
                            <div
                                className="review-portfolio-reserve-page__body__aligned-inflows__row font-md">
                                    <span
                                        className="inflow-description review-portfolio-reserve-page__body__aligned-inflows__data">{inflowPresentValue.description}</span>
                                <span
                                    className="inflow-value review-portfolio-reserve-page__body__aligned-inflows__data">{formatCurrency(inflowPresentValue.portfolioReserveAligned)}</span>
                            </div>
                        </div>))}
            </div>
        </div>
    </div>
}

export default AlignedInflowsTable;