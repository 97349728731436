import React from 'react';
import {Link, useParams} from "react-router-dom";
import {useAppSelector} from "../../store/hooks";
import FamilyGoalUtils from "./FamilyGoalUtils";
import {selectProfile} from "../controller/GoalsModelSlice";
import {Button, Dropdown} from "../../components";
import {RouteWithId} from "../../routes/types";
import useProfileEditableState from "../../hooks/useProfileEditableState";

export const noLifestyleGoalsLandingPageText = 'Let\'s discuss your goals. How much do you plan to spend on your core lifestyle each year?';
export const noFamilyGoalsLandingPageText = 'What other goals do you plan to achieve in your lifetime?'


const NoGoalsLandingPage = () => {
    const profile = useAppSelector(selectProfile)
    const {id} = useParams<RouteWithId>();
    const {isProfileWithProposalsReadOnly} = useProfileEditableState();

    const familyGoalDropdownItems = FamilyGoalUtils.getFamilyGoalDropdownItemsFor(profile);
    return (<>
        <article className="goals-summary-cta">
            <div>
                <h2 style={{width: '65%'}}>{noLifestyleGoalsLandingPageText}</h2>
                <Link to={`/Profile/${id}/ClientProfile/Goals/AddLifestyleSpending`}>
                    <Button
                        style={{width: '227px', textAlign: 'center'}}
                        className="goal-type-button"
                        icon="left"
                        iconName="add"
                        kind="primary"
                        rounded
                        size="medium"
                        disabled={isProfileWithProposalsReadOnly}
                    >
                        Add Lifestyle Spending
                    </Button>
                </Link>
                <div className="margintop-xxxl"> &nbsp;
                    <div className="margintop-md"></div>
                    <h2 style={{width: '100%'}}>{noFamilyGoalsLandingPageText}</h2>
                    <Link to={`/Profile/${id}/ClientProfile/Goals/AddDiscretionaryGoal`}>
                        <Button
                            style={{width: '227px', textAlign: 'center'}}
                            className="goal-type-button"
                            icon="left"
                            iconName="add"
                            kind="secondary"
                            rounded
                            size="medium"
                            disabled={isProfileWithProposalsReadOnly}
                        >
                            Add Discretionary Goal
                        </Button>
                    </Link>
                    <div className="margintop-lg">
                        <Link to={`/Profile/${id}/ClientProfile/Goals/AddPhilanthropicGoal`}>
                            <Button
                                style={{width: '227px', textAlign: 'center'}}
                                className="goal-type-button"
                                icon="left"
                                iconName="add"
                                kind="secondary"
                                rounded
                                size="medium"
                                disabled={isProfileWithProposalsReadOnly}
                            >
                                Add Philanthropic Goal
                            </Button>
                        </Link>
                    </div>
                    <div className="margintop-lg">{familyGoalDropdownItems.length > 0 && <Dropdown
                        className={"add-family-goal-dropdown-menu"}
                        buttonIcon="left"
                        buttonKind="secondary"
                        dropdownKind="menu"
                        defaultText="Add Family Goal"
                        iconNameOpen="add"
                        iconNameClose="add"
                        panelWidth="auto"
                        rounded={true}>
                        {familyGoalDropdownItems}
                    </Dropdown>
                    }
                    </div>
                </div>
            </div>
        </article>
    </>);
};

export default NoGoalsLandingPage;
