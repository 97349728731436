import {ClientMeetingDocumentsResponse, OldServiceTeam} from "./models/ClientDocuments";
import {emptyClientMeetingDocumentsSubMenusData, emptyServiceTeam} from "./ResourcesConstant";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store/store";
import {ProfileResponse} from "../ClientManagement/models/ProfileResponse";
import {testProfileResponse} from "../ClientManagement/TestUtils";

export interface MeetingDocumentsState {
    clientDocuments: ClientMeetingDocumentsResponse;
    myDocuments: ClientMeetingDocumentsResponse;
    approvedProfileFromApi: ProfileResponse;
    oldServiceTeam: OldServiceTeam
}

const initialState: MeetingDocumentsState = {
    clientDocuments: emptyClientMeetingDocumentsSubMenusData,
    myDocuments: emptyClientMeetingDocumentsSubMenusData,
    approvedProfileFromApi: testProfileResponse,
    oldServiceTeam: emptyServiceTeam,
};

export const clientMeetingDocumentSlice = createSlice({
    name: 'clientMeetingDocument',
    initialState,
    reducers: {
        setClientDocumentsResponse: (state, action: PayloadAction<ClientMeetingDocumentsResponse>) => {
            state.clientDocuments = action.payload;
        },
        setMyDocumentsResponse: (state, action: PayloadAction<ClientMeetingDocumentsResponse>) => {
            state.myDocuments = action.payload;
        },
        setApprovedProfileFromApiInStore: (state, action: PayloadAction<ProfileResponse>) => {
            state.approvedProfileFromApi = action.payload;
        },
        setOldServiceTeam: (state, action: PayloadAction<OldServiceTeam>) => {
            state.oldServiceTeam = action.payload;
        }
    }
});

export const {setClientDocumentsResponse, setMyDocumentsResponse, setApprovedProfileFromApiInStore, setOldServiceTeam} = clientMeetingDocumentSlice.actions;
export const selectMyDocuments = (state: RootState) => state.client.meetingDocuments.myDocuments;
export const selectClientDocuments = (state: RootState) => state.client.meetingDocuments.clientDocuments;
export const selectApprovedProfileFromApi = (state: RootState) => state.client.meetingDocuments.approvedProfileFromApi;
export const selectOldServiceTeam = (state: RootState) => state.client.meetingDocuments.oldServiceTeam;

export default clientMeetingDocumentSlice.reducer;