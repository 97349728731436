import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import {
    AssetsYearsInput,
    CurrencyInputWithLabel,
    Dropdown,
    DropdownItem,
    Input,
    Label,
    RadioGroup,
    RequiredFieldsBanner,
    RequiredFieldsSubheader,
    UnderlinedHeader
} from "../../components";
import {MemberGroup, MemberGroupMember} from "../../ClientManagement/models/InvestorGroupType";
import {ClientMemberOwnerDropdown} from "../Ownership/Sole/ClientMemberOwnerDropdown";
import {
    Beneficiary,
    EX_SPOUSE_LIFE_INSURANCE,
    LifeInsuranceFormData,
    lifeInsuranceTypes,
    SECOND_TO_DIE,
    SECOND_TO_DIE_ID
} from "../models/LifeInsurance";
import Ownership from "../Ownership";
import {LegalEntityFormData, OwnershipDetailsFormData, OwnershipWriteModel} from "../models/Ownership";
import {mapToInvestorMemberGroupMember, mapToOwnershipWriteModel} from "../Ownership/mappers";
import DataEntrySummary from "../../components/DataEntry/DataEntrySummary";
import deepEquals from "fast-deep-equal";
import {formatCurrency} from "../../utils/format";
import {isNaN} from "mathjs";
import DatePicker from "../../components/DatePicker/DatePicker";
import {DISPLAY_DATE_FORMAT, ISO8601_DATE_FORMAT} from "../../constants/common";
import moment, {Moment} from "moment";
import {booleanToRadioButton, radioButtonToBoolean} from "../formHelpers";
import {RadioYesNoOptional} from "../models/Common";
import {calculateInEstateFormValue} from "../AssetSummary/common/AssetSummaryCalculator";
import {hasSomeInEstateOwnershipInForm} from "../Ownership/helpers";
import {setActiveFormAsset} from "../clientAssetsSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectFamilyTree} from "../../ClientManagement/FamilyTree/FamilyTreeSlice";
import {LifeStatus} from "../../ClientManagement/models/MemberType";
import {FamilyRelationshipType} from "../../ClientManagement/models/FamilyRelationshipType";
import {calculateTotalAllocation, isLifeInsuranceFormDataMissing} from "./validation";
import {validateOwnershipDetails} from "../Ownership/validation";
import {mapLivingMemberGroupToList} from "../../ClientManagement/mappers";
import BeneficiariesForm from "./BeneficiariesForm";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import {HistoryBlockModal} from "../../components/HistoryBlockModal/HistoryBlockModal";
import { selectReleaseToggles } from "src/ReleaseToggles/releaseTogglesSlice";
export type LifeInsuranceFormProps = {
    handleSave: (request: LifeInsuranceFormData & OwnershipWriteModel) => Promise<void>,
    memberGroup: MemberGroup,
    profileId: string,
    initialLifeInsurance: LifeInsuranceFormData,
    initialOwnershipDetails: OwnershipDetailsFormData,
    defaultLifeInsurance: LifeInsuranceFormData,
    handleCancel: (isFormChanged: boolean) => void,
    formatTitle: (description: string) => string,
    legalEntities: LegalEntityFormData[],
    updateLegalEntities: (legalEntities: LegalEntityFormData[]) => void
};

const getUnselectedMembers = (selectedBeneficiaryMembers: Beneficiary[], allMembers: MemberGroupMember[], selectedInsuredId: string) => {
    const selectedBeneficiaryMembersIds = selectedBeneficiaryMembers
        .map((beneficiaryMember) => beneficiaryMember.memberId || beneficiaryMember.entityName);
    return allMembers.filter(member => !selectedBeneficiaryMembersIds.includes(member.id) && member.id !== selectedInsuredId);
}

const isFutureDate = (date: Moment) => date.isAfter(moment().endOf('day'));

export function LifeInsuranceForm(
    {
        handleSave,
        memberGroup,
        formatTitle,
        initialLifeInsurance,
        initialOwnershipDetails,
        defaultLifeInsurance,
        handleCancel,
        legalEntities,
        updateLegalEntities
    }: LifeInsuranceFormProps
) {
    const [form, updateForm] = useState<LifeInsuranceFormData>(initialLifeInsurance);
    const [ownershipFormData, updateOwnershipFormData] = useState<OwnershipDetailsFormData>(initialOwnershipDetails);
    const [term, setTerm] = useState<number>(0);
    const [isOwnershipPercentageErrorBannerShown, setOwnershipPercentageErrorBannerShown] = useState(false);
    const [isRequiredFieldsBannerShown, setRequiredFieldsBannerShown] = useState(false);
    const [isDescriptionInlineErrorShown, setIsDescriptionInlineErrorShown] = useState(false);
    const [upDatedMemberGroup, setMemberGroup] = useState<MemberGroup>(memberGroup);
    const [beneficiaryMembers, setBeneficiaryMembers] = useState<MemberGroupMember[]>([]);
    const [members, setMembers] = useState<MemberGroupMember[]>([]);
    const familyTree = useAppSelector(selectFamilyTree);
    const dispatch = useAppDispatch();
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();
    const [showBeneficiaryAlert, setShowBeneficiaryAlert] = useState<boolean>(false);
    const [showNavigationModal, setShowNavigationModal] = useState(true);
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const [issueDateError, setIssueDateError] = useState<string | undefined>(undefined)
    const [expirationDateError, setExpirationDateError] = useState<string | undefined>(undefined)

    useEffect(() => {
        const memberList = mapLivingMemberGroupToList(memberGroup);
        const beneficiaryMemberList = getUnselectedMembers(
            form.beneficiaries,
            memberList.filter(member => member.id !== form?.insuredMemberId),
            form.insuredMemberId);
        let entityList: Array<MemberGroupMember> = [];
        ownershipFormData.legalEntityOwnerships.forEach(entity => {
            entityList.push({
                id: entity.name,
                firstName: entity.name,
                lastName: '',
                age: 0,
                lifeStatus: LifeStatus.Living,
                deceasedDate: null,
                relationshipType: null
            })
        })
        setMembers([...memberList, ...entityList]);
        setBeneficiaryMembers([...beneficiaryMemberList, ...entityList]);
    }, []);

    useEffect(() => {
        setRequiredFieldsBannerShown(isRequiredFieldsBannerShown && isAnyRequiredFieldEmpty());
        setIsDescriptionInlineErrorShown(!form.description);
    }, [form.description, form.issueDate, form.expirationDate, ownershipFormData.legalEntityOwnerships]);

    useEffect(() => {
        const entityList: Array<MemberGroupMember> = [];
        if (ownershipFormData.legalEntityOwnerships.length > 0 && members.length) {
            ownershipFormData.legalEntityOwnerships.forEach(entity => {
                if (entity.name && members.findIndex(member => (member.firstName === entity.name)) === -1) {
                    entityList.push({
                        id: entity.name,
                        firstName: entity.name,
                        lastName: '',
                        age: 0,
                        lifeStatus: LifeStatus.Living,
                        deceasedDate: null,
                        relationshipType: null
                    })
                }
            })

            setMembers([...members, ...entityList]);
            setBeneficiaryMembers([...beneficiaryMembers, ...entityList]);
        }

    }, [ownershipFormData.legalEntityOwnerships]);

    useEffect(() => {
        setOwnershipPercentageErrorBannerShown(isOwnershipPercentageErrorBannerShown && isOwnershipPercentageNotEqual100());
    }, [ownershipFormData.legalEntityOwnerships, ownershipFormData.memberOwnerships]);

    useEffect(() => {
        setTerm(form.term);
    }, [form.term]);

    function getCurrentOwnerByOwnerMemberId(ownerMemberId: string) {
        let selectedOwner = upDatedMemberGroup.primaryMember;
        if (upDatedMemberGroup.partnerMember?.id === ownerMemberId) {
            selectedOwner = upDatedMemberGroup.partnerMember;
        } else if (upDatedMemberGroup.additionalMembers.length > 0 && (upDatedMemberGroup.additionalMembers.find(member => member.id === ownerMemberId) !== undefined)) {
            selectedOwner = upDatedMemberGroup.additionalMembers.find(member => member.id === ownerMemberId)!
        }
        return selectedOwner;
    }

    const getMemberName = (member: MemberGroupMember): string => {
        return `${member.firstName} ${member.lastName}`;
    }

    const handleSaveButton = async () => {
        const isRequiredFieldEmpty = isAnyRequiredFieldEmpty();
        const isInvalidIssueDate = issueDateError == undefined || form.type !== 'Term' ? false : true;
        const isInvalidExpirationDate = expirationDateError == undefined || form.type !== 'Term' ? false : true;
        setRequiredFieldsBannerShown(isInvalidIssueDate || isInvalidExpirationDate || isRequiredFieldEmpty );

        const isOwnershipPercentageInvalid = isOwnershipPercentageNotEqual100();
        setOwnershipPercentageErrorBannerShown(isOwnershipPercentageInvalid);

        const isInvalidBeneficiaryAllocation = (form.beneficiaries.length > 0 && calculateTotalAllocation(form.beneficiaries) !== 100.00);
        setShowBeneficiaryAlert(isInvalidBeneficiaryAllocation);
        if (isRequiredFieldEmpty || isOwnershipPercentageInvalid || isInvalidBeneficiaryAllocation || isInvalidIssueDate || isInvalidExpirationDate) {
            return false;
        }

        setShowNavigationModal(false);

        form.beneficiaries = form.beneficiaries.filter(beneficiary => beneficiary.memberId || beneficiary.entityName);
        const request = {
            ...form,
            term,
            ...mapToOwnershipWriteModel(ownershipFormData),
        };
        if (!form.deathBenefitValue) {
            request.beneficiaries = [];
        }
        await handleSave(request);

        return true;
    }

    const isFormChanged = () => {
        const updated = {
            ...form,
            ...ownershipFormData
        };
        const initial = {
            ...initialLifeInsurance,
            ...initialOwnershipDetails
        }
        return !deepEquals(initial, updated);
    }

    const onCancelClick = () => {
        setShowNavigationModal(false);
        handleCancel(isFormChanged());
    };

    const getBeneficiaryNames = useCallback((beneficiaries: Beneficiary[]) => {
        return beneficiaries.filter(beneficiary => beneficiary.memberId || beneficiary.entityName).map(beneficiary => {
            return beneficiary.entityName ? beneficiary.entityName : getMemberName(getCurrentOwnerByOwnerMemberId(beneficiary.memberId!))
        }).join(", ");
    }, [form.beneficiaries]);

    const onDeleteBeneficiary = (deleteIndex: number, beneficiaryList: Array<Beneficiary>) => {
        const removedMember = members.find(member => {
            return (member.id === form.beneficiaries[deleteIndex].memberId) || (member.firstName === form.beneficiaries[deleteIndex].entityName)
        });
        updateForm({
            ...form,
            beneficiaries: beneficiaryList
        });
        if (removedMember)
            setBeneficiaryMembers([...beneficiaryMembers, {...removedMember}]);
    }

    const onHandleTypeChange = (data: any) => {
        setIssueDateError(undefined)
        setExpirationDateError(undefined)
        updateForm({
            ...defaultLifeInsurance,
            id: form.id,
            type: data.value
        })
    }

    let ownerName = "";
    let count = 0;
    if (ownershipFormData.memberOwnerships.length > 0) {
        ownershipFormData.memberOwnerships.forEach(member => {
            count++
            ownerName = ownerName + getMemberName(getCurrentOwnerByOwnerMemberId(member.memberId)) + (count < ownershipFormData.memberOwnerships.length ? ", " : "");
        })
    }

    const assetSummarySectionValues = [
        {
            label: 'Description',
            value: form.description,
            subValue: `Issued ${(form.issueDate) ? moment(form.issueDate).format('MM/DD/YYYY') : '—'}`
        },
        {
            label: 'Company & Owner',
            value: form.insuranceCompanyName,
            subValue: ownerName
        },
        {
            label: 'Insured & Beneficiary',
            value: getMemberName(getCurrentOwnerByOwnerMemberId(form.insuredMemberId)),
            subValue: getBeneficiaryNames(form.beneficiaries)
        },
        {
            label: 'Death Benefit',
            value: formatCurrency(!isNaN(form.deathBenefitValue) ? form.deathBenefitValue : 0)
        }
    ];
    if (form.type !== lifeInsuranceTypes.TERM) {
        assetSummarySectionValues.push({
            label: 'Cash Value',
            value: formatCurrency(!isNaN(form.cashValue) ? form.cashValue : 0)
        });
    }


    useEffect(() => {
        const totalValue = isNaN(form.cashValue) ? 0 : form.cashValue;
        dispatch(setActiveFormAsset({
            assetType: 'lifeInsurance',
            id: form.id,
            inEstateValue: calculateInEstateFormValue(totalValue, ownershipFormData.memberOwnerships),
            description: form.description,
            hasInEstateOwnership: hasSomeInEstateOwnershipInForm(mapToOwnershipWriteModel(ownershipFormData).memberOwnerships),
        }));
        return clearActiveFormAsset;
    }, [form.description, form.cashValue, ownershipFormData, form.isCashValueWillFundGoals]);

    const clearActiveFormAsset = () => {
        dispatch(setActiveFormAsset(null));
    }

    const handleTermValue = (_event: ChangeEvent<HTMLInputElement>, value: number | string) => {
        setTerm(value as number);
    }

    useEffect(() => {
        let additionalMembers: MemberGroupMember[] = upDatedMemberGroup.additionalMembers;
        let isSecondToDie = false;
        if ((familyTree !== null && familyTree!.primaryContact !== null && familyTree!.primaryContact.family.length > 0)) {
            isSecondToDie = familyTree!.primaryContact.family.some(member => ((member.type === FamilyRelationshipType.SPOUSE || member.type === FamilyRelationshipType.EX_SPOUSE) && (member.fromMember.lifeStatus === LifeStatus.Living)));
        }
        if (isSecondToDie) {
            additionalMembers.push({
                id: SECOND_TO_DIE_ID,
                firstName: SECOND_TO_DIE,
                lastName: "",
                lifeStatus: LifeStatus.Living,
                deceasedDate: null,
                age: 0,
                relationshipType: null
            });
        }
        setMemberGroup({...upDatedMemberGroup, additionalMembers: [...additionalMembers]});
    }, [familyTree]);

    const calculateExpirationDate = (issueDate: string, termValue: number) => {
        if ((issueDate !== null && issueDate !== undefined) && termValue !== 0) {
            const issueDateMoment = moment(issueDate, ISO8601_DATE_FORMAT);
            const expirationDate = moment({
                year: issueDateMoment.year() + termValue,
                month: issueDateMoment.month(),
                date: issueDateMoment.date(),
            });
            updateForm({
                ...form,
                issueDate: issueDate,
                expirationDate: expirationDate.toISOString()
            })
        }
        else if ((issueDate !== null && issueDate !== undefined)) {
            updateForm({
                ...form,
                issueDate: issueDate,
            });
        }
    };

    const isAnyRequiredFieldEmpty = (): boolean => {
        return isLifeInsuranceFormDataMissing(form, ownershipFormData);
    }

    const isOwnershipPercentageNotEqual100 = (): boolean => {
        return !validateOwnershipDetails(ownershipFormData).isTotalOwnedPercentageEqualTo100;
    }

    const handleAddBeneficiary = () => {
        if (form.beneficiaries.length < members.length) {
            const defaultBeneficiary = {
                id: "",
                memberId: null,
                percentage: "0",
                entityName: ''
            };

            updateForm({
                ...form,
                beneficiaries: [
                    ...form.beneficiaries,
                    defaultBeneficiary
                ]
            })
        }
    }

    const onMemberChange = (memberIdOrEntityName: string, index: number) => {
        const newFormData = {
            ...form
        };
        if (ownershipFormData.legalEntityOwnerships.findIndex(legalEntityOwnership => (legalEntityOwnership.name === memberIdOrEntityName)) === -1) {
            form.beneficiaries[index].memberId = memberIdOrEntityName;
            form.beneficiaries[index].entityName = null;
        } else {
            form.beneficiaries[index].entityName = memberIdOrEntityName;
            form.beneficiaries[index].memberId = null;
        }
        updateForm(newFormData);
        setBeneficiaryMembers(getUnselectedMembers(form.beneficiaries, members, form.insuredMemberId));
    }

    const onPercentChange = (percentage: string, index: number) => {
        const newFormData = {
            ...form
        };
        newFormData.beneficiaries[index].percentage = percentage;
        updateForm(newFormData);
    }

    const onInsuredChange = (selectedClientOwnerMemberId: string) => {

        const previousInsuredMember = members.find(member => member.id === form.insuredMemberId)!;
        const newList = beneficiaryMembers.filter(member => member.id !== selectedClientOwnerMemberId);

        setBeneficiaryMembers(previousInsuredMember ? [...newList, {...previousInsuredMember}] : [...newList]);

        updateForm({
            ...form,
            insuredMemberId: selectedClientOwnerMemberId,
            beneficiaries: form.beneficiaries.filter(beneficiary => beneficiary.memberId !== selectedClientOwnerMemberId)
        });
    }

    const isPastDate = useCallback((date: Moment) => {

        const issueDateMoment = moment(form.issueDate, ISO8601_DATE_FORMAT);
        const expirationDate = moment({
            year: issueDateMoment.year() + term,
            month: issueDateMoment.month(),
            date: issueDateMoment.date(),
        });
        return date.isBefore(moment(expirationDate));
    }, [form.issueDate, term]);

    function handleIssueDateChange(date: Moment) {
        setIssueDateError(undefined)

        if (expirationDateError !== "Expiration Date is required.") {
            setExpirationDateError(undefined);
        }
        if (date == null) {
            setIssueDateError("Issue Date is required.");
            return false;
        }
        
        const issueDate = moment(moment(date).format("MM/DD/YYYY"))

        const expirationDateMoment = moment(moment(form.expirationDate).format("MM/DD/YYYY"))

        if (form.expirationDate && issueDate.isSameOrAfter(expirationDateMoment)) {
            setIssueDateError("The issue date must be before the expiration date.")
            return false;
        }

        return true;
    }

    function handleExpirationDateChange(date: Moment) {
        setExpirationDateError(undefined);
        
        if (issueDateError !== "Issue Date is required.") {
            setIssueDateError(undefined);
        }

        if (date == null) {
            setExpirationDateError("Expiration Date is required.")
            return false;
        }
        const expirationDate = moment(moment(date).format("MM/DD/YYYY"))

        const issueDateMoment = moment(moment(form.issueDate).format("MM/DD/YYYY"))
        
        if (form.issueDate && expirationDate.isSameOrBefore(issueDateMoment)) {
            setExpirationDateError("The expiration date must be after the issue date.")
            return false;
        }

        return true;
    }

    return <aside>
        <HistoryBlockModal
            when={isFormChanged() && showNavigationModal}
            itemType={'page'}
            onSave={handleSaveButton}
        />
        <DataEntryHeader
            title={formatTitle(form.description)}
            primaryButtonText='Save'
            disablePrimaryButton={isProfileWithProposalsOrArchived}
            secondaryButtonText='Cancel'
            onPrimaryButtonClick={handleSaveButton}
            onSecondaryButtonClick={onCancelClick}
        />
        <RequiredFieldsBanner showAlert={isRequiredFieldsBannerShown} itemType="life insurance"/>

        <div className="life-insurance__form layout-data-entry-form">
            <article>
                <section className="life-insurance-section">
                    <UnderlinedHeader
                        className="asset-details-section-header"
                        primaryText="Asset Details"
                        secondaryContent={<RequiredFieldsSubheader/>}
                    />
                    <div className="layout-data-entry-form__field">
                        <Label label="Description" htmlFor="lifeInsuranceDescriptionId" required/>
                        <Input
                            id="lifeInsuranceDescriptionId"
                            value={form.description}
                            readOnly={isProfileWithProposalsOrArchived}
                            onChange={(event) => {
                                updateForm({
                                    ...form,
                                    description: event.target.value
                                })
                            }}
                            error={isDescriptionInlineErrorShown ? "Description is required." : undefined}
                            size="medium"
                            maxLength={100}
                        />
                    </div>

                    <div className="layout-data-entry-form__field">
                        <Label label="Type" labelId="type"/>
                        <Dropdown
                            aria-labelledby="type"
                            className="typeField"
                            value={form.type}
                            onChange={onHandleTypeChange}
                            size="medium"
                            disabled={isProfileWithProposalsOrArchived}
                        >
                            <DropdownItem value={lifeInsuranceTypes.TERM} itemText={lifeInsuranceTypes.TERM}/>
                            <DropdownItem value={lifeInsuranceTypes.WHOLE} itemText={lifeInsuranceTypes.WHOLE}/>
                            <DropdownItem value={lifeInsuranceTypes.UNIVERSAL} itemText={lifeInsuranceTypes.UNIVERSAL}/>
                        </Dropdown>
                    </div>

                    <ClientMemberOwnerDropdown
                        id="insuredDropdown"
                        label="Insured"
                        labelId="insured"
                        value={mapToInvestorMemberGroupMember(getCurrentOwnerByOwnerMemberId(form.insuredMemberId))}
                        firstOwnerOption={mapToInvestorMemberGroupMember(upDatedMemberGroup.primaryMember)}
                        secondOwnerOption={mapToInvestorMemberGroupMember(upDatedMemberGroup.partnerMember !== null ? upDatedMemberGroup.partnerMember : upDatedMemberGroup.additionalMembers.find(member => member.relationshipType === EX_SPOUSE_LIFE_INSURANCE))}
                        thirdOwnerOption={mapToInvestorMemberGroupMember(upDatedMemberGroup.additionalMembers.length > 0 ? upDatedMemberGroup.additionalMembers.find(member => member.id === SECOND_TO_DIE_ID) : undefined)}
                        onChange={onInsuredChange}
                        disabled={isProfileWithProposalsOrArchived}
                    />

                    <div className="layout-data-entry-form__field">
                        <Label htmlFor="insuranceCompanyId" label="Insurance Company"/>
                        <Input
                            id="insuranceCompanyId"
                            value={form.insuranceCompanyName}
                            onChange={(event) => {
                                updateForm({
                                    ...form,
                                    insuranceCompanyName: event.target.value
                                })
                            }}
                            size="medium"
                            readOnly={isProfileWithProposalsOrArchived}
                        />
                    </div>
                    <div className="layout-data-entry-form__field">
                        <Label label="Issue Date" required={form.type === 'Term'} />
                        <div style={{position:"relative"}}>
                            <DatePicker
                                className="issueDateInput"
                                id="issueDateInput"
                                aria-label={"issueDateInput"}
                                displayFormat={DISPLAY_DATE_FORMAT}
                                hideKeyboardShortcutsPanel
                                isOutsideRange={isFutureDate}
                                date={form.issueDate ? moment(form.issueDate) : undefined}
                                disabled={isProfileWithProposalsOrArchived}
                                onDateChange={
                                    (date: Moment) => {
                                        if (form.type === 'Term') {
                                            handleIssueDateChange(date)
                                        }
                                        updateForm({
                                            ...form,
                                            issueDate: date?.toISOString()
                                        })
                                    }
                                }
                                error={issueDateError}
                            />
                        </div>
                    </div>
                    {(form.type === lifeInsuranceTypes.TERM) &&
                        <>
                            {(form.issueDate !== '' && releaseToggles?.enableTermLifeInsuranceField) &&
                                <div className="layout-data-entry-form__field" data-testid='termFieldYearsInput'>
                                    <div className="asset-year-input">
                                        <AssetsYearsInput
                                            label="Term"
                                            fieldName="term"
                                            value={term}
                                            onChangeValue={handleTermValue}
                                            readOnly={isProfileWithProposalsOrArchived}
                                            onBlur={(_event, value) => {
                                                calculateExpirationDate(form.issueDate!, value)
                                            }}
                                        />
                                    </div>
                                </div>}

                            <div className="layout-data-entry-form__field">
                                <Label label="Expiration Date" required={form.type === 'Term'}/>
                                <div style={{position:"relative"}}>
                                    <DatePicker
                                        className="expirationDateInput"
                                        id="expirationDateInput"
                                        aria-label={"expirationDateInput"}
                                        displayFormat={DISPLAY_DATE_FORMAT}
                                        hideKeyboardShortcutsPanel
                                        isOutsideRange={isPastDate}
                                        date={form.expirationDate ? moment(form.expirationDate) : undefined}
                                        disabled={isProfileWithProposalsOrArchived}
                                        onDateChange={
                                            (date: Moment) => {
                                                const expirationDate = date?.toISOString();
                                                handleExpirationDateChange(date)
                                                updateForm({
                                                    ...form,
                                                    expirationDate: expirationDate
                                                })                                                                                
                                            }
                                        }
                                        error={expirationDateError}
                                    />
                                </div>
                            </div>
                        </>
                    }

                    {(form.type !== lifeInsuranceTypes.TERM) &&
                        <>
                            <CurrencyInputWithLabel
                                label="Cash Value"
                                value={form.cashValue}
                                onChangeValue={(_, newValue) => {

                                    updateForm({
                                        ...form,
                                        cashValue: isNaN(newValue as number)?0:newValue as number,
                                    });
                                }}
                            />
                            <div className="layout-data-entry-form__field">
                                <RadioGroup
                                    id="cashValuetoFundGoals"
                                    name="isCashValueWillFundGoals"
                                    label="Do you plan to forfeit the death benefit to fund your goals?"
                                    layout="horizontal"
                                    values={["Yes", "No"]}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        const {value} = e.target;
                                        updateForm({
                                            ...form,
                                            isCashValueWillFundGoals: radioButtonToBoolean(value as RadioYesNoOptional),
                                        })
                                    }}
                                    selected={booleanToRadioButton(form.isCashValueWillFundGoals)}
                                    disabled={isProfileWithProposalsOrArchived}
                                />
                            </div>
                        </>
                    }
                    <CurrencyInputWithLabel
                        className={form.isCashValueWillFundGoals ? "disable-text" : ""}
                        label="Death Benefit"
                        value={form.deathBenefitValue}
                        readOnly={form.isCashValueWillFundGoals || isProfileWithProposalsOrArchived}
                        description={form.isCashValueWillFundGoals ? "Death benefit will be forfeited" : ""}
                        onChangeValue={(_, newValue) => {
                            updateForm({
                                ...form,
                                deathBenefitValue: isNaN(newValue as number)?0:newValue as number
                            });
                        }}
                    />
                </section>
                <Ownership
                    formData={ownershipFormData}
                    onFormDataChange={updateOwnershipFormData}
                    isOwnershipPercentageErrorBannerShown={isOwnershipPercentageErrorBannerShown}
                    totalAssetValue={form.cashValue}
                    memberGroup={upDatedMemberGroup}
                    legalEntities={legalEntities}
                    updateLegalEntities={updateLegalEntities}
                    isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                />

                {form.deathBenefitValue > 0 && <BeneficiariesForm
                    onDelete={onDeleteBeneficiary}
                    beneficiaries={form.beneficiaries}
                    members={members}
                    beneficiaryMembers={beneficiaryMembers}
                    onMemberChange={onMemberChange}
                    onPercentChange={onPercentChange}
                    onAdd={handleAddBeneficiary}
                    showAlert={showBeneficiaryAlert}
                    setShowAlert={setShowBeneficiaryAlert}
                    isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                />}

            </article>
            <aside>
                <DataEntrySummary
                    items={assetSummarySectionValues}
                    title='Asset Summary'
                />
            </aside>
        </div>
    </aside>;
}
