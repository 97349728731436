import React, {ReactNode} from "react";
import moment from "moment";

type PrintViewWrapperProps = {
    children: ReactNode;
    pageNumber?: number;
    displayName: string;
    showHeader?: boolean;
    showFooter?: boolean;
}
export const PrintViewWrapper = ({children, pageNumber, displayName, showHeader=true, showFooter=true}: PrintViewWrapperProps) => (
    <div className="printable-view" aria-label={`print-page-${pageNumber}`}>

        <div className={"report-content"}>
            {children}
        </div>
        {showFooter && <div className={"report-disclaimer"}>
            See "Important Information Regarding Hypothetical Projections."
            The return assumptions used in the Goals Driven Investing process are based on historical data and
            results do not reflect actual investment results and are not guarantees of future results.
            There are risks involved with investing, including possible loss of principal.
            Northern Trust makes no warranty, express or implied, as to the future performance or
            benefits of a portfolio modeled and managed in accordance with the Goals Driven Investing process.
            The process is dependent, in part, on values provided by clients which have not been independently verified.
            NOT FDIC INSURED/NO BANK GUARANTEE/MAY LOSE VALUE
        </div>}
    </div>
)

export default PrintViewWrapper