import RiskDonut from "../../ClientManagement/AssetAllocation/RiskDonut";
import RiskLegend, {riskLegendColor} from "../../components/Legend/RiskLegend";
import React from "react";
import {SimplifiedProposedAllocationResponse} from "../../ClientManagement/AssetAllocation/ProposedAllocationResponse";
import {roundPercentagesToHundred} from "../../utils/roundPercentagesToHundred";
import {Button} from "../../components";
import {useAppSelector, useAppDispatch} from "../../store/hooks";
import {
    selectPortfolioReserveButtonStates, selectPortfolioReserveUserPreferences,
    setPortfolioReserveButtonStates,
    setPortfolioReserveUserPreferences
} from "./PortfolioReserveSlice";


export const ReserveTargetAllocationDonut = (
    {reserveTargetAssetAllocation, assetAllocationRowActive}: { reserveTargetAssetAllocation: SimplifiedProposedAllocationResponse, assetAllocationRowActive: boolean }
) => {
    const dispatch = useAppDispatch();
    const portfolioReserveButtonStates = useAppSelector(selectPortfolioReserveButtonStates);
    const portfolioReserveUserPreferences = useAppSelector(selectPortfolioReserveUserPreferences);
    const {assetAllocation} = portfolioReserveButtonStates;

    const {
        percentage1: roundedRiskAssetsPercent,
        percentage2: roundedRiskControlAssetsPercent
    } = roundPercentagesToHundred(reserveTargetAssetAllocation.totalRiskAssetsPercent, reserveTargetAssetAllocation.totalRiskControlAssetsPercent);

    const assetAllocationClickHandler = () => {

        dispatch(setPortfolioReserveButtonStates({
            ...portfolioReserveButtonStates,
            assetAllocation: true
        }))

        if(portfolioReserveButtonStates.excessAssets && portfolioReserveButtonStates.portfolioRisk){
            dispatch(setPortfolioReserveUserPreferences({
                ...portfolioReserveUserPreferences,
                editablePortfolioReserve: true,
                clickedPortfolioReserveTargetLengthInput: false,
                clickedLifestyleMinimumRiskInput: false
            }));
        }

    };

    return (
        assetAllocation || assetAllocationRowActive ?
            <div className={`edit-portfolio-reserve-page__table__asset-allocation`}>
                <RiskDonut
                    data={reserveTargetAssetAllocation}
                    donutSize={`xsm`}
                />
                <div className={"risk-legend-label"} data-testid="RiskLegendForRA">
                    <RiskLegend legendColor={riskLegendColor.RISK_ASSETS}/>
                    <div className={'risk-label-centered'}>
                        <span className={"risk-label-margin-right"}>RA</span>
                        <span
                            className="edit-portfolio-reserve-page__table__excess-assets__amount font-xl color--primary">
                        {roundedRiskAssetsPercent}%
                    </span>
                    </div>
                </div>
                <div className={"risk-legend-label"} data-testid="RiskLegendForRC">
                    <RiskLegend legendColor={riskLegendColor.RISK_CONTROL_ASSETS}/>
                    <span className={'risk-label-centered'}>
                    <span className={"risk-label-margin-right"}>RC</span>
                    <span className="edit-portfolio-reserve-page__table__excess-assets__amount font-xl color--primary">
                        {roundedRiskControlAssetsPercent}%
                    </span>
                </span>
                </div>
            </div>
            :
            <div
                data-testid="asset-allocation-plus-button"
                className="edit-portfolio-reserve-page__centered" style={{width: "100%", height: "100%"}}>
                <Button
                    aria-label="AssetAllocationButton"
                    icon="only"
                    kind="borderless"
                    iconName="add_circle_outline"
                    size="large"
                    type="button"
                    className="edit-portfolio-reserve-page__button"
                    onClick={assetAllocationClickHandler}
                />
            </div>
    );
}