import React from "react";
import {Popover, Button} from "../../components";

export interface EquityVestedLabelAndInfoProps {
    labelId: string,
    labelText: string | number,
    popoverLabel: string,
    popoverContent: JSX.Element,
}

const EquityVestedLabelAndInfo = ({
                                   labelId,
                                   labelText,
                                   popoverLabel,
                                   popoverContent
                               }: EquityVestedLabelAndInfoProps) => {
    return (
        <div className={`display-flex justify-content-end`}>
            <label className="grantDateLabel paddingleft-sm textalign-left"
                   data-testid={labelId}>
                {labelText}
            </label>
            <Popover
                arrow={true}
                aria-label={popoverLabel}
                className="popover-text"
                content={popoverContent}
                direction={`bottom`}
                width={`288px`}>
                <Button className="popover-button"
                        icon="only"
                        iconName="info_outline"
                        kind="borderless"
                        size="small"
                />
            </Popover>
        </div>
    );
};

export default EquityVestedLabelAndInfo;