import React, {ReactNode, useState} from "react";
import {formatCurrency} from "../../utils/format";
import {TableCell} from "./TableCell";
import {TableActionDropdownMenu} from "./TableActionDropdownMenu";
import {DropdownItem} from "../Dropdown";
import classNames from "classnames";
import {shortenName} from "../../Assets/common/textUtils";
import {Icon} from "../Icon";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import useProfileEditableState from "src/hooks/useProfileEditableState";
import {assetsApiClient} from "../../Assets/AssetsApiClient";
import {selectClientAssets, setClientAssets} from "../../Assets/clientAssetsSlice";
import {DeleteModalProps, getApiDeleteFunction} from "../../Assets/common/AssetsView";
import {NO_OP} from "../../constants/common";
import DeletePartiallyOwnedInvestmentAccountConfirmationModal
    from "../../Assets/common/DeletePartiallyOwnedInvestmentAccountConfirmationModal";
import CustomModal from "../Modal/Custom/CustomModal";
import {wealthManagementApiClient} from "../../ClientManagement/WealthManagementApiClient";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";

const initialDeleteModalProps = {
    showDeleteModal: false,
    modalTitle: "Asset",
    assetDescription: "",
    assetId: "",
    handleDelete: NO_OP
} as DeleteModalProps;

export type GroupedTableData = {
    groupName?: string,
    groupId: string,
    groupValue?: number,
    items: GroupedTableItem[]
}

export type GroupedTableItem = {
    itemName: string,
    itemNameSubtext?: string,
    extraDetail1?: string,
    extraDetail2?: string,
    extraDetail3?: string,
    itemValue: number,
    menuOptions?: {
        itemText: string,
        value: string,
        route: string,
    }[],
}

type GroupedTableProps = {
    columnHeaders: string[],
    tableData: GroupedTableData[],
    menuClickHandler?: (route: string) => void,
    renderGroupHeader?: (group: GroupedTableData) => ReactNode,
};

export function GroupedTable({
                                 columnHeaders,
                                 tableData,
                                 menuClickHandler,
                                 renderGroupHeader = renderDefaultGroupHeader
                             }: GroupedTableProps) {

    const classesForColumnHeaderIndex = (index: number) => {
        if (index === 0) {
            const columnSpan = getFirstColumnSpan(columnHeaders.length);
            return columnSpan + ' justify-content-start';
        }
        return 'justify-content-end';
    }

    return <div role='table' className='grouped-table-grid grouped-table'>
        {columnHeaders.map((text, index) =>
            <span key={text}
                  role='columnheader'
                  className={classesForColumnHeaderIndex(index)}>{text}</span>)}
        <span aria-hidden role='columnheader'/>
        {tableData.map((group, index) =>
            <TableGroup key={index}
                        group={group}
                        tableWidth={columnHeaders.length}
                        menuClickHandler={menuClickHandler}
                        renderGroupHeader={renderGroupHeader}/>
        )}
    </div>;
}

type TableGroupProps = {
    group: GroupedTableData,
    tableWidth: number,
    menuClickHandler?: (route: string) => void,
    renderGroupHeader: (group: GroupedTableData) => ReactNode,
}

const renderDefaultGroupHeader = (group: GroupedTableData) => <GroupHeader groupName={group.groupName}
                                                                           groupValue={group.groupValue}/>;



function TableGroup({
                        group,
                        tableWidth,
                        menuClickHandler,
                        renderGroupHeader,
                    }: TableGroupProps) {
    const groupName = group.groupName;

    const dispatch = useAppDispatch();

    const profile = useAppSelector(selectProfile);
    const [customDelete, setDeleteModalProps] = useState<DeleteModalProps>(initialDeleteModalProps);
    const showPartiallyOwnedInvestmentDeleteConfirmationModal = customDelete
        .showDeleteModal && customDelete
        .assetType === 'partially owned investment account';
    const showDeleteConfirmationModal = customDelete
        .showDeleteModal && customDelete
        .assetType !== 'partially owned investment account';
    const activeProfileId = profile?.id;
    const clientAssets = useAppSelector(selectClientAssets);

    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    const hideDeleteModal = () => {
        setDeleteModalProps({
            ...customDelete,
            showDeleteModal: false
        });
    }

    let legalAgreementName = group.items.map(s=>s.itemName).toString();

    const onRemovePartiallyOwnedInvestmentAccount = async (partiallyOwnedInvestmentAccountId:string)=>{
        setDeleteModalProps({
            showDeleteModal: true,
            modalTitle: "Asset",
            assetDescription: legalAgreementName,
            assetType: "partially owned investment account",
            assetId: partiallyOwnedInvestmentAccountId.toString(),
            content: <div>this is custom content</div>
        })
    }

    const onRemoveStandAloneAccount = async (standAloneAccountAccountId:string)=>{

        let assetDescription = clientAssets!.accounts.data.filter(
            standAloneAccount => standAloneAccount.id === standAloneAccountAccountId
        )[0].name

        setDeleteModalProps({
            showDeleteModal: true,
            modalTitle: "Asset",
            assetDescription: assetDescription,
            assetType: "standalone account",
            assetId: standAloneAccountAccountId.toString(),
        })
    }


    const onRemoveBankingAccount = async (bankingAccountId:string)=>{

        let assetDescription = clientAssets!.bankingAccounts.filter(
            bankingAccount => bankingAccount.id === bankingAccountId
        )[0].accountName

        setDeleteModalProps({
            showDeleteModal: true,
            modalTitle: "Asset",
            assetDescription: assetDescription,
            assetType: "banking account",
            assetId: bankingAccountId.toString(),
        })
    }


    const onRemovePersonalAsset = async (standAloneAccountAccountId:string)=>{

        let assetDescription = clientAssets!.personalAssets.data.filter(
            standAloneAccount => standAloneAccount.id === standAloneAccountAccountId
        )[0].description


        setDeleteModalProps({
            showDeleteModal: true,
            modalTitle: "Asset",
            assetDescription: assetDescription,
            assetType: "personal asset",
            assetId: standAloneAccountAccountId.toString(),
        })
    }

    const onRemovePersonalLiability = async (personalLiabilityId:string)=>{

        let assetDescription = clientAssets!.personalLiabilities.filter(
            personalLiability => personalLiability.id === personalLiabilityId
        )[0].description


        setDeleteModalProps({
            showDeleteModal: true,
            modalTitle: "Liability",
            assetDescription: assetDescription,
            assetType: "liability",
            assetId: personalLiabilityId.toString(),
        })
    }

    const onRemoveLifeInsuranceAccount = async (standAloneAccountAccountId:string)=>{

         let assetDescription = clientAssets!.lifeInsurances.data.filter(
            standAloneAccount => standAloneAccount.id === standAloneAccountAccountId
        )[0].description

        setDeleteModalProps({
            showDeleteModal: true,
            modalTitle: "Asset",
            assetDescription: assetDescription,
            assetType: "life insurance",
            assetId: standAloneAccountAccountId.toString(),
        })
    }

    const onConformedDelete = async (partiallyOwnedInvestmentAccountId:string)=>{
        const response = await assetsApiClient.deletePartiallyOwnedInvestmentAccount(activeProfileId, partiallyOwnedInvestmentAccountId.toString())
        if(response.status === 200) {
            dispatch(setClientAssets({
                ...clientAssets!, partiallyOwnedLegalAgreements: clientAssets!.partiallyOwnedLegalAgreements.filter(
                    partiallyOwnedInvestmentAccount =>
                        partiallyOwnedInvestmentAccount.id !== partiallyOwnedInvestmentAccountId.toString()
                )
            }));
        }
        hideDeleteModal();
    }

    function getItemText(menuOp: { itemText: string; value: string; route: string }) {
        if(menuOp.route.includes('EditPartiallyOwnedInvestment') || menuOp.route.includes('EditStandaloneAccount') || menuOp.route.includes('EditBankingAccount')){
            return "Remove Account"
        } else if(menuOp.route.includes('EditPersonalAsset') || menuOp.route.includes('EditLifeInsurance')){
            return "Delete Asset"
        }
        else {
          return   "Delete Liability"
        }
    }

    function removeAccount(menuOp: { itemText: string; value: string; route: string }) {
       if(menuOp.route.includes('EditPartiallyOwnedInvestment')){
           return  onRemovePartiallyOwnedInvestmentAccount(menuOp.value.toString())
       } else if(menuOp.route.includes('EditStandaloneAccount')){
           return onRemoveStandAloneAccount(menuOp.value.toString())
       }
       else if(menuOp.route.includes('EditBankingAccount')){
           return onRemoveBankingAccount(menuOp.value.toString())
       }
       else if(menuOp.route.includes('EditLifeInsurance')){
           return onRemoveLifeInsuranceAccount(menuOp.value.toString())
       } else if(menuOp.route.includes('EditPersonalLiability')){
           return onRemovePersonalLiability(menuOp.value.toString())
       } else{
           return onRemovePersonalAsset(menuOp.value.toString())
       }
    }

    return <React.Fragment>
        {renderGroupHeader(group)}
        {
            group.items.map((item, index) =>
                <div className={classNames("invisible-row-wrapper", {'within-group': !!groupName})}
                     role="row"
                     key={groupName + item.itemName + index}
                     data-testid="table-group">
                    <TableCell text={item.itemName} subtext={item.itemNameSubtext} textSize="medium"
                               className={classNames("textalign-left", getFirstColumnSpan(tableWidth))}/>
                    {tableWidth > 2 && <TableCell text={item.extraDetail1} className="textalign-right"/>}
                    {tableWidth > 3 && <TableCell text={item.extraDetail2} className="textalign-right"/>}
                    {tableWidth > 4 && <TableCell text={item.extraDetail3} className="textalign-right"/>}
                    <TableCell text={formatCurrency(item.itemValue)} className='textalign-right cell-text-content'/>
                    {item.menuOptions && item.menuOptions.length ?
                        <TableActionDropdownMenu ariaLabel={`${item.itemName} Menu`} className="grouped-table-cell">
                            {item.menuOptions.map((menuOption) =>
                                <DropdownItem key={menuOption.route}
                                              itemText={menuOption.itemText}
                                              value={menuOption.value}
                                              onClick={() => menuClickHandler && menuClickHandler(menuOption.route)}/>
                            )}
                            {item.menuOptions.map((menuOp) =>
                                {
                                    if (!menuOp.route.includes('EditHeldAwayAccount'))
                                        return(<DropdownItem
                                            itemText={getItemText(menuOp)}
                                            value="Remove Account"
                                            disabled={isProfileWithProposalsOrArchived}
                                            onClick={
                                                ()=>{removeAccount(menuOp);
                                                }
                                            }
                                        />)
                                }
                            )}
                        </TableActionDropdownMenu> : <span aria-hidden/>
                    }
                </div>
            )
        }
        <DeletePartiallyOwnedInvestmentAccountConfirmationModal
            isOpen={showPartiallyOwnedInvestmentDeleteConfirmationModal}
            partiallyOwnedInvestmentAccount={clientAssets!.partiallyOwnedLegalAgreements.filter(
                partiallyOwnedAccount => partiallyOwnedAccount.legalAgreementName === customDelete
                    .assetDescription)[0]}
            onConfirm={onConformedDelete}
            onCancel={() => hideDeleteModal()}
        />
        <CustomModal
            isOpen={showDeleteConfirmationModal}
            title={`Delete this ${shortenName(customDelete.modalTitle,50)}?`}
            content={
                customDelete.content ??
                <>
                    <div className="font-md">
                        {`The ${customDelete.assetDescription} ${customDelete
                            .assetType?.toLowerCase()} and all associated data will be deleted permanently.`}
                    </div>
                </>
            }
            onClickConfirm={async () => {
                const apiDeleteFn = getApiDeleteFunction(customDelete
                    .assetType!);
                const response = await apiDeleteFn(profile.id, customDelete
                    .assetId);
                const responseStatus = response.status;

                if (responseStatus === 200) {
                    dispatch(setClientAssets(await assetsApiClient.getAssetsSummary(activeProfileId)));
                }
                await wealthManagementApiClient.deleteAssetRelianceStack(profile.id, customDelete
                    .assetId)

                hideDeleteModal();
            }}
            onClickCancel={() => hideDeleteModal()}
            confirmText={`Delete ${customDelete
                .modalTitle}`}
            cancelText={`Cancel`}
        />
    </React.Fragment>;
}

function GroupHeader(props: { groupName: string | undefined, groupValue: number | undefined }) {
    if (!props.groupName) {
        return <React.Fragment/>;
    }

    return <>
        <span role="cell"
              className="grouped-table-subheader textalign-left"><b>{shortenName(props.groupName)}</b></span>
        <GroupedTablePlaceholder/>
        <GroupedTablePlaceholder/>
        <GroupedTablePlaceholder/>
        {props.groupValue !== undefined ?
            <span role="cell"
                  className={classNames("grouped-table-subheader", "group-value")}>{formatCurrency(props.groupValue)}</span> :
            <GroupedTablePlaceholder/>}
        <GroupedTablePlaceholder/>
    </>;
}

export function LegalEntityGroupHeader(props: { groupName: string | undefined, groupId: string, groupValue: number | undefined, deleteEntity: (id: string) => void }) {
    if (!props.groupName) {
        return <React.Fragment/>;
    }

    return <>
        <span role="cell"
              className="grouped-table-subheader textalign-left"><b>{shortenName(props.groupName)}</b></span>
        <GroupedTablePlaceholder/>
        <GroupedTablePlaceholder/>
        <GroupedTablePlaceholder/>
        {props.groupValue !== undefined ?
            <span role="cell"
                  className={classNames("grouped-table-subheader", "group-value")}>{formatCurrency(props.groupValue)}</span> :
            <GroupedTablePlaceholder/>}
        <span role="cell">
            <TableActionDropdownMenu ariaLabel={`${props.groupName} Menu`}
                                     disabled={false}>
                <DropdownItem
                    itemText="Delete Entity"
                    value="Delete Entity"
                    onClick={() => props.deleteEntity(props.groupId)}>
                    <Icon name="delete"
                          className="asset-table-delete-icon"/>
                </DropdownItem>
            </TableActionDropdownMenu>
        </span>
    </>;
}

const MAXIMUM_NUMBER_OF_COLUMNS_ALLOWED = 6;

function getFirstColumnSpan(tableWidth: number) {
    return 'grid-span-' + (MAXIMUM_NUMBER_OF_COLUMNS_ALLOWED - tableWidth);
}

function GroupedTablePlaceholder() {
    return <div className="grid-span-1" aria-hidden/>;
}