import {GoalFundingOptions, NWOTReportOptions, ReportConfig, ReportType} from "../models/Reporting";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store/store";
import {initialSelectedPagesForAnnualReport} from "../ReportingConstants";
import {ClientMeetingDocumentItem} from "src/Resources/models/ClientDocuments";
import {FveDiscountRateType} from "../../ClientManagement/AssetReliance/AssetRelianceButtonState";
import {DataDisplayView} from "../../ClientManagement/AssetAllocation/ReviseAssetAllocation";
import {FundingDisplayOptions} from "../../Goals/Funding/models/Funding";

export interface ReportingState {
    selectedPagesForReport: ReportConfig,
    selectShowDrawerForClientDocuments: boolean,
    selectShowDrawerForMyDocuments: boolean,
    selectShowDrawerForAdviceLibrary: boolean,
    selectShowDrawerForPracticeDocuments: boolean,
    selectedClientDocuments: ClientMeetingDocumentItem[],
    selectedMyDocuments: ClientMeetingDocumentItem[],
    selectAdviceLibraryDocuments: string[],
    selectPracticeDocuments: string[],
    selectReportType: ReportType,
    selectedOptionsForNWOTReport:NWOTReportOptions,
    selectedOptionsForGoalFundingReport:GoalFundingOptions

}

export const initialStateOfSelectedPagesForAnnualReport: ReportingState = {
    selectedPagesForReport: initialSelectedPagesForAnnualReport,
    selectShowDrawerForClientDocuments: false,
    selectShowDrawerForMyDocuments: false,
    selectShowDrawerForAdviceLibrary: false,
    selectShowDrawerForPracticeDocuments:false,
    selectedClientDocuments: [],
    selectedMyDocuments: [],
    selectAdviceLibraryDocuments: [],
    selectPracticeDocuments: [],
    selectReportType: "Annual Report",
    selectedOptionsForNWOTReport: {discountRate:FveDiscountRateType.TRIPLE_NET,insuranceIncluded:false},
    selectedOptionsForGoalFundingReport:  {displayView: DataDisplayView.PERCENTAGE_VIEW, displayOptions: FundingDisplayOptions.ONLY_PORTFOLIO, fundingAge:0}
};

export const reportingSlice = createSlice({
    name: "reporting",
    initialState: initialStateOfSelectedPagesForAnnualReport,
    reducers: {
        setSelectedPagesForAnnualReport: (state, action: PayloadAction<ReportConfig>) => {
            state.selectedPagesForReport = action.payload;
        },
        setShowDrawerForAdviceLibrary: (state, action: PayloadAction<boolean>) => {
            state.selectShowDrawerForAdviceLibrary = action.payload;
        },
        setShowDrawerForClientDocuments: (state, action: PayloadAction<boolean>) => {
            state.selectShowDrawerForClientDocuments = action.payload;
        },
        setShowDrawerForMyDocuments: (state, action: PayloadAction<boolean>) => {
            state.selectShowDrawerForMyDocuments = action.payload;
        },
        setSelectedClientDocuments: (state, action: PayloadAction<ClientMeetingDocumentItem[]>) => {
            state.selectedClientDocuments = action.payload;
        },
        setSelectedMyDocuments: (state, action: PayloadAction<ClientMeetingDocumentItem[]>) => {
            state.selectedMyDocuments = action.payload;
        },
        setSelectedAdviceLibraryDocuments: (state, action: PayloadAction<string[]>) => {
            state.selectAdviceLibraryDocuments = action.payload;
        },
        setShowDrawerForPracticeDocuments: (state, action: PayloadAction<boolean>) => {
            state.selectShowDrawerForPracticeDocuments = action.payload;
        },
        setSelectedPracticeDocuments: (state, action: PayloadAction<string[]>) => {
            state.selectPracticeDocuments = action.payload;
        },
        resetReportingState: (_state, _action: PayloadAction<void>) => initialStateOfSelectedPagesForAnnualReport,
        setSelectedReportType: (state, action: PayloadAction<ReportType>) => {
            state.selectReportType = action.payload;
        },
        setOptionsForNWOTReport: (state, action: PayloadAction<NWOTReportOptions>) => {
            state.selectedOptionsForNWOTReport = action.payload;
        },
        setOptionsForGoalFundingReport: (state, action: PayloadAction<GoalFundingOptions>) => {
            state.selectedOptionsForGoalFundingReport = action.payload;
        }
    }
});

export const {
    setSelectedPagesForAnnualReport,
    setShowDrawerForClientDocuments,
    setShowDrawerForMyDocuments,
    setShowDrawerForAdviceLibrary,
    setShowDrawerForPracticeDocuments,
    setSelectedClientDocuments,
    setSelectedMyDocuments,
    setSelectedAdviceLibraryDocuments,
    setSelectedPracticeDocuments,
    resetReportingState,
    setSelectedReportType,
    setOptionsForNWOTReport,
    setOptionsForGoalFundingReport
} = reportingSlice.actions;

export const selectSelectedPagesForReport = (state: RootState) => state.client.reporting.selectedPagesForReport;

export const selectShowDrawerForAdviceLibrary = (state: RootState) => state.client.reporting.selectShowDrawerForAdviceLibrary;
export const selectAdviceLibraryDocuments = (state: RootState) => state.client.reporting.selectAdviceLibraryDocuments;
export const selectAdviceLibraryDocumentsCount = (state: RootState) => state.client.reporting.selectAdviceLibraryDocuments.length;

export const selectShowDrawerForPracticeDocuments = (state: RootState) => state.client.reporting.selectShowDrawerForPracticeDocuments;
export const selectPracticeDocuments = (state: RootState) => state.client.reporting.selectPracticeDocuments;
export const selectPracticeDocumentsCount = (state: RootState) => state.client.reporting.selectPracticeDocuments.length;

export const selectShowDrawerForClientDocuments = (state: RootState) => state.client.reporting.selectShowDrawerForClientDocuments;
export const selectSelectedClientDocuments = (state: RootState) => state.client.reporting.selectedClientDocuments;
export const selectClientDocumentsCount = (state: RootState) => state.client.reporting.selectedClientDocuments.length;

export const selectShowDrawerForMyDocuments = (state: RootState) => state.client.reporting.selectShowDrawerForMyDocuments;
export const selectSelectedMyDocuments = (state: RootState) => state.client.reporting.selectedMyDocuments;
export const selectMyDocumentsCount = (state: RootState) => state.client.reporting.selectedMyDocuments.length;
export const selectSelectedReportType = (state: RootState) => state.client.reporting.selectReportType;
export const selectOptionsForNWOTReport = (state: RootState) => state.client.reporting.selectedOptionsForNWOTReport;
export const selectOptionsForGoalFundingReport = (state: RootState) => state.client.reporting.selectedOptionsForGoalFundingReport;


export default reportingSlice.reducer;