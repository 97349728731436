import {App_Conf} from "./app_conf";
import {SpaConfigResponse} from "./models/SpaConfig";

const {SPA_CONFIG_FN_URL} = App_Conf;

const getSpaConfig = async (): Promise<SpaConfigResponse> => {
    const response = await fetch(SPA_CONFIG_FN_URL.toString(), {
        method: "GET",
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            'Access-Control-Allow-Origin': '*'
        }
    });
    if (response.ok) {
        return response.json();
    }
    throw response;
}

export default {
    getSpaConfig
};