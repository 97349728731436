import classNames from 'classnames';
import React, {ReactNode} from 'react';
import {Button} from "../Button";

type DataEntryFooterProps = {
    className?: string,
    CustomContentComponent?: () => ReactNode,
    hideDelete?: boolean,
    hideDuplicate?: boolean,
    hideAddNew?: boolean,
    onDelete?: () => void,
    onDuplicate?: () => void,
    onAddNew?: () => void,
    sticky?: boolean,
    disableDelete?: boolean
};

export default function DataEntryFooter(
    {
        className,
        CustomContentComponent,
        hideDelete = false,
        hideDuplicate = false,
        hideAddNew = false,
        onDelete,
        onDuplicate,
        onAddNew,
        sticky = false,
        disableDelete = false
    }: DataEntryFooterProps
) {
    return (
        <footer className={classNames('data-entry-footer', {'data-entry-footer--sticky': sticky}, className)}>
            {CustomContentComponent ? (
                CustomContentComponent()
            ) : (
                <>
                    <div>
                        {!hideDelete && (
                            <Button
                                className="data-entry-footer__button-delete"
                                destructive
                                icon="left"
                                iconName="delete"
                                disabled={disableDelete}
                                kind="borderless"
                                onClick={onDelete}
                            >
                                Delete
                            </Button>
                        )}
                    </div>
                    <div>
                        {!hideDuplicate && (
                            <Button
                                className="data-entry-footer__button-duplicate"
                                icon="left"
                                iconName="content_copy"
                                kind="borderless"
                                onClick={onDuplicate}
                            >
                                Duplicate
                            </Button>
                        )}
                        {!hideAddNew && (
                            <Button
                                className="data-entry-footer__button-add"
                                icon="left"
                                iconName="add_circle_outline"
                                kind="borderless"
                                onClick={onAddNew}
                            >
                                Add New
                            </Button>
                        )}
                    </div>
                </>
            )}
        </footer>
    )
}
