import {Icon} from "../../../components";
import classNames from "classnames";
import {ReactElement} from "react";

type AssetAccordionHeaderProps = {
    expanded: boolean,
    title: string | ReactElement,
    formattedTotalInvestableValue?: string,
    formattedPrimaryMemberTotalPresentValue?: string,
    formattedSecondaryMemberTotalPresentValue?: string,
    formattedJointTotalPresentValue?: string,
    formattedTotalPresentValue?: string,
    gridClassName: string,
    caption?: string,
    showChevron?: boolean,
    hideJoint?: boolean,
    customPresentValueLabel?: string
}


export function AssetAccordionHeader({
                                         expanded,
                                         title,
                                         formattedTotalInvestableValue,
                                         formattedPrimaryMemberTotalPresentValue,
                                         formattedSecondaryMemberTotalPresentValue,
                                         formattedJointTotalPresentValue,
                                         formattedTotalPresentValue,
                                         gridClassName,
                                         caption,
                                         showChevron = true,
                                         hideJoint = false,
                                         customPresentValueLabel
                                     }: AssetAccordionHeaderProps) {

    return <div className={classNames("accordion-header-content", gridClassName)}
                data-testid={`accordion-header-${title}`}>
         <span className={`paddingleft-md display-flex align-items-center ${getFirstColumnHeaderSpan()}`}>
           {showChevron && <Icon name={expanded ? 'chevron_down' : 'chevron_right'} size="large"/>}
             <span className="paddingleft-md">
               <div className="font-lg margin-none">
                   {title}
                   {
                       caption &&
                       <span className="asset-accordion-header-caption"
                             data-testid={`accordion-header-caption-${title}`}> ({caption})</span>
                   }
               </div>
           </span>
         </span>
        {formattedTotalInvestableValue ?
            <span className="font-lg textalign-right">{formattedTotalInvestableValue}</span> : <></>}
        {formattedPrimaryMemberTotalPresentValue ?
            <span className="font-lg textalign-right">{formattedPrimaryMemberTotalPresentValue}</span> : <></>}
        {formattedSecondaryMemberTotalPresentValue ?
            <span className="font-lg textalign-right">{formattedSecondaryMemberTotalPresentValue}</span> : <></>}
        {(!hideJoint && formattedJointTotalPresentValue) ?
            <span className="font-lg textalign-right">{formattedJointTotalPresentValue}</span> : <></>}
        <span className={classNames({"grid-span-2": (customPresentValueLabel || hideJoint)}, "font-lg textalign-right")}>
            {customPresentValueLabel &&
                <span className="condensed-subtitle marginright-md verticalalign-middle">{customPresentValueLabel}</span>}
            {formattedTotalPresentValue}
        </span>
    </div>

    function getFirstColumnHeaderSpan() {
        const columns = [
            formattedTotalInvestableValue,
            formattedPrimaryMemberTotalPresentValue,
            formattedSecondaryMemberTotalPresentValue,
            formattedJointTotalPresentValue,
            customPresentValueLabel,
            formattedTotalPresentValue
        ]

        let columnNumber = -1
        columns.forEach(column => column ?? columnNumber++)
        return "grid-span-" + columnNumber;
    }
}
