import DataEntryHeader from "../../../components/DataEntry/DataEntryHeader";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithProfileIdAndAssetId} from "../../../routes/types";
import AssetsViewContext from "../../common/AssetsViewContext";
import {assetsApiClient} from "../../AssetsApiClient";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {PartiallyOwnedInvestmentSearch} from "./PartiallyOwnedInvestmentSearch";
import {PartiallyOwnedInvestmentMessage, PartiallyOwnedInvestmentMessageState} from "./PartiallyOwnedInvestmentMessage";
import {PartiallyOwnedInvestmentSearchResults} from "./PartiallyOwnedInvestmentSearchResults";
import {PartiallyOwnedInvestmentError, PartiallyOwnedInvestmentErrorState} from "./PartiallyOwnedInvestmentError";
import {DisclaimerMessage} from "../../common/DisclaimerMessage";
import {PartiallyOwnedLegalAgreement} from "../../models/PartiallyOwnedInvestmentAccount";

export function SearchPartiallyOwnedInvestment() {
    const history = useHistory();
    const {profileId} = useParams<RouteWithProfileIdAndAssetId>();
    const viewType = useContext(AssetsViewContext);
    const [searchResults, setSearchResults] = useState<PartiallyOwnedLegalAgreement>();
    const [isSearching, setIsSearching] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [errorBannerWasClosed, setErrorBannerWasClosed] = useState(false);
    const [errorState, setErrorState] = useState<PartiallyOwnedInvestmentErrorState>('');
    const [messageState, setMessageState] = useState<PartiallyOwnedInvestmentMessageState>('');
    const componentUnmounted = useRef(false);

    useEffect(() => {
        return () => {
            componentUnmounted.current = true;
        }
    }, []);

    const handleClickOnCancel = () => {
        history.push(`/Profile/${profileId}/ClientProfile/${viewType}`);
    };

    const navigateToDetails = (legalAgreement: PartiallyOwnedLegalAgreement) => {
        history.push(`/Profile/${profileId}/ClientProfile/${viewType}/AddPartiallyOwnedInvestment`, {
            legalAgreement
        });
    };


    const resetMessageState = () => {
        setMessageState('');
    };

    const resetErrorState = () => {
        setErrorState('');
        setErrorBannerWasClosed(false);
    };

    const resetSearchResults = () => {
        setSearchResults(undefined);
    };

    const handleSearch = (search: string) => {
        resetErrorState();
        resetMessageState();
        setIsSearching(true);

        assetsApiClient.searchPartiallyOwnedInvestments(profileId, search)
            .then(legalAgreement => {
                if(componentUnmounted.current) return;
                setSearchResults(legalAgreement);
            })
            .catch(e => {
                if(componentUnmounted.current) return;
                resetSearchResults();
                switch (e.status) {
                    case 404:
                        setMessageState('NoSearchResults');
                        break;
                }
            })
            .finally(() => {
                if(componentUnmounted.current) return;
                setIsSearching(false);
            });
    };

    const handleSearchCleared = () => {
        resetMessageState();
        resetErrorState();
        resetSearchResults();
    };

    const handleErrorBannerClosed = () => {
        setErrorBannerWasClosed(true);
    };

    const handleAddAccount = (legalAgreement: PartiallyOwnedLegalAgreement) => {
        resetErrorState();
        resetMessageState();
        setIsAdding(true);

        assetsApiClient.validatePartiallyOwnedInvestment(profileId, legalAgreement.legalAgreementNumber)
            .then(() => {
                if(componentUnmounted.current) return;
                navigateToDetails(legalAgreement);
            })
            .catch(e => {
                if (componentUnmounted.current) return;
                switch (e.status) {
                    case 400:
                        setErrorState('AccountAlreadyAdded');
                        break;
                    case 409:
                        setErrorState('AccountAlreadyAddedInProfile')
                        break;
                }
            })
            .finally(() => {
                if(componentUnmounted.current) return;
                setIsAdding(false);
            });
    };

    const loadingIndicatorMessage = () => {
        return isAdding
            ? 'Validating account and loading legal agreements...'
            : 'Loading'
    };

    const isSearchingOrAdding = () => {
        return isSearching || isAdding;
    };

    return (
        <div className="partially-owned-investment">
            <DataEntryHeader
                title="Add Partially Owned Investment Account"
                secondaryButtonText="Cancel"
                onSecondaryButtonClick={handleClickOnCancel}
                hidePrimaryButton
            />

            <div className="partially-owned-investment-container">
                {
                    errorState.length > 0 && !errorBannerWasClosed &&
                    <div className="marginbottom-lg">
                        <PartiallyOwnedInvestmentError state={errorState} onClosed={handleErrorBannerClosed} />
                    </div>
                }
                <div className="search-bar-container marginbottom-lg">
                    <PartiallyOwnedInvestmentSearch
                        onSearch={handleSearch}
                        onSearchCleared={handleSearchCleared}
                    />
                </div>
                {
                    isSearchingOrAdding() &&
                    <LoadingIndicator
                        displayMessage={loadingIndicatorMessage()}
                    />
                }
                {
                    !isSearchingOrAdding() && searchResults &&
                    <PartiallyOwnedInvestmentSearchResults
                        legalAgreement={searchResults}
                        onAddAccount={handleAddAccount}
                    />
                }
                {
                    !isSearchingOrAdding() && !searchResults &&
                    <PartiallyOwnedInvestmentMessage state={messageState} />
                }
            </div>
            <DisclaimerMessage origin={"POIA"}/>
        </div>
    );
}