import {PortfolioReserveOptions} from "../models/PortfolioReserveOptions";
import {PortfolioReserveResponse} from "../../models/PortfolioReserve";
import {Button, DropdownItem, Icon, PageActionMenu, RadioGroup} from "../../../components";
import ExcessAssets from "../ExcessAssets";
import React, {ChangeEvent, useState} from "react";
import {
    selectPortfolioReserveButtonStates,
     setPortfolioReserveButtonStates,
    setPortfolioReserveOptions, selectPortfolioReserveOptions
} from "../PortfolioReserveSlice";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {MenuDivider} from "xps-react";
type ExcessAssetsRowProps = {
    excessAssetsRowActive: number | undefined | boolean,
    portfolioReserveOptions: PortfolioReserveOptions,
    onClickExcessAssetsInfoTip: () => void,
    editPortfolioReserveData: PortfolioReserveResponse,
    minRiskDescriptionText: string
}

export function ExcessAssetsRow({
                                    excessAssetsRowActive,
                                    portfolioReserveOptions,
                                    onClickExcessAssetsInfoTip,
                                    editPortfolioReserveData,
                                    minRiskDescriptionText
                                }: ExcessAssetsRowProps) {
    const dispatch = useAppDispatch();
    const reserveActionMenuState = useAppSelector(selectPortfolioReserveOptions)
    let initialMonteCarloOption = "5% to 95%"
    switch (reserveActionMenuState.monteCarloSelectedPercentile) {
        case 10:
            initialMonteCarloOption = "10% to 90%"
            break;
        case 25:
            initialMonteCarloOption = "25% to 75%"
            break;
    }
    const [selectedMonteCarloOption, setSelectedMonteCarloOption] = useState(initialMonteCarloOption)
    const buttonState = useAppSelector(selectPortfolioReserveButtonStates)
    const handleReserveOptionDropdown = (_e: ChangeEvent<HTMLInputElement>) => {
        let selectedPortfolioReserveOptions = {...portfolioReserveOptions}
        selectedPortfolioReserveOptions.showOutcomeRange = !selectedPortfolioReserveOptions.showOutcomeRange;
        dispatch(setPortfolioReserveOptions({
            ...reserveActionMenuState,
            showOutcomeRange: !reserveActionMenuState.showOutcomeRange
        }))
    }

    const handMonteCarloPercentileOptions = (_e: ChangeEvent<HTMLInputElement>) => {
        const {value} = _e.target;
        setSelectedMonteCarloOption(value)
        let selectedPortfolioReserveOptions = {...reserveActionMenuState}
        switch (value) {
            case "5% to 95%":
                selectedPortfolioReserveOptions.monteCarloSelectedPercentile = 5
                break;
            case "10% to 90%":
                selectedPortfolioReserveOptions.monteCarloSelectedPercentile = 10
                break;
            case "25% to 75%":
                selectedPortfolioReserveOptions.monteCarloSelectedPercentile = 25
                break;
            default:
                selectedPortfolioReserveOptions.monteCarloSelectedPercentile = 5
                break;
        }
        dispatch(setPortfolioReserveOptions(selectedPortfolioReserveOptions));
    }
    const handleHideButton = ()=>{ dispatch(setPortfolioReserveButtonStates({...buttonState,excessAssets: false})) };

    return <>
        <div className="edit-portfolio-reserve-page__table__column">
            <div style={{display: 'flex', marginRight: '2px'}}>
                <div
                    style={{width:'95%'}}
                    data-testid="monte-carlo-quick-slide-container"
                    className={`edit-portfolio-reserve-page__table__column__content ${excessAssetsRowActive ? "" : "row-inactive"}`}>
                    How much do my assets exceed my goals?
                    <div className="edit-portfolio-reserve-page__table__column__subtext">
                        {portfolioReserveOptions.showOutcomeRange &&
                            <>
                                <span>Range of Outcomes</span>
                                <Button
                                    icon="only"
                                    iconName="info_outline"
                                    kind="borderless"
                                    onClick={onClickExcessAssetsInfoTip}
                                    size="small"
                                />
                            </>
                        }
                    </div>
                </div>
                <PageActionMenu data-testid="excess-assets-page-action" className={"pr-page-action-menu"} panelWidth={200} aria-label="show-monte-carlo-options">
                    <DropdownItem>
                        Show Range of Outcomes
                        <input
                            id="showOutcomeRange"
                            name="show-outcome-range"
                            type="checkbox"
                            aria-label="show-outcome-range"
                            key={"show-outcome-range"}
                            style={{marginLeft: '10px'}}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleReserveOptionDropdown(e)
                            }}
                            checked={reserveActionMenuState.showOutcomeRange}
                        />
                    </DropdownItem>
                    <DropdownItem className="reserve-page-action-menu-radio-options">
                        {/*Warning - make sure there is an empty ' ' string (with a blank space) for radio group name. If it is just '', it will not allow user to select first option.*/}
                        <RadioGroup
                            name={' '}
                            data-testid={"monte-carlo-options"}
                            layout="vertical"
                            values={["5% to 95%", "10% to 90%", "25% to 75%"]}
                            onChange={handMonteCarloPercentileOptions}
                            selected={selectedMonteCarloOption}
                            disabled={false}
                        />
                    </DropdownItem>
                    {excessAssetsRowActive &&
                        <MenuDivider
                            className="menu-divider"
                            role="separator"
                            itemText=""
                            value="do_not_use"
                            style={{
                                padding: "0",
                                margin: "8px -16px",
                                borderBottom: "1px solid lightgrey"
                            }}
                        />}
                    {excessAssetsRowActive &&
                       <DropdownItem className="reserve-page-action-menu-options" value="" itemText="" onClick={handleHideButton}>
                        Hide this Section
                        <Icon  ariaHidden  name="visibility_off_outline" style={{marginLeft: '52px'}}/>
                    </DropdownItem>}

                </PageActionMenu>
            </div>
        </div>
        <div
            className="edit-portfolio-reserve-page__table__column edit-portfolio-reserve-page__table__second-column edit-portfolio-reserve-page__centered">
            <ExcessAssets
                presentValueType={"portfolioReserve"}
                editPortfolioReserveData={editPortfolioReserveData}
                excessAssetsRowActive={excessAssetsRowActive}
            />
        </div>
        <div
            className="edit-portfolio-reserve-page__table__column edit-portfolio-reserve-page__table__third-column edit-portfolio-reserve-page__centered">
            <ExcessAssets
                presentValueType={"minimumRisk"}
                minRiskDescriptionText={minRiskDescriptionText}
                editPortfolioReserveData={editPortfolioReserveData}
                excessAssetsRowActive={excessAssetsRowActive}
            />
        </div>
    </>;
}