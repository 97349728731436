import {useAppInsights} from "../AppInsights";
import {useEffect, useState} from "react";

const usePageViewTimer = (name: string, isLoading: boolean) => {
    const [trackingPageName, setTrackingPageName] = useState<string | null>(null);
    const appInsights = useAppInsights();

    useEffect(() => {
        if (isLoading) {
            appInsights.startTrackPage(name);
            setTrackingPageName(name);
        }
    }, [name, isLoading]);

    const stopTrackingPage = (pageName: string) => {
        appInsights.stopTrackPage(pageName);
        setTrackingPageName(null);
    };

    useEffect(() => {
        if (trackingPageName !== null && trackingPageName !== name) {
            stopTrackingPage(trackingPageName);
        } else if (!isLoading && trackingPageName === name) {
            stopTrackingPage(name);
        }
    }, [name, trackingPageName, isLoading]);
};

export default usePageViewTimer;