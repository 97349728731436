import {AssetRelianceStack} from "../../models/AssetRelianceResponse";
import {
    COLOR_ASSETS_ACCOUNTS,
    COLOR_EXCESS_ASSETS,
    COLOR_NT_GREY_050,
    COLOR_PROJECTED_ASSETS
} from "../../../constants/colors";

export default class AssetBarChartColumn {
    protected static barChartPixelHeight: number = 248;
    protected static goalLinePixelHeight: number = 4;

    protected readonly stack: AssetRelianceStack;
    protected readonly isProfileAssetSufficient: boolean;
    protected readonly overlayMaxValue: number;

    constructor(
        stack: AssetRelianceStack,
        isProfileAssetSufficient: boolean,
        overlayMaxValue: number = 1
    ) {
        this.stack = stack;
        this.overlayMaxValue = overlayMaxValue;
        this.isProfileAssetSufficient = isProfileAssetSufficient
    }

    getColumnMaxValue() {
        const goals = this.getGoalsForStack();
        const assetsAndExcluded = this.stack.netAssets + Math.max(0, this.stack.totalExcludedAssets)

        return goals > assetsAndExcluded ? goals : assetsAndExcluded;
    }

    static getColumnMaxValueFromStack(stack: AssetRelianceStack) {
        const goals = this.getGoalsFromStack(stack);
        const assetsAndExcluded = stack.netAssets + Math.max(0, stack.totalExcludedAssets);

        return goals > assetsAndExcluded ? goals : assetsAndExcluded;
    }

    getGoalsPositionVerticalOffset() {
        return (100 - this.getGoalsForStack() / this.getColumnMaxValue() * 100).toFixed(2);
    }

    getPillColor() {
        return this.stack.excessAssets >= 0 ? COLOR_EXCESS_ASSETS : COLOR_PROJECTED_ASSETS;
    }

    getVerticalLineMargins() {
        let topMargin;
        let bottomMargin;

        if (this.stack.excessAssets >= 0) {
            topMargin = 100 - ((this.stack.netAssets / this.getColumnMaxValue()) * 100);
            bottomMargin = (this.getGoalsForStack() / this.getColumnMaxValue()) * 100;
        } else {
            const topMarginWithoutGoalDisplacement = 100 - (this.getGoalsForStack() / this.getColumnMaxValue() * 100);

            topMargin = (topMarginWithoutGoalDisplacement + this.getGoalLineDisplacement())
            bottomMargin = (this.stack.netAssets / this.getColumnMaxValue() * 100);
        }

        return {
            topMargin: Math.max(topMargin, 0).toFixed(2),
            bottomMargin: Math.max(bottomMargin, 0).toFixed(2)
        }
    }

    getChartItems() {
        let chartItems = [];

        if (this.isProfileAssetSufficient) {
            chartItems.push({
                label: "Excluded Assets",
                total: this.stack.totalExcludedAssets,
                color: COLOR_NT_GREY_050,
                highlight: 0
            });

            if (this.stack.excessAssets < 0) {
                chartItems.push({
                    label: "Net Assets",
                    total: this.stack.netAssets,
                    color: COLOR_ASSETS_ACCOUNTS,
                    highlight: this.stack.netAssets
                });
            } else {
                chartItems.push({
                    label: "Excess Assets",
                    total: this.stack.excessAssets,
                    color: COLOR_ASSETS_ACCOUNTS,
                    highlight: 0
                }, {
                    label: "Goals",
                    total: this.stack.netAssets - this.stack.excessAssets,
                    color: COLOR_ASSETS_ACCOUNTS,
                    highlight: this.stack.netAssets - this.stack.excessAssets
                });
            }
        } else {
            chartItems.push({
                label: "Asset Shortfall",
                total: Math.abs(this.stack.excessAssets),
                color: COLOR_NT_GREY_050,
                highlight: 0
            });

            if (this.stack.netAssets >= 0) {
                chartItems.push(
                    {
                        label: "Net Assets",
                        total: this.stack.netAssets,
                        color: COLOR_ASSETS_ACCOUNTS,
                        highlight: this.stack.netAssets
                    }
                )
            }
        }
        return chartItems;
    }

    protected getGoalsForStack() {
        return this.stack.netAssets - this.stack.excessAssets;
    }

    protected static getGoalsFromStack(stack: AssetRelianceStack) {
        return stack.netAssets - stack.excessAssets;
    }

    public getGoalLineDisplacement() {
        return (AssetBarChartColumn.goalLinePixelHeight / AssetBarChartColumn.barChartPixelHeight * (this.overlayMaxValue / this.getColumnMaxValue()) * 100)
    }
}
