import {EquityCompensationFormData} from "src/Assets/models/EquityCompensation";
import {Accordion} from "src/components/Accordion";
import {MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import {EquityCompensationTableRowReport} from "./EquityCompensationTableRowReport";

interface EquityCompensationTableProps {
    inEstateEquityCompensations_: EquityCompensationFormData[];
    editEquity_: (equityCompId: string | undefined) => any;
    deleteEquity_: (equityComp: EquityCompensationFormData) => void;
    memberGroup_: MemberGroup | undefined;
}

function EquityCompensationTableReport({
                                     inEstateEquityCompensations_,
                                     editEquity_,
                                     deleteEquity_,
                                     memberGroup_,
                                 }: EquityCompensationTableProps) {
    return (
        <div
            role="table"
            className="assets-grid-with-actionmenu assets-grid-table equity-compensation-table"
            aria-label="equity-compensation-table"
        >
            <div
                role="row"
                className="assets-grid-with-actionmenu assets-grid-table-header"
                tabIndex={-1}
            >
        <span role="columnheader" className="textalign-left">
          Name & Owner
        </span>
                <span role="columnheader" className="textalign-right">
          Pre-Tax Unvested
        </span>
                <span role="columnheader" className="textalign-right">
          Pre-Tax Vested
        </span>
                <span role="columnheader" className="textalign-right">
          After-Tax Unvested
        </span>
                <span role="columnheader" className="textalign-right">
          After-Tax Vested
        </span>
            </div>
            <Accordion allowMultipleExpanded={true} className="grid-span-all equity-compensation-summary-accordion" accordionId={"EquityCompensationTableAccordion"}>
                {inEstateEquityCompensations_.map(
                    (inEstateEquity_: EquityCompensationFormData, index_) => {
                        const uuid_ = `${inEstateEquity_.entityOrAccountName}-${index_}`;
                        return (
                            <EquityCompensationTableRowReport
                                equityComp_={inEstateEquity_}
                                key={inEstateEquity_.id}
                                gridClassName_={"assets-grid-with-actionmenu"}
                                actionsDisabled_={false}
                                onClickEdit_={editEquity_}
                                onClickDelete_={deleteEquity_}
                                uuid_={uuid_}
                                memberGroup_={memberGroup_}
                            />
                        );
                    }
                )}
            </Accordion>
        </div>
    );
}

export default EquityCompensationTableReport;
