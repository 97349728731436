import { useRef, useEffect } from "react";

type ViewerProps = {
    htmlString: string
}

export const Viewer: React.FC<ViewerProps> = ({
    htmlString
}) => {
    const htmlViewerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (htmlViewerRef.current) {
            htmlViewerRef.current.innerHTML = htmlString;
        }
    }, [htmlString])
  
    return <div className="html-viewer" ref={htmlViewerRef}></div>;
}

export default Viewer;