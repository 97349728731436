import React from "react";
import Popover from "@nt/dds-react/core/Popover";
import { formatCurrency } from "src/utils/format";
import { GoalsSummaryForOneYear } from "../ReviseAssetAllocation";
import { Icon } from "src/components";

type GoalsDrivingCashTargetForNextYearProps = {
    goalsSummaryForOneYear: GoalsSummaryForOneYear;
};

const GoalsDrivingCashTargetForNextYear = ({
    goalsSummaryForOneYear,
}: GoalsDrivingCashTargetForNextYearProps) => {
    return (
        <Popover
            id="one-year-of-cash-popover"
            delay={0}
            content={
                <div className="one-year-of-cash__popover-content">
                    <span className="one-year-of-cash__popover-title">
                        Goals Driving Cash Target
                    </span>
                    <div className="one-year-of-cash__goal-item">
                        <span>Lifestyle</span>
                        <span aria-label="Lifestyle goals funded by portfolio for next year">
                            {formatCurrency(goalsSummaryForOneYear.lifestyle)}
                        </span>
                    </div>
                    <div className="one-year-of-cash__goal-item">
                        <span>Discretionary</span>
                        <span aria-label="Discretionary goals funded by portfolio for next year">
                            {formatCurrency(
                                goalsSummaryForOneYear.discretionary
                            )}
                        </span>
                    </div>
                    <div className="one-year-of-cash__goal-item">
                        <span>Philanthropic</span>
                        <span aria-label="Philanthropic goals funded by portfolio for next year">
                            {formatCurrency(
                                goalsSummaryForOneYear.philanthropic
                            )}
                        </span>
                    </div>
                    <div className="one-year-of-cash__goal-item">
                        <span>Family</span>
                        <span aria-label="Family goals funded by portfolio for next year">
                            {formatCurrency(goalsSummaryForOneYear.family)}
                        </span>
                    </div>
                    <hr />
                    <div className="one-year-of-cash__goal-item one-year-of-cash__goal-item-bold">
                        <span>Total</span>
                        <span aria-label="Total goals funded by portfolio for next year">
                            {formatCurrency(
                                goalsSummaryForOneYear.totalGoalsPresentValueForNextYear
                            )}
                        </span>
                    </div>
                </div>
            }
            direction="top"
            aria-label="One year of cash popover"
        >
            <Icon
                className="popover-trigger popoverContainer"
                name="info_outline"
                ariaHidden={false}
                ariaLabel="One year of cash info"
            />
        </Popover>
    );
};

export default GoalsDrivingCashTargetForNextYear;
