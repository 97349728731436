export type Presenter = {
    presenter?: string;
    presenterName?: string;
}

export type HistoricalMeeting = Presenter & {
    id: string;
    title: string;
    type: MeetingType;
    createdBy?: string;
    createdByName: string;
    startDate?: string;
    endDate?: string;
    status?: MeetingStatus;
}

export type Meeting = HistoricalMeeting & {
    relayContainerId?: string;
    fluidRelayDocumentId?: string;
    profileId: string;
    profileIdToPresent: string;
    onlineMeetingId: string;
    onlineMeetingCode: string;
    onlineMeetingJoinUrl: string;
    remoteEnabled: boolean;
}

export enum MeetingStatus {
    SCHEDULED = 'SCHEDULED',
    CREATED = 'CREATED',
    PAUSED = 'PAUSED',
    STARTED = 'STARTED',
    ENDED = 'ENDED',
    STOPPED = 'STOPPED',
    DELETED = 'DELETED'
}

export enum MeetingType {
    TRAINING_MEETING = 'TRAINING_MEETING',
    CLIENT_MEETING = 'CLIENT_MEETING',
}

export enum MeetingParticipation {
    IN_MEETING = 'IN_MEETING',
    OUT_OF_MEETING = 'OUT_OF_MEETING'
}

export type MeetingParticipant = {
    meetingId: string;
    userId: string;
    userName: string;
    communicationsId: string | null;
};

export type HumanReadableMeetingType = 'Training meeting' | 'Client meeting' | "";

export const mapMeetingTypeEnumToHumanReadable = (meetingType: MeetingType | undefined): HumanReadableMeetingType => {
    if (!meetingType) return "";
    return meetingType.slice(0, 1).toUpperCase().concat(
        meetingType.slice(1).toLowerCase().replace(/_/g, ' ')
    ) as HumanReadableMeetingType;
};

export const mapHumanReadableMeetingTypeToEnum = (humanReadableMeetingType: HumanReadableMeetingType): MeetingType => {
    return humanReadableMeetingType.toUpperCase().replace(/\s+/, '_') as MeetingType;
};

export type MultiPartMeetingContent = {
    part: number;
    totalParts: number;
    content: string;
    messageId: string;
};

export type MeetingContentDOM = string;

export type MeetingContentCanvas = Record<string, string>;

export type MeetingContentScrollState = {
    horizontalScrollPercentage: number;
    verticalScrollPercentage: number;
};

export type MeetingContentScrollPositions = Record<string, MeetingContentScrollState>;

export type MultiPartMeetingContentDOM = MultiPartMeetingContent;

export type BarChartSideBarPointerInfo = {
    hoveredElementId: string;
    pointerYOnBarchart: number;
    stackBarChartHeight: number;
    stackBarChartWidth: number;
}

export type PresenterPointerCoordinatesInfo = {
    x: number,
    y: number,
    scrollTop: number;
    scrollLeft: number;
    scrollContainerId: string | null;
    isOnBarChartSidebar: boolean;
    barChartSideBarPointerInfo? : BarChartSideBarPointerInfo;
}
