import React from 'react';
import classNames from 'classnames';
import NumberInput, {defaultFormatOptions, NumberInputProps} from "./NumberInput";

type CurrencyInputWithLabelProps = NumberInputProps & {
    label: string
};

export default function CurrencyInputWithLabel({
                                                   className,
                                                   formatOptions = defaultFormatOptions,
                                                   label,
                                                   id,
                                                   size = "medium",
                                                   ...rest
                                               }: CurrencyInputWithLabelProps) {
    const inputId = id ?? `${label}-id`;
    return <div className="layout-data-entry-form__field">
        <label htmlFor={inputId}>
            <b>{label}</b>
        </label>
        <NumberInput
            {...rest}
            id={inputId}
            className={classNames('currency-field', className)}
            inlineLabel="$"
            inlineLabelAlign="left"
            formatOptions={{...formatOptions, style: 'decimal'}}
            size={size}
        />
    </div>;
}
