import {formatCurrency} from "../../../utils/format";
import {sumByField} from "./holdingUtils";
import {Icon} from "../../../components";
import React from "react";
import {AssetClass} from "../../models/AssetClassifications";

type AssetClassHeaderProps = {
    expanded: boolean,
    holdingRows: {
        taxCost: number | null,
        marketValue: number | null,
        investableValue: number | null,
    }[],
    assetClass: AssetClass
}

function AssetClassHeader({expanded, holdingRows, assetClass}: AssetClassHeaderProps) {
    const totalAssetClassTaxCost = formatCurrency(sumByField(holdingRows, holding => holding.taxCost));
    const totalAssetClassMarketValue = formatCurrency(sumByField(holdingRows, holding => holding.marketValue));
    const totalAssetClassInvestableValue = formatCurrency(sumByField(holdingRows, holding => holding.investableValue));
    return (
        <div className="accordion-header-content holding-grid" role="row"
             aria-label={`${assetClass.assetClassName} - Tax Cost ${totalAssetClassTaxCost} - Market Value ${totalAssetClassMarketValue} - Investable Value ${totalAssetClassInvestableValue}`}
             data-testid="asset-classification-header"
        >
            <span role="cell" aria-label={`${assetClass.assetClassName}`}
                  className="paddingleft-md display-flex align-items-center holding-grid-column-custom-span">
              <Icon name={expanded ? 'chevron_down' : 'chevron_right'} size="large"/>
              <span className="paddingleft-md">
                  <div
                      className="h4 fontweight-normal margin-none">{assetClass.assetClassName}</div>
              </span>
            </span>
            <span role="cell" aria-label={`Tax Cost - ${totalAssetClassTaxCost}`}
                  className="asset-class-total textalign-right">{totalAssetClassTaxCost}</span>
            <span role="cell" aria-label={`Market Value - ${totalAssetClassMarketValue}`}
                  className="asset-class-total textalign-right">{totalAssetClassMarketValue}</span>
            <span role="cell" aria-label={`Investable Value - ${totalAssetClassInvestableValue}`}
                  className="asset-class-total textalign-right">{totalAssetClassInvestableValue}</span>
            <span role="cell" className="empty-grid-column"/>
        </div>
    )
}

export default AssetClassHeader;
