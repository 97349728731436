import React from "react";
import {useParams} from "react-router-dom";
import {RouteWithIdAndStrategyId} from "../routes/types";
import StrategyWithTimeFrame from "./components/StrategyWithTimeFrame";
import {EstimatedImpact, GiftToGrantorTrustStrategy, StrategyTimeFrame} from "./WealthPOTypes";
import {CreateOrEditStrategyParameters} from "./WealthPOUtils";
import {wealthPOApiClient} from "./WealthPOApiClient";

interface AddEditGiftToGrantorProps {
    refreshStrategiesSummary: () => void;
}

const AddEditGiftToGrantorTrust = ({refreshStrategiesSummary}: AddEditGiftToGrantorProps) => {
    const {id, strategyId} = useParams<RouteWithIdAndStrategyId>();

    const getEstimatedImpact = (requestBody: StrategyTimeFrame, signal?: AbortSignal): Promise<EstimatedImpact> => {
        return wealthPOApiClient.getEstimatedImpactForGiftToGrantorTrust(id, requestBody, signal);
    }

    const handleSaveClickGiftToGrantorTrust = (requestParameters: CreateOrEditStrategyParameters) => {
        const {description, contributionAmount, yearsUntilFlow, yearsOfGrantorStatus} = requestParameters;

        let requestBody = {
            description,
            contributionAmount,
            yearsUntilFlow,
            yearsOfGrantorStatus
        } as GiftToGrantorTrustStrategy

        if (strategyId) {
            requestBody = {
                ...requestBody,
                strategyId: strategyId,
            };
            return wealthPOApiClient.editGiftToGrantorTrustStrategy(id, requestBody);
        } else {
            return wealthPOApiClient.createGiftToGrantorTrustStrategy(id, requestBody);
        }
    }

    return (
        <StrategyWithTimeFrame profileId={id}
                               strategyId={strategyId}
                               header={`${strategyId === undefined ? "Add" : "Edit"} Gift to Grantor Trust`}
                               getEstimatedImpact={getEstimatedImpact}
                               handleSaveClick={handleSaveClickGiftToGrantorTrust}
                               isAnnualGift={false}
                               refreshStrategiesSummary={refreshStrategiesSummary}
        />)
}

export default AddEditGiftToGrantorTrust