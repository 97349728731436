import {
    AssetRelianceAssetType,
    AssetRelianceGoalSummaryDTO,
    AssetRelianceResponse,
    AssetRelianceStack
} from "../../models/AssetRelianceResponse";
import {AssetTableDisplay} from "../TableDisplay/AssetTableDisplay";
import {ColumnCounter, FixedColumnCounter} from "../../../components";
import {GoalTableDisplay} from "../TableDisplay/GoalTableDisplay";
import {ExcessAssetsTableDisplay} from "../TableDisplay/ExcessAssetsTableDisplay";
import _ from "lodash";
import {FveDiscountRateType} from "../AssetRelianceButtonState";

export class EditAssetRelianceResponse {
    private static isLiability(assetType: AssetRelianceAssetType | null): boolean {
        return assetType === AssetRelianceAssetType.PERSONAL_LIABILITY;
    }

    private static filterOutLiabilities(stacks: AssetRelianceStack[]): AssetRelianceStack[] {
        return stacks.filter(stack => !EditAssetRelianceResponse.isLiability(stack.stackAssetType));
    }

    constructor(private readonly assetRelianceResponse: AssetRelianceResponse) {
    }

    get profileId(): string {
        return this.assetRelianceResponse.profileId;
    }

    get allAssetsStack(): AssetRelianceStack {
        return this.assetRelianceResponse.allAssetsStack;
    }

    get assetStacks(): AssetRelianceStack[] {
        return this.assetRelianceResponse.assetStacks;
    }

    get columnAssetStacks(): AssetRelianceStack[] {
        return EditAssetRelianceResponse.filterOutLiabilities(this.assetStacks);
    }

    get assetTableDisplay(): AssetTableDisplay {
        return new AssetTableDisplay(
            this.columnCounter,
            this.rowStacks,
            this.columnStacks
        );
    }

    get columnCounter(): ColumnCounter {
        return new FixedColumnCounter(this.columnStacks.length)
    }

    get columnStacks(): (AssetRelianceStack)[] {
        return _.compact([
            this.allAssetsStack,
            ...this.columnAssetStacks,
            this.investablePortfolioAssetsStack
        ]);
    }

    get goalTableDisplay(): GoalTableDisplay {
        return new GoalTableDisplay(this.columnCounter, this.goals, this.columnStacks);
    }

    get excessAssetsTableDisplay(): ExcessAssetsTableDisplay {
        return new ExcessAssetsTableDisplay({
            columnCounter: this.columnCounter,
            assetTableDisplay: this.assetTableDisplay,
            goalTableDisplay: this.goalTableDisplay,
            allAssetsStack: this.allAssetsStack,
            includeLifeInsuranceAtDeath: false,
            fveDiscountRateType: FveDiscountRateType.TRIPLE_NET,
            showExpectedExcessAssets: false,
            excludedExcessAssetStacks: this.assetStacks,
        });
    }

    get investablePortfolioAssetsStack(): AssetRelianceStack | null {
        return this.assetRelianceResponse.investablePortfolioAssetsStack;
    }

    get rowStacks(): AssetRelianceStack[] {
        return this.assetStacks;
    }

    private get goals(): AssetRelianceGoalSummaryDTO {
        return this.assetRelianceResponse.goals;
    }
}