export type HoldingSummary = {
    totalMarketValue: number | null,
    totalInvestableValue: number | null,
    totalTaxCost: number | null,
    unrealizedCapitalGainsTax: number | null,
    totalInvestablePresentValue?: number | null,
    deferredTaxLiability: number | null,
    allHoldings: HoldingAssetSubclassDetails[],
    marketValue?: EstateValue,
    asOfDate?: string
}

export type HoldingAssetSubclassDetails = Omit<Holding, 'accountId'> & {
    assetSubclassDetailsName: string,
    accountNumber?: string
};

export enum HoldingType {
    Product = 'Product',
    CustomProduct = 'Custom_Product',
    ApprovedProduct = 'Approved_Product',
    Subclass = 'Subclass'
}

export type EstateValue = {
    inEstateValue: number,
    outOfEstateValue: number,
    totalValue: number
}

export type Holding = {
    id?: string | null,
    productId?: string,
    productCode?: string,
    assetSubclassDetailsId: string,
    marketValue: number | null,
    taxCost: number | null,
    investableValue: number | null,
    locked: boolean,
    concentrated: boolean,
    productName?: string,
    ticker?: string,
    cusip?: string,
    type?: HoldingType,
    marketEstateValue: EstateValue
};
export type HoldingWriteModel = Omit<Holding, 'marketEstateValue'>;
