import React, {useState} from 'react';
import {useAppSelector} from "../../store/hooks";
import {selectInEstateCnwAssetTotals, selectInEstateCnwAssetTotalsUsingDeathBenefit} from "../clientAssetsSlice";
import {AssetsView} from "../common/AssetsView";
import AssetsViewContext from "../common/AssetsViewContext";
import {selectFamilyTree} from "../../ClientManagement/FamilyTree/FamilyTreeSlice";

const CurrentNetWorthContainer = () => {

    const [showDeathBenefit, setShowAtDeathBenefit] = useState(false);//not in ASM
    const inEstateCnwAssetTotals = useAppSelector(showDeathBenefit ? selectInEstateCnwAssetTotalsUsingDeathBenefit : selectInEstateCnwAssetTotals)!;
    const familyTree = useAppSelector(selectFamilyTree);

    return (
        <AssetsViewContext.Provider value="CurrentNetWorth">
            <AssetsView inEstateTotals={inEstateCnwAssetTotals}
                        familyTree={familyTree}
                        showDeathBenefit={showDeathBenefit}
                        toggleShowDeathBenefit={() => setShowAtDeathBenefit(!showDeathBenefit)}
            />
        </AssetsViewContext.Provider>
    );
}

export default CurrentNetWorthContainer;