export enum MeetingControlResponse {
    APPROVED = "APPROVED",
    DECLINED = "DECLINED",
}

export type MeetingControlRequest = {
    user: {
        id: string;
        name: string;
    },
    response?: MeetingControlResponse,
};