import {get, post, put, remove} from "../core/api";
import {AgendaDetails} from "./models/AgendaDetails";

const isSuccess: (response: Response) => boolean = ({status}) => status === 200 || status === 201;

const handlePostSave = (response: Response) => {
    if (isSuccess(response)) {
        return response.json();
    } else {
        return undefined;
    }
};

const saveAgenda = (reqBody: AgendaDetails): Promise<AgendaDetails> => {

    return post(`/client-management/profiles/${reqBody.profileId}/agenda`, reqBody)
        .then(handlePostSave);
}

const updateAgenda = (reqBody: AgendaDetails): Promise<AgendaDetails> => {
    return put(`/client-management/profiles/${reqBody.profileId}/agenda`, reqBody)
        .then(handlePostSave);
}

export const getAgenda = (profileId: string): Promise<AgendaDetails> => {
    return get(`/client-management/profiles/${profileId}/agenda`)
        .then((res: Response) => res.json());
}

export const deleteAgenda = (profileId: string): Promise<{ status: number }> => {
    return remove(`/client-management/profiles/${profileId}/agenda`)
        .then((res: Response) => {
            return {status: res.status}
        });
}

export const agendaApiClient = {
    saveAgenda,
    updateAgenda,
    getAgenda,
    deleteAgenda
};
