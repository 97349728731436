import React from 'react';
import {LegalEntityType} from "../../../Assets/models/Ownership";
import {LegalEntityTypeSummary,} from "../../../Assets/mappers";
import AccordionWithHeader from "../../../components/Accordion/AccordionWithHeader";
import {AccordionItem} from "../../../components";
import {COLOR_NT_GREY_200} from "../../../constants/colors";
import {GroupedTable} from "../../../components/Table/GroupedTable";
import {AssetAccordionHeader} from "../../../Assets/AssetSummary/common/AssetAccordionHeader";
import {formatCurrency} from "../../../utils/format";
import {NO_OP} from "../../../constants/common";

export interface OutOfEstateAssetListReportProps {
    allItems: any,
    legalEntitiesSummariesForPage: LegalEntityTypeSummary[],
    allLegalEntityTypeSummaries: LegalEntityTypeSummary[],
    accordionIdForOutOfEstate: string,
    isDetailed?: boolean
}

const OutOfEstateLegalEntityReportView = ({
                                              allItems,
                                              legalEntitiesSummariesForPage,
                                              allLegalEntityTypeSummaries,
                                              accordionIdForOutOfEstate,
                                              isDetailed
                                          }: OutOfEstateAssetListReportProps) => {

    function checkFirstPage(summary: LegalEntityTypeSummary) {
        let entityGroup:LegalEntityTypeSummary = allLegalEntityTypeSummaries.find(group=>group.entityType===summary.entityType)!;
        if(summary && entityGroup){
            if (summary.entities[0].groupName === entityGroup.entities[0].groupName) {
                if (entityGroup.entities[0].items.length == 0) return true;
                if ((summary.entities[0].items[0].itemName === entityGroup.entities[0].items[0].itemName) && (summary.entities[0].items[0].itemValue === entityGroup.entities[0].items[0].itemValue)) {
                    return true;
                }
                return false;
            }
        } else return false;
    }

    function getOutEstateCaption(summary:LegalEntityTypeSummary) {
        if(!isDetailed) return "";
        else return (checkFirstPage(summary) ? "":"continued")
    }

    return  <div className="asset-list">
            <div className="accordion-tables">
                <AccordionWithHeader
                    accordionId={accordionIdForOutOfEstate}
                    allItems={allItems}
                    columnTitles={['Owner', '', 'Present Value']}
                    renderHeader
                    headerProps={
                        undefined
                    }
                >
                    <div className="assets">
                        <article className="layout-split-left">
                            <div>
                                {
                                    legalEntitiesSummariesForPage && legalEntitiesSummariesForPage.map((summary: LegalEntityTypeSummary, index: number) => {
                                        return <div key={index}>
                                                <AccordionItem
                                                    key={index}
                                                    uuid={summary.entityType}
                                                    accentColor={COLOR_NT_GREY_200}
                                                    HeaderComponent={({expanded}) => {
                                                        return <AssetAccordionHeader
                                                            expanded={expanded}
                                                            title={entitySummaryTitles[summary.entityType]}
                                                            caption={getOutEstateCaption(summary)}
                                                            formattedTotalPresentValue={formatCurrency(summary.totalValue)}
                                                            gridClassName={"assets-grid"}/>;
                                                    }
                                                    }>
                                                    <GroupedTable columnHeaders={["Name", "Present Value"]}
                                                                  tableData={summary.entities}
                                                                  menuClickHandler={NO_OP}
                                                    />
                                                </AccordionItem>
                                        </div>
                                    })
                                }
                            </div>
                        </article>

                    </div>
                </AccordionWithHeader>
            </div>
        </div>
}

const entitySummaryTitles: Record<LegalEntityType, string> = {
    "Irrevocable Trust": "Irrevocable Trusts",
    "Irrevocable Life Insurance Trust (ILIT)": "Irrevocable Life Insurance Trusts (ILITs)",
    "Charitable Foundation": "Charitable Foundations",
    "Donor Advised Fund (DAF)": "Donor Advised Funds (DAFs)",
    "529 Plan": "529 Plans",
    "Dynasty Trust": "Dynasty Trusts",
    "LP, LLC, Other Partnership": "LPs, LLCs, Other Partnerships",
    "S Corp": "S Corps",
    "C Corp": "C Corps",
    "Other": "Others",
};

export default OutOfEstateLegalEntityReportView;
