import React, {ReactElement} from 'react';
import ModalWrapper, {ModalButton} from "../ModalWrapper/ModalWrapper";
import {toDisplayDateTimeFormat} from "../../../utils/dateUtils";

export interface GenericErrorModalData {
    isOpen: boolean;
    header: string;
    message: string | ReactElement;
    operationId: string;
    errorDetail?: string,
    profileId?: string;
    time?: Date;
}

export const genericEmptyErrorModalData: GenericErrorModalData = {
    isOpen: false,
    header: '',
    message: '',
    operationId: ''
}

interface GenericErrorModalProps {
    buttonText?: string;
    onClickButton?: () => void;
    onRequestClose?: () => void;
    errorModalData: GenericErrorModalData;
    buttonProps?: Partial<ModalButton>;
    alertIcon?: string,
    alertIconType?: string,
    showAlertIcon?: boolean
}

const GenericErrorModal: React.FC<GenericErrorModalProps> = ({
                                                                 buttonText = 'Close',
                                                                 onClickButton,
                                                                 onRequestClose,
                                                                 errorModalData,
                                                                 buttonProps,
                                                                 alertIcon='warning',
                                                                 alertIconType='error',
                                                                 showAlertIcon = true
                                                             }) => {

    const renderDetail = (label: string, value: string)=> {
        return <div>{`${label}: ${value}`}</div>;
    }

    return (
        <ModalWrapper
            id="Generic Error Modal"
            headerText={errorModalData.header}
            isOpen={errorModalData.isOpen}
            showCloseButton={onRequestClose !== undefined}
            alertIconType={alertIconType}
            alertIcon={showAlertIcon ? alertIcon : undefined}
            onRequestClose={onRequestClose}
            buttons={[
                {
                    text: buttonText,
                    onClick: onClickButton,
                    ...buttonProps
                },
            ]}
        >
            <div>{errorModalData.message}</div>
            <div className="secondary-text">
                <br/>
                {
                    errorModalData.errorDetail && <div>{errorModalData.errorDetail}</div>
                }
                {
                    errorModalData.profileId && renderDetail('Profile', errorModalData.profileId)
                }
                {
                    errorModalData.time && renderDetail('Time', toDisplayDateTimeFormat(errorModalData.time?.toISOString()))
                }
                <div>{`Operation ID: ${errorModalData.operationId}`}</div>
            </div>
        </ModalWrapper>
    )
}

export default GenericErrorModal;