import React from 'react';
import NoGoalsLandingPage from "./NoGoalsLandingPage";
import GoalList from "./GoalList";
import {useAppSelector} from "../../store/hooks";
import SummaryBarChartSideBar from "../BarChartSidebar/SummaryBarChartSideBar";
import {selectGoalModel} from "../controller/GoalsModelSlice";
import {GoalModelType} from "../models/GoalModelType";
import ScrollableContainer from "../../components/ScrollableContainer/ScrollableContainer";
import {FamilyGoalType} from "../models/FamilyGoalType";
import {MemberType} from "../../ClientManagement/models/MemberType";
import {GoalType, NonLifestyleGoalType} from "../models/GoalType";

type SummaryProps = {
    onEditFamilyGoal: (selectedGoalType: string, savedGoal: FamilyGoalType) => void,
    onViewFamilyGoal: (memberSelected: MemberType) => void,
    onDeleteNonLifestyleGoal: (goal: NonLifestyleGoalType) => void,
    onEditNonLifestyleGoal: (goal: NonLifestyleGoalType, type: GoalType) => void
};

const Summary = ({
                     onEditFamilyGoal,
                     onViewFamilyGoal,
                     onDeleteNonLifestyleGoal,
                     onEditNonLifestyleGoal
                 }: SummaryProps) => {
    const {
        lifestyleSpendingGoal,
        familyGoals,
        discretionaryGoals,
        philanthropicGoals
    } = useAppSelector<GoalModelType>(selectGoalModel)

    const clientHasFamilyGoals = !!familyGoals && Object.values(familyGoals).length > 0
    const clientHasLifestyleGoals = !!lifestyleSpendingGoal.id;
    const clientHasNonLifestyleGoals = [...discretionaryGoals, ...philanthropicGoals].some(nonLifestyleGoal => !!nonLifestyleGoal.id);
    const clientHasSavedGoals = clientHasLifestyleGoals || clientHasFamilyGoals || clientHasNonLifestyleGoals;

    return (
        <ScrollableContainer id={'goals-summary-scroll-container'} className="goals-summary-container layout-split-sidebar">
            <SummaryBarChartSideBar/>
            {clientHasSavedGoals
                ?
                <GoalList
                    onEditFamilyGoal={onEditFamilyGoal}
                    onViewFamilyGoal={onViewFamilyGoal}
                    onDeleteNonLifestyleGoal={onDeleteNonLifestyleGoal}
                    onEditNonLifestyleGoal={onEditNonLifestyleGoal}
                />
                : <NoGoalsLandingPage/>}
        </ScrollableContainer>
    )
}

export default Summary;
