import {TableCell} from "../../../components/Table/TableCell";
import {formatCurrency} from "../../../utils/format";
import React from "react";
import {Icon} from "../../../components";
import {shortenName} from "../../common/textUtils";
import {BankingAccountsSummary} from "../../models/BankingAccounts";
import moment from "moment";
import {DISPLAY_DATE_FORMAT} from "../../../constants/common";

export const AssetSummaryBankingAccountDetails = ({bankingAccount}: { bankingAccount: BankingAccountsSummary }) => {

    const presentableAsOfDate = bankingAccount.valueAsOfDate ? `As of ${moment(bankingAccount.valueAsOfDate).format(DISPLAY_DATE_FORMAT)}` : '--';
    const shouldShowAccountNumber = !!bankingAccount.institution && !!bankingAccount.accountNumber && bankingAccount.accountName.length > 3;
    const presentableAccountNumber = `(...${bankingAccount.accountNumber?.substring(bankingAccount.accountNumber.length - 4)})`;

    const investableValue =  (bankingAccount.holdings !== null && bankingAccount.holdings.totalInvestableValue) ? bankingAccount.holdings.totalInvestableValue : 0;

        const bankingAccountText = (
        <div role="cell" style={{paddingLeft: "0px"}}>
            <div className="cell-with-popover">
                <div className="label-15px cell-text-content paddingright-sm">
                    <span>{shortenName(bankingAccount.accountName)}</span>
                </div>
            </div>
        </div>
    )
    const bankingAccountSubtext = (
        <>
            {presentableAsOfDate}
            <Icon name="status_processing" className='paddingleft-md' ariaHidden={false}
                  ariaLabel="Banking Accounts are Updated"/>
        </>
    );
    return <>
        <TableCell text={bankingAccountText} subtext={bankingAccountSubtext}/>
        <TableCell text={bankingAccount.institution} subtext={shouldShowAccountNumber ? presentableAccountNumber : undefined} textSize="small"/>
        <TableCell text={bankingAccount.entityType} subtext={bankingAccount.taxStatus} textSize="small"/>
        <TableCell text={formatCurrency(investableValue)} className="textalign-right"/>
    </>;
};