import React from 'react';
import {ReactComponentLike} from "prop-types";
import {Link} from ".";
import {LinkProps, LocationProps} from "../../models/routeData/RouteParamTypes";

type NavDrawerSubItemProps = {
    link: LinkProps,
    LinkRenderer: ReactComponentLike | string,
    location: LocationProps
}

export const NavDrawerSubItem = ({ link, LinkRenderer = 'a', location }: NavDrawerSubItemProps) => {
    return (
            <div className="nav-drawer-sub-item">
                <Link className="nav-drawer-sub-item__link" link={link} location={location} Renderer={LinkRenderer} />
            </div>
    )
}
