import React from 'react';
import {NO_OP} from "../../constants/common";

type AccordionContextValue = {
    expandedItems: string[],
    toggleItem: (uuid: string) => void,
};

const defaultValue: AccordionContextValue = {
    expandedItems: [],
    toggleItem: NO_OP,
};

export default React.createContext(defaultValue);
