import React from 'react';
import {DropdownMenu, MenuItem} from "../../../components/DropdownMenu";
import {MenuDivider, MenuGroup} from "@nt/dds-react/core/DropdownMenu";
import {HoldingAssetSubclassDetails} from "../../models/Holding";
import {NO_OP} from "../../../constants/common";
import {AssetClassifications, AssetSubclass, AssetSubclassDetails} from "../../models/AssetClassifications";

type AddCustomProductsDropdownMenuProps = {
    customProductClassifications: AssetClassifications,
    scrollableRegionId: string
    handleAddCustomProduct: (assetSubclassDetail: HoldingAssetSubclassDetails) => void
}

function AddCustomProductsDropdownMenu({
                                           customProductClassifications,
                                           scrollableRegionId,
                                           handleAddCustomProduct
                                       }: AddCustomProductsDropdownMenuProps) {

    const menuItemHeight = 35;
    const calculateMenuPanelHeight = (assetClasses: AssetClassifications): number => {
        const assetClassGroups = 2;
        const subclassDetailCount = [
                ...assetClasses.riskAssetClasses,
                ...assetClasses.riskControlAssetClasses
            ].map(assetClass => assetClass.assetSubclasses
            .map(assetSubclass => assetSubclass.assetSubclassDetails))
            .map(assetSubclassDetails => assetSubclassDetails.length)
            .reduce((totalCount, count) => totalCount + count, 0);

        return (assetClassGroups * 50) + (subclassDetailCount * menuItemHeight);
    }

    const calculateSubMenuPanelHeight = (subclassDetails: unknown[]): number => {
        return subclassDetails.length * menuItemHeight;
    }

    const calculateSubMenuPanelWidth = (subclassDetails: AssetSubclassDetails[]): number => {
        const maxTextSize = subclassDetails
            .map(subclassDetail => subclassDetail.assetSubclassDetailsName.length)
            .reduce((max, current) => Math.max(max, current), 0);

        const minimumPanelWidth = 240;
        const averageLetterWidth = 7;
        return Math.max(minimumPanelWidth, averageLetterWidth * maxTextSize);
    }

    const AssetSubclassDetailsMenu = (subclassDetailsMenuProps: { assetSubclass: AssetSubclass }) => {
        const {assetSubclass} = subclassDetailsMenuProps;

        return <>
            {assetSubclass.assetSubclassDetails
                .map((assetSubclassDetail) => {
                    return {
                        id: null,
                        assetSubclassDetailsId: assetSubclassDetail.assetSubclassDetailsId,
                        assetSubclassDetailsName: assetSubclassDetail.assetSubclassDetailsName,
                        marketValue: 0.00,
                        marketEstateValue: {
                            inEstateValue: 0,
                            outOfEstateValue: 0,
                            totalValue: 0
                        },
                        taxCost: null,
                        investableValue: 0.00,
                        locked: false,
                        concentrated: false,
                        productName: '',
                        cusip: '',
                        ticker: '',
                        liquidityCategory: null,
                        default: false
                    }
                })
                .map((assetSubclassDetail: HoldingAssetSubclassDetails, key: number) =>
                    <MenuItem key={key}
                              text={assetSubclassDetail.assetSubclassDetailsName}
                              scrollableRegionId={scrollableRegionId}
                              onClick={() => handleAddCustomProduct(assetSubclassDetail)} onSubMenuOpen={NO_OP}/>
                )}
        </>
    }

    function assetSubclassMenuItemRenderer(assetSubclass: AssetSubclass, key: number) {
        return <MenuItem key={key} text={assetSubclass.assetSubclassName}
                         onSubMenuOpen={NO_OP}
                         panelHeight={calculateSubMenuPanelHeight(assetSubclass.assetSubclassDetails)}
                         panelWidth={calculateSubMenuPanelWidth(assetSubclass.assetSubclassDetails)}
                         scrollableRegionId={scrollableRegionId}>
            <AssetSubclassDetailsMenu assetSubclass={assetSubclass}/>
        </MenuItem>;
    }

    const riskSubClasses = customProductClassifications.riskAssetClasses
        .flatMap(assetClass => assetClass.assetSubclasses);
    const riskControlSubClasses = customProductClassifications.riskControlAssetClasses
        .flatMap(assetClass => assetClass.assetSubclasses);
    return (
        <div data-testid="addCustomProduct">
            <DropdownMenu
                alignRight
                buttonKind="primary"
                buttonSize="medium"
                buttonText="Add Custom Product"
                panelHeight={calculateMenuPanelHeight(customProductClassifications)}
                panelWidth={240}
                id="addCustomProduct"
                disabled={false}
                scrollableRegionId={scrollableRegionId}
            >
                {[
                    {name: "Risk Assets", data: riskSubClasses},
                    {name: "Risk Control Assets", data: riskControlSubClasses}
                ].map((group, i) =>
                    <React.Fragment key={i}>
                        {i > 0 && <MenuDivider/>}
                        <MenuGroup name={group.name}>
                            {group.data
                                .map((assetSubclass, key) =>
                                    assetSubclassMenuItemRenderer(assetSubclass, key)
                                )}
                        </MenuGroup>
                    </React.Fragment>
                )}
            </DropdownMenu>
        </div>
    );
}

export default AddCustomProductsDropdownMenu;
