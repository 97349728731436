import React from 'react';
import {useAppSelector} from "../../store/hooks";
import {formatCurrency} from "../../utils/format";
import GoalBarChartSideBar from "./GoalBarChartSidebar";
import {BarChartSummaryTable, BarChartSummaryTableItem} from "../../components/BarChartSidebar/BarChartSummaryTable";
import {selectGoalModel} from "../controller/GoalsModelSlice";
import {BeneficiaryGoals, GoalModelType} from "../models/GoalModelType";
import {FamilyGoalType} from "../models/FamilyGoalType";
import {MemberType} from "../../ClientManagement/models/MemberType";
import FamilyGoalUtils from "../Summary/FamilyGoalUtils";
import {GoalBarChartKeyColors} from "./GoalBarChartKeyColors";
import {LifestyleSpendingGoal} from "../models/LifestyleSpendingGoal";

export function buildAddNewGoalTableItems(familyGoal: FamilyGoalType): BarChartSummaryTableItem[] {
    return [{
        leftText: `${familyGoal.userInputs.description}`,
        rightText: `${formatCurrency(familyGoal.calculatedFields.presentValue)}`,
        isActive: false,
        keyColor: GoalBarChartKeyColors.FAMILY
    }]
}

export interface AddNewFamilyGoalBarChartSideBarProps {
    familyGoalFromForm: FamilyGoalType;
    lifestyleSpendingGoalFromForm?: LifestyleSpendingGoal;
}

function AddNewFamilyGoalBarChartSideBar({familyGoalFromForm, lifestyleSpendingGoalFromForm}: AddNewFamilyGoalBarChartSideBarProps) {
    const {proposal, familyGoals: familyGoalsFromRedux} = useAppSelector<GoalModelType>(selectGoalModel)
    const beneficiaryId = familyGoalFromForm.beneficiaryId
    const beneficiaryGoalsFromRedux:BeneficiaryGoals|undefined = familyGoalsFromRedux[beneficiaryId];
    const memberSelected: MemberType|undefined = FamilyGoalUtils.getBeneficiaryFromProfile(proposal,beneficiaryId);
    const combinedFamilyGoals = {
        ...familyGoalsFromRedux,
        [beneficiaryId]: FamilyGoalUtils.combineBeneficiaryGoalsWithFamilyGoal(familyGoalFromForm, memberSelected, beneficiaryGoalsFromRedux)
    }

    const goalBarChartSideBarProps: {
        familyGoals: {[beneficiaryId: string]: BeneficiaryGoals}
        lifestyleSpendingGoal?: LifestyleSpendingGoal
    } = {
        familyGoals: combinedFamilyGoals
    };
    if (lifestyleSpendingGoalFromForm) {
        goalBarChartSideBarProps.lifestyleSpendingGoal = lifestyleSpendingGoalFromForm;
    }

    return <GoalBarChartSideBar
        {...goalBarChartSideBarProps}>
        <BarChartSummaryTable
            leftHeader={combinedFamilyGoals[beneficiaryId].name}
            rightHeader={`${formatCurrency(familyGoalFromForm!.calculatedFields.presentValue)}`}
            items={buildAddNewGoalTableItems(familyGoalFromForm!)}
        />
    </GoalBarChartSideBar>
}

export default AddNewFamilyGoalBarChartSideBar;