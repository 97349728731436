import {useHistory, useLocation, useParams} from "react-router-dom";
import DataEntryHeader from "../../../components/DataEntry/DataEntryHeader";
import React, {useContext, useEffect, useRef, useState} from "react";
import AssetsViewContext from "../../common/AssetsViewContext";
import {RouteWithProfileIdAndAssetId} from "../../../routes/types";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {PartiallyOwnedInvestmentAccountDetails} from "./PartiallyOwnedInvestmentAccountDetails";
import TaxDetails from "../../TaxDetails/TaxDetails";
import {TaxDetailsType} from "../../models/TaxDetails";
import {assetsApiClient} from "../../AssetsApiClient";
import {HoldingSummary} from "../../models/Holding";
import Ownership from "../../Ownership";
import {
    getDefaultOwnershipDetails,
    getTaxableLiabilityPaidByPortfolioForDeferredAccounts,
    getTaxableLiabilityPaidByPortfolioForTaxableAccounts
} from "../../formHelpers";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectProfile} from "../../../ClientManagement/ClientProfile/activeProfileSlice";
import {LegalEntityFormData, OwnershipDetailsFormData} from "../../models/Ownership";
import {MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import {clientManagementApiClient} from "../../../ClientManagement/ClientManagementApiClient";
import {AccountSummary} from "../../AccountsCommon/AccountSummary";
import {AssetClassifications} from "../../models/AssetClassifications";
import {mapToOwnershipDetailsFormData, mapToOwnershipWriteModel} from "../../Ownership/mappers";
import {calculateOwnershipPercentages, validateOwnershipDetails} from "../../Ownership/validation";
import {RequiredFieldsBanner} from "../../../components";
import AlertBanner from "../../../components/AlertBanner/AlertBanner";
import {
    PartiallyOwnedAccount,
    PartiallyOwnedInvestmentAccountWriteModel,
    PartiallyOwnedLegalAgreement,
    PartiallyOwnedLegalAgreementFormData
} from "../../models/PartiallyOwnedInvestmentAccount";
import {NO_OP} from "../../../constants/common";
import {selectClientAssets, setActiveFormAsset} from "../../clientAssetsSlice";
import {calculateInEstateFormValue} from "../../AssetSummary/common/AssetSummaryCalculator";
import DiscardAssetModal from "../../DiscardAssetModal";
import {shortenName} from "../../common/textUtils";
import deepEquals from "fast-deep-equal";


export function EditPartiallyOwnedInvestment() {
    const location = useLocation();
    const history = useHistory();
    const profile = useAppSelector(selectProfile);
    const {profileId, assetId} = useParams<RouteWithProfileIdAndAssetId>();
    const viewType = useContext(AssetsViewContext);
    const [account, setAccount] = useState<PartiallyOwnedInvestmentAccountWriteModel>({
        id: '',
        profileId: '',
        ordinal: -1,
        legalAgreementName: '',
        legalAgreementNumber: '',
        legalAgreementTypeCode: '',
        taxStatus: 'Taxable',
        institution: '',
        entityType: '',
        isEntityCapitalGains: false,
        isLiabilityPaidByPortfolio: false,
        ownershipCategory: 'Sole',
        partiallyOwnedAccounts: [],
        memberOwnerships: [],
        legalEntityOwnerships: [],
        permitBeneficiary: false
    });
    const [initialAccount, setInitialAccount] = useState<PartiallyOwnedInvestmentAccountWriteModel>({
        id: '',
        profileId: '',
        ordinal: -1,
        legalAgreementName: '',
        legalAgreementNumber: '',
        legalAgreementTypeCode: '',
        taxStatus: 'Taxable',
        institution: '',
        entityType: '',
        isEntityCapitalGains: false,
        isLiabilityPaidByPortfolio: false,
        ownershipCategory: 'Sole',
        partiallyOwnedAccounts: [],
        memberOwnerships: [],
        legalEntityOwnerships: [],
        permitBeneficiary: false
    });
    const [domesticTrustHoldings, setDomesticTrustHoldings] = useState<HoldingSummary>();
    const [taxDetails, setTaxDetails] = useState<TaxDetailsType>({
        isEntityCapitalGains: false,
        isLiabilityPaidByPortfolio: false,
    });
    const [initialTaxDetails, setInitialTaxDetails] = useState<TaxDetailsType>({
        isEntityCapitalGains: false,
        isLiabilityPaidByPortfolio: false,
    });
    const [unrealizedCapitalGainsTax, updateUnrealizedCapitalGainsTax] = useState<number | null>(null);
    const [unrealizedCapitalGainsTaxForAccountSummary, setUnrealizedCapitalGainsTaxForAccountSummary] = useState<number | null>(null);
    const [totalInvestablePresentValue] = useState<number | null | undefined>(null);
    const [deferredTaxLiability, updateDeferredTaxLiability] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const mounted = useRef(false);
    const defaultOwnershipDetails = getDefaultOwnershipDetails(profile.primaryContact.id!);
    const [ownershipDetailsFormData, updateOwnershipDetailsFormData] = useState<OwnershipDetailsFormData>(defaultOwnershipDetails);
    const [initialOwnershipDetailsFormData, updateInitialOwnershipDetailsFormData] = useState<OwnershipDetailsFormData>(defaultOwnershipDetails);
    const [memberGroup, setMemberGroup] = useState<MemberGroup>();
    const [legalEntities, setLegalEntities] = useState<LegalEntityFormData[]>([]);
    const [classifications, setClassifications] = useState<AssetClassifications>();
    const [isOwnershipLessThan100, setIsOwnershipLessThan100] = useState(false);
    const [isOutOfEstateOwnershipZero, setIsOutOfEstateOwnershipZero] = useState(false);
    const [isRequiredFieldsBannerShown, setIsRequiredFieldsBannerShown] = useState(false);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
    const [requiredFieldsBannerMessage, setRequiredFieldsBannerMessage] = useState<string>();
    const [initialLoading, setInitialLoading] = useState(true)
    const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false);
    const {accounts, partiallyOwnedLegalAgreements, investmentProgram} = useAppSelector(selectClientAssets)!;
    const taxableLiabilityPaidByPortfolio = getTaxableLiabilityPaidByPortfolioForTaxableAccounts(accounts, investmentProgram, partiallyOwnedLegalAgreements)!;
    const deferredLiabilityPaidByPortfolio = getTaxableLiabilityPaidByPortfolioForDeferredAccounts(accounts, investmentProgram, partiallyOwnedLegalAgreements)!;
    const [totalMarketValue, setTotalMarketValue] = useState<number>(0);
    const isEditPoia: boolean = assetId != undefined;
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (mounted.current && !isLoading) {
            dispatch(setActiveFormAsset({
                assetType: 'partiallyOwnedInvestmentAccount',
                id: account.id || undefined,
                inEstateValue: calculateInEstateFormValue(totalMarketValue, ownershipDetailsFormData.memberOwnerships),
                description: account.legalAgreementName,
                hasInEstateOwnership: true,
            }));
            return () => {
                dispatch(setActiveFormAsset(null));
            }
        }
    }, [mounted.current, isLoading, ownershipDetailsFormData, legalEntities, totalMarketValue])

    useEffect(() => {
        if (isEditPoia) {
            setIsLoading(true);
            setInitialLoading(false);
            Promise.all([
                clientManagementApiClient.getMemberGroup(profile.id),
                assetsApiClient.getLegalEntities(profile.id),
                assetsApiClient.getPartiallyOwnedInvestment(profileId, assetId),
                assetsApiClient.getAssetClassifications(),
                assetsApiClient.getDomesticTrustHoldings(profile.id, assetId, "POIA")] )
                .then(([memberGroupResponse, legalEntityResponse,
                           partiallyOwnedInvestmentResponse, assetClassificationResponse,
                           holdingsResponse]) => {
                    if (!mounted.current) return;
                    mapPartiallyOwnedInvestmentAccountToWriteModel(partiallyOwnedInvestmentResponse);
                    mapPartiallyOwnedInvestmentAccountOwnership(partiallyOwnedInvestmentResponse);
                    updateUnrealizedCapitalGainsTax(partiallyOwnedInvestmentResponse.holdings.unrealizedCapitalGainsTax);
                    updateDeferredTaxLiability((partiallyOwnedInvestmentResponse.holdings.deferredTaxLiability));
                    setMemberGroup(memberGroupResponse);
                    setLegalEntities(legalEntityResponse);
                    setClassifications(assetClassificationResponse);
                    setDomesticTrustHoldings(holdingsResponse);
                    setTotalMarketValue(holdingsResponse.totalMarketValue!);
                })
                .catch(e => console.error(e))
                .finally(() => {
                    if (!mounted.current) return;
                    setIsLoading(false);
                });
        } else {
            //Add Poia page
            const financialAccountPersistentIds: string[] = location.state.legalAgreement.partiallyOwnedAccounts.map((poia: PartiallyOwnedAccount) => poia.financialAccountPersistentId);
            Promise.all([
                clientManagementApiClient.getMemberGroup(profile.id),
                assetsApiClient.getLegalEntities(profile.id),
                assetsApiClient.getAssetClassifications(),
                assetsApiClient.getDomesticTrustHoldingsForPOIAPreSave(financialAccountPersistentIds, profileId)
            ] )
                .then(([memberGroupResponse, legalEntityResponse,
                           assetClassificationResponse, holdingsPoiaResponse]) => {
                    if (!mounted.current) return;
                    setMemberGroup(memberGroupResponse);
                    setLegalEntities(legalEntityResponse);
                    setClassifications(assetClassificationResponse);
                    setDomesticTrustHoldings({
                        ...domesticTrustHoldings,
                        totalMarketValue: holdingsPoiaResponse.totalMarketValue,
                        totalInvestableValue: 0,
                        totalTaxCost: holdingsPoiaResponse.totalTaxCost,
                        unrealizedCapitalGainsTax: holdingsPoiaResponse.unrealizedCapitalGainsTax,
                        deferredTaxLiability:holdingsPoiaResponse.deferredTaxLiability,
                        allHoldings: holdingsPoiaResponse.allHoldings,
                    });
                })
                .catch(e => console.error(e))
                .finally(() => {
                    if (!mounted.current) return;
                    setIsLoading(false);
                });
            mapLegalAgreementToWriteModel(location.state.legalAgreement, location.state.account);

            dispatch(setActiveFormAsset({
                assetType: 'partiallyOwnedInvestmentAccount',
                id: account.id || undefined,
                inEstateValue: location.state.legalAgreement.marketEstateValue.totalValue,
                description: location.state.legalAgreement.legalAgreementName,
                hasInEstateOwnership: true,
            }));
        }
    }, [assetId, profile.id])

    const loadHoldings = (updatedTaxDetails?: TaxDetailsType) => {
        assetsApiClient.getDomesticTrustHoldings(profile.id, assetId, "POIA")
            .then(holdingsResponse => {
                if (!mounted.current) return;
                setDomesticTrustHoldings(holdingsResponse);
                setTotalMarketValue(holdingsResponse.totalMarketValue!);
            })
            .catch(e => console.error(e));
    }

    const handleTaxDetailsChange = (updatedTaxDetails: TaxDetailsType) => {
        setTaxDetails(updatedTaxDetails);
        loadHoldings(updatedTaxDetails);
    };


    const handleUnrealizedCapitalGainsTaxChange = async (isEntityCapitalGains: boolean) => {
        let unrealizedCapitalGainsTaxResponse = await assetsApiClient.getUnrealizedCapitalGainsTaxPartiallyOwnedLegalAgreement(profileId, assetId, isEntityCapitalGains);
        if (mounted.current) {
            setUnrealizedCapitalGainsTaxForAccountSummary(unrealizedCapitalGainsTaxResponse);
        }

    };

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    }, []);

    useEffect(() => {
        if(isEditPoia) {
            handleUnrealizedCapitalGainsTaxChange(taxDetails.isEntityCapitalGains!)
                .catch(reason => console.log(reason));
        }
    }, [taxDetails.isEntityCapitalGains]);

    const navigateToAssetsView = () => {
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
    }

    function navigateToHoldingsView(partiallyOwnedInvestmentAccountId: String) {
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}/PartiallyOwnedInvestmentAccountHoldings/${partiallyOwnedInvestmentAccountId}`);
    }

    const mapPartiallyOwnedInvestmentAccountToWriteModel = (partiallyOwnedInvestmentAccount: PartiallyOwnedLegalAgreement) => {
        const {
            legalAgreementNumber,
            legalAgreementName,
            legalAgreementTypeCode,
            partiallyOwnedAccounts,
            marketEstateValue,
            ...rest
        } = partiallyOwnedInvestmentAccount;

        setAccount({
            ...account,
            ...rest,
            partiallyOwnedAccounts: partiallyOwnedAccounts,
            legalAgreementNumber: legalAgreementNumber,
            legalAgreementName: legalAgreementName,
            legalAgreementTypeCode: legalAgreementTypeCode,
        });
        setInitialAccount({
            ...account,
            ...rest,
            partiallyOwnedAccounts: partiallyOwnedAccounts,
            legalAgreementNumber: legalAgreementNumber,
            legalAgreementName: legalAgreementName,
            legalAgreementTypeCode: legalAgreementTypeCode,
        });
        setTaxDetails({
            isEntityCapitalGains: rest.isEntityCapitalGains,
            isLiabilityPaidByPortfolio: rest.isLiabilityPaidByPortfolio,
        });
        setInitialTaxDetails({
            isEntityCapitalGains: rest.isEntityCapitalGains,
            isLiabilityPaidByPortfolio: rest.isLiabilityPaidByPortfolio,
        });
    };

    const mapPartiallyOwnedInvestmentAccountOwnership = (partiallyOwnedInvestmentAccount: PartiallyOwnedLegalAgreement) => {
        updateOwnershipDetailsFormData(
            mapToOwnershipDetailsFormData(partiallyOwnedInvestmentAccount)
        );
        updateInitialOwnershipDetailsFormData(
            mapToOwnershipDetailsFormData(partiallyOwnedInvestmentAccount)
        );
    };

    const mapLegalAgreementToWriteModel = (partiallyOwnedLegalAgreement: PartiallyOwnedLegalAgreement, partiallyOwnedAccount: PartiallyOwnedAccount) => {
        let liabilityPaidByPortfolio = null;
        if (partiallyOwnedLegalAgreement.taxStatus === 'Taxable') {
            liabilityPaidByPortfolio = taxableLiabilityPaidByPortfolio;
        } else if (partiallyOwnedLegalAgreement.taxStatus === 'Deferred') {
            liabilityPaidByPortfolio = deferredLiabilityPaidByPortfolio;
        }

        setAccount({
            ...account,
            legalAgreementName: partiallyOwnedLegalAgreement.legalAgreementName,
            legalAgreementNumber: partiallyOwnedLegalAgreement.legalAgreementNumber,
            legalAgreementTypeCode: partiallyOwnedLegalAgreement.legalAgreementTypeCode,
            taxStatus: partiallyOwnedLegalAgreement.taxStatus,
            institution: partiallyOwnedLegalAgreement.institution,
            entityType: partiallyOwnedLegalAgreement.entityType,
            partiallyOwnedAccounts: partiallyOwnedLegalAgreement.partiallyOwnedAccounts,
            permitBeneficiary: partiallyOwnedLegalAgreement.permitBeneficiary,
        });
        setInitialAccount({
            ...account,
            legalAgreementName: partiallyOwnedLegalAgreement.legalAgreementName,
            legalAgreementNumber: partiallyOwnedLegalAgreement.legalAgreementNumber,
            legalAgreementTypeCode: partiallyOwnedLegalAgreement.legalAgreementTypeCode,
            taxStatus: partiallyOwnedLegalAgreement.taxStatus,
            institution: partiallyOwnedLegalAgreement.institution,
            entityType: partiallyOwnedLegalAgreement.entityType,
            partiallyOwnedAccounts: partiallyOwnedLegalAgreement.partiallyOwnedAccounts,
            permitBeneficiary: partiallyOwnedLegalAgreement.permitBeneficiary,
        });
        setTaxDetails( {
            ...taxDetails,
            isLiabilityPaidByPortfolio: liabilityPaidByPortfolio
        })
        setInitialTaxDetails( {
            ...taxDetails,
            isLiabilityPaidByPortfolio: liabilityPaidByPortfolio
        })
        setTotalMarketValue(partiallyOwnedLegalAgreement.marketEstateValue.totalValue);
    };

    const handleBannerClose = () => {
        setInitialLoading(false)
    }
    const handleClickOnSave = () => {
        const {isValid} = validateForm();

        if (isValid) {
            setIsSaveButtonDisabled(true);

            const {
                id,
                profileId: otherProfileId,
                ordinal,
                memberOwnerships,
                legalEntityOwnerships,
                holdings,
                ...request
            } = account;

            assetsApiClient.postPartiallyOwnedInvestment(profileId, {
                ...request,
                ...mapToOwnershipWriteModel(ownershipDetailsFormData),
                ...taxDetails
            })
                .then(response => {
                    if (!mounted.current) return;

                    if (response) {
                        navigateToAssetsView();
                    }
                })
                .finally(() => {
                    if (!mounted.current) return;
                    setIsSaveButtonDisabled(false)
                });
        }
    };

    const validateForm = () => {
        const ownershipValidation = validateOwnershipDetails(ownershipDetailsFormData);
        const isOwnershipPercentageInvalid = !ownershipValidation.isTotalOwnedPercentageEqualTo100
            || ownershipValidation.isTotalOutOfEstateOwnedPercentageEqualTo0;

        setIsOwnershipLessThan100(!ownershipValidation.isTotalOwnedPercentageEqualTo100)
        setIsOutOfEstateOwnershipZero(ownershipValidation.isTotalOutOfEstateOwnedPercentageEqualTo0);

        const isRequiredFieldEmpty = isAnyRequiredFieldEmpty();

        if (isRequiredFieldEmpty) {
            showRequiredFieldsBanner(true);
        }  else if (calculateOwnershipPercentages(ownershipDetailsFormData).grandTotal !== 100) {
            showRequiredFieldsBanner(
                true,
                'Ownership total is incorrect. Ensure the total asset ownership equals 100%.');
        } else if (calculateOwnershipPercentages(ownershipDetailsFormData).outOfEstateTotal == 0) {
            showRequiredFieldsBanner(
                true,
                'All required fields and out-of-estate ownership must be entered to save a Partially Owned Investment Account.');
        }else {
            showRequiredFieldsBanner(false);
        }

        return {
            isValid: !isRequiredFieldEmpty && !isOwnershipPercentageInvalid
        };
    };

    const showRequiredFieldsBanner = (show: boolean, message?: string) => {
        setIsRequiredFieldsBannerShown(show);
        setRequiredFieldsBannerMessage(message);
    }

    const isAnyRequiredFieldEmpty = () => {
        const isNameBlank = !account.legalAgreementName?.trim();

        const isOwnershipDataMissing = ownershipDetailsFormData?.legalEntityOwnerships.some((ownership) => {
            return !ownership.name.trim() || !ownership.type;
        });

        return isNameBlank || isOwnershipDataMissing;
    }

    const isFormChanged = () => {
        const updated = {
            ...account,
            ...taxDetails,
            ...ownershipDetailsFormData
        };
        const initial = {
            ...initialAccount,
            ...initialTaxDetails,
            ...initialOwnershipDetailsFormData
        }
        return !deepEquals(initial, updated);
    }
    const handleClickOnCancel = () => {
        if(isFormChanged()) {
            setShowDiscardChangesModal(true);
            return;
        }
        navigateToAssetsView();
    };

    const handleViewHoldingsClick = async () => {
        const {isValid} = validateForm();
        if (isValid) {
            const {
                id,
                profileId: otherProfileId,
                ordinal,
                memberOwnerships,
                legalEntityOwnerships,
                ...request
            } = account;

            await assetsApiClient.postPartiallyOwnedInvestment(profileId, {
                ...request,
                ...mapToOwnershipWriteModel(ownershipDetailsFormData),
                ...taxDetails
            }).then(response => {
                if (response) {
                    navigateToHoldingsView(response.id);
                }
            })
        }
    };

    const ownershipOnlyInEstate = calculateOwnershipPercentages(ownershipDetailsFormData).inEstateTotal === 100 && calculateOwnershipPercentages(ownershipDetailsFormData).grandTotal === 100;

    const renderZeroOutOfEstateAlertSubheader = () => {
        if (isOutOfEstateOwnershipZero) {
            return (
                <div className={"paddingbottom-lg"}>
                <AlertBanner
                    showAlert={true}
                    icon="warning"
                    type='error'
                    message={"Add out-of-estate ownership to this account."}
                />
                </div>
            )
        } else if (ownershipOnlyInEstate) {
            return (
                <div className={"paddingbottom-lg"}>
                    <AlertBanner
                        icon="info"
                        type='info'
                        className={"ownership-icon"}
                        showAlert={true}
                    >
                        <div style={{color: "black", paddingLeft: "2px", fontWeight: "normal"}}>Add out-of-estate ownership to this account</div>
                    </AlertBanner>
                </div>
            )
        }
    }


    const handleChangeAccount = (updatedAccount: PartiallyOwnedInvestmentAccountWriteModel) => {
        setAccount(updatedAccount);
    };

    if (isLoading || !memberGroup || !legalEntities || !classifications) {
        return (<LoadingIndicator/>);
    }
    return (
        <div className="edit-partially-owned-investment">
            <DataEntryHeader
                title={(assetId ? "Edit " : "Add ") + shortenName(account.legalAgreementName,50)}
                primaryButtonText="Save"
                secondaryButtonText="Cancel"
                onPrimaryButtonClick={handleClickOnSave}
                onSecondaryButtonClick={handleClickOnCancel}
                disablePrimaryButton={isSaveButtonDisabled}
            />
            <RequiredFieldsBanner showAlert={isRequiredFieldsBannerShown} message={requiredFieldsBannerMessage}
                                  itemType="Partially Owned Investment Account" />
            {!isOutOfEstateOwnershipZero && ownershipOnlyInEstate &&
                <div className="paddingleft-xxxl paddingright-xxxl">
                    <AlertBanner
                        type='warning'
                        icon='info'
                        showCloseBtn={true}
                        onClose={handleBannerClose}
                        className={"required-field-secondary-text"}
                        showAlert={initialLoading}
                        message={"Add out-of-estate ownership to this account."}
                    />
                </div>
            }
            <div className="partially-owned__form layout-data-entry-form">
                <article>
                    <section>
                        <PartiallyOwnedInvestmentAccountDetails
                            account={account}
                            onChangeAccount={handleChangeAccount}
                        />

                        {(account.taxStatus === "Taxable" || account.taxStatus === "Deferred") &&
                            <TaxDetails taxDetails={taxDetails}
                                        updateTaxDetails={handleTaxDetailsChange}
                                        taxStatus={account.taxStatus}
                                        isRequiredFieldsBannerShown={false}
                                        unrealizedCapitalGainsTax={unrealizedCapitalGainsTax}
                                        deferredTaxLiability={deferredTaxLiability}
                                        totalInvestablePresentValue={totalInvestablePresentValue}
                                        handleUnrealizedCapitalGainsTaxChange={NO_OP}
                                        handleLiabilityPaidByPortfolioChange={NO_OP}
                            />
                        }
                    </section>
                    <Ownership
                        onFormDataChange={updateOwnershipDetailsFormData}
                        formData={ownershipDetailsFormData}
                        totalAssetValue={totalMarketValue}
                        isOwnershipPercentageErrorBannerShown={isOwnershipLessThan100}
                        memberGroup={memberGroup}
                        legalEntities={legalEntities}
                        updateLegalEntities={setLegalEntities}
                        isPartiallyOwned={true}
                        subheader={renderZeroOutOfEstateAlertSubheader()}
                        taxDetails={taxDetails}
                    />
                </article>
                <AccountSummary
                    assetType={'partiallyOwnedInvestmentAccount'}
                    holdings={domesticTrustHoldings}
                    onClick={handleViewHoldingsClick}
                    unrealizedCapitalGainsTax={unrealizedCapitalGainsTaxForAccountSummary}
                    deferredTaxLiability={domesticTrustHoldings?.deferredTaxLiability}
                    classifications={classifications}
                />
            </div>
            <DiscardAssetModal
                isOpen={showDiscardChangesModal}
                title={`Discard changes to this Asset?`}
                content={`Any data entered for this asset will not be saved.`}
                onClickKeepEditing={() => setShowDiscardChangesModal(false)}
                onClickDiscardChanges={navigateToAssetsView}
            />
        </div>
    )
}

function mapToFormData(partiallyOwnedLegalAgreement: PartiallyOwnedLegalAgreement): PartiallyOwnedLegalAgreementFormData {
    return {
        ...partiallyOwnedLegalAgreement,
        ...mapToOwnershipDetailsFormData(partiallyOwnedLegalAgreement),
    };
}