import DataEntryHeader from "../../../components/DataEntry/DataEntryHeader";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithProfileIdAndAssetId} from "../../../routes/types";
import AssetsViewContext from "../../common/AssetsViewContext";
import {assetsApiClient} from "../../AssetsApiClient";
import {BankAccountSearchResults} from "./BankingAccountSearchResults";
import {BankingAccountReadModel, BankingAccountWriteModel} from "../../models/BankingAccount";
import {BankingAccountSearch} from "./BankingAccountSearch";
import {useAppSelector} from "../../../store/hooks";
import {
    selectProfile,
    selectResourceCode
} from "../../../ClientManagement/ClientProfile/activeProfileSlice";
import {ProfileType} from "../../../ClientManagement/models/ProfileResponse";
import {Icon} from "../../../components";
import {COLOR_NT_AQUA_800} from "../../../constants/colors";
import {EmptyStateContainer} from "../../../components";
import LoadingIndicator from "../../../pages/LoadingIndicator";

export function AddBankingAccount() {
    const history = useHistory();
    const {profileId} = useParams<RouteWithProfileIdAndAssetId>();
    const viewType = useContext(AssetsViewContext);
    const [bankingAccounts, setBankingAccounts] = useState<BankingAccountReadModel[]>();
    const [searchedBankAccount, setSearchedBankAccount] = useState<BankingAccountReadModel[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const componentUnmounted = useRef(false);
    const resourceCode = useAppSelector(selectResourceCode);
    const profile = useAppSelector(selectProfile);
    const isUlekPresent: boolean = resourceCode?.length > 0;


    useEffect(() => {
        return () => {
            componentUnmounted.current = true;
        }
    }, []);

    useEffect(() => {
        let isProfile: boolean = profile?.type === ProfileType.PROFILE;
        if (profileId && isUlekPresent) {
            assetsApiClient.getBankingAccounts(profileId, resourceCode, isProfile)
                .then(bankingResponse => {
                    setBankingAccounts(bankingResponse);
                    setSearchedBankAccount(bankingResponse);
                })
                .catch(error => {
                    console.error('Could not fetch banking accounts', error.message)
                }).finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }

    }, [profileId]);

    const handleClickOnCancel = () => {
        history.push(`/Profile/${profileId}/ClientProfile/${viewType}`);
    };

    const handleSearch = (search: string) => {
        const searchAccount = bankingAccounts?.filter(bankingAccount => bankingAccount.accountNumber === search);
        setSearchedBankAccount(searchAccount);
    }

    const handleSearchCleared = () => {
        setSearchedBankAccount(bankingAccounts);
    }
    const onSave = (accountsToSave: BankingAccountWriteModel[]) => {
        if (accountsToSave) {
            assetsApiClient.postBankingAccounts(profile.id, accountsToSave)
                .then(response => {
                    if (response.status === 200) {
                        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
                    }
                });
        }
    }

    if (isLoading) {
        return <LoadingIndicator className="banking-accounts-loading-indicator"/>;
    }

    function navigateToProfileSettingsPage() {
        history.push(`/Profile/${profileId}/ClientProfile/ProfileSettings`);
    }

    return (
        <div className="banking-account">
            <DataEntryHeader
                title="Add Northern Trust Bank Account"
                secondaryButtonText="Cancel"
                onSecondaryButtonClick={handleClickOnCancel}
                hidePrimaryButton
            />
            <div className="banking-account-container">
                <div className="search-bar-container marginbottom-lg">
                    <BankingAccountSearch
                        onSearch={handleSearch}
                        onSearchCleared={handleSearchCleared}
                    />
                </div>
                {
                    isUlekPresent && searchedBankAccount && searchedBankAccount.length > 0 &&
                    <BankAccountSearchResults bankingAccounts={searchedBankAccount} onSave={onSave}/>
                }
                {isUlekPresent && !searchedBankAccount?.length && bankingAccounts?.length &&
                    <EmptyStateContainer className="no-bank-accounts" title={'No matching accounts were found.'}
                                         size="large"/>
                }

                {!isUlekPresent &&
                    <div aria-label="No Ulek linked" role="info" className="banking-no-ulek-alert">
                        <span>
                            <Icon className="beneficiary-allocation-info-icon" name="status_warning"
                                  ariaHidden={false}
                                  ariaLabel="Invalid beneficiary allocation"
                                  type={"warning"}/>
                            Profile is not linked to Partner Workstation</span>

                        {profile.type === ProfileType.PROFILE && <span onClick={navigateToProfileSettingsPage}>
                                    LINK IN PROFILE SETTINGS</span>}
                    </div>
                }

                {
                    isUlekPresent && !bankingAccounts?.length &&
                    <div aria-label="No Ulek linked" role="info" className="banking-no-bank-acc-linked-alert">
                    <span>
                        <Icon className="beneficiary-allocation-info-icon" name={`info`}
                              ariaHidden={false}
                              ariaLabel="Invalid beneficiary allocation"
                              type={"info"}
                              color={`#00A0AA`}/>

                        &nbsp; No Northern Trust Bank Accounts associated with Partner WorkStation profile.
                    </span>
                    </div>
                }
            </div>
        </div>
    );
}