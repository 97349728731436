import React, {useEffect} from "react";
import {useAppSelector} from "../../store/hooks";
import {selectPortfolioReserveModel} from "./PortfolioReserveSlice";
import {portfolioReserveIsInvestablySufficient} from "../../Portfolio/portfolioUtils";
import {selectParticipatingInMeeting} from "../../ClientManagement/Meeting/meetingSlice";
import {useAppInsights} from "../../AppInsights";
import {MeetingParticipation} from "../../ClientManagement/Meeting/Meeting";
import {GenericErrorModalData} from "../../components/Modal/Error/GenericErrorModal";
import {LifestyleSpendingGoal, LifestyleSpendingPeriodInputs} from "../models/LifestyleSpendingGoal";
import goalUtils from "../Summary/GoalUtils";
import PrintViewWrapper from "../../Reporting/DetailedEmailReports/PrintViewWrapper";
import {ProfileResponse} from "../../ClientManagement/models/ProfileResponse";
import {PortfolioReserveResponse} from "../models/PortfolioReserve";
import ReviewPortfolioReserveContent from "./ReviewPortfolioReserveContent";


type ReviewPortfolioReserveProps = {
    errorModelData?: GenericErrorModalData,
    showTargetLabel?: boolean
    isReportingCall?: boolean
}

const ReviewPortfolioReserve = ({
                                    errorModelData,
                                    showTargetLabel = true,
                                    isReportingCall = false
                                }: ReviewPortfolioReserveProps) => {
    const participatingInMeeting = useAppSelector(selectParticipatingInMeeting)!;
    const appInsights = useAppInsights();
    const portfolioReserveModel = useAppSelector(selectPortfolioReserveModel);
    const {
        portfolioReserve,
        profile,
        lifestyleSpendingGoal,
        assets,
        normalizedNonLifestyleGoals,
    } = portfolioReserveModel;

    const fullPortfolioReserveTargetLength = getFullPortfolioReserveTargetLength(profile, portfolioReserve);
    const portfolioReserveTargetLength = getPortfolioReserveTargetLength(profile, fullPortfolioReserveTargetLength);


    useEffect(() => {
        // Anything in here is fired on component mount.
        const startTime: number = new Date().valueOf();
        return () => {
            // Anything in here is fired on component unmount.
            const endTime: number = new Date().valueOf();
            appInsights.trackEvent({
                name: 'ReviewPortfolioReserve',
                properties:
                    {
                        screen: 'Review Portfolio Reserve',
                        action: 'Time Spent',
                        meetingStatus: getMeetingStatus(participatingInMeeting),
                        data: {
                            timeSpent: endTime - startTime // Gives the time Spent in milliseconds
                        }
                    }
            })
        }
    }, [])

    const portfolioIsInvestablySufficient = portfolioReserveIsInvestablySufficient({
        ...portfolioReserveModel,
        lifestylePresentValue: portfolioReserveModel.lifestyleSpendingGoal.calculatedFields,
        totalInvestableValue: assets.totalInvestableValue
    });

    const pages = getPages(isReportingCall, lifestyleSpendingGoal, portfolioReserveTargetLength);

    return (
        isReportingCall ?
            <>{pages.map((pageNumber) => {
                return (
                    <PrintViewWrapper displayName={profile.displayName} key={pageNumber}>
                        <ReviewPortfolioReserveContent
                            portfolioReserve={portfolioReserve}
                            profile={profile}
                            lifestyleSpendingGoal={lifestyleSpendingGoal}
                            assets={assets}
                            nonLifestyleTotalPresentValue={normalizedNonLifestyleGoals.nonLifestyleTotalPresentValue}
                            showTargetLabel={showTargetLabel}
                            portfolioIsInvestablySufficient={portfolioIsInvestablySufficient}
                            protectedPeriods={getProtectedPeriods(lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods, portfolioReserveTargetLength)}
                            portfolioReserveTargetLength={portfolioReserveTargetLength}
                        />
                    </PrintViewWrapper>
                )
            })}
            </>
            : <>
                <ReviewPortfolioReserveContent
                    portfolioReserve={portfolioReserve}
                    profile={profile}
                    lifestyleSpendingGoal={lifestyleSpendingGoal}
                    assets={assets}
                    nonLifestyleTotalPresentValue={normalizedNonLifestyleGoals.nonLifestyleTotalPresentValue}
                    showTargetLabel={showTargetLabel}
                    portfolioIsInvestablySufficient={portfolioIsInvestablySufficient}
                    protectedPeriods={getProtectedPeriods(lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods, portfolioReserveTargetLength)}
                    portfolioReserveTargetLength={portfolioReserveTargetLength}
                />
            </>
    );
};

const getProtectedPeriods = (
    spendingPeriods: LifestyleSpendingPeriodInputs[],
    portfolioReserveTargetLength: number
) => {

    if (portfolioReserveTargetLength === 0) {
        return [];
    }

    const roundedActivatedPREndYear = Math.ceil(spendingPeriods[0].startYear + portfolioReserveTargetLength);
    const partialDrawdownYearAmount = Math.ceil(portfolioReserveTargetLength) - portfolioReserveTargetLength

    const protectedPeriods = spendingPeriods
        .filter((period) => period.startYear < roundedActivatedPREndYear)
        .map((period) => {
            return {
                ...period,
                endYear: Math.min(period.endYear, roundedActivatedPREndYear)
            }
        });
    if (protectedPeriods.length > 0) {
        protectedPeriods[0] = {
            ...protectedPeriods[0],
            startYear: spendingPeriods[0].startYear + partialDrawdownYearAmount
        }
    }
    return protectedPeriods;
}

const getMeetingStatus = (participatingInMeeting: boolean) => {
    return participatingInMeeting ? MeetingParticipation.IN_MEETING : MeetingParticipation.OUT_OF_MEETING
};

const getPages = (isReportingCall: boolean, lifestyleSpendingGoal: LifestyleSpendingGoal, portfolioReserveTargetLength: number) => {
    let pages: number[] = [1];
    if (isReportingCall === true && getProtectedPeriods(lifestyleSpendingGoal.userInputs.lifestyleSpendingPeriods, portfolioReserveTargetLength).length > 5) {
        pages = [1, 2];
    }
    return pages;
};

const getFullPortfolioReserveTargetLength = (profile: ProfileResponse, portfolioReserve: PortfolioReserveResponse) => {
    return profile.portfolioReserveTargetLength ?? portfolioReserve.portfolioReserveTargetLength;
};

const getPortfolioReserveTargetLength = (profile: ProfileResponse, fullPortfolioReserveTargetLength: number) => {
    return profile.activePortfolioReserve ? goalUtils.getRemainingPortfolioReserveLength(profile.portfolioReserveTargetLength, profile.portfolioReserveActivationDate) : fullPortfolioReserveTargetLength;
};

export default ReviewPortfolioReserve;
