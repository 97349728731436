import ModalWrapper from "../../components/Modal/ModalWrapper/ModalWrapper";
import React, {ChangeEvent, useEffect, useState} from "react";
import {FamilyGoalType} from "../models/FamilyGoalType";
import {FamilyAssets} from "../../Assets/models/FamilyAssets";
import {formatCurrency, formatCurrencyToNumber} from "../../utils/format";
import {CurrencyInput} from "../../components";
import Icon from "../../components/Icon/Icon";
import {GoalSubType} from "../models/GoalType";

type ApplyFamilyAssetsModalProps = {
    isOpen: boolean,
    onCancel: () => void,
    onApply: (familyGoal: FamilyGoalType, amountToApply: number, selectedGoalType?: GoalSubType) => void,
    familyGoal: FamilyGoalType,
    familyAssets: FamilyAssets,
    availableAssets: number,
}
const ApplyFamilyAssetsModal = ({
                                    isOpen,
                                    onCancel,
                                    onApply,
                                    familyGoal,
                                    familyAssets,
                                    availableAssets: initialAvailableAssets
                                }: ApplyFamilyAssetsModalProps) => {

        const [familyAssetAmount, setFamilyAssetAmount] = useState(familyGoal.familyAssetsAmount || 0);
        const [showAlert, updateShowAlert] = useState<boolean>(false);
        const remainingAvailableAssets = initialAvailableAssets + (familyGoal.familyAssetsAmount || 0) - familyAssetAmount;

        useEffect(() => {
            if (showAlert) {
                const timeout = setTimeout(() => updateShowAlert(false), 6000);
                return () => clearTimeout(timeout);
            }
        }, [showAlert])

        const handleChangeValue = (_event: ChangeEvent<HTMLInputElement>, value: number | string) => {
            if (isNaN(value as number)) {
                setFamilyAssetAmount(0);
            } else {
                setFamilyAssetAmount(value as number);
            }
        }

        const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            if (value === undefined || value === "") {
                setFamilyAssetAmount(0);
                return;
            }

            if (remainingAvailableAssets < 0) {
                adjustFamilyAssetAmountAndShowAlert();
            } else {
                updateShowAlert(false);
                setFamilyAssetAmount(formatCurrencyToNumber(value));
            }
        }

        const handleApply = () => {
            onApply(familyGoal, familyAssetAmount, familyGoal.userInputs.selectedGoalSubType);
        }

        const handleCancel = () => {
            setFamilyAssetAmount(familyGoal.familyAssetsAmount || 0);
            updateShowAlert(false);
            onCancel();
        }

        const adjustFamilyAssetAmountAndShowAlert = () => {
            const adjustedValue = initialAvailableAssets + (familyGoal.familyAssetsAmount || 0);
            setFamilyAssetAmount(adjustedValue);
            updateShowAlert(true);
        }

        const message = `Use family assets to fund the ${familyGoal.userInputs.description} goal `
            + `(present value ${familyGoal.calculatedFields.presentValue > 0
                ? formatCurrency(familyGoal.calculatedFields.presentValue + (familyGoal.familyAssetsAmount || 0))
                : formatCurrency(familyGoal.calculatedFields.goalsPresentValue)}).`;

        return (
            <ModalWrapper
                id="apply-family-assets-modal"
                isOpen={isOpen}
                headerText={`Apply Family Assets`}
                buttons={[
                    {
                        text: "CANCEL",
                        onClick: handleCancel
                    },
                    {
                        text: "APPLY",
                        onClick: handleApply,
                        primary: true,
                    },
                ]}
            >
                <div className="font-md">
                    {message}
                </div>
                <div className="display-flex">
                    <h4>Apply Family Assets</h4>
                    <div className="padding-md" style={{fontSize: "15px"}}>
                        <CurrencyInput
                            name="familyAssetInput"
                            value={familyAssetAmount}
                            onChangeValue={handleChangeValue}
                            onBlur={handleBlur}
                            className={"input--width-large"}
                            error={showAlert ? " " : undefined}
                        />
                        {showAlert &&
                            <label className="color-text--error apply-family-asset-modal">
                                <Icon
                                    ariaHidden
                                    name="warning"
                                    className={"paddingright-md"}
                                />
                                <span>Applied Assets were adjusted to match the family member’s available assets.</span>
                            </label>
                        }
                        <div className="display-flex paddingtop-md" style={{fontSize: "15px", color: "#767676"}}>
                            <div className="display-flex flex-column align-items-end paddingright-md">
                                <label className="paddingbottom-sm">{formatCurrency(remainingAvailableAssets)}</label>
                                <label>{formatCurrency(familyAssets.totalAssets)}</label>
                            </div>
                            <div>
                                <label className="paddingbottom-sm">Available Assets</label>
                                <label>Total Assets</label>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalWrapper>

        )
    }
;

export default ApplyFamilyAssetsModal;