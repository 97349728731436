import {AssetsSummary} from "../../models/Assets";
import {InvestorGroupType, MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import {formatCurrency} from "../../../utils/format";
import {PageActionMenu} from "../../../components";
import AddAssetButtonDropdown from "../common/AddAssetButtonDropdown";
import LiabilityList from "./LiabilityList";
import React from "react";
import {hasInEstateAssets, hasInEstateLiabilities} from "../common/utils";
import {DeleteModalProps} from "../../common/AssetsView";

export interface LiabilitiesSummaryProps {
    assets: AssetsSummary;
    investorGroup: InvestorGroupType;
    memberGroup: MemberGroup;
    onDeleteAssetClick: (deleteModalProps: DeleteModalProps) => void;
}

const LiabilitiesSummary = ({assets, investorGroup, memberGroup, onDeleteAssetClick}: LiabilitiesSummaryProps) => <>
    <div className="section-header">
        <h3>
            <div>
                You have <b>{formatCurrency(assets.liabilitiesValue.inEstateValue)}</b> in personal
                liabilities:
            </div>
            {!hasInEstateAssets(assets) && !hasInEstateLiabilities(assets) &&
                <div className="condensed-subtitle">Begin by entering assets or entities</div>
            }
        </h3>
        {!hasInEstateAssets(assets) &&
            <div>
                <PageActionMenu className="marginright-md"/>
                <AddAssetButtonDropdown assets={assets}
                                        investorGroup={investorGroup}
                                        memberGroup={memberGroup}
                />
            </div>
        }
    </div>
    {hasInEstateLiabilities(assets) &&
        <LiabilityList liabilitiesData={assets} onDeleteAssetClick={onDeleteAssetClick}/>
    }
</>;

export default LiabilitiesSummary;
