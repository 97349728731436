import {GeneralInflow} from "../../models/GeneralInflow";
import {DeleteModalProps} from "../../common/AssetsView";
import React from "react";
import {Icon} from "../../../components";
import DragDrop from '@nt/dds-react/core/DragDrop';
import {DropResult} from "react-beautiful-dnd";
import {sortByOrdinalAndPresentValue} from "../common/utils";
import {NO_OP} from "../../../constants/common";
import {GeneralInflowTableRow} from "./GeneralInflowTableRow";

export type GeneralInflowTableProps = {
    generalInflows: GeneralInflow[];
    onEdit: (assetId: string) => void;
    onSaveReviewTrustDisclosure: (generalInflow: GeneralInflow) => void;
    onDelete: (deleteModalProps: DeleteModalProps) => void;
    hideActionMenu?: boolean;
    isDraggable?: boolean;
    captureGeneralInflowsOrder: ({source, destination}: DropResult) => void
}

export function GeneralInflowTable({
                                       generalInflows,
                                       onEdit,
                                       onSaveReviewTrustDisclosure = NO_OP,
                                       onDelete,
                                       hideActionMenu = false,
                                       isDraggable = false,
                                       captureGeneralInflowsOrder
                                   }: GeneralInflowTableProps) {

    const sortedGeneralInflows = sortByOrdinalAndPresentValue(generalInflows) as GeneralInflow[]

    function handleDelete(assetId: string, description: string) {
        onDelete({
            showDeleteModal: true,
            modalTitle: "Asset",
            assetDescription: description,
            assetType: "general inflow",
            assetId: assetId,
        })
    }

    if (isDraggable) {
        return <>
            <div role="row" className="grid-display-contents asset-row-subheader">
                <span role="cell" className="textalign-left grid-span-3 cell-text-content"><b>General Inflows</b></span>
            </div>
            <DragDrop
                className="draggable"
                direction="vertical"
                draggableId="generalInflowDraggableId1"
                droppableId="generalInflowDroppableId1"
                onDragEnd={captureGeneralInflowsOrder}
                items={
                    sortedGeneralInflows?.map((generalInflow) =>
                        <div className="display-flex align-items-right" tabIndex={-1} key={generalInflow.id}>
                            <Icon ariaHidden className="drag__icon" name="drag_vertical"/>
                            <GeneralInflowTableRow
                                key={generalInflow.id}
                                generalInflow={generalInflow}
                                onClickEdit={onEdit}
                                onClickDelete={handleDelete}
                                onSaveReviewTrustDisclosure={onSaveReviewTrustDisclosure}
                                hideActionMenu={hideActionMenu}
                            />
                        </div>)
                }
            >
            </DragDrop>
        </>
    }
    return <>
        <div role="row" className="grid-display-contents asset-row-subheader">
            <span role="cell" className="textalign-left grid-span-3 cell-text-content"><b>General Inflows</b></span>
        </div>
        {
            sortedGeneralInflows?.map((generalInflow) =>
                <GeneralInflowTableRow
                    key={generalInflow.id}
                    generalInflow={generalInflow}
                    onClickEdit={onEdit}
                    onClickDelete={handleDelete}
                    onSaveReviewTrustDisclosure={onSaveReviewTrustDisclosure}
                    hideActionMenu={hideActionMenu}
                />
            )
        }
    </>;
}



