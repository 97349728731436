import React from "react";
import {useAppSelector} from "../../store/hooks";
import {selectGoalModel} from "../../Goals/controller/GoalsModelSlice";
import {GoalModelType} from "../../Goals/models/GoalModelType";
import {getMember} from "./PlanningPeriod";

const LifestylePlanningPeriod = () => {
    const { investorGroup } = useAppSelector<GoalModelType>(selectGoalModel)
    return (
        <>
            <div className="lifestyle-planning-period">
                <span role="note" aria-label="planningPeriod">
                    Planning Period: {getMember(investorGroup)}
                </span>
            </div>
        </>
    );
}

export default LifestylePlanningPeriod;
