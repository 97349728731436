import {Icon} from "../../../components";
import React, {useState} from "react";

type AssetListHeaderProps = {
    columnTitles: string[],
    className?: string,
    gridSpanClass?: string,
    testId?: string,
    onExpandedChanged: Function,
    initialIsExpanded?: boolean
}

export function AssetListHeader({
                                    columnTitles,
                                    className = 'assets-grid',
                                    gridSpanClass = 'grid-span-3',
                                    testId,
                                    onExpandedChanged,
                                    initialIsExpanded = false
                                }: AssetListHeaderProps) {

    const [isExpanded, updateIsExpanded] = useState(initialIsExpanded);

    const handleOnClick = () => {
        updateIsExpanded(!isExpanded)
        onExpandedChanged(!isExpanded)
    };

    return (
        <div className={`${className} assets-grid-table paddingleft-12`.trim()} data-testid={testId}>
            <span className={`"display-flex align-items-center paddingleft-md marginbottom-md cursor-pointer ${gridSpanClass}`.trim()}
                  onClick={handleOnClick}
                  role="button"
                  aria-pressed={isExpanded ? 'true' : 'false'}
            >
                <Icon name={isExpanded ? "chevron_double_down" : "chevron_double_right"}/>
                <span className="condensed-subtitle paddingleft-md">{columnTitles[0]}</span>
            </span>
            {
                columnTitles.slice(1).map((columnTitle, index) => {
                    return <span key={`${className}-col-${index}`} role="cell" className="condensed-subtitle textalign-right">{columnTitle}</span>
                })
            }
        </div>
    );
}
