import {formatCurrency} from "../../../utils/format";
import CustomModal from "../../../components/Modal/Custom/CustomModal";
import React from "react";
import {InvestmentProgram} from "../../models/InvestmentProgram";
import {useAppSelector} from "../../../store/hooks";
import {selectReleaseToggles} from "../../../ReleaseToggles/releaseTogglesSlice";

type ConfirmationModalProps = {
    investmentProgram: InvestmentProgram | undefined,
    onConfirm: (investmentProgram: InvestmentProgram) => void,
    onCancel: () => void
}
const ConfirmationModal = ({
    investmentProgram,
    onConfirm,
    onCancel
}: ConfirmationModalProps) => {

    const releaseToggles = useAppSelector(selectReleaseToggles);
    if (!investmentProgram) {
        return null;
    }

    return (
        <CustomModal
            isOpen={Boolean(investmentProgram)}
            title={
                <h1 className="h3 display-inlineblock">
                    {`Add ${investmentProgram?.name} Investment Program to this profile?`}
                </h1>
            }
            size={'large'}
            content={
                <div className='investment-programs font-default'>
                    {investmentProgram?.legalAgreements && investmentProgram.legalAgreements.length > 0 &&
                        <>
                            <div className="add-investment-program-dialog-grid">
                                <span className="fontweight-500">Investment Program Legal Agreements</span>
                                <span className="textalign-right fontweight-500">Legal Agreement #</span>
                                <span className="textalign-right fontweight-500">Market Value</span>
                            </div>
                            {investmentProgram.legalAgreements.map(legalAgreement => (
                                <div className="add-investment-program-dialog-grid"
                                     key={legalAgreement.legalAgreementNumber}>
                                    <span>{legalAgreement.name}</span>
                                    <span
                                        className="textalign-right">{legalAgreement.legalAgreementNumber}</span>
                                    <span
                                        className="textalign-right">{formatCurrency(legalAgreement.marketValue)}</span>
                                </div>
                            ))}
                        </>
                    }
                    {releaseToggles?.enableHeldAwayAccountForAsset && investmentProgram?.heldAwayAccounts && investmentProgram.heldAwayAccounts.length > 0 &&
                        <>
                            <div className="add-investment-program-dialog-grid">
                                <span className="fontweight-500">Held Away Account Name</span>
                                <span className="textalign-right fontweight-500">Account #</span>
                                <span className="textalign-right fontweight-500">Market Value</span>
                            </div>
                            {investmentProgram.heldAwayAccounts.map(heldAwayAccount => (
                                <div className="add-investment-program-dialog-grid"
                                     key={heldAwayAccount.financialAccountNumber}>
                                    <span>{heldAwayAccount.financialAccountName}</span>
                                    <span
                                        className="textalign-right">{heldAwayAccount.financialAccountNumber}</span>
                                    <span
                                        className="textalign-right">{formatCurrency(heldAwayAccount.baseMarketValue)}</span>
                                </div>
                            ))}
                        </>
                    }
                </div>
            }
            onClickConfirm={() => onConfirm(investmentProgram)}
            onClickCancel={onCancel}
            confirmText={'Add Investment Program'}
            cancelText={'Cancel'}
            confirmButtonDestructive={false}
        />
    )
}

export default ConfirmationModal;        