import {EstateValue, HoldingAssetSubclassDetails} from "../../models/Holding";
import React from "react";
import {EditHoldingValues} from "./EditHoldingValues";
import {AssetSubclass} from "../../models/AssetClassifications";

type Props = {
    assetSubclass: AssetSubclass,
    holdingsWithIndex: { holding: HoldingAssetSubclassDetails; index: number }[],
    subclassIndex: number,
    updateEditableHoldings: (value: number | null | EstateValue,
                             index: number,
                             valueType: 'taxCost' | 'investableValue' | 'marketValue' | 'marketEstateValue') => void,
    inEstateOwnershipPercentage: number
};

function getHoldingToEdit(subclassHoldings: { holding: HoldingAssetSubclassDetails; index: number }[]) {
    if (subclassHoldings.length > 1) {
        const holdings = subclassHoldings.map(holding => holding.holding);

        const mergedHolding = holdings.reduce((totals, currentHolding) => {
            return {
                taxCost: totals.taxCost + (currentHolding.taxCost ?? 0),
                marketValue: totals.marketValue + (currentHolding.marketValue ?? 0),
                investableValue: totals.investableValue + (currentHolding.investableValue ?? 0)
            };
        }, {
            taxCost: 0,
            marketValue: 0,
            investableValue: 0
        });

        return {holding: {...holdings[0], ...mergedHolding}, index: 0};
    } else {
        return subclassHoldings[0];
    }
}

export const EditSubclassHolding = ({
                                        assetSubclass,
                                        holdingsWithIndex,
                                        subclassIndex,
                                        updateEditableHoldings,
                                        inEstateOwnershipPercentage
                                    }: Props) => {
    const assetSubclassDetailsIds = assetSubclass.assetSubclassDetails
        .map(assetSubclassDetails => assetSubclassDetails.assetSubclassDetailsId);

    const subclassHoldings = holdingsWithIndex
        .filter(({holding}) => assetSubclassDetailsIds.includes(holding.assetSubclassDetailsId));

    if (subclassHoldings.length === 0) {
        return <></>;
    }

    return (
        <div key={`asset-subclass-${subclassIndex}`} className="holding-grid">
            <div className="grid-display-contents holding-subclass-row" role="row"
                 aria-label={assetSubclass.assetSubclassName}>
                <span className="line"/>
                <span role="cell" aria-label={`${assetSubclass.assetSubclassName}`}
                      className="holding-subclass-name fontweight-medium holding-grid-column-custom-span">{assetSubclass.assetSubclassName}</span>
                <EditHoldingValues
                    holdingWithIndex={getHoldingToEdit(subclassHoldings)}
                    label={assetSubclass.assetSubclassName}
                    onUpdate={updateEditableHoldings}
                    inEstateOwnershipPercentage={inEstateOwnershipPercentage}
                    readOnly={subclassHoldings.length > 1}
                />
                <span role="cell" className="empty-grid-column"/>
            </div>
        </div>
    )
};
