import {Button} from "../Button";
import React from "react";

type InfoPopoverPRStatusProps = {
    onClick: () => void,
    showPopover: boolean,
    onClickMonitorPortfolioReserve: () => void,
    onClose: () => void
}
const InfoPopoverPRStatus = ({
                              onClick,
                              showPopover,
                              onClickMonitorPortfolioReserve,
                              onClose
                          }: InfoPopoverPRStatusProps) => {

    return (
        <>
            <div className={"activate-pr-tooltip_button"} data-testid={"activate-pr-tooltip-button"}
                 style={{paddingLeft: "8px"}}
                 onClick={onClick}>
                <i className="dds-icons icon--warning color-text--error icon--size-16">info</i>
            </div>
            {showPopover &&
                <div className={"activate-pr-tooltip"} style={{marginTop: "1px"}}
                     data-testid={"activate-pr-tooltip"}>
                    <div className="marginbottom-lg activate-pr-tooltip_popover-content" style={{width: "250px"}}>
                        <div>
                            Changes you make
                            while the Portfolio Reserve is on may impact the expected drawdown and proposed allocation.
                            Please view the changes on the&nbsp;<a href="#"
                                                                   onClick={onClickMonitorPortfolioReserve}>Monitor
                            Portfolio Reserve page.</a>
                        </div>
                        <div>
                            <Button
                                icon="only"
                                iconName="close"
                                kind="borderless"
                                onClick={onClose}
                            />
                        </div>
                    </div>

                </div>
            }
        </>

    )

}

export default InfoPopoverPRStatus;