import React from "react";
import {PrimaryContact} from "../models/PrimaryContact";
import {toDisplayDateFormat} from "../../utils/dateUtils";
import {Link, useHistory} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    clearPartnerWorkstationState,
    selectLinkedClientName,
    selectLinkedUlek
} from "../PartnerWorkstation/partnerWorkstationSlice";
import {selectProfile, setResourceCode} from "../ClientProfile/activeProfileSlice";
import {Button} from "../../components";
import useProfileEditableState from "../../hooks/useProfileEditableState";

type PrimaryContactProps = {
    primaryContact: PrimaryContact,
    showPartnerWorkstationLink?: boolean,
}

export const PrimaryContactInformation = ({primaryContact, showPartnerWorkstationLink = true}: PrimaryContactProps) => {

    const profile = useAppSelector(selectProfile);
    const profileId = profile ? profile.id : '';
    const history = useHistory();
    const dispatch = useAppDispatch();
    const linkedUlek = useAppSelector(selectLinkedUlek);
    const linkedClientName = useAppSelector(selectLinkedClientName);
    const {isArchivedActiveClient, isArchiveReadOnly} = useProfileEditableState();

    const handleLinkExistingClient = () => {
        history.push(`/PartnerWorkstation`)
    }

    const handleRemoveLink = () => {
        dispatch(clearPartnerWorkstationState());
        dispatch(setResourceCode(''));
    }

    const hasLinkedClient = (): boolean => {
        return linkedUlek !== '';
    }

    return (
        <div className="primary-contact-info">
            <div className="primary-contact-info__header">
                <div className="h4 sections primaryContactLabel">Primary Contact Information</div>
                <div className="family-tree-link">Edit in{' '}
                    <Link role="link" to={{
                        pathname: `/Profile/${profileId}/ClientProfile/FamilyTree`,
                        state: {editPrimaryContact: true}
                    }}>
                        Family Tree
                    </Link>
                </div>
            </div>

            <hr className="section"/>

            {showPartnerWorkstationLink && <div className="h4 parent">
                <label className="h5 label" htmlFor="linkedPartner">Linked Partner Workstation Client</label>
                <div className="detail" aria-label="linkedPartner">
                    {hasLinkedClient() &&
                        <div className="linked-partner-full-name remove-button" aria-label="linkedPartnerName">
                            {linkedClientName}
                            {!isArchivedActiveClient && <Button
                                kind="borderless"
                                onClick={handleRemoveLink}
                            >
                                Remove Link
                            </Button>}
                        </div>
                        ||
                        <div className="linked-partner-full-name">
                            <span><i>No client linked.</i></span>
                            {!isArchiveReadOnly && <Button
                                icon="left"
                                iconName="link"
                                kind="borderless"
                                onClick={handleLinkExistingClient}
                            >
                                Link Existing Client
                            </Button>}
                        </div>
                    }
                </div>
            </div>}
            <div className="h4 parent">
                <label className="h5 label" htmlFor="fullName">Full Name</label>
                <div className="detail input-wrapper input-inline-align--right" aria-label="fullName">
                    {primaryContact.firstName + ' ' + primaryContact.lastName}
                </div>
            </div>
            <div className="h4 parent">
                <label className="h5 label">Age</label>
                <div className="detail input-wrapper input-inline-align--right" aria-label="age">
                    {primaryContact.age ? primaryContact.age : ''}
                </div>
            </div>
            <div className="h4 parent">
                <label className="h5 label">Birthdate</label>
                <div className="detail input-wrapper input-inline-align--right" aria-label="birthdate">
                    {primaryContact.birthdate ? toDisplayDateFormat(primaryContact.birthdate) : ''}
                </div>
            </div>
            <div className="h4 parent">
                <label className="h5 label">Gender</label>
                <div className="detail input-wrapper input-inline-align--right" aria-label="gender">
                    {primaryContact.gender ? primaryContact.gender : ''}
                </div>
            </div>
            <div className="h4 parent">
                <label className="h5 label">State of Residency</label>
                <div className="detail input-wrapper input-inline-align--right" aria-label="stateOfResidency">
                    {primaryContact.stateOfResidency ? primaryContact.stateOfResidency : ''} &nbsp;
                </div>
            </div>
            <div className="h4 parent">
                <label className="h5 label">Personal Planning Horizon</label>
                <div className="detail input-wrapper input-inline-align--right" aria-label="personalPlanningHorizon">
                    {primaryContact.personalPlanningHorizon ? primaryContact.personalPlanningHorizon : ''}
                </div>
            </div>
        </div>
    );
};
