import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    AssetRelianceReportResponse,
    EMPTY_ASSET_RELIANCE_RESPONSE
} from "../../../ClientManagement/models/AssetRelianceResponse";
import {emptyInvestorGroup} from "../../../ClientManagement/models/InvestorGroupType";
import {RootState} from "../../../store/store";
import {defaultAssetsState} from "../../../Assets/clientAssetsSlice";

export interface AssetRelianceReportState {
    assetRelianceReportResponse: AssetRelianceReportResponse;
}

export const initialAssetRelianceReportState: AssetRelianceReportState = {
    assetRelianceReportResponse: {...EMPTY_ASSET_RELIANCE_RESPONSE,investorGroupResponse:emptyInvestorGroup}
}

export const assetRelianceReportSlice = createSlice({
    name: "assetRelianceReport",
    initialState: initialAssetRelianceReportState,
    reducers: {
        setAssetRelianceReportResponse: (state, action: PayloadAction<AssetRelianceReportResponse>) => {
            state.assetRelianceReportResponse = action.payload;
        },
        resetAssetRelianceReportState: () => initialAssetRelianceReportState
    }
});

export const {
    setAssetRelianceReportResponse,
    resetAssetRelianceReportState,
} = assetRelianceReportSlice.actions;

export const selectAssetRelianceReportResponse = (state: RootState) => state.client.assetRelianceReport.assetRelianceReportResponse;

export default assetRelianceReportSlice.reducer;