import React, {useEffect, useState} from 'react'
import {Button, Icon} from "../components";
import {Logo, Switch} from "xps-react";
import {StreamMedia, VideoTile} from "@azure/communication-react";
import {Stack} from '@fluentui/react';
import {VideoOff48Filled} from '@fluentui/react-icons';
import {useCommunicationsContext} from "../ClientManagement/Meeting/CommunicationsContext";
import {DeviceAccess} from "@azure/communication-calling";
import {ConfirmExitMeetingModal} from "../ClientManagement/Meeting/MeetingActions";
import {Meeting, MeetingStatus} from "../ClientManagement/Meeting/Meeting";
import {meetingApiClient} from "../ClientManagement/Meeting/MeetingApiClient";
import {
    resetAudience,
    resetMeeting,
    selectActiveMeeting,
    setShowMeetingControls
} from "../ClientManagement/Meeting/meetingSlice";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import useMeetingUtils from "../ClientManagement/Meeting/useMeetingUtils";
import {useRelayContext} from "../ClientManagement/Meeting/Relay/types/RelayContext";
import {clearPartnerWorkstationState} from "../ClientManagement/PartnerWorkstation/partnerWorkstationSlice";
import {useHistory} from "react-router-dom";
import LoadingIndicator from "../pages/LoadingIndicator";

type AudioVideoPreviewProps = {
    showNTLogo: boolean;
    onJoinNow: (isAudioEnabled: boolean, isCameraEnabled: boolean) => void;
    setMeetingPortalView?: () => void;
}

const AudioVideoPreview: React.FC<AudioVideoPreviewProps> = ({showNTLogo, onJoinNow, setMeetingPortalView}) => {
    const [isAudioEnabled, setIsAudioEnabled] = useState<boolean>(true);
    const [isCameraEnabled, setIsCameraEnabled] = useState<boolean>(true);
    const [deviceAccess, setDeviceAccess] = useState<DeviceAccess>({video: false, audio: false});
    const [previewTarget, setPreviewTarget] = useState<HTMLElement | null>(null);
    const [isConfirmExitMeetingModalOpen, setConfirmExitMeetingModalOpen] = useState(false);
    const [isLoadingPreview, setLoadingPreview] = useState(true);

    const history = useHistory();
    const dispatch = useAppDispatch();
    const activeMeeting = useAppSelector(selectActiveMeeting) as Meeting;
    const communicationsContext = useCommunicationsContext();

    const {isCurrentUserPresenting, isInsideMeetingPortal} = useMeetingUtils();
    const {unloadMeetingContainer} = useRelayContext();

    useEffect(() => {
        communicationsContext.getDevicePermissions().then((dA) => {
            setIsAudioEnabled(dA.audio);
            setIsCameraEnabled(dA.video);
            setDeviceAccess(dA);
        });
    }, [])

    useEffect(() => {
        if (deviceAccess.video) {
            communicationsContext.createVideoPreview().then((preview) => {
                setPreviewTarget(preview?.view?.target ?? null);
            }).finally(() => {
                setLoadingPreview(false);
            })
        } else {
            setLoadingPreview(false);
        }
    }, [deviceAccess.video])

    const CameraOffPlaceholder = (): JSX.Element => {
        return (
            <Stack style={{width: '100%', height: '100%'}} verticalAlign="center">
                <Stack.Item align="center">
                    <VideoOff48Filled primaryFill="currentColor"/>
                </Stack.Item>
            </Stack>
        );
    };

    const navigateHome = () => {
        dispatch(clearPartnerWorkstationState());
        history.push({pathname: `/Profile/${activeMeeting.profileId}`})
    }

    const handleCancelJoinMeeting = () => {
        communicationsContext.disposeVideoPreview();
        if (isInsideMeetingPortal && setMeetingPortalView) {
            communicationsContext.disconnect().then();
            setMeetingPortalView();
        } else {
            const updatedMeeting = {...activeMeeting, status: MeetingStatus.ENDED};
            (isCurrentUserPresenting
                    ? meetingApiClient.updateMeeting(updatedMeeting)
                    : Promise.resolve()
            ).then(() => {
                if (isCurrentUserPresenting) {
                    dispatch(resetMeeting());
                }
            }).catch(error => {
                console.error('Could not update meeting', error.message)
            }).finally(() => {
                unloadMeetingContainer();
                communicationsContext.disconnect().then();
                dispatch(setShowMeetingControls(false));
                dispatch(resetMeeting());
                dispatch(resetAudience());

                navigateHome();
            });
        }
    }

    return <>
        {showNTLogo && <Logo
            alt="Single Line Anchor Logo"
            ariaLabel="Northern Trust Logo"
            color="black"
            logoType="single"
            width="200px"
        />}
        {isLoadingPreview && <LoadingIndicator displayMessage={"Loading Preview..."}/>}
        {!isLoadingPreview && <div className="audio-video-controls-container">
            <h3>Choose your Video and Audio options</h3>
            <div data-testid="video-preview" className="video-preview">
                {isCameraEnabled && <VideoTile
                    renderElement={<StreamMedia videoStreamElement={previewTarget}/>}
                />}
                {!isCameraEnabled && <VideoTile
                    renderElement={<CameraOffPlaceholder/>}
                />}
            </div>
            <div className="video-controls" data-testid="video-controls">
                <div className="control-button">
                    <div className={"video-cam-icon"}>
                        {isCameraEnabled && <Icon
                            key={'cameraCustomIconOn'}
                            name={'videocam'}
                            color={'black'}
                            style={{fontSize: '20px', width: '20px', height: '20px'}}
                        />}
                        {!isCameraEnabled && <Icon
                            key={'cameraCustomIconOff'}
                            name={'videocam_off'}
                            color={'black'}
                            style={{fontSize: '20px', width: '20px', height: '20px'}}
                        />}
                    </div>
                    <Switch
                        ariaLabelledBy="camera-switch"
                        id="camera-switch"
                        isOn={isCameraEnabled}
                        offText=""
                        onText=""
                        stateTextPosition="right"
                        onChange={() => {
                            if (deviceAccess.video) {
                                setIsCameraEnabled(!isCameraEnabled)
                            }
                        }}
                    />
                </div>
                {isCameraEnabled ? <div>Your camera is turned on</div> : <div>Your camera is turned off</div>}
            </div>
            <div className="audio-controls" data-testid="audio-controls">
                <div className={"volume-up-icon"}>
                    {isAudioEnabled && <Icon
                        key={'micCustomIconOn'}
                        name={'mic'}
                        color={'black'}
                        style={{fontSize: '20px', width: '20px', height: '20px'}}
                    />}
                    {!isAudioEnabled && <Icon
                        key={'micCustomIconOff'}
                        name={'mic_off'}
                        color={'black'}
                        style={{fontSize: '20px', width: '20px', height: '20px'}}
                    />}
                </div>
                <Switch
                    ariaLabelledBy="audio-switch"
                    id="audio-switch"
                    isOn={isAudioEnabled}
                    offText=""
                    onText=""
                    stateTextPosition="right"
                    onChange={() => {
                        if (deviceAccess.audio) {
                            setIsAudioEnabled(!isAudioEnabled)
                        }
                    }}
                />
            </div>
            <div className="audio-video-button-container">
                <Button kind="secondary" className="cancel" data-testid="cancel-join"
                        onClick={() => setConfirmExitMeetingModalOpen(true)}>CANCEL</Button>
                <Button kind="primary" className="join-now" onClick={() => onJoinNow(isAudioEnabled, isCameraEnabled)}>JOIN
                    NOW</Button>
            </div>
        </div>}
        {isConfirmExitMeetingModalOpen &&
            <ConfirmExitMeetingModal
                isOpen={isConfirmExitMeetingModalOpen}
                handleCancel={() => setConfirmExitMeetingModalOpen(false)}
                handleExitMeeting={() => handleCancelJoinMeeting()}
            />
        }
    </>
}

export default AudioVideoPreview;