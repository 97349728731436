import React from 'react';
import {formatCurrency} from "../../utils/format";
import GoalBarChartSideBar from "./GoalBarChartSidebar";
import {BarChartSummaryTable, BarChartSummaryTableItem} from "../../components/BarChartSidebar/BarChartSummaryTable";
import {NonLifestyleGoalType} from "../models/GoalType";
import {GoalBarChartKeyColors} from "./GoalBarChartKeyColors";
import {useAppSelector} from "../../store/hooks";
import {GoalModelType} from "../models/GoalModelType";
import {selectGoalModel} from "../controller/GoalsModelSlice";
import {LifestyleSpendingGoal} from "../models/LifestyleSpendingGoal";

export function buildAddNewGoalTableItems(nonLifestyleGoal: NonLifestyleGoalType): BarChartSummaryTableItem[] {
    return [{
        leftText: `${nonLifestyleGoal.userInputs.description}`,
        rightText: `${formatCurrency(nonLifestyleGoal.calculatedFields.presentValue)}`,
        isActive: false,
        keyColor: GoalBarChartKeyColors.PHILANTHROPIC
    }]
}

export interface AddNewGoalBarChartSideBarProps {
    nonLifestyleGoalFromForm: NonLifestyleGoalType;
    lifestyleSpendingGoalFromForm?: LifestyleSpendingGoal;
}

function AddNewPhilanthropicGoalBarChartSideBar({nonLifestyleGoalFromForm, lifestyleSpendingGoalFromForm}: AddNewGoalBarChartSideBarProps) {
    const {philanthropicGoals} = useAppSelector<GoalModelType>(selectGoalModel)

    const combinedPhilanthropicGoals = philanthropicGoals.filter((philanthropicGoal) => philanthropicGoal.id !== nonLifestyleGoalFromForm.id).concat(nonLifestyleGoalFromForm)

    let sumOfPresentValues = 0;

    combinedPhilanthropicGoals.forEach(goal => {
        sumOfPresentValues += goal.calculatedFields.presentValue
    })


    const leftHeader = 'Philanthropic Goals'

    const goalBarChartSideBarProps: {
        philanthropicGoals: NonLifestyleGoalType[],
        lifestyleSpendingGoal?: LifestyleSpendingGoal
    } = {
        philanthropicGoals: combinedPhilanthropicGoals
    };
    if (lifestyleSpendingGoalFromForm) {
        goalBarChartSideBarProps.lifestyleSpendingGoal = lifestyleSpendingGoalFromForm;
    }

    return (
        <GoalBarChartSideBar
            {...goalBarChartSideBarProps}
        >
            <BarChartSummaryTable
                leftHeader={leftHeader}
                rightHeader={`${formatCurrency(sumOfPresentValues)}`}
                items={buildAddNewGoalTableItems(nonLifestyleGoalFromForm!)}
            />
        </GoalBarChartSideBar>
    )
}

export default AddNewPhilanthropicGoalBarChartSideBar;

