import {Button, Search} from "../../../components";
import React, {KeyboardEvent, useState} from "react";

export type PartiallyOwnedInvestmentSearchProps = {
    onSearch: (search: string) => void,
    onSearchCleared: () => void
};

export function PartiallyOwnedInvestmentSearch({
                                                   onSearch,
                                                   onSearchCleared
                                               }: PartiallyOwnedInvestmentSearchProps) {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = () => {
        if(searchTerm.trim().length > 0) {
            onSearch(searchTerm);
        }
    };

    const handleSearchCleared = () => {
        setSearchTerm('');
        onSearchCleared();
    };

    return (
        <>
            <Search
                id="search-input"
                name="search_partially_owned_investment"
                size="medium"
                onClearListener={handleSearchCleared}
                placeholder="Search by full account number"
                handleChangeVal={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchTerm(e.target.value);
                }}
                onKeyDownListener={(e: KeyboardEvent) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSearch();
                    }
                }}
                value={searchTerm}
            />
            <Button
                className="search-button"
                icon="none"
                kind="primary"
                onClick={handleSearch}
                size="medium"
                type="button"
            >
                Search
            </Button>
        </>
    );
}