import {Popover} from "../Popover";
import React from "react";
import {Button} from "../Button";
import {ButtonProps} from "../Button/Button";
import {PopoverProps} from "../Popover/Popover";
import classNames from "classnames";

type ToolTip = {
    content: PopoverProps['content'],
    hide?: boolean,
} & Partial<Omit<PopoverProps, 'children'>>;

export interface ButtonWithToolProps extends ButtonProps {
    toolTip: ToolTip
}

const ButtonWithTooltip = ({
                               children,
                               toolTip,
                               ...buttonProps
                           }: ButtonWithToolProps) => {
    const toolTipPropsWithDefaults = {
        'aria-label': "info-popover",
        className: "popover-text",
        direction: "right" as const,
        hide: false,
        width: "288px",
        ...toolTip
    };

    const {className: customButtonClassName, ...buttonPropsWithoutClassname} = buttonProps;
    const buttonClassNames = classNames(customButtonClassName, "button-with-tooltip");

    if (toolTipPropsWithDefaults.hide) {
        return (
            <Button
                className={buttonClassNames}
                iconName="info_outline"
                icon={toolTipPropsWithDefaults.hide ? "none" : "right"}
                {...buttonPropsWithoutClassname}
            >
                {children}
            </Button>
        );
    }

    return (
        <Popover {...toolTipPropsWithDefaults}>
            <Button
                className={buttonClassNames}
                iconName="info_outline"
                icon={toolTipPropsWithDefaults.hide ? "none" : "right"}
                {...buttonPropsWithoutClassname}
            >
                {children}
            </Button>
        </Popover>
    );
}

export default ButtonWithTooltip;