import {useEffect} from 'react';
import {RelayEventHook} from "../types/RelayEventHook";
import {setPresenter} from "../../meetingSlice";
import {useAppDispatch} from "../../../../store/hooks";
import {Presenter} from "../../Meeting";

const presenterKey = 'meetingPresenter';

const relayPresenterState: RelayEventHook<Presenter> = (
    sharedObjects,
    registerMutatorFunction,
    registerValueChangeHandler
) => {
    const {meetingControlDDS} = sharedObjects;
    const dispatch = useAppDispatch();

    // Register mutator function
    useEffect(() => {
        registerMutatorFunction({
            key: 'updatePresenterState',
            callback: (newPresenter: string) => {
                meetingControlDDS.set(presenterKey, newPresenter);
            }
        });
    }, []);

    // Register ValueChange Handler
    useEffect(() => {
        registerValueChangeHandler({
            sharedMapName: 'meetingControlDDS',
            key: presenterKey,
            callback: (newPresenter: Presenter) => {
                dispatch(setPresenter(newPresenter));
            },
        });
    }, []);
};

export default relayPresenterState;