import {PresentationPaneHeader} from "../../components";
import React from "react";
import ScrollableContainer from "src/components/ScrollableContainer/ScrollableContainer";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import ComparePlansHeader from "./ComparePlansHeader";
import ComparePlansContent from "./ComparePlansContent";

export const ComparePlans = () => {
    return (
        <div className="compare-plans">
            <ComparePlansHeader/>
            <>
                <ComparePlansContent/>
            </>
        </div>
    )
}