import {Button} from "../../../components";
import React from "react";
import {formatCurrency} from "../../../utils/format";
import {AssetsSummary} from "../../models/Assets";
import {LegalEntityReadModel} from "../../models/Ownership";
import OutOfEstateAssetList from "./OutOfEstateAssetList";
import {hasOutOfEstateAssets, hasOutOfEstateLiabilities} from "../../AssetSummary/common/utils";
import {calculateOutOfEstateTotalValue} from "../../AssetSummary/common/AssetSummaryCalculator";

export type OutOfEstateContainerProps = {
    showOutOfEstate: boolean,
    onClickShowOutOfEstate: (show: boolean) => void
    assets: AssetsSummary,
    legalEntities: LegalEntityReadModel[]
    showDeathBenefit?: boolean;
    onDeleteLegalEntity: (legalEntityId: string) => void
}
const OutOfEstateContainer = ({
                                  showOutOfEstate,
                                  onClickShowOutOfEstate,
                                  assets,
                                  legalEntities,
                                  showDeathBenefit = false,
                                  onDeleteLegalEntity
                              }: OutOfEstateContainerProps) => {

    const outOfEstateTotalValue = calculateOutOfEstateTotalValue(assets, showDeathBenefit)

    return <>
        {showOutOfEstate &&
            <>
                <div className="section-header">
                    <h3>
                        Out of estate assets total <b>{formatCurrency(outOfEstateTotalValue).trim()}</b>:
                    </h3>
                    <Button
                        kind='borderless'
                        onClick={() => onClickShowOutOfEstate(false)}>
                        Hide out of estate assets
                    </Button>
                </div>
                <OutOfEstateAssetList assets={assets}
                                      legalEntities={legalEntities}
                                      showDeathBenefit={showDeathBenefit}
                                      onDeleteLegalEntity={onDeleteLegalEntity}/>
            </>}
        {(hasOutOfEstateAssets(assets) || hasOutOfEstateLiabilities(assets)) &&
            <Button
                kind='secondary'
                rounded
                onClick={() => onClickShowOutOfEstate(!showOutOfEstate)}>
                {showOutOfEstate ? "Hide out of estate assets" : "Show out of estate assets"}
            </Button>}
    </>
};


export default OutOfEstateContainer;