import {FamilyGoalType} from "../models/FamilyGoalType";
import {goalsApiClient} from "../GoalsApiClient";
import FamilyGoalUtils from "../Summary/FamilyGoalUtils";

const saveFamilyGoal = async (ownerId: string, familyGoal: FamilyGoalType): Promise<FamilyGoalType> => {
    return goalsApiClient.postFamilyGoal(ownerId, FamilyGoalUtils.toRequest(familyGoal))
        .then(goal => FamilyGoalUtils.updateGoalToRemoveTypeAndAddSubType({...goal, userInputs: {...goal.userInputs}}))
}

export const FamilyGoalService = {
    saveFamilyGoal,
}
