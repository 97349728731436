import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
    AccountDetailsType,
    StandaloneAccountFormData,
    StandaloneLiabilityType
} from "../models/StandaloneAccount";
import {LegalEntityFormData, OwnershipDetailsFormData} from "../models/Ownership";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import {assetsApiClient} from "../AssetsApiClient";
import {StandaloneAccountForm} from "./StandaloneAccountForm";
import AddFormContainer from "../AddFormContainer";
import {mapToOwnershipWriteModel} from "../Ownership/mappers";
import {TaxDetailsType} from "../models/TaxDetails";
import {
    getDefaultOwnershipDetails,
    getTaxableLiabilityPaidByPortfolioForDeferredAccounts,
    getTaxableLiabilityPaidByPortfolioForTaxableAccounts
} from "../formHelpers";
import {AssetClassifications} from "../models/AssetClassifications";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import AssetsViewContext from "../common/AssetsViewContext";
import {selectClientAssets} from "../clientAssetsSlice";

const AddStandaloneAccount: React.FC = () => {
    const history = useHistory();
    const viewType = useContext(AssetsViewContext);
    const profile = useAppSelector(selectProfile);
    const {accounts, partiallyOwnedLegalAgreements, investmentProgram} = useAppSelector(selectClientAssets)!;
    const taxableLiabilityPaidByPortfolio = getTaxableLiabilityPaidByPortfolioForTaxableAccounts(accounts, investmentProgram, partiallyOwnedLegalAgreements)! ;
    const deferredLiabilityPaidByPortfolio = getTaxableLiabilityPaidByPortfolioForDeferredAccounts(accounts, investmentProgram, partiallyOwnedLegalAgreements)! ;

    const personalLiability: StandaloneLiabilityType = {
        personalLiabilities:[{
            description:"",
            loanBalance: 0
        }]
    }

    const initialStandaloneAccount: StandaloneAccountFormData = {
        name: 'Account',
        asOfDate: undefined,
        accountNumber: '',
        entityType: '',
        institution: '',
        taxStatus: 'Taxable',
        doesPermitBeneficiary: null,
        isEntityCapitalGains: true,
        isLiabilityPaidByPortfolio: taxableLiabilityPaidByPortfolio,
        ...getDefaultOwnershipDetails(profile.primaryContact.id!)
    };

    const [memberGroup, setMemberGroup] = useState<MemberGroup>();
    const [legalEntities, updateLegalEntities] = useState<LegalEntityFormData[]>([]);

    const saveAccount = async (accountDetails: AccountDetailsType, taxDetails: TaxDetailsType, ownershipDetailsFormData: OwnershipDetailsFormData) => {
        const account = await assetsApiClient.postAccounts({
            profileId: profile.id,
            ...mapToOwnershipWriteModel(ownershipDetailsFormData),
            ...accountDetails,
            ...taxDetails,
        });

        return account.id;
    }


    const handleSave = async (accountDetails: AccountDetailsType, taxDetails: TaxDetailsType, ownershipDetails: OwnershipDetailsFormData) => {
        await saveAccount(accountDetails, taxDetails, ownershipDetails);
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
    }

    const onEditHoldingsClick = async (accountDetails: AccountDetailsType, taxDetails: TaxDetailsType, ownershipDetails: OwnershipDetailsFormData) => {
        const accountId = await saveAccount(accountDetails, taxDetails, ownershipDetails);
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}/StandaloneAccount/${accountId}/Holdings`);
    }

    const [classifications, setClassifications] = useState<AssetClassifications>();

    useEffect(() => {
        Promise.all([assetsApiClient.getAssetClassifications(), clientManagementApiClient.getMemberGroup(profile.id),
        assetsApiClient.getLegalEntities(profile.id)])
            .then(([assetClassificationResponse, memberGroupResponse, legalEntitiesResponse]) => {
                setClassifications(assetClassificationResponse);
                setMemberGroup(memberGroupResponse);
                updateLegalEntities(legalEntitiesResponse)
            }).catch(error => console.error('Could not fetch standalone account data', error.message));
    }, [])

    if (classifications) {
        return (
            <div className="standalone-account asset-form">
                <AddFormContainer
                    modalTitle="Asset"
                    form={(handleCancel) => (
                        <StandaloneAccountForm
                            formatTitle={() => "Add Standalone Account"}
                            handleSave={handleSave}
                            handleCancel={handleCancel}
                            initialStandaloneAccount={initialStandaloneAccount}
                            onClickEditHoldings={onEditHoldingsClick}
                            classifications={classifications}
                            memberGroup={memberGroup!}
                            legalEntities={legalEntities}
                            updateLegalEntities={updateLegalEntities}
                            liabilityPaidByPortfolioForTaxableAccounts={taxableLiabilityPaidByPortfolio}
                            liabilityPaidByPortfolioForDeferredAccounts={deferredLiabilityPaidByPortfolio}
                            loan={personalLiability}
                        />
                    )}
                />
            </div>
        );
    } else {
        return <LoadingIndicator/>;
    }
};

export default AddStandaloneAccount;
