import {TableCell} from "../../../components/Table/TableCell";
import {
    formatCurrencyToNumber,
    formatNumberToString
} from "../../../utils/format";
import {TableActionDropdownMenu} from "../../../components/Table/TableActionDropdownMenu";
import {DatePicker, Dropdown, DropdownItem, Icon, Input, NumberInput} from "../../../components";
import {DISPLAY_DATE_FORMAT, ISO8601_DATE_FORMAT} from "../../../constants/common";
import React, {ChangeEvent, useEffect, useState} from "react";
import moment, {Moment} from "moment/moment";
import {StockGrant, StockGrantType} from "../../models/EquityCompensation";
import {formatOptionalAmount} from "../AddOptionsAndGrants";
import CustomModal from "../../../components/Modal/Custom/CustomModal";
import EquityVestedLabelAndInfo from "../EquityVestedLabelAndInfo";

const stockGrantTypeOptions = Object.freeze([
    {
        itemText: "PSU",
        value: StockGrantType.PSU
    },
    {
        itemText: "RSU",
        value: StockGrantType.RSU
    }
]);

const stockGrantTypeDropdownItems = stockGrantTypeOptions.map(typeOption => (<DropdownItem key={typeOption.value}
                                                                                             itemText={typeOption.itemText}
                                                                                             value={typeOption.value}/>));

type StockGrantTableRowProps = {
    stockGrant: StockGrant,
    index: number,
    deleteStockGrant: (stockIndex: number) => void;
    handleEditVesting: (stockIndex: number) => void;
    updateStockGrant: (stockOption: StockGrant, stockIndex: number, updateCalculatedCol: boolean) => void;
    handleClearVesting: (stockIndex: number, stockId: string) => void;
}

const StockGrantTableRow: React.FC<StockGrantTableRowProps> = ({
                                                                     stockGrant,
                                                                     index,
                                                                     updateStockGrant,
                                                                     handleEditVesting,
                                                                     deleteStockGrant,
                                                                     handleClearVesting
                                                                 }) => {
    const [stockGrantFormData, setStockGrantFormData] = useState<StockGrant>(stockGrant);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showClearModal, setShowClearModal] = useState(false);

    useEffect(() => {
        setStockGrantFormData(stockGrant);
    }, [stockGrant]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setStockGrantFormData({
            ...stockGrantFormData,
            [name]: value
        })
    }

    const handleBlur = (name: string, value: string | number, updateCalculatedCol: boolean = false) => {
        updateStockGrant({
            ...stockGrantFormData,
            [name]: value
        }, index, updateCalculatedCol);
    }

    const handleOnGrantDateChange = (date: Moment) => {
        const grantDate = date?.utc().format(ISO8601_DATE_FORMAT);

        const selectedDate = (grantDate ? moment(new Date(grantDate)) : null);
        const expirationDate = selectedDate?.add(5, "years").utc().format(ISO8601_DATE_FORMAT) || "";
        updateStockGrant({
            ...stockGrantFormData,
            grantDate,
            expirationDate
        }, index, false);
    }

    const grantDateVal = stockGrantFormData.grantDate ? moment(stockGrantFormData.grantDate) : undefined;
    const expirationDateVal = stockGrantFormData.expirationDate ? moment(stockGrantFormData.expirationDate) : undefined;

    return <div
        role="row"
        className="stock-grants-grid stock-grants-grid-table-row"
        tabIndex={0}
        aria-label={`${stockGrantFormData.grantDescription} Row`}
    >
        <div role="cell">
            <Input
                name="grantDescription"
                aria-label={`${stockGrantFormData.grantDescription} Grant Description/ID`}
                value={stockGrantFormData.grantDescription}
                onChange={handleInputChange}
                onBlur={() => handleBlur("grantDescription", stockGrantFormData.grantDescription)}
            />
        </div>
        <div role="cell" aria-label={`${stockGrantFormData.grantDescription} Grant date`}>
            {stockGrantFormData.vested ?
                <EquityVestedLabelAndInfo labelId={`${stockGrantFormData.grantDescription} Grant date`}
                                          labelText={moment(stockGrantFormData.grantDate)?.format(DISPLAY_DATE_FORMAT)}
                                          popoverLabel={`grant-date-info`}
                                          popoverContent={
                                              <div className="popover-content" aria-label={`grant-date-info-content`}>
                                                  Clear vesting schedule to update or modify grant date.
                                              </div>
                                          }/>
                :
                <DatePicker
                    className="grantDateInput"
                    id="grantDateInput"
                    size="small"
                    displayFormat={DISPLAY_DATE_FORMAT}
                    isOutsideRange={() => false}
                    hideKeyboardShortcutsPanel
                    date={grantDateVal}
                    onDateChange={handleOnGrantDateChange}
                />}
        </div>
        <div role="cell">
            <Dropdown
                name="type"
                aria-label={`${stockGrantFormData.grantDescription} Type`}
                size="small"
                value={stockGrantFormData.type}
                onChange={(data: any) => {
                    updateStockGrant({
                        ...stockGrantFormData,
                        type: data?.value as StockGrantType
                    }, index, false)
                }}
            >
                {stockGrantTypeDropdownItems}
            </Dropdown>
        </div>
        <div role="cell" aria-label={`${stockGrantFormData.grantDescription} Expiration date`}>
            {stockGrantFormData.vested ?
                <EquityVestedLabelAndInfo labelId={`${stockGrantFormData.grantDescription} Expiration date`}
                                          labelText={moment(stockGrantFormData.expirationDate)?.utc().format(DISPLAY_DATE_FORMAT)}
                                          popoverLabel={`expiration-date-info`}
                                          popoverContent={
                                              <div className="popover-content" aria-label={`expiration-date-info-content`}>Clear vesting schedule to update or modify expiration date.</div>
                                          }/>
                :
                <DatePicker
                    aria-label="Expiration date"
                    className="expirationDateInput"
                    id="expirationDateInput"
                    size="small"
                    displayFormat={DISPLAY_DATE_FORMAT}
                    isOutsideRange={() => false}
                    hideKeyboardShortcutsPanel
                    date={expirationDateVal}
                    onDateChange={(date: Moment) => {
                        const expirationDate = date?.utc().format(ISO8601_DATE_FORMAT);
                        updateStockGrant({
                            ...stockGrantFormData,
                            expirationDate
                        }, index, false);
                    }}
                />}
        </div>
        <div role="cell" className="input-right-aligned">
            { stockGrantFormData.vested ?
                <EquityVestedLabelAndInfo labelId={`${stockGrantFormData.grantDescription} Shares Granted`}
                                          labelText={stockGrantFormData.sharesGranted}
                                          popoverLabel={`shares-granted-info`}
                                          popoverContent={
                                              <div className="popover-content" aria-label={`shares-granted-info-content`}>Clear vesting schedule to update or modify shares granted.</div>
                                          }/>
                :
                <NumberInput
                    aria-label={`${stockGrantFormData.grantDescription} Shares Granted`}
                    name="sharesGranted"
                    value={stockGrantFormData.sharesGranted}
                    onChangeValue={handleInputChange}
                    onBlur={(e) => handleBlur(e.target.name, formatCurrencyToNumber(e.target.value), true)}
            />}
        </div>
        <div role="cell" className="label-13px textalign-right" aria-label={`${stockGrantFormData.grantDescription} Shares Vested`}>
            {stockGrantFormData.vested ? formatNumberToString(stockGrantFormData.sharesVested) :
                <a role="button" className="edit-vesting-button" onClick={() => handleEditVesting(index)}>Edit Vesting</a>}
        </div>
        <TableCell textSize="small" ariaLabel={`${stockGrantFormData.grantDescription} Shares Unvested`}
                   text={formatNumberToString(stockGrantFormData.sharesUnvested)}
                   className="textalign-right"/>
        <TableCell textSize="small" ariaLabel={`${stockGrantFormData.grantDescription} unvested market value`}
                   text={formatOptionalAmount(stockGrantFormData.grossUnvestedValue)}
                   className="textalign-right"/>
        <TableCell textSize="small" ariaLabel={`${stockGrantFormData.grantDescription} after tax unvested market value`}
                   text={formatOptionalAmount(stockGrantFormData.afterTaxUnvestedMarketValue)}
                   className="textalign-right"/>
        <TableActionDropdownMenu
            ariaLabel={`Stock Grant Menu`}>
            <DropdownItem
                itemText="Edit Vesting Schedule"
                value="Edit Vesting Schedule"
                onClick={() => handleEditVesting(index)}
            />
            {stockGrantFormData.vested ? <DropdownItem
                itemText="Clear Vesting Schedule"
                value="Clear Vesting Schedule"
                onClick={()=>setShowClearModal(true)}
            /> : null }
            <DropdownItem
                itemText="Delete Stock Grant"
                value="Delete Stock Grant"
                //onClick={() => deleteStockGrant(index)}
                onClick={() => setShowConfirmModal(true)}
            >
                <Icon name="delete"
                      className="asset-table-delete-icon"/>
            </DropdownItem>
        </TableActionDropdownMenu>
        <CustomModal
            isOpen={showConfirmModal}
            title={`Delete this Stock Grant?`}
            content={

                <div>
                    {stockGrantFormData.grantDescription ?`The ${stockGrantFormData.grantDescription} and all associated data will be deleted permanently.` : `The stock grant and all associated data will be deleted permanently.`}
                </div>

            }
            onClickConfirm={() => {
                setShowConfirmModal(false);
                deleteStockGrant(index);
            }}
            onClickCancel={() => {
                setShowConfirmModal(false);
            }}
            confirmText={`DELETE GRANT`}
            cancelText={`CANCEL`}
        />
        <CustomModal
            isOpen={showClearModal}
            title={`Clear Vesting Schedule?`}
            content={
                <div>
                    {`Any data entered in the vesting schedule for this stock grant will be removed`}
                </div>
            }
            onClickCancel={() => {
                setShowClearModal(false);
            }}
            onClickConfirm={() => {
                if (stockGrant.id) {
                    handleClearVesting(index, stockGrant.id);
                }
                setShowClearModal(false);
            }}
            cancelText={`CANCEL`}
            confirmText={`CLEAR`}
        />
    </div>;
}

export default StockGrantTableRow;