import React from 'react';
import {useAppSelector} from "../../store/hooks";
import {selectGoalModel} from "../controller/GoalsModelSlice";
import {GoalModelType} from "../models/GoalModelType";
import SummaryLifestyleGoalList from "./SummaryLifestyleGoalList";
import SummaryTaxLiabilitiesList from "./SummaryTaxLiabilitiesList";
import NonLifestyleGoals from "./NonLifestyleGoals";
import {FamilyGoalType} from "../models/FamilyGoalType";
import {MemberType} from "../../ClientManagement/models/MemberType";
import {GoalType, NonLifestyleGoalType} from "../models/GoalType";
import useProfileEditableState from "../../hooks/useProfileEditableState";

type GoalsListProps = {
    onEditFamilyGoal: (selectedGoalType: string, savedGoal: FamilyGoalType) => void,
    onViewFamilyGoal: (memberSelected: MemberType) => void,
    onDeleteNonLifestyleGoal: (goal: NonLifestyleGoalType) => void,
    onEditNonLifestyleGoal: (goal: NonLifestyleGoalType, type: GoalType) => void
};

const GoalList = ({
                      onEditFamilyGoal,
                      onViewFamilyGoal,
                      onDeleteNonLifestyleGoal,
                      onEditNonLifestyleGoal
                  }: GoalsListProps) => {
    const {
        familyGoals,
        discretionaryGoals,
        philanthropicGoals,
        taxLiabilities
    } = useAppSelector<GoalModelType>(selectGoalModel);
    const clientHasTaxLiabilities = taxLiabilities.totalTaxLiabilities > 0;
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    return (
        <article>
            <SummaryLifestyleGoalList isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}/>
            <NonLifestyleGoals
                familyGoals={familyGoals}
                discretionaryGoals={discretionaryGoals}
                philanthropicGoals={philanthropicGoals}
                onEditFamilyGoal={onEditFamilyGoal}
                onViewFamilyGoal={onViewFamilyGoal}
                onDeleteNonLifestyleGoal={onDeleteNonLifestyleGoal}
                onEditNonLifestyleGoal={onEditNonLifestyleGoal}
                isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
            />
            {clientHasTaxLiabilities && <SummaryTaxLiabilitiesList/>}
        </article>
    )
}


export default GoalList;
