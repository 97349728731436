import React, {useMemo} from "react";
import {Dropdown, DropdownItem} from "../../components";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import {useAppSelector} from "../../store/hooks";
import {selectHasStateOfResidencyStrategy} from "../WealthPlanOptimizerSlice";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import {NO_OP} from "../../constants/common";

type AddStrategyButtonDropdownProps = {
    className: string,
}

const AddStrategyButtonDropdown: React.FC<AddStrategyButtonDropdownProps> = ({
                                                                                 className
                                                                             }) => {
    const history = useHistory();
    const {id} = useParams<RouteWithId>();
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const hasStateOfResidencyStrategy = useAppSelector(selectHasStateOfResidencyStrategy);

    const dropdownItemArray = useMemo(() => {
        return [
            ...(releaseToggles!.enableTwoYearGrat
                    ? [{
                        itemText: "2-Year GRAT",
                        value: "2-Year GRAT",
                        onClick: () => {
                            history.push(`/Profile/${id}/ClientProfile/WealthPlanOptimizer/AddTwoYearGRAT`);
                        },
                        disabled: false
                    }]
                    : []
            ),
            {
                itemText: "Annual Gifting",
                value: "Annual Gifting",
                onClick: () => {
                    history.push(`/Profile/${id}/ClientProfile/WealthPlanOptimizer/AddAnnualGifting`);
                },
                disabled: false
            },
            ...(releaseToggles!.enableGiftToGrantor
                    ? [{
                        itemText: "Gift to Grantor Trust",
                        value: "Gift to Grantor Trust",
                        onClick: () => {
                            history.push(`/Profile/${id}/ClientProfile/WealthPlanOptimizer/AddGiftToGrantorTrust`);
                        },
                        disabled: false
                    }]
                    : []
            ),
            {
                itemText: "State of Residency (Estate Tax)",
                value: "State of Residency (Estate Tax)",
                onClick: () => {
                    history.push(`/Profile/${id}/ClientProfile/WealthPlanOptimizer/AddStateOfResidency`);
                },
                disabled: hasStateOfResidencyStrategy
            }
        ];
    }, [id, hasStateOfResidencyStrategy]);

    return <Dropdown
        className={className}
        alignRight
        buttonIcon="left"
        buttonKind="primary"
        dropdownKind="menu"
        defaultText="Add Strategy"
        iconNameOpen="add"
        iconNameClose="add"
        panelWidth={245}
        rounded
        disabled={isProfileWithProposalsOrArchived}
    >
        {dropdownItemArray.map((item: any, i: number) =>
            <DropdownItem key={i}
                          itemText={item.itemText}
                          value={item.value}
                          onClick={item.onClick}
                          disabled={item.disabled}
            />)}
    </Dropdown>
}

export default AddStrategyButtonDropdown;
