import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import WealthPlanOptimizerContent from "../../../WealthPlanOptimizer/WealthPlanOptimizerContent";
import {wealthPOApiClient} from "../../../WealthPlanOptimizer/WealthPOApiClient";
import {RouteWithId} from "../../../routes/types";
import {useAppDispatch} from "../../../store/hooks";
import {initialStateOfResidencyStrategy, StrategyDetailsSectionInfo} from "../../../WealthPlanOptimizer/WealthPOTypes";
import {buildStrategyDetailsList} from "../../../WealthPlanOptimizer/WealthPOUtils";
import {
    resetWealthPlanOptimizerState,
    setWealthPlanOptimizerState,
    WealthPlanOptimizerState
} from "../../../WealthPlanOptimizer/WealthPlanOptimizerSlice";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {NO_OP} from "../../../constants/common";
import PrintViewWrapper from "../PrintViewWrapper";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../../../components/Modal/Error/GenericErrorModal";

interface WealthPlanOptimizerWrapperProps {
    displayName: string;
}

const WealthPlanOptimizerWrapper = ({displayName}: WealthPlanOptimizerWrapperProps) => {
    const {id} = useParams<RouteWithId>();
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [genericError, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const [listOfStrategyDetailsList, setListOfStrategyDetailsList] = useState<StrategyDetailsSectionInfo[][]>([]);

    const openErrorModal = (error: Error | any) => {
        setGenericError({
            isOpen: true,
            header: "Unable to Load This Page",
            message: (
                <>
                    <p>Check your VPN connection and retry.</p>
                    <p>If the problem persists, contact <a href="mailto:GPIITSupport@ntrs.com">GPS Technical
                        Support</a> at GPIITSupport@ntrs.com and provide the following details:</p>
                </>
            ),
            profileId: id,
            time: new Date(),
            errorDetail: `Failed to load Wealth Plan Optimizer (${error.status})`,
            operationId: error.headers.get('trace-id')
        });
    };

    useEffect(() => {
        setIsLoading(true);

        wealthPOApiClient.getWealthPOSummary(id).then(wealthPOSummaryResponse => {
            if (wealthPOSummaryResponse.stateOfResidencyStrategy == null) {
                wealthPOSummaryResponse.stateOfResidencyStrategy = initialStateOfResidencyStrategy;
            }

            dispatch(setWealthPlanOptimizerState({
                wealthPOSummaryResponse,
            } as WealthPlanOptimizerState))

            const {listOfStrategyDetailsLists} = buildStrategyDetailsList(
                wealthPOSummaryResponse.stateOfResidencyStrategy,
                wealthPOSummaryResponse.annualGiftingStrategies,
                wealthPOSummaryResponse.giftToGrantorTrustStrategies
            );

            setListOfStrategyDetailsList(listOfStrategyDetailsLists);
            setIsLoading(false);
        }).catch((error) => {
            if (error.status === 404) {
                setListOfStrategyDetailsList([]);
                dispatch(resetWealthPlanOptimizerState());
            } else {
                openErrorModal(error);
            }

            setIsLoading(false);
        });

        return () => {
            setListOfStrategyDetailsList([]);
            dispatch(resetWealthPlanOptimizerState());
        }
    }, [id])

    const memoizedList = useMemo(() => {
        if (genericError.isOpen) {
            return (
                <GenericErrorModal
                    errorModalData={genericError}
                    onClickButton={NO_OP}
                    onRequestClose={NO_OP}
                    buttonText="Retry"
                    buttonProps={
                        {
                            primary: true,
                            className: 'full-width center-align',
                            iconPosition: 'left',
                            iconName: 'refresh'
                        }
                    }
                    showAlertIcon={false}
                />
            );
        } else {
            if (listOfStrategyDetailsList.length !== 0) {
                return listOfStrategyDetailsList.map((strategyList, index) =>
                    <div key={index}>
                        <PrintViewWrapper displayName={displayName}>
                            <WealthPlanOptimizerContent refreshStrategiesSummary={NO_OP}
                                                        strategyDetailsList={strategyList}
                                                        isContinued={index > 0}
                                                        isInReportPreview={true}
                            />
                        </PrintViewWrapper>
                    </div>)
            } else {
                return (
                    <div>
                        <PrintViewWrapper displayName={displayName}>
                            <WealthPlanOptimizerContent refreshStrategiesSummary={NO_OP}
                                                        strategyDetailsList={[]}
                                                        isContinued={false}
                                                        isInReportPreview={true}
                            />
                        </PrintViewWrapper>
                    </div>
                )
            }
        }
    }, [listOfStrategyDetailsList, genericError.isOpen]);

    if (isLoading) {
        return <LoadingIndicator/>
    }

    return (
        <>
            {
                memoizedList
            }
        </>
    )
}

export default WealthPlanOptimizerWrapper;
