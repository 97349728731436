import React, {ReactElement} from "react";
import {Col, Container, Row} from "react-grid-system";

type PlanSummaryCardProps = {
    header: string;
    subhead: ReactElement
    onClick: () => void
    className: string
}

const PlanSummaryCard: React.FC<PlanSummaryCardProps> = ({header, subhead, onClick, className}) => {
    return (
        <Container tabIndex={0} style={{paddingLeft: '40px', marginRight: '32px'}} className={className}
                   aria-label={header} onClick={onClick}>
            <div>
                <Row>
                    <Col>
                        <h4 className='header'>{header}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {subhead}
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

export default PlanSummaryCard;