import {formatCurrency} from "../../../utils/format";
import {ColumnCounter, TableCell, TableDisplay, TableRow} from "../../../components";
import {getAssetColor} from "../../../utils/colorPicker";
import {AssetRelianceAssetType, AssetRelianceStack} from "../../models/AssetRelianceResponse";
import {getAssetIdentity, getStackIdentity} from "../AssetRelianceUtil";
import {ReactElement} from "react";
import {AssetRelianceTablePopover} from "./AssetRelianceTablePopover";

const hasStackAssetType = (stack: AssetRelianceStack): stack is Omit<AssetRelianceStack, 'stackAssetType'>
    & { stackAssetType: AssetRelianceAssetType } => {
    return stack.stackAssetType !== null && stack.stackAssetType !== AssetRelianceAssetType.INVESTABLE_PORTFOLIO_ASSET;
}

const constructTableRowLabel = (stack: AssetRelianceStack): string | ReactElement => {
    if (stack.stackAssetType === AssetRelianceAssetType.PERSONAL_ASSET && stack.associatedLiabilities && stack.associatedLiabilities?.length > 0) {
        return AssetRelianceTablePopover({
            description: stack.description,
            stackAssetId: stack.stackAssetId,
            stackAssetType: AssetRelianceAssetType.PERSONAL_ASSET,
            linkedTo: stack.associatedLiabilities!!.map(liability => {
                return {description: liability.description, loanBalance: liability.liabilityValue}
            })
        });
    } else if (stack.stackAssetType === AssetRelianceAssetType.PERSONAL_LIABILITY && stack.collateral) {
        return AssetRelianceTablePopover({
            description: stack.description,
            stackAssetId: stack.stackAssetId,
            stackAssetType: AssetRelianceAssetType.PERSONAL_LIABILITY,
            linkedTo: [{description: stack.collateral.description, loanBalance: stack.collateral.presentValue}]
        });
    }
    return stack.description;
}

export interface AssetTableRow<ValueType> extends TableRow<ValueType> {
    isLiability: boolean;
}


export class AssetTableDisplay extends TableDisplay<number, AssetTableRow<number>> {
    private collateralToLiabilitiesMap: Map<string, number> = new Map<string, number>();

    private static isLiability(assetType: AssetRelianceAssetType): boolean {
        return assetType === AssetRelianceAssetType.PERSONAL_LIABILITY;
    }

    private static getAssetLabelSuffix(assetType: AssetRelianceAssetType): string | undefined {
        switch (assetType) {
            case AssetRelianceAssetType.SOCIAL_SECURITY:
                return "(Social Security)"
            case AssetRelianceAssetType.FUTURE_ASSET_PURCHASE:
                return "(Future Asset Purchase)"
            default:
                return undefined;
        }
    }

    private static isInvestablePortfolioStack(stackAssetType: AssetRelianceAssetType | null): boolean {
        return stackAssetType === AssetRelianceAssetType.INVESTABLE_PORTFOLIO_ASSET;
    }

    private static isInvestablePortfolioAsset(assetType: AssetRelianceAssetType): boolean {
        return assetType === AssetRelianceAssetType.RISK_CONTROL ||
            assetType === AssetRelianceAssetType.LIQUID_RISK_ASSET ||
            assetType === AssetRelianceAssetType.SEMI_LIQUID_RISK_ASSET ||
            assetType === AssetRelianceAssetType.IL_LIQUID_RISK_ASSET ||
            assetType === AssetRelianceAssetType.CONCENTRATED_INVESTMENTS ||
            assetType === AssetRelianceAssetType.OTHER_ASSET;
    }

    private constructLiabilityMap(rowStacks: AssetRelianceStack[]) {
        rowStacks.filter(row => row.stackAssetType === AssetRelianceAssetType.PERSONAL_LIABILITY)
            .forEach(row => {
                const collateralIdOfRow = row.collateral?.id!;
                if (collateralIdOfRow === null) {
                    return;
                }

                const liabilityAmount = this.collateralToLiabilitiesMap.get(collateralIdOfRow) ?? 0;
                this.collateralToLiabilitiesMap.set(collateralIdOfRow, liabilityAmount + row.presentValue);
            })
    }

    constructor(
        columnCounter: ColumnCounter,
        protected readonly rowStacks: AssetRelianceStack[],
        protected readonly columnStacks: AssetRelianceStack[],
    ) {
        super(columnCounter, (value) => formatCurrency(value));
        this.constructLiabilityMap(rowStacks);
    }

    get rows(): AssetTableRow<number>[] {
        return this.rowStacks.filter(hasStackAssetType).map((stack, rowIndex): AssetTableRow<number> => {
            const isLiability = AssetTableDisplay.isLiability(stack.stackAssetType);

            const getValues = () => {
                if (isLiability) {
                    return this.generateLiabilityRow(stack);
                }
                return this.generateColumnValues(stack.presentValue, stack.investableValue, rowIndex, stack.stackAssetType);
            }

            return {
                accentColor: getAssetColor(stack.stackAssetType),
                uniqueIdentifier: getStackIdentity(stack),
                label: constructTableRowLabel(stack),
                labelSuffix: AssetTableDisplay.getAssetLabelSuffix(stack.stackAssetType),
                values: getValues(),
                isLiability,
                isDraggable: !isLiability,
                children: stack.assets.map((assetSubclass): AssetTableRow<number> => ({
                    accentColor: getAssetColor(stack.stackAssetType),
                    uniqueIdentifier: getAssetIdentity(stack.stackAssetType, assetSubclass.assetId),
                    label: assetSubclass.description,
                    values: this.generateColumnValues(assetSubclass.presentValue, assetSubclass.investableValue, rowIndex, stack.stackAssetType),
                    isLiability,
                    children: [],
                    isDraggable: !isLiability
                }))
            };
        });
    }

    protected get headerLabel(): string {
        return "Assets";
    }

    protected get headerValues(): TableCell<number>[] {
        const headerValues: number[] = Array(this.columnCount).fill(0);
        if (this.columnCount > 0) {
            let previousNetAssets = this.columnStacks[0].presentValue;
            headerValues[0] = previousNetAssets;
            for (let index = 1; index < this.columnCount; index++) {
                const stack = this.columnStacks[index];
                if (stack) {
                    if (stack.stackAssetType === AssetRelianceAssetType.INVESTABLE_PORTFOLIO_ASSET) {
                        headerValues[index] = stack.investableValue;
                    } else {
                        previousNetAssets -= stack.presentValue + (this.collateralToLiabilitiesMap.get(stack.stackAssetId!) ?? 0);
                        headerValues[index] = previousNetAssets;
                    }
                }
            }
        }
        return headerValues.map((value) => this.createTableCell({value}));
    }

    private generateColumnValues(
        columnValue: number,
        investableValue: number,
        rowIndex: number,
        rowAssetType: AssetRelianceAssetType,
    ): TableCell<number>[] {
        const columnValues = Array(this.columnCount).fill(columnValue);

        for (let columnIndex = 0; columnIndex < this.columnCount; columnIndex++) {
            const columnStack = this.columnStacks[columnIndex];
            const columnStackSequenceNumber = columnStack?.stackSequenceNumber ?? 0;
            const isColumnInvestablePortfolioStack = AssetTableDisplay.isInvestablePortfolioStack(columnStack?.stackAssetType);
            const isRowInvestablePortfolioAsset = AssetTableDisplay.isInvestablePortfolioAsset(rowAssetType);
            const isRowAssetExcluded = rowIndex < columnStackSequenceNumber;

            if (isColumnInvestablePortfolioStack && isRowInvestablePortfolioAsset && investableValue) {
                columnValues[columnIndex] = investableValue;
            } else if (isRowAssetExcluded) {
                columnValues[columnIndex] = null;
            }
        }
        return columnValues.map(value => this.createTableCell({value}));
    }

    private generateLiabilityRow(
        stack: Omit<AssetRelianceStack, "stackAssetType"> & { stackAssetType: AssetRelianceAssetType }): TableCell<number>[] {

        const columnValues = Array(this.columnCount).fill(stack.presentValue);

        const summaryColumnCount = 1;
        const rowIndexOfCollateralAsset = this.rowStacks.findIndex(rowStack => stack.collateral?.id === rowStack.stackAssetId);
        const columnIndexOfCollateralAsset = this.columnStacks.findIndex(columnStack => stack.collateral?.id === columnStack.stackAssetId);
        const columnsBeforeDebtCollateral = this.rowStacks
            .filter((val, idx) => idx < rowIndexOfCollateralAsset && val.excluded)
            .length + summaryColumnCount;


        const liabilityHasNoCollateral = () => stack.collateral?.id === null || rowIndexOfCollateralAsset < 0;


        for (let columnIndex = 0; columnIndex < this.columnCount; columnIndex++) {
            const columnStack = this.columnStacks[columnIndex];

            if (AssetTableDisplay.isInvestablePortfolioStack(columnStack?.stackAssetType)) {
                columnValues[columnIndex] = null;
                continue;
            }

            if (liabilityHasNoCollateral()) {
                continue;
            }

            if (columnIndexOfCollateralAsset >= 0) {
                if (columnIndex >= columnIndexOfCollateralAsset) columnValues[columnIndex] = null;
            } else if (columnIndex >= columnsBeforeDebtCollateral) columnValues[columnIndex] = null;
        }

        return columnValues.map(value => this.createTableCell({value}));
    }
}