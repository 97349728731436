import {EstateSummary, EstateType} from "./models/api";
import {formatCurrency} from "../utils/format";
import React from "react";

export function WealthTransferHeader({estateSummary, estateType}: {
    estateSummary: EstateSummary,
    estateType: EstateType
}) {
    const text = estateType === EstateType.CURRENT ? 'Your current estate value is ' : `Your estimated estate value at age ${estateSummary.atAge} is `;
    return <h3>{text} <b>{formatCurrency(estateSummary.estateValue)}</b>.
        Under your current
        plan, <b>{formatCurrency(estateSummary.beneficiaryTotalValue)}</b> would be
        distributed to beneficiaries
        and <b>{formatCurrency(estateSummary.estimatedEstateTax)}</b> to estimated estate taxes.
    </h3>;
}