import React from "react";
import {kebabCase} from "../../utils/stringUtils";
import {formatCurrency} from "../../utils/format";

type MonetaryValueCardProps = {
    value: number | string;
    onClick: () => void;
    heading: string;
}

const MonetaryValueCard: React.FC<MonetaryValueCardProps> = ({value, onClick, heading}) => {
    const kebabHeading = kebabCase(heading);
    return (
        <div className={`monetary-value-card ${kebabHeading}`} aria-label={heading} role='button' onClick={onClick}
             tabIndex={0}>
            <div className="sub-header" data-testid={`${kebabHeading}-label`}>{heading}</div>
            <span className="dollar-value" data-testid={`${kebabHeading}-value`}>
                    {typeof value === 'string' ? value : formatCurrency(value)}
            </span>
        </div>
    )
}

export default MonetaryValueCard;