import React, {useEffect, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../../../routes/types";
import {Col, Grid, Row, Table} from "xps-react";
import useProfileAndProposals from "../../../../hooks/useProfileAndProposals";
import {ProfileResponse} from "../../../models/ProfileResponse";
import {clientManagementApiClient} from "../../../ClientManagementApiClient";
import {toDisplayDateFormat} from "../../../../utils/dateUtils";
import {Button, DropdownItem, Search} from "../../../../components";
import {ProfileProposalActionMenu} from "../ProfileProposalActionMenu";
import {dashboardVariables, PARTNER_DASHBOARD_PAGE_SIZES} from "../../../PartnerDashboard/partnerDashboardConstants";
import {capitalizeFirstLetter} from "../../../../utils/stringUtils";

const ViewArchivedProposalsAndProfiles: React.FC = () => {
    const {id} = useParams<RouteWithId>();
    const {profile} = useProfileAndProposals(id);
    const history = useHistory();
    const [archivedProposals, setArchivedProposals] = useState<ProfileResponse[]>([]);
    const [filteredArchivedProposals, setFilteredArchivedProposals] = useState<ProfileResponse[]>([]);
    const [searchText, updateSearchText] = useState("");

    useEffect(() => {
        fetchArchivedProposals();
    }, [id]);

    function fetchArchivedProposals() {
        clientManagementApiClient.getArchivedProposals(id)
            .then(res => {
                setArchivedProposals(res)
                setFilteredArchivedProposals(res)
            })
            .catch(error => console.error('Could not fetch archived proposals', error.message));
    }

    const navigateToProposalSettings = (proposalId: string) => {
        history.push(`/Profile/${proposalId}/ProposalSettings/${id}`);
    }

    const handleSearch = (value: string) => {
        updateSearchText(value);
        const filteredProposals = archivedProposals.filter((proposal) => proposal.displayName
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        );
        setFilteredArchivedProposals(filteredProposals);
    }

    function archivedProposalActionMenu(proposal: ProfileResponse) {
        return (
            <ProfileProposalActionMenu
                dropdownItems={[
                    <DropdownItem
                        key={`viewProposalDetails ${proposal.id}`}
                        aria-label="viewProposalDetails"
                        className="viewProposalDetails"
                        onClick={() => navigateToProposalSettings(proposal.id)}
                        itemText="Proposal Settings"
                        value="ProposalSettings"
                    />
                ]}
            />
        );
    }

    return (
        <>
            <Grid fluid={true}>
                <Row>
                    <Col>
                        <div className="page-container archived-proposals-container">
                            <Row className="display-flex">
                                <Col display-flex justify-content-start align-items-center>
                                    <h1>Archived Proposals and Profiles - {profile?.displayName}</h1>
                                </Col>
                                <Col className="display-flex justify-content-end align-items-center">
                                    <Button
                                        className="marginright-sm"
                                        icon="none"
                                        kind="secondary"
                                        size="medium"
                                        onClick={() => history.push(`/Profile/${id}`)}
                                        type="button"
                                    >
                                        Close
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="display-flex justify-content-start align-items-center">
                                <Search
                                    id="search_container"
                                    name="search_standalone"
                                    size="medium"
                                    onClearListener={() => handleSearch("")}
                                    placeholder="Type to search..."
                                    handleChangeVal={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                    ) => handleSearch(e.target.value)}
                                    value={searchText}
                                />
                            </Row>
                            <Row>
                                <Col>
                                    <Table
                                        id="ArchivedProposalsTable"
                                        caption="Archived Proposals"
                                        data={filteredArchivedProposals}
                                        noDataText={"No matching proposals could be found."}
                                        minRows={0}
                                        pageSizeDropUp={true}
                                        pageSizeOptions={PARTNER_DASHBOARD_PAGE_SIZES}
                                        rowHeight="medium"
                                        showPagination={filteredArchivedProposals.length > 0}
                                        sortable={false}
                                        defaultPageSize={dashboardVariables.PARTNER_DASHBOARD_DEFAULT_ROW}
                                        SubComponent={null}
                                        columns={[
                                            {
                                                Header: () => (
                                                    <span>Proposal Name</span>
                                                ),
                                                accessor: "displayName",
                                                Cell: ({original}: { original: ProfileResponse }) => (
                                                    <span className={"proposal-name"}>
                                                   <Link role="link"
                                                         to={`/Profile/${original.id}/ClientProfile/FamilyTree?archived=true`}>
                                                       {original.displayName}
                                                   </Link>
                                               </span>
                                                ),
                                                width: 300
                                            },
                                            {
                                                Header: () => (
                                                    <span>Type</span>
                                                ),
                                                accessor: "type",
                                                Cell: ({original}: { original: ProfileResponse }) =>
                                                    <span>{capitalizeFirstLetter(original.type.toLowerCase())}</span>,
                                                width: 150
                                            },
                                            {
                                                Header: () => (
                                                    <span>Archived</span>
                                                ),
                                                accessor: "lastModifiedDate",
                                                Cell: ({original}: { original: ProfileResponse }) => <>
                                                    <span>{original.lastModifiedByName}, {toDisplayDateFormat(original.lastModifiedDate)}</span></>,
                                                width: 350
                                            },
                                            {
                                                Header: () => (
                                                    <span>Created</span>
                                                ),
                                                accessor: "createdDate",
                                                Cell: ({original}: { original: ProfileResponse }) => <>
                                                    <span>{original.createdByName}, {toDisplayDateFormat(original.createdDate)}</span></>,
                                            },
                                            {
                                                Cell: ({original}: { original: ProfileResponse }) =>
                                                    archivedProposalActionMenu(original),
                                                className: "overflow-visible",
                                                accessor: "proposalId",
                                                minWidth: 32,
                                                maxWidth: 32,
                                            }
                                        ]}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Grid>
        </>
    );
}
export default ViewArchivedProposalsAndProfiles;
