import {Icon} from "../../../components";
import React from "react";

type AssetClassHeaderProps = {
    expanded: boolean,
    assetSubclassName: string
    taxCost: string,
    marketValue: string,
    investableValue: string,
}

function AssetSubclassHeader({expanded, assetSubclassName, taxCost, marketValue, investableValue}: AssetClassHeaderProps) {
    return (
        <div
            className="holding-grid grid-display-contents holding-subclass-row"
            role="row"
            aria-label={`${assetSubclassName} - Tax Cost ${taxCost} - Market Value ${marketValue} - Investable Value ${investableValue}`}>

                    <span role="cell" aria-label={`${assetSubclassName}`}
                          className="holding-subclass-name paddingleft-md display-flex align-items-center holding-grid-column-custom-span">
                        <Icon name={expanded ? 'chevron_down' : 'chevron_right'} size="large"/>
                        <span className="paddingleft-md">
                            <div aria-label={`${assetSubclassName}`}
                                 className="fontweight-medium holding-grid-column-custom-span">{assetSubclassName}
                            </div>
                        </span>
                    </span>

            <span role="cell" aria-label={`Tax Cost - ${taxCost === '-0.00' ? '0.00' : taxCost}`}
                  className="textalign-right">{taxCost}</span>
            <span role="cell" aria-label={`Market Value - ${marketValue}`}
                  className="textalign-right">{marketValue === '-0.00' ? '0.00' : marketValue}</span>
            <span role="cell"
                  aria-label={`Investable Value - ${investableValue}`}
                  className="textalign-right">{investableValue === '-0.00' ? '0.00' : investableValue}</span>
            <span role="cell" className="empty-grid-column"/>
        </div>
    )
}

export default AssetSubclassHeader;
