import {useAppDispatch} from "../../../store/hooks";
import React, {useEffect, useState} from "react";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {wealthTransferApiClient} from "../../../WealthTransfer/WealthTransferApiClient";
import WealthTransferReportView from "./WealthTransferReportView";
import {EstateSummary, EstateType} from "../../../WealthTransfer/models/api";
import {assetsApiClient} from "../../../Assets/AssetsApiClient";
import {LegalEntityReadModel} from "../../../Assets/models/Ownership";
import {setAccordionPreferencesState} from "../../../Assets/common/accordionPreferencesSlice";
import {ProfileResponse} from "../../../ClientManagement/models/ProfileResponse";
import {AssetsSummary} from "../../../Assets/models/Assets";

type WealthTransferReportProps = {
    estateType: EstateType,
    profile: ProfileResponse,
    approvedProfile: ProfileResponse
};

const accordionPreferencesIds = [
    {
        id: "WealthTransferOutOfEstateLegalDetailedReport",
        expandedItems: ["Irrevocable Trust",
            "Irrevocable Life Insurance Trust (ILIT)",
            "Charitable Foundation",
            "Donor Advised Fund (DAF)",
            "529 Plan",
            "Dynasty Trust",
            "LP, LLC, Other Partnership",
            "S Corp",
            "C Corp",
            "Other"]
    }
]

const WealthTransferReport = ({estateType, profile: profileOrProposal, approvedProfile}: WealthTransferReportProps) => {

    const dispatch = useAppDispatch();
    const displayName = approvedProfile.displayName;
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [legalEntities, setLegalEntities] = useState<LegalEntityReadModel[]>();
    const [clientAssets, setClientAssets] = useState<AssetsSummary | undefined>();
    const [estateSummaryState, setEstateSummaryState] = useState<EstateSummary | undefined>(undefined);

    useEffect(() => {
        accordionPreferencesIds.forEach(accordion => {
            dispatch(setAccordionPreferencesState({
                accordionId: accordion.id,
                state: {
                    expandedItems: accordion.expandedItems
                }
            }));
        })

    }, []);

    useEffect(() => {
            if (!profileOrProposal.id) {
                return;
            }

            setIsLoading(true);
            switch (estateType.toString()) {
                case EstateType.CURRENT:
                    Promise.all([
                        assetsApiClient.getAssetsSummary(profileOrProposal.id),
                        assetsApiClient.getLegalEntities(profileOrProposal.id),
                        wealthTransferApiClient.getEstateSummary(profileOrProposal.id, EstateType.CURRENT),
                    ]).then(([assetsResponse, legalEntitiesResponse, estateSummaryResponse]) => {
                        setClientAssets(assetsResponse);
                        setLegalEntities(legalEntitiesResponse);
                        setEstateSummaryState(estateSummaryResponse);
                        setIsLoading(false);
                    });
                    break;
                default:
                    wealthTransferApiClient.getEstateSummary(profileOrProposal.id, estateType)
                        .then(estateSummary => {
                            setEstateSummaryState(estateSummary);
                            setIsLoading(false);
                        });
                    break;
            }
        },
        [profileOrProposal.id, estateType]
    );

    if (isLoading || !estateSummaryState) {
        return <LoadingIndicator/>;
    }

    return <WealthTransferReportView
        displayName={displayName}
        profileName={approvedProfile.displayName}
        estateSummary={estateSummaryState}
        estateType={estateType}
        clientAssets={clientAssets!}
        legalEntities={legalEntities!}
    />
}

export default WealthTransferReport;