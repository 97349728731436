import React, {useState} from 'react'
import {VestingPeriod} from 'src/Assets/models/EquityCompensation'
import {AlertBanner, Button, DropdownItem, Label} from 'src/components'
import {UnderlinedHeader} from 'src/components/UnderlinedHeader'
import VestingTable from './VestingTable'
import Dropdown from "../../../components/Dropdown/Dropdown";

export interface CustomizeVestingEventsProps {
    vestingPeriods: VestingPeriod[];
    addVestingPeriod: () => void;
    deleteVestingPeriod: (id: string) => void;
    updateVestingPeriod: (updatedVestingPeriod: VestingPeriod) => void;
    totalShares: number;
    grantDate: string;
    expirationDate: string;
    showAlert: boolean;
    clearVestingPeriods: () => void;
    autoCalculateVestingPeriods: (frequency: string) => void
}

const CustomizeVestingEvents = ({
                                    vestingPeriods,
                                    addVestingPeriod,
                                    updateVestingPeriod,
                                    deleteVestingPeriod,
                                    totalShares,
                                    grantDate,
                                    expirationDate,
                                    showAlert,
                                    clearVestingPeriods,
                                    autoCalculateVestingPeriods
                                }: CustomizeVestingEventsProps) => {
    return (
        <section className="customize-vesting-events">
            <AlertBanner showAlert={showAlert} icon="warning">
                <span>Shares Vested do not equal Total Shares. <span className='fontweight-400'>Update shares to equal 100%</span></span>
            </AlertBanner>
            <UnderlinedHeader
                className="customize-vesting-events-section-header"
                primaryText="Customize Vesting Events"
                rightAlignedContent={vestingPeriods.length && <Button
                    onClick={clearVestingPeriods}
                    iconName="clear"
                    kind="borderless"
                    disabled={!vestingPeriods.length}
                >CLEAR</Button>}
            />
            {vestingPeriods.length ? (
                <div className="customize-vesting-events__list">
                    <VestingTable
                        vestingPeriods={vestingPeriods}
                        updateVestingPeriod={updateVestingPeriod}
                        deleteVestingPeriod={deleteVestingPeriod}
                        totalShares={totalShares}
                        grantDate={grantDate}
                        expirationDate={expirationDate}
                    />
                    <Button
                        size='medium'
                        icon="left"
                        iconName="add"
                        kind="borderless"
                        onClick={addVestingPeriod}
                    >
                        Add Vesting Event
                    </Button>
                </div>
            ) : (
                <AutoCalculateVestingPeriod addVestingPeriod={addVestingPeriod}
                                            autoCalculateVestingPeriods={autoCalculateVestingPeriods}/>
            )}
        </section>
    )
}

export interface AutoCalculateVestingPeriodProps {
    addVestingPeriod: () => void;
    autoCalculateVestingPeriods: (frequency: string) => void
}

const frequencyOptions = Object.freeze(["Monthly", "Quarterly", "Semi-Annual", "Annual"]);
const frequencyDropdownItems = frequencyOptions.map(frequency => (<DropdownItem key={frequency}
                                                                                itemText={frequency}
                                                                                value={frequency}/>));

function AutoCalculateVestingPeriod({addVestingPeriod, autoCalculateVestingPeriods}: AutoCalculateVestingPeriodProps) {
    const [isAutoCalculate, setIsAutoCalculate] = useState<boolean>(false);
    const [vestingFrequency, setVestingFrequency] = useState<string>(frequencyOptions[1]);

    const handleApplyClick = () => {
        autoCalculateVestingPeriods(vestingFrequency)
    }

    return isAutoCalculate ? <div className="layout-data-entry-form">
        <div className="layout-data-entry-form__field">
            <Label label="Vesting Frequency" htmlFor="vestingFrequency"/>
            <Dropdown
                aria-label={"vesting frequency"}
                id="vestingFrequency"
                onChange={(data) => setVestingFrequency(data.value)}
                value={vestingFrequency}
            >
                {frequencyDropdownItems}
            </Dropdown>
        </div>
        <div className="marginleft-lg margintop-lg">
            <Button
                className="marginright-sm"
                icon="none"
                kind="secondary"
                onClick={() => setIsAutoCalculate(false)}
                size={"medium"}
                type="button"
            >
                Cancel
            </Button>
            <Button
                className="marginleft-md"
                icon="none"
                kind="primary"
                onClick={handleApplyClick}
                size={"medium"}
                type="button"
            >
                Apply
            </Button>
        </div>
    </div> : <div className="customize-vesting-events__empty-view">
        <Button
            size='medium'
            onClick={() => setIsAutoCalculate(true)}
            className='marginright-lg'
        >
            Auto-Calculate Vesting Events
        </Button>
        <Button
            size='medium'
            icon="left"
            iconName="add"
            kind="borderless"
            onClick={addVestingPeriod}
        >
            Add Vesting Event
        </Button>
    </div>
}

export default CustomizeVestingEvents