import React from "react";
import {clamp} from "../../utils/Clamp";
import {YearsInput} from "./YearsInput";
import {EventHandler} from "../../constants/common";

type AgeInputProps = {
    age: number,
    className?: string,
    error?: string | null,
    inputDescription?: string,
    label?: string,
    onChange: EventHandler,
    hasInteractions?: boolean,
    whenUserHasInteracted?: () => void,
    forceShowErrors?: boolean,
    onBlur?: () => void
    readOnly?: boolean,
}

export const AgeInput: React.FC<AgeInputProps> = ({
                                                      age,
                                                      className = "",
                                                      onChange,
                                                      error,
                                                      inputDescription = "",
                                                      label = "Age",
                                                      hasInteractions,
                                                      whenUserHasInteracted,
                                                      forceShowErrors,
                                                      onBlur,
                                                      readOnly= false
                                                  }) => {
    return (
        <div className={`parent`}>
            <YearsInput
                className={`parent ${className}`}
                label={label}
                inputDescription={inputDescription}
                onChange={(e: any) => {
                    let updatedAge = parseInt(e.target.value);
                    updatedAge = clamp(updatedAge, 0, 149);
                    onChange(updatedAge);
                }}
                required={true}
                value={age}
                error={error}
                hasInteractions={hasInteractions}
                whenUserHasInteracted={whenUserHasInteracted}
                forceShowErrors={forceShowErrors}
                onBlur={onBlur}
                readOnly={readOnly}
            />
        </div>
    );
}