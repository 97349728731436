import React, {useEffect, useState} from "react";
import ModalWrapper from "./Modal/ModalWrapper/ModalWrapper";

const ResetScreenModal = () => {
    const [showZoomOutModal, setShowZoomOutModal] = useState<boolean>(false);
    useEffect(() => {
        if (window.innerWidth < 1366) {
            setShowZoomOutModal(true);
        }
    }, []);

    return <ModalWrapper
        id="screen-reset-modal"
        isOpen={showZoomOutModal}
        headerText={"Please zoom out"}
        showCloseButton={false}
        buttons={[
            {
                text: "Ok",
                primary: true,
                onClick: () => setShowZoomOutModal(false)
            }
        ]}
    >
        <div className="font-md">
            <span>
                Your browser zoom is not optimized for Goals Driven.
            </span>
            <br/>
            <span>
                Zoom out (Ctrl -) until all sections of this page are clearly visible on-screen.
            </span>
        </div>
    </ModalWrapper>
}

export default ResetScreenModal;