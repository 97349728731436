import {Button} from "../../components";
import React from "react";
import {MemberGroupMember} from "../../ClientManagement/models/InvestorGroupType";
import {ButtonProps} from "../../components/Button/Button";
import {MemberBequestFormData, ResiduaryMemberBequestFormData} from "../models/ui";

export type AddResiduaryBequestsButtonProps = {
    members: MemberGroupMember[],
    bequests: (MemberBequestFormData | ResiduaryMemberBequestFormData)[],
    onClick: () => void,
    buttonText: string,
} & Pick<ButtonProps, 'className' | 'kind' | 'size'>;

export const AddBequestWithMemberButton = (
    {
        members,
        bequests,
        onClick,
        buttonText,
        ...rest
    }: AddResiduaryBequestsButtonProps
) => {
    return <Button
        onClick={onClick}
        disabled={members.length === bequests.length}
        icon='left'
        iconName='add'
        {...rest}
    >{buttonText}</Button>
};