import {PersonalAsset} from "../../models/PersonalAsset";
import {DeleteModalProps} from "../../common/AssetsView";
import React from "react";
import {AssetsViewContextValue} from "../../common/AssetsViewContext";
import PersonalAssetsTableSection from "./PersonalAssetsTableSection";
import {DropResult} from "react-beautiful-dnd";
import {NO_OP} from "../../../constants/common";
import {sortByOrdinalAndPresentValue} from "../common/utils";

type PersonalAssetsTableProps = {
    inEstatePersonalAssets: PersonalAsset[];
    profileId: string,
    viewType: AssetsViewContextValue,
    onDeleteAssetClick: (deleteModalProps: DeleteModalProps) => void,
    hideActionMenu?: boolean,
    isDraggable?: boolean,
    capturePersonalAssetsOrder?: ({source, destination}: DropResult) => void
}
export default function PersonalAssetsTable({
                                                inEstatePersonalAssets,
                                                profileId,
                                                viewType,
                                                onDeleteAssetClick,
                                                hideActionMenu = false,
                                                isDraggable = false,
                                                capturePersonalAssetsOrder = NO_OP
                                            }: PersonalAssetsTableProps) {

    const sortedPersonalAssets = sortByOrdinalAndPresentValue(inEstatePersonalAssets) as PersonalAsset[]

    return (
        <div role="table" className="assets-grid-table" aria-label="personal-assets-table">
            <div role="row" className={`${hideActionMenu? 'assets-grid-table-header_arrange': 'assets-grid-table-header'} assets-grid-with-actionmenu`} tabIndex={-1}>
                <span role="columnheader" className='grid-span-6 paddingleft-xxl textalign-left'>Name</span>
                <span role="columnheader" className='grid-span-4 textalign-right'>Present Value</span>
            </div>

            {sortedPersonalAssets.length > 0 &&
                <PersonalAssetsTableSection
                    inEstatePersonalAssets={sortedPersonalAssets}
                    profileId={profileId}
                    viewType={viewType}
                    onDeleteAssetClick={onDeleteAssetClick}
                    hideActionMenu={hideActionMenu}
                    isDraggable={isDraggable}
                    capturePersonalAssetsOrder={capturePersonalAssetsOrder}
                />
            }

        </div>
    );
}