import {AgendaItem} from "./models/AgendaDetails";

export const DEFAULT_AGENDA_ENTRIES: AgendaItem[] = [
    {
        itemSequenceNo: 1,
        title: "Family Update",
        notes: "Discuss most recent updates with your family and important decisions your family faces"
    },
    {
        itemSequenceNo: 2,
        title: "Assets and Goals",
        notes: "Review current assets and goals"
    },
    {
        itemSequenceNo: 3,
        title: "Asset Allocation",
        notes: "Assess whether current assets are aligned with your financial goals"
    },
    {
        itemSequenceNo: 4,
        title: "Wealth Planning",
        notes: "Review wealth planning strategies"
    },
    {
        itemSequenceNo: 5,
        title: "Market Update",
        notes: "Review the current economic and financial market environment"
    }
]

export const DEFAULT_DISCUSSION_TOPIC: AgendaItem = {
    title: "",
    startTime: "",
    presenterName: "",
    notes: "",
    mode: 'add',
    itemSequenceNo: 0,
}