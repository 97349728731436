import Dropdown, {DropdownItem} from "../../../components/Dropdown/Dropdown";
import React from "react";
import {TrustInflowType, trustInflowTypes} from "../../models/GeneralInflow";

const typeDropdownItems = trustInflowTypes.map(trustInflowType => (<DropdownItem key={trustInflowType}
                                                                                 itemText={trustInflowType}
                                                                                 value={trustInflowType}/>));

type TrustInflowTypeDropdownProps = {
    value: TrustInflowType,
    onChange: (type: TrustInflowType) => void
}
const TrustInflowTypeDropdown = ({
                                     value,
                                     onChange
                                 }: TrustInflowTypeDropdownProps) => {
    return (
        <>
            <label id="trust-inflow-type">
                <b>Trust Inflow Type</b>
            </label>
            <Dropdown
                aria-labelledby="trust-inflow-type"
                value={value}
                onChange={(data) => onChange(data.value)}
            >
                {typeDropdownItems}
            </Dropdown>
        </>
    )
}

export default TrustInflowTypeDropdown;