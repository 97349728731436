import {get, post, put} from "src/core/api";
import {EstateTaxExemptionsReadModel} from "./models/ui";
import {AllBequests, EstateSummary, EstateTaxExemptionsWriteModel, EstateType} from "./models/api";

const getEstateSummary = async (profileId: string, estateType: EstateType): Promise<EstateSummary> => {
    const response = await get(`/holistic-advice/profiles/${profileId}/estateSummary`, {queryParams: {estateType}});
    return response.json()
}

const estateSummaryEvaluateBequests = async (profileId: string, allBequests: AllBequests): Promise<EstateSummary> => {
    const response = await post(`/holistic-advice/profiles/${profileId}/estateSummary/evaluateBequests`, allBequests);
    return response.json()
}

const estateSummaryEvaluateUsedTaxExemptions = async (profileId: string, taxExemptions: EstateTaxExemptionsWriteModel): Promise<EstateSummary> => {
    const response = await post(`/holistic-advice/profiles/${profileId}/estateSummary/evaluateUsedTaxExemptions`, taxExemptions);
    return response.json();
}

const saveWealthTransferPlan = async (profileId: string, allBequests: AllBequests) => {
    await post(`/holistic-advice/profiles/${profileId}/allBequests`, allBequests);
};

const getAllBequests = async (profileId: string): Promise<AllBequests> => {
    const response = await get(`/holistic-advice/profiles/${profileId}/allBequests`);
    return response.json();
};

const getExemptions = async (profileId: string): Promise<EstateTaxExemptionsReadModel> => {
    return (await get(`/holistic-advice/profiles/${profileId}/estateTaxExemptions`)).json();
}

const getMaxExemptionsForDeceasedDate = async (profileId: string, year: number): Promise<{ maximumAllowedTaxExemption: number }> => {
    return (await get(`/holistic-advice/profiles/${profileId}/estateTaxExemptions/maxExemptionByDeceasedYear?year=${year}`)).json()
}

const saveExemptions = async (profileId: string, taxExemptions: EstateTaxExemptionsWriteModel) => {
    await put(`/holistic-advice/profiles/${profileId}/estateTaxExemptions`, taxExemptions);
}

export const wealthTransferApiClient = {
    estateSummaryEvaluateUsedTaxExemptions,
    estateSummaryEvaluateBequests,
    getEstateSummary,
    saveWealthTransferPlan,
    getAllBequests,
    getExemptions,
    saveExemptions,
    getMaxExemptionsForDeceasedDate
};

