import {OnlineMeeting} from "@microsoft/microsoft-graph-types";
import {Client} from "@microsoft/microsoft-graph-client";
import {IMsalContext} from "@azure/msal-react";
import {getAuthProvider} from "../../MsalUtils";

export const initializeGraphClient = (msal: IMsalContext) => {
    return Client.initWithMiddleware({authProvider: getAuthProvider(msal)});
};

export type OnlineMeetingDetails = {
    onlineMeetingId: string;
    onlineMeetingCode: string;
    onlineMeetingJoinUrl: string;
}

export const createOnlineMeeting = async (client: Client): Promise<OnlineMeetingDetails> => {
    const requestBody: OnlineMeeting = {
        startDateTime: new Date().toISOString(),
        subject: 'Online Meeting',
        lobbyBypassSettings: {
            isDialInBypassEnabled: true,
            scope: "everyone"
        },
        joinMeetingIdSettings: {
            isPasscodeRequired: true,
        },
    };
    const createdMeeting = await client.api('/me/onlineMeetings')
        .post(requestBody)
        .catch(() => {
            throw new Error('Failed to create online meeting');
        });
    return mapOnlineMeetingToMeetingDetails(createdMeeting);
}

const mapOnlineMeetingToMeetingDetails = (onlineMeeting: OnlineMeeting) => {
    return {
        onlineMeetingId: onlineMeeting.joinMeetingIdSettings?.joinMeetingId || '',
        onlineMeetingCode: onlineMeeting.joinMeetingIdSettings?.passcode || '',
        onlineMeetingJoinUrl: onlineMeeting.joinWebUrl || ''
    };
}
