import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store/store";
import {emptyProfileRequest, ProfileRequest} from "../models/ProfileResponse";

export type NewClientProfileFormValidation = null | {
    displayName: string | null,
    firstName: string | null,
    lastName: string | null,
    age: string | null,
    personalPlanningHorizon: string | null
}

export type NewClientProfileValidationContainer = {
    newClientProfileForm: null | NewClientProfileFormValidation
}

export type NewClientProfileFormInteractions = {
    displayName: boolean,
    firstName: boolean,
    lastName: boolean,
    age: boolean,
    personalPlanningHorizon: boolean
}

const emptyFormInteractions: NewClientProfileFormInteractions = {
    displayName: false,
    firstName: false,
    lastName: false,
    age: false,
    personalPlanningHorizon: false
}

const initialFormValidationState: NewClientProfileValidationContainer = {newClientProfileForm: null};
export const getEmptyProfileRequestForm: () => ProfileRequest = () => ({...emptyProfileRequest});
export const getInitialFormValidationContainer: () => NewClientProfileValidationContainer = () => ({...initialFormValidationState});
export const getEmptyNewClientFormInteractions: () => NewClientProfileFormInteractions = () => ({...emptyFormInteractions});

export interface NewClientProfileState {
    profileRequestForm: ProfileRequest
    isValidForm: boolean;
    newClientFormValidation: NewClientProfileValidationContainer;
    newClientProfileFormInteractions: NewClientProfileFormInteractions;
    isSaveButtonClicked: boolean;
}

// initialState can be a value or a function. In this case, it's a function because `null` doesn't have enough type
// information by itself to say that it can also be of type FamilyTreeType.
const initialState: NewClientProfileState = {
    profileRequestForm: getEmptyProfileRequestForm(),
    isValidForm: false,
    newClientFormValidation: getInitialFormValidationContainer(),
    newClientProfileFormInteractions: getEmptyNewClientFormInteractions(),
    isSaveButtonClicked: false
}
export const newClientProfileSlice = createSlice({
    name: 'newClientProfile',
    initialState,
    reducers: {
        // reducers need to return the new state, given the existing state and the action. In this case, the new state is
        // just the payload of the action; in other cases, you can mutate the state object, and changes to that state will
        // be recorded to create the new state.
        setProfileRequestForm: (state, action: PayloadAction<ProfileRequest>) => {
            state.profileRequestForm = action.payload;
        },
        setIsValidForm: (state, action: PayloadAction<boolean>) => {
            state.isValidForm = action.payload;
        },
        setNewClientFormValidation: (state, action: PayloadAction<NewClientProfileValidationContainer>) => {
            state.newClientFormValidation = action.payload;
        },
        setNewClientFormInteractions: (state, action: PayloadAction<NewClientProfileFormInteractions>) => {
            state.newClientProfileFormInteractions = action.payload;
        },
        setIsSaveButtonClicked: (state, action: PayloadAction<boolean>) => {
            state.isSaveButtonClicked = action.payload;
        }
    },
});

export const {
    setProfileRequestForm,
    setIsValidForm,
    setNewClientFormValidation,
    setNewClientFormInteractions,
    setIsSaveButtonClicked
} = newClientProfileSlice.actions;

export const selectProfileRequestForm = (state: RootState) => state.client.newClientProfile.profileRequestForm;
export const selectIsValidForm = (state: RootState) => state.client.newClientProfile.isValidForm;
export const selectNewClientFormValidation = (state: RootState) => state.client.newClientProfile.newClientFormValidation;
export const selectNewClientFormInteractions = (state: RootState) => state.client.newClientProfile.newClientProfileFormInteractions;
export const selectIsSaveButtonClicked = (state: RootState) => state.client.newClientProfile.isSaveButtonClicked;

export default newClientProfileSlice.reducer;