import {ColumnCounter, TableCell} from "../../../components";
import {AssetRelianceAssetType, AssetRelianceStack} from "../../models/AssetRelianceResponse";
import {AssetTableDisplay} from "./AssetTableDisplay";

export class ReviewAssetTableDisplay extends AssetTableDisplay {
    constructor(
        columnCounter: ColumnCounter,
        protected readonly rowStacks: AssetRelianceStack[],
        protected readonly columnStacks: AssetRelianceStack[],
    ) {
        super(columnCounter, rowStacks, columnStacks);
    }

    protected get headerValues(): TableCell<number>[] {
        const headerValues: number[] = Array(this.columnCount).fill(0);
        if (this.columnCount > 0) {
            for (let index = 0; index < this.columnCount; index++) {
                const stack = this.columnStacks[index];
                if (stack.stackAssetType === AssetRelianceAssetType.INVESTABLE_PORTFOLIO_ASSET) {
                    headerValues[index] = stack.investableValue;
                } else {
                    headerValues[index] = stack.netAssets;
                }
            }
        }
        return headerValues.map((value) => this.createTableCell({value}));
    }
}