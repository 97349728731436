import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {setShowOutOfEstateAssets, setShowOutOfEstateCNW} from "./OutOfEstateAssetsSlice";

export const useOutOfEstateAssetSummary = () => {
    const dispatch = useAppDispatch();
    const showOutOfEstateAssetSummary = useAppSelector((state) => {
        return state.client.outOfEstate.showOutOfEstateAssetSummary;
    });

    const setShowOutOfEstateAssetSummary = (show: boolean) => {
        dispatch(setShowOutOfEstateAssets(show));
    };

    return {showOutOfEstateAssetSummary, setShowOutOfEstateAssetSummary};
};

export const useOutOfEstateCurrentNetWorth = () => {
    const dispatch = useAppDispatch();
    const showOutOfEstateCurrentNetWorth = useAppSelector((state) => {
        return state.client.outOfEstate.showOutOfEstateCurrentNetWorth;
    });

    const setShowOutOfEstateCurrentNetWorth = (show: boolean) => {
        dispatch(setShowOutOfEstateCNW(show));
    };

    return {showOutOfEstateCurrentNetWorth, setShowOutOfEstateCurrentNetWorth};
};