import React from 'react';
import {ReactComponentLike} from "prop-types";
import {Icon} from '../Icon';
import classNames from 'classnames';
import {AccordionItem} from '../Accordion';
import {Link, NavDrawerSubItem} from ".";
import {HistoryProps, LinkProps} from "../../models/routeData/RouteParamTypes";

type NavDrawerItemProps = {
    history?: HistoryProps,
    link: LinkProps,
    LinkRenderer: ReactComponentLike | string,
}

export const NavDrawerItem = ({
                                  history = {location: {pathname: '', search: '', hash: '', state: ''}},
                                  link,
                                  LinkRenderer = 'a'
                              }: NavDrawerItemProps) => {

    const location = history['location'];
    const currentPath = location['pathname'];
    const hasActiveChild = Boolean(
        link.children &&
        link.children.find((child: any) => {
            if (child.pathFunction) {
                return currentPath.includes(child.pathFunction(location));
            } else if (child.path) {
                return currentPath.includes(child.path);
            } else {
                return false;
            }
        })
    );

    const HeaderNavComp: React.FC<{ expanded: boolean }> = ({expanded}: { expanded: boolean }) => {
        const icon = expanded ? 'chevron_up' : 'chevron_down';
        return (
            <>
                <span>{link.name}</span>
                {link.subMenuType && link.subMenuType === 'right' ? (
                    <Icon name={'chevron_right'}/>
                ) : (
                    <Icon name={icon}/>
                )}
            </>
        )
    }

    return (
        <>
            {!link.children ? (
                <li className="nav-drawer-item">
                    <Link className="nav-drawer-item__link" link={link} location={location} Renderer={LinkRenderer}/>
                </li>
            ) : (
                <AccordionItem
                    buttonClassName={classNames('nav-drawer-item__link', {
                        active: hasActiveChild,
                        disabled: !link.enabled
                    })}
                    HeaderComponent={HeaderNavComp}
                    primaryText={link.name}
                    uuid={link.id}
                >
                    {link.children.filter((child: LinkProps) => !child['hidden'])
                        .map((child: LinkProps) => (
                            <NavDrawerSubItem
                                key={child.id}
                                link={child}
                                LinkRenderer={LinkRenderer}
                                location={location}
                            />
                        ))}
                </AccordionItem>
            )}
        </>
    )
}
