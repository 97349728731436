import {PersonalAsset} from "../../models/PersonalAsset";
import {formatCurrency} from "../../../utils/format";
import {TableActionDropdownMenu} from "../../../components/Table/TableActionDropdownMenu";
import {DropdownItem, Icon} from "../../../components";
import React from "react";
import {TableCell} from "../../../components/Table/TableCell";
import classNames from "classnames";
import useProfileEditableState from "../../../hooks/useProfileEditableState";

type AssetSummaryPersonalAssetProps = {
    personalAsset: PersonalAsset;
    onClickEdit: (id: string) => void;
    onClickDelete: (personalAsset: PersonalAsset) => void;
    renderPersonalAssetDetails: (personalAsset: PersonalAsset) => React.ReactNode;
    actionsDisabled: boolean;
    gridClassName: string,
    hideActionMenu?: boolean
};
export const PersonalAssetRow = ({
                                     personalAsset,
                                     onClickEdit,
                                     onClickDelete,
                                     renderPersonalAssetDetails,
                                     actionsDisabled,
                                     gridClassName,
                                     hideActionMenu = false
                                 }: AssetSummaryPersonalAssetProps) => {
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    return (<div key={`personal-asset-row-${personalAsset.id}`}
                 role="row"
                 aria-label="Personal Asset Row"
                 className={classNames("assets-grid-table-row", gridClassName)}
                 tabIndex={0}>
        {renderPersonalAssetDetails(personalAsset)}
        <TableCell text={formatCurrency(personalAsset.inEstateValue)} className="textalign-right"/>
        {!hideActionMenu && <TableActionDropdownMenu
            disabled={actionsDisabled}
            ariaLabel={`${personalAsset.description} Menu`}>
            <DropdownItem
                itemText="Edit Asset"
                value="Edit Asset"
                onClick={() => onClickEdit(personalAsset.id)}
            />
            <DropdownItem
                itemText="Delete Asset"
                value="Delete Asset"
                disabled={isProfileWithProposalsOrArchived}
                onClick={() => onClickDelete(personalAsset)}
            >
                <Icon name="delete"
                      className="asset-table-delete-icon"/>
            </DropdownItem>
        </TableActionDropdownMenu>}
    </div>);
};
