import React from "react";
import diagonalImage from '../images/diagonal-pattern.png';
import classNames from "classnames";

export type BarChartSummaryTableItem = {
    leftText: string;
    rightText: string;
    isActive: boolean;
    keyColor?: string
}

export type BarChartSummaryTableProps = {
    leftHeader: string,
    rightHeader: string,
    items: BarChartSummaryTableItem[]
};

export const BarChartSummaryTable = (props: BarChartSummaryTableProps) => {
    // TODO: use testid instead of label
    return <div role="table" className="bar-chart-summary-table" aria-label="Bar Chart Summary Table">
        <TableRow isHeader={true} leftText={props.leftHeader} rightText={props.rightHeader} showIcon={false}
                  highlight={false}/>
        {props.items.map((item, i) =>
            <TableRow isHeader={false} key={i} leftText={item.leftText} rightText={item.rightText} showIcon
                      highlight={item.isActive} keyColor={item.keyColor}/>)}
    </div>;
}

type TableRowProps = {
    leftText: string,
    rightText: string,
    showIcon: boolean,
    highlight: boolean,
    keyColor?: string,
    isHeader: boolean,
}

type TableRowBGProps = {
    backgroundImage?: string,
    backgroundRepeat?: string,
    backgroundColor?: string
}

const TableRow = (props: TableRowProps) => {
    const iconStyle: TableRowBGProps = props.highlight
        ? {backgroundImage: `url(${diagonalImage})`, backgroundRepeat: 'repeat'}
        : {};

    if (props.keyColor) {
        iconStyle.backgroundColor = props.keyColor;
    }

    const cellRole = props.isHeader ? "columnheader" : "cell";

    return <div role="row"
                className={classNames("bar-chart-summary-table-row", {
                    'bar-chart-summary-table-row__highlight': props.highlight
                })}>
        <div>{props.showIcon && <div className="bar-chart-summary-table-row-icon" style={iconStyle}/>}
            <span role={cellRole}>{props.leftText}</span>
        </div>
        <div role={cellRole}>{props.rightText}</div>
    </div>
}
