import React from 'react';
import {InfoPopover, PercentInput} from "../../components";
import {EffectiveTaxRateType} from "../models/EffectiveTaxRateType";
import {ReferenceDataType} from "../../models/referenceData/ReferenceDataType";
import {EffectiveTaxRateReferenceData} from "../../models/referenceData/effectiveTaxRateReferenceData";
import {clampStringsWithOptions} from "../../utils/Clamp";

type EffectiveTaxRateProps = {
    taxRate: EffectiveTaxRateType,
    updateTaxRate: (taxRate: EffectiveTaxRateType) => void,
    referenceData: ReferenceDataType,
    isReadOnly?: boolean
}

export const calculateTotalTax = (federal: string, stateAndLocal: string) => {
    const fedNum = parseFloat(federal);
    const stateNum = parseFloat(stateAndLocal);
    return isNaN(fedNum) || isNaN(stateNum) ? '0.00'
        : (fedNum + stateNum).toFixed(2);
}

export const taxRateWithDefaults = (taxRate: EffectiveTaxRateType, effectiveTaxRates: EffectiveTaxRateReferenceData) => {
    const {
        federalTaxRateDefault,
        stateTaxRateDefault,
        capitalGainsPercentDefault
    } = effectiveTaxRates;
    return {
        ...taxRate,
        federal: federalTaxRateDefault,
        stateAndLocal: stateTaxRateDefault,
        total: calculateTotalTax(federalTaxRateDefault, stateTaxRateDefault),
        capitalGains: capitalGainsPercentDefault
    };
}

export const EffectiveTaxRates = (props: EffectiveTaxRateProps) => {
        const {taxRate, updateTaxRate, referenceData, isReadOnly = false} = props;
        const {
            federalTaxRateMinimum,
            federalTaxRateMaximum,
            stateTaxRateMinimum,
            stateTaxRateMaximum,
            capitalGainsPercentMinimum,
            capitalGainsPercentMaximum
        } = referenceData.taxRates;


        const federalTaxRateHandler = (value: string) => {
            const clampedFed = clampStringsWithOptions(value, federalTaxRateMinimum, federalTaxRateMaximum, {
                defaultValue: referenceData.taxRates.federalTaxRateDefault
            });
            const clampedStateAndLocal = clampStringsWithOptions(String(taxRate.stateAndLocal), stateTaxRateMinimum, stateTaxRateMaximum, {
                defaultValue: referenceData.taxRates.stateTaxRateDefault
            });
            updateTaxRate({
                    ...taxRate,
                    federal: value,
                    total: calculateTotalTax(clampedFed, clampedStateAndLocal)
                }
            )
        }

        const stateAndLocalTaxRateHandler = (value: string) => {
            const clampedFed = clampStringsWithOptions(String(taxRate.federal), federalTaxRateMinimum, federalTaxRateMaximum, {
                defaultValue: referenceData.taxRates.federalTaxRateDefault
            });
            const clampedStateAndLocal = clampStringsWithOptions(value, stateTaxRateMinimum, stateTaxRateMaximum, {
                defaultValue: referenceData.taxRates.stateTaxRateDefault
            });
            updateTaxRate({
                    ...taxRate,
                    stateAndLocal: value,
                    total: calculateTotalTax(clampedFed, clampedStateAndLocal)
                }
            )
        }

        const capitalGainsTaxRateHandler = (value: string) => updateTaxRate({
            ...taxRate,
            capitalGains: value
        })

        return (
            <div>
                <span className="h4">Effective Tax Rates</span>
                <InfoPopover
                    content={
                        <div>
                            <b>Effective Tax Rate</b> is the average rate at which an individual or married couple's
                            income is taxed.
                            It is calculated by dividing the amount of income tax paid by taxable income. Refer to the
                            client's income tax return or the <u><a
                            href="#"
                            onClick={() => {
                                window.open("https://taxfoundation.org/publications/state-individual-income-tax-rates-and-brackets/#Current", "_blank", "width=1366, height=1024");
                            }}>
                            Federal Taxes Reference Guide</a></u> for marginal tax rates.
                        </div>
                    }
                    direction="bottom"
                    width="345px"
                    label="effective-tax-rates"
                />
                <hr className="section"/>
                <div className="h4 parent">
                    {isReadOnly ? <div>
                            <label className="h5 label" htmlFor="federal">
                                Federal
                            </label>
                            <div className="totalInput totalTax-alignment" id="federalInput">
                                <span className="disabledInput">{parseFloat(taxRate.federal).toFixed(2)}{'%'}</span>
                            </div>
                        </div> :
                        <PercentInput
                            defaultValue={referenceData.taxRates.federalTaxRateDefault}
                            className={"effectiveTaxRates paddingbottom-12"}
                            label={"Federal"}
                            minValue={Number(federalTaxRateMinimum)}
                            maxValue={Number(federalTaxRateMaximum)}
                            onChange={federalTaxRateHandler}
                            value={taxRate.federal}
                        />}
                </div>
                <div className="h4 parent">
                    {/*TODO : For hasProposal = 'true', temporary fields have been added. Needs to change once theme is introduced.*/}

                    {isReadOnly ? <div>
                            <label className="h5 label" htmlFor="stateAndLocal">
                                State and Local
                            </label>
                            <div className="totalInput totalTax-alignment" id="stateAndLocalInput">
                                <span className="disabledInput">{parseFloat(taxRate.stateAndLocal).toFixed(2)}{'%'}</span>
                            </div>
                        </div> :
                        <PercentInput
                            defaultValue={referenceData.taxRates.stateTaxRateDefault}
                            className={"effectiveTaxRates"}
                            label={"State and Local"}
                            minValue={Number(referenceData && stateTaxRateMinimum)}
                            maxValue={Number(referenceData && stateTaxRateMaximum)}
                            onChange={stateAndLocalTaxRateHandler}
                            value={taxRate.stateAndLocal}
                        />}
                </div>
                <div className="h4 parent">
                    <label className="h5 label" htmlFor="total">
                        Total
                    </label>
                    <div className="totalInput totalTax-alignment" id="totalInput">
                        <span className="disabledInput">{taxRate.total}{'%'}</span>
                    </div>
                </div>
                <div className="h4 parent">
                    {/*TODO : For hasProposal = 'true', temporary fields have been added. Needs to change once theme is introduced.*/}

                    {isReadOnly ? <div>
                            <label className="h5 label" htmlFor="capitalGains">
                                Capital Gains
                            </label>
                            <div className="totalInput totalTax-alignment" id="capitalGainsInput">
                                <span className="disabledInput">{parseFloat(taxRate.capitalGains).toFixed(2)}{'%'}</span>
                            </div>
                        </div> :
                        <PercentInput
                            className={"effectiveTaxRates"}
                            defaultValue={referenceData.taxRates.capitalGainsPercentDefault}
                            label={"Capital Gains"}
                            minValue={Number(referenceData && capitalGainsPercentMinimum)}
                            maxValue={Number(referenceData && capitalGainsPercentMaximum)}
                            onChange={capitalGainsTaxRateHandler}
                            value={taxRate.capitalGains}
                        />}
                </div>
            </div>
        );
    }
;

export default EffectiveTaxRates;
