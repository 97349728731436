export type EffectiveTaxRateType = {
    federal: string,
    stateAndLocal: string,
    total: string,
    capitalGains: string
}

export const emptyEffectiveTaxRate = {
    federal: '',
    stateAndLocal: '',
    total: '',
    capitalGains: ''
}