import {emptyPresentValueResponse, PresentValueResponse} from "./PresentValue";
import {GoalSubType, PrioritizationType} from "./GoalType";

export enum FamilyGoalsType {
    PRE_SCHOOL = "Pre-School",
    PRIMARY_SCHOOL = "Primary School",
    SECONDARY_SCHOOL = "Secondary School",
    UNDERGRADUATE_SCHOOL = "Undergraduate School",
    GRADUATE_COLLEGE = "Graduate School",
    WEDDING = "Wedding",
    ANNUAL_EXCLUSION_GIFT = "Annual Exclusion Gift",
    HOME = "Home",
    WEALTH_TRANSFER = "Wealth Transfer",
    SUPPLEMENTAL_INCOME = "Supplemental Income",
    GIFT = "Gift",
    AUTOMOBILE = "Automobile",
    FIVE_TWENTY_NINE_PLAN = "529 Plan",
    CUSTOM = "Custom",
    UNKNOWN = "__"
}

export type FamilyGoalUserInputs = {
    description: string,
    selectedGoalSubType: GoalSubType
    annualFlow: number,
    startDate: string,
    endDate: string,
    riskAsset: number | null
    riskControl: number | null
    isFundedByNonInvestableAssets: boolean
    isAchieved?: boolean
}

export const emptyFamilyGoalUserInputs: FamilyGoalUserInputs = {
    description: "",
    selectedGoalSubType: FamilyGoalsType.UNKNOWN,
    annualFlow: 0,
    startDate: "",
    endDate: "",
    riskAsset: 0,
    riskControl: 0,
    isFundedByNonInvestableAssets: false,
}

export type FamilyGoalRequest = {
    id?: string,
    beneficiaryId?: string,
    ownerId?: string,
    selectedGoalType: GoalSubType,
    familyAssetsAmount?: number,
} & Omit<FamilyGoalUserInputs, 'selectedGoalSubType'>

export type FamilyGoalType = {
    id?: string,
    createdTimestamp?: string,

    beneficiaryId: string,
    ownerId: string,

    userInputs: FamilyGoalUserInputs
    calculatedFields: PresentValueResponse
    prioritizationType?:PrioritizationType,

    familyAssetsAmount?: number
}

export const newFamilyGoal = (
    ownerId: string,
    beneficiaryId: string,
    selectedGoalSubType: GoalSubType,
    startDate: string,
    endDate: string,
): FamilyGoalType => {
    return {
        ownerId,
        beneficiaryId,
        calculatedFields: emptyPresentValueResponse,
        userInputs: {
            riskControl: null,
            riskAsset: null,
            annualFlow: 0,
            description: selectedGoalSubType,
            selectedGoalSubType: selectedGoalSubType,
            startDate,
            endDate,
            isFundedByNonInvestableAssets: false
        },
        prioritizationType:PrioritizationType.NEEDS
    }
}
