import React from "react";
import classNames from "classnames";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {Button, Icon} from "../../components";
import {
    resetMeeting,
    selectActiveMeeting,
    selectAudienceMembers,
    selectMeetingControlRequest,
    selectParticipatingInMeeting,
    selectShowClientView,
    selectShowExpandedVideoGallery,
    selectShowMeetingControls,
    setActiveMeeting,
    setMeetingModalVisibility,
    setShowClientView
} from "./meetingSlice";
import {meetingApiClient} from "./MeetingApiClient";
import {Meeting, MeetingParticipation, MeetingStatus, MeetingType} from "./Meeting";
import {useHistory} from "react-router-dom";
import {ProfileResponse} from "../models/ProfileResponse";
import {selectApprovedProfile} from "../ClientProfile/approvedProfileSlice";
import useMeetingUtils from "./useMeetingUtils";
import {msalUtils} from "../../MsalUtils";
import {useMsal} from "@azure/msal-react";
import {NO_OP} from "../../constants/common";
import {useAppInsights} from "src/AppInsights";
import {useRelayContext} from "./Relay/types/RelayContext";
import ParticipantAudioVideo from "./ParticipantAudioVideo";
import {CommunicationsConnectionState, useCommunicationsContext} from "./CommunicationsContext";
import {useSelector} from "react-redux";
import {isUserInMeeting} from "./utils/MeetingUtils";

export const MeetingControls: React.FC = () => {
    const showMeetingControls = useAppSelector(selectShowMeetingControls);
    return (showMeetingControls
            ? <div className="meeting-controls"><ControlMenu/></div>
            : null
    )
}

const ControlMenu: React.FC = () => {
    const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true);
    const communicationsContext = useCommunicationsContext();
    const showExpandedVideoGallery = useSelector(selectShowExpandedVideoGallery);

    return (
        <div>
            <ControlMenuHeader onClick={() => setIsCollapsed(!isCollapsed)} isCollapsed={isCollapsed}/>
            {
                !isCollapsed
                && communicationsContext.connectionState === CommunicationsConnectionState.CONNECTED
                && !showExpandedVideoGallery
                && <ParticipantAudioVideo/>
            }
            <ControlMenuActions isCollapsed={isCollapsed}/>
        </div>
    )
}

type ControlMenuHeaderDisplayProps = {
    label: string
    onClick: () => void
}

const ControlMenuHeaderDisplay: React.FC<ControlMenuHeaderDisplayProps> = ({label, onClick}) => (
    <div className="flex-grow-1">
        <span onClick={onClick} className="control-menu-item__label">{label}</span>
    </div>
)

type ControlMenuHeaderProps = {
    onClick: () => void
    isCollapsed: boolean;
}

const ControlMenuHeader: React.FC<ControlMenuHeaderProps> = (props: ControlMenuHeaderProps) => {
    const {status} = useAppSelector(selectActiveMeeting)!;

    let HeaderComponent;

    switch (status) {
        case MeetingStatus.STARTED:
            HeaderComponent = <ControlMenuHeaderDisplay onClick={props.onClick} label={"Meeting In Progress"}/>
            break;
        case MeetingStatus.PAUSED:
            HeaderComponent = <ControlMenuHeaderDisplay onClick={props.onClick} label={"Meeting Paused"}/>
            break;
        case MeetingStatus.STOPPED:
            HeaderComponent = <ControlMenuHeaderDisplay onClick={props.onClick} label={"Meeting Stopped"}/>
            break;
        case MeetingStatus.ENDED:
            HeaderComponent = <ControlMenuHeaderDisplay onClick={props.onClick} label={"Meeting Ended"}/>
            break;
        default:
            HeaderComponent = <ControlMenuHeaderDisplay onClick={props.onClick} label={"Meeting Not Started"}/>
    }

    return <>
        <div role="heading" className={
            classNames('control-menu-heading',
                {"meeting-not-started": status === MeetingStatus.CREATED},
                {"meeting-in-progress": status === MeetingStatus.STARTED},
                {"meeting-paused": status === MeetingStatus.PAUSED},
                {"meeting-stopped": status === MeetingStatus.STOPPED},
                {"meeting-not-started": status === MeetingStatus.ENDED},
            )}>
            <div className="menu-chevron-container" onClick={props.onClick}>
                <Icon className={
                    classNames('control-menu-heading__chevron',
                        {"meeting-in-progress": status === MeetingStatus.STARTED}
                    )} data-testid="chevron" name={props.isCollapsed ? 'chevron_up' : 'chevron_down'} size="large"/>
            </div>
            <div className="header-dropdown">
                {HeaderComponent}
            </div>
        </div>
    </>;
};

interface ControlMenuActionsProps {
    isCollapsed: boolean;
}

const ControlMenuActions: React.FC<ControlMenuActionsProps> = ({isCollapsed}) => {
    const {isCurrentUserPresenting} = useMeetingUtils();
    const meeting = useAppSelector(selectActiveMeeting) as Meeting;
    const isClientViewOpened = useAppSelector(selectShowClientView);
    const dispatch = useAppDispatch();

    const onClickOpenClientView = () => {
        dispatch(setShowClientView(true));
    }

    return (
        <>
            {!isCollapsed &&
                <div className="control-menu-actions">
                    <div>
                        <span className="meeting-presenter-label">Presenter:</span>
                    </div>
                    <div>
                    <span
                        className="meeting-presenter">{meeting.presenterName} ({meeting.presenter?.toUpperCase()})
                    </span>
                    </div>
                    {meeting.status !== MeetingStatus.ENDED
                        && <MenuActionButton label="Open Client View"
                                             icon="presentation"
                                             color="primary"
                                             onClick={onClickOpenClientView}
                                             disabled={isClientViewOpened}
                        />}
                    {isCurrentUserPresenting ? <PresenterControlMenuActions/> : <ParticipantControlMenuActions/>}
                </div>
            }
        </>
    );
};

type MeetingEventData = {
    name: string;
    action: string;
    data: string;
}

const PresenterControlMenuActions: React.FC = () => {
    const meeting = useAppSelector(selectActiveMeeting) as Meeting;
    const {id} = useAppSelector(selectApprovedProfile) as ProfileResponse;
    const dispatch = useAppDispatch();
    const history = useHistory();
    const appInsights = useAppInsights();
    const participatingInMeeting = useAppSelector(selectParticipatingInMeeting)!;

    const triggerMeetingEvent = (data: MeetingEventData) => {
        if (meeting.type == MeetingType.CLIENT_MEETING) {
            appInsights.trackEvent({
                name: data.name,
                properties: {
                    screen: window.location.href.split("/").pop(),
                    action: data.action,
                    meetingStatus: participatingInMeeting ? MeetingParticipation.IN_MEETING : MeetingParticipation.OUT_OF_MEETING,
                    meetingId: meeting.onlineMeetingId,
                    data: data.data
                }
            })
        }
    }

    let transitionMeetingStatusButton;
    switch (meeting.status) {
        case MeetingStatus.STARTED:
            transitionMeetingStatusButton =
                <MenuActionButton label="Pause Sharing" icon="pause_circle_outline" color="primary" onClick={() => {
                    meetingApiClient.updateMeeting({...meeting, status: MeetingStatus.PAUSED})
                        .then((updateMeeting) => dispatch(setActiveMeeting(updateMeeting)))
                        .catch(error => console.error('Could not update meeting', error.message))
                }}/>
            break;
        case MeetingStatus.PAUSED:
        case MeetingStatus.STOPPED:
            transitionMeetingStatusButton =
                <MenuActionButton label="Resume Sharing" icon="play_circle_outline" color="primary" onClick={() => {
                    meetingApiClient.updateMeeting({...meeting, status: MeetingStatus.STARTED})
                        .then((updateMeeting) => dispatch(setActiveMeeting(updateMeeting)))
                        .catch(error => console.error('Could not update meeting', error.message))
                }}/>
            break;
        default:
            transitionMeetingStatusButton =
                <MenuActionButton label="Start Meeting" icon="play_circle_outline" color="primary" onClick={() => {
                    meetingApiClient.updateMeeting({...meeting, status: MeetingStatus.STARTED})
                        .then((updateMeeting) => {
                            triggerMeetingEvent({
                                name: "StartMeeting",
                                action: "Start meeting button click",
                                data: "Start meeting button clicked"
                            });
                            dispatch(setActiveMeeting(updateMeeting))
                        }).catch(error => console.error('Could not update meeting', error.message))
                }}/>
            break;
    }

    const handleEndMeeting = () => {
        const updatedMeeting = {...meeting, status: MeetingStatus.ENDED};
        meetingApiClient.updateMeeting(updatedMeeting)
            .then(() => {
                triggerMeetingEvent({
                    name: "EndMeeting",
                    action: "End meeting button click",
                    data: "End meeting button clicked"
                });
                dispatch(setActiveMeeting(updatedMeeting));
                dispatch(resetMeeting());
                history.push(`/Profile/${id}`);
            }).catch(error => console.error('Could not update meeting', error.message))
    };

    return <>
        {transitionMeetingStatusButton}
        <MenuActionButton label="QR Code" icon="classifications" color="primary" onClick={() => {
            dispatch(setMeetingModalVisibility({meetingInfo: true, meetingSchedule: false}))
        }}/>
        {meeting.status === MeetingStatus.STARTED || meeting.status === MeetingStatus.PAUSED ?
            <MenuActionButton label="Stop Meeting" icon="stop_circle_outline" color="alternate" onClick={() => {
                meetingApiClient.updateMeeting({...meeting, status: MeetingStatus.STOPPED})
                    .then((updateMeeting) => dispatch(setActiveMeeting(updateMeeting)))
                    .catch(error => console.error('Could not update meeting', error.message))
            }}/> : <React.Fragment/>
        }
        {meeting.status === MeetingStatus.CREATED || meeting.status === MeetingStatus.STOPPED ?
            <MenuActionButton label="End Meeting" icon="cancel_outline" color="alternate" onClick={handleEndMeeting}/> :
            <React.Fragment/>
        }
    </>
};

const ParticipantControlMenuActions: React.FC = () => {
    const meeting = useAppSelector(selectActiveMeeting) as Meeting;
    const history = useHistory();
    const msal = useMsal();
    const currentUser = msalUtils.getLanId(msal);
    const meetingControlRequest = useAppSelector(selectMeetingControlRequest);
    const {sharedObjectMutators} = useRelayContext();
    const audience = useAppSelector(selectAudienceMembers);
    const {presenter} = useAppSelector(selectActiveMeeting)!;
    const dispatch = useAppDispatch();

    const requestControl = () => {
        const hasPresenterJoinedMeeting = audience
            && presenter
            && isUserInMeeting(audience, presenter);
        if (hasPresenterJoinedMeeting) {
            const controlRequest = {
                user: {
                    id: msalUtils.getLanId(msal),
                    name: msalUtils.getAccountName(msal)
                }
            };
            sharedObjectMutators.requestControl(controlRequest);
        }
    };

    return <>
        {
            meeting.status === MeetingStatus.STARTED
            && meetingControlRequest?.user.id === currentUser
            && !meetingControlRequest?.response
            && <MenuActionButton label="Requesting..." icon="hand_right" color="primary" onClick={NO_OP}/>
        }

        {meeting.status === MeetingStatus.STARTED
            && meetingControlRequest?.user.id !== currentUser
            && <MenuActionButton label="Request Control" icon="hand_right" color="primary" onClick={requestControl}/>}

        <MenuActionButton label="QR Code" icon="classifications" color="primary" onClick={() => {
            dispatch(setMeetingModalVisibility({meetingInfo: true, meetingSchedule: false}))
        }}/>
        <MenuActionButton label="Leave Meeting" icon="cancel_outline" color="alternate" onClick={() => {
            history.push(`/Profile/${meeting.profileId}`);
        }}/>

    </>;

};

type ControlMenuItemProps = {
    color: 'primary' | 'alternate',
    onClick: () => void,
    label: string;
    icon?: string;
    classList?: string[],
    disabled?: boolean
};

const MenuActionButton: React.FC<ControlMenuItemProps> = ({onClick, label, icon, color, classList = [], disabled= false}) => {
    return <Button type="button"
                   kind="secondary"
                   size="medium"
                   icon={icon ? 'right' : 'none'}
                   iconName={icon}
                   onClick={onClick}
                   className={classNames('control-menu-item', `control-menu-item-${color}`, ...classList)}
                   disabled={disabled}
    >
        <span className="control-menu-item__label">{label}</span>
    </Button>;
}
