import {OwnershipDetailsFormData} from "../../models/Ownership";
import ClientMemberOwnershipTable from "../components/ClientMemberOwnershipTable";
import {MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import {MemberDropdown} from "../../../WealthTransfer/components/MemberDropdown";
import {mapLivingMemberGroupToList} from "../../../ClientManagement/mappers";
import {Label} from "../../../components";
import SoleNonClientMemberOwnershipTable from "../components/SoleNonClientMemberOwnershipTable";
import {DEFAULT_SOLE_OWNERSHIP_PERCENTAGE} from "./SoleOwnership";

type IndividualOwnerDropdownProps = {
    formData: OwnershipDetailsFormData,
    onFormDataChange: (updatedFormData: OwnershipDetailsFormData) => void,
    totalAssetValue?: number | null,
    memberGroup: MemberGroup,
    disableOutOfEstate: boolean,
    isProfileWithProposalsOrArchived?: boolean
}

export const IndividualOwnerDropdown = ({
                                            memberGroup,
                                            onFormDataChange,
                                            formData,
                                            totalAssetValue,
                                            disableOutOfEstate = false,
                                            isProfileWithProposalsOrArchived = false
                                        }: IndividualOwnerDropdownProps) => {

    const members = mapLivingMemberGroupToList(memberGroup, disableOutOfEstate);
    const selectedOwnerId = formData.nonClientMemberOwnerships[0]?.memberId || formData.memberOwnerships[0].memberId;
    const selectedOwner = members.find(member => member.id === selectedOwnerId) ?? memberGroup.primaryMember;

    const isSelectedOwnerOutOfEstate = isMemberOutOfEstate(memberGroup, selectedOwnerId);

    const onIndividualChange = (newMemberId: string) => {
        const updatedFormData = {...formData};
        const newOwnership = {
            memberId: newMemberId,
            percentage: DEFAULT_SOLE_OWNERSHIP_PERCENTAGE,
            isRevocableTrust: false
        };

        updatedFormData.memberOwnerships = [newOwnership];
        updatedFormData.nonClientMemberOwnerships = [];
        if (isMemberOutOfEstate(memberGroup, newMemberId)) {
            updatedFormData.nonClientMemberOwnerships = [newOwnership];
            updatedFormData.memberOwnerships = [];
        }

        onFormDataChange(updatedFormData)
    };


    return (
        <>
            <div className="layout-data-entry-form__field">
                <Label labelId="owner" label="Owner"/>
                <div style={{whiteSpace: "pre", width: "140px"}}>
                <MemberDropdown
                    aria-labelledby="owner"
                    value={selectedOwnerId}
                    members={members}
                    className="ownership-dropdown-item"
                    onChange={onIndividualChange}
                />
                </div>
            </div>
            {isSelectedOwnerOutOfEstate ?
                <SoleNonClientMemberOwnershipTable onUpdate={onFormDataChange}
                                                   selectedMember={selectedOwner}
                                                   ownershipDetails={formData}
                                                   totalAssetValue={totalAssetValue}
                                                   isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}

                /> :
                <ClientMemberOwnershipTable onUpdate={onFormDataChange}
                                            primaryMember={selectedOwner}
                                            ownershipDetails={formData}
                                            totalAssetValue={totalAssetValue}
                                            isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
                />
            }
        </>
    );
};

function isMemberOutOfEstate(memberGroup: MemberGroup, memberId: string) {
    return memberGroup.additionalMembers.findIndex(member => member.id === memberId) >= 0;
}
