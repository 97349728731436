import React, {MouseEventHandler, PropsWithChildren} from "react";
import {Dropdown} from "../Dropdown";
import classNames from "classnames";

interface TableActionMenuProps {
    ariaLabel: string,
    className?: string,
    disabled?: boolean,
    size?: 'small' | 'medium' | 'large',
    onClick?: MouseEventHandler
}

export function TableActionDropdownMenu({
                                            className,
                                            ariaLabel,
                                            children,
                                            onClick,
                                            size = "small",
                                            disabled = false
                                        }: PropsWithChildren<TableActionMenuProps>) {
    return <span className={classNames("table-action-menu", className)} >
            <Dropdown
                size={size}
                disabled={disabled}
                className="table-action-menu__dropdown"
                dropdownKind="menu"
                iconNameClose="menu_dots"
                iconNameOpen="menu_dots"
                alignRight={true}
                buttonKind="borderless"
                buttonAriaLabel={ariaLabel}
                buttonIcon="only"
                panelWidth={240}
                onClick={onClick}
            >
                {children}
            </Dropdown>
        </span>;
}
