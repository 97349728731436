import React, {ChangeEvent, ReactElement, useEffect, useState} from "react";
import {Col, Row} from "xps-react";
import {Accordion, AccordionItem, Button, Icon, Input} from "../../components";
import {useHistory} from "react-router-dom";
import {useMsal} from "@azure/msal-react";
import {msalUtils} from "../../MsalUtils";
import {Account, PartnerResource, PartnerResourceRequest} from "../models/PartnerResource";
import {useAppDispatch} from "../../store/hooks";
import {setLinkedClientName, setLinkedUlek} from "./partnerWorkstationSlice";
import {setResourceCode} from "../ClientProfile/activeProfileSlice";
import {partnerApiClient} from "../PartnerApiClient";
import {v4 as UUIDV4} from "uuid";

const PartnerWorkstation: React.FC = () => {

    const [partnerResources, updatePartnerResources] = React.useState(new Array<PartnerResource>());
    const [partnerResourceNoContentResponse, updatePartnerResourceNoContentResponse] = React.useState(false);

    const history = useHistory();
    const msal = useMsal();
    const dispatch = useAppDispatch();
    const [ulekInput, updateUlekInput] = useState<string>('');
    const [entityNameInput, updateEntityNameInput] = useState<string>('');
    const [firstNameInput, updateFirstNameInput] = useState<string>('');
    const [lastNameInput, updateLastNameInput] = useState<string>('');

    const handleCancel = () => {
        history.goBack();
    };

    const handleClear = () => {
        updateFirstNameInput('');
        updateLastNameInput('');
        updateEntityNameInput('');
        updateUlekInput('');
    }

    const handleSearch = () => {

        const partnerRequest: PartnerResourceRequest = {
            partnerCode: msalUtils.getLanId(msal)
        }
        if (ulekInput) {
            partnerRequest.resourceCode = ulekInput
        }
        if (firstNameInput) {
            partnerRequest.clientFirstName = firstNameInput
        }
        if (lastNameInput) {
            partnerRequest.clientLastName = lastNameInput
        }
        if (entityNameInput) {
            partnerRequest.entityName = entityNameInput
        }
        partnerApiClient.getPartnerResources(partnerRequest)
            .then((response: PartnerResource[]) => {
                if (response.length > 0) {
                    filterOutProspectsThenUpdateResponse(response);
                    updateUlekInput('');
                } else {
                    updatePartnerResourceNoContentResponse(true);
                }
            }).catch(reason => {
                console.log(reason);
            }
        );
    }

    const linkClient = (partnerResource: PartnerResource) => {
        dispatch(setLinkedUlek(partnerResource.resourceCode));
        dispatch(setLinkedClientName(partnerResource.firstName + " " + partnerResource.lastName));
        dispatch(setResourceCode(partnerResource.resourceCode));
    }

    const filterOutProspectsThenUpdateResponse = (response: PartnerResource[]) => {
        const filtered = response.filter((resource) => !resource.prospect)
        updatePartnerResources(filtered);
    }

    return (
        <div className={'page-container'}>
            <div className={'partner_details'}>
                <div className="row-container">
                    <Row>
                        <span className="font-xxl font-xxl">Select Matching Client from Partner Workstation</span>
                        <Col>
                            <div className="textalign-right">
                                <Button className="marginright-md"
                                        icon="none"
                                        id="cancel_new_client_profile_button"
                                        includeRef={false}
                                        kind="secondary"
                                        size="medium"
                                        tabIndex={0}
                                        type="button"
                                        onClick={handleCancel}
                                >
                                    CANCEL
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="search-fields-container">
                            <div className="input-form label">
                                <label className="h5 label" id="first-name">
                                    First Name
                                </label>
                                <Input
                                    className="first-name-input input-box"
                                    id="first-name"
                                    name="first-name"
                                    aria-label="first-name"
                                    aria-labelledby="first-name"
                                    type="text"
                                    value={firstNameInput}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        updateFirstNameInput(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="input-form label">
                                <label className="h5 label" id="last-name">
                                    Last Name
                                </label>
                                <Input
                                    className="last-name-input input-box"
                                    id="last-name"
                                    name="last-name"
                                    aria-label="last-name"
                                    aria-labelledby="last-name"
                                    type="text"
                                    value={lastNameInput}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        updateLastNameInput(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="input-form label">
                                <label className="h5 label" id="entity-name">
                                    Entity Name
                                </label>
                                <Input
                                    className="input-box"
                                    id="entity-name"
                                    name="entity-name"
                                    aria-label="entity-name"
                                    aria-labelledby="entity-name"
                                    type="text"
                                    value={entityNameInput}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        updateEntityNameInput(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="input-form label">
                                <label className="h5" id="ulek-input">
                                    ULEK
                                </label>
                                <Input
                                    className="input-box"
                                    id="ulek"
                                    name="ulek"
                                    aria-label="ulek"
                                    aria-labelledby="ulek-input"
                                    type="text"
                                    value={ulekInput}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        updateUlekInput(e.target.value);
                                    }}
                                />
                            </div>
                            <div className={'search-partner-workstation input-form'}>
                                <Button
                                    className="marginright-md"
                                    icon="none"
                                    id="cancel_new_client_profile_button"
                                    onClick={handleClear}
                                    size="medium"
                                    tabIndex={0}
                                    kind="borderless"
                                    type="button"
                                >
                                    Clear
                                </Button>
                                <Button
                                    icon="none"
                                    id="search_partner_workstation_button"
                                    kind="primary"
                                    onClick={handleSearch}
                                    size="medium"
                                    tabIndex={0}
                                    type="button"
                                >
                                    Search Partner Workstation
                                </Button>
                            </div>
                        </Col>
                        <Col className="search-results-container">
                            <div>
                                <div className="h4 search-results">Search
                                    Results {partnerResources.length !== 0 ? `(${partnerResources.length})` : ''}</div>
                                <hr className="section"/>
                                {partnerResources.length === 0 &&
                                    <SearchInstructions displayStatus={partnerResourceNoContentResponse}/>
                                    ||
                                    <SearchResults partnerResources={partnerResources}
                                                   onLink={(partnerResource) => {
                                                       linkClient(partnerResource);
                                                       history.goBack();
                                                   }}/>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

const SearchInstructions: React.FC<{ displayStatus: boolean }> = ({displayStatus}) => (
    <div>
        {!displayStatus ?
            <div className='search-for-clients'>
                <p>Search for clients in Partner Workstation using the fields to the left</p>
            </div> :
            <div>
                <div className='search-for-clients'>
                    <p>No matching records found</p>
                </div>
                <div className='search_no_results'>
                    Please verify that the client information you are searching for exists and that you have access
                    within Partner Workstation. You can only match client data that you have access to.
                </div>
            </div>
        }
    </div>
);

type SearchResultsProps = {
    partnerResources: Array<PartnerResource>
    onLink: (partnerResource: PartnerResource) => void
}
const SearchResults: React.FC<SearchResultsProps> = ({partnerResources, onLink}) => (
    <Col className="table-spacing">
        <Row className={"search-results-header"}>
            <Col><h5>Full Name</h5></Col>
            <Col><h5>Entity Name</h5></Col>
            <Col><h5>Legal Agreement</h5></Col>
            <Col><h5>ULEK</h5></Col>
            <Col><h5>Status</h5></Col>
        </Row>
        <Row>
            <Accordion>
                {partnerResources.map((partnerResource) => (
                    <PartnerResourceResult key={partnerResource.resourceCode}
                                           partnerResource={partnerResource}
                                           onLink={() => onLink(partnerResource)}/>
                ))}
            </Accordion>
        </Row>
    </Col>
);

const AccordionButton: React.FC<{ expanded: boolean }> = ({expanded}) => (
    <Icon name={expanded ? 'chevron_down' : 'chevron_right'}/>
);

const PartnerResourceResult: React.FC<{ partnerResource: PartnerResource, onLink: () => void }> = ({
                                                                                                       partnerResource,
                                                                                                       onLink
                                                                                                   }) => {
    const [uuid, generateUUID] = useState('');
    const fullName = (partnerResource.firstName ?? '') + " " + (partnerResource.lastName ?? '');

    useEffect(() => {
        generateUUID(UUIDV4());
    }, [])

    function generateAgreementNumberHeaderRowItem(account?: Account) {
        return <Row key={account ? `header-${account.accountNumbers[0]}` : undefined}>
            <Col>{account?.agreementNumber || 'N/A'}</Col>
        </Row>;
    }

    return (
        <AccordionItem
            uuid={uuid}
            HeaderComponent={({expanded}: { expanded: boolean }) => (
                <Row>
                    <AccordionButton expanded={expanded}/>
                    <PartnerResourceAttribute attribute={fullName}/>
                    <PartnerResourceAttribute attribute={partnerResource.entityName}/>
                    <PartnerResourceAttribute attribute={
                        <>
                            {
                                partnerResource.accounts.length > 0
                                    ? partnerResource.accounts.map((account) =>
                                        generateAgreementNumberHeaderRowItem(account))
                                    : generateAgreementNumberHeaderRowItem()
                            }
                        </>
                    }
                    />
                    <PartnerResourceAttribute attribute={partnerResource.resourceCode}/>
                    <PartnerResourceAttribute attribute={partnerResource.linked ?
                        <><Icon name="lock"/> <span>Connected to GDWM Profile</span></> : ''}/>
                </Row>
            )}
        >
            <Col className={"expanded-search-results"}>
                <Row>
                    <Col className={"client-details"}>
                        <Row>
                            <Col><h5>Full Name</h5></Col>
                            <Col>{fullName}</Col>
                        </Row>
                        <Row>
                            <Col><h5>Preferred Name</h5></Col>
                            <Col>{partnerResource.preferredName}</Col>
                        </Row>
                        <Row>
                            <Col><h5>Entity Name</h5></Col>
                            <Col>{partnerResource.entityName}</Col>
                        </Row>
                    </Col>
                    <Col className={"service-team"}>
                        <Row>
                            <Col><b>Service Team</b></Col>
                        </Row>
                        <hr/>
                        <Row>
                            <Col>
                                {partnerResource.partners.map((partner) => (
                                    <Row key={partner.partnerCode} className={"service-team-members"}>
                                        <Col className={"name"}>{partner.partnerName}</Col>
                                        <Col>{partner.title}</Col>
                                    </Row>
                                ))}
                            </Col>

                        </Row>
                    </Col>
                </Row>
                <Row className="account-details">
                    <Col>
                        <Row className="account-details__header">
                            <Col><b>Legal Agreement #</b></Col>
                            <Col><b>Agreement Name</b></Col>
                            <Col><b>Account #s</b></Col>
                        </Row>
                        <hr/>
                        {partnerResource.accounts.map((account) => (
                            <Row className="account-details__entry" key={'Detail' + account.accountNumbers[0]}>
                                <Col>{account.agreementNumber || 'N/A'}</Col>
                                <Col>{account.legalName || 'N/A'}</Col>
                                <Col>
                                    <ul>{account.accountNumbers.map((accountNumber) =>
                                        (<li key={accountNumber}>{accountNumber}</li>))}</ul>
                                </Col>
                            </Row>
                        ))}
                    </Col>
                </Row>
                {!partnerResource.linked &&
                    <Row alignItems={"end"} justify={"end"} className={"link-this-client-container"}>
                        <Button
                            role="button"
                            className="marginright-md"
                            icon="none"
                            id="cancel_new_client_profile_button"
                            kind="primary"
                            onClick={onLink}
                            size="medium"
                            tabIndex={0}
                            type="button"
                        >
                            Link This Client
                        </Button>
                    </Row>
                }
            </Col>
        </AccordionItem>
    )
};

const PartnerResourceAttribute: React.FC<{ attribute: string | ReactElement }> = ({attribute}) => (
    <Col>
        <h5>{attribute}</h5>
    </Col>
);

export default PartnerWorkstation;
