import React from "react";
import classNames from "classnames";
import ReactDOM from "react-dom";

type QuickSlidesProps = {
    children?: React.ReactNode,
    id: string,
    isOpen: boolean,
    parentElement: string,
    footer: any
}
export const QuickSlides: React.FC<QuickSlidesProps> = ({
                                                            id,
                                                            isOpen,
                                                            parentElement = '[data-testid="scrollable-container"]',
                                                            children,
                                                            footer
                                                        }) => {
    if (!isOpen) {
        return null;
    }

    const parent = (document.querySelector(parentElement) || document.body);

    const modal = (
        <div className={classNames('quick-slides')} id={id}>
            <div className="quick-slides__content">
                <div className="quick-slides__body">{children}</div>
                {footer && <div className="quick-slides__footer">{footer}</div>}
            </div>
        </div>
    );
    return ReactDOM.createPortal(modal, parent);
}
