import ModalWrapper, {ModalButton} from "../../../components/Modal/ModalWrapper/ModalWrapper";
import React, {ReactNode} from "react";


export interface WarningPopupProps {
    id: string;
    headerText: string | ReactNode;
    isOpen?: boolean;
    buttons: ModalButton[];
    dataTestId?: string;
    children?: ReactNode;
}

export const WarningPopup: React.FC<WarningPopupProps> = ({
                                                              id,
                                                              headerText,
                                                              isOpen,
                                                              buttons,
                                                              dataTestId,
                                                              children
                                                          }) => {

    return <ModalWrapper
        id={id}
        isOpen={isOpen}
        headerText={headerText}
        buttons={buttons}
        dataTestId={dataTestId}
    >
        <div className="font--large">
            {children}
        </div>
    </ModalWrapper>
}