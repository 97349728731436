import React from "react";
import {Col, Grid, Row} from "xps-react";
import {
    AgeInput,
    BirthdateInput,
    Button,
    GenderInput,
    MiddleInitial,
    Name,
    PersonalPlanningHorizonInput,
    StateOfResidencyInput,
} from "../../components";
import {ReferenceDataType} from "../../models/referenceData/ReferenceDataType";
import {
    calculateAgeFromBirthdate,
    calculateBirthdateFromAge,
    personalPlanningHorizonFromAge,
    toISO
} from "../../utils/dateUtils";
import {useHistory, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    clearPartnerWorkstationState,
    selectLinkedClientName,
    selectLinkedUlek
} from "../PartnerWorkstation/partnerWorkstationSlice";
import {
    selectIsSaveButtonClicked,
    selectNewClientFormInteractions,
    selectNewClientFormValidation,
    selectProfileRequestForm,
    setNewClientFormInteractions,
    setProfileRequestForm
} from "./NewClientProfileSlice";
import {validateInputRemovingSpaces} from "../../utils/stringUtils";
import {RouteWithProfileType} from "../../routes/types";
import useProfileEditableState from "../../hooks/useProfileEditableState";

type PrimaryContactProps = {
    referenceData: ReferenceDataType,
}

export const NewClientPrimaryContact = (props: PrimaryContactProps) => {
    const dispatch = useAppDispatch();
    const linkedUlek = useAppSelector(selectLinkedUlek);
    const linkedClientName = useAppSelector(selectLinkedClientName);
    const profileRequestForm = useAppSelector(selectProfileRequestForm);
    const {newClientProfileForm} = useAppSelector(selectNewClientFormValidation);
    const formInteractions = useAppSelector(selectNewClientFormInteractions);
    const isSaveButtonClicked = useAppSelector(selectIsSaveButtonClicked);
    const {referenceData} = props;
    const history = useHistory();
    const listOfStates = Object.entries(referenceData.statesOfResidency).map(([, value]) => value).sort();
    const {type} = useParams<RouteWithProfileType>();
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    const updatePrimaryContactHandler = (e: any) => dispatch(setProfileRequestForm({
        ...profileRequestForm,
        primaryContact: {
            ...profileRequestForm.primaryContact,
            [e.target.name]: validateInputRemovingSpaces(e.target.value)
        }
    }));

    const handleLinkExistingClient = () => {
        history.push(`/PartnerWorkstation`)
    }

    const hasLinkedClient = (): boolean => {
        return linkedUlek !== '';
    }

    const handleRemoveLink = () => {
        dispatch(clearPartnerWorkstationState());
    }

    const profileTypeFormattedText = () => {
        switch (type.toUpperCase()) {
            case ('PROSPECT'):
                return {
                    header: 'Prospect',
                    isLinkButtonEnabled: false,
                }
            default:
                return {
                    header: 'Client',
                    isLinkButtonEnabled: true,
                }
        }
    }

    return (
        <Grid>
            <Row>
                <Col>
                    <div className="h4 sections primaryContactLabel">Primary Contact Information</div>
                    <hr className="section"/>
                    {type.toUpperCase() !== 'PROSPECT' && type.toUpperCase() !== 'SAMPLE' && <div className="linked-partner">
                        <label
                            className="h5 linked-partner-label">{`Linked Partner Workstation ${profileTypeFormattedText().header}`}</label>
                        <div className={"linked-partner-content"} aria-label="linkedPartner">
                            {hasLinkedClient() &&
                                <div className={"full-name-and-button remove-button"} aria-label="linkedPartnerName">
                                    {linkedClientName}
                                    <Button
                                        kind="borderless"
                                        onClick={handleRemoveLink}
                                    >
                                        Remove Link
                                    </Button>
                                </div>
                                ||
                                <div className={"full-name-and-button"}>
                                    <span><i>{`No ${profileTypeFormattedText().header.toLowerCase()} linked.`}</i></span>
                                    {profileTypeFormattedText().isLinkButtonEnabled ?
                                        <Button
                                            icon="left"
                                            iconName="link"
                                            kind="borderless"
                                            onClick={handleLinkExistingClient}
                                            disabled={isProfileWithProposalsOrArchived}
                                        >
                                            Link Existing Client
                                        </Button> : null
                                    }
                                </div>
                            }
                        </div>
                    </div>}
                    <Name name={profileRequestForm.primaryContact.firstName}
                          className="first-name"
                          label={"First Name"}
                          onChange={updatePrimaryContactHandler}
                          required={true}
                          error={newClientProfileForm?.firstName}
                          hasInteractions={formInteractions.firstName}
                          whenUserHasInteracted={() => {
                              dispatch(setNewClientFormInteractions({
                                  ...formInteractions,
                                  firstName: true
                              }))
                          }}
                          forceShowErrors={isSaveButtonClicked}
                          maxLength={25}/>
                    <MiddleInitial name={profileRequestForm.primaryContact.middleInitial}
                                   className="middle-initial"
                                   label={"Middle Initial"}
                                   maxLength={1}
                                   required={false}
                                   onChange={updatePrimaryContactHandler}/>
                    <Name name={profileRequestForm.primaryContact.lastName}
                          className="last-name"
                          label={"Last Name"}
                          onChange={updatePrimaryContactHandler}
                          required={true}
                          error={newClientProfileForm?.lastName}
                          hasInteractions={formInteractions.lastName}
                          whenUserHasInteracted={() => {
                              dispatch(setNewClientFormInteractions({
                                  ...formInteractions,
                                  lastName: true
                              }))
                          }}
                          forceShowErrors={isSaveButtonClicked}
                          maxLength={25}/>
                    <BirthdateInput birthdate={profileRequestForm.primaryContact.birthdate}
                                    onChange={(birthdate: string) => dispatch(setProfileRequestForm({
                                        ...profileRequestForm,
                                        primaryContact: {
                                            ...profileRequestForm.primaryContact,
                                            birthdate: toISO(birthdate),
                                            age: calculateAgeFromBirthdate(birthdate)
                                        }
                                    }))}
                                    width={'300px'}
                    />
                    <AgeInput age={profileRequestForm.primaryContact.age}
                              className="age"
                              inputDescription="Entering birthdate will calculate age"
                              onChange={
                                  (age: number) =>
                                      dispatch(setProfileRequestForm({
                                          ...profileRequestForm,
                                          primaryContact: {
                                              ...profileRequestForm.primaryContact,
                                              age: age,
                                              birthdate: calculateBirthdateFromAge(age),
                                              personalPlanningHorizon: personalPlanningHorizonFromAge(age,
                                                  profileRequestForm.primaryContact.personalPlanningHorizon)
                                          }
                                      }))
                              }
                              error={newClientProfileForm?.age}
                              hasInteractions={formInteractions.age}
                              whenUserHasInteracted={() => {
                                  dispatch(setNewClientFormInteractions({
                                      ...formInteractions,
                                      age: true
                                  }))
                              }}
                              forceShowErrors={isSaveButtonClicked}/>
                    <GenderInput onChange={(e: any) => dispatch(setProfileRequestForm({
                        ...profileRequestForm,
                        primaryContact: {
                            ...profileRequestForm.primaryContact,
                            gender: e.itemText
                        }
                    }))}/>
                    <StateOfResidencyInput listOfStates={listOfStates} onSelected={(e: any) => {
                        dispatch(setProfileRequestForm({
                            ...profileRequestForm,
                            primaryContact: {
                                ...profileRequestForm.primaryContact,
                                stateOfResidency: e.itemText
                            }
                        }))
                    }} value={profileRequestForm.primaryContact.stateOfResidency || undefined}/>
                    <PersonalPlanningHorizonInput
                        personalPlanningHorizon={profileRequestForm.primaryContact.personalPlanningHorizon}
                        age={profileRequestForm.primaryContact.age}
                        onChange={(pph: number) => dispatch(setProfileRequestForm({
                            ...profileRequestForm,
                            primaryContact: {
                                ...profileRequestForm.primaryContact,
                                personalPlanningHorizon: pph
                            }
                        }))}
                        error={newClientProfileForm?.personalPlanningHorizon}
                        hasInteractions={formInteractions.personalPlanningHorizon}
                        whenUserHasInteracted={() => {
                            dispatch(setNewClientFormInteractions({
                                ...formInteractions,
                                personalPlanningHorizon: true
                            }))
                        }}
                        forceShowErrors={isSaveButtonClicked}/>
                </Col>
            </Row>
        </Grid>
    );
};
