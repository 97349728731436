export const NO_OP = () => {
    // intentionally empty
};

export const NO_OP_ASYNC = async () => {
    // intentionally empty
};

export type EventHandler = (e: any) => void;

export const DISPLAY_DATE_FORMAT = "MM/DD/YYYY";

export const DISPLAY_MONTH_YEAR_FORMAT = "MM/YYYY";

export const DISPLAY_LONG_DATE_FORMAT = "MMMM D, yyyy";

export const ISO8601_DATE_FORMAT = "YYYY-MM-DD";

export const DISPLAY_DATE_TIME_FORMAT = "MM/DD/YYYY  h:mm A";