import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {RouteWithId} from "../../../routes/types";
import {wealthManagementApiClient} from "../../../ClientManagement/WealthManagementApiClient";
import {resetAccordionTableState} from "../../../components/AccordionTable/AccordionTableSlice";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import AssetRelianceContentReport from "./AssetRelianceContentReport";
import {clientManagementApiClient} from "../../../ClientManagement/ClientManagementApiClient";
import {selectAssetRelianceReportResponse, setAssetRelianceReportResponse} from "./AssetRelianceReportSlice";
import {FveDiscountRateType} from "../../../ClientManagement/AssetReliance/AssetRelianceButtonState";

interface AssetRelianceWrapperProps {
    displayName: string,
    id: string,
    showExpectedExcessAsset?: boolean,
    includeLifeInsuranceAtDeath?: boolean,
    selectedFveDiscountRate?: FveDiscountRateType
}


const AssetRelianceWrapper = ({displayName, id, showExpectedExcessAsset = false,
                                  includeLifeInsuranceAtDeath = false,
                                  selectedFveDiscountRate = FveDiscountRateType.TRIPLE_NET}: AssetRelianceWrapperProps) => {

    return (<>
            <div className="asset-reliance">
                <AssetRelianceContentReport
                    id={id}
                    displayName={displayName}
                    showExpectedExcessAsset={showExpectedExcessAsset}
                    includeLifeInsuranceAtDeath={includeLifeInsuranceAtDeath}
                    selectedFveDiscountRate={selectedFveDiscountRate}
                />
            </div>
        </>
    )
}

type assetRelianceContainerProps = {
    displayName: string,
    profileId: string,
    showExpectedExcessAsset?: boolean,
    includeLifeInsuranceAtDeath?: boolean,
    selectedFveDiscountRate?: FveDiscountRateType
}

const AssetRelianceContainer = ({displayName,showExpectedExcessAsset = false,
                                    includeLifeInsuranceAtDeath = false,
                                    selectedFveDiscountRate = FveDiscountRateType.TRIPLE_NET,profileId}: assetRelianceContainerProps) => {

    return <AssetRelianceWrapper displayName={displayName}
                                 id={profileId}
                                 showExpectedExcessAsset={showExpectedExcessAsset}
                                 includeLifeInsuranceAtDeath={includeLifeInsuranceAtDeath}
                                 selectedFveDiscountRate={selectedFveDiscountRate}
    />
}

export default AssetRelianceContainer;