import {AssetsSummary} from "../../models/Assets";
import {COLOR_EQUITY_COMPENSATIONS} from "../../../constants/colors";
import {AssetAccordionHeader} from "../../AssetSummary/common/AssetAccordionHeader";
import {AccordionItemWithActionMenu} from "../../../components";
import {useContext} from "react";
import {assetListData} from "../../AssetSummary/common/utils";
import {
    calculateInEstateCNWEquityCompensationTotalValue,
    calculateTotalEquityCompensationsPartnerOwnedValue,
    calculateTotalEquityCompensationsPrimaryOwnedValue
} from "../../AssetSummary/common/AssetSummaryCalculator";
import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {AssetAccordionContentHeader} from "../../AssetSummary/common/AssetAccordionContentHeader";
import {useHistory} from "react-router-dom";
import AssetsViewContext from "../../common/AssetsViewContext";
import {DeleteModalProps} from "../../common/AssetsView";
import {EquityCompensationFormData} from "src/Assets/models/EquityCompensation";
import {EquityCompensationTableRow} from "./EquityCompensationTableRow";
import {formatCurrency} from "src/utils/format";

export interface InEstateEquityCompensationsProps {
    profileId: string,
    assetsData: AssetsSummary,
    investorGroup: InvestorGroupType,
    onRemoveAssetClick: (deleteModalProps: DeleteModalProps) => void,
    captionLabel?: string
}

const EquityCompensations = ({
                                 profileId,
                                 assetsData,
                                 investorGroup,
                                 onRemoveAssetClick,
                                 captionLabel=""
                             }: InEstateEquityCompensationsProps) => {
    const history = useHistory();
    const viewType = useContext(AssetsViewContext);

    const {
        hasInEstateEquityCompensations,
        inEstateEquityCompensations,
    } = assetListData(assetsData);


    return (
        <>
            {hasInEstateEquityCompensations && <AccordionItemWithActionMenu
                uuid="EquityCompensations"
                accentColor={COLOR_EQUITY_COMPENSATIONS}
                HeaderComponent={({expanded}) => {
                    const totalInEstateMarketValue = calculateInEstateCNWEquityCompensationTotalValue(assetsData.equityCompensations.data);
                    const totalInEstatePrimaryOwnedValue = calculateTotalEquityCompensationsPrimaryOwnedValue(assetsData.equityCompensations.data, investorGroup);
                    const totalInEstatePartnerOwnedValue = calculateTotalEquityCompensationsPartnerOwnedValue(assetsData.equityCompensations.data, investorGroup);

                    return <AssetAccordionHeader
                        expanded={expanded}
                        title={captionLabel.length>0?"Equity Comp":"Equity Compensation"}
                        caption={captionLabel.length>0?"Vested(continued)":"Vested"}
                        formattedPrimaryMemberTotalPresentValue={formatCurrency(totalInEstatePrimaryOwnedValue)}
                        formattedSecondaryMemberTotalPresentValue={formatCurrency(totalInEstatePartnerOwnedValue)}
                        hideJoint={false}
                        formattedJointTotalPresentValue={formatCurrency(0)}
                        formattedTotalPresentValue={formatCurrency(totalInEstateMarketValue)}
                        gridClassName={"current-net-worth-grid"}
                    />
                }}
            >
                <div role="table" className="current-net-worth-grid-with-actionmenu assets-grid-table" aria-label="equity-compensation-table">
                    <AssetAccordionContentHeader investorGroup={investorGroup} hideJoint={false} />
                    {inEstateEquityCompensations.map((equityCompensation: EquityCompensationFormData) => {
                        return <EquityCompensationTableRow
                            equityCompensation={equityCompensation}
                            investorGroup={investorGroup}
                            actionsDisabled={false}
                            onClickEdit={(equityCompensationId: string) => {
                                history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditEquityCompensation/${equityCompensationId}`);
                            }}
                            onClickDelete={(equityCompensationDelete: EquityCompensationFormData) => {
                                onRemoveAssetClick({
                                    showDeleteModal: true,
                                    modalTitle: "Asset",
                                    assetDescription: equityCompensationDelete.companyOrStockName ?? "",
                                    assetType: "equity compensation",
                                    assetId: equityCompensationDelete.id,
                                });
                            }}
                            key={equityCompensation.id}
                            gridClassName={"current-net-worth-grid-with-actionmenu"}
                        />;
                    })}
                </div>

            </AccordionItemWithActionMenu>}
        </>
    );
}

export default EquityCompensations;
