import {GoalType, NonLifestyleGoalType} from "../models/GoalType";
import {BeneficiaryGoals, GoalModelType} from "../models/GoalModelType";
import {InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import GoalUtils from "../Summary/GoalUtils";
import {FundedByPortfolioPercentageAttributes, NonLifestyleGoalRow} from "./Prioritization";

function makeNonLifestyleGoalListTableRowData(
    nonLifestyleGoalsForDisplay: NonLifestyleGoalType[],
    beneficiaryGoalsForDisplay: BeneficiaryGoals[],
    investorGroup: InvestorGroupType
): NonLifestyleGoalRow[] {
    const nonLifestyleGoalRows: NonLifestyleGoalRow[] = nonLifestyleGoalsForDisplay.map(
        (goal: NonLifestyleGoalType) => {
            const {yearsOfFlow, yearsUntilFlow} = GoalUtils.getYearsOfFlowAndYearsUntilFlow(goal, investorGroup)

            return {
                name: goal.userInputs.description,
                goalType: goal.goalType,
                annualFlow: goal.userInputs.annualFlow,
                presentValue: goal.calculatedFields.presentValue,
                beneficiaryName: "",
                isFundedByNonInvestableAssets: goal.userInputs.isFundedByNonInvestableAssets,
                yearsOfFlow,
                yearsUntilFlow,
                savedGoal: goal
            }
        }
    );

    const beneficiaryGoalRowsPerBeneficiary: NonLifestyleGoalRow[][] = Object.values(beneficiaryGoalsForDisplay).map(
        (beneficiaryGoal: BeneficiaryGoals) => {
            const beneficiaryName: string = beneficiaryGoal.name;

            return Object.values(beneficiaryGoal.goals).map(
                (goal: NonLifestyleGoalType) => {

                    const {
                        yearsOfFlow,
                        yearsUntilFlow
                    } = GoalUtils.getYearsOfFlowAndYearsUntilFlow(goal, investorGroup)

                    return {
                        name: goal.userInputs.description,
                        goalType: GoalType.FAMILY,
                        annualFlow: goal.userInputs.annualFlow,
                        presentValue: goal.calculatedFields.presentValue,
                        isFundedByNonInvestableAssets: goal.userInputs.isFundedByNonInvestableAssets,
                        beneficiaryName,
                        yearsOfFlow,
                        yearsUntilFlow,
                        savedGoal: goal
                    }
                }
            );
        }
    )
    const familyGoalRows: NonLifestyleGoalRow[] = [];
    beneficiaryGoalRowsPerBeneficiary.forEach(
        (beneficiaryGoalRows: NonLifestyleGoalRow[]) => {
            familyGoalRows.push(...beneficiaryGoalRows);
        }
    );
    return [...nonLifestyleGoalRows, ...familyGoalRows];
}

function addFbniLifestyleGoal(goalModel: GoalModelType, fundedByNonInvestables: NonLifestyleGoalRow[]): NonLifestyleGoalRow[] {
    const presentValueForInsufficientYears = goalModel.lifestyleSpendingGoal.calculatedFields.presentValueForInsufficientYears;
    if (presentValueForInsufficientYears > 0) {
        const yearsUntilFlow = goalModel.lifestyleSpendingGoal.calculatedFields.sufficientYears;
        const planningPeriod = goalModel.investorGroup.planningPeriod.numberOfYears;

        const fbniLifeStyleGoal: NonLifestyleGoalRow = {
            beneficiaryName: "",
            isFundedByNonInvestableAssets: false,
            name: "Lifestyle Spending",
            presentValue: presentValueForInsufficientYears,
            yearsUntilFlow: yearsUntilFlow,
            yearsOfFlow: planningPeriod - yearsUntilFlow
        }

        fundedByNonInvestables.unshift(fbniLifeStyleGoal)
    }
    return  fundedByNonInvestables
}
function getNonLifestyleGoalTotalPresentValue(nonLifestyleGoals: NonLifestyleGoalRow[]): number {
    return nonLifestyleGoals
        .map((nonLifestyleGoal) => nonLifestyleGoal.presentValue)
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
}


export function sortNonLifeStyleGoalList(rowData: NonLifestyleGoalRow[]) {
    const rowDataForSort = rowData.map((row) => {
            return {
                ...row,
                sumYearsOfFlowAndYearsUntilFlow: row.yearsOfFlow + row.yearsUntilFlow
            }
        }
    )
    const fieldSorter = (fields: string[]) => (a: NonLifestyleGoalRow, b: NonLifestyleGoalRow) => fields.map(o => {
        let dir = 1;
        if (o[0] === '-') {
            dir = -1;
            o = o.substring(1);
        }
        let nonLifestyleGoalRowProperty = o as keyof typeof a
        // @ts-ignore
        if (a[nonLifestyleGoalRowProperty] > b[nonLifestyleGoalRowProperty]) {
            return dir;
        }
        // @ts-ignore
        return a[nonLifestyleGoalRowProperty] < b[nonLifestyleGoalRowProperty] ? -(dir) : 0;

    }).reduce((p, n) => p ? p : n, 0);
    // sorting rowData here
    rowDataForSort.sort(fieldSorter(['sumYearsOfFlowAndYearsUntilFlow', 'yearsUntilFlow','-presentValue','name']));
    return rowDataForSort.map((row) => {
            return {name:row.name,
                goalType: row.goalType,
                annualFlow:row.annualFlow,
                yearsOfFlow:row.yearsOfFlow,
                yearsUntilFlow:row.yearsUntilFlow,
                presentValue:row.presentValue,
                beneficiaryName:row.beneficiaryName,
                isFundedByNonInvestableAssets:row.isFundedByNonInvestableAssets,
                savedGoal:row.savedGoal
            }
        }
    )
}

function get2dArrayOfPVs(prioritizationRows: NonLifestyleGoalRow[]): number[][]{
    let twoDArray: number[][];
    // Create 2d array of pvs each row representing pv per year for a specific goals
    twoDArray = prioritizationRows.map(ele => ele.savedGoal?.calculatedFields.presentValueByYear!);

    // creating normalized 2d array with padded zeros to make each row of equal length
    const maxLength = Math.max(...(twoDArray.map(el => el.length)));
    twoDArray = twoDArray.map(ele => ele.concat(Array(maxLength -ele.length).fill(0)))

    return twoDArray;
}

export function getFundedByPortfolioPercentageAttributes(prioritizationRows: NonLifestyleGoalRow[], assetsAvailable: number): FundedByPortfolioPercentageAttributes {
    const presentValues2dArrayWithPaddedZeros = get2dArrayOfPVs(prioritizationRows);
    const maxLength = Math.max(...(presentValues2dArrayWithPaddedZeros.map(el => el.length)));
    for(let i = 0; i< maxLength; i++){
        let totalPvForAllGoalsPerYear =  sumColumnValues(presentValues2dArrayWithPaddedZeros, i);
        if(assetsAvailable > totalPvForAllGoalsPerYear){
            assetsAvailable = assetsAvailable - totalPvForAllGoalsPerYear;
        }
        else{
            return {
                breakEvenYear : i,
                breakEvenYearTotalPV : totalPvForAllGoalsPerYear,
                remainingAssetsForBreakEvenYear: assetsAvailable
            }
        }
    }

    return {breakEvenYear: 0, breakEvenYearTotalPV:0, remainingAssetsForBreakEvenYear:0}
}

export function sumColumnValues(arr: number[][], columnIndex: number): number {
    let sum = 0;
    for (const element of arr) {
        const row = element;
        if (columnIndex < row.length) {
            sum += row[columnIndex];
        }
    }
    return sum;
}

function updateNonLSRowsWithFundedByPortfolioPercentage(nonLifestyleGoalRows: NonLifestyleGoalRow[], fundedByPortfolioPercentageAttributes: FundedByPortfolioPercentageAttributes) {
    return nonLifestyleGoalRows.map(nonLSGoalRow => {
        const {
            breakEvenYear,
            breakEvenYearTotalPV,
            remainingAssetsForBreakEvenYear
        } = fundedByPortfolioPercentageAttributes;

        const presentValuePerYear = nonLSGoalRow.savedGoal?.calculatedFields.presentValueByYear;
        if (nonLSGoalRow.presentValue > 0 && presentValuePerYear && presentValuePerYear.length > breakEvenYear) {
            const breakEvenYearPV = presentValuePerYear[breakEvenYear];
            const breakEvenYearAllocation = breakEvenYearPV / breakEvenYearTotalPV;
            const dollarAmountOfBreakEvenYear = breakEvenYearAllocation * remainingAssetsForBreakEvenYear;
            const sumOfPVsBeforeBreakEvenYear = presentValuePerYear.slice(0, breakEvenYear)
                .reduce((previous, current) => previous + current, 0);
            const fundedByPortfolioPercentage = (dollarAmountOfBreakEvenYear + sumOfPVsBeforeBreakEvenYear) * 100 / nonLSGoalRow.presentValue
            nonLSGoalRow.fundedByPortfolioPercentage = Math.round(fundedByPortfolioPercentage);
            return nonLSGoalRow;
        }
        nonLSGoalRow.fundedByPortfolioPercentage = 100;
        return nonLSGoalRow
    })
}

export default {
    makeNonLifestyleGoalListTableRowData,
    addFbniLifestyleGoal,
    getNonLifestyleGoalTotalPresentValue,
    sortNonLifeStyleGoalList,
    get2dArrayOfPVs,
    getFundedByPortfolioPercentageAttributes,
    sumColumnValues,
    updateNonLSRowsWithFundedByPortfolioPercentage
}