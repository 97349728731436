import React from "react";
import {YearsInput} from "./YearsInput";
import {EventHandler} from "../../constants/common";
import {clamp} from "../../utils/Clamp";

type PersonalPlanningHorizonInputProps = {
    personalPlanningHorizon: number,
    age: number,
    onChange: EventHandler,
    error?: string | null,
    forceShowErrors?: boolean,
    className?: string,
    hasInteractions?: boolean,
    whenUserHasInteracted?: () => void,
    readOnly?: boolean
}

const parsePPHEvent = (e: any) => {
    const pph = parseInt(e.target.value);
    return isNaN(pph) ? null : clamp(pph, 0, 150);
}

export const PersonalPlanningHorizonInput: React.FC<PersonalPlanningHorizonInputProps> = (
    {
        personalPlanningHorizon,
        age,
        onChange,
        error,
        className = "",
        hasInteractions,
        whenUserHasInteracted,
        forceShowErrors,
        readOnly = false,
    }) => {
    return (
        <div className={`parent`}>
            <YearsInput label={"Personal Planning Horizon"}
                        value={personalPlanningHorizon}
                        className={`paddingbottom-md ${className}`}
                        onChange={(e: any) => {
                            const pph = parsePPHEvent(e);
                            onChange(pph);
                        }}
                        onBlur={(e: any) => {
                            const pph = parsePPHEvent(e);
                            onChange((pph && pph <= age) ? age + 1 : pph);
                        }}
                        error={error}
                        hasInteractions={hasInteractions}
                        whenUserHasInteracted={whenUserHasInteracted}
                        required={true}
                        forceShowErrors={forceShowErrors}
                        readOnly={readOnly}
            />
        </div>
    );
}