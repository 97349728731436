export interface GoalFundingResponse {
    fundedByRiskControlAtYear: number[] | null;
    fundedByRiskAssetsAtYear: number[] | null;
    fundedByPortfolioAtYear: number[] | null;
    fundedByRiskControlPercentageAtYear: number[] | null;
    fundedByRiskAssetsPercentageAtYear: number[] | null;
    fundedByInflowsAtYear: number[] | null;
    fundedByNonInvestableAtYear: number[] | null;
    presentValueAtYear: number[];
    lifestyle: GoalFundingSummary;
    discretionary: GoalFundingSummary;
    philanthropic: GoalFundingSummary;
    family: FamilyGoalFundingSummaryTotal;
    taxLiabilities: GoalFundingSummary;
    investableExcess: GoalFundingSummary;
}

export interface GoalFundingSummary {
    fundedByInflowsAtYear?: number[] | null,
    fundedByNonInvestableAtYear?: number[] | null,
    fundedByRiskControlAtYear: number[] | null,
    fundedByPortfolioAtYear: number[] | null,
    fundedByRiskAssetsAtYear: number[] | null,
    fundedByRiskControlAtYearPercentage: number[] | null,
    fundedByRiskAssetsAtYearPercentage: number[] | null,
    presentValueAtYear: number[],
    goals: GoalFundingItem[]
}
export interface FamilyGoalFundingSummaryTotal {
    fundedByRiskControlAtYear: number[] | null,
    fundedByRiskAssetsAtYear: number[] | null,
    fundedByPortfolioAtYear: number[] | null,
    fundedByRiskControlAtYearPercentage: number[] | null,
    fundedByRiskAssetsAtYearPercentage: number[] | null,
    presentValueAtYear: number[] | null,
    fundedByInflowsAtYear?: number[] | null,
    fundedByNonInvestableAtYear: number[] | null,
    goalSummary: FamilyGoalFundingSummary[];
}

export interface FamilyGoalFundingSummaryResponse {
    beneficiaryId: string,fundedByRiskControlAtYear: number[] | null,
    fundedByRiskAssetsAtYear: number[] | null,
    fundedByPortfolioAtYear: number[] | null,
    fundedByRiskControlAtYearPercentage: number[] | null,
    fundedByRiskAssetsAtYearPercentage: number[] | null,
    presentValueAtYear: number[] | null,
    fundedByInflowsAtYear?: number[] | null,
    fundedByNonInvestableAtYear: number[] | null,
    goalItem: GoalFundingItem[]
}

export interface FamilyGoalFundingSummary {
    beneficiaryId: string,
    name: string | null,
    birthdate: string | null,
    age: number | null,
    fundedByRiskControlAtYear: number[] | null,
    fundedByRiskAssetsAtYear: number[] | null,
    fundedByPortfolioAtYear: number[] | null,
    fundedByRiskControlAtYearPercentage: number[] | null,
    fundedByRiskAssetsAtYearPercentage: number[] | null,
    presentValueAtYear: number[] | null,
    fundedByInflowsAtYear?: number[] | null,
    fundedByNonInvestableAtYear: number[] | null,
    goalItem: GoalFundingItem[]
}

export interface GoalFundingItem {
    id: string | null,
    description: string,
    fundedByInflowsAtYear?: number[] | null,
    fundedByNonInvestableAtYear?: number[] | null,
    fundedByRiskControlAtYear: number[] | null,
    fundedByPortfolioAtYear: number[] | null,
    fundedByRiskAssetsAtYear: number[] | null,
    fundedByRiskControlAtYearPercentage: number[] | null,
    fundedByRiskAssetsAtYearPercentage: number[] | null,
    presentValueAtYear: number[]
}

export enum DataDisplayView {
    PERCENTAGE_VIEW = 'Percentage View',
    DOLLAR_VIEW = 'Dollar View'
}

export enum FundingDisplayOptions {
    ONLY_PORTFOLIO,
    PORTFOLIO_AND_INFLOWS,
    PORTFOLIO_AND_NON_INVESTABLE,
    PORTFOLIO_AND_INFLOWS_AND_NON_INVESTABLE
}