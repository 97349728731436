import React from "react"
import {Icon} from "../../components/Icon";

interface LegendProps {
    legendColor: string,
    label: string;
}

const FutureEstateValueBarChartLegend: React.FC<LegendProps> = ({legendColor, label}) => {
    return (
        <div>
            <Icon name={"media_record"} color={legendColor}/>
            <span className="future-estate-value-legend-label">{label}</span>
        </div>
    )
}

export default FutureEstateValueBarChartLegend;