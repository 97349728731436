import {WarningPopup} from "./WarningPopup";
import React from "react";

export function TurnOffPortfolioReserveModal(props: { open: boolean, onConfirm: () => Promise<void>, onCancel: () => Promise<void> }) {
    return <WarningPopup
        id="off-toggle-modal"
        isOpen={props.open}
        headerText={`Turn off the Portfolio Reserve?`}
        buttons={[
            {
                text: "CONFIRM",
                onClick: props.onConfirm,
                primary: true,
            },
            {
                text: "CANCEL",
                onClick: props.onCancel,
            },
        ]}
        children={<div>
            <div className="off-toggle-modal font--large marginbottom-lg">
                By turning off the Portfolio Reserve...
            </div>
            <div className={"off-toggle-modal__list-item font-md"} data-testid={"off-modal-subtext_1"}>
                Proposed drawdown to Risk Control will reset to fully funding the Reserve Target (see Monitor
                Reserve
                prior to deactivating to view the impact to the proposed asset allocation)
            </div>
            <div className={"off-toggle-modal__list-item font-md"} data-testid={"off-modal-subtext_2"}>
                Investable excess shortfall will appear if goals are currently defunded on the Goals Prioritization
                screen
            </div>
        </div>}
    />;
}