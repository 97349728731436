import {useAppSelector} from "../../store/hooks";
import {selectGoalModel} from "../controller/GoalsModelSlice";
import React from "react";
import {Dropdown, DropdownItem} from "../../components";
import FamilyGoalUtils from "./FamilyGoalUtils";

import {Link} from "react-router-dom";
import {NO_OP} from "../../constants/common";

type SummaryAddGoalButtonProps = {
    isProfileWithProposalsOrArchived?: boolean
};

const SummaryAddGoalButton = ({isProfileWithProposalsOrArchived = false}: SummaryAddGoalButtonProps) => {
    const {proposal} = useAppSelector(selectGoalModel);

    return (
        <Dropdown
            className="dropdown--rounded"
            size="medium"
            alignRight
            buttonIcon="left"
            buttonKind="primary"
            drop="down"
            dropdownKind="menu"
            defaultText="Add Goal"
            iconNameOpen="add"
            iconNameClose="add"
            panelWidth={"auto"}
            rounded
            aria-label="add-new-goal"
            disabled={isProfileWithProposalsOrArchived}
        >
            <DropdownItem key="Discretionary Goal" className="add-goal-dropdown-item" value='Discretionary Goal' itemText='' onClick={NO_OP}>
                <Link to={`/Profile/${proposal.id}/ClientProfile/Goals/AddDiscretionaryGoal`}
                      className="add-goal-dropdown-item">
                    <div style={{width: "100%"}}>
                        Discretionary Goal
                    </div>
                </Link>
            </DropdownItem>
            <DropdownItem key="Philanthropic Goal" className="add-goal-dropdown-item" value='Philanthropic Goal' itemText='' onClick={NO_OP}>
                <Link to={`/Profile/${proposal.id}/ClientProfile/Goals/AddPhilanthropicGoal`}
                      className="add-goal-dropdown-item">
                    <div style={{width: "100%"}}>
                        Philanthropic Goal
                    </div>
                </Link>
            </DropdownItem>
            {FamilyGoalUtils.getFamilyGoalDropdownItemsFor(proposal, "Family Goal for")}
        </Dropdown>
    );
}



export default SummaryAddGoalButton;
