import React, {useEffect, useState} from "react";
import {FamilyMemberForm, MemberType} from "../../models/MemberType";
import {MemberDetailsForm} from "./MemberDetailsForm";
import {FamilyMemberRelationships} from "./Relationships/FamilyMemberRelationships";
import {useAppDispatch, useAppSelector} from "src/store/hooks";
import {AlertBanner, Tab, TabBar, TabPanel, TabsProvider} from "../../../components"
import {
    FamilyRelationshipState,
    getEmptyFamilyFormInteractions,
    getEmptyMemberForm,
    SaveAction,
    selectAddMemberChildren,
    selectEditMemberType,
    selectFamilyMemberForm,
    selectFamilyMemberFormSnapshot,
    selectFamilyMode,
    selectFamilyTree,
    selectIsSaveButtonClicked,
    selectIsValidForm,
    selectRelatedTo,
    selectRelationshipStates,
    selectSaveAction,
    setAddMemberChildren,
    setChangedAParent,
    setFamilyFormInteractions,
    setFamilyMemberForm,
    setIsSaveButtonClicked,
    setIsValidForm,
    setRelatedTo,
    setRelationshipStates
} from "../FamilyTreeSlice";
import {clientManagementApiClient} from "../../ClientManagementApiClient";
import DeleteMemberModal from "./DeleteMemberModal";
import DeleteMemberFooter from "./DeleteMemberFooter";
import {getAddOrEditHeaderTitle} from "./AddOrEditFamilyMemberUtils";
import {FamilyMode} from "./FamilyMode";
import DataEntrySideDrawer from "../../../components/DataEntry/DataEntrySideDrawer";
import DataEntryHeader from "../../../components/DataEntry/DataEntryHeader";
import ModalWrapper from "../../../components/Modal/ModalWrapper/ModalWrapper";
import {assetsApiClient} from "../../../Assets/AssetsApiClient";
import {MemberOwnership} from "../../../Assets/models/Ownership";
import {getAllPartnerRelationshipStatesForMemberId} from "../../FamilyRelationshipService";
import {calculateBirthdateFromAge} from "../../../utils/dateUtils";
import {FamilyRelationshipType} from "../../models/FamilyRelationshipType";
import useProfileEditableState from "../../../hooks/useProfileEditableState";

type AddFamilyMemberProps = {
    isOpen: boolean,
    updateIsOpen: (value: boolean) => void,
    profileId: string,
};

export type InlineAlerts = {
    age: boolean,
    birthdate: boolean,
    personalPlanningHorizon: boolean,
    lifeStatus: boolean,
}

export type FamilyMemberRelationshipsInlineAlertVisibility = {
    partnerDropdown: boolean,
}

const AddOrEditFamilyMember: React.FC<AddFamilyMemberProps> = ({isOpen, updateIsOpen, profileId}) => {

    const [showRequiredFieldsMessage, updateShowRequiredFieldsMessage] = useState(true);
    const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false);
    const [showDeleteMemberModal, setShowDeleteMemberModal] = useState(false);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    const [inlineAlertVisibility, setInlineAlertVisibility] = useState<InlineAlerts>({
        age: false,
        birthdate: false,
        personalPlanningHorizon: false,
        lifeStatus: false
    });
    const [familyMemberRelationshipsInlineAlertVisibility, setFamilyMemberRelationshipsInlineAlertVisibility] = useState<FamilyMemberRelationshipsInlineAlertVisibility>({
        partnerDropdown: false
    });
    const dispatch = useAppDispatch();
    const isValidForm = useAppSelector(selectIsValidForm);
    const familyTree = useAppSelector(selectFamilyTree)!;
    const memberForm: FamilyMemberForm = useAppSelector(selectFamilyMemberForm)!;
    const memberFormSnapshot = useAppSelector(selectFamilyMemberFormSnapshot)!;
    const saveAction = useAppSelector(selectSaveAction)!;
    const familyMode = useAppSelector(selectFamilyMode)!;
    const isSaveButtonClicked = useAppSelector(selectIsSaveButtonClicked);
    const relationshipStates = useAppSelector(selectRelationshipStates);
    const relatedTo = useAppSelector(selectRelatedTo);
    const addMemberChildren = useAppSelector(selectAddMemberChildren);
    const editingMember = useAppSelector(selectEditMemberType);

    const [isMemberAssetOwner, setIsMemberAssetOwner] = useState(false);
    const [isPartnerAssetOwner, setIsPartnerAssetOwner] = useState(false);

    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    const partnerRelationshipState: FamilyRelationshipState = getAllPartnerRelationshipStatesForMemberId(memberForm.id!, familyTree.primaryContact)[0];

    useEffect(() => {
        setIsMemberAssetOwner(false)
        if (profileId && editingMember) {
            assetsApiClient.getMemberOwnerships(profileId, editingMember.id)
                .then((response: MemberOwnership[]) => {
                    if (response.length > 0) {
                        setIsMemberAssetOwner(true)
                    }
                })
                .catch(console.error);

            if (saveAction === SaveAction.Edit && memberForm.id === familyTree.primaryContact.id && partnerRelationshipState) {
                assetsApiClient.getMemberOwnerships(profileId, partnerRelationshipState.memberId)
                    .then((response: MemberOwnership[]) => {
                        if (response.length > 0) {
                            setIsPartnerAssetOwner(true)
                        }
                    })
                    .catch(console.error);
            }
        }
    }, [editingMember, profileId]);

    const handleDeleteFamilyMember = async () => {
        if (memberForm.id && saveAction === SaveAction.Edit) {
            await clientManagementApiClient.deleteFamilyMember(memberForm.id, profileId).then((response) => {
                if (response) {
                    clearAllFields()
                    setShowDeleteMemberModal(false);
                    updateIsOpen(false);
                }
            }).catch(error => console.error('Could not delete family member', error.message));
        }
    }

    const handleSaveButton = async () => {
        if (isValidForm) {
            if (relatedTo?.id) {
                if (saveButtonDisabled) {
                    return;
                }
                setSaveButtonDisabled(true);

                let member: FamilyMemberForm = {
                    ...memberForm,
                    relatesTo: relationshipStates?.map(state => ({
                        memberId: state.memberId,
                        type: state.type
                    })),
                    children: addMemberChildren
                };
                //Check for null birthdate because age may not have changed from default of zero
                if (member.birthdate == null) {
                    member = {...member, birthdate: calculateBirthdateFromAge(member.age)}
                }
                const saveActionFunction = saveAction === SaveAction.Create ? clientManagementApiClient.postNewFamilyMember
                    : clientManagementApiClient.editFamilyMember;
                saveActionFunction(member, profileId)
                    .then((response) => {
                        if (response) {
                            clearAllFields()
                            updateIsOpen(false);
                            setShowDeleteMemberModal(false);

                            if (member.relatesTo && member.relatesTo?.filter(r => r.type === FamilyRelationshipType.PARENT).length > 0) {
                                dispatch(setChangedAParent(true));
                            }
                        }
                    })
                    .catch(error => console.error('Could not create new family member', error.message))
                    .finally(() => setSaveButtonDisabled(false));
            }
            dispatch(setIsSaveButtonClicked(false));
        } else {
            dispatch(setIsSaveButtonClicked(true));
        }
        setInlineAlertVisibility({
            age: false,
            birthdate: false,
            personalPlanningHorizon: false,
            lifeStatus: false,
        });
        setFamilyMemberRelationshipsInlineAlertVisibility({
            partnerDropdown: false
        });
    };

    const hasNoChanges = () => {
        const memberFormKeys: Array<keyof FamilyMemberForm> = Object.keys(
            memberForm
        ) as Array<keyof FamilyMemberForm>;
        return memberFormKeys.every(
            (key) => memberForm[key] === memberFormSnapshot[key]
        );
    };

    const handleCancelButton = async () => {
        if (hasNoChanges()) {
            clearAllFields();
            updateIsOpen(false);
            dispatch(setFamilyFormInteractions(getEmptyFamilyFormInteractions()));
            dispatch(setIsSaveButtonClicked(false));
        } else {
            setShowDiscardChangesModal(true);
            dispatch(setIsSaveButtonClicked(false));
        }
        setInlineAlertVisibility({
            age: false,
            birthdate: false,
            personalPlanningHorizon: false,
            lifeStatus: false,
        });
        setFamilyMemberRelationshipsInlineAlertVisibility({
            partnerDropdown: false
        });
    };

    const handleModalDiscardButton = async () => {
        clearAllFields();
        setShowDiscardChangesModal(false);
        updateIsOpen(false);
        updateShowRequiredFieldsMessage(false);
        dispatch(setIsSaveButtonClicked(false));
        dispatch(setFamilyFormInteractions(getEmptyFamilyFormInteractions()));
        dispatch(setIsValidForm(true));
    };

    const handleDeleteButton = async () => {
        setShowDeleteMemberModal(true);
    }

    const clearAllFields = () => {
        dispatch(setFamilyMemberForm({
            ...getEmptyMemberForm(),
        }));
        dispatch(setRelationshipStates([]));
        dispatch(setRelatedTo({} as MemberType));
        dispatch(setAddMemberChildren([]));
    }

    const title = getAddOrEditHeaderTitle(saveAction, familyMode, `${editingMember?.firstName} ${editingMember?.lastName}`);

    return (
        <div>
            <div className="family-form-side-drawer">
                <DataEntrySideDrawer
                    isOpen={isOpen}
                >
                    <DataEntryHeader
                        title={title}
                        primaryButtonText={"Save"}
                        secondaryButtonText={"Cancel"}
                        onPrimaryButtonClick={handleSaveButton}
                        disablePrimaryButton={saveButtonDisabled || isProfileWithProposalsOrArchived}
                        onSecondaryButtonClick={handleCancelButton}
                        SubtitleComponent={() =>
                            <div className="margin-none h4">
                                <span className="color-text--error font-md fontweight-normal required-fields-text">
                                    {showRequiredFieldsMessage ? '* Required Fields' : <br/>}
                                </span>
                            </div>
                        }
                    />
                    {isOpen && <article>
                        {
                            (familyMode === FamilyMode.IMMEDIATE || familyMode === FamilyMode.EXTENDED)
                                ? <TabbedMemberForm isValidForm={isValidForm}
                                                    isSaveButtonClicked={isSaveButtonClicked}
                                                    updateShowRequiredFieldsMessage={updateShowRequiredFieldsMessage}
                                                    setShowInlineAlert={setInlineAlertVisibility}
                                                    showInlineAlert={inlineAlertVisibility}
                                                    isMemberAssetOwner={isMemberAssetOwner}
                                                    isPartnerAssetOwner={isPartnerAssetOwner}
                                                    familyMemberRelationshipsInlineAlertVisibility={familyMemberRelationshipsInlineAlertVisibility}
                                                    setFamilyMemberRelationshipsInlineAlertVisibility={setFamilyMemberRelationshipsInlineAlertVisibility}
                                />
                                : <MemberDetailsForm
                                    inlineAlertVisibility={inlineAlertVisibility}
                                    setInlineAlertVisibility={setInlineAlertVisibility}
                                    isMemberAssetOwner={isMemberAssetOwner}
                                />
                        }
                    </article>}
                    <DeleteMemberFooter
                        handleDeleteButton={handleDeleteButton}
                        isMemberAssetOwner={isMemberAssetOwner}
                        familyMode={familyMode}
                        saveAction={saveAction}
                    />
                </DataEntrySideDrawer>
            </div>
            <DiscardChangesModal showDiscardChangesModal={showDiscardChangesModal}
                                 handleKeepEditing={() => setShowDiscardChangesModal(false)}
                                 handleModalDiscardButton={handleModalDiscardButton}/>
            <DeleteMemberModal showDeleteMemberModal={showDeleteMemberModal}
                               handleModalCancelButton={() => setShowDeleteMemberModal(false)}
                               handleModelDeleteButton={handleDeleteFamilyMember}
                               familyMode={familyMode}/>
        </div>
    );
};

type TabbedMemberFormProps = {
    isValidForm?: boolean,
    isSaveButtonClicked?: boolean,
    updateShowRequiredFieldsMessage: (shouldShow: boolean) => void,
    setShowInlineAlert: (values: InlineAlerts) => void,
    showInlineAlert: InlineAlerts,
    isMemberAssetOwner: boolean,
    isPartnerAssetOwner: boolean,
    familyMemberRelationshipsInlineAlertVisibility: FamilyMemberRelationshipsInlineAlertVisibility
    setFamilyMemberRelationshipsInlineAlertVisibility: (value: FamilyMemberRelationshipsInlineAlertVisibility) => void,
}

const TabbedMemberForm: React.FC<TabbedMemberFormProps> = ({
                                                               isValidForm,
                                                               isSaveButtonClicked,
                                                               updateShowRequiredFieldsMessage,
                                                               setShowInlineAlert,
                                                               showInlineAlert,
                                                               isMemberAssetOwner,
                                                               isPartnerAssetOwner,
                                                               familyMemberRelationshipsInlineAlertVisibility,
                                                               setFamilyMemberRelationshipsInlineAlertVisibility,
                                                           }) => {
    return (
        <TabsProvider tabIdArray={['details', 'relationships']}>
            {!isValidForm && isSaveButtonClicked && <RequiredFieldsMissingBanner/>}
            <TabBar size="medium">
                <Tab name="Details" onClick={() => {
                    updateShowRequiredFieldsMessage(true)
                }}/>
                <Tab name="Relationships" onClick={() => {
                    updateShowRequiredFieldsMessage(false)
                }}/>
            </TabBar>
            <TabPanel tabIndex={0}>
                <div>
                    <MemberDetailsForm
                        setInlineAlertVisibility={setShowInlineAlert}
                        inlineAlertVisibility={showInlineAlert}
                        isMemberAssetOwner={isMemberAssetOwner}
                    />
                </div>
                <div className="relationships-view">
                    <FamilyMemberRelationships
                        isMemberAssetOwner={isMemberAssetOwner}
                        isPartnerAssetOwner={isPartnerAssetOwner}
                        familyMemberRelationshipsInlineAlertVisibility={familyMemberRelationshipsInlineAlertVisibility}
                        setFamilyMemberRelationshipsInlineAlertVisibility={setFamilyMemberRelationshipsInlineAlertVisibility}
                    />
                </div>
            </TabPanel>
        </TabsProvider>
    );
}

const RequiredFieldsMissingBanner: React.FC = () => (
    <AlertBanner
        className="display-flex justify-content-right alertBanner"
        id="alertBanner"
        fullWidth={false}
        icon="warning"
        showAlert={true}
        showCloseBtn={false}
        type="error"
    >
        <b className="alertBannerStyleName">Required fields missing</b>
    </AlertBanner>
)

type DiscardChangesModalProps = {
    showDiscardChangesModal: boolean,
    handleKeepEditing: () => void
    handleModalDiscardButton: () => void
}

const DiscardChangesModal: React.FC<DiscardChangesModalProps> = (
    {
        showDiscardChangesModal,
        handleKeepEditing,
        handleModalDiscardButton
    }) => {
    return (
        <ModalWrapper
            id="discard-changes-modal"
            isOpen={showDiscardChangesModal}
            headerText="Confirm"
            buttons={[
                {
                    text: "Keep Editing",
                    onClick: handleKeepEditing,
                },
                {
                    text: "Discard Changes",
                    onClick: handleModalDiscardButton,
                    destructive: true,
                    primary: true,
                },
            ]}
        >
            <div className="font-md">
                You have made changes, you can discard your changes, or keep editing
                to save your changes
            </div>
        </ModalWrapper>
    )
};


export default AddOrEditFamilyMember;
