import AlertBanner from "./AlertBanner";
import React from "react";

type LeftRightAlertBannerProps = {
    showAlert: boolean,
    rightText?: string,
    leftText?: string,
    className?: string
};

export default function LeftRightAlertBanner({
                                                 showAlert,
                                                 leftText,
                                                 rightText,
                                             }: LeftRightAlertBannerProps) {
    return <AlertBanner showAlert={showAlert} className="left-right-alert-banner" icon="warning">
        <div className="display-flex justify-content-between">
            <span>{leftText}</span>
            <span>{rightText}</span>
        </div>
    </AlertBanner>;
}
