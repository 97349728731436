import React, {useState} from "react";
import {ACSTokenProviderClient} from "./ACSTokenProviderClient";
import {
    persistAcsToken,
    selectActiveMeeting,
    selectMeetingModalVisibility,
    selectParticipatingInMeeting,
    setMeetingModalVisibility
} from "./meetingSlice";
import {msalUtils} from "../../MsalUtils";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import {useMsal} from "@azure/msal-react";
import {useCommunicationsContext} from "./CommunicationsContext";
import {useHistory} from "react-router-dom";
import AudioVideoPreview from "../../MeetingPortal/AudioVideoPreview";
import LoadingIndicator from "../../pages/LoadingIndicator";

const PreviewContainer: React.FC = () => {
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const meetingModalVisibility = useAppSelector(selectMeetingModalVisibility)!;
    const activeMeeting = useAppSelector(selectActiveMeeting);
    const isParticipatingInMeeting = useAppSelector(selectParticipatingInMeeting);

    const [isLoadingMeeting, setLoadingMeeting] = useState(false);

    const msal = useMsal();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const communicationsContext = useCommunicationsContext();

    const onJoinNow = (isAudioEnabled: boolean, isCameraEnabled: boolean) => {
        const tokenRefresher = () => {
            return ACSTokenProviderClient.getACSTeamsUserToken(msal)
                .then(acsToken => acsToken);
        }

        setLoadingMeeting(true);

        communicationsContext.disposeVideoPreview();

        (releaseToggles?.enableMeetingManagementRemote
                ? ACSTokenProviderClient.getACSTeamsUserToken(msal)
                : Promise.resolve('')
        ).then((acsToken) => {
            if (!acsToken) {
                return;
            }
            dispatch(persistAcsToken(acsToken));
            return communicationsContext.connect({
                acsTokenDetails: {
                    accessToken: acsToken,
                    tokenRefresher
                },
                userId: msalUtils.getAccountUserObjectId(msal),
                displayName: msalUtils.getAccountName(msal),
                joinUrl: activeMeeting!.onlineMeetingJoinUrl,
                isAudioOn: isAudioEnabled,
                isVideoOn: isCameraEnabled,

            })
        }).then(() => {
            dispatch(setMeetingModalVisibility({
                ...meetingModalVisibility,
                meetingInfo: true,
            }));
            history.push(`/Profile/${activeMeeting?.profileIdToPresent}/ClientProfile/FamilyTree`)
        }).catch((error) => {
            console.error("Unable to connect to call", error.message);
            if (releaseToggles?.enableMeetingManagementRemote) {
                communicationsContext.disconnect().then();
            }
        })
    }

    return (
        <>
            {
                activeMeeting && isParticipatingInMeeting && <div className={'preview-container'}>
                    {!isLoadingMeeting && <AudioVideoPreview showNTLogo={false} onJoinNow={onJoinNow}/>}
                    {isLoadingMeeting && <LoadingIndicator displayMessage={"Your meeting is loading..."}/>}
                </div>
            }
        </>
    )
}

export default PreviewContainer;
