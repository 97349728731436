import React from "react";
import ModalWrapper from "src/components/Modal/ModalWrapper/ModalWrapper";


type DeleteGoalModalProps = {
    showDeleteGoalModal: boolean,
    onDelete: () => void
    onCancel: () => void
    goalType: string | undefined
    description: string
    cancelButtonText?: string
}

const DeleteGoalModal: React.FC<DeleteGoalModalProps> = (
    {
        showDeleteGoalModal,
        onDelete,
        onCancel,
        goalType,
        description,
        cancelButtonText = "KEEP EDITING"
    }) => {


    return (
        <ModalWrapper
            id="discard-changes-modal"
            isOpen={showDeleteGoalModal}
            headerText={`Delete this Goal?`}
            buttons={[
                {
                    text: cancelButtonText,
                    onClick: onCancel
                },
                {
                    text: "DELETE GOAL",
                    onClick: onDelete,
                    destructive: true,
                    primary: true,
                },
            ]}
        >
            <div className="font-md">
               The {description} {goalType} goal and all associated data will be deleted permanently
            </div>
        </ModalWrapper>
    )
};

export default DeleteGoalModal;