import {HasPercentageString, OwnershipDetailsFormData} from "../models/Ownership";

interface OwnershipPercentageTotals {
    inEstateTotal: number,
    outOfEstateTotal: number,
    grandTotal: number,
}

export const calculateOwnershipPercentages = (ownershipDetails: OwnershipDetailsFormData): OwnershipPercentageTotals => {
    const {memberOwnerships, legalEntityOwnerships, nonClientMemberOwnerships} = ownershipDetails;

    const inEstateTotal = memberOwnerships.reduce(calculateSum, 0);

    const legalEntityTotal = legalEntityOwnerships.reduce(calculateSum, 0);
    const nonClientMemberTotal = nonClientMemberOwnerships.reduce(calculateSum, 0);
    const outOfEstateTotal = legalEntityTotal + nonClientMemberTotal;

    return {
        inEstateTotal: round(inEstateTotal),
        outOfEstateTotal: round(outOfEstateTotal),
        grandTotal: round(inEstateTotal + outOfEstateTotal),
    };
}

interface OwnershipDetailsValidationResult {
    isTotalOwnedPercentageEqualTo100: boolean,
    isTotalOutOfEstateOwnedPercentageEqualTo0: boolean
}

export const isOwnershipPercentageNotEqual100 = (ownershipDetailsFormData: OwnershipDetailsFormData) => {
    return !validateOwnershipDetails(ownershipDetailsFormData).isTotalOwnedPercentageEqualTo100;
}

export const validateOwnershipDetails = (ownershipDetails: OwnershipDetailsFormData): OwnershipDetailsValidationResult => {
    const {grandTotal, outOfEstateTotal} = calculateOwnershipPercentages(ownershipDetails);

    return {
        isTotalOwnedPercentageEqualTo100: grandTotal === 100,
        isTotalOutOfEstateOwnedPercentageEqualTo0: outOfEstateTotal === 0
    };
}

function round(value: number) {
    return parseFloat(value.toFixed(2));
}

function calculateSum(previousSum: number, ownership: HasPercentageString) {
    return parseFloat(ownership.percentage) + previousSum;
}
