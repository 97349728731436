import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../store/hooks";

import LoadingIndicator from "../pages/LoadingIndicator";

import {
    selectPortfolioReserveUserPreferences, setPortfolioReserveModel,
    setPortfolioReserveUserPreferences, setPortfolioReserveYears
} from "../Goals/PortfolioReserve/PortfolioReserveSlice";
import PortfolioReserveService from "../Goals/PortfolioReserve/PortfolioReserveService";
import {useParams} from "react-router-dom";
import {RouteWithId} from "../routes/types";
import {genericEmptyErrorModalData, GenericErrorModalData} from "../components/Modal/Error/GenericErrorModal";
import MonitorPortfolioReserveReport from "./MonitorPortfolioReserveReport";
import {ProfileResponse} from "../ClientManagement/models/ProfileResponse";
import {CurrentAllocationResponse} from "../ClientManagement/AssetAllocation/CurrentAllocationResponse";
import {ProposedAllocationResponse} from "../ClientManagement/AssetAllocation/ProposedAllocationResponse";
import {ReviseAssetAllocationResponse} from "../ClientManagement/AssetAllocation/ReviseAssetAllocation";

type MonitorPortfolioReserveWrapperProps = {
    approvedProfile: ProfileResponse,
    currentAllocation: ReviseAssetAllocationResponse,
    proposedAllocation: ReviseAssetAllocationResponse
}
const MonitorPortfolioReserveWrapper = ({
                                            approvedProfile,
                                            currentAllocation,
                                            proposedAllocation
                                        }: MonitorPortfolioReserveWrapperProps) => {
    const [isLoading, setIsLoading] = useState(true);

    const {id} = useParams<RouteWithId>();
    const dispatch = useAppDispatch();
    const portfolioReserveUserPreferences = useAppSelector(selectPortfolioReserveUserPreferences);
    const [genericError, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);

    const handleError = ((traceId: string) => {
        console.log(genericError)
        setGenericError({
            isOpen: true,
            header: 'Communication Failure',
            message: 'There has been a communication failure. The data you see may not be accurate, please refresh your browser. If this error continues, please contact the support team.',
            operationId: traceId
        });
    })

    useEffect(() => {
        PortfolioReserveService.getPortfolioReserveModel(id, handleError)
            .then(portfolioReserveModel => {
                dispatch(setPortfolioReserveUserPreferences({
                    ...portfolioReserveUserPreferences,
                    clickedPortfolioReserveTargetLengthInput: false,
                    clickedLifestyleMinimumRiskInput: false
                }));
                dispatch(setPortfolioReserveModel(portfolioReserveModel));
                dispatch(setPortfolioReserveYears({
                    prTargetInputYears: portfolioReserveModel.portfolioReserve.portfolioReserveTargetLength,
                    minimumRiskInputYears: portfolioReserveModel.portfolioReserve.minimumRiskReserveLength
                }))
                setIsLoading(false);
            });

    }, [id]);

    if (isLoading) {
        return <LoadingIndicator/>
    }

    return <MonitorPortfolioReserveReport
        approvedProfile={approvedProfile}
        currentAllocation={currentAllocation}
        proposedAllocation={proposedAllocation}/>

}

export default MonitorPortfolioReserveWrapper;