import React from 'react'
import AccordionWithHeader from 'src/components/Accordion/AccordionWithHeader';
import classNames from 'classnames';
import {COLOR_EXCESS_ASSETS_ACCENT} from "../../constants/colors";
import {DataDisplayView, FundingDisplayOptions, GoalFundingResponse} from "../../Goals/Funding/models/Funding";
import {FundingAccordionHeader} from "../../Goals/Funding/FundingAccordionHeader";
import FundingSummaryTotals from "../../Goals/Funding/FundingSummaryTotals";
import {GoalBarChartKeyColors} from "../../Goals/BarChartSidebar/GoalBarChartKeyColors";
import FundingTableSummary from "../../Goals/Funding/FundingTableSummary";
import FamilyFundingSummary from "../../Goals/Funding/FamilyFundingSummary";
import {
    formatFundingCurrency,
    formatFundingPercentage,
    isDollarView,
    isShowFundingForInflows,
    isShowFundingForNonInvestable,
    isShowOnlyPortfolio
} from "../../Goals/Funding/fundingUtils";

type FundingSummaryProps = {
    ageIndex: number;
    startAge: number;
    fundingInfo: GoalFundingResponse;
    displayOptions: FundingDisplayOptions;
    displayView: DataDisplayView;
    pageNumber: number;
    isShortfall: boolean;
}

const FundingSummaryReport: React.FC<FundingSummaryProps> = ({
                                                                 ageIndex,
                                                                 startAge,
                                                                 fundingInfo,
                                                                 displayOptions,
                                                                 displayView,
                                                                 pageNumber,
                                                                 isShortfall
                                                             }) => {
    return (
        <div
            className={classNames("funding-summary", {
                "funding-view-default":
                    displayOptions === FundingDisplayOptions.ONLY_PORTFOLIO,
                "funding-view-with-inflows-or-non-investable":
                    displayOptions === FundingDisplayOptions.PORTFOLIO_AND_INFLOWS ||
                    displayOptions === FundingDisplayOptions.PORTFOLIO_AND_NON_INVESTABLE,
                "funding-view-with-inflows-and-non-investable":
                    displayOptions === FundingDisplayOptions.PORTFOLIO_AND_INFLOWS_AND_NON_INVESTABLE,
            })}
        >
            {pageNumber === 1 && <FundingSummaryTotals
                ageIndex={ageIndex}
                fundingInfo={fundingInfo}
                isShortfall={isShortfall}
            />}
            <div className='funding-summary__tables'>
                <AccordionWithHeader
                    accordionId={"GoalFundingAccordion"}
                    allItems={["Lifestyle Spending", "Discretionary Goals", "Philanthropic Goals", "Family Goals"]}
                    headerProps={{ className: "funding-grid", gridSpanClass: "" }}
                    columnTitles={[]}
                    HeaderComponent={({ expanded, toggle }) => (
                        <FundingAccordionHeader expanded={expanded} toggle={toggle} displayOptions={displayOptions} />
                    )}
                    renderHeader={true}
                >
                    {pageNumber === 1 && <FundingTableSummary
                        title="Lifestyle Spending"
                        ageIndex={ageIndex}
                        startAge={startAge}
                        goalsSummary={fundingInfo.lifestyle}
                        color={GoalBarChartKeyColors.LIFESTYLE}
                        displayOptions={displayOptions}
                        displayView={displayView}
                    />}

                    {pageNumber === 2 &&  <FundingTableSummary
                        title="Discretionary Goals"
                        ageIndex={ageIndex}
                        startAge={startAge}
                        goalsSummary={fundingInfo.discretionary}
                        color={GoalBarChartKeyColors.DISCRETIONARY}
                        displayOptions={displayOptions}
                        displayView={displayView}
                    />}
                    {pageNumber === 2 && <FundingTableSummary
                        title="Philanthropic Goals"
                        ageIndex={ageIndex}
                        startAge={startAge}
                        goalsSummary={fundingInfo.philanthropic}
                        color={GoalBarChartKeyColors.PHILANTHROPIC}
                        displayOptions={displayOptions}
                        displayView={displayView}
                    />}
                    {pageNumber === 2  && <FamilyFundingSummary
                        title="Family Goals"
                        ageIndex={ageIndex}
                        startAge={startAge}
                        goalsSummaryTotal={fundingInfo.family}
                        color={GoalBarChartKeyColors.FAMILY}
                        displayOptions={displayOptions}
                        displayView={displayView}
                        />}
                    {pageNumber === 2  && <FundingTableSummary
                        title="Tax Liabilities"
                        ageIndex={ageIndex}
                        startAge={startAge}
                        goalsSummary={fundingInfo.taxLiabilities}
                        color={GoalBarChartKeyColors.TAX_LIABILITIES}
                        displayOptions={displayOptions}
                        displayView={displayView}
                        enableAchieved={false}
                        /> }
                    {pageNumber === 2   && <FundingTableSummary
                        title={isShortfall ? "Investable Shortfall" : "Investable Excess"}
                        ageIndex={ageIndex}
                        startAge={startAge}
                        goalsSummary={fundingInfo.investableExcess}
                        color={COLOR_EXCESS_ASSETS_ACCENT}
                        displayOptions={displayOptions}
                        displayView={displayView}
                        showNested={false}
                        enableAchieved={false}
                        />
                    }
                    {pageNumber === 2 && <div className='funding-summary__tables__total funding-grid'>
                        <span className="font-lg textalign-left paddingleft-xxxl"><b>Totals</b></span>
                        <span role="cell" className="font-lg textalign-right" aria-label="Goals Total Funded by Risk Assets">
                            <b>{isDollarView(displayView) ? formatFundingCurrency(fundingInfo.fundedByRiskAssetsAtYear?.[ageIndex]) : formatFundingPercentage(fundingInfo.fundedByRiskAssetsPercentageAtYear?.[ageIndex])}</b>
                        </span>
                        <span role="cell" className="font-lg textalign-right" aria-label="Goals Total Funded by Risk Control">
                            <b>{isDollarView(displayView)? formatFundingCurrency(fundingInfo.fundedByRiskControlAtYear?.[ageIndex]) : formatFundingPercentage(fundingInfo.fundedByRiskControlPercentageAtYear?.[ageIndex])}</b>
                        </span>
                        {!isShowOnlyPortfolio(displayOptions) && <span role="cell" className="font-lg textalign-right" aria-label="Funded by Portfolio">
                            <b>{isDollarView(displayView) ? formatFundingCurrency(fundingInfo.fundedByPortfolioAtYear?.[ageIndex]) : ""}</b>
                        </span>}
                        {isShowFundingForInflows(displayOptions) &&
                            <span role="cell" className="font-lg textalign-right" aria-label="Goals Total Funded by Inflows">
                                <b>{isDollarView(displayView) ? formatFundingCurrency(fundingInfo.fundedByInflowsAtYear?.[ageIndex]) : ""}</b>
                            </span>
                        }
                        {isShowFundingForNonInvestable(displayOptions) &&
                            <span role="cell" className="font-lg textalign-right" aria-label="Goals Total Funded by Non Investable">
                                <b>{isDollarView(displayView) ? formatFundingCurrency(fundingInfo.fundedByNonInvestableAtYear?.[ageIndex]) : ""}</b>
                            </span>
                        }
                        <span className="font-lg textalign-right" aria-label="Goals Total Present Value">
                            <b>{formatFundingCurrency(fundingInfo.presentValueAtYear?.[ageIndex])}</b>
                        </span>
                    </div> }
                </AccordionWithHeader>
            </div>
        </div>
    );
}

export default FundingSummaryReport