import React from "react";
import classNames from "classnames";
import {RiskControlClass} from "../AssetClasses";
import GoalsDrivingCashTargetForNextYear from "./GoalsDrivingCashTargetForNextYear";
import {formatAllocationAmount, formatAllocationPercentage} from "./reviseAssetAllocationUtils";
import ReviseAllocationInput from "./ReviseAllocationInput";
import ReviseAllocationSubclassMenu from "./ReviseAllocationSubclassMenu";
import {
    DataDisplayView,
    GoalsSummaryForOneYear,
    ProposedAllocationEntry
} from "../ReviseAssetAllocation";
import {useAppSelector} from "../../../store/hooks";
import {selectReleaseToggles} from "../../../ReleaseToggles/releaseTogglesSlice";
import {ReviseAssetSubclassesSummary} from "./ReviseAssetSubclassesSummary";

type  ReviseSubClassAssetsProps = {
    reviseAssetSubClassAllocation: ReviseAssetSubclassesSummary[],
    dollarView: boolean,
    totalProposedAmount: number,
    showIPCRange: boolean,
    gridViewClasses: any,
    areInputsDisabled: boolean,
    averageTargetMaturity: number | null,
    goalsSummaryForOneYear: GoalsSummaryForOneYear,
    updateProposedAllocationEntry: (value: ProposedAllocationEntry) => void,
    displayView: DataDisplayView,
    totalProposedAllocation: number,
    toggleExcludeSubclass: (subclassName: string, excludeSubclass: boolean) => Promise<void>,
    isProposedAllocationValuesLoadedFirstTime: boolean;
    isProfileWithProposalsOrArchived?: boolean;
}

export const ReviseSubClassAssets: React.FC<ReviseSubClassAssetsProps> = ({
                                                                              reviseAssetSubClassAllocation,
                                                                              dollarView,
                                                                              totalProposedAmount,
                                                                              showIPCRange,
                                                                              gridViewClasses,
                                                                              areInputsDisabled,
                                                                              averageTargetMaturity,
                                                                              goalsSummaryForOneYear,
                                                                              updateProposedAllocationEntry,
                                                                              displayView,
                                                                              totalProposedAllocation,
                                                                              toggleExcludeSubclass,
                                                                              isProposedAllocationValuesLoadedFirstTime,
                                                                              isProfileWithProposalsOrArchived = false
                                                                          }) => {
    const releaseToggles = useAppSelector(selectReleaseToggles);

    return <>{reviseAssetSubClassAllocation.map(({
                                                     subclassName,
                                                     currentInvestablePercent,
                                                     currentInvestableAmount,
                                                     proposedInvestablePercent,
                                                     proposedInvestableAmount,
                                                     proposedInvestablePercentOfTotalPortfolio,
                                                     differenceAmount,
                                                     recommendedProposedTargetPercent,
                                                     recommendedProposedTargetAmount,
                                                     minimumProposedInvestablePercent,
                                                     minimumProposedInvestableAmount,
                                                     maximumProposedInvestablePercent,
                                                     maximumProposedInvestableAmount,
                                                     excludeSubclass,
                                                     isDisableAsset,
                                                     readonly,
                                                     lockedAmount
                                                 }) => {
        const isOutOfIPCRange = (proposedInvestablePercent > maximumProposedInvestablePercent) || (proposedInvestablePercent < minimumProposedInvestablePercent);
        const getMinValue = () => {
            if (lockedAmount && proposedInvestableAmount > lockedAmount) {
                return dollarView ? lockedAmount : (lockedAmount / totalProposedAmount) * 100;
            }
            return 0;
        };

        return (
            <div
                key={subclassName}
                className={classNames(
                    gridViewClasses,
                    {'excluded-subclass': excludeSubclass || isDisableAsset},
                    'revise-allocation-subclass-grid revise-allocation-subclass-row'
                )}
                role="row"
                tabIndex={0}
                aria-label={`${subclassName} row`}

            >
                <div className="grid-left-contents">
                                        <span role="gridcell" aria-label={`${subclassName} Name`}>
                                            <span className="subclass-name">
                                                <span className="marginright-12">{subclassName}</span>
                                                {releaseToggles?.enableRiskControlEnhancements && <>
                                                    {(subclassName === RiskControlClass.INFLATION_PROTECTED_FIXED_INCOME || subclassName === RiskControlClass.CONVENTIONAL_FIXED_INCOME) && averageTargetMaturity !== null &&
                                                        <i className="risk-control-label">{averageTargetMaturity} {averageTargetMaturity > 1 ? 'years' : 'year'} average
                                                            target maturity</i>
                                                    }
                                                </>}
                                                {subclassName === RiskControlClass.CASH_AND_CASH_EQUIVALENTS &&
                                                    <span className="display-flex risk-control-label">
                                                        <i className="marginright-12">1 year of cash</i>
                                                        <GoalsDrivingCashTargetForNextYear
                                                            goalsSummaryForOneYear={goalsSummaryForOneYear}/>
                                                    </span>}
                                            </span>
                                        </span>
                    <span role="gridcell" className="textalign-right"
                          aria-label={`${subclassName} Current ${dollarView ? "$" : "%"}`}>
                                            {dollarView ? formatAllocationAmount(currentInvestableAmount) : formatAllocationPercentage(currentInvestablePercent)}
                                        </span>
                    <span role="gridcell" className="textalign-right aqua-bg"
                          aria-label={`${subclassName} Recommended Target ${dollarView ? "$" : "%"}`}>
                                            {dollarView ? formatAllocationAmount(recommendedProposedTargetAmount) : formatAllocationPercentage(recommendedProposedTargetPercent)}
                                        </span>
                    {showIPCRange &&
                        <>
                                                <span role="gridcell" className="textalign-right grey-bg"
                                                      aria-label={`${subclassName} IPC Range Minimum ${dollarView ? "$" : "%"}`}>
                                                    {dollarView ? formatAllocationAmount(minimumProposedInvestableAmount) : formatAllocationPercentage(minimumProposedInvestablePercent)}
                                                </span>
                            <span role="gridcell" className="textalign-right grey-bg"
                                  aria-label={`${subclassName} IPC Range Maximum ${dollarView ? "$" : "%"}`}>
                                                    {dollarView ? formatAllocationAmount(maximumProposedInvestableAmount) : formatAllocationPercentage(maximumProposedInvestablePercent)}
                                                </span>
                        </>
                    }
                    <span role="gridcell">
                                            <ReviseAllocationInput
                                                proposedInvestableValue={dollarView ? proposedInvestableAmount : proposedInvestablePercent}
                                                minValue={getMinValue()}
                                                assetSubclassName={subclassName}
                                                isLocked={lockedAmount>0}
                                                onUpdateProposedAllocationEntry={updateProposedAllocationEntry}
                                                showIpcRangeWarning={isOutOfIPCRange}
                                                disabled={areInputsDisabled || excludeSubclass || isDisableAsset || readonly || isProfileWithProposalsOrArchived}
                                                displayView={displayView}
                                                totalProposedAllocationValue={totalProposedAllocation}
                                                isProposedAllocationValuesLoadedFirstTime={isProposedAllocationValuesLoadedFirstTime}
                                            />
                                        </span>
                </div>
                <span className="grid-divider"></span>
                <div className="grid-right-contents">
                                        <span role="gridcell" className="textalign-right"
                                              aria-label={`${subclassName} ${dollarView ? "Difference $" : "% of Total Portfolio"}`}>
                                            {dollarView ? formatAllocationAmount(differenceAmount) : formatAllocationPercentage(proposedInvestablePercentOfTotalPortfolio)}
                                        </span>
                    <ReviseAllocationSubclassMenu
                        subclassName={subclassName}
                        toggleExcludeSubclass={toggleExcludeSubclass}
                        excludeSubclass={excludeSubclass}
                        isDisableMenu={isDisableAsset || isProfileWithProposalsOrArchived}
                    />
                </div>
            </div>
        );
    })}</>
}