import {InfoPopover, RadioGroup, UnderlinedHeader} from "../../components";
import React from "react";
import {NonLifestyleGoalType} from "../models/GoalType";

interface GoalFundingProps {
    nonLifestyleGoal: NonLifestyleGoalType,
    updateNonLifestyleGoal: (updatedNonLifestyleGoal: Partial<NonLifestyleGoalType['userInputs']>) => void,
    isProfileWithProposalsOrArchived?: boolean
}

export const GoalFunding = ({
                                nonLifestyleGoal,
                                updateNonLifestyleGoal,
                                isProfileWithProposalsOrArchived=false
                                  }: GoalFundingProps) => {

    const investableRadioOption = "Funded by Investable Assets (Current Portfolio)"
    const nonInvestableRadioOption = "Funded by Non-Investable Assets (Inflows, Illiquid Assets, Personal Assets)"

    function booleanToRadioButton(value: boolean): string {
        switch (value) {
            case true: return nonInvestableRadioOption;
            case false: return investableRadioOption;
            default: return investableRadioOption;
        }
    }

    function radioButtonToBoolean(value : string) {
        return value === nonInvestableRadioOption;
    }

    return <section aria-label="Goal Funding">
        <UnderlinedHeader
            primaryText='Goal Funding'
            secondaryContent={
                <InfoPopover
                    content={<div>Indicates whether the proposed allocation of the goal will
                        impact the proposed asset allocation of the client's portfolio. Visit the Goal Funding
                        page to view and edit this information for all goals to confirm they are aligned to
                        the proper assets and avoid a shortfall of goal-aligned investable assets.</div>}
                    direction="top"
                    width="288px"
                    label="goal-funding-popover"
                />
            }
            className="goal-timeframe-underlined-header"
        />
        <RadioGroup
            name={""}
            data-testid={"goal-funding-radio-group"}
            layout="vertical"
            values={[investableRadioOption, nonInvestableRadioOption]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                let newValue =  e.target.value;
                updateNonLifestyleGoal({isFundedByNonInvestableAssets: radioButtonToBoolean(newValue)});
            }}
            selected={booleanToRadioButton(nonLifestyleGoal.userInputs.isFundedByNonInvestableAssets)}
            disabled={isProfileWithProposalsOrArchived}
        />
    </section>
}
