export type MonteCarloResponse = {
    minimumRisk: MonteCarloRangeDetails[],
    portfolioSupports: MonteCarloRangeDetails[]
}

export type MonteCarloRangeDetails = {
    percentile: number,
    bottom: number,
    top: number
}

export const emptyMonteCarloResponse: MonteCarloResponse = {
    minimumRisk: [
        {
            percentile: 0,
            bottom: 0,
            top: 0
        }
    ],
    portfolioSupports: [
        {
            percentile: 0,
            bottom: 0,
            top: 0
        }]
}
