import React from "react";
import SectionHeader from "../SectionHeader";
import {Accordion} from "../../../../components";
import {PersonalLiabilitySummary} from "../../../models/PersonalLiability";
import PersonalLiabilities from "./PersonalLiabilities";
import {AccordionItemIds} from "../constants";
import {DropResult} from "react-beautiful-dnd";
import {NO_OP} from "../../../../constants/common";

const columns = ["Liability Type", "Present Value"];

type LiabilitiesProps = {
    totalLiabilitiesValue: number,
    personalLiabilities: PersonalLiabilitySummary[];
    capturePersonaLiabilitiesOrder?: ({source, destination}: DropResult) => void
}

const expandedItems = [AccordionItemIds.personalLiabilities]

const Liabilities = ({
                         totalLiabilitiesValue,
                         personalLiabilities,
                         capturePersonaLiabilitiesOrder = NO_OP
                     }: LiabilitiesProps) => {

    if (personalLiabilities.length === 0) {
        return <></>;
    }

    return (
        <>
            <SectionHeader
                columns={columns}
                gridSpanClass="grid-span-4"
            />
            <Accordion
                expanded={expandedItems}
            >
                <PersonalLiabilities
                    totalLiabilitiesValue={totalLiabilitiesValue}
                    personalLiabilities={personalLiabilities}
                    capturePersonaLiabilitiesOrder={capturePersonaLiabilitiesOrder}
                />
            </Accordion>
        </>
    )
}
export default Liabilities;