import {MemberGroup, MemberGroupMember} from "../../../ClientManagement/models/InvestorGroupType";
import {MemberOwnershipFormData} from "../../models/Ownership";

export function getCurrentOwnerByOwnerMemberId(ownerMemberId: string, memberGroup: MemberGroup) {
    let selectedOwner = memberGroup.primaryMember;
    if (memberGroup.partnerMember?.id === ownerMemberId) {
        selectedOwner = memberGroup.partnerMember;
    } else if (memberGroup.additionalMembers.length > 0 && (memberGroup.additionalMembers.find(member => member.id === ownerMemberId) !== undefined)) {
        selectedOwner = memberGroup.additionalMembers.find(member => member.id === ownerMemberId)!
    }
    return selectedOwner;
}

 export function getOwnerName(memberOwnerships: MemberOwnershipFormData[], memberGroup: MemberGroup) {
    let ownerName = "";
    let count = 0;
    if (memberOwnerships.length > 0) {
        memberOwnerships.forEach(member => {
            count++
            ownerName = ownerName + getCurrentOwnerByOwnerMemberId(member.memberId, memberGroup).firstName + (count < memberOwnerships.length ? ", " : "");
        })
    }
    return ownerName;
}

export function getMemberName(member: MemberGroupMember) {
    return `${member.firstName} ${member.lastName}`;
}
