import classNames from "classnames";
import React, {ReactElement} from "react";
import {InfoPopover} from "../InfoPopover";

type TableCellProps = {
    text?: string | null | ReactElement,
    subtext?: string | ReactElement,
    className?: string,
    textSize?: 'small' | 'medium',
    popoverContent?: ReactElement,
    popoverWidth?: string
    ariaLabel?: string,
};

export function TableCell({
                              text,
                              subtext,
                              className,
                              textSize = 'medium',
                              popoverContent,
                              ariaLabel,
                              popoverWidth
                          }: TableCellProps) {

    const renderCellContent = () => {
        if (popoverContent) {
            return (
                <div style={{display: "flex"}}>
                    <div className="cell-text-content">
                        {text}
                    </div>
                    <InfoPopover content={popoverContent} direction="top" width={popoverWidth} className="current-net-worth-popover-text"/>
                </div>

            )
        } else {
            return (
                <div className="cell-text-content">
                    {text}
                </div>
            )
        }
    }

    return (
        <div role="cell" aria-label={ariaLabel} className={classNames(className,
            {"label-15px": textSize === 'medium'},
            {"label-13px": textSize === 'small'})}>
            {renderCellContent()}
            <div>
                {
                    subtext &&
                    <div className="sublabel-12px cell-text-content" data-testid="tableCellSubText">
                        {subtext}
                    </div>
                }
            </div>
        </div>
    );
}
