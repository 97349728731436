import {NavLink} from "react-router-dom";
import classNames from "classnames";
import {ConfirmExitMeetingModal} from "src/ClientManagement/Meeting/MeetingActions";
import MeetingToasts from "src/ClientManagement/Meeting/Toast/MeetingToasts";
import AppHeader from "src/components/Header/AppHeader";
import PdfPreviewPane from "src/components/QuickSlides/PdfPreviewPane";
import LoadingIndicator from "src/pages/LoadingIndicator";
import ProfileControls from "../ProfileControls";
import ClientProfileHeader from "../ClientProfileHeader";
import {LinkProps} from "src/models/routeData/RouteParamTypes";
import {ResourcesState} from "src/Resources/resourcesSlice";
import {
    BarChartSideBarPointerInfo,
    Meeting,
    MeetingContentCanvas,
    MeetingContentDOM,
    MeetingContentScrollPositions,
    MeetingParticipant,
    MeetingStatus,
    MultiPartMeetingContent,
    PresenterPointerCoordinatesInfo
} from "src/ClientManagement/Meeting/Meeting";
import Routes from "./Routes";
import MeetingInfoModal from "../../Meeting/Modal/MeetingInfoModal";
import React, {useCallback, useEffect, useMemo, useReducer, useRef} from "react";
import {MeetingContainerSchema, useRelayContext} from "../../Meeting/Relay/types/RelayContext";
import useMeetingUtils from "../../Meeting/useMeetingUtils";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {
    selectMeetingModalVisibility,
    selectShowClientView,
    selectShowExpandedVideoGallery,
    setActiveMeeting,
} from "../../Meeting/meetingSlice";
import {splitMultiPartMeetingContent} from "../../Meeting/multiPartMeetingContentUtils";
import {
    getClosestScrollContainerFromPoint,
    getScrollHeight,
    getScrollLeft,
    getScrollTop,
    getScrollWidth
} from "../../../components/ScrollableContainer/ScrollableContainerUtils";
import ExpandedVideoGalleryWindow from "../../Meeting/ExpandedVideoGallery/ExpandedVideoGalleryWindow";
import ClientViewWindow from "../../Meeting/ClientViewWindow";
import {selectReleaseToggles} from "../../../ReleaseToggles/releaseTogglesSlice";
import {IValueChanged} from "fluid-framework";
import {msalUtils} from "../../../MsalUtils";
import {useMsal} from "@azure/msal-react";
import {selectResourceCode} from "../activeProfileSlice";
import {partnerApiClient} from "../../PartnerApiClient";
import {ServiceTeamMember} from "../../models/ServiceTeam";
import {meetingApiClient} from "../../Meeting/MeetingApiClient";
import {getHoveredBarChartSectionId} from "../../Meeting/utils/PointerSyncUtils";

type MeetingContent = {
    dom: MeetingContentDOM;
    canvasRecords: MeetingContentCanvas;
    scrollPositions: MeetingContentScrollPositions;
    forceRedraw: boolean;
    forceRedrawCanvas: boolean;
    location: string;
    dirty: boolean;
};
type MeetingContentChangeAction = {
    type: 'reset';
} | {
    type: 'update';
    data: Partial<MeetingContent>;
};

const MAX_MESSAGE_SIZE_BYTES = 250_000;
const DEFAULT_MEETING_CONTENT: MeetingContent = {
    dom: '',
    canvasRecords: {},
    scrollPositions: {},
    forceRedraw: false,
    forceRedrawCanvas: false,
    location: '',
    dirty: false,
};

export type ClientProfileProps = {
    history: any;
    onLogoClick: () => void;
    navigationLinks: LinkProps[];
    documentInfo: ResourcesState | undefined;
    showMeetingControls: boolean;
    isMeetingActive: string;
    meeting: Meeting;
    isLoading: boolean;
    isConfirmExitMeetingModalOpen: boolean;
    handleCancel: () => void;
}

const ClientProfile = ({
                           history,
                           onLogoClick,
                           navigationLinks,
                           documentInfo,
                           showMeetingControls,
                           isMeetingActive,
                           meeting,
                           isLoading,
                           isConfirmExitMeetingModalOpen,
                           handleCancel
                       }: ClientProfileProps) => {
    const {sharedObjects} = useRelayContext();
    const {sharedObjectMutators} = useRelayContext();
    const {isCurrentUserPresenting} = useMeetingUtils();
    const resourceCode = useAppSelector(selectResourceCode);
    const presentationViewRef = useRef<HTMLDivElement | null>(null);
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const msal = useMsal();
    const dispatch = useAppDispatch();
    const pathName: string = history!.location!.pathname;
    const [meetingContent, dispatchMeetingContent] = useReducer(
        (state: MeetingContent, action: MeetingContentChangeAction) => {
            if (action.type === 'reset') {
                return {...DEFAULT_MEETING_CONTENT};
            }
            return {
                ...state,
                ...action.data,
                dirty: true,
            };
        },
        DEFAULT_MEETING_CONTENT
    );
    const updateMeetingContentTimer = useRef<ReturnType<typeof setInterval> | null>(null);

    const isSyncingPresentationView: boolean = useMemo(() => !!sharedObjects
            && !!presentationViewRef.current
            && isCurrentUserPresenting
            && meeting.status === MeetingStatus.STARTED,
        [sharedObjects, presentationViewRef.current, isCurrentUserPresenting, meeting.status]
    );

    const isOtherPartnerPresenting: boolean = useMemo(() => !!sharedObjects
            && !isCurrentUserPresenting
            && meeting.status === MeetingStatus.STARTED,
        [sharedObjects, isCurrentUserPresenting, meeting.status]
    );

    const setNewPresenter = useCallback(async () => {
        const lanId = msalUtils.getLanId(msal);
        const accountName = msalUtils.getAccountName(msal);

        let meetingParticipants: string[] = [];
        sharedObjects?.meetingParticipantDDS.get('participants').forEach((participant: MeetingParticipant) => {
            meetingParticipants.push(participant.userId.toLowerCase());
        })

        let serviceTeam: string[] = [];
        await partnerApiClient.getServiceTeam(resourceCode).then((response: ServiceTeamMember[]) => {
            response.forEach(teamMember => {
                serviceTeam.push(teamMember.partnerCode.toLowerCase());
            })
        })

        let partnersInMeeting = meetingParticipants.filter(
            (element) => serviceTeam.includes(element));

        partnersInMeeting.sort();

        if (partnersInMeeting.length > 0 && lanId.toString().toLowerCase() === partnersInMeeting[0]) {
            await meetingApiClient.updateMeeting({
                ...meeting,
                presenter: lanId,
                presenterName: accountName,
            }).then((updatedMeeting) => {
                dispatch(setActiveMeeting(updatedMeeting));
                sharedObjectMutators.updatePresenterState({
                    presenter: lanId,
                    presenterName: accountName,
                });
            }).catch((error) => {
                console.error('Could not update meeting', error.message);
            });
        }
    }, [sharedObjects, sharedObjectMutators, meeting, resourceCode]);

    const modalVisibility = useAppSelector(selectMeetingModalVisibility);
    const showExpandedVideoGallery = useAppSelector(selectShowExpandedVideoGallery);
    const showClientViewWindow = useAppSelector(selectShowClientView);

    useEffect(() => {
        if (sharedObjects) {
            sharedObjects.meetingPortalParticipantJoinDDS.on('valueChanged', () => {
                dispatchMeetingContent({type: 'update', data: {forceRedraw: true}});
            });
        }
    }, [sharedObjects]);

    useEffect(() => {
        const clearMeetingContentTimer = () => {
            if (updateMeetingContentTimer.current) {
                clearInterval(updateMeetingContentTimer.current);
                updateMeetingContentTimer.current = null;
            }
        };

        if (!isSyncingPresentationView) {
            clearMeetingContentTimer();
            if (meetingContent.dirty) {
                dispatchMeetingContent({type: 'reset'});
            }
            return;
        }

        updateMeetingContentTimer.current = setInterval(() => {
            if (presentationViewRef.current && sharedObjects) {
                let meetingContentChanges: Partial<MeetingContent> | null = {};

                if (pathName !== meetingContent.location) {
                    meetingContent.forceRedrawCanvas = true;
                }

                checkMeetingContentDom(presentationViewRef, meetingContent.forceRedraw, meetingContent.dom, sharedObjects, (content) => {
                    if (meetingContentChanges) {
                        meetingContentChanges.dom = content;
                    }
                });

                checkMeetingContentCanvas(presentationViewRef, meetingContent.forceRedraw, meetingContent.canvasRecords, meetingContent.forceRedrawCanvas, sharedObjects, (canvasData) => {
                    if (meetingContentChanges) {
                        meetingContentChanges.canvasRecords = canvasData;
                        meetingContentChanges.forceRedrawCanvas = false;
                    }
                });

                checkMeetingContentScrollPositions(presentationViewRef, meetingContent.forceRedraw, meetingContent.scrollPositions, sharedObjects, (scrollPositions) => {
                    if (meetingContentChanges) {
                        meetingContentChanges.scrollPositions = scrollPositions;
                    }
                });

                if (meetingContent.forceRedraw) {
                    meetingContentChanges.forceRedraw = false;
                }

                if (Object.keys(meetingContentChanges).length > 0) {
                    meetingContentChanges.location = pathName;
                    dispatchMeetingContent({type: 'update', data: meetingContentChanges});
                }

                meetingContentChanges = null;
            }
        }, 500);

        return () => {
            clearMeetingContentTimer();
        };
    }, [meetingContent, isSyncingPresentationView, pathName]);

    useEffect(() => {
        if (sharedObjects) {
            sharedObjects.meetingParticipantDDS.removeAllListeners("valueChanged");

            const missingParticipantId = (missingAParticipant: MeetingParticipant[] | undefined, allParticipants: MeetingParticipant[]): string => {
                let allParticipantsUserIds: string[] = [];
                allParticipants?.forEach(participant => {
                    allParticipantsUserIds.push(participant.userId.toLowerCase());
                });

                let missingAParticipantUserIds: string[] = [];
                missingAParticipant?.forEach(participant => {
                    missingAParticipantUserIds.push(participant.userId.toLowerCase());
                });

                let removedId: string = "error";
                allParticipantsUserIds.forEach(aId => {
                    let removed: boolean = true;
                    missingAParticipantUserIds.forEach(mId => {
                        if (mId === aId) {
                            removed = false;
                        }
                    })

                    if (removed)
                        removedId = aId;
                });

                return removedId;
            }

            sharedObjects.meetingParticipantDDS.on("valueChanged", (valueChanged: IValueChanged) => {
                if (releaseToggles?.enableHandlePresenterLeaveActiveMeeting) {
                    let currentParticipants: MeetingParticipant[] | undefined = sharedObjects.meetingParticipantDDS.get(valueChanged.key);

                    let previousParticipants: MeetingParticipant[] = valueChanged.previousValue;

                    if (currentParticipants !== undefined && (previousParticipants.length > currentParticipants.length)) {
                        let removedId: string = missingParticipantId(currentParticipants, previousParticipants);
                        if (removedId.toLowerCase() === meeting.presenter?.toLowerCase()) {
                            setNewPresenter().then();
                        }
                    }
                }
            });

            if (!isOtherPartnerPresenting) {
                sharedObjects?.meetingParticipantDDS.removeAllListeners("valueChanged");
                return;
            }

            return () => {
                sharedObjects?.meetingParticipantDDS.removeAllListeners("valueChanged");
            };
        }
    }, [meeting.presenter, sharedObjects, releaseToggles?.enableHandlePresenterLeaveActiveMeeting, isOtherPartnerPresenting]);

    const syncPointerPositionWithClientView = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (isSyncingPresentationView
            && presentationViewRef.current
            && sharedObjects
            && releaseToggles?.enableMeetingManagementClientViewPointer) {
            if (presentationViewRef.current) {
                const scrollContainer = getClosestScrollContainerFromPoint(event.clientX, event.clientY);
                const boundingClientRect = presentationViewRef.current?.getBoundingClientRect();

                const x = (event.clientX - boundingClientRect.x);
                const y = (event.clientY - boundingClientRect.y);

                const pointedElement = document.elementFromPoint(event.clientX, event.clientY);
                const sidebar = pointedElement?.closest('[id="bar-chart-sidebar"]');


                const pointerCoordinatesInfo = {
                    x,
                    y,
                    scrollTop: scrollContainer ? getScrollTop(scrollContainer) : 0,
                    scrollLeft: scrollContainer ? getScrollLeft(scrollContainer) : 0,
                    scrollContainerId: scrollContainer ? scrollContainer.id : null,
                    isOnBarChartSidebar: !!sidebar,
                    barChartSideBarPointerInfo: sidebar ? getBarChartSideBarPointerInfo(pointedElement, sidebar, event.clientY) : undefined
                } as PresenterPointerCoordinatesInfo;

                sharedObjects.pointerCoordinatesInfoDDS.set('pointerCoordinatesInfo', pointerCoordinatesInfo);
            }
        }
    }, [isSyncingPresentationView,
        presentationViewRef.current,
        sharedObjects,
        releaseToggles?.enableMeetingManagementClientViewPointer
    ]);

    return (
        <div className="app-viewport app-viewport--in-meeting">
            <div className="host-viewport">
                <AppHeader
                    headerToolbarAlignment="center"
                    history={history}
                    HeaderToolbar={ClientProfileHeader}
                    LinkRenderer={NavLink}
                    onLogoClick={onLogoClick}
                    links={navigationLinks}
                    navigationStyle="drawer"
                    theme="none"
                    showMeetingControl={true}
                >
                    {documentInfo?.currentPage && documentInfo?.pdfUrl && (
                        <PdfPreviewPane
                            currentPage={documentInfo.currentPage}
                            pdf={documentInfo.pdfUrl}
                            redirectUrl={documentInfo.redirectUrl}
                        />
                    )}
                </AppHeader>
            </div>
            {!showMeetingControls && <ProfileControls pathName={pathName}/>}
            <div
                data-testid="presentation-viewport"
                className={classNames(
                    "presentation-viewport",
                    "presentation-viewport--presenter",
                    {
                        "presentation-viewport--meeting-border meeting-border--red":
                            showMeetingControls &&
                            isMeetingActive &&
                            meeting?.status !== MeetingStatus.STARTED &&
                            meeting?.status !== MeetingStatus.PAUSED &&
                            meeting?.status !== MeetingStatus.ENDED,
                    },
                    {
                        "presentation-viewport--meeting-border meeting-border--orange":
                            showMeetingControls &&
                            isMeetingActive &&
                            meeting?.status === MeetingStatus.PAUSED,
                    }
                )}
            >
                <div className="presentation-view"
                     ref={presentationViewRef}
                     onMouseMoveCapture={event => syncPointerPositionWithClientView(event)}
                >
                    {isLoading ? <LoadingIndicator/> : <Routes/>}
                </div>
            </div>
            <MeetingToasts/>

            {modalVisibility?.meetingInfo && <MeetingInfoModal/>}
            {isConfirmExitMeetingModalOpen && (
                <ConfirmExitMeetingModal
                    isOpen={isConfirmExitMeetingModalOpen}
                    handleCancel={handleCancel}
                    path={`/Profile/${meeting.profileId}`}
                />
            )}
            {showExpandedVideoGallery && <ExpandedVideoGalleryWindow/>}
            {showClientViewWindow && meeting?.status !== MeetingStatus.ENDED && <ClientViewWindow/>}
        </div>
    );
}

const getBarChartSideBarPointerInfo = (pointedElement: Element | null,
                                       sidebar: any,
                                       y: number): BarChartSideBarPointerInfo => {
    const stackedBarChart = sidebar.children.namedItem('bar-chart-sidebar-stacked-bar-chart');

    return {
        hoveredElementId: getHoveredBarChartSectionId(pointedElement),
        pointerYOnBarchart: stackedBarChart ? (y - stackedBarChart.getBoundingClientRect().y) : 0,
        stackBarChartWidth: stackedBarChart ? stackedBarChart.getBoundingClientRect().width : null,
        stackBarChartHeight: stackedBarChart ? stackedBarChart.getBoundingClientRect().height : null,
    } as BarChartSideBarPointerInfo;
};

const checkMeetingContentDom = (
    presentationViewRef: React.MutableRefObject<HTMLDivElement | null>,
    forceRedraw: boolean,
    meetingContentDOM: string,
    sharedObjects: MeetingContainerSchema,
    onDomContentChanged: (content: string) => void,
) => {
    if (presentationViewRef.current) {
        let domContent: string | null = presentationViewRef.current.innerHTML;

        if (forceRedraw || domContent !== meetingContentDOM) {
            onDomContentChanged(domContent);
            splitMultiPartMeetingContent(domContent, MAX_MESSAGE_SIZE_BYTES, (multiPartContent: MultiPartMeetingContent) => {
                let timeoutId: ReturnType<typeof setTimeout> | null = setTimeout(() => {
                    sharedObjects.domContentDDS.set(
                        'meetingContentDOM',
                        multiPartContent
                    );
                    if (timeoutId) {
                        clearTimeout(timeoutId);
                        timeoutId = null;
                    }
                }, multiPartContent.part * 50);
            });
        }

        domContent = null; // NOSONAR
    }
}

const checkMeetingContentCanvas = (
    presentationViewRef: React.MutableRefObject<HTMLDivElement | null>,
    forceRedraw: boolean,
    meetingContentCanvasRecords: MeetingContentCanvas,
    forceRedrawCanvas: boolean,
    sharedObjects: MeetingContainerSchema,
    onCanvasContentChanged: (canvasData: MeetingContentCanvas) => void,
) => {
    if (presentationViewRef.current) {
        let hasCanvasUpdate = false;
        let canvasData: MeetingContentCanvas | null = {};

        let canvasElements: NodeListOf<HTMLCanvasElement> | null = presentationViewRef.current.querySelectorAll('canvas');

        for (let i = 0, l = canvasElements.length; i < l; i++) {
            const targetElement = canvasElements[i];
            const querySelector = getPathToPresentationViewElement(targetElement);
            const canvasDataUrl = targetElement.toDataURL();

            if ((meetingContentCanvasRecords[querySelector] !== canvasDataUrl) || forceRedrawCanvas) {
                hasCanvasUpdate = true;
            }
            canvasData[querySelector] = canvasDataUrl;
        }
        canvasElements = null; // NOSONAR

        if (forceRedraw || hasCanvasUpdate) {
            onCanvasContentChanged(canvasData);
            for (let [querySelector, canvasDataURL] of Object.entries(canvasData)) {
                splitMultiPartMeetingContent(canvasDataURL, MAX_MESSAGE_SIZE_BYTES, (multiPartContent: MultiPartMeetingContent) => {
                    let timeoutId: ReturnType<typeof setTimeout> | null = setTimeout(() => {
                        sharedObjects.canvasContentDDS.set(
                            querySelector,
                            multiPartContent
                        );
                        if (timeoutId) {
                            clearTimeout(timeoutId);
                            timeoutId = null;
                        }
                    }, multiPartContent.part * 50);
                });
            }
        }

        canvasData = null; // NOSONAR
    }
}

const checkMeetingContentScrollPositions = (
    presentationViewRef: React.MutableRefObject<HTMLDivElement | null>,
    forceRedraw: boolean,
    meetingContentScrollPositions: MeetingContentScrollPositions,
    sharedObjects: MeetingContainerSchema,
    onScrollPositionsChanged: (scrollPositions: MeetingContentScrollPositions) => void,
) => {
    if (presentationViewRef.current) {
        let hasScrollPositionUpdate = false;
        let scrollPositions: MeetingContentScrollPositions | null = {};

        let scrollableElements: NodeListOf<Element> | null = presentationViewRef.current.querySelectorAll('[data-syncscrollposition]');
        for (let i = 0, l = scrollableElements.length; i < l; i++) {
            const targetElement = scrollableElements[i];
            const querySelector = getPathToPresentationViewElement(targetElement);
            const scrollWidth = getScrollWidth(targetElement);
            const scrollHeight = getScrollHeight(targetElement);
            const scrollState = {
                horizontalScrollPercentage: scrollWidth ? (getScrollLeft(targetElement) / scrollWidth) : 0,
                verticalScrollPercentage: scrollHeight ? (getScrollTop(targetElement) / scrollHeight) : 0,
            };

            const existingScrollState = meetingContentScrollPositions[querySelector];
            if (!existingScrollState
                || existingScrollState.horizontalScrollPercentage !== scrollState.horizontalScrollPercentage
                || existingScrollState.verticalScrollPercentage !== scrollState.verticalScrollPercentage) {
                hasScrollPositionUpdate = true;
            }
            scrollPositions[querySelector] = scrollState;
        }
        scrollableElements = null; // NOSONAR

        if (forceRedraw || hasScrollPositionUpdate) {
            onScrollPositionsChanged(scrollPositions);
            sharedObjects.domContentDDS.set('meetingContentScrollPositions', scrollPositions);
        }

        scrollPositions = null; // NOSONAR
    }
}

// TODO revisit testing strategy
const getPathToPresentationViewElement = (element: Element): string => {
    if (element.className.includes('presentation-view')) {
        return '';
    }
    if (element.id) {
        return '#' + element.id;
    }
    if (element === document.body) {
        return element.tagName;
    }
    if (element.parentElement) {
        let ix = 0;
        const siblings = element.parentElement.children;
        for (let i = 0; i < siblings.length; i++) {
            const sibling = siblings[i];
            if (sibling === element) {
                const parentPath = getPathToPresentationViewElement(element.parentElement);
                return (parentPath ? parentPath + ' ' : '') + element.tagName + ':nth-child(' + (ix + 1) + ')';
            }
            if (sibling.nodeType === 1 && sibling.tagName === element.tagName) {
                ix++;
            }
        }
    }

    return element.tagName;
}

export default ClientProfile;