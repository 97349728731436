import React from 'react';
import AlertBanner from './AlertBanner';

type RequiredFieldsBannerProps = {
    showAlert: boolean,
    itemType?: string,
    message?: string

}

const RequiredFieldsBanner: React.FC<RequiredFieldsBannerProps> = ({ showAlert, itemType, message}) => (
    <div className="marginbottom-md alert-banner-container">
        <AlertBanner
            showAlert={showAlert}
            icon={'warning'}
        >
            <b>Required fields missing. </b>
            <span className="required-field-secondary-text" style={{ fontWeight: 400 }}>
                {message ? message : `All required fields must be entered to save a ${itemType}.` }
            </span>
        </AlertBanner >
    </div>

);

export default RequiredFieldsBanner;