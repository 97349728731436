import {formatCurrency} from "../../../utils/format";
import {TableActionDropdownMenu} from "../../../components/Table/TableActionDropdownMenu";
import {DropdownItem} from "../../../components";
import React from "react";
import {TableCell} from "../../../components/Table/TableCell";
import {HeldAwayAccountSummary} from "../../models/InvestmentProgram";
import classNames from "classnames";
import {DropdownGroup} from "xps-react";
import {useAppSelector} from "../../../store/hooks";
import {selectReleaseToggles} from "../../../ReleaseToggles/releaseTogglesSlice";

type AssetSummaryHeldAwayAccountProps = {
    heldAwayAccount: HeldAwayAccountSummary,
    onClickEdit: (id: string) => void;
    onClickViewHoldings: (id: string) => void;
    renderHeldAwayAccountDetails: (heldAwayAccount: HeldAwayAccountSummary) => React.ReactNode;
    actionsDisabled: boolean;
    gridClassName: string;
    hideActionMenu?: boolean
};
export const HeldAwayAccountRow = ({
                                       heldAwayAccount,
                                       onClickEdit,
                                       onClickViewHoldings,
                                       renderHeldAwayAccountDetails,
                                       actionsDisabled,
                                       gridClassName,
                                       hideActionMenu = false
                                   }: AssetSummaryHeldAwayAccountProps) => {

    const presentValue = heldAwayAccount.holdings.marketValue?.inEstateValue;
    const releaseToggles = useAppSelector(selectReleaseToggles);

    return (<div key={`held-away-row-${heldAwayAccount?.id}`}
                 role="row"
                 className={classNames("assets-grid-table-row", gridClassName)}
                 tabIndex={0}
                 aria-label={heldAwayAccount.financialAccountName}>
        {renderHeldAwayAccountDetails(heldAwayAccount)}
        <TableCell text={formatCurrency(presentValue)} className="textalign-right"/>
        {!hideActionMenu &&
            <TableActionDropdownMenu ariaLabel={`${heldAwayAccount.financialAccountName} Menu`}
                                     disabled={actionsDisabled}>
                <DropdownGroup groupName=" ">
                    <DropdownItem
                        itemText="Edit Held Away Account"
                        value="Edit Held Away Account"
                        onClick={() => onClickEdit(heldAwayAccount?.id)}
                        disabled={!releaseToggles?.enableHeldAwayAccountForAsset}
                    />
                    <DropdownItem
                        itemText="Edit Holdings"
                        value="Edit HA Holdings"
                        onClick={() => onClickViewHoldings(heldAwayAccount?.id)}
                    />
                </DropdownGroup>
            </TableActionDropdownMenu>}
    </div>);
};