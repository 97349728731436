import {NumberInput} from "src/components"
import {ChangeEvent, FocusEvent} from "react";


type YearsInputProps = {
    className?: string,
    name: string,
    value: number | string,
    readOnly: boolean,
    onChangeValue?: (event: ChangeEvent<HTMLInputElement>, value: number | string) => void
    onBlur?: (event: FocusEvent<HTMLInputElement>) => void
    hasError: boolean
}

export const YearsInput = ({
                               className,
                               name,
                               value,
                               readOnly,
                               onChangeValue,
                               onBlur,
                               hasError,
                               ...rest
                           }: YearsInputProps) => {
    return (
        <NumberInput
            className={className}
            name={name}
            value={value}
            readOnly={readOnly}
            inlineLabel="years"
            inlineLabelAlign="right"
            size="medium"
            onChangeValue={onChangeValue}
            onBlur={onBlur}
            error={hasError ? " " : ''}
            {...rest}
        />
    )
}
