import {EditEstateTaxExemptionForm} from "./EditEstateTaxExemptionForm";
import React, {useEffect, useState} from "react";
import {EstateTaxExemptionsReadModel} from "../models/ui";
import {wealthTransferApiClient} from "../WealthTransferApiClient";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {EstateTaxExemptionsWriteModel} from "../models/api";
import {useAppDispatch} from "../../store/hooks";
import {setEstateSummary} from "../EstateSummarySlice";

export function EditTaxExemptionContainer() {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const {id} = useParams<RouteWithId>();
    const {profile} = useProfileAndProposals(id);

    const [taxExemptions, setTaxExemptions] = useState<EstateTaxExemptionsReadModel>();

    const dismissDrawer = () => history.push(`/Profile/${profile.id}/ClientProfile/WealthTransferPlanning`);

    const onSave = async (updatedTaxExemptions: EstateTaxExemptionsWriteModel) => {
        await wealthTransferApiClient.saveExemptions(profile.id, updatedTaxExemptions);
        dismissDrawer();
    };

    const onEvaluate = (updatedTaxExemptions: EstateTaxExemptionsWriteModel) => {
        wealthTransferApiClient.estateSummaryEvaluateUsedTaxExemptions(profile.id, updatedTaxExemptions)
            .then(estateSummary => dispatch(setEstateSummary(estateSummary)))
    };

    const getMaxExemption = async (deceasedYear: number | null) => {
        if (!taxExemptions) {
            return;
        }
        let partnerMaximumAllowedTaxExemption;
        if (deceasedYear) {
            const {maximumAllowedTaxExemption} = await wealthTransferApiClient.getMaxExemptionsForDeceasedDate(profile.id, deceasedYear);
            partnerMaximumAllowedTaxExemption = maximumAllowedTaxExemption;
        } else {
            partnerMaximumAllowedTaxExemption = null;
        }
        setTaxExemptions({
            ...taxExemptions,
            partnerMemberExemption: {
                ...taxExemptions.partnerMemberExemption,
                maximumAllowedTaxExemption: partnerMaximumAllowedTaxExemption,
            }
        });
    }

    useEffect(() => {
        wealthTransferApiClient.getExemptions(profile.id).then(setTaxExemptions)
    }, [profile.id]);

    if (!taxExemptions) {
        return <LoadingIndicator/>
    }

    return <EditEstateTaxExemptionForm
        onCancel={dismissDrawer}
        onSave={onSave}
        onEvaluate={onEvaluate}
        initialFormData={taxExemptions}
        getMaxExemption={getMaxExemption}
    />
}