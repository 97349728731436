import React from "react";
import {NonLifestyleGoalType} from "../models/GoalType";
import {GoalModelType} from "../models/GoalModelType";
import {selectGoalModel} from "../controller/GoalsModelSlice";
import {useAppSelector} from "../../store/hooks";
import DataEntrySummary from "../../components/DataEntry/DataEntrySummary";
import {formatCurrency} from "../../utils/format";
import GoalUtils from "../Summary/GoalUtils";


export default function({nonLifestyleGoal}: {nonLifestyleGoal: NonLifestyleGoalType}){
    const {investorGroup} = useAppSelector<GoalModelType>(selectGoalModel)
    const {yearsUntilFlow, yearsOfFlow} = GoalUtils.getYearsOfFlowAndYearsUntilFlow(nonLifestyleGoal, investorGroup)
    return <div className="non-lifestyle-goal-live-preview">
        <section>
            <DataEntrySummary title="Goal Summary"
                              items={[
                                  {
                                      label: 'Annual Flow',
                                      value: formatCurrency(Number(nonLifestyleGoal.userInputs.annualFlow))
                                  },
                                  {
                                      label: 'Years until Flow',
                                      value: `${yearsUntilFlow} years`
                                  },
                                  {
                                      label: 'Years of Flow',
                                      value: `${yearsOfFlow} years`
                                  },
                                  {
                                      label: 'Present Value',
                                      value: formatCurrency(nonLifestyleGoal.calculatedFields.presentValue)
                                  }
                              ]}/>
        </section>
    </div>
}
