import React, {useEffect, useState} from "react";
import NewWindow from "react-new-window";
import {App_Conf} from "../../../core/app_conf";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectAudienceMembers, selectMeetingDetails, setShowExpandedVideoGallery} from "../meetingSlice";
import {useSelector} from "react-redux";
import {msalUtils} from "../../../MsalUtils";
import {useMsal} from "@azure/msal-react";
import {CallAudioVideoSettings, CallManagers, useCommunicationsContext} from "../CommunicationsContext";
import globalWindowUtils from "../../../utils/globalWindowUtils";
import {LocalVideoStream} from "@azure/communication-calling";

const ExpandedVideoGalleryWindow: React.FC = () => {
    const dispatch = useAppDispatch();
    const [newWindow, setNewWindow] = useState<Window | null>(null);
    const [receivedAudioVideoSettings, setReceivedAudioVideoSettings] = useState<CallAudioVideoSettings | null>(null);
    const meetingDetails = useSelector(selectMeetingDetails)
    const audienceMembers = useAppSelector(selectAudienceMembers);
    const msal = useMsal();
    const {audioVideoSettings, callManagers} = useCommunicationsContext();

    const userId = msalUtils.getAccountUserObjectId(msal);

    useEffect(() => {
        const messageHandler = (event: MessageEvent) => {
            if (event.data && globalWindowUtils.getWindowLocationOrigin() === event.origin) {
                try {
                    const parsedMessageData = JSON.parse(event.data);
                    if (parsedMessageData.eventType === 'audioVideoSettingsChanged') {
                        setReceivedAudioVideoSettings(parsedMessageData.payload);
                        if (callManagers && parsedMessageData.payload) {
                            handleVideoChanged(audioVideoSettings.videoEnabled, parsedMessageData.payload.videoEnabled, callManagers).then();
                        }
                    } else if (parsedMessageData.eventType === 'callConnected' && callManagers?.call) {
                        callManagers.call.muteIncomingAudio().then();
                        if (!callManagers.call.isMuted) {
                            callManagers.call.mute().then();
                        }
                    }
                } catch (e) {
                    return;
                }
            }
        };
        window.addEventListener("message", messageHandler, false);
        return () => {
            window.removeEventListener("message", messageHandler);
        };
    }, [callManagers?.call]);

    useEffect(() => {
        if (meetingDetails && meetingDetails?.acsToken && meetingDetails?.activeMeeting.onlineMeetingJoinUrl && userId) {
            newWindow?.postMessage(JSON.stringify({
                acsToken: meetingDetails?.acsToken,
                userId: userId,
                joinUrl: meetingDetails?.activeMeeting.onlineMeetingJoinUrl,
                audienceMembers,
                audioVideoSettings,
            }));
        }
    }, [
        meetingDetails?.acsToken,
        meetingDetails?.activeMeeting?.onlineMeetingJoinUrl,
        userId,
        newWindow,
        audienceMembers,
    ]);

    return <NewWindow
        url={`${App_Conf.APP_URL}/VideoGallery`}
        onOpen={(window: Window) => {
            window.onload = () => {
                setNewWindow(window);
            };
        }}
        onUnload={() => {
            if (callManagers) {
                callManagers.call?.unmuteIncomingAudio().then(() => {
                    (
                        receivedAudioVideoSettings
                            ? handleAudioChanged(audioVideoSettings.audioEnabled, receivedAudioVideoSettings.audioEnabled, callManagers)
                            : Promise.resolve()
                    ).then(() => {
                        dispatch(setShowExpandedVideoGallery(false));
                    })
                })
            }
            newWindow?.close();
        }}
        features={{width: 900, height: 400}}>
    </NewWindow>
}

const handleVideoChanged = (oldVideoEnabled: boolean, newVideoEnabled: boolean, callManagers: CallManagers): Promise<void> => {
    if (newVideoEnabled !== oldVideoEnabled && callManagers.call) {
        if (newVideoEnabled) {
            const camera = callManagers.statefulCallClient.getState().deviceManager.selectedCamera;
            if (camera) {
                const localVideoStream = new LocalVideoStream(camera);
                return callManagers.call.startVideo(localVideoStream).then();
            } else {
                console.error(`No camera device found on the system`);
            }
        } else {
            return callManagers.call.stopVideo(callManagers.call.localVideoStreams[0]).then();
        }
    }

    return Promise.resolve();
};

const handleAudioChanged = (oldAudioEnabled: boolean, newAudioEnabled: boolean, callManagers: CallManagers): Promise<void> => {
    if (newAudioEnabled !== oldAudioEnabled && callManagers.call) {
        if (newAudioEnabled) {
            return callManagers.call.unmute().then();
        }
        return callManagers.call.mute().then();
    }

    return Promise.resolve();
};

export default ExpandedVideoGalleryWindow;