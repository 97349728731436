import React, {useContext} from 'react';
import {NO_OP, NO_OP_ASYNC} from "../../constants/common";
import {CallClientState, CreateViewResult, StatefulCallClient} from "@azure/communication-react";
import {Call, CallAgent, DeviceAccess} from "@azure/communication-calling";
import {MeetingParticipant} from "./Meeting";

export enum CommunicationsConnectionState {
    CONNECTED,
    DISCONNECTED,
}

export type CallManagers = {
    statefulCallClient: StatefulCallClient,
    callAgent?: CallAgent,
    call?: Call,
}

export type ACSTokenDetails = {
    accessToken: string,
    tokenRefresher: () => Promise<string>,
}

export type ConnectCallProps = {
    acsTokenDetails: ACSTokenDetails,
    userId: string,
    displayName: string,
    joinUrl: string,
    isAudioOn: boolean,
    isVideoOn: boolean
}

export type CallAudioVideoSettings = {
    audioEnabled: boolean;
    videoEnabled: boolean;
};

export type CommunicationsContextValue = {
    callManagers: CallManagers | undefined,
    callClientState: CallClientState | undefined,
    connect: (connectCallProps: ConnectCallProps) => Promise<void>;
    disconnect: () => Promise<void>;
    getDevicePermissions: () => Promise<DeviceAccess>;
    createVideoPreview: () => Promise<CreateViewResult | undefined>;
    disposeVideoPreview: () => void,
    connectionState: CommunicationsConnectionState;
    audioVideoSettings: CallAudioVideoSettings;
}

export type CallContext = {
    acsToken: string;
    userId: string;
    joinUrl: string;
    audienceMembers: MeetingParticipant[];
    audioVideoSettings: CallAudioVideoSettings;
}

const defaultValue: CommunicationsContextValue = {
    callManagers: undefined,
    callClientState: undefined,
    connect: NO_OP_ASYNC,
    disconnect: NO_OP_ASYNC,
    getDevicePermissions: () => Promise.resolve({video: false, audio: false}),
    createVideoPreview: () => Promise.resolve(undefined),
    disposeVideoPreview: NO_OP,
    connectionState: CommunicationsConnectionState.DISCONNECTED,
    audioVideoSettings: {
        audioEnabled: false,
        videoEnabled: false,
    },
};

const communicationsContext = React.createContext(defaultValue);

export const useCommunicationsContext = () => {
    return useContext(communicationsContext);
};

export default communicationsContext;