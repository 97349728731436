import React, {useContext} from 'react';
import {Route, Switch} from "react-router-dom";
import HoldingsSummary from "./Holdings/HoldingsSummary";
import EditSubclasses from "./Holdings/EditSubclasses";
import AddProducts from "./Holdings/AddProducts";
import AssetsViewContext from "../common/AssetsViewContext";

export default function HoldingsRouter() {
    const viewType = useContext(AssetsViewContext);

    return (
        <Switch>
            <Route exact path={`/Profile/:profileId/ClientProfile/${viewType}/StandaloneAccount/:assetId/Holdings`} component={HoldingsSummary} />
            <Route exact path={`/Profile/:profileId/ClientProfile/${viewType}/StandaloneAccount/:assetId/Holdings/EditSubclasses`} component={EditSubclasses} />
            <Route exact path={`/Profile/:profileId/ClientProfile/${viewType}/StandaloneAccount/:assetId/Holdings/AddProducts`} component={AddProducts} />
        </Switch>
    );
}
