import {CSSProperties} from "react";
import {NumberInput} from "src/components";

type YearsInputProps = {
    style: CSSProperties,
    name: string,
    value: string | number,
    onChangeValue: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onBlur: () => void,
    readonly?: boolean
    disabled?: boolean
}

export const YearsInput = ({
    name,
    value,
    onChangeValue,
    onBlur,
    readonly,
    disabled,
    ...rest
}: YearsInputProps) => {
    return (
        <NumberInput 
            name={name}
            value={value}
            onChangeValue={onChangeValue}
            onBlur={onBlur}
            size="medium"
            inlineLabel="years"
            inlineLabelAlign="right"
            readOnly={readonly}
            disabled={disabled}
            {...rest}
        />
    )
}