import {formatCurrency} from "../../../utils/format";
import CustomModal from "../../../components/Modal/Custom/CustomModal";
import React from "react";
import {BankingAccountWriteModel} from "../../models/BankingAccount";
import {HoldingsScrollableContainer} from "../../StandaloneAccount/Holdings/HoldingsScrollableContainer";

type AddBankingAccountModalProps = {
    bankingAccounts: BankingAccountWriteModel[] | undefined,
    onConfirm: (bankingAccounts: BankingAccountWriteModel[]) => void,
    onCancel: () => void
}
const AddBankingAccountModal = ({
                                    bankingAccounts,
                                    onConfirm,
                                    onCancel
                                }: AddBankingAccountModalProps) => {

    if (!bankingAccounts) {
        return null;
    }

    return (
        <CustomModal
            isOpen={Boolean(bankingAccounts)}
            title={
                <h1 className="h3 display-inlineblock">
                    {`Add Northern trust Bank Accounts to this profile?`}
                </h1>
            }
            size={'large'}
            content={
                <div className='banking-account font-default'>
                    {bankingAccounts.length > 0 &&
                        <>
                        <HoldingsScrollableContainer>
                            <div className="add-banking-account-dialog-grid">
                                <span className="fontweight-500">Banking Deposit Accounts</span>
                                <span className="textalign-right fontweight-500">Account Number</span>
                                <span className="textalign-right fontweight-500">Present Value</span>
                            </div>
                            {bankingAccounts.map(bankingAccount => (
                                <div className="add-banking-account-dialog-grid"
                                     key={bankingAccount.accountNumber}>
                                    <span>{bankingAccount.accountName}</span>
                                    <span
                                        className="textalign-right">{bankingAccount.accountNumber}</span>
                                    <span
                                        className="textalign-right">{formatCurrency(bankingAccount.eodNtBalance)}</span>
                                </div>
                            ))}
                        </HoldingsScrollableContainer>
                        </>
                    }
                </div>
            }
            onClickConfirm={()=>onConfirm(bankingAccounts)}
            onClickCancel={onCancel}
            confirmText={'Add Accounts'}
            cancelText={'Cancel'}
            confirmButtonDestructive={false}
        />
    )
}

export default AddBankingAccountModal;