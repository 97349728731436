import React from "react";

interface ChartStatusLineProps {
    id: string;
    label: string;
    width: string;
    height?: string;
    zIndex?: number;
    verticalOffset: string;
    marginLeft?: number,
    lineColor: string;
    textColor: string;
    isAssetReliance: boolean;
}

const ChartStatusLine: React.FC<ChartStatusLineProps> = ({
                                                                  label, width, height = '4px',
                                                                  zIndex = 1, verticalOffset, marginLeft = 310,
                                                                  lineColor, textColor, id, isAssetReliance
                                                              }) => {

    return (
        <div className="chart-status-line-container"
             data-testid={id}
             style={{
                 top: `calc(${verticalOffset}%)`,
                 zIndex: zIndex,
                 marginLeft: marginLeft
             }}
        >

            {isAssetReliance ?
                <span style={{color: textColor, marginLeft: "auto"}}>{label}</span>
                :
                <div className="wpo-label" style={{color: textColor}}>{label}</div>
            }

            <div className="horizontal-rule" style={{
                width: width,
                height: height,
                backgroundColor: lineColor,
            }}/>
        </div>
    )
}

export default ChartStatusLine;