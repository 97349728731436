import {emptyInvestorGroup, InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import {emptyLifestyleGoalForm, LifestyleSpendingGoal, LifestyleSpendingGoalResponse} from "./LifestyleSpendingGoal";
import {emptyProfileResponse, ProfileResponse} from "../../ClientManagement/models/ProfileResponse";
import {DiscountRateType} from "./DiscountRate";
import {AssetTotals, emptyAssetTotals} from "../../Assets/models/Assets";
import {FamilyGoalType} from "./FamilyGoalType";
import {NonLifestyleGoalType} from "./GoalType";

export type TaxLiabilities = {
    totalTaxLiabilities: number;
    estimatedDeferredTaxLiability: number;
    estimatedUnrealizedCapitalGainsLiability: number;
    totalTaxLiabilitiesFundedByPortfolio: number;
    estimatedUnrealizedCapitalGainsLiabilityFundedByPortfolio: number;
    estimatedDeferredTaxLiabilityForAllAccountsFundedByPortfolio: number;
}

export type GoalModelType = {
    investorGroup: InvestorGroupType;
    lifestyleSpendingGoal: LifestyleSpendingGoal;
    familyGoals: { [key: string]: BeneficiaryGoals };
    discretionaryGoals: NonLifestyleGoalType[];
    philanthropicGoals: NonLifestyleGoalType[];
    nonLifestyleGoals: NonLifestyleGoalType[];
    proposal: ProfileResponse;
    discountRateSelection: DiscountRateType;
    assets: AssetTotals;
    approvedProfile: ProfileResponse;
    taxLiabilities: TaxLiabilities;
    nonLifestyleGoalChanged: boolean;
}

export type AllGoalsType = {
    totalGoalsPresentValue: number,
    totalAssetPurchasesPresentValue: number,
    lifeStyle?: LifestyleSpendingGoalResponse;
    nonLifestyle: NonLifestyleGoalType[];
    family: { [key: string]: BeneficiaryGoals };
}

export type BeneficiaryGoals = {
    totalPresentValue: number,
    beneficiaryId: string,
    name: string,
    birthdate: string | null,
    age: number,
    goals: { [key: string]: FamilyGoalType };
}

export type BeneficiaryGoalsResponse = {
    totalPresentValue: number,
    beneficiaryId: string,
    goals: { [key: string]: FamilyGoalType };
}

export const emptyTaxLiabilities: TaxLiabilities = {
    totalTaxLiabilities: 0,
    estimatedDeferredTaxLiability: 0,
    estimatedUnrealizedCapitalGainsLiability: 0,
    totalTaxLiabilitiesFundedByPortfolio: 0,
    estimatedUnrealizedCapitalGainsLiabilityFundedByPortfolio: 0,
    estimatedDeferredTaxLiabilityForAllAccountsFundedByPortfolio: 0
};

export const emptyGoalModel: GoalModelType = {
    lifestyleSpendingGoal: {...emptyLifestyleGoalForm(emptyInvestorGroup.planningPeriod)},
    familyGoals: {},
    discretionaryGoals: [],
    philanthropicGoals: [],
    nonLifestyleGoals: [],
    proposal: {...emptyProfileResponse},
    investorGroup: {...emptyInvestorGroup},
    discountRateSelection: DiscountRateType.TRIPLE_NET,
    assets: {...emptyAssetTotals},
    approvedProfile: {...emptyProfileResponse},
    taxLiabilities: {...emptyTaxLiabilities},
    nonLifestyleGoalChanged: false,
}
