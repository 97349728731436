import React from "react";

export const MeetingBackgroundImage: React.FC = () => {
    return (
        <img className="center-content content-box-shadow height-100p width-100p"
             alt="client meeting background"
             src="/meeting-background.png"/>
    )
}


type MeetingMessageProps = {
    msg: string
}

export function MeetingInterruptMessage(props: MeetingMessageProps) {

    return (
        <div className="meeting-portal-message">
            <img className="background-img" src="/meeting-background-border.png" />
            <div className="meeting-portal-message-whiteboard">
                <img src="/Logo.svg" alt="Northern Trust logo" />
                <hr />
                <p className="meeting-portal-message-text">
                    {props.msg}
                </p>
            </div>
        </div>
    )
}