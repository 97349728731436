import {Badge as XpsBadge} from 'xps-react';

type BadgeProps = {
    badgeContext?: string,
    className?: string,
    type?: 'info' | 'positive' | 'warning' | 'alert' | 'error' | 'grey' | 'white',
    value: number,
}

const Badge = ({type, ...props}: BadgeProps) => {
    return <XpsBadge {...props} type={type} />;
};

export default Badge;