import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import type {RootState} from '../../store/store';
import {Meeting, MeetingParticipant, MeetingStatus, MeetingType, Presenter} from "./Meeting";
import {MeetingControlRequest} from "./Relay/types/MeetingControlRequest";
import {AudienceType} from "./Relay/types/Audience";

export type MeetingModalVisibility = {
    meetingInfo: boolean;
    meetingSchedule: boolean;
};

export const initialMeeting = {
    id: '',
    relayContainerId: '',
    fluidRelayDocumentId: '',
    title: '',
    type: MeetingType.TRAINING_MEETING,
    profileId: '',
    profileIdToPresent: '',
    createdByName: '',
    onlineMeetingId: '',
    onlineMeetingCode: '',
    onlineMeetingJoinUrl: '',
    remoteEnabled: true
}

export type MeetingState = {
    activeMeeting: Meeting;
    showMeetingControls: boolean;
    meetingControlRequest?: MeetingControlRequest;
    audience: AudienceType;
    participatingInMeeting: boolean;
    modalVisibility: MeetingModalVisibility;
    showExpandedVideoGallery: boolean;
    showClientView: boolean;
    acsToken: string;
    scheduledMeetingForm: Meeting;
}

export const initialMeetingState: MeetingState = {
    activeMeeting: {
        id: '',
        relayContainerId: '',
        fluidRelayDocumentId: '',
        title: '',
        type: MeetingType.TRAINING_MEETING,
        profileId: '',
        profileIdToPresent: '',
        createdByName: '',
        onlineMeetingId: '',
        onlineMeetingCode: '',
        onlineMeetingJoinUrl: '',
        remoteEnabled: true
    },
    showMeetingControls: false,
    meetingControlRequest: undefined,
    audience: {
        members: []
    },
    participatingInMeeting: false,
    showExpandedVideoGallery: false,
    showClientView: false,
    modalVisibility: {
        meetingInfo: false,
        meetingSchedule: false,
    },
    acsToken: "",
    scheduledMeetingForm: {
        id: '',
        relayContainerId: '',
        fluidRelayDocumentId: '',
        title: '',
        type: MeetingType.TRAINING_MEETING,
        profileId: '',
        profileIdToPresent: '',
        createdByName: '',
        onlineMeetingId: '',
        onlineMeetingCode: '',
        onlineMeetingJoinUrl: '',
        remoteEnabled: true
    }
}

// The meetingSlice represents the meeting that the spa is currently viewing
export const meetingSlice = createSlice({
    initialState: initialMeetingState,
    name: 'meeting',
    reducers: {
        setActiveMeeting: (state, action: PayloadAction<Meeting>) => {
            state.activeMeeting = action.payload;
        },
        resetMeeting: () => ({...initialMeetingState}),
        setShowMeetingControls: (state, action: PayloadAction<boolean>) => {
            state.showMeetingControls = action.payload;
        },
        setPresenter: (state, action: PayloadAction<Presenter>) => {
            state.activeMeeting = {
                ...state.activeMeeting,
                presenter: action.payload.presenter,
                presenterName: action.payload.presenterName
            };
        },
        setMeetingStatus: (state, action: PayloadAction<MeetingStatus>) => {
            state.activeMeeting = {
                ...state.activeMeeting,
                status: action.payload,
            };
        },
        setMeetingControlRequest: (state, action: PayloadAction<MeetingControlRequest>) => {
            state.meetingControlRequest = action.payload;
        },
        resetMeetingControlRequest: (state) => {
            state.meetingControlRequest = undefined;
        },
        setAudienceMembers: (state, action: PayloadAction<MeetingParticipant[]>) => {
            state.audience = {...state.audience, members: action.payload};
        },
        resetAudience: (state) => {
            state.audience = {...initialMeetingState.audience}
        },
        setParticipatingInMeeting: (state, action: PayloadAction<boolean>) => {
            state.participatingInMeeting = action.payload;
        },
        setShowExpandedVideoGallery: (state, action: PayloadAction<boolean>) => {
            state.showExpandedVideoGallery = action.payload;
        },
        setShowClientView: (state, action: PayloadAction<boolean>) => {
            state.showClientView = action.payload;
        },
        setMeetingModalVisibility: (state, action: PayloadAction<MeetingModalVisibility>) => {
            state.modalVisibility = action.payload;
        },
        persistAcsToken: (state, action: PayloadAction<string>) => {
            state.acsToken = action.payload;
        },
        setScheduledMeetingForm: (state, action: PayloadAction<Meeting>) => {
            state.scheduledMeetingForm = action.payload;
        }
    }
});

export const {
    setActiveMeeting,
    setMeetingStatus,
    resetMeeting,
    setShowMeetingControls,
    setPresenter,
    setMeetingControlRequest,
    resetMeetingControlRequest,
    setAudienceMembers,
    resetAudience,
    setParticipatingInMeeting,
    setShowExpandedVideoGallery,
    setShowClientView,
    setMeetingModalVisibility,
    setScheduledMeetingForm,
    persistAcsToken
} = meetingSlice.actions;

export const selectActiveMeeting = (state: RootState) => state.client.meeting?.activeMeeting;
export const selectShowMeetingControls = (state: RootState) => state.client.meeting?.showMeetingControls;
export const selectMeetingControlRequest = (state: RootState) => state.client.meeting?.meetingControlRequest;
export const selectAudienceMembers = (state: RootState) => state.client.meeting?.audience?.members;
export const selectParticipatingInMeeting = (state: RootState) => state.client.meeting?.participatingInMeeting;
export const selectShowExpandedVideoGallery = (state: RootState) => state.client.meeting?.showExpandedVideoGallery;
export const selectShowClientView = (state: RootState) => state.client.meeting?.showClientView;
export const selectMeetingModalVisibility = (state: RootState) => state.client.meeting?.modalVisibility;
export const selectScheduledMeetingForm = (state: RootState) => state.client.meeting?.scheduledMeetingForm;
export const selectMeetingDetails = (state: RootState) => state.client.meeting;

export default meetingSlice.reducer;
