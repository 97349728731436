import React from 'react';
import {useAppSelector} from "../../store/hooks";
import {selectInEstateAssetTotals} from "../clientAssetsSlice";
import {AssetsView} from "../common/AssetsView";
import {selectFamilyTree} from "../../ClientManagement/FamilyTree/FamilyTreeSlice";
import AssetsViewContext from "../common/AssetsViewContext";

const AssetSummaryContainer = () => {
    const inEstateAssetsTotals = useAppSelector(selectInEstateAssetTotals)!;
    const familyTree = useAppSelector(selectFamilyTree);

    return (
        <AssetsViewContext.Provider value="AssetSummary">
            <AssetsView inEstateTotals={inEstateAssetsTotals}
                        familyTree={familyTree}
            />
        </AssetsViewContext.Provider>
    )
}

export default AssetSummaryContainer;



