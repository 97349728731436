import React, {useEffect, useState} from "react";
import {GeneralInflow, GeneralInflowDetails} from "../models/GeneralInflow";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithAssetId} from "../../routes/types";
import {assetsApiClient} from "../AssetsApiClient";
import {GeneralInflowForm} from "./GeneralInflowForm";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import moment from "moment";
import EditFormContainer from "../EditFormContainer";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {InvestorGroupType, MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import {extractYear, getMemberWithLongestPlanningPeriod, getOwnersAgeRange} from "./InflowTimeFrameCalculator";
import {mapToMemberOwnershipFormData, mapToMemberOwnershipWriteModel} from "../Ownership/mappers";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import {wealthManagementApiClient} from "../../ClientManagement/WealthManagementApiClient";

const EditGeneralInflow: React.FC = () => {
    const history = useHistory();
    const profile = useAppSelector(selectProfile)!;
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const {assetId} = useParams<RouteWithAssetId>();

    const [initialGeneralInflow, updateInitialGeneralInflow] = useState<GeneralInflowDetails>();
    const [generalInflowResponse, updateGeneralInflowResponse] = useState<GeneralInflow>();
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType | null>(null)
    const [memberGroup, setMemberGroup] = useState<MemberGroup>();

    useEffect(() => {
        let componentUnmounted = false;
        assetsApiClient.getGeneralInflow(profile.id, assetId)
            .then(generalInflow => {
                if (componentUnmounted) return;
                updateGeneralInflowResponse(generalInflow);
            });
        return () => {
            componentUnmounted = true;
        }
    }, [assetId, history.location.pathname]);

    useEffect(() => {
        Promise.all([
            clientManagementApiClient.getInvestorGroup(profile.id),
            clientManagementApiClient.getMemberGroup(profile.id)
        ]).then(([investorGroupResponse, memberGroupResponse]) => {
            setInvestorGroup(investorGroupResponse);
            setMemberGroup(memberGroupResponse)
        }).catch(error => console.error('Could not fetch asset details', error.message));
    }, [profile.id]);

    useEffect(() => {
        if (generalInflowResponse && investorGroup) {
            const memberId = generalInflowResponse.memberOwnerships.length > 1
                ? getMemberWithLongestPlanningPeriod(investorGroup).id
                : generalInflowResponse.memberOwnerships[0].member.id;
            const {
                ownersAgeRangeFrom,
                ownersAgeRangeTo
            } = getOwnersAgeRange(investorGroup, memberId, generalInflowResponse.startDate, generalInflowResponse.endDate);

            updateInitialGeneralInflow({
                ...generalInflowResponse,
                taxRate: generalInflowResponse.taxRate.toString(),
                inflowYearsFrom: extractYear(generalInflowResponse.startDate!),
                inflowYearsTo: extractYear(generalInflowResponse.endDate!),
                memberOwnerships: generalInflowResponse.memberOwnerships.map(ownership => mapToMemberOwnershipFormData(ownership)),
                ownersAgeRangeFrom,
                ownersAgeRangeTo
            });
        }
    }, [generalInflowResponse, investorGroup]);

    const checkStartDatePassed = (startDate: string | null) => {
        const today = moment();
        return !!(startDate && today.isSameOrAfter(moment.utc(startDate)));
    };

    const handleSave = async (generalInflow: GeneralInflowDetails) => {
        if (!initialGeneralInflow) {
            return;
        }
        const {
            description,
            type,
            trustInflowType,
            memberOwnerships,
            grossAnnualFlow,
            taxRate,
            netAnnualFlow,
            yearsUntilFlow,
            yearsOfFlow,
            startDate,
            endDate,
            isInflowWillFundLifestyleGoal,
            isHighRisk,
            willAdjustWithInflation,
            inflowReserveLength,
            isDisclosureReviewed
        } = generalInflow;
        await assetsApiClient.putGeneralInflow({
            id: assetId,
            profileId: profile.id,
            description,
            type,
            trustInflowType,
            memberOwnerships: memberOwnerships.map(memberOwner => mapToMemberOwnershipWriteModel(memberOwner)),
            grossAnnualFlow,
            taxRate: parseFloat(taxRate),
            netAnnualFlow,
            yearsUntilFlow,
            yearsOfFlow,
            startDate,
            endDate,
            isInflowWillFundLifestyleGoal,
            isHighRisk,
            willAdjustWithInflation,
            inflowReserveLength,
            isDisclosureReviewed
        })
        history.push(`/Profile/${profile.id}/ClientProfile/AssetSummary`);
    }

    const handleDelete = async () => {
        const response = await assetsApiClient.deleteGeneralInflow(profile.id, assetId);
        const wealthResponse = await wealthManagementApiClient.deleteAssetRelianceStack(profile.id, assetId);
        if (response.status === 200 && wealthResponse) {
            history.push(`/Profile/${profile.id}/ClientProfile/AssetSummary`);
        }
    };

    if (!initialGeneralInflow || !investorGroup || !memberGroup) {
        return <LoadingIndicator/>;
    }

    return (
        <div className="general-inflow">
            <EditFormContainer
                modalTitle="Asset"
                assetType="General Inflow"
                assetDescription={initialGeneralInflow.description}
                handleDelete={handleDelete}
                form={(handleCancel) => (
                    <GeneralInflowForm
                        profileId={profile.id}
                        formatTitle={description => `Edit ${description}`}
                        onSave={handleSave}
                        onCancel={handleCancel}
                        initialGeneralInflow={initialGeneralInflow}
                        investorGroup={investorGroup}
                        hasStartDatePassed={checkStartDatePassed(initialGeneralInflow.startDate)}
                        memberGroup={memberGroup}
                        releaseToggles={releaseToggles!}
                    />
                )}
            />
        </div>
    );

}

export default EditGeneralInflow;
