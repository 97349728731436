import {meetingApiClient} from "../MeetingApiClient";
import {MeetingParticipant, MeetingStatus} from "../Meeting";
import {msalUtils} from "../../../MsalUtils";
import {getIdentifierRawId} from "@azure/communication-common";
import {isRelayContainerNotFoundError} from "../Relay/utils/relayUtils";
import {persistAcsToken, setActiveMeeting, setParticipatingInMeeting, setShowMeetingControls} from "../meetingSlice";
import {useAppDispatch} from "../../../store/hooks";
import {useRelayContext} from "../Relay/types/RelayContext";
import {useMsal} from "@azure/msal-react";
import {useHistory} from "react-router-dom";
import {useCommunicationsContext} from "../CommunicationsContext";
import {ACSTokenProviderClient} from "../ACSTokenProviderClient";

type JoinMeetingOptions = {
    meetingId: string;
    profileId: string;
    onClickEndedMeeting: () => void;
    rejoinCallOptions?: RejoinCallOptions;
};

type JoinCallOptions = {
    onlineMeetingJoinUrl: string;
} & RejoinCallOptions;

type RejoinCallOptions = {
    isAudioEnabled: boolean;
    isVideoEnabled: boolean;
};

type MeetingConnectionUtils = {
    joinMeeting: (options: JoinMeetingOptions) => Promise<void>;
};

const useMeetingConnectionUtils = (): MeetingConnectionUtils => {

    const msal = useMsal();
    const dispatch = useAppDispatch();
    const history = useHistory();

    const relayContext = useRelayContext();
    const communicationsContext = useCommunicationsContext();

    const joinMeeting = ({
                             meetingId,
                             profileId,
                             onClickEndedMeeting,
                             rejoinCallOptions,
                         }: JoinMeetingOptions) => {
        const lanId = msalUtils.getLanId(msal);
        const userName = msalUtils.getAccountName(msal);

        return meetingApiClient.getMeeting(profileId, meetingId)
            .then((activeMeeting) => {
                if (activeMeeting.status === MeetingStatus.ENDED) {
                    onClickEndedMeeting();
                    return;
                }

                dispatch(setActiveMeeting(activeMeeting));

                const userObjectId = msalUtils.getAccountUserObjectId(msal);
                const meetingParticipant: MeetingParticipant = {
                    meetingId: activeMeeting.onlineMeetingId,
                    userId: lanId,
                    userName: userName,
                    communicationsId: activeMeeting.remoteEnabled ? getIdentifierRawId({microsoftTeamsUserId: userObjectId}) : null,
                };
                return meetingApiClient.addParticipant(activeMeeting.profileIdToPresent, meetingParticipant).then(() =>
                    relayContext.connectToMeetingContainer(lanId, userName, activeMeeting.relayContainerId!).catch((connectError) => {
                        if (!isRelayContainerNotFoundError(connectError)) {
                            console.error('Failed to connect to meeting container', connectError);
                            throw connectError;
                        }
                        return relayContext.createMeetingContainer(lanId, userName)
                            .then(({containerId, documentId}) => {
                                return meetingApiClient.updateMeeting({
                                    ...activeMeeting,
                                    relayContainerId: containerId,
                                    fluidRelayDocumentId: documentId
                                }).then((updatedMeeting) => {
                                    dispatch(setActiveMeeting(updatedMeeting));
                                }).catch(error => {
                                    console.error('Could not update meeting', error.message)
                                    throw error;
                                });
                            })
                            .catch((reCreateError) => {
                                console.error('Failed to re-create the meeting container', reCreateError);
                                throw reCreateError;
                            });
                    })
                ).then(() => {
                    if (rejoinCallOptions && activeMeeting.remoteEnabled) {
                        return joinCall({
                            ...rejoinCallOptions,
                            onlineMeetingJoinUrl: activeMeeting.onlineMeetingJoinUrl,
                        });
                    } else {
                        return Promise.resolve();
                    }
                }).then(() => {
                    dispatch(setShowMeetingControls(true));
                    dispatch(setParticipatingInMeeting(true));

                    if (!rejoinCallOptions) {
                        activeMeeting.remoteEnabled
                            ? history.push(`/Profile/${activeMeeting.profileIdToPresent}/Preview`)
                            : history.push(`/Profile/${activeMeeting.profileIdToPresent}/ClientProfile/FamilyTree`);
                    }
                }).catch(() => {
                    if (activeMeeting.remoteEnabled) {
                        communicationsContext.disconnect().then();
                    }
                });
            }).catch(error => {
                console.error('Could not fetch meeting', error.message);
            });
    };

    const tokenRefresher = () => {
        return ACSTokenProviderClient.getACSTeamsUserToken(msal)
            .then(acsToken => acsToken);
    }

    const joinCall = ({
                          onlineMeetingJoinUrl,
                          isAudioEnabled,
                          isVideoEnabled,
                      }: JoinCallOptions) => {
        return ACSTokenProviderClient.getACSTeamsUserToken(msal).then((acsToken) => {
            if (!acsToken) {
                return;
            }
            dispatch(persistAcsToken(acsToken));
            return communicationsContext.connect({
                acsTokenDetails: {
                    accessToken: acsToken,
                    tokenRefresher
                },
                userId: msalUtils.getAccountUserObjectId(msal),
                displayName: msalUtils.getAccountName(msal),
                joinUrl: onlineMeetingJoinUrl,
                isAudioOn: isAudioEnabled,
                isVideoOn: isVideoEnabled,
            })
        }).catch((error) => {
            console.error("Unable to connect to call", error.message);
            communicationsContext.disconnect().then();
        })
    };

    return {
        joinMeeting,
    }
}

export default useMeetingConnectionUtils;