import React, {ReactNode, useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import AccordionContext from './AccordionContext';
import {NO_OP} from "../../constants/common";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {removeAccordionState, selectAccordionStates, setAccordionState} from "./accordionSlice";
import {
    selectAccordionPreferencesStates,
    setAccordionPreferencesState
} from "../../Assets/common/accordionPreferencesSlice";

export type AccordionProps = {
    children: ReactNode;
    className?: string;
    expanded?: string[];
    allowMultipleExpanded?: boolean;
    allowZeroExpanded?: boolean;
    onChange?: (items?: string[]) => void;
    accordionId?: string; // Adding accordionId will enable tracking accordion state in redux
    persistAccordionPreferencesForProfile?: boolean;
}
export default function Accordion({
                                      accordionId,
                                      children,
                                      className,
                                      allowMultipleExpanded = true,
                                      allowZeroExpanded = true,
                                      onChange = NO_OP,
                                      ...props
                                  }: AccordionProps) {
    const dispatch = useAppDispatch();
    const accordionStates = useAppSelector(selectAccordionStates);
    const {accordionPreferencesStates} = useAppSelector(selectAccordionPreferencesStates);

    let accordionPreferencesForProfile: string[] = [];
    if (props.persistAccordionPreferencesForProfile && accordionId && accordionPreferencesStates[accordionId]) {
        accordionPreferencesForProfile = accordionPreferencesStates[accordionId].expandedItems;
    }
    const [expandedItems, setExpandedItems] = useState<string[]>(props.expanded || accordionPreferencesForProfile || []);

    useEffect(() => {
        setExpandedItems(props.expanded || []);

        if (!!props.expanded) {
            updateExpandedItems(props.expanded);
        }
    }, [props.expanded]);

    useEffect(() => {
        if (!accordionId) {
            return;
        }

        if (!accordionStates[accordionId]) {
            dispatch(setAccordionState({
                accordionId,
                state: {
                    expandedItems: expandedItems
                }
            }));
        }
        return () => {
            dispatch(removeAccordionState(accordionId));
        };
    }, []);

    useEffect(() => {
        if (accordionId && accordionStates[accordionId]) {
            setExpandedItems(accordionStates[accordionId].expandedItems);
        }
    }, [accordionStates]);

    const updateExpandedItems = useCallback((items: string[]) => {
        if (!accordionId) {
            setExpandedItems(items);
            return
        }

        dispatch(setAccordionState({
            accordionId: accordionId,
            state: {
                expandedItems: items
            }
        }));

        if (props.persistAccordionPreferencesForProfile) {
            dispatch(setAccordionPreferencesState({
                accordionId: accordionId,
                state: {
                    expandedItems: items
                }
            }));
        }
    }, [accordionId]);

    const toggleItem = (uuid: string) => {
        if (expandedItems.includes(uuid)) {
            if (allowZeroExpanded || expandedItems.length > 1) {
                // collapse
                const items = expandedItems.filter(item => item !== uuid);
                updateExpandedItems(items);
                onChange(items);
            }
        } else {
            // expand
            const items = allowMultipleExpanded ? expandedItems.concat([uuid]) : [uuid];
            updateExpandedItems(items);
            onChange(items);
        }
    };
    return (
        <AccordionContext.Provider value={{expandedItems, toggleItem}}>
            <ul className={classNames('accordion', className)}>{children}</ul>
        </AccordionContext.Provider>
    );
}

