import DataEntrySideDrawer from "../../components/DataEntry/DataEntrySideDrawer";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import {Accordion, AccordionItem, Icon} from "../../components";
import {Checkbox} from "xps-react";
import React, {ChangeEvent, useMemo} from "react";
import {AdvisorDocumentSubMenu} from "../../Resources/models/AdviceLibrary";

type AdvisorDocumentSetupSectionProps = {
    drawerLabel: string;
    onAttach: () => void;
    openAdvisorDocumentDrawer: boolean
    selectedAdvisorDocuments: string[];
    advisorDocumentsSubMenuArray: AdvisorDocumentSubMenu[];
    setSelectedAdvisorDocuments: (state: string[]) => void;
    setShowDrawerForAdvisorDocument: (state: boolean) => void;
};

function AdvisorDocumentSetupSection({
                                            drawerLabel,
                                            onAttach,
                                            openAdvisorDocumentDrawer,
                                            selectedAdvisorDocuments,
                                            advisorDocumentsSubMenuArray,
                                            setSelectedAdvisorDocuments,
                                            setShowDrawerForAdvisorDocument
                                        }: AdvisorDocumentSetupSectionProps) {

    const expandedSections = advisorDocumentsSubMenuArray.map((submenu, index) => `${submenu.subMenuLabel}-${index}`);

    const selectedDocuments: string[] = useMemo(() => {
        return openAdvisorDocumentDrawer ? [...selectedAdvisorDocuments] : []
    }, [openAdvisorDocumentDrawer]);


    const handleToggleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
        let updatedDocumentNames = [...selectedAdvisorDocuments];
        const {checked, name: selectedDocumentName} = e.target;
        if (checked) {
            updatedDocumentNames.push(selectedDocumentName);
        } else {
            updatedDocumentNames = updatedDocumentNames.filter(document => document !== selectedDocumentName);
        }
        setSelectedAdvisorDocuments(updatedDocumentNames);
    }

    const isDocumentChecked = (label: string) => {
        return selectedAdvisorDocuments.includes(label);
    }

    const handleClose = () => {
        setSelectedAdvisorDocuments([...selectedDocuments]);
        setShowDrawerForAdvisorDocument(false);
    }

    if (!openAdvisorDocumentDrawer) {
        return null;
    }

    return <DataEntrySideDrawer isOpen={openAdvisorDocumentDrawer}>
        <DataEntryHeader
            title={drawerLabel}
            primaryButtonText={"Attach"}
            hideSecondaryButton={true}
            onPrimaryButtonClick={onAttach}
            showCloseButton={true}
            onCloseButtonClick={handleClose}
            buttonSize="small"
        />
        <Accordion className="advice-library-accordion" allowMultipleExpanded={true} expanded={expandedSections}
                   accordionId={`adviceLibraryAccordion`}>
            {advisorDocumentsSubMenuArray.map((submenu, index) => {
                const uuid = `${submenu.subMenuLabel}-${index}`;
                return (
                    <AccordionItem
                        uuid={uuid}
                        key={uuid}
                        HeaderComponent={({expanded}) =>
                            <div className="accordion-header-content marginbottom-12">
                                <span className="display-flex align-items-center">
                                  <Icon name={expanded ? 'chevron_down' : 'chevron_right'} size="large"/>
                                  <span className="paddingleft-md">
                                      <div
                                          className="h5 fontweight-normal margin-none">{submenu.subMenuLabel}</div>
                                  </span>
                                </span>
                            </div>}
                    >
                        <ul className="advice-library-accordion__items">
                            {submenu.subMenuItems.map(({documentLabel}, childIndex) => (
                                <li
                                    className="advice-library-accordion__item"
                                    key={`${documentLabel}-${childIndex}`}
                                >
                                    <Checkbox
                                        className="advice-library-accordion__item__checkbox"
                                        aria-label={documentLabel}
                                        value={documentLabel}
                                        checked={isDocumentChecked(documentLabel)}
                                        name={documentLabel}
                                        onChange={handleToggleCheckbox}
                                    />
                                </li>
                            ))}
                        </ul>
                    </AccordionItem>
                );
            })
            }
        </Accordion>
    </DataEntrySideDrawer>;
}

export default AdvisorDocumentSetupSection;