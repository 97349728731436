import React from "react";

const header = "Page Unavailable"
const message = "Please check the address you have entered, or you can navigate elsewhere using the menu above."


const PageUnavailable: React.FC = () => {
    return (
        <div className = "page-container page-unavailable">
            <h3>{header}</h3>
            <span>{message}</span>
        </div>
    )
};

export default PageUnavailable;