import {formatCurrency} from "../../utils/format";
import {Col, Container, Row} from "react-grid-system";

interface InvestableInsufficiencyCardProps {
    totalPortfolioAssetsValue: number,
    portfolioAlignedGoalAndTaxLiabilitiesValue: number
    activePortfolioReserve: boolean
}

export function InvestableInsufficiencyCard({
                                                totalPortfolioAssetsValue,
                                                portfolioAlignedGoalAndTaxLiabilitiesValue,
                                                activePortfolioReserve
                                            }: InvestableInsufficiencyCardProps) {
    const isInvestablySufficient: boolean = (totalPortfolioAssetsValue - portfolioAlignedGoalAndTaxLiabilitiesValue) >= 0;

    let investableSufficiencyText = "Investable Asset Shortfall"

    if(isInvestablySufficient){
        investableSufficiencyText = "Investable Asset Excess"
    }
    else if(activePortfolioReserve){
        investableSufficiencyText = "Reliant on Market Recovery"
    }

    const borderClass = (!isInvestablySufficient) && (activePortfolioReserve) ? 'goal-prioritization-card-shortfall':'goal-prioritization-card'

    return (
        <Container>
            <div className={borderClass} data-testid={"insufficiency-card"}>
                <Row data-testid={"investable-assets"}>
                    <Col xs={8} className='goal-prioritization-card-first-column'>
                        {"Investable Assets"}
                    </Col>
                    <Col xs={4}>
                        {formatCurrency(totalPortfolioAssetsValue)}
                    </Col>
                </Row>
                <Row data-testid={"portfolio-aligned-goals"}>
                    <Col xs={8} className='goal-prioritization-card-first-column'>
                        {"Portfolio Aligned Goals"}
                    </Col>
                    <Col xs={4}>
                        {formatCurrency(portfolioAlignedGoalAndTaxLiabilitiesValue)}
                    </Col>
                </Row>
                <Row data-testid={"investable-sufficiency"}>
                    <Col xs={8} className='goal-prioritization-card-first-column'>
                        {investableSufficiencyText}
                    </Col>
                    <Col xs={4}>
                        {formatCurrency(Math.abs(totalPortfolioAssetsValue - portfolioAlignedGoalAndTaxLiabilitiesValue))}
                    </Col>
                </Row>
            </div>
        </Container>
    )
}
