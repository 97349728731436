import { get } from "src/core/api";
import { GoalFundingResponse } from "./models/Funding";

const extractBody = async (res: Response) => {
    return await res.json();
};

const getGoalFundingOverTime = async (profileId: string, abortSignal?: AbortSignal): Promise<GoalFundingResponse> => {
    return get(`/goal/goalFunding/${profileId}`, {
        signal: abortSignal
    }).then(extractBody);
}

export const fundingApiClient = {
    getGoalFundingOverTime
}