import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store/store";
import {emptyProfileSettings, ProfileSettings} from "../models/ProfileResponse";
import {emptyEffectiveTaxRate} from "../models/EffectiveTaxRateType";

export const getEmptyProfileSettingsForm = (): ProfileSettings => ({
    ...emptyProfileSettings,
    effectiveTaxRate: {...emptyEffectiveTaxRate}
});

export interface ProfileSettingsState {
    profileSettingsForm: ProfileSettings
}

const initialState: ProfileSettingsState = {
    profileSettingsForm: getEmptyProfileSettingsForm(),
}

export const profileSettingsSlice = createSlice({
    name: 'profileSettings',
    initialState,
    reducers: {
        setProfileSettingsForm: (state, action: PayloadAction<ProfileSettings>) => {
            state.profileSettingsForm = action.payload;
        },
    },
});

export const {
    setProfileSettingsForm
} = profileSettingsSlice.actions;

export const selectProfileSettingsForm = (state: RootState) => state.client.profileSettings.profileSettingsForm;

export default profileSettingsSlice.reducer;