import {MultiPartMeetingContent} from "./Meeting";
import {v4 as generate_uuid} from "uuid";

export const splitMultiPartMeetingContent = (
    content: string,
    maxMessageBytes: number,
    multiPartMessageCallback: (multiPartContent: MultiPartMeetingContent) => void,
) => {
    const messageId = generate_uuid();
    const messageBytes = getStringBytes(content);
    const numChunks = Math.ceil(messageBytes / maxMessageBytes);
    for (let i = 0; i < numChunks; i++) {
        const multiPartContent: MultiPartMeetingContent = {
            part: i,
            totalParts: numChunks,
            content: content.slice(
                i * maxMessageBytes,
                Math.min((i + 1) * maxMessageBytes, content.length)
            ),
            messageId: messageId,
        };
        multiPartMessageCallback(multiPartContent)
    }
};

export const getStringBytes = (input: string) => (new TextEncoder().encode(input)).length;
