import React from "react";
import {Donut} from "xps-react";
import {COLOR_RISK_ASSETS, COLOR_RISK_CONTROL_ASSETS} from "../../constants/colors";
import {SimplifiedProposedAllocationResponse} from "./ProposedAllocationResponse";
import {SimplifiedCurrentAllocationResponse} from "./CurrentAllocationResponse";

export interface RiskDonutProps {
    data: SimplifiedProposedAllocationResponse | SimplifiedCurrentAllocationResponse,
    donutSize: 'xsm' | 'sm' | 'md' | 'lg' | 'xl',
    testId?: string;
}

const riskColors: [string, string] = [COLOR_RISK_ASSETS, COLOR_RISK_CONTROL_ASSETS]

const RiskDonut: React.FC<RiskDonutProps> = ({data, donutSize, testId}) => {
    return (
        <div className={`donut-container donut-container__${donutSize}`}>
            <Donut
                className="donut"
                data={[{value: data.totalRiskAssetsPercent}, {value: data.totalRiskControlAssetsPercent}]}
                colors={riskColors}
                outerRadius={48}
                data-testid={testId}
            />
        </div>
    )
}

export default RiskDonut;
