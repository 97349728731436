import Popover from "@nt/dds-react/core/Popover";
import React from "react";
import {Icon} from "../../components";

export function TaxLiabilityPopover() {
    return <>
        <span data-testid="tax-liability-info-popover">
        <Popover
            id="taxliability-popover-id"
            className="marginleft-xxxl"
            delay={0}
            content={
                <div className="tax-liability-popover-content">
                    <div>Estimated tax liabilities are not reflected in the proposed </div>
                    <div>asset allocation for future years.</div>
                </div>
            }
            direction="bottom"
            aria-label="taxliability"
        >
            <Icon className="info-icon popover-trigger popoverContainer" ariaHidden={false}
                  name="status_info_outline" type="info" ariaLabel="tax liabilities data"/>
        </Popover>
        </span>
    </>
}
