import React, {FC, ReactNode} from "react";
import ModalWrapper from "../ModalWrapper/ModalWrapper";

export type CustomModalProps = {
    isOpen: boolean,
    title: string | ReactNode,
    content: string | ReactNode,
    onClickCancel: () => void,
    onClickConfirm: () => void,
    cancelText: string,
    confirmText: string,
    confirmButtonDestructive?: boolean,
    size?: string
}

const CustomModal: FC<CustomModalProps> = ({
                                               isOpen = false,
                                               title,
                                               content,
                                               onClickCancel,
                                               onClickConfirm,
                                               cancelText,
                                               confirmText,
                                               confirmButtonDestructive= true,
                                               size='small'
                                           }) => (
    <ModalWrapper
        id="discard-changes-modal"
        isOpen={isOpen}
        headerText={title}
        size={size}
        buttons={[
            {
                text: `${cancelText}`,
                onClick: onClickCancel,
            },
            {
                text: `${confirmText}`,
                onClick: onClickConfirm,
                destructive: confirmButtonDestructive,
                primary: true,
            }
        ]}
    >
        <div className="font-md">{content}</div>
    </ModalWrapper>
);

export default CustomModal;
