import React from "react";
import AssetReliancePill from "./AssetReliancePill";
import {COLOR_ASSET_SHORTFALL_ACCENT, COLOR_EXCESS_ASSETS_ACCENT} from "../../constants/colors";

interface AssetRelianceAssetLineProps {
    topPercentage: string,
    bottomPercentage: string;
    color: string;
    excessAssets: number;
}

const ExcessAssetsVerticalLine: React.FC<AssetRelianceAssetLineProps> = ({color, bottomPercentage, topPercentage, excessAssets}) => {
    return (
            <div
                data-testid={"asset-reliance-asset-line-container-test"}
                className={"asset-reliance-asset-line-container"}
                style={{
                    color: color,
                    top: `${topPercentage}%`,
                    bottom: `${bottomPercentage}%`,
                    position: `absolute`,
                    background: (excessAssets >= 0) ? COLOR_EXCESS_ASSETS_ACCENT : COLOR_ASSET_SHORTFALL_ACCENT
                }}
            >
            <AssetReliancePill color={color}
                               excessValue={excessAssets}/></div>
    )
}

export default ExcessAssetsVerticalLine;