import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useRef } from "react";

type EditorProps = {
    value: string;
    onChange: (value: string) => void;
    maxLength?: number;
    placeholder?: string;
};

const Editor: React.FC<EditorProps> = ({
    value,
    onChange,
    placeholder = "",
    maxLength = 255,
}: EditorProps) => {
    const reactQuillRef = useRef<ReactQuill>(null);

    const checkCharacterCount = (event: KeyboardEvent) => {
        if(reactQuillRef?.current?.unprivilegedEditor) {
            const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
            if (unprivilegedEditor.getLength() > maxLength && event.key !== "Backspace")
                event.preventDefault();
        }
    };

    return (
        <div className="rich-text-editor">
            <ReactQuill
                theme="snow"
                value={value}
                onChange={onChange}
                onKeyDown={checkCharacterCount}
                placeholder={placeholder}
                ref={reactQuillRef}
                modules={{
                    toolbar: [
                        ["bold", "italic", "underline"],
                        [{ list: "ordered" }, { list: "bullet" }],
                    ],
                }}
            />
        </div>
    );
};

export default Editor;
