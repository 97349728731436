import {Icon, Label, Name, RadioGroup, Tab, TabBar, TabPanel, TabsProvider} from "../../components";
import React, {ChangeEvent, FC, useEffect, useReducer, useState} from "react";
import {Dropdown, DropdownItem, Loader} from "xps-react";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {meetingApiClient} from "./MeetingApiClient";
import {MeetingHistorySummary} from "./MeetingHistorySummary";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    initialMeetingState,
    resetMeeting,
    selectActiveMeeting,
    selectMeetingModalVisibility,
    selectParticipatingInMeeting,
    setActiveMeeting,
    setMeetingModalVisibility,
    setParticipatingInMeeting,
    setShowMeetingControls
} from "./meetingSlice";
import {useMsal} from "@azure/msal-react";
import {msalUtils} from "../../MsalUtils";
import {useStopwatch} from "react-timer-hook";
import moment from "moment";
import {useRelayContext} from "./Relay/types/RelayContext";
import useMeetingHistory from "./useMeetingHistory";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {
    HumanReadableMeetingType,
    mapHumanReadableMeetingTypeToEnum,
    mapMeetingTypeEnumToHumanReadable,
    Meeting,
    MeetingParticipant,
    MeetingParticipation,
    MeetingStatus,
    MeetingType
} from "./Meeting";
import ModalWrapper from "../../components/Modal/ModalWrapper/ModalWrapper";
import {agendaApiClient} from "../../Agenda/AgendaApiClient";
import {createOnlineMeeting, initializeGraphClient} from "./GraphClient";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import {useAppInsights} from "src/AppInsights";
import {useCommunicationsContext} from "./CommunicationsContext";
import useMeetingUtils from "./useMeetingUtils";
import MeetingScheduleSummary from "./MeetingScheduleSummary";
import {getIdentifierRawId} from "@azure/communication-common";
import useMeetingConnectionUtils from "./utils/useMeetingConnectionUtils";

export const MeetingActions = () => {
    const [isNewMeetingModalOpen, setIsNewMeetingModalOpen] = useState<boolean>(false);
    const [isEndedMeetingModalOpen, setIsEndedMeetingModalOpen] = useState<boolean>(false);

    const meeting = useAppSelector(selectActiveMeeting);
    const releaseToggles = useAppSelector(selectReleaseToggles);

    const {meetings, isHistoryLoading} = useMeetingHistory();
    const isActiveMeeting = !!meeting?.id;

    const meetingButton = isActiveMeeting
        ? <MeetingJoinButton onClickEndedMeeting={() => {
            setIsEndedMeetingModalOpen(true);
        }}/>
        : <MeetingStartButton onClick={() => {
            setIsNewMeetingModalOpen(true);
        }}/>;

    const meetingActionsTabArray = releaseToggles?.enableMeetingManagementScheduler
        ? ['MEETING SCHEDULER', 'MEETING HISTORY']
        : ['MEETING HISTORY'];

    const meetingTabs = (tabName: string) => {
        return <Tab className={releaseToggles?.enableMeetingManagementScheduler ? "" : "meeting-history-tab"} name={tabName} key={tabName}/>
    }

    const meetingActionsTabPanel = releaseToggles?.enableMeetingManagementScheduler
        ?
            <TabPanel tabIndex={0}>
                <MeetingScheduleSummary/>
                <MeetingHistorySummary meetings={meetings} isHistoryLoading={isHistoryLoading}/>
            </TabPanel>
        :
            <TabPanel tabIndex={0}>
                <MeetingHistorySummary meetings={meetings} isHistoryLoading={isHistoryLoading}/>
            </TabPanel>

    return (
        <div className="meeting-actions">
            {releaseToggles?.enableMeetingManagement && <Label label="Meeting Actions"/>}
            {releaseToggles?.enableMeetingManagement && (isHistoryLoading ? <Loader size="md"/> : meetingButton)}
            <NewMeetingModal isOpen={isNewMeetingModalOpen} handleCancel={() => {
                setIsNewMeetingModalOpen(false);
            }}/>
            <MeetingEndModal isOpen={isEndedMeetingModalOpen} handleClose={() => {
                setIsEndedMeetingModalOpen(false);
            }}/>
            <div className="meeting-history-section" style={{
                marginTop: releaseToggles?.enableMeetingManagement ? undefined : 0
            }}>
                <TabsProvider tabIdArray={meetingActionsTabArray}>
                    <TabBar size="medium">
                        {meetingActionsTabArray.map(meetingTabs)}
                    </TabBar>
                    {meetingActionsTabPanel}
                </TabsProvider>
            </div>
        </div>
    );
};

enum MeetingActionButtonType {
    START = 'START',
    JOIN = 'JOIN'
}

type MeetingActionButtonProps = {
    type: MeetingActionButtonType;
    label: string;
    description?: string;
    onClick: () => void;
}

type MeetingActionStartProps = {
    onClick: () => void;
}

type MeetingActionJoinProps = {
    onClickEndedMeeting: () => void;
}

type EndMeetingModalProps = {
    isOpen: boolean;
    handleClose: () => void;
};

const MeetingStartButton: React.FC<MeetingActionStartProps> = ({onClick}) => {

    return <MeetingActionButton type={MeetingActionButtonType.START}
                                label='Start a Meeting'
                                onClick={onClick}/>;
};
/*
* currentTime
* StartDate
* The time that meeting has been so far = currentTime - StartDate;
* offsetTimestamp: currentTime
* */
const calculateMeetingTime = (startDate: string | undefined) => {
    const currentTime = moment(Date.now());
    const startTime = startDate ? moment(new Date(startDate)) : currentTime;
    return currentTime.add(moment.duration(currentTime.diff(startTime))).toDate();
}

const pad = (time: number) => {
    return time < 10 ? `0${time}` : `${time}`;
}

const MeetingEndModal: React.FC<EndMeetingModalProps> = ({isOpen = false, handleClose}) => {
    return (
        <ModalWrapper
            id="discard-changes-modal"
            isOpen={isOpen}
            headerText={"This meeting has ended"}
            buttons={[
                {
                    text: 'OKAY',
                    onClick: handleClose,
                    primary: true,
                    destructive: true
                }
            ]}
            showCloseButton={true}
            onRequestClose={handleClose}
        >
            <div className="font-md">
                The meeting you are trying to join has been ended by a presenter or participant.
            </div>
        </ModalWrapper>
    );
}


const MeetingJoinButton: React.FC<MeetingActionJoinProps> = ({onClickEndedMeeting}) => {
    const meeting = useAppSelector(selectActiveMeeting)!;
    const {
        seconds,
        minutes,
        hours
    } = useStopwatch({autoStart: true, offsetTimestamp: calculateMeetingTime(meeting.startDate)});
    const timeInProgressLabel = `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    const presenterName = meeting.presenterName;
    const presenterId = meeting.presenter;

    const {joinMeeting} = useMeetingConnectionUtils();

    return <MeetingActionButton type={MeetingActionButtonType.JOIN}
                                label={`Join Meeting in Progress (${timeInProgressLabel})`}
                                description={`${presenterName} (${presenterId}) is the current presenter`}
                                onClick={() => {
                                    joinMeeting({
                                        meetingId: meeting.id,
                                        profileId: meeting.profileId,
                                        onClickEndedMeeting: onClickEndedMeeting,
                                    }).then()
                                }}/>;
};

const MeetingActionButton: React.FC<MeetingActionButtonProps> = ({type, label, description, onClick}) => {
    return <div className={`meeting-action meeting-action__${type.valueOf().toLowerCase()}`} tabIndex={0}
                onClick={onClick}>
        <div className="meeting-action__container">
            <span className="meeting-action__label">{label}</span>
            {description && <div className="meeting-action__description">{description}</div>}
        </div>
        <Icon name="arrow_forward"/>
    </div>;
}

type NewMeetingModalProps = {
    isOpen: boolean;
    handleCancel: () => void,
};

type NewMeetingModalForm = {
    data: NewMeetingModalFormData;
    dirtyFields: Partial<Record<keyof NewMeetingModalFormData, boolean>>;
    errorFields: Partial<Record<keyof NewMeetingModalFormData, boolean>>;
    reValidate: boolean;
};

type NewMeetingModalFormData = {
    meetingTitle: string;
    idToPresent: string;
    meetingType: MeetingType | undefined;
};

const NewMeetingModal: FC<NewMeetingModalProps> = ({isOpen = false, handleCancel}) => {
    const {id} = useParams<RouteWithId>();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const msal = useMsal();
    const relayContext = useRelayContext();
    const communicationsContext = useCommunicationsContext();
    const releaseToggles = useAppSelector(selectReleaseToggles);

    const {proposals, approvedProfile} = useProfileAndProposals(id);
    const meetingModalVisibility = useAppSelector(selectMeetingModalVisibility)!;

    const [isLoadingMeeting, setLoadingMeeting] = useState(false);
    const [startMeetingDisabled, setStartMeetingDisabled] = useState<boolean>(false);
    const [meetingTitle, setMeetingTitle] = useState<string>('');

    const defaultForm: NewMeetingModalForm = {
        data: {
            meetingTitle: meetingTitle ? meetingTitle : '',
            idToPresent: '',
            meetingType: undefined,
        },
        dirtyFields: {},
        errorFields: {},
        reValidate: false,
    };

    const [form, dispatchFormAction] = useReducer(newMeetingModalFormReducerFn, {...defaultForm});

    const validateForm = (formToValidate: NewMeetingModalForm, requireValidationForAllFields: boolean = false) => {
        const isMeetingTitleInvalid = !formToValidate.data.meetingTitle?.trim();
        const isMeetingTypeInvalid = !formToValidate.data.meetingType;
        const isDataToPresentInvalid = !formToValidate.data.idToPresent
        const errorFields = {
            meetingTitle: isMeetingTitleInvalid
                && (formToValidate.dirtyFields.meetingTitle || requireValidationForAllFields),
            meetingType: isMeetingTypeInvalid
                && (formToValidate.dirtyFields.meetingType || requireValidationForAllFields),
            idToPresent: isDataToPresentInvalid
                && (formToValidate.dirtyFields.idToPresent || requireValidationForAllFields)
        };
        dispatchFormAction({
            type: 'updateErrorFields',
            errorFields: errorFields,
        });
        return Object.values(errorFields).every((hasError) => !hasError);
    };

    const resetFormValidations = () => {
        dispatchFormAction({
            type: 'reInitialize',
            form: {
                ...defaultForm,
                data: form.data,
            }
        });
    }

    useEffect(() => {
        if(approvedProfile.id) {
            agendaApiClient.getAgenda(approvedProfile.id).then(
                agenda => {
                    dispatchFormAction({
                        type: 'update',
                        data:
                            {
                                meetingTitle: agenda.meetingTitle
                            }
                    });
                    setMeetingTitle(agenda.meetingTitle);
                }
            )
        }
    }, [approvedProfile.id]);

    useEffect(() => {
        if (form.reValidate) {
            validateForm(form);
        }
    }, [form.dirtyFields, form.reValidate]);

    useEffect(() => {
        resetFormValidations();
    }, [isOpen]);

    const appInsights = useAppInsights();
    const participatingInMeeting = useAppSelector(selectParticipatingInMeeting)!;
    const triggerCreateMeetingEvent = () => {
        if (form.data.meetingType === MeetingType.CLIENT_MEETING) {
            appInsights.trackEvent({
                name: 'CreateMeeting',
                properties: {
                    action: 'Create meeting button click',
                    meetingStatus: participatingInMeeting ? MeetingParticipation.IN_MEETING : MeetingParticipation.OUT_OF_MEETING,
                    data: 'Create meeting button clicked'
                }
            })
        }
    }

    const handleStartMeeting = () => {
        if (startMeetingDisabled) {
            return;
        }

        setStartMeetingDisabled(true);
        dispatchFormAction({type: 'markAllFieldsAsDirty'});
        if (validateForm(form, true)) {
            setLoadingMeeting(true);

            const profileIdToPresent = form.data.idToPresent;
            const lanId = msalUtils.getLanId(msal);
            const userName = msalUtils.getAccountName(msal);
            const userObjectId = msalUtils.getAccountUserObjectId(msal);

            createOnlineMeeting(initializeGraphClient(msal)).then((onlineMeeting) => {
                const meetingParticipant: MeetingParticipant = {
                    meetingId: onlineMeeting.onlineMeetingId,
                    userId: lanId,
                    userName: userName,
                    communicationsId: releaseToggles?.enableMeetingManagementRemote
                        ? getIdentifierRawId({microsoftTeamsUserId: userObjectId})
                        : null,
                };
                return Promise.all([
                    Promise.resolve(onlineMeeting),
                    meetingApiClient.addParticipant(profileIdToPresent, meetingParticipant).then(() => {
                        dispatch(setActiveMeeting({
                            ...initialMeetingState.activeMeeting,
                            onlineMeetingId: onlineMeeting.onlineMeetingId,
                            profileIdToPresent,
                        }))
                    })
                ]);
            }).then(([onlineMeeting]) => {
                return Promise.all([
                    relayContext.createMeetingContainer(lanId, userName),
                    Promise.resolve(onlineMeeting)
                ]);
            }).then(async ([{containerId, documentId}, onlineMeeting]) => {
                return meetingApiClient.createMeeting({
                    id: containerId,
                    fluidRelayDocumentId: documentId,
                    relayContainerId: containerId,
                    title: form.data.meetingTitle.trim(),
                    type: form.data.meetingType!,
                    profileId: id,
                    profileIdToPresent: profileIdToPresent,
                    createdBy: lanId,
                    createdByName: userName,
                    remoteEnabled: releaseToggles?.enableMeetingManagementRemote ?? false,
                    ...onlineMeeting
                })
            }).then((meeting) => {
                dispatch(setActiveMeeting(meeting));
                dispatch(setParticipatingInMeeting(true));
                dispatch(setShowMeetingControls(true));

                setLoadingMeeting(false);
                triggerCreateMeetingEvent();

                if (releaseToggles?.enableMeetingManagementRemote) {
                    history.push(`/Profile/${profileIdToPresent}/Preview`)
                } else {
                    dispatch(setMeetingModalVisibility({
                        ...meetingModalVisibility,
                        meetingInfo: true,
                    }));
                    history.push(`/Profile/${profileIdToPresent}/ClientProfile/FamilyTree`)
                }
            }).catch(error => {
                console.error('Failed to start meeting:', error.message);
                if (releaseToggles?.enableMeetingManagementRemote) {
                    communicationsContext.disconnect().then();
                }
            });
        } else {
            setStartMeetingDisabled(false);
        }
    };

    const meetingTitleSection = (<div className="layout-data-entry-form__field">
        <Name name={form.data.meetingTitle}
              label={"Meeting Title"}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  dispatchFormAction({type: 'update', data: {meetingTitle: e.target.value}});
              }}
              required={true}
              maxLength={255}
              hasInteractions={form.dirtyFields.meetingTitle}
              whenUserHasInteracted={() => {
                  dispatchFormAction({type: 'update', data: {meetingTitle: form.data.meetingTitle}});
              }}/>
        <p className="font-default color-text--error font-weight-400 inlineInputError meeting-modal-inline-error">
            <span>{form.errorFields.meetingTitle ? 'Meeting title is required' : ''}</span>
        </p>
    </div>);

    const dataToPresentSection = (<div className="layout-data-entry-form__field">
        <Label label="Data to Present"
               htmlFor="data-to-present-DropdownField"
               labelId="data-to-present-DropdownField-Label"
               required={true}
        />
        <Dropdown
            id="data-to-present"
            size="medium"
            value={form.data.idToPresent}
            dropdownKind="select"
            onChange={({value: idToPresent}: { value: string }) => {
                dispatchFormAction({type: 'update', data: {idToPresent}});
            }}
        >
            <DropdownItem className={'fontweight-500'} key={approvedProfile.id} value={approvedProfile.id}
                          text={approvedProfile.displayName}
                          itemText={approvedProfile.displayName}
            />
            {proposals.map(proposal => <DropdownItem key={proposal.id} value={proposal.id}
                                                     text={proposal.displayName}
                                                     itemText={proposal.displayName}
                                                     style={{paddingLeft: '35px'}}
            />)}
        </Dropdown>
        <p className="font-default color-text--error font-weight-400 inlineInputError meeting-modal-inline-error meeting-modal-inline-error-padding">
            <span>{form.errorFields.idToPresent ? 'Data to present is required' : ''}</span>
        </p>
    </div>);

    const meetingTypeSection = (<div className="layout-data-entry-form__field" style={{
        marginBottom: -16,
    }}>
        <RadioGroup
            id="meetingType"
            name="meetingType"
            label="Meeting Type "
            layout="vertical"
            values={
                [MeetingType.TRAINING_MEETING, MeetingType.CLIENT_MEETING]
                    .map(meetingType => mapMeetingTypeEnumToHumanReadable(meetingType))
            }
            required={true}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                dispatchFormAction({
                    type: 'update',
                    data: {
                        meetingType: mapHumanReadableMeetingTypeToEnum(e.target.value as HumanReadableMeetingType)
                    }
                });
            }}
        />
        <p className="font-default color-text--error font-weight-400 inlineInputError meeting-modal-inline-error">
            <span>{form.errorFields.meetingType ? 'Meeting type is required' : ''}</span>
        </p>
    </div>);

    const meetingModalWrapperHeader = (
        <React.Fragment>
            <span className={"meeting-modal-header"}>{"Start a new meeting?"}</span>
            <p className="font-default color-text--error noMargin">{"* Required Fields"}</p>
        </React.Fragment>
    );

    return <>
        {
            <React.Fragment>
                <ModalWrapper
                    id="new-meeting-modal"
                    isOpen={isOpen}
                    headerText={!isLoadingMeeting ? meetingModalWrapperHeader : ""}
                    buttons={isLoadingMeeting ? [] : [
                        {
                            text: 'Cancel',
                            onClick: () => {
                                handleCancel();
                                dispatchFormAction({
                                    type: 'reInitialize',
                                    form: defaultForm,
                                });
                            }
                        },
                        {
                            text: 'Start Meeting',
                            onClick: handleStartMeeting,
                            primary: true,
                        }
                    ]}
                >
                    {isLoadingMeeting &&
                        <LoadingIndicator displayMessage={"Your meeting is loading..."}/>
                    }
                    {!isLoadingMeeting && <>
                        <div className="font-md">Open this proposal for a meeting with the Client View and meeting
                            controls
                            enabled.
                            Client Meetings will be tracked and saved in the Meeting History and Profile Updates along
                            with
                            any
                            exported reports.
                        </div>
                        {meetingTitleSection}
                        {dataToPresentSection}
                        {meetingTypeSection}
                    </>
                    }
                </ModalWrapper>
            </React.Fragment>
        }
    </>;
}

type ReInitializeFormAction = {
    type: 'reInitialize';
    form: NewMeetingModalForm;
};
type UpdateFormDataAction = {
    type: 'update';
    data: Partial<NewMeetingModalFormData>;
};
type UpdateFormErrorFieldsAction = {
    type: 'updateErrorFields';
    errorFields: Partial<Record<keyof NewMeetingModalFormData, boolean>>;
};
type MarkAllFormFieldsDirtyAction = {
    type: 'markAllFieldsAsDirty';
};

const reduceFormDataToDirtyFields = (formData: Partial<NewMeetingModalFormData>) => {
    return Object.keys(formData).reduce((dirtyFields, field) => ({
        ...dirtyFields,
        [field]: true
    }), {});
}

const newMeetingModalFormReducerFn = (
    state: NewMeetingModalForm,
    action: ReInitializeFormAction | UpdateFormDataAction | UpdateFormErrorFieldsAction | MarkAllFormFieldsDirtyAction
) => {
    if (action.type === 'update') {
        return {
            data: {
                ...state.data,
                ...action.data,
            },
            dirtyFields: {
                ...state.dirtyFields,
                ...(reduceFormDataToDirtyFields(action.data)),
            },
            errorFields: state.errorFields,
            reValidate: true,
        };
    }
    if (action.type === 'updateErrorFields') {
        return {
            ...state,
            errorFields: {
                ...state.errorFields,
                ...action.errorFields,
            }
        }
    }
    if (action.type === 'markAllFieldsAsDirty') {
        return {
            data: state.data,
            dirtyFields: {
                ...state.dirtyFields,
                ...(reduceFormDataToDirtyFields(state.data)),
            },
            errorFields: state.errorFields,
            reValidate: true,
        };
    }
    return {
        data: {...action.form.data},
        dirtyFields: {...action.form.dirtyFields},
        errorFields: {...action.form.errorFields},
        reValidate: action.form.reValidate,
    };
};


type ConfirmExitMeetingModalProps = {
    isOpen: boolean;
    handleCancel: () => void;
    path?: string;
    handleExitMeeting?: () => void;
}

export const ConfirmExitMeetingModal: FC<ConfirmExitMeetingModalProps> = ({
                                                                              isOpen,
                                                                              handleCancel,
                                                                              path,
                                                                              handleExitMeeting
                                                                          }) => {
    const history = useHistory();
    const activeMeeting = useAppSelector(selectActiveMeeting) as Meeting;

    const dispatch = useAppDispatch();
    const {isCurrentUserPresenting} = useMeetingUtils();

    const handleAction = () => {
        if (endMeeting) {
            //End Meeting
            const updatedMeeting = {...activeMeeting, status: MeetingStatus.ENDED};
            meetingApiClient.updateMeeting(updatedMeeting)
                .then(() => {
                    dispatch(setActiveMeeting(updatedMeeting));
                    dispatch(resetMeeting());
                }).catch(error => {
                console.error('Could not update meeting', error.message)
            }).finally(() => history.push({pathname: path}));
        } else {
            history.push({pathname: path})
        }
    }

    let title: string = 'You are about to leave the meeting';
    let footer: string = 'Are you sure you want to leave this meeting? You can rejoin later if the meeting is still in progress';
    let endMeeting: boolean = false;

    if (isCurrentUserPresenting) {
        title = 'Presenters are unable to navigate outside of an active meeting';
        footer = 'If you wish to navigate back to the partner dashboard page, you must first end your active meeting.';
        endMeeting = true;
    }
    return <>
        {<React.Fragment>
            <ModalWrapper
                id="confirm-exit-modal"
                isOpen={isOpen}
                headerText={title}
                buttons={[
                    {
                        datatestid: "leave",
                        text: isCurrentUserPresenting ? "END MEETING" : "CONFIRM",
                        onClick: handleExitMeeting ? handleExitMeeting : () => handleAction(),
                        destructive: true,
                        primary: true,
                    },
                    {
                        datatestid: "cancel-leave",
                        text: "CANCEL",
                        onClick: handleCancel,
                    }
                ]}
                size="small">
                <div className="font-md">{footer}</div>
            </ModalWrapper>
        </React.Fragment>
        }
    </>
}