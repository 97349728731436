import React from "react";
import {useHistory} from "react-router-dom";
import {InvestorGroupType, MemberGroup} from "../../../ClientManagement/models/InvestorGroupType";
import {AssetsSummary} from "../../models/Assets";
import ArrangeAssets from "./ArrangeAssets";
import {ReorderAssetRequest} from "./ReorderAssets";
import {putAssetsOrder} from "../../AssetsApiClient";

type ArrangeAssetsContainerProps = {
    profileId: string,
    assetsSummary: AssetsSummary | undefined,
    investorGroup?: InvestorGroupType,
    memberGroup: MemberGroup
}
const ArrangeAssetsContainer = ({ profileId, assetsSummary, investorGroup, memberGroup }: ArrangeAssetsContainerProps) => {
    const history = useHistory();

    const handleSave = (reorderAssetRequest: ReorderAssetRequest) => {
        putAssetsOrder(profileId, reorderAssetRequest)
            .then(response => {
                if (response.status === 200) {
                    history.push(`/Profile/${profileId}/ClientProfile/AssetSummary`);
                }
            })
            .catch(error => {
                console.error('Could not update the order of the assets', error.message)
            });
    }

    const handleCancel = () => history.goBack();

    return (
            <ArrangeAssets
                profileId={profileId}
                assetsSummary={assetsSummary!}
                memberGroup={memberGroup}
                investorGroup={investorGroup!}
                onSave={handleSave}
                onCancel={handleCancel}
            />
    )
}

export default ArrangeAssetsContainer;