import React, {useContext, useEffect, useState} from 'react'
import {AlertBanner, Button, EmptyStateContainer, Icon} from "../../../components";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import {useAppSelector} from "../../../store/hooks";
import {selectProfile} from "../../../ClientManagement/ClientProfile/activeProfileSlice";
import {RouteWithAssetId} from "../../../routes/types";
import {assetsApiClient} from "../../AssetsApiClient";
import {StandaloneAccount} from "../../models/StandaloneAccount";
import {HoldingAssetSubclassDetails} from "../../models/Holding";
import LoadingIndicator from "../../../pages/LoadingIndicator";
import {clientManagementApiClient} from "../../../ClientManagement/ClientManagementApiClient";
import {toDisplayDateFormat} from "../../../utils/dateUtils";
import HoldingsInfo from "./HoldingsInfo";
import DataEntryHeader from "../../../components/DataEntry/DataEntryHeader";
import {InvestorGroupType} from "../../../ClientManagement/models/InvestorGroupType";
import {HoldingSummarySubclassDetailsRow} from './HoldingSummarySubclassDetailsRow';
import CustomModal from "../../../components/Modal/Custom/CustomModal";
import {HoldingSummaryAccordion} from "./HoldingSummaryAccordion";
import {presentAccountSubtitle, presentOwnershipSummary} from "./presenter";
import {HoldingSummaryAssetSubclass} from "./HoldingSummaryAssetSubclass";
import {AssetClassifications} from "../../models/AssetClassifications";
import {HoldingsScrollableContainer} from './HoldingsScrollableContainer';
import AssetsViewContext from "../../common/AssetsViewContext";
import {mapHoldingAssetSubclassDetailsToHoldingWriteModel, sumByField} from "./holdingUtils";
import useProfileEditableState from "../../../hooks/useProfileEditableState";
import {HistoryBlockDiscardModal} from "../../../components/HistoryBlockDiscardModal/HistoryBlockModal";

const HoldingsSummary = () => {
    const viewType = useContext(AssetsViewContext);
    const {url} = useRouteMatch();
    const [standaloneAccount, setStandaloneAccount] = useState<StandaloneAccount>();
    const [holdings, setHoldings] = useState<HoldingAssetSubclassDetails[]>([]);
    const [investorGroup, setInvestorGroup] = useState<InvestorGroupType>();
    const [selectedHolding, setSelectedHolding] = useState<HoldingAssetSubclassDetails>();
    const [classifications, setClassifications] = useState<AssetClassifications>();
    const [showNavigationModal, setShowNavigationModal] = useState(true);
    const [formChanged, setFormChanged] = useState(false)
    const {assetId} = useParams<RouteWithAssetId>();
    const history = useHistory();
    const profile = useAppSelector(selectProfile);
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();
    const [showBanner, setShowBanner] = useState(true)

    useEffect(() => {
        fetchAccount();
    }, [assetId]);

    const fetchAccount = () => {
        Promise.all([
            assetsApiClient.getAccount(profile.id, assetId),
            clientManagementApiClient.getInvestorGroup(profile.id),
            assetsApiClient.getAssetClassifications(),
        ]).then(([
                     account,
                     investorGroupResponse,
                     assetClassifications
                 ]) => {
            setStandaloneAccount(account);
            setHoldings(account.holdings.allHoldings);
            setInvestorGroup(investorGroupResponse);
            setClassifications(assetClassifications);
        }).catch(error => console.error('Could not fetch asset details', error.message));
    }

    const saveHoldings = (success: () => void) => {
        setShowNavigationModal(false)

        const updatedHoldings = holdings
            .filter(holding => holding.id)
            .map(holding => mapHoldingAssetSubclassDetailsToHoldingWriteModel(holding));

        if (updatedHoldings.length > 0) {
            assetsApiClient.postHoldings(profile.id, assetId, updatedHoldings)
                .then(() => {
                    success();
                }).catch(reason => console.log(reason));
        } else {
            success();
        }
    }

    const onAddOrEditSubclassesClick = () => {
        setShowNavigationModal(false)
        saveHoldings(() => history.push(`${url}/EditSubclasses`));
    }

    const onAddProductsClick = () => {
        setShowNavigationModal(false)
        saveHoldings(() => history.push(`${url}/AddProducts`));
    }

    const onDoneClick = () => {
        setShowNavigationModal(false)
        saveHoldings(() => history.push(`/Profile/${(profile.id)}/ClientProfile/${viewType}/EditStandaloneAccount/${assetId}`));
    };

    const handleLockHolding = async (index: number, checked: boolean) => {
        handleAssetSubclassDetailUpdate(index, (assetSubclassDetail: HoldingAssetSubclassDetails) => {
            assetSubclassDetail.locked = checked
        })
        setFormChanged(true)
    }

    const handleConcentrateHolding = async (index: number, checked: boolean) => {
        handleAssetSubclassDetailUpdate(index, (assetSubclassDetail: HoldingAssetSubclassDetails) => {
            assetSubclassDetail.concentrated = checked
        })
        setFormChanged(true)
    }

    const handleAssetSubclassDetailUpdate = async (index: number, changeFunction: (assetSubclassDetail: HoldingAssetSubclassDetails) => void) => {
        if (index >= 0) {
            const holdingToUpdate = holdings[index];
            changeFunction(holdingToUpdate);

            setHoldings([
                ...holdings.slice(0, index),
                holdingToUpdate,
                ...holdings.slice(index + 1, holdings.length),
            ])
        }
    }

    const handleDeleteHolding = async (holdingId: string) => {
        const response = await assetsApiClient.deleteHolding(profile.id, holdingId);
        if (response.status === 200) {
            fetchAccount();
        }
    }

    const handleClose = () => {
        setShowBanner(false)
    }


    if (!standaloneAccount || !investorGroup || !classifications) {
        return <LoadingIndicator/>;
    }

    const primaryMember = investorGroup.primaryMember;
    const partnerMember = investorGroup.partnerMember;

    function renderHoldingSummaryWithHoldings(account: StandaloneAccount, assetClassifications: AssetClassifications) {
        const marketVal = sumByField(holdings, holding => holding.marketEstateValue.inEstateValue)
        const loans = standaloneAccount?.personalLiabilities.reduce((x, pLiability) => x + pLiability.loanBalance, 0)
        const loanExceedsValue = loans! > marketVal
        const assetSubclassDetailsRowRenderer = (assetSubclassDetail: HoldingAssetSubclassDetails, index: number) =>
            <HoldingSummarySubclassDetailsRow
                key={`asset-subclass-details-${assetSubclassDetail.id}-${assetSubclassDetail.productName}`}
                index={index}
                assetSubclassDetail={assetSubclassDetail}
                selectHoldingToDelete={(subclassDetail) => setSelectedHolding(subclassDetail)}
                handleLockHolding={handleLockHolding}
                handleConcentrateHolding={handleConcentrateHolding}
                disableCheckboxes={isProfileWithProposalsOrArchived!}
            />;
        const allHoldings = account.holdings.allHoldings;
        return (
            <>
                <DataEntryHeader
                    title={account.name}
                    primaryButtonText="Done"
                    secondaryButtonText="Edit Products"
                    tertiaryButtonText="Edit Subclasses"
                    disableSecondaryButton={isProfileWithProposalsOrArchived}
                    disableTertiaryButton={isProfileWithProposalsOrArchived}
                    hideTertiaryButton={false}
                    onPrimaryButtonClick={onDoneClick}
                    onSecondaryButtonClick={onAddProductsClick}
                    onTertiaryButtonClick={onAddOrEditSubclassesClick}
                    SubtitleComponent={() => <span
                        className='holdings-subtitle'>{`As of ${toDisplayDateFormat(account.asOfDate)}`}</span>}
                />
                {loanExceedsValue &&
                    <AlertBanner
                        className={"liability-holdings-width"}
                        showAlert={showBanner}
                        icon='error'
                        showCloseBtn={true}
                        type='warning'
                        fullWidth={false}
                        onClose={handleClose}
                        message="The value of this asset is lower than its corresponding liabilities."
                    />}
                <div>
                    <HoldingsInfo account={account}
                                  primaryMember={primaryMember}
                                  partnerMember={partnerMember}
                                  allHoldings={allHoldings}
                                  classifications={assetClassifications}
                    />
                    <div className="holding-grid holding-grid-container" data-testid="holdings-summary-grid">
                        <div className="grid-display-contents" role="row">
                            <span className="display-flex align-items-center paddingbottom-md paddingleft-xl"
                                  role="cell">
                                <Icon name="chevron_double_right"/>
                                <span className="condensed-subtitle paddingleft-md">Asset Class / Subclass</span>
                            </span>
                            <span className="condensed-subtitle" role="cell">Product</span>
                            <span className="condensed-subtitle textalign-right" role="cell">Tax Cost</span>
                            <span className="condensed-subtitle textalign-right" role="cell">Market Value</span>
                            <span className="condensed-subtitle textalign-right" role="cell">Investable Value</span>
                            <span className="condensed-subtitle textalign-center" role="cell">Locked</span>
                            <span className="condensed-subtitle textalign-center" role="cell">Concen</span>
                        </div>
                        <div className="empty-grid-row"/>
                        <HoldingsScrollableContainer>
                            <HoldingSummaryAccordion
                                riskAssetClasses={assetClassifications.riskAssetClasses}
                                riskControlAssetClasses={assetClassifications.riskControlAssetClasses}
                                assetSubclassRowRenderer={(assetSubclass, holdingsWithIndex, subclassIndex) => {
                                    return <HoldingSummaryAssetSubclass
                                        key={subclassIndex}
                                        assetSubclass={assetSubclass}
                                        holdingsWithIndex={holdingsWithIndex}
                                        subclassIndex={subclassIndex}
                                        assetSubclassDetailsRowRenderer={assetSubclassDetailsRowRenderer}/>
                                }}
                                selectedHoldings={allHoldings}/>
                        </HoldingsScrollableContainer>
                    </div>
                </div>
                <CustomModal
                    isOpen={selectedHolding !== undefined}
                    title={`Delete this Holding?`}
                    content={
                        <>
                            <div>
                                {`The ${selectedHolding?.productName} holding and all associated data will be deleted permanently.`}
                            </div>
                        </>
                    }
                    onClickConfirm={() => {
                        handleDeleteHolding(selectedHolding?.id!);
                        setSelectedHolding(undefined);
                    }}
                    onClickCancel={() => {
                        setSelectedHolding(undefined);
                    }}
                    confirmText={`Delete Holding`}
                    cancelText={`Cancel`}
                />
                <HistoryBlockDiscardModal
                    when={formChanged && showNavigationModal}
                />
            </>
        );
    }

    function renderHoldingsSummaryWithoutHoldings(account: StandaloneAccount) {
        const accountSubtitle = presentAccountSubtitle(account);
        const ownershipSummary = presentOwnershipSummary(primaryMember, partnerMember, account);
        return (
            <>
                <DataEntryHeader
                    title={account.name}
                    primaryButtonText="Done"
                    hideSecondaryButton={true}
                    onPrimaryButtonClick={onDoneClick}
                    SubtitleComponent={() => <span className='holdings-subtitle'>
                        {`${accountSubtitle}  |  ${ownershipSummary}`}
                    </span>}
                />
                <EmptyStateContainer
                    className="no-holdings-placeholder"
                    title="No holdings have been entered for this account."
                    size="large"
                    description={
                        <>
                            <Button onClick={onAddOrEditSubclassesClick} kind="secondary" size="medium">Add Holdings By
                                Subclass</Button>
                            <Button onClick={onAddProductsClick} kind="secondary" size="medium">Add Holdings By
                                Product</Button>
                        </>
                    }
                />
            </>
        );
    }

    return (
        <div className="holdings-container holdings-container__holdings-summary">
            {holdings.length === 0 ? renderHoldingsSummaryWithoutHoldings(standaloneAccount) : renderHoldingSummaryWithHoldings(standaloneAccount, classifications)}
        </div>
    );
};

export default HoldingsSummary;
