import {HoldingAssetSubclassDetails} from "../../models/Holding";
import {AccordionItem} from "../../../components";
import React from "react";
import AssetClassHeader from "./AssetClassHeader";
import AssetClassificationFooter from "./AssetClassificationFooter";
import {AssetClass, AssetSubclass} from "../../models/AssetClassifications";

type HoldingSummaryTableProps = {
    assetClasses: AssetClass[],
    assetClassificationName: string,
    accentColor: string,
    holdingsWithIndex: { holding: HoldingAssetSubclassDetails, index: number }[],
    assetSubclassRowRenderer: (
        assetSubclass: AssetSubclass,
        holdingsWithIndex: { holding: HoldingAssetSubclassDetails; index: number }[],
        subclassIndex: number,
    ) => React.ReactNode,
}

function extractDetailsIdsFromClass(assetClass: AssetClass): string[] {
    return assetClass.assetSubclasses
        .flatMap(assetSubclass => assetSubclass.assetSubclassDetails)
        .map(assetSubclassDetails => assetSubclassDetails.assetSubclassDetailsId);
}

function getHoldingsInAssetClass(holdings: HoldingAssetSubclassDetails[], assetClass: AssetClass) {
    const assetClassSubclassDetailsIds = extractDetailsIdsFromClass(assetClass);
    return holdings
        .filter((holding) => assetClassSubclassDetailsIds.includes(holding.assetSubclassDetailsId));
}

const HoldingSummaryTable = ({
                                 accentColor,
                                 assetClasses,
                                 assetClassificationName,
                                 assetSubclassRowRenderer,
                                 holdingsWithIndex
                             }: HoldingSummaryTableProps) => {
    const holdings = holdingsWithIndex
        .map(({holding}) => holding);

    return (
        <>
            <div className="grid-display-contents holding-asset-classification">
                <div className="grid-display-contents" role="table" data-testid={assetClassificationName}>
                    {assetClasses.map((assetClass: AssetClass, classIndex) => {
                        return (
                            <AccordionItem
                                uuid={assetClass.assetClassName}
                                key={classIndex}
                                HeaderComponent={({expanded}) => (
                                    <AssetClassHeader
                                        expanded={expanded}
                                        holdingRows={getHoldingsInAssetClass(holdings, assetClass)}
                                        assetClass={assetClass}
                                    />
                                )}
                                className="holding-grid-accordion-item"
                                accentColor={accentColor}
                            >
                                <ul>
                                    {assetClass.assetSubclasses.map((assetSubclass, subclassIndex) => {
                                        return assetSubclassRowRenderer(assetSubclass, holdingsWithIndex, subclassIndex);
                                    })}
                                </ul>
                            </AccordionItem>
                        )
                    })}
                </div>
            </div>
            <AssetClassificationFooter
                assetClassificationName={assetClassificationName}
                holdings={holdings}
            />
        </>
    );
}

export default HoldingSummaryTable;
