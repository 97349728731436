import moment, {Moment} from "moment";
import React, {ChangeEvent, useEffect, useState} from "react";
import {VestingPeriod} from "src/Assets/models/EquityCompensation";
import {Icon} from "src/components";
import {Button} from "src/components/Button";
import DatePicker from "src/components/DatePicker/DatePicker";
import NumberInput from "src/components/Input/NumberInput";
import {DISPLAY_DATE_FORMAT, ISO8601_DATE_FORMAT} from "src/constants/common";
import {formatCurrencyToNumber} from "src/utils/format";

interface VestingPeriodRowProps {
  vestingPeriod: VestingPeriod;
  deleteVestingPeriod: (id: string) => void;
  updateVestingPeriod: (updatedVestingPeriod: VestingPeriod) => void;
  totalShares: number;
  grantDate: string;
  expirationDate: string;
}

const VestingPeriodRow = ({ vestingPeriod, deleteVestingPeriod, updateVestingPeriod, totalShares, grantDate, expirationDate }: VestingPeriodRowProps) => {
  const [vestingPeriodFormData, setVestingPeriodFormData] = useState<VestingPeriod>(vestingPeriod);

  useEffect(() => {
      setVestingPeriodFormData(vestingPeriod);
  }, [vestingPeriod]);

  function isBeforeGrantDate(date: moment.Moment) {
    return date.isBefore(moment(grantDate).startOf('day'));
  }

  function isAfterExpirationDate(date: moment.Moment): boolean {
    return date.isAfter(moment(expirationDate).endOf('day'));
  }

  function isVested(date: string) {
    return moment(date).isSameOrBefore(moment().endOf('day'));
  }

  const isOutsideRange = (date: Moment) => isBeforeGrantDate(date) || isAfterExpirationDate(date);

  const handleDateChange = (date: Moment) => {
    let nextVestingDate: string;
    if(isBeforeGrantDate(date)) {
      nextVestingDate = grantDate
    } else if(isAfterExpirationDate(date)) {
      nextVestingDate = expirationDate
    } else {
      nextVestingDate = date?.utc().format(ISO8601_DATE_FORMAT);
    }
    updateVestingPeriod({
      ...vestingPeriodFormData,
      nextVestingDate,
    });
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setVestingPeriodFormData({
        ...vestingPeriodFormData,
        [name]: value
    })
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const { name, value } = e.target;
    const updatedVestingPeriod = calculateVestingPeriodFormData(vestingPeriodFormData, name, value, totalShares);
    updateVestingPeriod(updatedVestingPeriod);
  }

  return (
    <div
      role="row"
      className="vesting-grid vesting-table__row"
      tabIndex={0}
      aria-label="Vesting Period"
    >
      <div role="cell" aria-label="Next Vesting Date">
        <DatePicker
          className="nextVestingDateInput"
          id="nextVestingDateInput"
          size="medium"
          displayFormat={DISPLAY_DATE_FORMAT}
          isOutsideRange={isOutsideRange}
          hideKeyboardShortcutsPanel
          date={
            vestingPeriodFormData.nextVestingDate
              ? moment(vestingPeriodFormData.nextVestingDate)
              : undefined
          }
          onDateChange={handleDateChange}
        />
      </div>
      <div role="cell" className="input-right-aligned textalign-right">
        <NumberInput
          size="medium"
          name="percentage"
          removeMarginTop
          aria-label='Percent (%)'
          minValue={0}
          maxValue={100}
          formatOptions={{
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: "decimal",
          }}
          allowDecimals
          value={vestingPeriodFormData.percentage}
          onChangeValue={handleInputChange}
          onBlur={handleBlur}
          tabIndex={0}
        />
      </div>
      <div role="cell" className="input-right-aligned textalign-right" 
          aria-label='Shares Vesting Per Period Wrapper'>
        <NumberInput
          size="medium"
          minValue={0}
          maxValue={totalShares}
          formatOptions={{
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: "decimal",
          }}
          allowDecimals
          inlineLabel={isVested(vestingPeriodFormData.nextVestingDate) ? VestedLabel : undefined}
          inlineLabelAlign="left"
          aria-label='Shares Vesting Per Period'
          name="sharesVestingPerPeriod"
          value={vestingPeriodFormData.sharesVestingPerPeriod}
          onChangeValue={handleInputChange}
          onBlur={handleBlur}
        />
      </div>
      <div role="cell">
        <Button
          icon="only"
          iconName="close"
          kind="borderless"
          onClick={() => deleteVestingPeriod(vestingPeriodFormData.id)}
        />
      </div>
    </div>
  );
};

function calculateSharesVestingPerPeriodFromPercentage(sharesGranted: number, percentage: number): number {
  return (sharesGranted * percentage) / 100
}

function calculatePercentageFromSharesVestingPerPeriod(sharesGranted: number, sharesVestingPerPeriod: number): number {
  return (sharesVestingPerPeriod / sharesGranted) * 100
}

const calculateVestingPeriodFormData = (vestingPeriodFormData: VestingPeriod, name: string, value: string, sharesGranted: number): VestingPeriod => {
  const formattedValue = formatCurrencyToNumber(value);

  switch(name){
    case 'percentage':
      return {
        ...vestingPeriodFormData,
        percentage: formattedValue,
        sharesVestingPerPeriod: calculateSharesVestingPerPeriodFromPercentage(sharesGranted, formattedValue)
      }
    case 'sharesVestingPerPeriod':
      return {
        ...vestingPeriodFormData,
        sharesVestingPerPeriod: formattedValue,
        percentage: calculatePercentageFromSharesVestingPerPeriod(sharesGranted, formattedValue)
      }
    default:
      return {
        ...vestingPeriodFormData,
        [name]: formattedValue
      }
  }
}

const VestedLabel = <span>
  <Icon className="vested-icon" name="check" />
  <span className="vested-text">Vested</span>
</span>;

export default VestingPeriodRow;
