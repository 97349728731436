import React, {Fragment} from "react";
import {ReserveTargetAllocationDonut} from "../ReserveTargetAllocationDonut";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {
    selectPortfolioReserveButtonStates, setPortfolioReserveButtonStates,
} from "../PortfolioReserveSlice";
import {DropdownItem, Icon, PageActionMenu} from "../../../components";
type AssetAllocationRowProps = {
    assetAllocationRowActive: boolean,
    reserveTargetAssetAllocation: { totalRiskAssetsPercent: number; totalRiskControlAssetsPercent: number },
    minimumRiskAssetAllocation: { totalRiskAssetsPercent: number; totalRiskControlAssetsPercent: number },
    riskControlDiffDesc: string
}
export const AssetAllocationRow = ({
                                       assetAllocationRowActive,
                                       reserveTargetAssetAllocation,
                                       minimumRiskAssetAllocation,
                                       riskControlDiffDesc
                                   }: AssetAllocationRowProps
) => {
    const dispatch = useAppDispatch();
    const portfolioReserveButtonStates = useAppSelector(selectPortfolioReserveButtonStates);
    const {assetAllocation} = portfolioReserveButtonStates;
    const buttonState = useAppSelector(selectPortfolioReserveButtonStates);
    const handleHideButton = () =>{ dispatch(setPortfolioReserveButtonStates({...buttonState,assetAllocation: false}))};
    return (
        <div
            className={`edit-portfolio-reserve-page__table__row`}
            role="row">
            <div className="edit-portfolio-reserve-page__table__column">
                <div style={{display: 'flex', marginRight: '2px'}}>
                     <div
                      style ={{width:'95%'}}

                    className={`edit-portfolio-reserve-page__table__column__content ${assetAllocationRowActive ? "" : "row-inactive"}`}>
                    How does this Reserve Target impact my proposed asset allocation?
                     </div>
                    {assetAllocationRowActive &&
                        <PageActionMenu data-testid="asset-allocation-page-action" className={"pr-page-action-menu"}
                                        panelWidth={200} aria-label=" ">

                            <DropdownItem className="reserve-page-action-menu-options" value="" itemText=""
                                          onClick={handleHideButton}>
                                Hide this Section
                                <Icon ariaHidden name="visibility_off_outline" style={{marginLeft: '52px'}}/>
                            </DropdownItem>
                        </PageActionMenu>
                    }
                </div>
            </div>
            <div
                className="edit-portfolio-reserve-page__table__column edit-portfolio-reserve-page__table__second-column edit-portfolio-reserve-page__centered">
                <ReserveTargetAllocationDonut
                    assetAllocationRowActive={assetAllocationRowActive}
                    reserveTargetAssetAllocation={reserveTargetAssetAllocation}/>
            </div>
            <div
                className="edit-portfolio-reserve-page__table__column edit-portfolio-reserve-page__table__third-column edit-portfolio-reserve-page__centered">
                <ReserveTargetAllocationDonut
                    assetAllocationRowActive={assetAllocationRowActive}
                    reserveTargetAssetAllocation={minimumRiskAssetAllocation}/>
                {assetAllocation ? <span className="edit-portfolio-reserve-page__text">{riskControlDiffDesc}</span> :
                    <Fragment/>}
            </div>
        </div>
    );
}