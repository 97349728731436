import {emptyPresentValueResponse, PresentValueResponse} from "./PresentValue";
import {FamilyGoalsType} from "./FamilyGoalType";

export enum GoalType {
    LIFESTYLE_SPENDING = 'Lifestyle_Spending',
    FAMILY = 'Family',
    DISCRETIONARY = 'Discretionary',
    PHILANTHROPIC = 'Philanthropic',
    TAX_LIABILITIES = 'Tax Liabilities',
}

export enum PrioritizationType {
    NEEDS = 'NEEDS',
    WANTS = 'WANTS'
}

export enum DiscretionaryGoalsType {
    HOME = 'Home',
    AUTOMOBILE = 'Automobile',
    TRAVEL = 'Travel',
    HOME_IMPROVEMENTS = 'Home Improvements',
    SPECIALTY_VEHICLE = 'Specialty Vehicle',
    DISCRETIONARY = 'Discretionary',
    INSURANCE = 'Insurance',
    CUSTOM = 'Custom'
}

export enum PhilanthropicGoalsType {
    ANNUAL_GIFT = 'Annual Gift',
    ONE_TIME_GIFT = 'One Time Gift',
    FUND_CONTRIBUTION = 'Fund Contribution',
    COMMUNITY_SUPPORT = 'Community Support',
    UNIVERSITY_GIFT = 'University Gift',
    CUSTOM = 'Custom'
}

export type NonLifestyleGoalType = NonLifeStyleGoalRequest & {
    createdTimestamp?: string,
    beneficiaryId?: string,
    calculatedFields: PresentValueResponse
}

export type UpdateNonLifestyleGoalType = {
    id: string,
    goalType: GoalType,
    prioritizationType: PrioritizationType,
    beneficiaryId?: string

}

export type NonLifeStyleGoalRequest = {
    id?: string,
    ownerId?: string,
    goalType?: GoalType,
    userInputs: GoalUserInputs,
    beneficiaryId?: string,
    prioritizationType?:PrioritizationType
}

export type GoalUserInputs = {
    description: string,
    selectedGoalSubType: GoalSubType
    annualFlow: number,
    startDate: string,
    endDate: string,
    riskAsset: number | null
    riskControl: number | null
    isFundedByNonInvestableAssets: boolean
    isAssetPurchase?: boolean
    isAchieved?: boolean
}

export type GoalSubType = FamilyGoalsType | DiscretionaryGoalsType | PhilanthropicGoalsType;

export const newNonLifestyleGoal = (
    ownerId: string,
    goalType: GoalType,
    selectedGoalSubType: GoalSubType,
    startDate: string,
    endDate: string,
    beneficiaryId?: string
): NonLifestyleGoalType => {
    return {
        ownerId,
        beneficiaryId,
        goalType: goalType,
        calculatedFields: emptyPresentValueResponse,
        userInputs: {
            riskControl: null,
            riskAsset: null,
            annualFlow: 0,
            description: selectedGoalSubType,
            selectedGoalSubType: selectedGoalSubType,
            startDate,
            endDate,
            isFundedByNonInvestableAssets: false
        },
    }
}


