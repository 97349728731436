import {useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import {validate as isValidUUID} from "uuid";
import {useAppInsights} from "../AppInsights";
import {msalUtils} from "../MsalUtils";
import {useMsal} from "@azure/msal-react";

export const RouteTracker = () => {
    const location = useLocation();
    const appInsights = useAppInsights();
    const msal=useMsal();
    const oid=msalUtils.getOid(msal);
    const lanId=msalUtils.getLanId(msal);
    const accTitle = msalUtils.getAccountTitle(msal);

    useEffect(() => {
        let path = location.pathname.split('/')
        let screenName = path[path.length-1]
        if (!screenName) {
            screenName = 'PartnerDashboard';
        } else if (isValidUUID(screenName) && path[path.length-2] == 'Profile') {
            screenName = 'ProfileDetails';
        }else if(isValidUUID(screenName)){
            // edit asset
            // ex: EditStandaloneAccount - URL = .../EditStandAloneAccount/UUID
            screenName = path[path.length-2]
        }else if(screenName == 'New'){
            // diferentiate new client vs new prospect vs new sample
            screenName = path[path.length-1] + path[path.length-2] 
        }else if(screenName == 'Summary'){
            // diferentiate Goals summary and WealthPlanOptimizer Summary
            screenName = path[path.length-2] + path[path.length-1]
        }else if(path.includes('Resources')){
            // handle document tracking
            // documents are like /resources/library/folder/doc/page
            screenName = 'Resource Document: ' + path[path.length-2]
        }

        appInsights.trackPageView({
            name: screenName,
            properties: {
                unique_user_id: oid,
                lan_id: lanId,
                title: accTitle
            }
        });
    }, [location.pathname])
    return <></>
}