import React, {ChangeEvent} from "react";
import {Checkbox} from "xps-react";
import {formatCurrency} from "../../../utils/format";
import {shortenName} from "../../common/textUtils";

type LegalEntityOwnershipProps = {
    name: string,
    isOutOfEstateReport: boolean,
    onChangeOutOfEstateReport: (selected: boolean) => void
    totalOwned?: number | null,
    isProfileWithProposalsOrArchived?: boolean
}

export default function SoleLegalEntityOwnershipTable({
                                                        name,
                                                        isOutOfEstateReport,
                                                        onChangeOutOfEstateReport,
                                                        totalOwned,
                                                        isProfileWithProposalsOrArchived = false
                                                    }: LegalEntityOwnershipProps) {
    return (
        <div className="ownership">
            <div className="h5">Non-Client Ownership</div>
            <div className="ownership-table">
                <div role="5-column-row">
                    <div className="grid-span-2">ENTITY</div>
                    <div className="center-align">% OWNED</div>
                    <div className="center-align">OUT-OF-ESTATE REPORT</div>
                    <div className="right-align">TOTAL OWNED</div>
                </div>
                <div role="5-column-row" className="ownership-table-row">
                    <div className="grid-span-2">{shortenName(name,25)}</div>
                    <div className="center-align">100.00</div>
                    <div className="center-align ownership-table-checkbox-field">
                        <Checkbox
                            aria-label={`${name} out-of-estate report`}
                            value={isOutOfEstateReport}
                            checked={isOutOfEstateReport}
                            name="OUT-OF-ESTATE REPORT"
                            onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeOutOfEstateReport(event.target.checked)}
                            disabled={isProfileWithProposalsOrArchived}
                        />
                    </div>
                    <div className="right-align">
                        {totalOwned ? formatCurrency(totalOwned) : '--'}
                    </div>
                </div>
            </div>
        </div>
    )
}
