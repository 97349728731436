import {HoldingAssetSubclassDetails} from "../../models/Holding";
import {formatCurrency} from "../../../utils/format";
import {sumByField} from "./holdingUtils";
import React from "react";

type AssetClassificationFooterProps = {
    assetClassificationName: string,
    holdings: HoldingAssetSubclassDetails[],
}

function AssetClassificationFooter({
                                       assetClassificationName,
                                       holdings
                                   }: AssetClassificationFooterProps) {
    const totalTaxCost = formatCurrency(sumByField(holdings, holding => holding.taxCost));
    const totalMarketValue = formatCurrency(sumByField(holdings, holding => holding.marketValue));
    const totalInvestableValue = formatCurrency(sumByField(holdings, holding => holding.investableValue));
    return (
        <div className="holdings-footer holding-grid" data-testid="asset-classification-footer">
            <span
                className="asset-classification-total holding-grid-column-span-2 holding-grid-column-custom-span">{`Total ${assetClassificationName}`}</span>
            <span className="asset-classification-total">{totalTaxCost}</span>
            <span className="asset-classification-total">{totalMarketValue}</span>
            <span className="asset-classification-total">{totalInvestableValue}</span>
            <span className="empty-grid-column"/>
            <span className="empty-grid-column"/>
        </div>
    );
}

export default AssetClassificationFooter;
