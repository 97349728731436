import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {assetsApiClient} from "../AssetsApiClient";
import {useHistory} from "react-router-dom";
import {LifeInsuranceForm} from "./LifeInsuranceForm";
import {clientManagementApiClient} from "../../ClientManagement/ClientManagementApiClient";
import {MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import LoadingIndicator from "../../pages/LoadingIndicator";
import {CreateLifeInsuranceRequest, LifeInsuranceFormData, lifeInsuranceTypes} from "../models/LifeInsurance";
import {useAppSelector} from "../../store/hooks";
import {selectProfile} from "../../ClientManagement/ClientProfile/activeProfileSlice";
import AddFormContainer from "../AddFormContainer";
import {getDefaultOwnershipDetails} from "../formHelpers";
import {LegalEntityFormData, OwnershipDetailsFormData} from "../models/Ownership";
import AssetsViewContext from "../common/AssetsViewContext";

const AddLifeInsurance: React.FC = () => {
    const history = useHistory();
    const profile = useAppSelector(selectProfile);
    const viewType = useContext(AssetsViewContext);
    const [memberGroup, setMemberGroup] = useState<MemberGroup>();
    const [legalEntities, updateLegalEntities] = useState<LegalEntityFormData[]>([]);

    const initialLifeInsurance = useMemo(
        (): LifeInsuranceFormData => {
            return {
                id:'',
                description: "Life Insurance",
                type: lifeInsuranceTypes.TERM,
                insuredMemberId: memberGroup?.primaryMember.id!,
                deathBenefitValue: 0,
                insuranceCompanyName: '',
                issueDate: '',
                cashValue: 0,
                isCashValueWillFundGoals: false,
                expirationDate:'',
                term: 0,
                beneficiaries: [],
            }
        },
        [memberGroup]
    );

    useEffect(() => {
        if (!profile) return;
        Promise.all([clientManagementApiClient.getMemberGroup(profile.id),
            assetsApiClient.getLegalEntities(profile.id)])
            .then(([memberGroupResponse, legalEntitiesResponse]) => {
                setMemberGroup(memberGroupResponse);
                updateLegalEntities(legalEntitiesResponse);
            }).catch(error => console.error('Could not fetch life insurance data', error.message));
    }, [profile])

    const getInitialOwnershipDetails = useCallback(
        (): OwnershipDetailsFormData => {
            return getDefaultOwnershipDetails(memberGroup?.primaryMember.id!)
        },
        [profile, memberGroup]
    );

    if (!memberGroup || !profile) {
        return <LoadingIndicator/>;
    }

    const onSaveClick = async (request: CreateLifeInsuranceRequest) => {
        await assetsApiClient.postLifeInsurance(profile.id, request);
        history.push(`/Profile/${profile.id}/ClientProfile/${viewType}`);
    };

    return <div className='life-insurance'>
        <AddFormContainer
            modalTitle="Asset"
            form={(handleCancel) => (
                <LifeInsuranceForm
                    initialLifeInsurance={initialLifeInsurance}
                    defaultLifeInsurance={initialLifeInsurance}
                    initialOwnershipDetails={getInitialOwnershipDetails()}
                    handleSave={onSaveClick}
                    memberGroup={memberGroup}
                    profileId={profile.id}
                    formatTitle={() => 'Add Life Insurance'}
                    handleCancel={handleCancel}
                    legalEntities={legalEntities}
                    updateLegalEntities={updateLegalEntities}
                />
            )}
        />
    </div>;
};

export default AddLifeInsurance;
