import {GeneralInflow} from "../../models/GeneralInflow";
import React, {useState} from "react";
import {shortenName} from "../../common/textUtils";
import {
    isGeneralInflowPendingDisclosureReview,
    isTrustInflowTypeWithDisclosures
} from "../../GeneralInflow/InflowUtils";
import {FutureInflowsPopover} from "./FutureInflowsPopover";
import {formatCurrency} from "../../../utils/format";
import ConfirmReviewDisclosure from "../../GeneralInflow/GeneralInflowForm/ConfirmReviewDisclosure";
import {TableActionDropdownMenu} from "../../../components/Table/TableActionDropdownMenu";
import {DropdownGroup} from "xps-react";
import {DropdownItem, Icon} from "../../../components";
import useProfileEditableState from "../../../hooks/useProfileEditableState";

export type GeneralInflowTableRowProps = {
    generalInflow: GeneralInflow,
    onClickEdit: (id: string) => void,
    onClickDelete: (id: string, description: string) => void,
    onSaveReviewTrustDisclosure: (generalInflow: GeneralInflow) => void,
    hideActionMenu: boolean
};

export function GeneralInflowTableRow({
                                          generalInflow,
                                          onClickEdit,
                                          onClickDelete,
                                          onSaveReviewTrustDisclosure,
                                          hideActionMenu
                                      }: GeneralInflowTableRowProps) {
    const [isConfirmationDisclosureModalOpen, setConfirmationDisclosureModalOpen] = useState<boolean>(false);
    const [isDisclosureReviewed] = useState<boolean>(generalInflow.isDisclosureReviewed === true);
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    function renderActionMenu(
        inflow: GeneralInflow,
        handleClickOnEditGeneralInflow: (id: string) => void,
        handleClickOnDeleteGeneralInflow: (id: string, description: string) => void) {

        return (
            <TableActionDropdownMenu ariaLabel={`${inflow.description} Menu`}>
                {isTrustInflowTypeWithDisclosures(inflow.trustInflowType) &&
                    <DropdownGroup groupName=" ">
                        <DropdownItem
                            itemText="Edit Asset"
                            value="Edit Asset"
                            onClick={() => handleClickOnEditGeneralInflow(inflow.id!)}
                        />
                        <DropdownItem
                            itemText="Review Trust Disclosure"
                            value="Review Trust Disclosure"
                            onClick={() => setConfirmationDisclosureModalOpen(true)}
                        />
                    </DropdownGroup>
                }
                {!isTrustInflowTypeWithDisclosures(inflow.trustInflowType) &&
                    <DropdownItem
                        itemText="Edit Asset"
                        value="Edit Asset"
                        onClick={() => handleClickOnEditGeneralInflow(inflow.id!)}
                    />
                }
                <DropdownItem
                    itemText="Delete Asset"
                    value="Delete Asset"
                    disabled={isProfileWithProposalsOrArchived}
                    onClick={() => handleClickOnDeleteGeneralInflow(inflow.id!, inflow.description)}
                >
                    <Icon name="delete" className="asset-table-delete-icon"/>
                </DropdownItem>
            </TableActionDropdownMenu>
        );
    }

    const handleSaveConfirmReviewDisclosure = (isReviewed: boolean) => {
        generalInflow.isDisclosureReviewed = isReviewed;
        setConfirmationDisclosureModalOpen(false);
        onSaveReviewTrustDisclosure(generalInflow);
    }

    const handleCancelConfirmReviewDisclosure = () => {
        setConfirmationDisclosureModalOpen(false);
    };

    return <div key={`general-inflows-row-${generalInflow.id}`} role="row"
                className="assets-grid-with-actionmenu assets-grid-table-row row-within-subheading" tabIndex={0}
                aria-label={`general inflow - ${generalInflow.id}`}>
        <div role="cell" className='textalign-left display-flex'>
            <div className="cell-with-popover">
                <div className='cell-text-content'>
                    {shortenName(generalInflow.description, 23)}
                    {isGeneralInflowPendingDisclosureReview(generalInflow)
                        && <span className="disclosure-needs-review"><br/>Disclosure needs review</span>
                    }
                </div>
                <FutureInflowsPopover futureInflow={generalInflow}/>
            </div>
        </div>
        <span role="cell"
              className='textalign-right cell-text-content'>{formatCurrency(generalInflow.netAnnualFlow)}
            <div className="sublabel-12px cell-text-content">{formatCurrency(generalInflow.grossAnnualFlow)}</div>
        </span>

        <span role="cell" className='textalign-right cell-text-content'>{generalInflow.yearsUntilFlow}</span>
        <span role="cell" className='textalign-right cell-text-content'>{generalInflow.yearsOfFlow}</span>
        <span role="cell"
              className='textalign-right cell-text-content'>{formatCurrency(generalInflow.presentValue)}</span>

        {isTrustInflowTypeWithDisclosures(generalInflow.trustInflowType) &&
            <ConfirmReviewDisclosure
                trustInflowType={generalInflow.trustInflowType!}
                isOpen={isConfirmationDisclosureModalOpen}
                isDisclosureReviewed={isDisclosureReviewed}
                onCancel={handleCancelConfirmReviewDisclosure}
                onSave={handleSaveConfirmReviewDisclosure}
            />
        }
        {!hideActionMenu &&
            renderActionMenu(
                generalInflow,
                onClickEdit,
                onClickDelete
            )
        }
    </div>;
}