import {Dropdown, DropdownItem, PresentationPaneHeader, RadioGroup} from "../../components";
import {DropdownGroup, MenuDivider} from "xps-react";
import {NO_OP} from "../../constants/common";
import React, { useEffect, useState } from "react";
import {useParams} from "react-router-dom";
import useProfileAndProposals from "../../hooks/useProfileAndProposals";
import {RouteWithId} from "../../routes/types";
import classNames from "classnames";
import { DataDisplayView, FundingDisplayOptions } from "./models/Funding";

const FundingHeader = (props: {
    handleChangeDisplayView: (e: React.ChangeEvent<HTMLInputElement>) => void,
    displayView: DataDisplayView,
    isFundingLoadedFirstTime: boolean,
    setDisplayOptions: (displayOptions: FundingDisplayOptions) => void
}) => {
    const [showFundedByInflows, setShowFundedByInflows] = useState<boolean>(false);
    const [showFundedByNonInvestable, setShowFundedByNonInvestable] = useState<boolean>(false);
    const {id} = useParams<RouteWithId>();
    const displayName = useProfileAndProposals(id).approvedProfile.displayName;

    useEffect(() => {
        let updatedDisplayOptions = FundingDisplayOptions.ONLY_PORTFOLIO;
        if(showFundedByInflows && !showFundedByNonInvestable) {
            updatedDisplayOptions = FundingDisplayOptions.PORTFOLIO_AND_INFLOWS
        } else if(!showFundedByInflows && showFundedByNonInvestable) {
            updatedDisplayOptions = FundingDisplayOptions.PORTFOLIO_AND_NON_INVESTABLE
        } else if(showFundedByInflows && showFundedByNonInvestable) {
            updatedDisplayOptions = FundingDisplayOptions.PORTFOLIO_AND_INFLOWS_AND_NON_INVESTABLE
        }
        props.setDisplayOptions(updatedDisplayOptions);
    }, [showFundedByInflows, showFundedByNonInvestable]);

    const toggleFundedByInflows = () => {
        setShowFundedByInflows(!showFundedByInflows);
    }

    const toggleFundedByNonInvestable = () => {
        setShowFundedByNonInvestable(!showFundedByNonInvestable);
    }

    const titleRowChildren= () => {
        return (
            <span className={classNames('page-action-menu', 'menu_down')}>
            <Dropdown
                alignRight
                buttonIcon="right"
                buttonKind="borderless"
                buttonSize="large"
                className="funding-page-action-menu__dropdown"
                drop="drop"
                dropdownKind="menu"
                rounded={true}
                iconNameOpen="menu_down"
                iconNameClose="menu_up"
                panelHeight='auto'
                panelWidth={240}
                aria-label='show-display-options'
                defaultText='Display Options'
                value=''
            >
                <DropdownGroup groupName={` `} key={`ls-dropdown-group`} show={true}>
                    <DropdownItem className="funding-page-action-menu-options" value="do_not_use" itemText="" onClick={NO_OP}>
                        <RadioGroup
                            id="dataDisplay"
                            name="dataDisplay"
                            label="Data Table"
                            layout="vertical"
                            values={[DataDisplayView.DOLLAR_VIEW, DataDisplayView.PERCENTAGE_VIEW]}
                            onChange={props.handleChangeDisplayView}
                            selected={props.displayView}
                        />
                    </DropdownItem>
                    <MenuDivider
                        className="funding-menu-divider"
                        role="separator"
                        itemText=""
                        value="do-not-use"
                    />
                    <DropdownItem
                        key="showFundedByInflows"
                        itemText={showFundedByInflows ? 'Hide Funded by Inflows' : 'Show Funded by Inflows'}
                        value={showFundedByInflows ? 'Hide Funded by Inflows' : 'Show Funded by Inflows'}
                        onClick={toggleFundedByInflows}
                    />
                    <DropdownItem
                        key="showFundedByNonInvestable"
                        itemText={showFundedByNonInvestable ? 'Hide Funded by Non Investable' : 'Show Funded by Non Investable'}
                        value={showFundedByNonInvestable ? 'Hide Funded by Non Investable' : 'Show Funded by Non Investable'}
                        onClick={toggleFundedByNonInvestable}
                    />
                </DropdownGroup>
            </Dropdown>
            </span>
        );
    }

    return (
        <PresentationPaneHeader className={`goals-funding__header`}
                                displayName={displayName}
                                title={'Goal Funding Over Time'}
                                titleRow={titleRowChildren()}
                                />
    )
}

export default FundingHeader;