import {MeetingParticipant} from "../Meeting";

export type NewPointerPositionCalculationProps = {
    boundingClientRectangle: DOMRect;
    xPercentage: number;
    yPercentage: number;
    pointerYOnBarChart: number;
    stackedBarChartHeight: number;
}

export const isUserInMeeting = (audience: MeetingParticipant[], userId: string): boolean => {
    return audience.filter(member => member.userId.toUpperCase() === userId.toUpperCase()).length > 0
}

export const getNewPointerPositionInPixels = (props: NewPointerPositionCalculationProps) => {
    const top = (props.pointerYOnBarChart > 0
            ? props.pointerYOnBarChart
            : ((props.boundingClientRectangle.height * props.yPercentage)
                + props.boundingClientRectangle.y
                + props.stackedBarChartHeight)
    ) + "px";

    const left = (props.boundingClientRectangle.width * props.xPercentage)
        + props.boundingClientRectangle.x
        + "px";

    return {top, left}
}