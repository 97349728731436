import React, {useEffect, useRef, useState} from "react";
import {Route, Switch, useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../routes/types";
import useProfileAndProposals from "../hooks/useProfileAndProposals";
import {wealthTransferApiClient} from "./WealthTransferApiClient";
import LoadingIndicator from "../pages/LoadingIndicator";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {isLoaded, resetEstateSummary, selectEstateSummary, setEstateSummary} from "./EstateSummarySlice";
import usePageViewTimer from "../hooks/usePageViewTimer";
import {EstateSummary, EstateType} from "./models/api";
import {ClientAssetsState, selectClientAssets, setClientAssets} from "../Assets/clientAssetsSlice";
import {LegalEntityReadModel} from "../Assets/models/Ownership";
import {assetsApiClient} from "../Assets/AssetsApiClient";
import AssetsViewContext from "src/Assets/common/AssetsViewContext";
import OutOfEstateContainer from "../Assets/common/OutOfEstate/OutOfEstateContainer";
import {NO_OP} from "../constants/common";
import {selectReleaseToggles} from "../ReleaseToggles/releaseTogglesSlice";
import {WealthTransferBarChartSidebar} from "./WealthTransferBarChartSidebar";
import ScrollableContainer from "../components/ScrollableContainer/ScrollableContainer";
import {WealthTransferHeader} from "./WealthTransferHeader";
import {WealthTransferOptions} from "./WealthTransferOptions";
import {AlertBanner, Dropdown, DropdownItem} from "../components";
import {WealthTransferAccordion} from "./WealthTransferAccordion";
import DataEntrySideDrawer from "../components/DataEntry/DataEntrySideDrawer";
import {EditBeneficiariesContainer} from "./EditBeneficiaries";
import {EditTaxExemptionContainer} from "./EditTaxExemption";
import useProfileEditableState from "../hooks/useProfileEditableState";

function pageName(pathname: string) {
    const parts = pathname.split('/');
    return parts[parts.length - 1];
}

const WealthTransferPlanningContainer = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const {id} = useParams<RouteWithId>();
    const {profile: profileOrProposal, approvedProfile} = useProfileAndProposals(id);
    const estateSummaryState = useAppSelector(selectEstateSummary);
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const [openDrawer, updateOpenDrawer] = useState(false);
    const [estateType, setEstateType] = useState<EstateType>(EstateType.CURRENT);
    const [nonDefaultEstateSummary, setNonDefaultEstateSummary] = useState<EstateSummary>();
    const clientAssets = useAppSelector(selectClientAssets);
    const [legalEntities, setLegalEntities] = useState<LegalEntityReadModel[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const isMounted = useRef<boolean>();
    const [showOutOfEstateAssets, setShowOutOfEstateAssets] = useState(false);

    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    const onEditBeneficiaries = () => {
        history.push(`/Profile/${profileOrProposal.id}/ClientProfile/WealthTransferPlanning/Edit/Beneficiaries`);
    };
    const onEditEstateTaxExemption = () => {
        history.push(`/Profile/${profileOrProposal.id}/ClientProfile/WealthTransferPlanning/Edit/TaxExemptions`);
    };

    const enableWealthTransferMenu = releaseToggles?.enableWealthTransferMenu ?? false;

    usePageViewTimer('Wealth Transfer Page Load Timer (milliseconds)', isLoading);

    useEffect(() => {
            const drawerOpening = openDrawer && isLoaded(estateSummaryState);
            if (!profileOrProposal.id || drawerOpening) {
                return;
            }

            isMounted.current = true;
            switch (estateType) {
                case EstateType.CURRENT:
                    dispatch(resetEstateSummary());
                    setIsLoading(true);
                    Promise.all([
                        assetsApiClient.getAssetsSummary(profileOrProposal.id),
                        assetsApiClient.getLegalEntities(profileOrProposal.id),
                        wealthTransferApiClient.getEstateSummary(profileOrProposal.id, estateType)
                    ]).then(([assetsResponse, legalEntitiesResponse, estateSummary]) => {
                        if (isMounted.current) {
                            setIsLoading(false);
                            dispatch(setClientAssets(assetsResponse));
                            setLegalEntities(legalEntitiesResponse);
                            dispatch(setEstateSummary(estateSummary));
                        }
                    });
                    break;
                default:
                    setNonDefaultEstateSummary(undefined);
                    wealthTransferApiClient.getEstateSummary(profileOrProposal.id, estateType)
                        .then(estateSummary => setNonDefaultEstateSummary(estateSummary));
                    break;
            }
            return () => {
                isMounted.current = false;
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [profileOrProposal.id, openDrawer, dispatch, estateType]
    );

    useEffect(() => {
        const shouldOpenEditDrawer = ['Beneficiaries', 'TaxExemptions'].includes(pageName(history.location.pathname));
        updateOpenDrawer(shouldOpenEditDrawer);
    }, [history.location.pathname]);

    const onToggleEstateType = async (newEstateType: EstateType) => {
        setEstateType(newEstateType);
    }

    function getEstateSummaryForEstateType(selectedEstateType: EstateType): EstateSummary | undefined {
        switch (selectedEstateType) {
            case EstateType.CURRENT:
                if (isLoaded(estateSummaryState)) {
                    return estateSummaryState.estateSummary;
                } else {
                    return undefined;
                }
            default:
                return nonDefaultEstateSummary;
        }
    }

    const estateSummaryForEstateType = getEstateSummaryForEstateType(estateType);

    function outOfEstateAssets(assets?: ClientAssetsState, legalEntityReadModels?: LegalEntityReadModel[]) {
        if (estateType === EstateType.CURRENT) {
            if (!assets || !legalEntityReadModels) {
                return <div className="hide-element-with-static-position"/>;
            }
            return <div className="holistic-advice-summary-page">
                <AssetsViewContext.Provider value="CurrentNetWorth">
                    <OutOfEstateContainer showOutOfEstate={showOutOfEstateAssets}
                                          onClickShowOutOfEstate={(showOutOfEstate) => {
                                              setShowOutOfEstateAssets(showOutOfEstate)
                                          }}
                                          assets={assets}
                                          onDeleteLegalEntity={NO_OP}
                                          legalEntities={legalEntityReadModels}
                                          showDeathBenefit={true}
                    />
                </AssetsViewContext.Provider>
            </div>;
        } else {
            return <></>;
        }
    }

    const estateSummaryForBarChartSidebar = openDrawer ? getEstateSummaryForEstateType(EstateType.CURRENT) : estateSummaryForEstateType
    const estateTypeSelection = openDrawer ? EstateType.CURRENT : estateType

    return <article className="summary-page layout-split-left">
        {estateSummaryForBarChartSidebar === undefined ?
            <LoadingIndicator className="hide-element-with-static-position"/> :
            <WealthTransferBarChartSidebar
                estateSummary={estateSummaryForBarChartSidebar}
                displayName={approvedProfile.displayName}
                estateType={estateTypeSelection}
            />
        }
        <section>
            <ScrollableContainer id={'wealth-transfer-summary-page-content-scroll-container'}>
                <div className={"summary-page-content"}>
                    <div style={{marginRight: "40px"}} className="section-header">
                        {
                            estateSummaryForEstateType === undefined ?
                                <LoadingIndicator style={{width: "100%"}} className="hide-element-with-static-position"
                                                  displayMessage=" "/> :
                                <WealthTransferHeader estateType={estateType}
                                                      estateSummary={estateSummaryForEstateType}/>
                        }
                        {enableWealthTransferMenu &&
                            <WealthTransferOptions onToggleEstateType={onToggleEstateType}
                                                   estateType={estateType}/>}
                        <Dropdown
                            alignRight
                            buttonIcon="left"
                            buttonKind="primary"
                            className="wealth-transfer-navigation-dropdown"
                            dropdownKind="menu"
                            defaultText="Edit"
                            iconNameOpen="edit"
                            disabled={isProfileWithProposalsOrArchived}
                            iconNameClose="edit"
                            panelWidth={240}
                            rounded
                        >
                            <DropdownItem itemText="Beneficiaries"
                                          value="goToBeneficiaries"
                                          onClick={onEditBeneficiaries}/>
                            <DropdownItem itemText="Estate Tax Exemption"
                                          value="goToEstateTaxExemption"
                                          onClick={onEditEstateTaxExemption}/>
                        </Dropdown>

                    </div>
                    {estateSummaryForEstateType === undefined ?
                        <LoadingIndicator style={{
                            position: "absolute",
                            top: "50%",
                            left: "25%",
                            marginTop: "-75px",
                            marginLeft: "-25px"
                        }}/> :
                        <>
                            {estateType === EstateType.SUNSET &&
                                <AlertBanner
                                    icon="info"
                                    type="info"
                                    showAlert={true}
                                    message={"The exemption amount shown has been reduced by approximately 50% in accordance with the 2026 sunset exemption."}
                                />
                            }
                            <WealthTransferAccordion estateType={estateType}
                                                     estateSummary={estateSummaryForEstateType}/>
                            {outOfEstateAssets(clientAssets, legalEntities)}
                        </>}
                </div>
                <DataEntrySideDrawer isOpen={openDrawer} size="full" formLayout="split">
                    <Switch>
                        <Route path={"/Profile/:profileId/ClientProfile/WealthTransferPlanning/Edit/Beneficiaries"}
                               component={EditBeneficiariesContainer}/>
                        <Route path={"/Profile/:profileId/ClientProfile/WealthTransferPlanning/Edit/TaxExemptions"}
                               component={EditTaxExemptionContainer}/>
                    </Switch>
                </DataEntrySideDrawer>
            </ScrollableContainer>
        </section>
    </article>
}

export default WealthTransferPlanningContainer;
