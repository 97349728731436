import {usePropsFor, VideoGallery, VideoGalleryProps} from "@azure/communication-react";
import React, {useState} from "react";
import {Icon} from "../components";

const MAX_REMOTE_VIDEO_STREAMS = 5;

type MeetingPortalAudioVideoProps = {
    displayName: string;
}
const MeetingPortalAudioVideo: React.FC<MeetingPortalAudioVideoProps> = ({displayName}) => {
    const videoGalleryProps = usePropsFor(VideoGallery);

    const participantsWithVideo = videoGalleryProps.remoteParticipants
        .filter(p => p.videoStream?.isAvailable);

    const [participantScrollIndex, setParticipantScrollIndex] = useState<number>(0);
    const participantScrollIndexEnd = participantScrollIndex + Math.min(MAX_REMOTE_VIDEO_STREAMS, participantsWithVideo.length);

    const slicedParticipants = participantsWithVideo
        .slice(participantScrollIndex, Math.min(participantScrollIndexEnd, participantsWithVideo.length))
        .concat(
            participantsWithVideo.slice(0, Math.max(0, participantScrollIndexEnd - participantsWithVideo.length))
        );
    const videoGalleryPropsOverrides: VideoGalleryProps = {
        ...videoGalleryProps,
        showMuteIndicator: true,
        remoteVideoViewOptions: {
            scalingMode: 'Crop',
        },
        localVideoViewOptions: {
            scalingMode: 'Crop',
        },
        localParticipant: {
            ...videoGalleryProps.localParticipant,
            displayName: displayName,
        },
        maxRemoteVideoStreams: MAX_REMOTE_VIDEO_STREAMS,
        remoteParticipants: slicedParticipants
    };

    const showCarouselButtons = participantsWithVideo.length > MAX_REMOTE_VIDEO_STREAMS;

    return <div className="meeting-portal-sidebar-left">
        {
            showCarouselButtons && <button className="meeting-portal-video-gallery-scroll-up" onClick={() => {
                setParticipantScrollIndex(
                    participantScrollIndex + 1 >= participantsWithVideo.length
                        ? 0
                        : participantScrollIndex + 1
                );
            }}>
                <Icon name="chevron_up" size="large"/>
            </button>
        }
        {
            showCarouselButtons && <button className="meeting-portal-video-gallery-scroll-down" onClick={() => {
                setParticipantScrollIndex(
                    participantScrollIndex - 1 < 0
                        ? participantsWithVideo.length - 1
                        : participantScrollIndex - 1
                );
            }}>
                <Icon name="chevron_down" size="large"/>
            </button>
        }
        <div className="meeting-portal-video-gallery">
            {videoGalleryProps && <VideoGallery {...videoGalleryPropsOverrides}/>}
        </div>
    </div>;
}

export default MeetingPortalAudioVideo;