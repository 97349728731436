import React from "react";
import {UnderlinedHeader} from "../../components";
import {Checkbox} from "xps-react";
import {formatPercentWithTwoDecimals} from "../../utils/format";

export function InflowCharacteristics(props: {
    isProfileWithProposalsOrArchived?: boolean,
    isInflowWillFundLifestyleGoal?: boolean,
    isHighRisk?: boolean,
    willAdjustWithInflation: boolean,
    isAdjustWithInflationCheckboxDisabled?: boolean,
    onFundLifestyleGoalChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    interestRate: number | undefined
}) {
    return <section>
        <UnderlinedHeader
            className="inflow-characteristics-section-header"
            primaryText="Inflow Characteristics"
        />
        {props.isInflowWillFundLifestyleGoal !== undefined &&
            <div className="layout-data-entry-form__field">
                <Checkbox
                    name="isInflowWillFundLifestyleGoal"
                    aria-label="Inflow will fund lifestyle goal"
                    value="Inflow will fund lifestyle goal"
                    checked={props.isInflowWillFundLifestyleGoal}
                    onChange={props.onFundLifestyleGoalChange}
                    disabled={props.isProfileWithProposalsOrArchived}
                />
            </div>}
        {props.isHighRisk !== undefined &&
            <div className="layout-data-entry-form__field">
                <Checkbox
                    name="isHighRisk"
                    aria-label="Inflow is high risk"
                    value="Inflow is high risk"
                    checked={props.isHighRisk}
                    onChange={props.onChange}
                    disabled={props.isProfileWithProposalsOrArchived}
                />
            </div>}
        <div className="layout-data-entry-form__field">
            <Checkbox
                name="willAdjustWithInflation"
                aria-label="Inflow will adjust with inflation"
                value="Inflow will adjust with inflation"
                checked={props.willAdjustWithInflation}
                disabled={props.isAdjustWithInflationCheckboxDisabled || props.isProfileWithProposalsOrArchived}
                onChange={props.onChange}
            />
        </div>
        <div className="layout-data-entry-form__field margintop-lg">
            <label>
                <b>Cash Flow Discount Rate</b>
            </label>
            <span aria-label="Cash Flow Discount Rate">
                {props.interestRate && formatPercentWithTwoDecimals(props.interestRate)}
            </span>
        </div>
    </section>;
}
