import React from 'react';

const EmptyAssetAllocation: React.FC = () => {
    return (
        <div className="empty-asset-allocation">
            <span>To review your asset allocation, please begin by entering your assets.</span>
        </div>
    )
}

export default EmptyAssetAllocation;