import React from "react";
import {RadioGroup} from "../RadioGroup";
import {EventHandler} from "../../constants/common";
import {LifeStatus} from "../../ClientManagement/models/MemberType";
import {Label} from "../Label";

type LifeStatusInputProps = {
    onChange: EventHandler,
    className?: string,
    selected?: string,
    disabled?: boolean
}

export const LifeStatusInput: React.FC<LifeStatusInputProps> = ({
                                                                    onChange,
                                                                    className,
                                                                    selected = LifeStatus.Living,
                                                                    disabled = false
                                                                }) => {
    return (
        <div className={`h4 parent ${className}`}>
            <Label label={"Life Status"}/>
            <RadioGroup
                name=""
                className="lifeStatusField"
                id="lifeStatusInput"
                aria-label="lifeStatus"
                aria-labelledby="lifeStatus-label"
                disabled={disabled}
                onChange={onChange}
                selected={selected}
                values={Object.values(LifeStatus)}
            />
        </div>
    );
}
