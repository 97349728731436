import {DatePicker as XpsDatePicker} from "xps-react";
import {Moment} from "moment";
import classNames from 'classnames';
import React from "react";

const XPS_BUILT_IN_DATE_FORMATS = [
    "M/D/YY",
    "M/D/YYYY",
    "M/DD/YY",
    "M/DD/YYYY",
    "MM/D/YY",
    "MM/D/YYYY",
    "MM/DD/YY",
    "MM/DD/YYYY",
    "M-D-YY",
    "M-D-YYYY",
    "M-DD-YY",
    "M-DD-YYYY",
    "MM-D-YY",
    "MM-D-YYYY",
    "MM-DD-YY",
    "MM-DD-YYYY",
];
const DATE_FORMATS = [
    ...XPS_BUILT_IN_DATE_FORMATS,
    "MMDDYY",
    "MMDDYYYY",
];

type DatePickerProps = {
    "aria-label"?: string;
    "aria-labelledby"?: string;
    className: string,
    date?: Moment,
    displayFormat: string,
    error?: string,
    hideKeyboardShortcutsPanel: boolean,
    id: string,
    isOutsideRange?: (date: Moment) => boolean,
    onDateChange: (date: Moment) => void,
    width?: string;
    size?: 'large' | 'medium' | 'small',
    disabled?: boolean
};

const DatePicker: React.FC<DatePickerProps> = ({
                                                   width,
                                                   size = "medium",
                                                   ...props
                                               }) => {
    return (
        <div className={classNames({'marginbottom-xl': !!props.error}, 'gpi-datepicker')}>
            <div style={{'width': width!}}>
                <XpsDatePicker
                    customFormatList={DATE_FORMATS}
                    disableTyping={false}
                    nextVersion={true}
                    numberOfMonths={1}
                    placeholder={props.displayFormat}
                    size={size}
                    type="single"
                    {...omit(props, [
                        "className",
                        "aria-label",
                        "aria-labelledby",
                    ])}
                />
            </div>
        </div>
    );
};

const omit = <O extends Object>(obj: O, props: Array<keyof O>) => {
    obj = {...obj}
    props.forEach(prop => delete obj[prop])
    return obj
}

export default DatePicker;
