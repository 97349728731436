import {RefObject, useEffect} from "react";
import {ReactDiagram} from "gojs-react";
import {FamilyTreeViewportBounds} from "../models/FamilyTreeType";
import {DiagramEvent, Point, Spot} from "gojs";

export const useFamilyTreeViewportBoundsChangeHandler = (
    diagramReference: RefObject<ReactDiagram>,
    handleViewportBoundsChanged: (viewportBounds: FamilyTreeViewportBounds) => void,
) => {
    useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout>;
        const viewportBoundsChangedHandler = (diagramEvent: DiagramEvent) => {
            const originalWidth = diagramEvent.diagram.viewportBounds.width;
            const updatedWidth = diagramEvent.parameter.width;
            const widthDifference = originalWidth - updatedWidth;
            const originalHeight = diagramEvent.diagram.viewportBounds.height;
            const updatedHeight = diagramEvent.parameter.height;
            const heightDifference = originalHeight - updatedHeight;
            if (heightDifference !== 0 || widthDifference !== 0) {
                diagramEvent.diagram.position = new Point(
                    diagramEvent.diagram.viewportBounds.x - (widthDifference / 2),
                    diagramEvent.diagram.viewportBounds.y - (heightDifference / 2),
                );
            }

            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                const viewportBounds: FamilyTreeViewportBounds = {
                    scale: diagramEvent.diagram.scale,
                    position: {
                        x: diagramEvent.diagram.position.x,
                        y: diagramEvent.diagram.position.y,
                    }
                };
                handleViewportBoundsChanged(viewportBounds);
            }, 200);
        };
        const diagram = diagramReference.current?.getDiagram();
        diagram?.addDiagramListener('ViewportBoundsChanged', viewportBoundsChangedHandler);
        return () => {
            clearTimeout(timeoutId);
            diagram?.removeDiagramListener('ViewportBoundsChanged', viewportBoundsChangedHandler);
        };
    }, [diagramReference.current, handleViewportBoundsChanged]);
};

export const useDocumentBoundsChangeHandler = (
    diagramReference: RefObject<ReactDiagram>,
    shouldReFitTreeWhenDocumentBoundsChange: boolean,
    handleViewportBoundsChanged: (viewportBounds: FamilyTreeViewportBounds) => void,
) => {
    const handleDocumentBoundsChanged = (diagramEvent: DiagramEvent) => {
        if (shouldReFitTreeWhenDocumentBoundsChange) {
            diagramEvent.diagram.scale = 0;

            const handleLayoutCompleted = () => {
                handleViewportBoundsChanged({
                    scale: diagramEvent.diagram.scale,
                    position: {
                        x: diagramEvent.diagram.position.x,
                        y: diagramEvent.diagram.position.y,
                    }
                });
                diagramEvent.diagram.removeDiagramListener('LayoutCompleted', handleLayoutCompleted);
            }
            diagramEvent.diagram.addDiagramListener('LayoutCompleted', handleLayoutCompleted);

            diagramEvent.diagram.zoomToFit();
            diagramEvent.diagram.alignDocument(Spot.Center, Spot.Center);
        }
    };
    useEffect(() => {
        const diagramRef = diagramReference.current?.getDiagram();
        diagramRef?.addDiagramListener("DocumentBoundsChanged", handleDocumentBoundsChanged);

        return () => {
            diagramRef?.removeDiagramListener("DocumentBoundsChanged", handleDocumentBoundsChanged);
        };
    }, [diagramReference.current]);
}

export const useToResetFamilyTreeToDefault = (
    diagramReference: RefObject<ReactDiagram>,
    viewportBounds: FamilyTreeViewportBounds | null,
) => {
    useEffect(() => {
        const diagram = diagramReference.current?.getDiagram();
        if (null === viewportBounds && diagram) {
            diagram.raiseDiagramEvent('DocumentBoundsChanged');
        }
    }, [diagramReference.current, viewportBounds]);
}