import React, {CSSProperties, ReactElement} from "react";
import classNames from "classnames";

interface PresentationPaneHeaderProps {
    className?: string;
    displayName: string;
    style?: CSSProperties;
    title: string;
    subtitle?:string;
    bottomRow?: ReactElement;
    titleRow?: ReactElement;
}

const PresentationPaneHeader: React.FC<PresentationPaneHeaderProps> = ({
                                                                           bottomRow,
                                                                           className,
                                                                           displayName,
                                                                           style,
                                                                           title,
                                                                           subtitle,
                                                                           titleRow
                                                                       }) => {
    return (
        <div className={classNames("presentation-pane-header", className)} style={style}>
            <div className="display-name" id="display_name">
                {displayName}
            </div>
            <div className="title-row">
                <h1>
                    {title}
                    {subtitle &&<span className="presentation-pane-header__subtitle">{subtitle}</span>}
                </h1>
                {titleRow}
            </div>
            {bottomRow}
        </div>
    );
}

export default PresentationPaneHeader;