import React, {useEffect, useState} from 'react';
import {Button, DropdownItem, Icon, PageActionMenu} from "../../components";
import {DISPLAY_LONG_DATE_FORMAT} from "../../constants/common";
import moment from "moment";
import {Meeting, MeetingParticipant, MeetingParticipation, MeetingStatus, MeetingType,} from "./Meeting";
import {Col, DropdownGroup, Row} from "xps-react";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../../routes/types";
import {meetingApiClient} from "./MeetingApiClient";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import MeetingInfoModal from "./Modal/MeetingInfoModal";
import {
    initialMeetingState,
    persistAcsToken,
    selectMeetingModalVisibility,
    selectParticipatingInMeeting,
    selectScheduledMeetingForm,
    setActiveMeeting,
    setMeetingModalVisibility,
    setParticipatingInMeeting,
    setScheduledMeetingForm,
    setShowMeetingControls
} from "./meetingSlice";
import MeetingScheduleModal from "./Modal/MeetingScheduleModal";
import {msalUtils} from "../../MsalUtils";
import {useMsal} from "@azure/msal-react";
import {getIdentifierRawId} from "@azure/communication-common";
import {ACSTokenProviderClient} from "./ACSTokenProviderClient";
import {useRelayContext} from "./Relay/types/RelayContext";
import {useCommunicationsContext} from "./CommunicationsContext";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import {useAppInsights} from "../../AppInsights";
import ModalWrapper from "../../components/Modal/ModalWrapper/ModalWrapper";
import LoadingIndicator from "../../pages/LoadingIndicator";

const MeetingScheduleSummary: React.FC = () => {
    const {id} = useParams<RouteWithId>()
    const modalVisibility = useAppSelector(selectMeetingModalVisibility)!;
    const scheduledMeetingForm = useAppSelector(selectScheduledMeetingForm);
    const dispatch = useAppDispatch();
    const msal = useMsal();
    const relayContext = useRelayContext();
    const communicationsContext = useCommunicationsContext();
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const history = useHistory();
    const [isStartingMeeting, setIsStartingMeeting] = useState(false);
    const [deleteModalState, setDeleteModalState] = useState<{ isOpen: boolean, isDeleting: boolean, meeting?: Meeting }>({
        isOpen: false,
        isDeleting: false
    });

    const [scheduledMeetings, setScheduledMeetings] = useState<Meeting[]>();

    useEffect(() => {
        if (id) {
            meetingApiClient.getScheduledMeetings(id)
                .then(meetings => setScheduledMeetings(meetings))
                .catch((error) => console.error("Could not fetch scheduled meetings: ", error.message))
        }
    }, [id, scheduledMeetingForm?.id]);

    const appInsights = useAppInsights();
    const participatingInMeeting = useAppSelector(selectParticipatingInMeeting)!;
    const triggerCreateMeetingEvent = (scheduleMeeting: Meeting) => {
        if (scheduleMeeting.type === MeetingType.CLIENT_MEETING) {
            appInsights.trackEvent({
                name: 'CreateMeeting',
                properties: {
                    action: 'Create meeting button click',
                    meetingStatus: participatingInMeeting ? MeetingParticipation.IN_MEETING : MeetingParticipation.OUT_OF_MEETING,
                    data: 'Create meeting button clicked'
                }
            })
        }
    }

    const handleStartScheduledMeeting = (scheduledMeeting: Meeting) => {
        setIsStartingMeeting(true);
        const lanId = msalUtils.getLanId(msal);
        const userName = msalUtils.getAccountName(msal);
        const userObjectId = msalUtils.getAccountUserObjectId(msal);
        const meetingParticipant: MeetingParticipant = {
            meetingId: scheduledMeeting.onlineMeetingId,
            userId: lanId,
            userName: userName,
            communicationsId: getIdentifierRawId({microsoftTeamsUserId: userObjectId})
        }

        const tokenRefresher = () => {
            return ACSTokenProviderClient.getACSTeamsUserToken(msal)
                .then(acsToken => acsToken);
        }

        meetingApiClient.addParticipant(scheduledMeeting.profileIdToPresent, meetingParticipant)
            .then(() => {
                dispatch(setActiveMeeting({
                    ...initialMeetingState.activeMeeting,
                    onlineMeetingId: scheduledMeeting.onlineMeetingId,
                    profileIdToPresent: scheduledMeeting.profileIdToPresent
                }));
                return Promise.all([
                    relayContext.createMeetingContainer(lanId, userName),
                    (releaseToggles?.enableMeetingManagementRemote
                            ? ACSTokenProviderClient.getACSTeamsUserToken(msal)
                            : Promise.resolve('')
                    ).then((acsToken) => {
                        if (!acsToken) {
                            return scheduledMeeting
                        }
                        dispatch(persistAcsToken(acsToken));
                        return communicationsContext.connect({
                            acsTokenDetails: {
                                accessToken: acsToken,
                                tokenRefresher
                            },
                            userId: userObjectId,
                            displayName: msalUtils.getAccountName(msal),
                            joinUrl: scheduledMeeting.onlineMeetingJoinUrl,
                            isAudioOn: false,
                            isVideoOn: false
                        }).then(() => scheduledMeeting)
                    })
                ]);
            }).then(async ([{containerId, documentId}, meeting]) => {
            return meetingApiClient.updateMeeting({
                ...meeting,
                fluidRelayDocumentId: documentId,
                relayContainerId: containerId,
                title: meeting.title.trim(),
                type: meeting.type,
                profileId: id,
                profileIdToPresent: meeting.profileIdToPresent,
                createdBy: lanId,
                createdByName: userName,
                remoteEnabled: releaseToggles?.enableMeetingManagementRemote ?? false,
                startDate: moment().toISOString(true),
                status: MeetingStatus.CREATED
            })
        }).then((createdMeeting) => {
            dispatch(setActiveMeeting(createdMeeting));
            dispatch(setShowMeetingControls(true));
            dispatch(setParticipatingInMeeting(true));
            dispatch(setMeetingModalVisibility({
                meetingInfo: true,
                meetingSchedule: false
            }));
            triggerCreateMeetingEvent(createdMeeting);
            history.push(`/Profile/${createdMeeting.profileIdToPresent}/ClientProfile/FamilyTree`)
        }).catch((error) => {
            console.error('Failed to start meeting: ', error.message);
            if (releaseToggles?.enableMeetingManagementRemote) {
                communicationsContext.disconnect().then();
            }
        }).finally(() => setIsStartingMeeting(false))
    }

    const handleDeleteMeeting = (meetingToDelete: Meeting) => {
        meetingApiClient.updateMeeting({
            ...meetingToDelete,
            status: MeetingStatus.DELETED
        }).then(() => {
            const updatedScheduledMeetings = scheduledMeetings?.filter(meeting => meeting.id !== meetingToDelete.id);
            setScheduledMeetings(updatedScheduledMeetings);
        }).catch(err => {
            console.error(err.message)
        }).finally(() => setDeleteModalState({isOpen: false, isDeleting: false}))
    }

    const forFutureMeetingsOnly = (meeting: Meeting) => moment(meeting.startDate).add(14, 'days').isSameOrAfter(moment());

    return (
        <>
            <div className="meeting-scheduled-summary">
                {
                    scheduledMeetings?.filter(forFutureMeetingsOnly).slice(0, 5).map((meeting) =>
                        <div key={meeting.onlineMeetingId}>
                            <Row role={"listitem"} className="meeting-scheduled-item">
                                <Col className="scheduled-item__icon"><Icon name={"calendar_date"}/></Col>
                                <Col className="scheduled-item__details">
                                    <Row><b>{meeting.title}</b></Row>
                                    <Row><span className={"greyed-out"}>{
                                        moment(meeting.startDate).format(DISPLAY_LONG_DATE_FORMAT)
                                    }</span></Row>
                                </Col>
                                <Col className={"meeting-action-menu-wrapper"}>
                                    <PageActionMenu
                                        buttonKind="borderless"
                                        className="meeting-action-menu"
                                        panelHeight="auto"
                                        panelWidth={240}
                                        aria-label="meeting-options-dropdown"
                                        key="page-action-menu"
                                    >
                                        <DropdownGroup groupName=" " key={"ls-dropdown-group"}>
                                            <DropdownItem className="page-action-menu-options"
                                                          onClick={() => {
                                                              handleStartScheduledMeeting(meeting)
                                                          }}
                                                          value="Start Meeting"
                                                          disabled={isStartingMeeting}
                                                          itemText="Start Meeting">
                                            </DropdownItem>
                                            <DropdownItem className="page-action-menu-options"
                                                          onClick={() => {
                                                              dispatch(setScheduledMeetingForm(meeting))
                                                              dispatch(setMeetingModalVisibility({
                                                                  ...modalVisibility,
                                                                  meetingInfo: false,
                                                                  meetingSchedule: true
                                                              }))
                                                          }}
                                                          value="Edit Meeting"
                                                          itemText="Edit Meeting">
                                            </DropdownItem>
                                            <DropdownItem className="page-action-menu-options"
                                                          onClick={() => setDeleteModalState({
                                                              isOpen: true,
                                                              isDeleting: false,
                                                              meeting
                                                          })}
                                                          value="Delete Meeting"
                                                          itemText="Delete Meeting">
                                            </DropdownItem>
                                        </DropdownGroup>
                                    </PageActionMenu>
                                </Col>
                            </Row>
                        </div>
                    )
                }
            </div>
            <Button className="schedule-meeting-btn" onClick={() => {
                dispatch(setMeetingModalVisibility({
                    ...modalVisibility,
                    meetingInfo: false,
                    meetingSchedule: true
                }));
            }}>
                SCHEDULE MEETING
            </Button>
            {modalVisibility?.meetingSchedule &&
                <MeetingScheduleModal/>}
            {modalVisibility?.meetingInfo && scheduledMeetingForm?.id &&
                <MeetingInfoModal/>}
            <ModalWrapper
                isOpen={deleteModalState.isOpen}
                id="deleteMeetingModal"
                headerText="Delete this meeting?"
                buttons={deleteModalState.isDeleting ? [] : [
                    {
                        text: "CANCEL",
                        onClick: () => setDeleteModalState({isOpen: false, isDeleting: false})
                    },
                    {
                        text: "DELETE MEETING",
                        primary: true,
                        destructive: true,
                        onClick: () => {
                            if (deleteModalState.meeting) {
                                handleDeleteMeeting(deleteModalState.meeting)
                                setDeleteModalState({isOpen: true, isDeleting: true})
                            }
                        }
                    }
                ]}
            >
                <div className="font-md">
                    {deleteModalState.isDeleting
                        ? <LoadingIndicator displayMessage="Deleting meeting..."></LoadingIndicator>
                        : "The Meeting ID and Meeting Passcode will no longer work. Please notify all remote participants that you've removed the meeting."
                    }
                </div>
            </ModalWrapper>
        </>
    );
}

export default MeetingScheduleSummary;