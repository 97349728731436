import moment from "moment";
import {DISPLAY_DATE_FORMAT, DISPLAY_DATE_TIME_FORMAT, ISO8601_DATE_FORMAT} from "../constants/common";

export const calculateBirthdateFromAge = (age: number) => {
    return moment().subtract(age, 'years').subtract(6, 'months').toISOString().substring(0, 10);
};

export const calculateAgeFromBirthdate = (birthdate: string, dateFormat = DISPLAY_DATE_FORMAT) => {
    return Math.floor(moment.duration(moment().diff(moment(birthdate, dateFormat))).asYears());
};

export const personalPlanningHorizonFromAge = (age: number, personalPlanningHorizon: number) => {
    const sanitizedAge = isNaN(+age) ? 0 : age;
    return (sanitizedAge < personalPlanningHorizon) ? personalPlanningHorizon : (sanitizedAge + 1)
}

export const momentToISO = (date: moment.Moment) => date.toISOString().substring(0, 10);

export const toISO = (date: string) => date ? moment(date, DISPLAY_DATE_FORMAT).toISOString().substring(0, 10) : '';

export const toDisplayDateFormat = (date: string | undefined | null, displayFormat: string = DISPLAY_DATE_FORMAT) => date ? moment(date.split('T')[0]).format(displayFormat) : '';

export const toDisplayDateTimeFormat = (date: string | undefined | null, displayFormat: string = DISPLAY_DATE_TIME_FORMAT) => date ? moment(date).format(displayFormat) : '';

export const getTodayDate = (dateFormat = ISO8601_DATE_FORMAT) => {
    return moment().format(dateFormat);
};

export const getTodayDateWithYearInPast = (yearsToSubtract: number, dateFormat = ISO8601_DATE_FORMAT) => {
    return moment().subtract(yearsToSubtract, 'years').format(dateFormat);
}

export const getTodayDateWithYearInFuture = (yearsToAdd: number, dateFormat = ISO8601_DATE_FORMAT) => {
    return moment().add(yearsToAdd, 'years').format(dateFormat);
}

export const calculateYearsBetween = (startDate: string, endDate: string, dateFormat = ISO8601_DATE_FORMAT) => {
    return moment.duration(moment(endDate, dateFormat).diff(moment(startDate, dateFormat))).asYears();
};
