import {LifestyleSpendingPeriodInputs} from "../models/LifestyleSpendingGoal";
import LifestyleSpendingPeriodsList from "./LifestyleSpendingPeriodsList";
import {Button} from "../../components";
import React from "react";
import {PlanningPeriodType} from "../../ClientManagement/models/InvestorGroupType";

type LifestyleSpendingPeriodsProps = {
    lifestyleSpendingPeriods: LifestyleSpendingPeriodInputs[],
    onUpdate: (newSpendingPeriods: LifestyleSpendingPeriodInputs[]) => Promise<void>,
    onBlurAnnualSpend: () => Promise<void>,
    onBlurLengthOfSpend: () => Promise<void>,
    planningPeriod: PlanningPeriodType,
    hasError: boolean,
    enableMultipleSpendingPeriods: undefined | boolean,
    onAddSpendingPeriod: () => Promise<void>,
    totalLifestyleSpendingYears: number,
    isProfileWithProposalsOrArchived?: boolean
};

export function LifestyleSpendingPeriods({
                                             lifestyleSpendingPeriods,
                                             onUpdate,
                                             onBlurAnnualSpend,
                                             onBlurLengthOfSpend,
                                             planningPeriod,
                                             hasError,
                                             enableMultipleSpendingPeriods,
                                             onAddSpendingPeriod,
                                             totalLifestyleSpendingYears,
                                             isProfileWithProposalsOrArchived = false
                                         }: LifestyleSpendingPeriodsProps) {

    return <>
        <div className="lifestyle-grid">
            <span className="LifestyleSpendingPeriodHeaders">Description</span>
            <span className="LifestyleSpendingPeriodHeaders">Annual Spending</span>
            <span className="LifestyleSpendingPeriodHeaders">Length of Spend</span>
        </div>


        <LifestyleSpendingPeriodsList
            lifestyleSpendingPeriodsList={lifestyleSpendingPeriods}
            onUpdate={onUpdate}
            onBlurAnnualSpend={onBlurAnnualSpend}
            onBlurLengthOfSpend={onBlurLengthOfSpend}
            planningPeriod={planningPeriod}
            hasError={hasError}
            isProfileWithProposalsOrArchived={isProfileWithProposalsOrArchived}
        />

        {enableMultipleSpendingPeriods &&
            <Button
                className="margintop-xxl"
                kind="primary"
                size={"medium"}
                type="button"
                data-testid={'add-spending-period'}
                icon="left"
                iconName="add"
                onClick={onAddSpendingPeriod}
                disabled={isProfileWithProposalsOrArchived}
            >
                 ADD SPENDING PERIOD
            </Button>
        }
        <hr/>
        {hasError &&
            <div data-testid="spending-period-warning">
                <div className="lifestyle-spending-total-container color-text--error">
                    <b>Total Lifestyle Spending</b>
                    <div className="lifestyle-spending-sum-container">
                                        <span
                                            data-testid="spending-total-sum">
                                            <b>{totalLifestyleSpendingYears} years</b>
                                        </span>
                    </div>
                </div>
                <span className="color-text--error">
                                    The total lifestyle spend must equal {planningPeriod.numberOfYears} years.
                                </span>
            </div>
        }
        {!hasError &&
            <div className="lifestyle-spending-total-container">
                <b>Total Lifestyle Spending</b>
                <div className="lifestyle-spending-sum-container">
                                <span
                                    data-testid="spending-total-sum">
                                    <b>{totalLifestyleSpendingYears} years</b>
                                </span>
                </div>
            </div>
        }
    </>;
}