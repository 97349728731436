import {LogLevel} from "@azure/msal-browser";
import {App_Conf} from "./core/app_conf";
import {Configuration} from "@azure/msal-browser/dist/config/Configuration";
import storageHelper from "./core/storageHelper";

const {APP_URL} = App_Conf;
const {AUTHORITY} = App_Conf;

export const getMsalConfig = (): Configuration => ({
    auth: {
        clientId: storageHelper.getSpaConfig().clientId,
        authority: AUTHORITY,
        redirectUri: APP_URL
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
});

export const getLoginRequest = () => ({
    scopes: [storageHelper.getSpaConfig().scopes],
    forceRefresh: false
});

export const appURL = APP_URL;