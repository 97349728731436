import React, {ChangeEvent} from "react";
import NumberInput from "./NumberInput";
import {NO_OP} from "../../constants/common";

type AssetsYearsInputProps = {
    fieldName: string,
    label: string,
    value: number,
    maxLength?: number,
    maxValue?: number,
    minValue?: number,
    readOnly?: boolean,
    onChangeValue?: (e: ChangeEvent<HTMLInputElement>, value: number) => void,
    onBlur?: (e: ChangeEvent<HTMLInputElement>, value: number) => void,
    error?: string,
    readonly?: boolean
}

function clamp(value: number, min: number, max: number) {
    return Math.min(Math.max(value, min), max);
}

export function AssetsYearsInput({
                                     error,
                                     fieldName,
                                     label,
                                     maxLength,
                                     maxValue,
                                     minValue,
                                     onBlur,
                                     onChangeValue = NO_OP,
                                     readOnly = false,
                                     value,
                                 }: AssetsYearsInputProps) {
    const handleOnBlur = (event: ChangeEvent<HTMLInputElement>) => {
        let clampedValue = parseInt(event.target.value);

        if(minValue !== undefined && maxValue !== undefined) {
            clampedValue = clamp(parseInt(event.target.value), minValue, maxValue);
        }
        onBlur && onBlur(event, clampedValue);
    }
    return <>
        <label id={`${fieldName}-label`}>
            <b>{label}</b>
        </label>
        <NumberInput
            name={fieldName}
            aria-labelledby={`${fieldName}-label`}
            value={value}
            maxLength={maxLength}
            onChangeValue={(e, newValue) => onChangeValue(e, newValue as number)}
            minValue={0}
            onBlur={handleOnBlur}
            readOnly={readOnly}
            error={error}
            inlineLabel="years"
            inlineLabelAlign="right"
            size="medium"
        />
    </>
}
