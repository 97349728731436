import React, {FunctionComponent, ReactNode, useContext} from 'react';
import classNames from 'classnames';
import {IconPlaceholder} from "../../../components/Icon/IconPlaceholder";
import Icon from "../../../components/Icon/Icon";
import AccordionContext from "../../../components/Accordion/AccordionContext";


type AccordionItemProps = {
    children?: ReactNode,
    uuid_: string,
    buttonClassName_?: string,
    className_?: string,
    accentColor_?: string,
    primaryText_?: string,
    rightAlignedContent_?: ReactNode,
    HeaderComponent?: FunctionComponent<{ expanded: boolean, toggle: () => void }>,
    accordionHeight_?: string,
    expandable_?: boolean,
    isSingleAccordion_?: boolean
};

export default function AccordionItemReport({
                                          children,
                                          uuid_,
                                          buttonClassName_,
                                          HeaderComponent,
                                          className_,
                                          accentColor_,
                                          rightAlignedContent_,
                                          primaryText_,
                                          accordionHeight_,
                                          expandable_ = true,
                                          isSingleAccordion_ = true,
                                      }: AccordionItemProps) {
    const {toggleItem} = useContext(AccordionContext);
    const toggle_ = () => {
        if (expandable_) {
            toggleItem(uuid_);
        }
    }
    const isExpanded_ = true;
    const iconName_ = isExpanded_ ? 'chevron_down' : 'chevron_right';
    const icon_ = expandable_ ? <Icon name={iconName_} size="large"/> : <IconPlaceholder/>;
    const Header_ = (HeaderComponent)
        ? <HeaderComponent expanded={isExpanded_} toggle={toggle_} />
        : (
            <div className="accented-accordion-item__header">
                <span className="accented-accordion-item__left-content">
                    {icon_}
                    <span className="paddingleft-md">
                        <div className="h4 fontweight-normal margin-xs">{primaryText_}</div>
                    </span>
                </span>
                {rightAlignedContent_ && (
                    <span className="accented-accordion-item__right-content">{rightAlignedContent_}</span>
                )}
            </div>
        );

    const AccordionItemWithoutAccent = (
        <li
            aria-label={`${primaryText_} Accordion Item`}
            className={classNames(
                'accordion__item',
                {'accordion__item--expanded': isExpanded_, 'accordion__item--collapsed': !isExpanded_},
                accentColor_ ? className_ : null,
            )}
        >
            <div
                className={classNames('accordion__button', buttonClassName_, expandable_ && 'accordion__button--expandable')}
                aria-pressed={isExpanded_ ? 'true' : 'false'}
                onClick={toggle_}
                role="button"
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        toggle_();
                    }
                }}
                tabIndex={0}
            >
                {Header_}
            </div>
            <div
                className={`${isSingleAccordion_ ? 'accordion__content' : 'accordion__content-right-moved'}`}>{children}</div>
        </li>
    );

    if (accentColor_) {
        return (
            <div style={{minHeight: accordionHeight_}}
                 role="accordionitem"
                 className={classNames('accented-accordion-item', className_)}>
                <div aria-label="accordion accent color"
                     className="accented-accordion-item__accent"
                     style={{backgroundColor: accentColor_, minHeight: accordionHeight_}}/>
                {AccordionItemWithoutAccent}
            </div>
        );
    } else {
        return AccordionItemWithoutAccent;
    }
}
