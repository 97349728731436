import {MemberType} from "../../ClientManagement/models/MemberType";
import React, {useMemo} from "react";
import {BeneficiaryGoals as BeneficiaryGoalsType} from "../models/GoalModelType";
import {Accordion} from "../../components";
import {BeneficiaryGoalsAccordionItem} from "./BeneficiaryGoalsAccordionItem";
import BeneficiaryGoalsAccordionHeader from "./BeneficiaryGoalsAccordionHeader";
import {InvestorGroupType} from "../../ClientManagement/models/InvestorGroupType";
import {FamilyGoalType} from "../models/FamilyGoalType";
import {FamilyAssets} from "../../Assets/models/FamilyAssets";
import FamilyGoalUtils from "../Summary/FamilyGoalUtils";
import {GoalSubType} from "../models/GoalType";

type BeneficiaryGoalsProps = {
    memberSelected: MemberType,
    dropdownItems: JSX.Element[],
    beneficiaryGoals: BeneficiaryGoalsType,
    investorGroup: InvestorGroupType,
    onEditGoal: (selectedGoalType: string, savedGoal: FamilyGoalType) => void,
    onApplyFamilyAssets: (goal: FamilyGoalType, assetAmountToApply: number, selectedGoalType?: GoalSubType) => void,
    familyAssets: FamilyAssets,
    availableAssets: number,
}

const BeneficiaryGoals = ({
                              memberSelected,
                              dropdownItems,
                              beneficiaryGoals,
                              investorGroup,
                              onEditGoal,
                              onApplyFamilyAssets,
                              familyAssets,
                              availableAssets
                          }: BeneficiaryGoalsProps) => {
    const memoizedAccordion = useMemo(() => <Accordion
        expanded={["FamilyGoal"]}
        allowMultipleExpanded
        allowZeroExpanded
        accordionId="beneficiaryGoalsAccordionId"
    >
        <div className="assets">
            <BeneficiaryGoalsAccordionHeader
                memberSelected={memberSelected}
                dropdownItems={dropdownItems}
                totalMyResponsibility={FamilyGoalUtils.getTotalMyResponsibility(beneficiaryGoals)}
            />
            <BeneficiaryGoalsAccordionItem
                memberSelected={memberSelected}
                beneficiaryGoals={beneficiaryGoals}
                investorGroup={investorGroup}
                onEditGoal={onEditGoal}
                onApplyFamilyAssets={onApplyFamilyAssets}
                familyAssets={familyAssets}
                availableAssets={availableAssets}
            />
        </div>
    </Accordion>, [memberSelected, dropdownItems, beneficiaryGoals, investorGroup, availableAssets]);
    return (
        <article>
            <section style={{marginBottom: 0}}>
                <div className="goal-list">
                    <div className="accordion-tables">{memoizedAccordion}</div>
                </div>
            </section>
        </article>
    )
}
export default BeneficiaryGoals;
