import {EmptyStateContainer, Tab, TabBar, TabPanel, TabsProvider} from "../../../components";
import InvestmentPrograms from "./InvestmentPrograms";
import React from "react";
import {InvestmentProgram, InvestmentProgramsResponse} from "../../models/InvestmentProgram";

export enum TabType {
    MyInvestmentProgramsTab = "MyInvestmentProgramsTab",
    TeamInvestmentProgramsTab = "TeamInvestmentProgramsTab"
}

type InvestmentProgramTabsProps = {
    onChangeTab: (tab: TabType) => void,
    myInvestmentPrograms: InvestmentProgramsResponse | undefined,
    teamInvestmentPrograms: InvestmentProgramsResponse | undefined,
    onSelect: (investmentProgram: InvestmentProgram) => void,
    currentPage: number,
    onChangeCurrentPage: (currentPage: number) => void,
    pageSize: number,
    onChangePageSize: (pageSize: number) => void,
    isLoading?: boolean,
    isSearching?: boolean,
    displayNoMatchFound?: boolean,
    isTabChange?: boolean
}

const InvestmentProgramTabs = ({
                                   onChangeTab,
                                   myInvestmentPrograms,
                                   teamInvestmentPrograms,
                                   onSelect,
                                   currentPage,
                                   onChangeCurrentPage,
                                   pageSize,
                                   onChangePageSize,
                                   isLoading,
                                   isSearching,
                                   displayNoMatchFound,
                                   isTabChange
                               }: InvestmentProgramTabsProps) => {
    return (
        <TabsProvider
            defaultActiveIndex={0}
            tabIdArray={['myInvestmentPrograms', 'teamInvestmentPrograms']}>
            <TabBar className="paddingtop-xl" size="small" theme="light">
                <Tab name="My Investment Programs" onClick={() => onChangeTab(TabType.MyInvestmentProgramsTab)}/>
                <Tab name="Team Investment Programs" onClick={() => onChangeTab(TabType.TeamInvestmentProgramsTab)}/>
            </TabBar>
            <TabPanel className="marginbottom-xl">
                <div>
                    <InvestmentPrograms
                        investmentPrograms={myInvestmentPrograms?.investmentPrograms ?? []}
                        displayProgramManager={false}
                        handleSave={onSelect}
                        currentPage={currentPage}
                        setCurrentPage={onChangeCurrentPage}
                        pageSize={pageSize}
                        setPageSize={onChangePageSize}
                        totalPages={myInvestmentPrograms?.totalPages ?? 1}
                        totalInvestmentPrograms={myInvestmentPrograms?.totalInvestmentPrograms ?? 0}
                        isLoading={isLoading}
                    />
                    {(displayNoMatchFound && !isTabChange) &&
                        <EmptyStateContainer
                            className='no-search-result-placeholder'
                            title={<>Sorry, no matching records were found.<br/>Please refine your search criteria.</>}
                            size="large"
                        />}
                </div>
                <div>
                    <InvestmentPrograms
                        investmentPrograms={teamInvestmentPrograms?.investmentPrograms ?? []}
                        displayProgramManager
                        handleSave={onSelect}
                        currentPage={currentPage}
                        setCurrentPage={onChangeCurrentPage}
                        pageSize={pageSize}
                        setPageSize={onChangePageSize}
                        totalPages={teamInvestmentPrograms?.totalPages ?? 1}
                        totalInvestmentPrograms={teamInvestmentPrograms?.totalInvestmentPrograms ?? 0}
                        isLoading={isLoading}
                    />
                    {(displayNoMatchFound && !isTabChange) &&
                        <EmptyStateContainer
                            className='no-search-result-placeholder'
                            title={<>Sorry, no matching records were found.<br/>Please refine your search criteria.</>}
                            size="large"
                        />}
                </div>
            </TabPanel>
        </TabsProvider>
    );
}

export default InvestmentProgramTabs;